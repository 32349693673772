import React from 'react'
import { IconProps } from '../../ui/icons/types'
import TriangleFigure from '../../ui/icons/TriangleFigure'
import TriangleVolumeFigure from '../../ui/icons/TriangleVolumeFigure'
import SmileyFigure from '../../ui/icons/SmileyFigure'
import GraphFigure from '../../ui/icons/GraphFigure'

export enum CorrectAnswerRatePercentage {
  ONE_STAR = 50,
  TWO_STARS = 70,
  THREE_STARS = 90,
}

type PracticeIcon = {
  Icon: React.ElementType<IconProps>
  size?: number | { width: number; height: number }
  color?: string
}

export const PRACTICE_ICONS: PracticeIcon[] = [
  { Icon: TriangleFigure, size: { width: 188, height: 181 } },
  { Icon: TriangleVolumeFigure, size: { width: 265, height: 258 } },
  { Icon: SmileyFigure, size: { width: 150, height: 145 } },
  { Icon: GraphFigure, size: { width: 419, height: 218 } },
]

export const AVAILABLE_GRADES = Array.from({ length: 13 }, (_, i) => i)
