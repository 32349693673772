import React from 'react';

import './styles.scss';

const NumpadMathButton = (props) => {
    return (
        <button
            className={`numpad-item-button ${props.styleClass ? props.styleClass : ''}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

export default NumpadMathButton;