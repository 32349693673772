import {
  ALPHA_COMMAND,
  ANGLE_SIGN,
  APPROXIMATE_COMMAND,
  BACK_SIMILAR_COMMAND,
  BETA_COMMAND,
  BIG_DELTA_COMMAND,
  CARET_DEGREE_SIGN,
  COS_COMMAND,
  DEGREE_SIGN,
  DELTA_COMMAND,
  DIVIDE_COMMAND,
  DOTS_COMMAND,
  EXTRA_SQUARE_ROOT,
  GAMMA_COMMAND,
  GREATER_OR_EQUAL,
  LAMBDA_COMMAND,
  LESS_OR_EQUAL,
  MULTIPLE_COMMAND,
  NOT_EQUALS_COMMAND,
  PARALLEL_SIGN,
  PI_COMMAND,
  PLUS_MINUS_COMMAND,
  POUNDS_COMMAND,
  SIMILAR_COMMAND,
  SIN_COMMAND,
  TAN_COMMAND,
  THETA_COMMAND,
  THICK_APPROXIMATE_COMMAND,
  TIMES_COMMAND,
  UNDERLINE_COMMAND,
  VAR_DELTA_COMMAND,
} from '../../../constants/katexConstants'
import {
  ALPHA_SIGN_SPEECH,
  ANGLE_SIGN_SPEECH,
  APPROXIMATE_SIGN_SPEECH,
  BACK_SIMILAR_SIGN_SPEECH,
  BETA_SIGN_SPEECH,
  BIG_DELTA_SIGN_SPEECH,
  DEGREE_SIGN_SPEECH,
  DELTA_SIGN_SPEECH,
  DIVIDE_SIGN_SPEECH,
  DOTS_SIGN_SPEECH,
  GAMMA_SIGN_SPEECH,
  GREATER_OR_EQUAL_SIGN_SPEECH,
  LAMBDA_SIGN_SPEECH,
  LESS_OR_EQUAL_SIGN_SPEECH,
  MULTIPLE_SIGN_SPEECH,
  NOT_EQUALS_SIGN_SPEECH,
  PARALLEL_SIGN_SPEECH,
  PI_SIGN_SPEECH,
  PLUS_MINUS_SIGN_SPEECH,
  POUNDS_SIGN_SPEECH,
  SIMILAR_SIGN_SPEECH,
  SQUARE_ROOT_SPEECH,
  THETA_SIGN_SPEECH,
  THICK_APPROXIMATE_SIGN_SPEECH,
  UNDERLINE_SIGN_SPEECH,
} from '../../../constants/textToSpeechConstants'

const squareRootRegex = /\\sqrt\{(?<squareValue>.*?)\}/gm // Matches \sqrt{x} and groups x to be accessible under name "squareValue"
const mathPowerRegex = /(?<base>.*?)\^\{(?<power>.*?)\}/gm // Matches x^{y} and groups x and y to be accessible under names "base" and "power"
const fractionRegex = /\\dfrac\{(?<numerator>.*?)}\{(?<denominator>.*?)\}/gm // Matches \dfrac{x}{y} and groups x and y to be accessible under names "nominator" and "denominator" accordingly

const emptyElement = "<span class='empty-element'></span>"

type LatexConversionMap = {
  multiple?: string
}

// TODO: refactor and just write a function that will map through key-value object
export const transformLatexToText = (
  latexText: string,
  { multiple = MULTIPLE_SIGN_SPEECH }: LatexConversionMap = {}
) => {
  return latexText
    .replace(/\\left\(\s/gm, '(')
    .replace(/\\right\)/gm, ')')

    .replace(/\\left\[\s/gm, '[')
    .replace(/\\right\]/gm, ']')

    .replace(/\\left\\{\s/gm, '{')
    .replace(/\\right\\}\s/gm, '}')

    .replace(/\\{\s/gm, '{')
    .replace(/\\}\s/gm, '}')

    .replace(/\\cap/g, '∩')
    .replace(/\\cup/g, '∪')
    .replace(/\^{\\circ*.}/g, DEGREE_SIGN_SPEECH)

    .replace(MULTIPLE_COMMAND, multiple)
    .replace(TIMES_COMMAND, multiple)
    .replace(DIVIDE_COMMAND, DIVIDE_SIGN_SPEECH)
    .replace(EXTRA_SQUARE_ROOT, SQUARE_ROOT_SPEECH)
    .replace(DEGREE_SIGN, DEGREE_SIGN_SPEECH)
    .replace(CARET_DEGREE_SIGN, DEGREE_SIGN_SPEECH)
    .replace(GREATER_OR_EQUAL, GREATER_OR_EQUAL_SIGN_SPEECH)
    .replace(LESS_OR_EQUAL, LESS_OR_EQUAL_SIGN_SPEECH)
    .replace(ANGLE_SIGN, ANGLE_SIGN_SPEECH)
    .replace(PARALLEL_SIGN, PARALLEL_SIGN_SPEECH)
    .replace(APPROXIMATE_COMMAND, APPROXIMATE_SIGN_SPEECH)
    .replace(BACK_SIMILAR_COMMAND, BACK_SIMILAR_SIGN_SPEECH)
    .replace(SIMILAR_COMMAND, SIMILAR_SIGN_SPEECH)
    .replace(THICK_APPROXIMATE_COMMAND, THICK_APPROXIMATE_SIGN_SPEECH)
    .replace(NOT_EQUALS_COMMAND, NOT_EQUALS_SIGN_SPEECH)
    .replace(DOTS_COMMAND, DOTS_SIGN_SPEECH)
    .replace(POUNDS_COMMAND, POUNDS_SIGN_SPEECH)
    .replace(PI_COMMAND, PI_SIGN_SPEECH)
    .replace(ALPHA_COMMAND, ALPHA_SIGN_SPEECH)
    .replace(BETA_COMMAND, BETA_SIGN_SPEECH)
    .replace(GAMMA_COMMAND, GAMMA_SIGN_SPEECH)
    .replace(THETA_COMMAND, THETA_SIGN_SPEECH)
    .replace(LAMBDA_COMMAND, LAMBDA_SIGN_SPEECH)
    .replace(PLUS_MINUS_COMMAND, PLUS_MINUS_SIGN_SPEECH)
    .replace(VAR_DELTA_COMMAND, BIG_DELTA_SIGN_SPEECH)
    .replace(BIG_DELTA_COMMAND, BIG_DELTA_SIGN_SPEECH)
    .replace(DELTA_COMMAND, DELTA_SIGN_SPEECH)
    .replace(UNDERLINE_COMMAND, UNDERLINE_SIGN_SPEECH)
    .replace(COS_COMMAND, 'cos')
    .replace(SIN_COMMAND, 'sin')
    .replace(TAN_COMMAND, 'tan')
    .replace(/\\\$/g, '$')
    .replace(/\\#/g, '#')
    .replace(/\\euro/g, '€')
    .replace(/\\yen/g, '¥')
    .replace(/\\%/g, '%')
    .replace(/\\text\{\\textperthousand}/g, '‰')
    .replace(/\\rightarrow/g, '→')
    .replace(/\\leftarrow/g, '←')
    .replace(/\\toparrow/g, '↑')
    .replace(/\\downarrow/g, '↓')
    .replace(/\\ast/g, '*')
    .replace(/\\sigma/g, 'Σ')
    .replace(mathPowerRegex, `$<base><sup>$<power></sup>${emptyElement}`)
    .replace(
      fractionRegex,
      `<div class='fraction'><span placeholder='a' tabindex="0" inputmode="none" class='fraction-item numerator'>$<numerator></span><span placeholder='b' tabindex="0" inputmode="none" class='fraction-item denominator'>$<denominator></span></div>`
    )
    .replace(squareRootRegex, '√<div class="latex-square-root">$<squareValue></div>')
    .replace(/\\/g, '')
}
