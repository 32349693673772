import React from 'react'
import { DEFAULT_ICON_FILL } from '../constants'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'

export const MathVolumeIcon = ({ size = 28, fill = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.6928 12.6636H20.5068'
        stroke={fill}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6931 16.2212H19.3213'
        stroke={fill}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6928 19.7791H20.5068'
        stroke={fill}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6931 23.3367H19.3213'
        stroke={fill}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask id='mask0_32_2783' maskUnits='userSpaceOnUse' x='0' y='0' width='29' height='28'>
        <path d='M0 0H28.875V28H0V0Z' fill='#ffffff' />
      </mask>
      <g mask='url(#mask0_32_2783)'>
        <path
          d='M28.8084 0.804393L25.2506 4.36214V25.1157C25.2506 26.0981 24.4542 26.8945 23.4718 26.8945H9.24079C8.25832 26.8945 7.46191 26.0981 7.46191 25.1157V0.804393H28.8084Z'
          stroke={fill}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.46211 0.804475V4.36222H3.78577V13.8495C3.78577 14.832 2.98937 15.6284 2.0069 15.6284C1.02443 15.6284 0.228027 14.832 0.228027 13.8495V4.36222C0.228027 2.39734 1.82089 0.804475 3.78577 0.804475H7.46211Z'
          stroke={fill}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.2506 9.10572C22.3368 8.32871 19.2701 8.32871 16.3563 9.10572C13.4424 9.88273 10.3758 9.88273 7.46191 9.10572'
          stroke={fill}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
