import React from 'react'
import styles from './ExerciseTitle.module.scss'
import useOverflowYDetection from '../../../../hooks/useOverflowYDetection'

import UITooltip from '../../../../ui/tooltips/UITooltip'

type ExerciseTitleProps = {
  title: string
}

const ExerciseTitle = ({ title }: ExerciseTitleProps) => {
  const { parentRef, isOverflowY } = useOverflowYDetection()

  return (
    <UITooltip title={isOverflowY ? <span>{title}</span> : ''} placement='top-start'>
      <div ref={parentRef} className={styles.CardTitleContainer}>
        <h5 className={styles.CardTitle}>{title}</h5>
      </div>
    </UITooltip>
  )
}

export default ExerciseTitle
