import React from 'react'

import './styles.scss'
import { ReactComponent as LanguageIcon } from '../../../assets/images/translation.svg'
import { SIDEBAR_LEFT_ICON_SIZE } from '../../../constants/styleConstants'
import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../constants/defaults'
import { useTranslation } from 'react-i18next'

const LanguageSelector = (props) => {
  const { t } = useTranslation()
  return (
    <div className={`language-selector-container ${props.relative ? 'relative' : ''}`}>
      <span className='language-title'>{props.title}</span>
      <div className='language-selector' onClick={props.onSelectorClick}>
        <div className='language-selector-header'>
          <LanguageIcon size={SIDEBAR_LEFT_ICON_SIZE} className='language-selector-icon' />
          <span className='language-name'>
            {props.languageName === NO_LANGUAGE_SELECTED || props.languageName === DEPRECATED_NONE_LANGUAGE
              ? t('languageListNone')
              : props.languageName}
          </span>
        </div>
      </div>
      {props.children}
    </div>
  )
}

export default LanguageSelector
