import { getUserId } from './getUserInfo'
import { showLogoutToast } from '../../constants/toasts'
import { USER_DATA } from '../../config/localStorageKeys'

import history from '../../history'
import { RoutesPaths } from '../../containers/paths'
import { apiManager } from '../../api/apiManager'

export const userDeleted = (userId) => {
  if (userId !== getUserId()) {
    return
  }

  const logoutUser = () => {
    apiManager.setToken(null)
    localStorage.removeItem(USER_DATA)
    history.push(RoutesPaths.INDEX)
  }

  showLogoutToast({ toastId: new Date(), callback: logoutUser })
}
