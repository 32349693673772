import i18n from 'i18next'
import { attach, createEffect } from 'effector'
import { redirectToHomePage } from '../../helpers'
import { Assignment, ExamStatuses } from '../../features/ExerciseItem/types'
import {
  $assignmentPopup,
  AssignmentPopup,
  closeAssignmentPopup,
  setAssignmentPopup,
} from '../../features/assignmentPopups/model'
import { assignmentPaused, assignmentUnpaused } from '../../features/CheatDetection/models/pausedAssignments'
import { cheatDetected, resetCheatDetection } from '../../features/CheatDetection/models/cheatDetected'
import { assignmentModel } from '../../features/assignment/model/assignment.model'
import { checkIsExercisePublished } from '../../helpers/exercises/exercisesHelper'
import { showErrorToast } from '../../constants/toasts'
import { updateAssignment } from '../../features/assignmentsPagination/model/assignments'
import { getCurrentAssignment } from '../../features/assignments/requests'
import { createAssignmentSocketListener } from '../../helpers/exercises/createAssignmentSocketListener'
import { checkIsExamStopped } from './helpers'
import { currentExerciseModel } from '../../features/assignment/model/credentials.model'

type StudentCheatUpdateSocketPayload = {
  exerciseId: string
  studentId: string
}

type StudentCheatUpdateProps = {
  assignment: Assignment | null
  assignmentPopup: AssignmentPopup | null
} & StudentCheatUpdateSocketPayload

const updateActiveAssignment = (assignment: Assignment, updatedAssignment: Assignment) => {
  if (assignment?._id === updatedAssignment._id) {
    assignmentModel.setAttemptInfo(updatedAssignment)
    assignmentModel.setAssignment(updatedAssignment)
  }
}

export const onExerciseDelete = createAssignmentSocketListener(({ exerciseId, assignment }) => {
  if (exerciseId === assignment?._id) redirectToHomePage()
})

export const onExerciseUpdate = createAssignmentSocketListener(async ({ assignment, exerciseId }) => {
  const updatedAssignment: Assignment = await getCurrentAssignment(exerciseId)

  if (assignment?._id !== updatedAssignment._id) {
    return
  }

  if (checkIsExercisePublished(assignment) && !checkIsExercisePublished(updatedAssignment)) {
    showErrorToast({
      message: i18n.t('exerciseArchivedText'),
      toastId: updatedAssignment._id,
    })
    redirectToHomePage()
    return
  }

  const isExamStopped = checkIsExamStopped({ assignment })
  const isExamStoppedAfterUpdate = checkIsExamStopped({ updatedAssignment })

  if (isExamStopped !== isExamStoppedAfterUpdate) {
    updateActiveAssignment(assignment, updatedAssignment)
  }

  updateActiveAssignment(assignment, updatedAssignment)

  resetCheatDetection()
})

export const onSolutionUpdate = createAssignmentSocketListener(async ({ assignment, exerciseId }) => {
  if (assignment?._id !== exerciseId) return

  currentExerciseModel.saveCredits()
  window.location.reload()
})

export const onStudentCheatUpdate = attach({
  source: { assignment: assignmentModel.$assignment, assignmentPopup: $assignmentPopup },
  mapParams: (params: StudentCheatUpdateSocketPayload, { assignment, assignmentPopup }) => ({
    assignment,
    assignmentPopup,
    ...params,
  }),
  effect: createEffect(async ({ assignment, assignmentPopup, exerciseId, studentId }: StudentCheatUpdateProps) => {
    if (!studentId || exerciseId !== assignment?._id) return

    const currentUpdatedExercise = await getCurrentAssignment(exerciseId)
    const examStatus = currentUpdatedExercise?.stats?.examStats.state

    updateActiveAssignment(assignment, currentUpdatedExercise)
    updateAssignment(currentUpdatedExercise)

    if (examStatus === ExamStatuses.STOPPED) {
      if (!assignmentPopup) setAssignmentPopup(AssignmentPopup.PAUSED_BY_TEACHER)
      assignmentPaused(currentUpdatedExercise._id)
      return
    }

    cheatDetected(null)
    assignmentUnpaused(currentUpdatedExercise._id)
    closeAssignmentPopup()
  }),
})
