import React, { forwardRef, ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type Props = {
  width: number
  height: number
  className?: string
  children: ReactNode
  style?: React.CSSProperties
}

export const PalletContainer = forwardRef<HTMLDivElement, Props>(
  ({ className, width, height, children, style }, ref) => {
    return (
      <div style={style} className={clsx(styles.PalletContainer, className)} ref={ref}>
        <div style={{ width, height, position: 'relative' }}>{children}</div>
      </div>
    )
  }
)
