import { Assignment, ExamStatuses } from '../../../ExerciseItem/types'

export const isExamEnabled = (state: Assignment | null) => state?.testModeEnabled ?? false

export const isExamPaused = (state: Assignment | null) =>
  !!(state?.testModeEnabled && state.stats?.examStats?.state === ExamStatuses.STOPPED)

export const isExamFinished = (state: Assignment | null) => {
  const settings = state?.settings
  const examStats = state?.stats?.examStats

  return (
    ((settings?.assessed && settings?.state === ExamStatuses.STOPPED) ||
      settings?.state === ExamStatuses.FINISHED ||
      examStats?.state === ExamStatuses.FINISHED ||
      examStats?.state === ExamStatuses.DISTRICT_LOCKED) &&
    examStats?.state !== ExamStatuses.ONGOING
  )
}

export const isPausedByTeacher = (state: Assignment | null, pausedAssignments: string[]) => {
  return !!pausedAssignments.find((id) => id === state?._id)
}
