import { createEvent, restore } from 'effector'

export class sideBarModel {
  public static readonly toggle = createEvent()
  public static readonly setIsOpened = createEvent<boolean>()
  public static readonly $isOpened = restore(this.setIsOpened, true).on(this.toggle, (isOpen) => !isOpen)

  public static readonly toggleProblemsList = createEvent()
  public static readonly setIsProblemsListOpened = createEvent<boolean>()
  public static readonly $isProblemsListOpened = restore(this.setIsProblemsListOpened, false).on(
    this.toggleProblemsList,
    (isOpen) => !isOpen
  )
  public static readonly closeProblemsList = () => {
    this.setIsProblemsListOpened(false)
  }
}
