import { AxiosRequestConfig, ResponseType } from 'axios'

export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum ContentType {
  JSON = 'application/json',
  FORM_ENCODED = 'application/x-www-form-urlencoded',
  FORM_DATA = 'multipart/form-data',
}

type RequestDriver = 'fetch' | 'axios'

type CommonConfig = {
  keepalive?: boolean
  driver?: RequestDriver
  protect?: boolean
  headers?: Record<string, string>
  responseType?: ResponseType
}

export type LikeAxiosConfig = {
  url?: string
  body?: any
  params?: Record<string, number | string | boolean | Array<string | number | boolean>>
  signal?: AxiosRequestConfig['signal']
  keepalive?: boolean
  headers?: Record<string, string>
}

export type MapperFn<Params> = (params: Params) => string | number | LikeAxiosConfig

type RequestCreatorSettings<P> = {
  url?: string
  data?: (params: P) => string | number | LikeAxiosConfig
} & CommonConfig

export type RequestCreatorProps<Params> = string | number | MapperFn<Params> | RequestCreatorSettings<Params>

export type RequestDescriptor<Params> = ({ url?: never; fn: MapperFn<Params> } | { url: string; fn?: never }) &
  CommonConfig

export type MinimumRequestConfig = {
  headers?: Record<string, string>
}
