export const NOT_STUDENT = 'userNotAStudentText'
export const NOT_TEACHER = 'userNotATeacherText'
export const CODE_NOT_FOUND = 'defaultCustomErrorText'

export const NO_ARCHIVED_EXERCISES = 'noArchivedExercisesText'
export const NO_AVAILABLE_EXERCISES = 'noAvailableExercisesText'

export const NETWORK_ERROR = 'networkErrorMessageText'

export const DRAW_BOARD_IMAGE_ERROR = 'unable to get image from draw board'
