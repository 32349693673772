import React from 'react'
import './styles.scss'
import clsx from 'clsx'

import Geogebra from './components/Geogebra'
import { GeogebraAppNames, GeogebraSettings } from './types'

type GeogebraAppletProps = {
  handleArrowNavigation?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  initialState: string
  geogebraSettings?: GeogebraSettings
  isDisabled: boolean
}

const GeogebraApplet = ({ handleArrowNavigation, initialState, geogebraSettings, isDisabled }: GeogebraAppletProps) => {
  //TODO why do we need here handleArrowNavigation let move it just to Problem component
  return (
    <div
      tabIndex={-1}
      aria-disabled={isDisabled}
      className={clsx('problem-draw-area', {
        'geogebra-applet-disabled': isDisabled,
      })}
      onKeyDown={handleArrowNavigation}
    >
      <div id='draw-area' className={clsx('problem-draw-area', 'default-geogebra')}>
        <Geogebra
          id='ggb-applet'
          appName={GeogebraAppNames.CLASSIC}
          scaleContainerClass='ggb-applet'
          showToolBar
          showAlgebraInput={false}
          useBrowserForJS
          showToolBarHelp={false}
          geogebraSettings={geogebraSettings}
          initialBase64AppletState={initialState}
          isDefaultType
          isFullWidth
        />
      </div>
    </div>
  )
}

export default GeogebraApplet
