import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export type ProgressBarProps = {
  progress: number
  variant?: 'primary' | 'yellow' | 'purple' | 'exam' | 'practice'
  rounded?: boolean
}

export const ProgressBar = ({ progress = 0, variant = 'primary', rounded }: ProgressBarProps) => {
  const mergedClassNames = clsx(styles.ProgressBar, {
    [styles[variant]]: variant,
    [styles.Rounded]: rounded,
  })

  return (
    <div className={clsx(styles.ProgressBarWrapper, { [styles.Rounded]: rounded })}>
      <div className={mergedClassNames} style={{ width: `${progress >= 100 ? 100 : progress}%` }} />
    </div>
  )
}
