import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

import ArrowControlLeft from '../icons/ArrowControlLeft'

type BackToExerciseProps = {
  onClick: () => void
  title: string
  isBigButton?: boolean
}

const BackToExercisesButton = ({ onClick, title, isBigButton }: BackToExerciseProps) => {
  return (
    <div className={clsx(styles.BackToExercisesButton, { [styles.BigButton]: isBigButton })} onClick={onClick}>
      <div className={styles.BackToExercisesIcon}>
        <ArrowControlLeft size={11.75} />
      </div>
      <span className={styles.BackToExercisesTitle}>{title}</span>
    </div>
  )
}

export default BackToExercisesButton
