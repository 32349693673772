import React from 'react'
import useMeasure from 'react-use-measure'
import { animated, useSpring, AnimatedProps } from '@react-spring/web'
import styles from './styles.module.scss'

import { PracticeTree } from '../../../types'
import { useActiveSubCategoryModel } from '../../../models/activeSubCategory'
import { PracticeDropdownNavItem } from './PracticeDropdownNavItem'

type PracticeNavigationContentBlocksProps = {
  practiceTree: PracticeTree[]
  isOpened: boolean
}

const PracticeNavigationContentBlocks = ({ practiceTree, isOpened }: PracticeNavigationContentBlocksProps) => {
  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const [ref, bounds] = useMeasure()

  const animationStyles = useSpring({
    from: { height: 0, marginTop: 0, visibility: 'hidden', display: 'none' },
    to: {
      height: isOpened ? bounds.height : 0,
      visibility: isOpened ? 'visible' : 'hidden',
      display: isOpened ? 'block' : 'none',
    },
  } as AnimatedProps<any>)

  return (
    <animated.div style={animationStyles}>
      <ul className={styles.SubcategoriesList} ref={ref}>
        {practiceTree.map((practiceTree) => (
          <PracticeDropdownNavItem
            key={practiceTree._id}
            id={practiceTree._id}
            title={practiceTree.nameTranslations}
            activeSubCategoryId={activeSubCategoryId}
          />
        ))}
      </ul>
    </animated.div>
  )
}

export default PracticeNavigationContentBlocks
