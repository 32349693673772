import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'

import Dialog from '../../ui/dialog'
import { Button } from '../../ui/button/Button'
import { UseTranslationTFunction } from '../../locales/i18n'
import { $detectedCheat } from './models/cheatDetected'
import { closeAssignmentPopup } from '../assignmentPopups/model'
import { navigateToAssignmentsList } from '../../helpers/exercises'

import { CheatType } from '../../api/types.cheats'

const titleMap: Record<CheatType, (t: UseTranslationTFunction) => string> = {
  [CheatType.LEAVING_BUTTON]: (t) => t('cheatDetection.notAllowedToLeave'),
  [CheatType.LEAVING_BROWSER_OR_TAB]: (t) => t('cheatDetection.notAllowedToLeave'),
  [CheatType.TEXT_REPLACEMENT]: (t) => t('cheatDetection.notAllowedToAutocomplete'),
}

export const CheatingDetectedPopup = () => {
  const { t } = useTranslation()
  const detectedCheat = useUnit($detectedCheat)

  const closeDialog = () => {
    closeAssignmentPopup()
    navigateToAssignmentsList()
  }

  return (
    <Dialog isOpen>
      <Dialog.Title text={titleMap[detectedCheat?.cheatType ?? CheatType.LEAVING_BUTTON](t)} />
      <Dialog.Content text={t('cheatDetection.wasThisMistake')} />
      <Dialog.Actions>
        <Button
          text={t('cheatDetection.exitAssignment')}
          variant='error'
          style={{ maxWidth: 185 }}
          onClick={closeDialog}
        />
      </Dialog.Actions>
    </Dialog>
  )
}
