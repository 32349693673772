export const HANDWRITING = 0
export const SINGLE_CHOICE = 1
export const MULTIPLE_CHOICE = 2
export const ORDERED = 3
export const BOXES = 5
export const PREFILLED_BOXES = 8
export const PREFILLED_CHOICES = 9
export const GEOGEBRA = 10

export default [
  HANDWRITING,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  ORDERED,
  BOXES,
  PREFILLED_BOXES,
  PREFILLED_CHOICES,
  GEOGEBRA,
]
