import React, { useContext, useEffect } from 'react'
import { useUnit } from 'effector-react'
import './styles.scss'
import { getChangedGrade } from '../../features/PracticeMode/PracticePageNavigation/helpers'
import { ChangeGradeActions } from '../../features/PracticeMode/types'
import GlobalCircleLoader from '../../ui/Loaders/GlobalCircleLoader'
import { usePracticeTheory } from '../../features/PracticeMode/models/practiceTheory'
import ScrollToUnsolvedAbilityButton from '../../features/PracticeMode/PracticePageNavigation/ScrollToUnsolvedAbilityButton/ScrollToUnsolvedAbilityButton'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { getStudentSettings } from '../../helpers/users/getUserInfo'
import { fetchSkillsTreeStatisticsFx, fetchSkillsTreeFx } from '../../features/practiceModeSkills/model/requests'
import { $practiceGrade, setPracticeGrade } from '../../features/PracticeMode/models/currentPracticeGrade'
import { setAvailableGrades } from '../../features/PracticeMode/models/availableGrades'
import usePracticeStore from '../../features/PracticeMode/models/practice'
import SkillsTreeRoot from '../../features/practiceModeSkills/SkillsTreeRoot'
import PracticeBody from '../../features/PracticeMode/PracticeBody/PracticeBody'
import { getPracticeCategories } from '../../features/PracticeMode/requests'
import { AnimatedLayout } from '../../features/PracticeMode/PracticePageNavigation/AnimatedLayout/AnimatedLayout'
import { PracticeIcons } from '../../features/PracticeMode/components/PracticeIcons/PracticeIcons'
import { PracticeClose } from '../../features/PracticeMode/components/PracticeClose/PracticeClose'
import { PracticeHeader } from '../../features/PracticeMode/components/PracticeHeader/PracticeHeader'
import { AVAILABLE_GRADES } from '../../features/PracticeMode/constants'
import { getSkillTheoryFx } from '../../features/practiceModeSkills/model/skillTheory'

const PracticePage = () => {
  const { state: userSettings, setSettings: setStudentSettings } = useContext(UserSettingsContext)
  const practiceGrade = useUnit($practiceGrade)
  const setCategories = usePracticeStore((state) => state.setCategories)
  const isPracticeTreeLoading = useUnit(fetchSkillsTreeFx.pending)
  const isTheoryLoading = usePracticeTheory((state) => state.isLoading)
  const isSkillsTheoryLoading = useUnit(getSkillTheoryFx.pending)
  const isLoading = isTheoryLoading || isSkillsTheoryLoading

  useEffect(() => {
    const availableGrades = !userSettings.practiceModeLockedGrades?.length
      ? AVAILABLE_GRADES
      : AVAILABLE_GRADES.filter((grade) => !userSettings.practiceModeLockedGrades.includes(grade))
    setAvailableGrades(availableGrades)

    // for case of returning to practice page after practice/exercise page
    if (practiceGrade >= 0 && availableGrades.includes(practiceGrade)) {
      return
    }

    if (!userSettings.grade) {
      setPracticeGrade(availableGrades[0])
      return
    }

    if (availableGrades.includes(userSettings.grade)) {
      setPracticeGrade(userSettings.grade)
      return
    }

    const closestPracticeGrade = getChangedGrade({
      type: ChangeGradeActions.DECREASE,
      currentGrade: userSettings.grade,
      newGrade: userSettings.grade - 1,
      filteredGrades: availableGrades,
    })

    setPracticeGrade(availableGrades.includes(closestPracticeGrade) ? closestPracticeGrade : availableGrades[0])
  }, [practiceGrade, userSettings.grade, userSettings.practiceModeLockedGrades])

  useEffect(() => {
    if (userSettings.practiceModeTreeId === null) return
    if (!userSettings.practiceModeTreeId) {
      getPracticeCategories().then((value) => {
        setCategories(value || [])
      })
      return
    }

    getStudentSettings().then((response) => {
      if (!response) return
      setStudentSettings(response)
    })

    fetchSkillsTreeFx(userSettings.practiceModeTreeId)
    fetchSkillsTreeStatisticsFx()
  }, [userSettings.practiceModeTreeId])

  return (
    <AnimatedLayout>
      <div className='practice-page-wrapper'>
        <div className='practice-page'>
          <PracticeIcons />
          <PracticeClose />
          <PracticeHeader treeId={userSettings.practiceModeTreeId} />
          {userSettings.practiceModeTreeId ? <SkillsTreeRoot /> : <PracticeBody />}

          <ScrollToUnsolvedAbilityButton />
          {(isLoading || isPracticeTreeLoading) && <GlobalCircleLoader />}
        </div>
      </div>
    </AnimatedLayout>
  )
}

export default PracticePage
