import i18next from 'i18next'

import { practiceLoadingModel } from '../models/practiceLoading'
import { createNewAttempt, getSubcategoryStatistic } from '../requests'
import { showErrorToast } from '../../../constants/toasts'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'
import { Ability } from '../types'
import usePracticeProblemsStore from '../models/practiceProblems'
import usePracticeStore from '../models/practice'
import { PracticeProblem } from '../../Problem/types'

type OpenAbilityCardProps = {
  ability: Ability
  nextAbility?: Ability
  onSuccess?: () => void
  onSaveData?: () => void
}

const useOpenAbilityCallback = () => {
  const { setId, setProblemsList, setCurrentProblemIndex, resetRightAnswersCount } = usePracticeProblemsStore()
  const { setNextAbilityInfo, setOpenedAbilityInfo, statistics, setStatistics, categories } = usePracticeStore()
  const openAbilityCard = async ({ ability, nextAbility, onSuccess, onSaveData }: OpenAbilityCardProps) => {
    practiceLoadingModel.setLoadingAbility(true)
    try {
      onSuccess?.()
      const response = await createNewAttempt(ability._id)
      setOpenedAbilityInfo(ability)
      setId(response._id)
      setProblemsList(response.problems.map((problem) => ({ ...problem, isSubmittedInCurrentSession: false })))
      setCurrentProblemIndex(0)
      onSaveData?.()

      if (nextAbility) {
        setNextAbilityInfo(nextAbility)

        if (!statistics[nextAbility._id]) {
          const currentCategory = categories.find((category) => {
            return category.subcategories.find((subcategory) => {
              return subcategory.abilities.find((ability) => ability._id === nextAbility._id)
            })
          })
          if (!currentCategory) {
            return
          }

          setStatistics(
            await Promise.all(
              currentCategory.subcategories.map((subcategory) => getSubcategoryStatistic(subcategory._id))
            )
          )
        }
      }
    } catch (error: any) {
      showErrorToast({
        message: error.response
          ? i18next.t(
              `${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`
            )
          : error.message,
        toastId: error.response ? error.response.data.code : error.message,
      })
    } finally {
      practiceLoadingModel.setLoadingAbility(false)
      resetRightAnswersCount()
    }
  }

  return { openAbilityCard }
}

export default useOpenAbilityCallback
