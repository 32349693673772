import { getUserId } from './getUserInfo'

export const getStudentStats = (problem) => {
  if (!problem) return
  return problem.studentStats?.find((stats) => stats.studentId === getUserId())
}

const isSolved = (problem) => {
  const status = getStudentStats(problem)
  return status ? status.stats.solved === 1 : false
}

export const checkIsUnsolvedSubProblem = (problem) => {
  return problem.subProblems.some((subProblem) => {
    return !isSolved(subProblem)
  })
}

export const findFirstUnsolvedProblemIndex = (problems) => {
  const problemIndex = problems.findIndex((problem) => {
    if (!problem.subProblems.length) {
      return !isSolved(problem)
    }

    const unsolved = checkIsUnsolvedSubProblem(problem)

    return unsolved === true
  })

  if (problemIndex === -1) {
    return { currentProblemIndex: 0, currentSubProblemIndex: 0 }
  }

  let subProblemIndex = findFirstUnsolvedSubproblemIndex(problems[problemIndex].subProblems)

  return { currentProblemIndex: problemIndex, currentSubProblemIndex: subProblemIndex }
}

export const findFirstUnsolvedSubproblemIndex = (subProblems) => {
  const index = subProblems.findIndex((subProblem) => {
    return !isSolved(subProblem)
  })

  return index === -1 ? 0 : index
}

const isHasAttempts = (problem) => {
  const stats = getStudentStats(problem)
  return !stats || !stats.stats ? false : stats.stats.attempt > 0
}

const isSubProblemHasNoAttempts = (problem) => {
  return problem.subProblems.some((subProblem) => {
    return !isHasAttempts(subProblem)
  })
}

const findFirstNotAnsweredSubProblemIndex = (subProblems) => {
  const index = subProblems.findIndex((subProblem) => {
    return !isHasAttempts(subProblem)
  })

  return index === -1 ? 0 : index
}

export const findFirstNotAnsweredProblemIndex = (problems) => {
  const problemIndex = problems.findIndex((problem) => {
    if (!problem.subProblems.length) {
      return !isHasAttempts(problem)
    }

    const notAnswered = isSubProblemHasNoAttempts(problem)
    return notAnswered === true
  })

  if (problemIndex === -1) {
    return { currentProblemIndex: 0, currentSubProblemIndex: 0 }
  }

  let subProblemIndex = findFirstNotAnsweredSubProblemIndex(problems[problemIndex].subProblems)

  return { currentProblemIndex: problemIndex, currentSubProblemIndex: subProblemIndex }
}
