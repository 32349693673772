import { checkIsSwedenLocale } from '../../helpers/userLocale'
import { LOCALES, UNITS_CATEGORIES } from './constants/common'
import { POWER_CUBE } from '../../constants/mathOperationConstants'

export const getGridRowsStyle = (selectedUnit: UNITS_CATEGORIES) => {
  if (selectedUnit === UNITS_CATEGORIES.VOLUME) {
    return '40px 40px'
  }

  if (selectedUnit === UNITS_CATEGORIES.TIME) {
    return '40px'
  }

  if (checkIsSwedenLocale()) {
    return '40px'
  }

  return '40px 40px'
}

export const numpadLocale = checkIsSwedenLocale() ? LOCALES.SWE : LOCALES.EN

export const createPowerCubeValue = (value: string) => {
  return `${value}${POWER_CUBE}`
}

export const createGridGap = () => ({
  value: '',
  symbol: '',
})
