export const MULTIPLE_COMMAND = /\\cdot/g
export const TIMES_COMMAND = /\\times/g
export const LARGE_COMMAND = /\\large/gi
export const DIVIDE_COMMAND = /\\div/g
export const QUAD_COMMAND = /\\q{1,2}uad/g
export const FRACTION_SIGN = /frac/g
export const SQUARE_ROOT = /\\sqrt/g
export const EXTRA_SQUARE_ROOT = /\\surd/g
export const DEGREE_SIGN = /\\degree/g
export const CARET_DEGREE_SIGN = /\^\\circ/g
export const GREATER_OR_EQUAL = /\\geq?/g
export const LESS_OR_EQUAL = /\\leq?/g
export const ANGLE_SIGN = /\\angle/g
export const PARALLEL_SIGN = /\\parallel/g
export const APPROXIMATE_COMMAND = /\\approx/g
export const BACK_SIMILAR_COMMAND = /\\backsim/g
export const SIMILAR_COMMAND = /\\sim/g
export const THICK_APPROXIMATE_COMMAND = /\\thickapprox/g
export const NOT_EQUALS_COMMAND = /\\neq/g
export const DOTS_COMMAND = /\\dots/g
export const POUNDS_COMMAND = /\\pounds/g
export const PI_COMMAND = /\\[Pp]i/g
export const ALPHA_COMMAND = /\\alpha/g
export const BETA_COMMAND = /\\beta/g
export const GAMMA_COMMAND = /\\gamma/g
export const THETA_COMMAND = /\\theta/g
export const LAMBDA_COMMAND = /\\lambda/g
export const COMMA_SEPARATOR_COMMAND = /\{,}/g
export const AND_COMMAND = /&/g
export const COS_COMMAND = /\\cos/g
export const SIN_COMMAND = /\\sin/g
export const TAN_COMMAND = /\\tan/g

export const BIG_COMMAND = /\\[Bb]ig{1,2}[lmr]?/g
export const LEFT_COMMAND = /\\left/g
export const MIDDLE_COMMAND = /\\middle/g
export const RIGHT_COMMAND = /\\right/g
export const THIN_SPACE_COMMAND = /\\thinspace/g
export const THICK_SPACE_COMMAND = /\\thickspace/g
export const MED_SPACE_COMMAND = /\\medspace/g
export const EN_SPACE_COMMAND = /\\enspace/g
export const NO_BREAK_SPACE_COMMAND = /\\nobreakspace/g
export const SPACE_COMMAND = /\\space/g
export const HUGE_COMMAND = /\\[Hh]uge/g
export const TINY_COMMAND = /\\tiny/g
export const FOOT_NOTE_SIZE_COMMAND = /\\footnotesiz/g
export const SMALL_COMMAND = /\\small/g
export const NORMAL_SIZE_COMMAND = /\\normalsize/g
export const PLUS_MINUS_COMMAND = /\\pm/g
export const VAR_DELTA_COMMAND = /\\varDelta/g
export const BIG_DELTA_COMMAND = /\\Delta/g
export const DELTA_COMMAND = /\\delta/g
export const COLOR_COMMAND = /\\color{[a-zA-Z\s]*}/g
export const BEGIN_END_COMMAND = /\\(begin|end){[a-zA-Z\s]*}/g
export const UNDERLINE_COMMAND = /\\underline/g
export const HSPACE_COMMAND = /\\hspace\{\d*\.?\d*[a-zA-Z]*}/g
export const CENTER_EQUATION_COMMAND = /{rcl}/g
export const POWER_OF_COMMAND = /\^/gm

export const GREATER_SYMBOL = '>'
