import type { AssignmentSettings } from '../../types'
import { ExamStatuses } from '../../types'
import i18next from 'i18next'

type ExerciseButtonTextFn = (settings: AssignmentSettings) => string

const EXERCISE_BUTTON_TEXT: Partial<Record<ExamStatuses | 'default', ExerciseButtonTextFn>> = {
  [ExamStatuses.NOT_STARTED]: () => i18next.t('exam.paused'),
  [ExamStatuses.ONGOING]: () => i18next.t('exam.started'),
  [ExamStatuses.STOPPED]: (settings) => (settings?.assessed ? i18next.t('exam.ended') : i18next.t('exam.paused')),
  [ExamStatuses.FINISHED]: () => i18next.t('exam.ended'),
  [ExamStatuses.DISTRICT_LOCKED]: () => i18next.t('exam.ended'),
  default: () => '',
} as const

export const getTextButtonFn = (status: ExamStatuses) => {
  return EXERCISE_BUTTON_TEXT[status] ?? EXERCISE_BUTTON_TEXT.default
}
