import { EMPTY_HTML_ELEMENT, FRACTION_FIRST_PART, FRACTION_SECOND_PART } from '../../constants/HTMLElementsConstants'

export const surroundFractionItemsWithBrackets = (answer) => {
  const surroundItem = (regexp) => {
    while ((match = regexp.openBracketRegexp.exec(surroundedAnswer)) !== null) {
      const indexToPasteOpenBracket = match.index + match[0].length
      const matchIfNeedToSurround = regexp.closeBracketRegexp.exec(surroundedAnswer)

      if (!matchIfNeedToSurround || matchIfNeedToSurround[0].length - match[0].length - 7 < 2) {
        continue
      }

      const indexToPasteCloseBracket = matchIfNeedToSurround.index + matchIfNeedToSurround[0].length - 7
      surroundedAnswer = [
        surroundedAnswer.slice(0, indexToPasteOpenBracket),
        '[',
        surroundedAnswer.slice(indexToPasteOpenBracket, indexToPasteCloseBracket),
        ']',
        surroundedAnswer.slice(indexToPasteCloseBracket),
      ].join('')
    }
  }

  const surroundNumeratorWithBracketsRegexp = {
    openBracketRegexp:
      /<span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>/g,
    closeBracketRegexp:
      /<span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>.*?<\/span>/g,
  }

  const surroundDenominatorWithBracketsRegexp = {
    openBracketRegexp:
      /<span placeholder=['|"]b['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item denominator['|"]>/g,
    closeBracketRegexp:
      /<span placeholder=['|"]b['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item denominator['|"]>.*?<\/span>/g,
  }

  let surroundedAnswer = answer
  let match
  surroundItem(surroundNumeratorWithBracketsRegexp)
  surroundItem(surroundDenominatorWithBracketsRegexp)

  return surroundedAnswer
}

export const cleanEmptyTags = (answer) => {
  return answer.replace(/<sup><\/sup>/g, '').replace(/<sup class='a-pow-b'>(&nbsp;|\s)?<\/sup>/g, '')
}

export const cleanEmptyString = (answer) => {
  const emptyStringRegex = /<span class=['|"]empty-element['|"]><\/span>/
  const match = emptyStringRegex.exec(answer)
  if (match && match[0].length === answer.length) {
    answer = ''
  }

  return answer
}

export const fullFractionHelper = (answer) => {
  const fractionWithoutDenominator =
    /<div class=['|"]fraction['|"]><span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>[\w+-/*=]*(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*<\/span><\/div>(<span class=['|"]empty-element['|"]><\/span>)?/g
  const fractionWithoutNumerator =
    /<div class=['|"]fraction['|"]><span placeholder=['|"]b['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item denominator['|"]>[\w+-/*=]*(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*<\/span><\/div>(<span class=['|"]empty-element['|"]><\/span>)?/g
  const fractionWithBrokenDenominator =
    /<div class=['|"]fraction['|"]><span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>[\w+-/*=]*(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*<span style=['|"]font-size: 2\.5em;['|"]>[\w+-/*=]+(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*<\/span><\/span><\/div>(<span class=['|"]empty-element['|"]><\/span>)?/g

  const match = fractionWithoutDenominator.exec(answer)
  if (match) {
    const partWithoutDenominator = answer.slice(match.index, match.index + match[0].length)
    const numeratorRegex =
      /<div class=['|"]fraction['|"]><span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>/
    const partWithoutNumerator = partWithoutDenominator.replace(numeratorRegex, '')
    const closeDivMatch = /<\/div>/.exec(partWithoutNumerator)
    const numeratorValue = partWithoutNumerator.slice(0, closeDivMatch.index)
    answer = [answer.slice(0, match.index), numeratorValue, answer.slice(match.index + match[0].length)].join('')
  }

  return answer.replace(fractionWithoutNumerator, '').replace(fractionWithBrokenDenominator, '')
}

const automaticallyMakeDigitSuperscripted = (answer) => {
  const superscriptDigitRegex = /[a-z]+[0-9]+/
  let transformedAnswer = answer
  let match
  while ((match = superscriptDigitRegex.exec(transformedAnswer)) !== null) {
    const superscriptPart = transformedAnswer.slice(match.index, match.index + match[0].length)
    const lettersPartMatch = /[a-z]+/.exec(superscriptPart)
    const lettersPart = superscriptPart.slice(
      lettersPartMatch.index,
      lettersPartMatch.index + lettersPartMatch[0].length
    )
    const digitsPart = superscriptPart.slice(lettersPartMatch.index + lettersPartMatch[0].length)
    transformedAnswer = [
      transformedAnswer.slice(0, match.index),
      lettersPart,
      '<sup>',
      digitsPart,
      '</sup>',
      EMPTY_HTML_ELEMENT,
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  return transformedAnswer
}

const automaticallySuperscriptWithCaretSign = (answer) => {
  const superscriptRegex = /[a-zA-Z0-9]+\^-?[0-9]+/
  let match
  while ((match = superscriptRegex.exec(answer)) !== null) {
    const superscriptPart = answer.slice(match.index, match.index + match[0].length)
    const beforeSuperscriptMatch = /[a-zA-Z0-9]+\^/.exec(superscriptPart)
    const beforeSuperscriptContent = superscriptPart.slice(
      beforeSuperscriptMatch.index,
      beforeSuperscriptMatch.index + beforeSuperscriptMatch[0].length - 1
    )
    const afterSuperscriptMatch = /\^-?[0-9]+/.exec(superscriptPart)
    const afterSuperscriptContent = superscriptPart.slice(
      afterSuperscriptMatch.index + 1,
      afterSuperscriptMatch.index + afterSuperscriptMatch[0].length
    )

    answer = [
      answer.slice(0, match.index),
      beforeSuperscriptContent,
      '<sup>',
      afterSuperscriptContent,
      '</sup>',
      EMPTY_HTML_ELEMENT,
      answer.slice(match.index + match[0].length),
    ].join('')
  }

  return answer
}

export const autoSuperscript = (answer) => {
  return automaticallySuperscriptWithCaretSign(automaticallyMakeDigitSuperscripted(answer))
}

export const autoCreateFractionWithSlash = (answer) => {
  const regex = /(([a-zA-Z]+|[0-9]+)|\([\w+-√/*\s(&nbsp;)]+\))\s*(&nbsp;)*\//
  const match = regex.exec(answer)
  if (!match) {
    return { performedAnswer: answer, needToSetCursor: false }
  }

  const fractionPart = answer.slice(match.index, match.index + match[0].length - 1)
  const performedAnswer = [
    answer.slice(0, match.index),
    FRACTION_FIRST_PART,
    fractionPart,
    FRACTION_SECOND_PART,
    EMPTY_HTML_ELEMENT,
    answer.slice(match.index + match[0].length),
  ].join('')

  return { performedAnswer, needToSetCursor: true }
}

export const performAnswerForNumpad = (answer) => {
  return autoCreateFractionWithSlash(autoSuperscript(fullFractionHelper(answer)))
}
export const performFractionWithSlashWithoutMathExpressions = (answer) => {
  const fractionRegexp = /\w+(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*\/\w+(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*/g
  let transformedAnswer = answer
  let match
  while ((match = fractionRegexp.exec(transformedAnswer)) !== null) {
    let { numeratorPart, denominatorPart } = findFraction(transformedAnswer, match)

    if (numeratorPart.length > 1) {
      numeratorPart = `[${numeratorPart}]`
    }

    if (denominatorPart.length > 1) {
      denominatorPart = `[${denominatorPart}]`
    }

    const firstFractionSymbol = transformedAnswer[match.index - 1]
    const lastFractionSymbol = transformedAnswer[match.index + match[0].length]
    const dontSurroundWithBrackets = firstFractionSymbol === '[' && lastFractionSymbol === ']'
    transformedAnswer = joinFractionsPart(
      numeratorPart,
      denominatorPart,
      transformedAnswer,
      match,
      dontSurroundWithBrackets
    )
  }

  return transformedAnswer
}

const findFraction = (expression, match) => {
  const foundedPart = expression.slice(match.index, match.index + match[0].length)
  const slashRegex = /\/(?!sup)/
  const matchSlash = slashRegex.exec(foundedPart)
  const numeratorPart = foundedPart.slice(0, matchSlash.index)
  const denominatorPart = foundedPart.slice(matchSlash.index + 1)

  return { numeratorPart, denominatorPart }
}

const joinFractionsPart = (numerator, denominator, expression, match, dontSurround) => {
  let fullFraction = `${numerator}/${denominator}`
  if (!dontSurround) {
    fullFraction = `[${fullFraction}]`
  }

  return [expression.slice(0, match.index), fullFraction, expression.slice(match.index + match[0].length)].join('')
}

const surroundFractionPart = (fractionPart) => {
  if (fractionPart.length < 2) {
    return fractionPart
  }

  const mathSymbolsRegexp = /[+-/*]/g
  const isMathSymbols = mathSymbolsRegexp.test(fractionPart)
  if (!isMathSymbols) {
    return `[${fractionPart}]`
  }

  if (fractionPart[fractionPart.length - 1] === ')' && fractionPart[0] === '(') {
    fractionPart = fractionPart.substring(1, fractionPart.length - 1)
    if (fractionPart.length > 1) {
      return `[${fractionPart}]`
    }
  }

  return null
}

export const performFractionWithoutTags = (answer) => {
  const fractionRegexp =
    /^[^<]\(?([\w+-/*]|(√[\w+\-*().,</sup>]*\s?))+(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*\)?\/\(?([\w+-/*]|(√[\w+\-*().,</sup>]*\s?))+(<sup>(\d+\+?-?\/?\*?)+<\/sup>)*\)?/gm
  let transformedAnswer = answer
  let match
  while ((match = fractionRegexp.exec(transformedAnswer)) !== null) {
    let { numeratorPart, denominatorPart } = findFraction(transformedAnswer, match)

    numeratorPart = surroundFractionPart(numeratorPart)
    denominatorPart = surroundFractionPart(denominatorPart)

    if (!numeratorPart || !denominatorPart) {
      continue
    }

    transformedAnswer = joinFractionsPart(numeratorPart, denominatorPart, transformedAnswer, match)
  }

  return transformedAnswer
}

export const performSquareRoot = (answer) => {
  const squareRootRegex = /√[\w+-/*=<>]+/
  let transformedAnswer = answer
  let match
  while ((match = squareRootRegex.exec(transformedAnswer)) !== null) {
    const squareRootPart = transformedAnswer.slice(match.index, match.index + match[0].length)
    const transformedSquareRootPart = ['√[', squareRootPart.substring(1, squareRootPart.length), ']'].join('')

    transformedAnswer = [
      transformedAnswer.slice(0, match.index),
      transformedSquareRootPart,
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  return transformedAnswer
}

export const cleanAnswerSpaces = (answer) => {
  return answer.replace(/&nbsp;|\s/g, '')
}

export const getUserAnswer = (answer) => {
  return Array.isArray(answer) ? [...answer] : [cleanAnswerSpaces(answer)]
}

export const transformSquareRoot = (answer) => {
  const squareRootRegex = /√\[[\w+-/*=<>]+\s?]/
  let transformedAnswer = answer
  let match
  while ((match = squareRootRegex.exec(transformedAnswer)) !== null) {
    const squareRootPart = transformedAnswer.slice(match.index, match.index + match[0].length)
    const transformedSquareRootPart = ['√', squareRootPart.substring(2, squareRootPart.length - 1), ' '].join('')

    transformedAnswer = [
      transformedAnswer.slice(0, match.index),
      transformedSquareRootPart,
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  return transformedAnswer
}

const transformPower = (expression) => {
  const powerRegex = /[A-Za-z0-9]+\^\[(-?[\dβασ∘πθ]+\+?-?\/?\*?)+]/g
  let transformedExpression = expression
  let match
  while ((match = powerRegex.exec(transformedExpression)) !== null) {
    const powPart = transformedExpression.slice(match.index, match.index + match[0].length)
    transformedExpression = [
      transformedExpression.slice(0, match.index),
      powPart.replace(/\^\[/g, '<sup>').replace(/]/g, '</sup>'),
      transformedExpression.slice(match.index + match[0].length),
    ].join('')
  }

  return transformedExpression
}

export const transformAnswer = (answerInput) => {
  let answer = Array.isArray(answerInput) ? answerInput[0] : answerInput

  answer = answer.replace(/×/g, '*')
  const numeratorPartString =
    "<div class='fraction'><span placeholder='a' tabindex=\"0\" inputmode=\"none\" class='fraction-item numerator'>"
  const denominatorPartString =
    '<span placeholder=\'b\' tabindex="0" inputmode="none" class=\'fraction-item denominator\'>'

  let transformedAnswer = answer
  const regexForInsertClosingSpans =
    /\[+([\wβασ∘πθ+\-*().,]*|(√[\wβασ∘πθ+\-*().,</sup>]*\s?)*|(<sup>[\wβασ∘πθ+\-*().,]+<\/sup>)*)*]?|\/[^sup]\[?([\wβασ∘πθ+\-*().,]*|(√[\wβασ∘πθ+\-*().,</sup>]*\s?)*|(<sup>[\wβασ∘πθ+\-*().,]+<\/sup>)*)]+/g
  const regexForInsertDenominatorPart = /\/[^sup]\[?([\wβασ∘πθ+\-*().,√</sup>]*|(√[\wβασ∘πθ+\-*().,</sup>]*\s?)*)]+/g
  const regexForInsertClosingDiv =
    /\[+([\wβασ∘πθ+\-*().,</sup>]|(√[\wβασ∘πθ+\-*().,</sup>]*\s?))*]?\/[^sup]\[?([\wβασ∘πθ+\-*().,</sup>]|(√[\wβασ∘πθ+\-*().,</sup>]*\s?))*]+/g
  const regexForCleanBrackets = /\[([\wβασ∘πθ+\-*().,</sup>]|(√[\wβασ∘πθ+\-*().,</sup>]*\s?))*]/g
  let match

  transformedAnswer = transformSquareRoot(transformedAnswer)
  transformedAnswer = transformPower(transformedAnswer)

  while ((match = regexForInsertClosingDiv.exec(transformedAnswer)) !== null) {
    transformedAnswer = [
      transformedAnswer.slice(0, match.index + match[0].length),
      '</div>',
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  while ((match = regexForInsertClosingSpans.exec(transformedAnswer)) !== null) {
    transformedAnswer = [
      transformedAnswer.slice(0, match.index + match[0].length),
      '</span>',
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  while ((match = regexForInsertDenominatorPart.exec(transformedAnswer)) !== null) {
    transformedAnswer = [
      transformedAnswer.slice(0, match.index),
      denominatorPartString,
      transformedAnswer.slice(match.index + 1, match.index + match[0].length - 1),
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  while ((match = regexForCleanBrackets.exec(transformedAnswer)) !== null) {
    transformedAnswer = [
      transformedAnswer.slice(0, match.index),
      transformedAnswer.slice(match.index + 1, match.index + match[0].length - 1),
      transformedAnswer.slice(match.index + match[0].length),
    ].join('')
  }

  transformedAnswer = transformedAnswer.replace(/\[+/g, numeratorPartString)

  if (/>$/.test(transformedAnswer)) {
    transformedAnswer += "<span class='empty-element'></span>"
  }

  return transformedAnswer
}

const checkIsFractionPartCompleted = (answer, firstPartRegex, fullPartRegex) => {
  let isCompleted = true
  let match
  while ((match = fullPartRegex.exec(answer)) !== null) {
    answer.slice(match.index)
    const matchFirstNumeratorPart = firstPartRegex.exec(answer)
    if (!matchFirstNumeratorPart || match[0].length - matchFirstNumeratorPart[0].length - 7 < 1) {
      isCompleted = false
      break
    }
  }

  return isCompleted
}

export const checkIsFractionCompleted = (answer) => {
  const firstPartFractionNumerator =
    /<span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>/g
  const fullFractionNumerator =
    /<span placeholder=['|"]a['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item numerator['|"]>.*?<\/span>/g

  const isNumeratorCompleted = checkIsFractionPartCompleted(answer, firstPartFractionNumerator, fullFractionNumerator)
  if (!isNumeratorCompleted) {
    return false
  }

  const firstPartFractionDenominator =
    /<span placeholder=['|"]b['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item denominator['|"]>/g
  const fullFractionDenominator =
    /<span placeholder=['|"]b['|"] tabindex=['|"]0['|"] inputmode=['|"]none['|"] class=['|"]fraction-item denominator['|"]>.*?<\/span>/g
  return checkIsFractionPartCompleted(answer, firstPartFractionDenominator, fullFractionDenominator)
}
