import { useEffect } from 'react'
import { transformAnswer } from '../../../helpers/exercises/answerProblemHelper'
import { useAssignmentCredentials } from '../../assignment/hooks/useAssignmentCredentials'
import { focusAndSetCaretAtEnd } from '../../../components/Problem/ProblemAnswer/ProblemAnswerInput/helpers'
import { currentProblemAnswerModel } from '../../Problem/models/answer'

export const usePreviousAnswer = (previousAnswer?: string | string[]) => {
  const credits = useAssignmentCredentials()

  useEffect(() => {
    currentProblemAnswerModel.setText(previousAnswer ? transformAnswer(previousAnswer) : '')
    focusAndSetCaretAtEnd()
  }, [previousAnswer, credits.problemId])
}
