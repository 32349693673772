import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const SpeakerIcon = ({ size = 20, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_417_1243)'>
        <path
          d='M17.3379 3.6709C19.1137 5.58979 21.5996 10.8024 17.3379 16.3014'
          stroke={fill}
          strokeWidth='0.645945'
          strokeLinecap='round'
        />
        <path
          d='M15.2754 5.56055C16.5929 6.99261 18.4373 10.733 15.2754 14.2386'
          stroke={fill}
          strokeWidth='0.968964'
          strokeLinecap='round'
        />
        <path
          d='M4.92627 6.48212L10.7025 1.74269C11.0188 1.48325 11.4943 1.70819 11.4943 2.11728V17.8648C11.4943 18.2722 11.0223 18.4976 10.7054 18.2418L4.93265 13.5791C4.84216 13.506 4.72852 13.468 4.61225 13.4715C3.68802 13.4992 1.95496 13.5301 1.18356 13.4658C0.38919 13.3996 0.274797 12.7437 0.318585 12.3883C0.321867 12.3619 0.324305 12.3362 0.324305 12.3095V8.22446C0.324305 6.91841 0.897113 6.59192 1.18356 6.59192H4.619C4.73105 6.59192 4.83973 6.5531 4.92627 6.48193V6.48212Z'
          fill={fill}
          stroke={fill}
          strokeWidth='0.645945'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_417_1243'>
          <rect width='20' height='20' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
