import { useUnit } from 'effector-react/effector-react.umd'
import { $initialAlternatives } from '../models/initialAlternatives'
import { useHintMyScriptModel } from '../../AnswerHints/model'
import { useChatMessages } from './useChatMessages'

export const useChatAlternatives = () => {
  const initialAlternatives = useUnit($initialAlternatives)
  const hintFeedback = useHintMyScriptModel((state) => state.hint?.text)
  const chatMessages = useChatMessages()

  if (chatMessages.length > 1) {
    return chatMessages.at(-1)?.options ?? []
  }

  if (hintFeedback) {
    return initialAlternatives?.options.hints ?? []
  }

  return initialAlternatives?.options.default ?? []
}
