import { MISTAKE, SUCCESS, EXAM_MODE, SUCCESS_FIRST_ATTEMPT } from '../../constants/answerStatusConstants'
import numberToOrdinal from '../numberToOrdinal'
import i18next from 'i18next'
import { nativeLanguageISOCodeMap } from '../../config/allLanguages'
import { SWEDISH } from '../../config/languages'

export const isMistakeAnswerStatus = (answerStatus) => {
  return answerStatus === MISTAKE
}

export const isSuccessAnswerStatus = (answerStatus) => {
  return answerStatus === SUCCESS
}

export const isSuccessFirstAttemptAnswerStatus = (answerStatus) => {
  return answerStatus === SUCCESS_FIRST_ATTEMPT
}

export const isExamModeAnswerStatus = (answerStatus) => {
  return answerStatus === EXAM_MODE
}

export const getAnswerResultStyle = (answerResultStatus) => {
  if (!answerResultStatus) {
    return ''
  }

  if (isMistakeAnswerStatus(answerResultStatus)) {
    return 'mistake'
  }

  if (isSuccessAnswerStatus(answerResultStatus)) {
    return 'success'
  }

  return 'success-first-attempt'
}

export const transformPreviousSelectedTypeAnswer = (answers) => {
  if (!answers || !answers.length) {
    return []
  }

  return answers.map((variant, index) => {
    return {
      id: index,
      variant: variant.replace(/\n/g, ''),
      textToSpeech:
        i18next.language === nativeLanguageISOCodeMap[SWEDISH]
          ? `${i18next.t('translationOption')} ${index + 1}, ${variant},`
          : `${numberToOrdinal(index + 1)} ${i18next.t('translationOption')}, ${variant},`,
    }
  })
}

export const getBoxStatusStyle = (status) => {
  const styleMap = {
    [SUCCESS]: 'success-box',
    [SUCCESS_FIRST_ATTEMPT]: 'success-first-attempt-box',
    [MISTAKE]: 'mistake-box',
    [EXAM_MODE]: 'exam-mode-box',
  }

  return styleMap[status] || ''
}

export const getSuccessOrUnsolvedStyle = (answerStatus) => {
  if (isSuccessFirstAttemptAnswerStatus(answerStatus) || isSuccessAnswerStatus(answerStatus)) {
    return 'success'
  }

  return 'unsolved'
}

export const checkIsProblemSolvedCorrectly = (answerStatus) => {
  return isSuccessFirstAttemptAnswerStatus(answerStatus) || isSuccessAnswerStatus(answerStatus)
}

export const moveMathExpressionToOneSide = (firstPart, secondPart) => {
  if (!firstPart) {
    return secondPart
  }

  if (!secondPart) {
    return firstPart
  }

  if (firstPart.length >= secondPart.length) {
    return [firstPart, '+(-1)*(', secondPart, ')'].join('')
  } else {
    return [secondPart, '+(-1)*(', firstPart, ')'].join('')
  }
}
