import React from 'react'
import { NativeImageProps } from '../../lib/types/HTMLElements.types'
import { createImageSrc } from './createImageSrc'
import { ImageProps } from './types'

export const Image = ({
  src,
  cdnSrc,
  format = 'webp',
  size = 'small',
  alt = '',
  ...props
}: ImageProps & NativeImageProps) => {
  return (
    <img
      alt={alt}
      src={createImageSrc({
        src,
        cdnSrc,
        format,
        size,
      })}
      {...props}
    />
  )
}
