import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ChatMessage } from '../../../MagmaChat/components/ChatMessage/ChatMessage'
import { SlideInRight } from '../../../../ui/SlideInRight/SlideInRight'

export const DrawingValidationSuccess = () => {
  const { t } = useTranslation()
  return (
    <SlideInRight className={styles.DrawingValidation}>
      <ChatMessage
        content={t('hints.greatWorkFeedback')}
        isOwnMessage={false}
        containerClassName={styles.DrawingValidationBubble}
        className={styles.DrawingValidationText}
        iconSize={60}
      />
    </SlideInRight>
  )
}
