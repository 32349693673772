import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

type EmptyAnswerPlaceholderProps = {
  text?: string
  className?: string
}

const EmptyAnswerPlaceholder = ({ text, className }: EmptyAnswerPlaceholderProps) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('my-script-text-container empty-answer', className)}>
      <span className='placeholder-text empty-text'>{text ?? t('myScriptPlaceholderText')}</span>
    </div>
  )
}

export default EmptyAnswerPlaceholder
