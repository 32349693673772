import React, { FocusEvent, KeyboardEvent, useCallback, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import { useTranslation } from 'react-i18next'

import { useCleanUpContentEditable } from '../../hooks/useCleanupContentEditable'
import { useAutoFocus } from '../../../../../../hooks/useAutoFocus'

import { AnswerType } from '../../../../../../features/Problem/types.answer'
import { DeviceOS, getOS } from '../../../../../../lib/helpers/getOS'
import { InputType } from '../../../../../../features/Problem/models/inputType'
import { MyScriptMathCharacterTypes } from '../../../../../../constants/myScriptMathCharacterTypes'
import { cleanupContentEditableMathPower } from '../../../../../../features/Problem/problem.answer.helpers'
import { useUnit } from 'effector-react'
import { $focusedInputType, FocusedInput } from '../../../../../../features/Problem/models/initialInputFocus'
import { drawBoardModel } from '../../../../../../features/DrawBoard/model'
import { focusAndSetCaretAtEnd } from '../../helpers'
import { useAssignmentCredentials } from '../../../../../../features/assignment/hooks/useAssignmentCredentials'
import { currentProblemAnswerModel } from '../../../../../../features/Problem/models/answer'
import { MISTAKE } from '../../../../../../constants/answerStatusConstants'
import { CONTENT_EDITABLE_TEST_ID } from '../../../../../../config/dataTestIDs'
import { KeyboardKeys } from '../../../../../../constants/keyboardKeys'

export enum CaretPosition {
  END = 'END',
  START = 'START',
}

type AnswerContentEditableProps = {
  answerType?: AnswerType
  autoFocus?: boolean
  characterType: MyScriptMathCharacterTypes
  html: string
  inputType: InputType
  isDisabled: boolean
  onChange: (event: ContentEditableEvent) => void
  onFocus: () => void
  onTabPress: () => void
  className?: string
  placeholder?: string
  initialCaretPosition?: CaretPosition
}

const ALLOWED_KEYS = ['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Enter']
export const CONTENT_EDITABLE_ID = 'answer-input'
const isAppleDevice = [DeviceOS.iOS, DeviceOS.macOS].includes(getOS())

export const AnswerContentEditable = ({
  answerType,
  characterType,
  html,
  inputType,
  isDisabled,
  onChange,
  onFocus,
  onTabPress,
  className,
  placeholder,
  initialCaretPosition = CaretPosition.START,
}: AnswerContentEditableProps) => {
  const { t } = useTranslation()
  const credits = useAssignmentCredentials()
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const contentEditableRef = useRef(null)
  const focusedInput = useUnit($focusedInputType)
  const placeholderText =
    placeholder || (inputType === InputType.MY_SCRIPT ? t('answerInputPlaceholderText') : undefined)

  const shouldAutoFocusGetter = useCallback(() => {
    if (drawBoardModel.ref.inputFocused) return false
    return focusedInput === FocusedInput.TEXT_INPUT
  }, [focusedInput])

  useAutoFocus(shouldAutoFocusGetter, contentEditableRef)
  useCleanUpContentEditable(contentEditableRef)

  useEffect(() => {
    if (initialCaretPosition === CaretPosition.END && answerStatus === MISTAKE) focusAndSetCaretAtEnd()
  }, [initialCaretPosition, answerStatus, credits.problemId])

  const handleTabPress = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      onTabPress()
    }
  }

  const handleBlur = (event: FocusEvent) => {
    event.preventDefault()
  }

  const handleRestrictedKeys = (event: KeyboardEvent) => {
    if (event.key === KeyboardKeys.BACKSPACE)
      return cleanupContentEditableMathPower((event.target as HTMLDivElement).innerHTML)
    if (ALLOWED_KEYS.includes(event.key)) return

    const isHandwritingAnswerType = answerType === AnswerType.HANDWRITING
    const isAcceptOnlyNumbersWhole =
      isHandwritingAnswerType && characterType === MyScriptMathCharacterTypes.NUMBERS_WHOLE
    const isAcceptOnlyNumbers = isHandwritingAnswerType && characterType === MyScriptMathCharacterTypes.NUMBERS

    const digitRegex = /\D/g
    const scandicCharactersRegex = /[a-zA-Zåäö]+/g

    if (isAcceptOnlyNumbersWhole && event.key.match(digitRegex)) {
      event.preventDefault()
    }

    if (isAcceptOnlyNumbers && event.key.match(scandicCharactersRegex)) {
      event.preventDefault()
    }
  }

  return (
    <ContentEditable
      innerRef={contentEditableRef}
      id={CONTENT_EDITABLE_ID}
      data-testid={CONTENT_EDITABLE_TEST_ID}
      html={html}
      disabled={isDisabled}
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={handleTabPress}
      onKeyDown={handleRestrictedKeys}
      onBlur={handleBlur}
      inputMode='none'
      placeholder={placeholderText}
      className={clsx(className, {
        [styles.ProblemAnswerInput]: !isAppleDevice,
        [styles.ProblemAnswerInputAppleDevice]: isAppleDevice,
      })}
    />
  )
}
