import { attach, createEffect, createEvent, restore } from 'effector'
import i18next from 'i18next'
import { AxiosError } from 'axios'

import { mergeAssignmentStatistics } from '../../../helpers/exercises/exercisesHelper'
import { redirectToHomePage } from '../../../helpers'
import { NETWORK_ERROR_MESSAGE } from '../../../constants/defaults'
import { showErrorToast } from '../../../constants/toasts'
import { getAssignmentsList, getAssignmentById, getAssignmentStatistics } from '../../assignments/requests'

const INITIAL_PAGE = 1

export const resetPage = createEvent()

const setPage = createEvent<number>()
const nextPage = createEvent()
const prevPage = createEvent()

export const $page = restore(setPage, INITIAL_PAGE)
  .on(nextPage, (page) => page + 1)
  .on(prevPage, (page) => page - 1)
  .reset(resetPage)

export const fetchAssignmentsPageFx = attach({
  source: $page,
  effect: createEffect(getAssignmentsList),
})

fetchAssignmentsPageFx.doneData.watch(() => nextPage())

export const fetchAssignmentByIdFx = createEffect(getAssignmentById)
export const fetchAssignmentStatisticsFx = createEffect(getAssignmentStatistics)

function isAxiosError(error: unknown): error is AxiosError<any, any> {
  return (error as AxiosError<any, any>).isAxiosError !== undefined
}

export const fetchAssignmentWithStatsFx = createEffect(async (assignmentId: string) => {
  try {
    const [assignmentResponse, statsResponse] = await Promise.all([
      await getAssignmentById(assignmentId),
      await getAssignmentStatistics(assignmentId),
    ])

    return mergeAssignmentStatistics(assignmentResponse, statsResponse)
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response?.status === 404 || error.response?.status === 400) {
        redirectToHomePage()
        return
      }

      if (error.message === NETWORK_ERROR_MESSAGE) {
        showErrorToast({ message: i18next.t('networkErrorText'), toastId: NETWORK_ERROR_MESSAGE })
      }
    }
  }
})
