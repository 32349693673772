import React, { useContext } from 'react'
import styles from './styles.module.scss'
import { NumpadButton } from '../NumpadButton'
import { CharTypeNumpadContext } from '../../index'
import { NumpadSymbol } from '../../constants/symbols'

type NumpadSymbolsProps = {
  symbols: NumpadSymbol[]
}

export const NumpadSymbols = ({ symbols }: NumpadSymbolsProps) => {
  const { onClick, onSpecialSymbolPress } = useContext(CharTypeNumpadContext)
  return (
    <div className={styles.NumpadGrid}>
      {symbols.map(({ value, symbol, styles, isSpecialSymbol }, index) =>
        symbol ? (
          <NumpadButton
            key={index}
            className={styles}
            onClick={() => {
              if (isSpecialSymbol) {
                return onSpecialSymbolPress?.(value)
              }

              onClick?.(value)
            }}
          >
            {symbol}
          </NumpadButton>
        ) : (
          <div key={index} />
        )
      )}
    </div>
  )
}
