import React, { PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import SlideProblemDescriptionButton from '../../../ui/buttons/SlideProblemDescriptionButton'
import { sideBarModel } from '../../Problem/Sidebar/model'
import { useUnit } from 'effector-react'

type SidebarProps = {
  isSlideButtonHidden?: boolean
}

const Sidebar: React.FC<PropsWithChildren<SidebarProps>> = ({ isSlideButtonHidden, children }) => {
  const isSidebarOpened = useUnit(sideBarModel.$isOpened)
  return (
    <div className={clsx(styles.ProblemSidebarContainer, { [styles.Show]: isSidebarOpened })} tabIndex={-1}>
      {!isSlideButtonHidden && (
        <SlideProblemDescriptionButton isRight={!isSidebarOpened} onClick={sideBarModel.toggle} />
      )}

      {children}
    </div>
  )
}

export default Sidebar
