import React from 'react'
import { inputTypeMyScript } from '../../../../../Problem/helpers'
import MyScriptView from '../../../../../MyScript/MyScriptView'
import Keyboards from '../../../../../../components/Problem/Keyboards'
import { backspaceClick, onTabPress } from '../../../../../Problem/problem.answer.helpers'
import { ProblemAnswerInput } from '../../../../../../components/Problem/ProblemAnswer/ProblemAnswerInput'
import styles from './styles.module.scss'
import ToolContent from '../../../../../../components/Problem/ProblemAnswer/ToolContent'
import { currentProblemAnswerModel } from '../../../../../Problem/models/answer'
import AnswerFeedback from '../../../../../../ui/feedback/AnswerFeedback'
import ProblemAnswerButton from '../../../../../Problem/ProblemAnswerButton/ProblemAnswerButton'
import { getUserType } from '../../../../../../helpers/users/getUserInfo'
import { PracticeHandwritingProps } from '../types'

export const PracticeHandwritingRegular = ({
  inputType,
  answerStatus,
  currentProblem,
  onSubmitClick,
  onAnswerChangeStart,
  setIsRecognitionPending,
  onNumpadButtonPress,
  onSpecialSymbolPress,
  onContentEditableChange,
  onFocusInput,
  exerciseMode,
  answerStyle,
  answerText,
  feedbackText,
  submitButtonText,
  isRecognitionPending,
}: PracticeHandwritingProps) => {
  return (
    <>
      {answerStatus ? null : inputTypeMyScript(inputType.value) ? (
        <MyScriptView
          showForceDrawing={false}
          characterType={currentProblem?.characterType}
          isDisabled={!!answerStatus}
          onPressIn={onAnswerChangeStart}
          onRecognition={setIsRecognitionPending}
        />
      ) : (
        <Keyboards
          onPress={onNumpadButtonPress}
          onSpecialSymbolPress={onSpecialSymbolPress}
          onBackspacePress={backspaceClick}
          isButtonsDisabled={false}
          characterType={currentProblem?.characterType}
        />
      )}
      <ProblemAnswerInput
        characterType={currentProblem?.characterType}
        exerciseMode={exerciseMode}
        inputType={inputType.value}
        isDisabled={!!answerStatus}
        isPracticeMode
        isUnitRequired={false}
        onBackspacePress={backspaceClick}
        onChange={onContentEditableChange}
        onFocus={onFocusInput}
        onTabPress={onTabPress}
        showAnswerPreview={false}
        showAnswerPreviewExamMode={false}
        styleVariant={answerStyle}
        value={answerText}
      />

      <div className={styles.HandwritingAnswerButtons}>
        {!answerStatus ? (
          <ToolContent
            inputType={inputType.value}
            changeInputType={inputType.toggle}
            clearAnswer={currentProblemAnswerModel.clearText}
          />
        ) : (
          <AnswerFeedback text={feedbackText} style={answerStyle} />
        )}
        <div className={styles.SubmitAnswerButton}>
          <ProblemAnswerButton
            onClick={onSubmitClick}
            text={submitButtonText}
            style={answerStyle}
            exerciseMode={exerciseMode}
            disabled={!answerText || isRecognitionPending}
            userType={getUserType()}
          />
        </div>
      </div>
    </>
  )
}
