import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'

import Dialog from '../../../../ui/dialog'
import { Button } from '../../../../ui/button/Button'
import { closeAssignmentPopup } from '../../model'
import { navigateToAssignmentsList } from '../../../../helpers/exercises'
import { cheatDetected } from '../../../CheatDetection/models/cheatDetected'
import { sideBarModel } from '../../../Problem/Sidebar/model'
import { $isCheatingDetectionEnabled } from '../../../CheatDetection/models/isCheatingDetectionEnabled'
import { $exam } from '../../../Problem/models/exam/exam'

import { CheatType } from '../../../../api/types.cheats'

export const AssignmentFinishedPopup = () => {
  const { t } = useTranslation()
  const isCheatDetectionEnabled = useUnit($isCheatingDetectionEnabled)
  const exam = useUnit($exam)

  const leaveAssignment = () => {
    if (!exam.isFinished) {
      // Used for statistics, not for marking as cheat. It should work even with cheatDetection disabled.
      cheatDetected(CheatType.LEAVING_BUTTON)
    }

    closeAssignmentPopup()
    sideBarModel.setIsProblemsListOpened(true)
    navigateToAssignmentsList()
  }

  const openProblemsList = () => {
    sideBarModel.setIsProblemsListOpened(true)
    closeAssignmentPopup()
  }

  if (isCheatDetectionEnabled) {
    return (
      <Dialog isOpen onExitIconClick={closeAssignmentPopup} onClose={closeAssignmentPopup}>
        <Dialog.Title text={t('cheatDetection.lastQuestion')} />
        <Dialog.Content text={t('cheatDetection.cannotGoBackIn')} />
        <Dialog.Actions>
          <Button text={t('cheatDetection.exitAssignment')} variant='error' onClick={leaveAssignment} />
          <Button text={t('cheatDetection.stay')} variant='success' onClick={() => closeAssignmentPopup()} />
        </Dialog.Actions>
      </Dialog>
    )
  }

  return (
    <Dialog isOpen onExitIconClick={closeAssignmentPopup} onClose={closeAssignmentPopup}>
      <Dialog.Title text={t('endExerciseCongratsHeading')} />
      <Dialog.Content text={t('endExerciseCongratsText')} />
      <Dialog.Actions>
        <Button text={t('endExerciseBackText')} variant='outlined' onClick={openProblemsList} />
        <Button text={t('endExerciseButtonText')} variant='success' onClick={leaveAssignment} />
      </Dialog.Actions>
    </Dialog>
  )
}
