import React from 'react'

type SupProps = {
  value: string
  supValue: string
}

const Sup = ({ value, supValue }: SupProps) => {
  return (
    <span>
      {value}
      <sup>{supValue}</sup>
    </span>
  )
}

export default Sup
