import { CONTENT_EDITABLE_ID } from '../../components/Problem/ProblemAnswer/ProblemAnswerInput/components/AnswerContentEditable'
import { MutableRefObject } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'

type FocusAnswerInputProps = {
  timeoutMs?: number
  drawBoardRef?: MutableRefObject<BoardController | null>
}
export const focusAnswerInput = ({ timeoutMs = 0, drawBoardRef }: FocusAnswerInputProps) => {
  setTimeout(() => {
    if (drawBoardRef?.current?.spreadsheetController) {
      drawBoardRef.current.spreadsheetController.blur()
    }

    const element = document.querySelector(`#${CONTENT_EDITABLE_ID}`) as HTMLInputElement
    if (element) {
      element.focus()
    }
  }, timeoutMs)
}
