import {
  addAssignment,
  fetchMultipleAssignmentStatisticsFx,
  removeAssignment,
  resetAssignments,
  setExerciseError,
  updateAssignment,
} from '../../features/assignmentsPagination/model/assignments'
import {
  fetchAssignmentByIdFx,
  fetchAssignmentsPageFx,
  resetPage,
} from '../../features/assignmentsPagination/model/request'
import { closeAssignmentPopup } from '../../features/assignmentPopups/model'
import { ExamStatuses } from '../../features/ExerciseItem/types'
import { assignmentPaused, assignmentUnpaused } from '../../features/CheatDetection/models/pausedAssignments'
import { cheatDetected } from '../../features/CheatDetection/models/cheatDetected'
import { NETWORK_ERROR } from '../../constants/customErrorMessages'
import { getCurrentAssignment } from '../../features/assignments/requests'
import { createAssignmentSocketListener } from '../../helpers/exercises/createAssignmentSocketListener'

export const exerciseUpdated = createAssignmentSocketListener(async ({ assignment, exerciseId }) => {
  try {
    if (!assignment) {
      const updatedAssignment = await fetchAssignmentByIdFx(exerciseId)
      if (updatedAssignment.published) addAssignment(updatedAssignment)
      return
    }

    const updatedAssignment = await fetchAssignmentByIdFx(exerciseId)
    if (!updatedAssignment) return

    const isUnpublished = !updatedAssignment.published || updatedAssignment.archived
    if (isUnpublished) {
      removeAssignment(exerciseId)
      return
    }

    updateAssignment(updatedAssignment)
  } catch (error) {
    setExerciseError(NETWORK_ERROR)
  }
})

export const statisticsUpdated = createAssignmentSocketListener(async ({ assignment, exerciseId }) => {
  try {
    if (!assignment) return

    const updatedAssignment = await fetchAssignmentByIdFx(exerciseId)
    if (!updatedAssignment) return

    const isUnpublished = !updatedAssignment.published || updatedAssignment.archived
    if (isUnpublished) {
      removeAssignment(exerciseId)
      return
    }

    updateAssignment(updatedAssignment)
  } catch (error) {
    setExerciseError(NETWORK_ERROR)
  }
})

export const studentUpdated = createAssignmentSocketListener(async ({ exerciseId }) => {
  const currentUpdatedExercise = await getCurrentAssignment(exerciseId)
  const examStatus = currentUpdatedExercise?.stats?.examStats.state

  updateAssignment(currentUpdatedExercise)
  closeAssignmentPopup()

  if (examStatus === ExamStatuses.STOPPED) {
    assignmentPaused(currentUpdatedExercise._id)
    return
  }

  cheatDetected(null)
  assignmentUnpaused(currentUpdatedExercise._id)
})

export const refetchAssignments = async () => {
  resetAssignments()
  resetPage()
  const response = await fetchAssignmentsPageFx()
  const assignments = response._embedded.exercises
  await fetchMultipleAssignmentStatisticsFx(assignments)
}
