import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

import { TEACHER } from '../../../constants/userTypes'
import { drawBoardModel } from '../../DrawBoard/model'
import { ExerciseMode } from '../models/exerciseMode'
import { UIAnswerStatusButtonColor, UIExerciseModeButtonColor } from '../problem.style.mode.helpers'
import { Button, BUTTON_COLOR, SIZE } from '@magmamath/ui'
import { KeyboardKeys } from '../../../constants/keyboardKeys'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../assignment/model/assignment.model'

interface ProblemAnswerButtonProps {
  onClick: () => void
  text: string
  style?: string
  disabled?: boolean
  userType: number
  animation?: boolean
  specialButtonText?: string
  examStopped?: boolean
  withMargin?: boolean
  assignmentType?: number
  exerciseMode: ExerciseMode
  classNames?: {
    button?: string
    content?: string
  }
}

const ProblemAnswerButton: React.FC<ProblemAnswerButtonProps> = ({
  onClick,
  text,
  specialButtonText,
  style = '',
  examStopped,
  disabled = false,
  userType,
  exerciseMode,
  classNames,
}) => {
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isButtonDisabled = (isExamMode && examStopped && userType !== TEACHER) || disabled || isAnswerPending
  const buttonText: string = specialButtonText || text

  const buttonColor =
    (style
      ? UIAnswerStatusButtonColor[style as keyof typeof UIAnswerStatusButtonColor]
      : UIExerciseModeButtonColor[exerciseMode]) || BUTTON_COLOR.BLUE

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (drawBoardModel.ref.inputFocused) return

      if (event.key === KeyboardKeys.ENTER && isButtonDisabled) {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [isButtonDisabled])

  return (
    <Button
      onClick={onClick}
      size={SIZE.LARGE}
      color={buttonColor}
      isLoading={isAnswerPending}
      disabled={isButtonDisabled}
      classes={{
        button: clsx(styles.ProblemAnswerButton, classNames?.button, {
          [styles[`ProblemAnswerButton-${buttonColor}`]]: !!buttonColor && exerciseMode !== ExerciseMode.PRACTICE,
        }),
        content: clsx(styles.ProblemAnswerButtonContent, classNames?.content),
      }}
    >
      {buttonText}
    </Button>
  )
}

export default ProblemAnswerButton
