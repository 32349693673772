import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import { Category, PracticeStatistics } from '../types'
import SubcategoriesBlock from '../SubcategoriesBlock/SubcategoriesBlock'
import { filterSubcategoriesByGrade, getSolvedCategoryPercent } from '../practice.helpers'
import usePracticeStore from '../models/practice'
import { useUnit } from 'effector-react'
import { $practiceGrade } from '../models/currentPracticeGrade'
import ContentHeader from '../ContentHeader/ContentHeader'

type PracticeProps = {
  category: Category
  statistics: PracticeStatistics
  scrollArea: HTMLDivElement | null
}

const PracticeCategory = ({ category, statistics, scrollArea }: PracticeProps) => {
  const { currentCategoryId } = usePracticeStore()
  const practiceGrade = useUnit($practiceGrade)

  const filteredSubcategories = useMemo(
    () => (!!category?.subcategories?.length ? filterSubcategoriesByGrade(category.subcategories, practiceGrade) : []),
    [category?.subcategories, practiceGrade]
  )

  const isCurrentCategoryActive = category._id === currentCategoryId
  const solvedPercent = getSolvedCategoryPercent(filteredSubcategories, practiceGrade, statistics)
  return (
    <div className={styles.PracticeContentContainer}>
      <ContentHeader
        isActive={isCurrentCategoryActive}
        title={category.primaryName}
        grade={practiceGrade}
        solvedPercent={solvedPercent}
      />
      {!!filteredSubcategories.length && (
        <div className={styles.SubcategoriesBlockContainer} id={category._id}>
          {filteredSubcategories.map((subcategory) => {
            return (
              <SubcategoriesBlock
                blockName={subcategory.primaryName}
                blockId={subcategory._id}
                abilities={subcategory.abilities}
                statistics={statistics}
                scrollArea={scrollArea}
                key={subcategory._id}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default PracticeCategory
