import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'
import { PracticeDrawingHistory, PracticeTheory } from '../../../api/types.practice'

type PracticeTheoryModel = {
  theory: PracticeTheory | null
  drawingHistory: PracticeDrawingHistory | null
  isLoading: boolean
}

const initialState: PracticeTheoryModel = {
  theory: null,
  drawingHistory: null,
  isLoading: false,
}

export const usePracticeTheory = create(() => initialState)

const setTheory = addEvent(usePracticeTheory, (theory: PracticeTheory | null) => ({
  theory,
}))

const setDrawingHistory = addEvent(usePracticeTheory, (drawingHistory: PracticeDrawingHistory | null) => ({
  drawingHistory,
}))

const setIsLoading = addEvent(usePracticeTheory, (isLoading: boolean) => ({
  isLoading,
}))

export const practiceTheoryModel = {
  setTheory,
  setDrawingHistory,
  setIsLoading,
}
