import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import './animation.css'
import styles from './styles.module.scss'

const ENTRANCE_ANIMATIONS = ['entrance_animation', 'animate__animated', 'animate__slideInRight']

type SlideInRightProps = {
  shouldAnimate?: boolean
  isAbsolute?: boolean
  className?: string
}

export const SlideInRight = ({
  shouldAnimate = true,
  isAbsolute = false,
  className,
  children,
}: PropsWithChildren<SlideInRightProps>) => {
  return (
    <div className={clsx(isAbsolute && styles.BottomRight, shouldAnimate && ENTRANCE_ANIMATIONS, className)}>
      {children}
    </div>
  )
}
