import { ApiManager } from '../lib/apiManager/ApiManager'
import { apiConfig } from '../config/api'
import { getUserLocale } from '../helpers/locale'
import { LocalStorageKeys } from './localStorage/keys'
import { AppLocale } from '../locales/i18n.config'

export const apiManager = new ApiManager(apiConfig.apiUrl, {
  requestMiddleware: (config) => {
    if (!config.headers) config.headers = {}
    config.headers['Accept-Language'] = getUserLocale().header ?? AppLocale.EN
    return config
  },
  tokenSettings: {
    saveKey: LocalStorageKeys.TOKEN,
    accessLifetime: Infinity,
    transitionToNew: async (currentToken) => {
      if (currentToken) {
        if (currentToken.refresh) return currentToken
        /**
         * Here should be request to exchange old token to a new one
         */
        return currentToken
      }
      const oldToken = localStorage.getItem(LocalStorageKeys.TOKEN_OLD)
      if (!oldToken) return null
      return {
        access: oldToken,
        refresh: '',
        startTime: Date.now(),
      }
    },
    refresher: async (currentToken) => {
      //request to backend to refresh token
      return currentToken
    },
  },
})

//TODO: USED FOR SOCKET.IO, need to rework it in order to pass token after init happend (so after refreshing as well)
export const getToken = () => {
  const res = localStorage.getItem(LocalStorageKeys.TOKEN)
  if (!res) return
  const parsed = JSON.parse(res)
  if (!parsed) return
  return parsed.access.replace('JWT ', '')
}
