import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef } from 'react'
import styles from './joinClass.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'
import { Typography } from '@magmamath/ui'

import { ExitIcon } from '../../ui/icons/Exit/ExitIcon'
import {
  $formJoinClass,
  $isJoinClassFormFilled,
  $isJoinClassOpen,
  type JoinClassInputBoxKeys,
  joinClassSubmitted,
  setFormJoinClass,
  setIsJoinClassOpen,
  updateJoinClassField,
} from './model/model'
import { KeyboardKeys } from '../../constants/keyboardKeys'

export const JoinClass = () => {
  const { t } = useTranslation()
  const formRef = useRef<HTMLFormElement | null>(null)

  const formJoinClass = useUnit($formJoinClass)
  const isJoinClassOpen = useUnit($isJoinClassOpen)
  const isFormFilled = useUnit($isJoinClassFormFilled)

  const focusInput = (index: number) => {
    if (!formRef.current) return
    const inputElements = Array.from(formRef.current.children) as HTMLInputElement[]

    const inputElement = inputElements[index]
    if (!inputElement) return

    inputElement.focus()

    // Set caret position after a short delay to ensure element is fully rendered
    setTimeout(() => {
      const length = inputElement.value.length
      inputElement.setSelectionRange(length, length)
    })
  }

  const handleSubmitForm = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    joinClassSubmitted()
    setIsJoinClassOpen(false)
  }

  const handleUpdateForm = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target
    if (value.length > 1) return focusInput(index + 1)

    updateJoinClassField({ name, value })

    const shouldFocusNextInput = formRef.current && value.length === 1 && index < formRef.current.length - 1
    if (shouldFocusNextInput) focusInput(index + 1)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const inputKey = (event.target as HTMLInputElement).name as JoinClassInputBoxKeys

    if (event.key === KeyboardKeys.BACKSPACE) {
      event.preventDefault()

      const updatedForm = { ...formJoinClass }
      updatedForm[inputKey] = ''
      setFormJoinClass(updatedForm)

      if (index > 0) focusInput(index - 1)
      return
    }

    if (event.key === KeyboardKeys.ARROW_LEFT && index > 0) focusInput(index - 1)
    if (event.key === KeyboardKeys.ARROW_RIGHT && index < Object.keys(formJoinClass).length - 1) focusInput(index + 1)
  }

  const handlePasteCode = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const copiedCode = event.clipboardData.getData('text/plain')

    const updatedForm = { ...formJoinClass }
    const inputKeys = Object.keys(updatedForm) as JoinClassInputBoxKeys[]

    inputKeys.forEach((key, index) => {
      updatedForm[key] = copiedCode[index]
    })

    setFormJoinClass(updatedForm)
    focusInput(inputKeys.length - 1)
  }

  useEffect(() => {
    if (isJoinClassOpen) focusInput(0)
  }, [isJoinClassOpen])

  return (
    <>
      <div className={clsx(styles.JoinClassModal, { [styles.JoinClassVisible]: isJoinClassOpen })}>
        <button className={styles.JoinClassClose} onClick={() => setIsJoinClassOpen(false)}>
          <ExitIcon />
        </button>

        <Typography variant='h3' align='center' className={styles.JoinClassHeading}>
          {t('classCodeText')}
        </Typography>

        <form className={styles.JoinClassForm} ref={formRef}>
          {Object.entries(formJoinClass).map(([key, value], index) => (
            <input
              key={key}
              name={key}
              value={value}
              onChange={(event) => handleUpdateForm(event, index)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onPaste={handlePasteCode}
              className={styles.JoinClassInput}
            />
          ))}
        </form>

        <button className={styles.JoinClassSubmit} onClick={handleSubmitForm} disabled={!isFormFilled}>
          {t('joinNewClassText')}
        </button>
      </div>

      <div className={clsx(styles.JoinClassBackdrop, { [styles.BackdropVisible]: isJoinClassOpen })} />
    </>
  )
}
