import React, { useContext } from 'react'
import AnswerHintContent from '../../../../AnswerHint/AnswerHintContent'
import AnswerHint from '../../../../AnswerHint'
import { getUserType } from '../../../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../../../constants/userTypes'
import { isMistakeAnswerStatus } from '../../../../../helpers/exercises/exerciseAnswerHelper'
import AnswerInfoContext from '../../../../../context/answerInfoContext'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../../features/Problem/models/exerciseMode'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../../features/assignment/model/assignment.model'

type ChoiceHintProps = {
  showAnswerPreview: boolean
}

export const ChoiceHint = ({ showAnswerPreview }: ChoiceHintProps) => {
  const { answerResultStatus, isExamFinished } = useContext(AnswerInfoContext)
  const { exerciseMode } = useCurrentExerciseMode()
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  const isTeacher = getUserType() === TEACHER
  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isEnabledInExamMode =
    isExamMode && isExamFinished() && (isMistakeAnswerStatus(answerResultStatus) || !answerResultStatus)

  if (!showAnswerPreview && !isEnabledInExamMode) {
    return null
  }

  if (isTeacher || (showAnswerPreview && !isAnswerPending)) {
    return (
      <div>
        <AnswerHint />
      </div>
    )
  }

  if (isEnabledInExamMode) {
    return (
      <div>
        <AnswerHintContent showAnswerHint />
      </div>
    )
  }

  return null
}
