import React, { useContext, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import '../../features/DrawBoard/styles.scss'
import { findFirstUnsolvedProblemIndex, findFirstNotAnsweredProblemIndex } from '../../helpers/users/getUserStudyInfo'
import { getCurrentAttempt, getLastAnswer, makeAttempt, checkIsSolved } from '../../helpers/exercises/exercisesHelper'
import { sendCheckAnswerHint } from '../../features/assignments/requests'
import { getUserAnswer } from '../../helpers/exercises/answerProblemHelper'
import { compareAnswers, evalMathExpression, clearLeadingZeros } from '../../helpers/smart-eval'
import { getUserType, getUserId } from '../../helpers/users/getUserInfo'
import { showErrorToast } from '../../constants/toasts'
import { TEACHER } from '../../constants/userTypes'
import answerTypes, {
  HANDWRITING,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  ORDERED,
  PREFILLED_BOXES,
  PREFILLED_CHOICES,
  GEOGEBRA,
} from '../../constants/answerTypes'
import { NETWORK_ERROR_MESSAGE } from '../../constants/defaults'
import { MIN_LINES_AMOUNT, MIN_LINES_AMOUNT_IF_NO_DRAWING_REQUIRED } from '../../constants/drawingArea'
import { RECENT_PROBLEM_ID, RECENT_SUBPROBLEM_ID } from '../../config/localStorageKeys'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import AnswerInfoContext from '../../context/answerInfoContext'
import ProblemNotSupported from './ProblemNotSupported'
import ProblemFeedback from './ProblemFeedback'
import { Sidebar } from '../../features/Problem/Sidebar/'
import { PROBLEM_FEEDBACK_VARIANTS } from '../../constants/problemFeedback'
import { useTextToSpeechStore } from '../../features/TextToSpeech/model'
import { drawBoardModel, useDrawBoardModel } from '../../features/DrawBoard/model'
import { getProblemDescription } from './helpers'
import { useProblemStore } from '../../features/Problem/models/model'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../features/Problem/models/answer'
import { getDescription, MyScriptImageExportFormat, translateCurrentTask } from '../../features/Problem/helpers'
import useTimeMeasure from '../../hooks/useTimeMeasure'
import { getGeogebraBase64State, getGeogebraCanvasBase64 } from '../../features/Geogebra/helpers/geogebraApiHelpers'
import { useGeogebraModelActions } from '../../features/Geogebra/model'
import { useAssignmentsProgress } from '../../features/Problem/models/progress'
import { ToolbarToolsNames } from '../../features/Geogebra/types'
import { sideBarModel } from '../../features/Problem/Sidebar/model'
import { AnswerSectionBlock } from './AnswerSectionBlock'
import { useMatchesMobile } from '../../lib/device/hook'
import { BoardActionsModule } from '@magmamath/react-native-draw-board'
import { useCurrentExerciseMode, currentExerciseMode, ExerciseMode } from '../../features/Problem/models/exerciseMode'
import { useUnit, useStoreMap } from 'effector-react'
import { CanvasType } from '../Profile/ChangeCanvasType'
import { $hasCanvasHistory, FocusedInput, updateInitialFocus } from '../../features/Problem/models/initialInputFocus'
import { useProblemHint } from '../../features/AnswerHints/hooks'
import { hintMyScriptModel, HintType } from '../../features/AnswerHints/model'
import { getAttemptBackgroundImage } from '../../features/Problem/helpers.attempts'
import { FeedbackSolution } from '../../features/FeedbackSolution/FeedbackSolution'
import { availableChatsModel } from '../../features/MagmaChat/models/availableChatsModel'
import { setChatOpen } from '../../features/MagmaChat/models/isChatOpen'
import { threadIdsModel } from '../../features/MagmaChat/models/threadIdsModel'
import { getDrawboardDrawingImage } from '../../features/AnswersQueue/requests/images'
import { getAnswerHint } from '../../features/AnswersQueue/requests/hints'
import { addedToOfflineQueue } from '../../features/AnswersQueue/models/offlineQueue/offlineQueue'
import { offlineQueueImagesDB } from '../../features/AnswersQueue/models/offlineQueue/offlineQueueImagesDB'
import { getUserLocale } from '../../helpers/userLocale'
import { sendAnswerFx } from '../../features/AnswersQueue/models/saveAnswer/saveAnswer'
import { validateCurrentDrawingFx } from '../../features/drawingValidation/model'
import { drawBoardStatisticModule } from '../../features/DrawBoard/model.modules'
import { currentExerciseModel, setAssignmentId, setExerciseId } from '../../features/assignment/model/credentials.model'
import { createInitialMessage } from '../../features/MagmaChat/models/messages'
import { initialMessage } from '../../features/MagmaChat/constants'
import { useAssignmentCredentials } from '../../features/assignment/hooks/useAssignmentCredentials'
import { ExerciseAnswer } from '../../features/Problem/components/ExerciseAnswer'
import { answerAreaMyScriptModel } from '../../features/MyScript/model'
import { ExerciseDescription } from '../../features/exerciseDescription/ExerciseDescription'
import WorkingArea from '../../features/workingArea/WorkingArea'
import { KeyboardKeys } from '../../constants/keyboardKeys'
import { $assignmentPopup, AssignmentPopup, setAssignmentPopup } from '../../features/assignmentPopups/model'
import { $isCheatingDetectionEnabled } from '../../features/CheatDetection/models/isCheatingDetectionEnabled'
import { $exam } from '../../features/Problem/models/exam/exam'
import { api } from '../../api'
import { msCompanion } from '../../features/DrawBoard/hook.myScript'
import { assignmentModel } from '../../features/assignment/model/assignment.model'
import { fetchAssignmentWithStatsFx } from '../../features/assignmentsPagination/model/request'
import { getAnswerResultStyle, getSubmitAnswerButtonInfo, checkIsExamStopped } from './helpers'
import CircleLoader from '../../ui/Loaders/CircleLoader'
import { writeLogg } from '../../helpers'

const Problem = ({ redirectProblemId, redirectSubProblemId, offlineMode }) => {
  const drawBoard = useRef(null)
  const [drawBoardActions] = useState(() => new BoardActionsModule())
  drawBoardActions.setUp(drawBoard.current?.model || null)
  const { t, i18n } = useTranslation()
  const { isTranslated, setIsTranslated } = useTextToSpeechStore((state) => state)
  const { currentProblemIndex, currentSubProblemIndex, setCurrentProblemIndex, setCurrentSubProblemIndex } =
    useProblemStore((state) => state)
  const { getProblemHint } = useProblemHint()
  const { setBase64Answer } = useGeogebraModelActions()
  const { getTimeSpentInMilliseconds, resetStartTime } = useTimeMeasure([currentProblemIndex, currentSubProblemIndex])
  const calculateInitialProgress = useAssignmentsProgress((state) => state.calculateInitialProgress)
  const updateProgressBar = useAssignmentsProgress((state) => state.addSubmittedProblem)
  const drawBoardMode = useDrawBoardModel(({ mode }) => mode)
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const isMistakeAnswerStatus = answerStatus === ProblemAnswerStatus.MISTAKE
  const isSuccessAnswerStatus = answerStatus === ProblemAnswerStatus.SUCCESS
  const isAnswerSucceededOnFirstAttempt = answerStatus === ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT
  const isCorrectAnswerStatus = isAnswerSucceededOnFirstAttempt || isSuccessAnswerStatus
  const isExamAnswerStatus = answerStatus === ProblemAnswerStatus.EXAM_MODE
  const [badAnswerFormat, setBadAnswerFormat] = useState('')
  const { exerciseMode } = useCurrentExerciseMode()
  const [answerHintChecked, setAnswerHintChecked] = useState(false)
  const [startSolvingTime, setStartSolvingTime] = useState(null)
  const isFetching = useUnit(fetchAssignmentWithStatsFx.pending)

  const [confettiAnimation, setConfettiAnimation] = useState(false)
  const [wiggleAnimation, setWiggleAnimation] = useState(false)

  const { state: userSettingsState } = useContext(UserSettingsContext)
  const exam = useUnit($exam)
  const isCheatingDetectionEnabled = useUnit($isCheatingDetectionEnabled)
  const assignmentPopup = useUnit($assignmentPopup)
  const exercises = useUnit(assignmentModel.$exercises)

  const currentProblemObject = exercises[currentProblemIndex] ?? null
  const currentSubProblems = currentProblemObject?.subProblems ?? []
  const currentSubProblem = currentSubProblems[currentSubProblemIndex]
  const currentTask = currentSubProblem || currentProblemObject
  const isGeogebraAnswerType = currentTask?.answerType === GEOGEBRA
  const isMobileDevice = useMatchesMobile()
  const hasCanvasHistory = useUnit($hasCanvasHistory)
  const isChoiceAnswerType =
    currentTask?.answerType && [SINGLE_CHOICE, MULTIPLE_CHOICE, ORDERED].includes(currentTask?.answerType)
  const credits = useAssignmentCredentials()

  const minLinesAmount = currentTask?.drawingRequired ? MIN_LINES_AMOUNT : MIN_LINES_AMOUNT_IF_NO_DRAWING_REQUIRED
  const isEmptyDrawing = useStoreMap({
    store: drawBoardStatisticModule.$counter,
    keys: [minLinesAmount],
    fn: ({ list }) => !(list.total >= minLinesAmount || list.element || list.text),
  })

  const assignment = useUnit(assignmentModel.$assignment)
  const attemptInfo = useUnit(assignmentModel.$attemptInfo)
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const classroomGrade = useStoreMap(assignmentModel.$assignment, (assignment) => assignment?.classes?.[0]?.grade)

  // TODO: can be removed and replaced with: useAssignmentCredentials
  const currentProblemCredits = useMemo(() => {
    if (!assignment._id || !currentProblemObject) {
      return {
        exerciseId: null,
        problemId: null,
        subProblemId: null,
      }
    }
    const subProblems = currentProblemObject.subProblems || []
    const subProblem = subProblems[currentSubProblemIndex]
    return {
      exerciseId: assignment._id,
      problemId: currentProblemObject._id,
      subProblemId: subProblem?._id ?? null,
    }
  }, [currentSubProblemIndex, currentProblemObject, assignment._id])
  const theoryRef = useRef()
  const problemAreaRef = useRef()

  const assignmentId = assignment?._id
  const problemId = currentProblemCredits?.subProblemId ?? currentProblemCredits?.problemId

  const handleArrowNavigation = ({ key }) => {
    if (drawBoardModel.ref.inputFocused || assignmentPopup === AssignmentPopup.FINISHED) return
    if (key === KeyboardKeys.ARROW_RIGHT) forwardClick()
    if (key === KeyboardKeys.ARROW_LEFT) backClick()
  }

  const findFirstProblem = useCallback(() => {
    if (!exercises.length) {
      return
    }

    const navigationType = window.performance?.getEntriesByType('navigation')?.[0]?.type
    if (navigationType && navigationType !== 'reload') {
      sessionStorage.removeItem(RECENT_PROBLEM_ID)
      sessionStorage.removeItem(RECENT_SUBPROBLEM_ID)
    }

    const recentProblemId = sessionStorage.getItem(RECENT_PROBLEM_ID)
    const neededProblemId = redirectProblemId || recentProblemId
    if (neededProblemId && neededProblemId !== 'undefined') {
      const redirectProblemIndex = exercises.findIndex((problem) => problem._id === neededProblemId)
      if (redirectProblemIndex === -1) {
        return
      }

      const recentSubProblemId = sessionStorage.getItem(RECENT_SUBPROBLEM_ID)
      recentProblemId && sessionStorage.removeItem(RECENT_PROBLEM_ID)
      recentSubProblemId && sessionStorage.removeItem(RECENT_SUBPROBLEM_ID)
      const neededSubProblemId = redirectSubProblemId || recentSubProblemId
      if (!neededSubProblemId) {
        setCurrentProblemIndex(redirectProblemIndex)
        setCurrentSubProblemIndex(0)
        return
      }

      const redirectProblem = exercises[redirectProblemIndex]
      const subProblems = redirectProblem?.subProblems
      if (!subProblems || !subProblems.length) {
        return
      }

      const redirectSubProblemIndex = subProblems.findIndex((subProblem) => subProblem._id === neededSubProblemId)

      if (redirectSubProblemIndex !== -1) {
        setCurrentProblemIndex(redirectProblemIndex)
        setCurrentSubProblemIndex(redirectSubProblemIndex)
      }
      return
    }

    if (!exam.isEnabled) {
      return findFirstUnsolvedProblemIndex(exercises)
    }

    return exam.isFinished
      ? { currentProblemIndex: 0, currentSubProblemIndex: 0 }
      : findFirstNotAnsweredProblemIndex(exercises)
  }, [
    exam.isEnabled,
    exam.isFinished,
    exercises,
    redirectProblemId,
    redirectSubProblemId,
    setCurrentProblemIndex,
    setCurrentSubProblemIndex,
  ])

  const checkIsCurrentProblemAlreadySolved = useCallback(() => {
    if (!Object.keys(attemptInfo).length || (exam.isEnabled && !exam.isFinished)) {
      return false
    }

    return checkIsSolved(attemptInfo, currentProblemCredits.problemId, currentProblemCredits.subProblemId)
  }, [exam.isEnabled, exam.isFinished, attemptInfo, currentProblemCredits])

  const setAnswerStatusHelper = useCallback(
    (isAnswerCorrect, attempt) => {
      if (!currentProblemCredits.problemId) {
        return
      }
      const currentAttempt =
        attempt || getCurrentAttempt(attemptInfo, currentProblemCredits.problemId, currentProblemCredits.subProblemId)
      if (currentAttempt < 1) {
        return
      }
      if (exam.isEnabled && !exam.isFinished) {
        return currentProblemAnswerModel.setStatus(ProblemAnswerStatus.EXAM_MODE)
      }
      if (!isAnswerCorrect) {
        return currentProblemAnswerModel.setStatus(ProblemAnswerStatus.MISTAKE)
      }
      currentProblemAnswerModel.setStatus(
        currentAttempt === 1 ? ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT : ProblemAnswerStatus.SUCCESS
      )
    },
    [attemptInfo, currentProblemCredits, exam.isFinished, exam.isEnabled]
  )

  const setStatusIfCurrentProblemAlreadyHasAttempts = useCallback(() => {
    if (!Object.keys(attemptInfo).length) {
      return
    }

    setAnswerStatusHelper(checkIsCurrentProblemAlreadySolved())
  }, [attemptInfo, checkIsCurrentProblemAlreadySolved, setAnswerStatusHelper])

  useEffect(() => {
    const subProblems = currentProblemObject?.subProblems || []
    const subProblem = subProblems?.[currentSubProblemIndex]

    const currentProblemId = subProblem?._id ?? currentProblemObject?._id

    setExerciseId(currentProblemId ?? '')
    setAssignmentId(assignment?._id ?? '')
  }, [assignment?._id, currentProblemObject?._id, currentProblemObject?.subProblems, currentSubProblemIndex])

  useEffect(() => {
    if (exerciseMode === ExerciseMode.EXAM && !exam.isEnabled) return
    currentExerciseMode.setExerciseMode(exam.isEnabled ? ExerciseMode.EXAM : ExerciseMode.REGULAR)
  }, [exam.isEnabled, exerciseMode])

  // TODO: to effector and just check $currentExercise to calculate initial progress
  useEffect(() => {
    if (!assignment) return
    calculateInitialProgress(assignment, exam.isEnabled)
  }, [assignment, exam.isEnabled])

  useEffect(() => {
    const firstProblemObject = findFirstProblem()
    if (!firstProblemObject) {
      return
    }

    setCurrentProblemIndex(firstProblemObject.currentProblemIndex)
    setCurrentSubProblemIndex(firstProblemObject.currentSubProblemIndex)
  }, [findFirstProblem, setCurrentProblemIndex, setCurrentSubProblemIndex])

  useEffect(() => {
    currentProblemAnswerModel.resetStatus()
    setBadAnswerFormat('')
    setStatusIfCurrentProblemAlreadyHasAttempts()
  }, [currentProblemIndex, currentSubProblemIndex, setStatusIfCurrentProblemAlreadyHasAttempts])

  useEffect(() => {
    if (!currentProblemObject?._id) {
      return
    }

    // TODO: to effector, also listen for $currentExercise id change
    setStartSolvingTime(new Date())
  }, [currentProblemObject])

  // TODO: remove since subProblems are not supported anymore - do it on separate PR and one commit in case we need to bring it back
  // We probably cannot do this for backward compatibility, since sellers could
  // use very old exercises with subProblems
  useEffect(() => {
    if (!currentSubProblem) return
    const currentSubProblemElement = document.getElementById(currentSubProblem._id)
    if (!currentSubProblemElement) return
    currentSubProblemElement.scrollIntoView({ block: 'end', inline: 'nearest' })
  }, [currentSubProblem])

  // TODO: TO useOnProblemChange() or just use key={credits.id} on component to reset to default state
  useEffect(() => {
    setIsTranslated(false)
  }, [currentProblemIndex, setIsTranslated])

  // TODO: TO useOnProblemChange() or just use key={credits.id} on component to reset to default state
  useEffect(() => {
    setAnswerHintChecked(false)
    setConfettiAnimation(false)
    setWiggleAnimation(false)
    sideBarModel.setIsOpened(true)
  }, [currentProblemIndex, currentSubProblemIndex])

  useEffect(() => {
    window.addEventListener('beforeunload', currentExerciseModel.saveCredits)

    return () => {
      window.removeEventListener('beforeunload', currentExerciseModel.saveCredits)
    }
  }, [])

  useEffect(() => {
    return () => {
      currentProblemAnswerModel.resetStatus()
    }
  }, [])

  const forwardClick = () => {
    if (!exercises.length) {
      return
    }

    if (isGeogebraAnswerType) {
      setBase64Answer({
        assignmentId: assignment?._id,
        problemId: currentTask._id,
        base64Answer: getGeogebraBase64State(),
      })
    }

    if (!(currentSubProblemIndex + 1 >= currentSubProblems.length)) {
      return setCurrentSubProblemIndex(currentSubProblemIndex + 1)
    }

    if (currentProblemIndex + 1 >= exercises.length) {
      setAssignmentPopup(AssignmentPopup.FINISHED)
      sideBarModel.setIsProblemsListOpened(true)
      return
    }

    setCurrentProblemIndex(currentProblemIndex + 1)
    setCurrentSubProblemIndex(0)
  }

  const backClick = () => {
    if (!exercises.length) {
      return
    }

    if (isGeogebraAnswerType) {
      setBase64Answer({
        assignmentId: assignment?._id,
        problemId: currentTask._id,
        base64Answer: getGeogebraBase64State(),
      })
    }

    if (currentSubProblemIndex > 0) {
      return setCurrentSubProblemIndex(currentSubProblemIndex - 1)
    }

    if (currentProblemIndex < 1) {
      setCurrentProblemIndex(exercises.length - 1)
      setCurrentSubProblemIndex(exercises[exercises.length - 1].subProblems.length - 1)
      return
    }

    setCurrentProblemIndex(currentProblemIndex - 1)
    setCurrentSubProblemIndex(exercises[currentProblemIndex - 1].subProblems.length - 1)
  }

  const prevAnswer = useMemo(() => {
    if (!currentProblemCredits) return ''
    return getLastAnswer(attemptInfo, currentProblemCredits.problemId, currentProblemCredits.subProblemId)
  }, [currentProblemCredits, attemptInfo])

  const currentExercise = assignment
  const compareLastAnswer = useCallback(() => {
    if (!prevAnswer || !prevAnswer.length) {
      return
    }

    compareAnswers(
      currentTask || {},
      prevAnswer,
      badAnswerFormat,
      setBadAnswerFormat,
      currentExercise?.isRequiredUnit,
      i18n.language
    )
  }, [prevAnswer, currentTask, currentExercise])

  useEffect(() => {
    // TODO: useOnProblemChange
    compareLastAnswer()
  }, [currentProblemIndex, currentSubProblemIndex, compareLastAnswer])

  const isAnswerCorrect = (userAnswer, currentTask) => {
    setBadAnswerFormat('')
    let localBadAnswerFormat = ''

    const updateBadAnswerFormat = (badAnswerFormat) => {
      localBadAnswerFormat = badAnswerFormat
      setBadAnswerFormat(badAnswerFormat)
    }

    if (!currentTask.answer) {
      return {
        isCorrect: false,
        badAnswerFormat: localBadAnswerFormat,
      }
    }

    if (!Array.isArray(userAnswer)) {
      const cleanedTaskAnswer = currentTask.answer.map((answer) => answer.replace(/,/g, '.'))
      const evaluatedAnswers = cleanedTaskAnswer.map((answer) => evalMathExpression(answer))
      const evaluatedUserAnswer = evalMathExpression(userAnswer)

      if (!currentTask.smartEvaluation) {
        return evaluatedAnswers.includes(evaluatedUserAnswer.replace(/,/g, '.'))
      }

      return evaluatedAnswers
        .map((answer) => answer.toLowerCase())
        .includes(evaluatedUserAnswer.replace(/,/g, '.').toLowerCase())
    }

    if (
      currentTask.answerType === ORDERED ||
      currentTask.answerType === PREFILLED_BOXES ||
      currentTask.answerType === PREFILLED_CHOICES
    ) {
      if (userAnswer.length !== currentTask.answer.length) {
        return {
          isCorrect: false,
          badAnswerFormat: localBadAnswerFormat,
        }
      }

      let transformedTaskAnswer = currentTask.answer.map((answer) => answer.replace(/\n/g, ''))
      let transformedUserAnswer = userAnswer.map((answer) => answer.replace(/\n/g, ''))
      if (currentTask.smartEvaluation) {
        transformedTaskAnswer = transformedTaskAnswer.map((answer) => clearLeadingZeros(answer))
        transformedUserAnswer = transformedUserAnswer.map((answer) => clearLeadingZeros(answer))
      }

      return {
        isCorrect: JSON.stringify(transformedUserAnswer) === JSON.stringify(transformedTaskAnswer),
        badAnswerFormat: localBadAnswerFormat,
      }
    }

    if (
      currentTask.answerType === SINGLE_CHOICE ||
      currentTask.answerType === MULTIPLE_CHOICE ||
      (currentTask.answerType === GEOGEBRA &&
        currentTask.geogebraSettings?.toolbarTools[0] === ToolbarToolsNames.POINT.toUpperCase())
    ) {
      if (userAnswer.length !== currentTask.answer.length) {
        return {
          isCorrect: false,
          badAnswerFormat: localBadAnswerFormat,
        }
      }
      const clearedUserAnswer = userAnswer.map((answer) => answer.replace(/\n/g, ''))
      const clearedTaskAnswer = currentTask.answer.map((answer) => answer.replace(/\n/g, ''))
      return {
        isCorrect: clearedUserAnswer.every((answer) => clearedTaskAnswer.includes(answer)),
        badAnswerFormat: localBadAnswerFormat,
      }
    }

    return {
      isCorrect: compareAnswers(
        currentTask,
        userAnswer,
        badAnswerFormat,
        updateBadAnswerFormat,
        assignment.isRequiredUnit,
        i18n.language
      ),
      badAnswerFormat: localBadAnswerFormat,
    }
  }

  const chatbotThreadId = useStoreMap({
    store: threadIdsModel.$state,
    keys: [assignmentId, problemId],
    fn: (threadIds) => threadIds[assignmentId]?.[problemId]?.threadId ?? null,
  })

  const checkIsCorrectAndSendAnswer = async (answer, isMyScriptUsed) => {
    drawBoard.current?.model.cache.addAnchor()
    const geogebraAppletStateBase64 = isGeogebraAnswerType && getGeogebraBase64State()

    const { problemId, subProblemId } = currentProblemCredits
    const timeSpentOnTask = getTimeSpentInMilliseconds()
    resetStartTime()

    const strokeCount = drawBoardStatisticModule.counter.list.total
    const attemptsCount = getCurrentAttempt(attemptInfo, problemId, subProblemId)

    const userAnswer = getUserAnswer(answer)
    const { isCorrect, badAnswerFormat } = isAnswerCorrect(userAnswer, currentTask)
    const shouldValidateDrawing = isCorrect && currentTask?.requiresInterpretation && !exam.isEnabled

    // TODO: effector for statuses
    setAnswerStatusHelper(isCorrect, attemptsCount + 1)
    assignmentModel.updateAttemptInfo(
      makeAttempt({
        exercisesAttempts: attemptInfo,
        problemId,
        subProblemId,
        answer: userAnswer,
        solved: isCorrect,
      })
    )

    let drawingBase64
    try {
      if (currentTask?.drawingAreaImage) {
        assignmentModel.setIsAnswerPending(true)
        drawingBase64 = await getDrawboardDrawingImage(drawBoard).finally(() => {
          assignmentModel.setIsAnswerPending(false)
        })
      } else {
        drawingBase64 = isGeogebraAnswerType ? getGeogebraCanvasBase64() : await getDrawboardDrawingImage(drawBoard)
      }
    } catch (error) {
      console.error('error', error)
      writeLogg(error)
    }

    if (exam.isEnabled || isCorrect) {
      updateProgressBar({
        currentExercise: assignment,
        problemId,
      })
    }

    let hintPayload
    if (!isCorrect) {
      createInitialMessage({
        ...initialMessage,
        assignmentId: credits.id,
        problemId: credits.problemId,
      })

      availableChatsModel.setField({ assignmentId, problemId })

      const { hint, errorType, isSmartEvalHint } = await getAnswerHint({
        currentTask,
        badAnswerFormat,
        fetchHints: getProblemHint,
      })

      if (isSmartEvalHint) {
        // Hint state is also set in getProblemHint()
        hintMyScriptModel.setHint({
          text: hint,
          type: HintType.SMART_EVAL,
        })

        hintMyScriptModel.setHintOpen(true)
      }

      if (hint || errorType) hintPayload = { text: hint || '', errorType: errorType || '' }
    }

    let isDrawingValid = null
    let canvasRecognitionResult = null
    try {
      canvasRecognitionResult = !isGeogebraAnswerType ? await msCompanion.recognizeBoard() : null

      if (isCorrect && shouldValidateDrawing && !isGeogebraAnswerType) {
        const drawingValidations = await validateCurrentDrawingFx({
          assignmentId,
          problemId,
          userAnswer,
          problemDescription: getDescription(currentProblemObject),
          recognitionResult: canvasRecognitionResult,
        })

        isDrawingValid = drawingValidations.isValid
      }
    } catch (e) {
      console.error('e', e)
      writeLogg(e)
    }

    const solutionPayload = {
      locale: getUserLocale(),
      answer: userAnswer,
      correctAnswer: currentTask.answer,
      exerciseId: assignment._id,
      correct: isCorrect,
      strokeCount,
      recognitionUsed: !!isMyScriptUsed,
      type: drawBoardMode,
      timeToSolve: timeSpentOnTask,
      drawingImage: null,
      handwritingImage: null,
      requiresInterpretation: !!currentTask?.requiresInterpretation,
      recognition: {
        drawingStatus: Number(!!isDrawingValid),
        drawingResult: canvasRecognitionResult?.latex,
      },
      ...(subProblemId ? { subProblemId } : { problemId }),
      ...(geogebraAppletStateBase64 && { geogebraDrawing: geogebraAppletStateBase64 }),
      ...(hintPayload && { hint: hintPayload }),
      ...(chatbotThreadId && { threadId: chatbotThreadId }),
      ...(!!chatbotThreadId && { chat: !!chatbotThreadId }),
    }

    const sessionPayload = {
      correct: isCorrect,
      endTime: new Date(),
      exercise: assignment._id,
      numberOfAnswers: getCurrentAttempt(attemptInfo, problemId, subProblemId), // attemptsCount
      problem: problemId,
      showAnswer: answerHintChecked,
      startTime: startSolvingTime,
      student: getUserId(),
      subProblem: currentProblemCredits.subProblemId,
    }

    try {
      const myScriptDrawingBase64 = await answerAreaMyScriptModel.flush()

      await sendAnswerFx({
        drawingBase64,
        myScriptDrawingBase64,
        solution: solutionPayload,
        session: sessionPayload,
        assignmentId,
        problemId,
      })
    } catch (error) {
      writeLogg(error)
      if (error.message === NETWORK_ERROR_MESSAGE && !navigator.onLine) {
        const drawboardBase64 = isGeogebraAnswerType ? getGeogebraCanvasBase64() : await drawBoard.current.getImage()
        const handwritingBase64 = await answerAreaMyScriptModel.flush({ format: MyScriptImageExportFormat.Base64 })

        const drawboardImageId = await offlineQueueImagesDB.save(drawboardBase64)
        const handwritingImageId = await offlineQueueImagesDB.save(handwritingBase64)

        addedToOfflineQueue({
          token: api.manager.$token.getState()?.access || '',
          solution: solutionPayload,
          session: sessionPayload,
          images: {
            drawboard: drawboardImageId,
            handwriting: handwritingImageId,
          },
        })

        return
      }

      currentProblemAnswerModel.setStatus('')
      showErrorToast({
        message: t('somethingWentWrongText'),
        toastId: 'submitAnswerError',
      })

      console.error('Error submitting answer: ', error)
    }
  }

  const onAnswerHintChecked = async () => {
    const response = await sendCheckAnswerHint(
      assignment._id,
      currentProblemCredits.problemId,
      currentProblemCredits.subProblemId
    )
    if (!response) {
      return
    }

    setAnswerHintChecked(true)
    return response
  }

  const manuallyLoadedImage = getAttemptBackgroundImage(
    attemptInfo,
    currentProblemCredits.problemId,
    currentProblemCredits.subProblemId
  )

  const isForceDrawingEnabled = !!assignment?.isRequiredDrawing
  const isEmptyCanvas = isEmptyDrawing && !manuallyLoadedImage
  const isDrawingRequired =
    isForceDrawingEnabled && isEmptyCanvas && (!answerStatus || isExamAnswerStatus) && !isMobileDevice
  const isShowDrawingRequiredMessage = isDrawingRequired && (!exam.isEnabled || !exam.isFinished)

  const getCurrentTaskAnswerHint = useCallback(() => {
    if (!currentTask) return {}
    return {
      rightAnswer: currentTask.answer,
      example: currentTask.answerDrawing,
    }
  }, [currentTask])

  const isAnswerLookingAllowed = assignment?.allowedAnswerLooking && isMistakeAnswerStatus

  const answerContextProps = {
    style: getAnswerResultStyle(exam, answerStatus),
    submitButtonText: getSubmitAnswerButtonInfo(exam, answerStatus),
    answerResultStatus: answerStatus,
    answerHint: getCurrentTaskAnswerHint(),
    resetAnswerResultStatus: currentProblemAnswerModel.resetStatus,
    examMode: exam.isEnabled, // TODO: remove from context and useUnit
    examStopped: checkIsExamStopped({ assignment }),
    showRequireDrawingMessage: isShowDrawingRequiredMessage,
    wiggleButton: wiggleAnimation,
    problemData: currentTask || {},
    answerHintChecked,
    onAnswerHintChecked,
    isExamFinished: () => exam.isFinished, // TODO: remove from context and useUnit
    badAnswerFormat,
    offlineMode,
    isTranslated,
    characterType: currentTask?.characterType,
    answerType: currentTask?.answerType,
  }

  useEffect(() => {
    setChatOpen(false)
  }, [assignment?._id, currentProblemIndex, currentSubProblemIndex])

  const isSupportedAnswerType = useMemo(
    () => !currentTask || answerTypes.includes(currentTask.answerType),
    [currentTask]
  )

  const renderProblemDescription = () => {
    // TODO: separate component
    if (!currentProblemObject) {
      return <CircleLoader />
    }

    if (!isSupportedAnswerType) {
      return <ProblemNotSupported />
    }

    return (
      <AnswerInfoContext.Provider
        value={{
          attemptInfo: attemptInfo, // TODO: remove from context and useUnit
          examMode: exam.isEnabled, // TODO: remove from context and useUnit
          examStopped: checkIsExamStopped({ assignment }), // TODO: remove from context
          isExamFinished: () => exam.isFinished, // TODO: remove from context and useUnit
        }}
      >
        <ExerciseDescription
          key={credits.problemId}
          onDragStart={drawBoardActions.images.webStartDragging}
          exercise={currentProblemObject}
          onTranslateButtonPress={onTranslateButtonPress}
        />
      </AnswerInfoContext.Provider>
    )
  }

  const isDisabledDrawingPart =
    (exam.isEnabled && (checkIsExamStopped({ assignment }) || exam.isFinished) && getUserType() !== TEACHER) ||
    !isSupportedAnswerType ||
    isCorrectAnswerStatus

  useEffect(() => {
    const isSpreadsheetDefaultMode = userSettingsState.canvasType === CanvasType.SPREADSHEET

    if (isChoiceAnswerType) {
      updateInitialFocus(FocusedInput.SPREADSHEET_CELL)
      return
    }

    if (!hasCanvasHistory && isSpreadsheetDefaultMode && isForceDrawingEnabled) {
      updateInitialFocus(FocusedInput.SPREADSHEET_CELL)
      return
    }

    updateInitialFocus(FocusedInput.TEXT_INPUT)
  }, [isChoiceAnswerType, hasCanvasHistory, isForceDrawingEnabled, userSettingsState.canvasType])

  const onTranslateButtonPress = async () => {
    await translateCurrentTask({
      isTranslated,
      setIsTranslated,
      problem: currentProblemObject,
      subProblems: currentSubProblems,
      translationLanguage: userSettingsState.translationLanguage,
    })
  }

  const renderProblemSolvingArea = () => {
    // TODO: separate component
    if (!Object.keys(assignment).length || isMobileDevice) {
      return
    }

    return (
      <div className='problem-area' id='problem-area' ref={problemAreaRef}>
        <div id='theory-window-portal' ref={theoryRef}></div>
        {(exam.isEnabled && exam.isFinished) ||
          (isCorrectAnswerStatus && (
            <ProblemFeedback
              problemArea={problemAreaRef.current}
              showConfettiAnimation={confettiAnimation && !isAnswerPending}
              showWiggleButtonAnimation={wiggleAnimation && !isAnswerPending}
              setConfettiState={setConfettiAnimation}
              setWiggleButtonState={setWiggleAnimation}
              allowShowConfetti={isAnswerSucceededOnFirstAttempt}
              variant={PROBLEM_FEEDBACK_VARIANTS.CONFETTI}
            />
          ))}
        <WorkingArea
          isDisabledGeogebra={isCorrectAnswerStatus || exam.isFinished}
          isDisabledDrawBoard={isDisabledDrawingPart}
          // isLoading={!problems.length && isFetching}
          isLoading={false}
          currentProblem={currentProblemObject}
          currentTask={currentTask}
          drawBoardRef={drawBoard}
          onKeyDown={handleArrowNavigation}
          userSettings={userSettingsState}
          manuallyLoadedImage={manuallyLoadedImage}
          isCheatingDetectionEnabled={isCheatingDetectionEnabled}
          currentProblemCredits={currentProblemCredits.problemId ? currentProblemCredits : null}
        />

        <FeedbackSolution
          assignmentId={assignment?._id}
          problemId={currentTask?._id}
          description={getProblemDescription(currentProblemObject)}
          isExamMode={exam.isEnabled}
          isSubProblem={!!currentSubProblem}
          answerType={currentTask?.answerType}
          assignmentClassGrade={classroomGrade}
          height={drawBoard.current?.model.transform.sizes.visible.height}
          problemAnswer={currentProblemObject?.answer}
        />

        <AnswerInfoContext.Provider value={answerContextProps}>
          <ExerciseAnswer
            answerType={currentTask?.answerType}
            answerStatus={answerStatus}
            currentTask={currentTask}
            characterType={currentTask?.characterType}
            allowAnswerLooking={isAnswerLookingAllowed}
            isLoading={!exercises.length || currentTask?.answerType === undefined}
            isEmpty={!exercises.length}
            isUnitRequired={assignment?.isRequiredUnit}
            isSolved={checkIsCurrentProblemAlreadySolved()}
            isExamFinished={exam.isFinished}
            isExamStopped={checkIsExamStopped({ assignment })}
            isExerciseInExamMode={exam.isEnabled}
            isCorrectAnswerStatus={isCorrectAnswerStatus}
            previousAnswer={prevAnswer}
            onNextButtonPress={forwardClick}
            onSubmitClick={checkIsCorrectAndSendAnswer}
            onAnswerChangeStart={() => drawBoard.current?.model.intersectionModule.$isActive.setValue(false)}
            wiggleAnimation={wiggleAnimation}
            submitButtonProps={{
              text: getSubmitAnswerButtonInfo(exam, answerStatus),
              style: getAnswerResultStyle(exam, answerStatus),
            }}
            userGrade={userSettingsState.grade}
            assignmentClassGrade={classroomGrade}
          />
        </AnswerInfoContext.Provider>
      </div>
    )
  }

  return (
    <div className='problem-area-container'>
      <Sidebar
        currentExerciseId={assignment?._id}
        currentProblemObject={currentProblemObject}
        currentSubProblem={currentSubProblem}
        onForwardButtonPress={forwardClick}
        onBackButtonPress={backClick}
        assignmentName={assignment?.name}
        activeProblemName={`${currentProblemIndex + 1}${currentSubProblem?.name || ''}`}
        onTranslateButtonPress={onTranslateButtonPress}
        isCheatingDetectionEnabled={isCheatingDetectionEnabled}
        isExamFinished={exam.isFinished}
        isGeogebraAnswerType={currentTask?.answerType === GEOGEBRA}
      >
        {isMobileDevice ? (
          <AnswerInfoContext.Provider value={answerContextProps}>
            <AnswerSectionBlock
              isLoading={!exercises.length && isFetching}
              isEmpty={!exercises.length}
              isHandwritingProblem={currentTask?.answerType === HANDWRITING}
              description={renderProblemDescription()}
            >
              <ExerciseAnswer
                answerType={currentTask?.answerType}
                answerStatus={answerStatus}
                currentTask={currentTask}
                characterType={currentTask?.characterType}
                allowAnswerLooking={isAnswerLookingAllowed}
                isLoading={!exercises.length || isFetching || currentTask?.answerType === undefined}
                isEmpty={!exercises.length}
                isUnitRequired={assignment?.isRequiredUnit}
                isSolved={checkIsCurrentProblemAlreadySolved()}
                isExamFinished={exam.isFinished}
                isExamStopped={checkIsExamStopped({ assignment })}
                isExerciseInExamMode={exam.isEnabled}
                isCorrectAnswerStatus={isCorrectAnswerStatus}
                previousAnswer={prevAnswer}
                onNextButtonPress={forwardClick}
                onSubmitClick={checkIsCorrectAndSendAnswer}
                onAnswerChangeStart={() => drawBoard.current?.model.intersectionModule.$isActive.setValue(false)}
                wiggleAnimation={wiggleAnimation}
                submitButtonProps={{
                  text: getSubmitAnswerButtonInfo(exam, answerStatus),
                  style: getAnswerResultStyle(exam, answerStatus),
                }}
                userGrade={userSettingsState.grade}
                assignmentClassGrade={classroomGrade}
              />
            </AnswerSectionBlock>
          </AnswerInfoContext.Provider>
        ) : (
          renderProblemDescription()
        )}
      </Sidebar>

      {renderProblemSolvingArea()}
    </div>
  )
}

export default Problem
