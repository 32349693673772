import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type SelectedAnswer = {
  id: number
  variant: string
  textToSpeech?: string
}

type State = {
  singleChoiceAnswer: SelectedAnswer | null
  selectedAnswers: SelectedAnswer[]
  selectedOrderedAnswers: Map<string, string>
}

type Actions = {
  setSingleChoiceAnswer: (answer: SelectedAnswer) => void
  setSelectedAnswers: (answers: SelectedAnswer[]) => void
  setSelectedOrderedAnswers: (answers: Map<string, string>) => void
  resetAnswers: () => void
}

const initialState: State = {
  singleChoiceAnswer: null,
  selectedAnswers: [],
  selectedOrderedAnswers: new Map(), // TODO why is this a map?
}

export const useChoiceAnswersModel = create(
  immer<State & Actions>((set) => ({
    ...initialState,
    setSingleChoiceAnswer: (answer) =>
      set((state) => {
        state.singleChoiceAnswer = answer
      }),
    setSelectedAnswers: (answers) =>
      set((state) => {
        state.selectedAnswers = answers
      }),
    setSelectedOrderedAnswers: (answers) =>
      set((state) => {
        state.selectedOrderedAnswers = answers
      }),
    resetAnswers: () =>
      set((state) => {
        state.singleChoiceAnswer = null
        state.selectedAnswers = []
        state.selectedOrderedAnswers = new Map()
      }),
  }))
)
