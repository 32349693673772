import React, { PropsWithChildren } from 'react'
import styles from './styles.module.scss'

import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

import { DialogTitle } from './components/DialogTitle'
import { DialogContent } from './components/DialogContent'
import { DialogActions } from './components/DialogActions'
import type { SxProps, Theme } from '@mui/material'
import clsx from 'clsx'
import { ExitIcon } from '../icons/Exit/ExitIcon'

type DialogProps = {
  isOpen: boolean
  onExitIconClick?: () => void
  sx?: SxProps<Theme>
  containerStyles?: string
  onClose?: () => void
}

// TODO: not unused but can be reworked easily without using material ui just to have dialog, remove this and dependencies:
// "@material-ui/core": "^4.10.2",
// "@mui/material": "^5.10.14",

const Dialog = ({
  children,
  isOpen,
  onExitIconClick,
  sx,
  containerStyles,
  onClose,
}: PropsWithChildren<DialogProps>) => {
  const mergedClassNames = clsx(styles.ModalContainer, containerStyles)

  return (
    <Modal
      open={isOpen}
      sx={{ zIndex: 10001, ...sx }}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 250 },
      }}
      onClose={onClose}
    >
      <Fade in={isOpen}>
        <div className={mergedClassNames}>
          {onExitIconClick && (
            <Box onClick={onExitIconClick} className={styles.ModalCloseCross}>
              <ExitIcon />
            </Box>
          )}
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

export default Object.assign(Dialog, {
  Title: DialogTitle,
  Content: DialogContent,
  Actions: DialogActions,
})
