import React from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'

import PrivateRoute from '../PrivateRoute'
import ExercisePage from '../ExercisePage'
import history from '../../history'
import { RoutesPaths } from '../paths'
import '../../features/CheatDetection/models/isCheatingDetectionEnabled'
import { ExercisesPage } from '../ExercisesPage/ExercisesPage'

const REDIRECT_PATHS = ['/exercises/*', '/exercises']

const HomePage = () => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path={RoutesPaths.EXERCISES_AVAILABLE} component={ExercisesPage} />
        <PrivateRoute exact path={RoutesPaths.EXERCISE} component={ExercisePage} />

        {REDIRECT_PATHS.map((route) => (
          <PrivateRoute key={route} path={route} component={null}>
            <Redirect to={RoutesPaths.EXERCISES_AVAILABLE} />
          </PrivateRoute>
        ))}
      </Switch>
    </Router>
  )
}

export default HomePage
