import { useUnit } from 'effector-react'
import { $assignmentId, $exerciseId } from '../model/credentials.model'

export const useAssignmentCredentials = () => {
  const currentAssignmentId = useUnit($assignmentId)
  const currentProblemId = useUnit($exerciseId)
  return {
    id: currentAssignmentId,
    problemId: currentProblemId,
  }
}
