import React from 'react'
import { COLORS } from '@magmamath/ui'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'

export const AssignmentIcon = ({ size = 25, fill = COLORS.NEUTRAL_9 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.56 7.04C9.56 6.57608 9.18392 6.2 8.72 6.2C8.25608 6.2 7.88 6.57608 7.88 7.04V8.3H6.62C6.15608 8.3 5.78 8.67608 5.78 9.14C5.78 9.60392 6.15608 9.98 6.62 9.98H7.88V11.24C7.88 11.7039 8.25608 12.08 8.72 12.08C9.18392 12.08 9.56 11.7039 9.56 11.24V9.98H10.82C11.2839 9.98 11.66 9.60392 11.66 9.14C11.66 8.67608 11.2839 8.3 10.82 8.3H9.56V7.04Z'
        fill={fill}
      />
      <path
        d='M6.6411 17.5909C6.31306 17.919 6.31306 18.4508 6.6411 18.7789C6.96914 19.1069 7.501 19.1069 7.82904 18.7789L8.71999 17.8879L9.61095 18.7789C9.93899 19.1069 10.4709 19.1069 10.7989 18.7789C11.1269 18.4509 11.1269 17.919 10.7989 17.591L9.90793 16.7L10.7989 15.809C11.1269 15.481 11.1269 14.9491 10.7989 14.6211C10.4709 14.2931 9.93899 14.2931 9.61095 14.6211L8.71999 15.5121L7.82904 14.6211C7.501 14.2931 6.96914 14.2931 6.6411 14.6211C6.31306 14.9492 6.31306 15.481 6.6411 15.809L7.53205 16.7L6.6411 17.5909Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.76 9.14C13.76 8.67608 14.1361 8.3 14.6 8.3H18.38C18.8439 8.3 19.22 8.67608 19.22 9.14C19.22 9.60392 18.8439 9.98 18.38 9.98H14.6C14.1361 9.98 13.76 9.60392 13.76 9.14Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.76 15.44C13.76 14.9761 14.1361 14.6 14.6 14.6H18.38C18.8439 14.6 19.22 14.9761 19.22 15.44C19.22 15.9039 18.8439 16.28 18.38 16.28H14.6C14.1361 16.28 13.76 15.9039 13.76 15.44Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.76 17.96C13.76 17.4961 14.1361 17.12 14.6 17.12H18.38C18.8439 17.12 19.22 17.4961 19.22 17.96C19.22 18.4239 18.8439 18.8 18.38 18.8H14.6C14.1361 18.8 13.76 18.4239 13.76 17.96Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 4.52C2 3.12824 3.12824 2 4.52 2H20.48C21.8718 2 23 3.12824 23 4.52V20.48C23 21.8718 21.8718 23 20.48 23H4.52C3.12824 23 2 21.8718 2 20.48V4.52ZM4.52 3.68C4.05608 3.68 3.68 4.05608 3.68 4.52V20.48C3.68 20.9439 4.05608 21.32 4.52 21.32H20.48C20.9439 21.32 21.32 20.9439 21.32 20.48V4.52C21.32 4.05608 20.9439 3.68 20.48 3.68H4.52Z'
        fill={fill}
      />
    </svg>
  )
}
