import React from 'react'
import './styles.scss'

const SectionButton = (props) => {
  return (
    <button className={`selector-button theory-selector-item ${props.active ? 'active' : ''}`} onClick={props.onClick}>
      {props.title}
    </button>
  )
}

export default SectionButton
