import { restore, createEvent } from 'effector'
import type { PracticeSkill } from '../../PracticeMode/types'

export class skillInfoModel {
  static readonly setOpenedSkillInfo = createEvent<PracticeSkill>()
  static readonly $openedSkillInfo = restore(this.setOpenedSkillInfo, null)

  static readonly setNextSkillInfo = createEvent<PracticeSkill>()
  static readonly $nextSkillInfo = restore(this.setNextSkillInfo, null)
}
