import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import styles from './styles.module.scss'

import usePracticeStore from '../../models/practice'
import usePracticeProblemsStore from '../../models/practiceProblems'
import { getNextOrderedAbility, getNextPromptedAbility, filterAbilitiesByGrade } from '../../practice.helpers'
import { getAbilityStatistic } from '../../requests'
import { fullscreenModeOff } from '../../../../helpers'
import { useActiveSubCategoryModel } from '../../models/activeSubCategory'
import useOpenAbilityCallback from '../../hooks/useOpenAbilityCallback'
import EndExerciseAbilityItem from './EndExerciseAbilityItem/EndExerciseAbilityItem'
import BackToExercisesButton from '../../../../ui/buttons/backToExercisesButton'
import ArrowFigure from '../../../../ui/icons/ArrowFigure'
import CardProgress from '../../AbilityCard/CardProgress/CardProgress'
import { CorrectAnswerRatePercentage } from '../../constants'
import { $practiceGrade } from '../../models/currentPracticeGrade'
import { useUnit } from 'effector-react'

const BIG_STAR_SIZE = 75

const EndExerciseModal = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { openAbilityCard } = useOpenAbilityCallback()
  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const { rightAnswersCount, problemsList, setEndProblems } = usePracticeProblemsStore()
  const { openedAbilityInfo, nextAbilityInfo, statistics, categories, openedCategory, setStatistics } =
    usePracticeStore()
  const practiceGrade = useUnit($practiceGrade)

  const currentAttemptStatistic = (rightAnswersCount / problemsList.length) * 100
  const isSuccessAttempt = currentAttemptStatistic >= CorrectAnswerRatePercentage.ONE_STAR

  const openNextCard = async () => {
    setEndProblems(false)

    const nextAbility =
      getNextOrderedAbility(
        filterAbilitiesByGrade(
          openedCategory?.subcategories?.find((subcategory) => subcategory._id === activeSubCategoryId)?.abilities ||
            [],
          practiceGrade
        ),
        nextAbilityInfo.order
      ) ||
      getNextPromptedAbility({
        categories,
        grade: practiceGrade,
        currentAbilityId: nextAbilityInfo._id,
      })

    await openAbilityCard({ ability: nextAbilityInfo, nextAbility })
  }

  const tryAgainExercise = async () => {
    const stat = await getAbilityStatistic(openedAbilityInfo._id)
    await openAbilityCard({ ability: openedAbilityInfo })
    setEndProblems(false)
    setStatistics([stat])
  }

  const leaveExercise = () => {
    setEndProblems(false)
    history.goBack()
    fullscreenModeOff()
  }

  return (
    <div>
      <div className={styles.EndExerciseWrapper}>
        <div className={clsx(styles.EndExerciseContainer, { [styles.SuccessContainer]: isSuccessAttempt })}>
          <div className={styles.BackToExercisesButtonWrapper}>
            <BackToExercisesButton onClick={leaveExercise} title={t('allCardsText')} />
          </div>
          <div className={styles.LeaveEndExerciseFigure}>
            <ArrowFigure size={{ width: 108, height: 33 }} />
          </div>
          <div className={styles.EndExerciseTextContainer}>
            <div className={styles.EndExerciseCurrentResults}>
              <CardProgress statistic={currentAttemptStatistic} size={BIG_STAR_SIZE} />
            </div>
            <div className={styles.EndExerciseHeading}>
              {isSuccessAttempt && <span>{t('successExerciseAttemptText')}</span>}
              {!isSuccessAttempt && (
                <>
                  <span>{t('failedExerciseAttemptText')}</span>
                  <span>{t('failedExerciseAttemptInfoText')}</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.CardsContainer}>
            {!isSuccessAttempt && (
              <EndExerciseAbilityItem abilityName={openedAbilityInfo.primaryName} onClick={tryAgainExercise} />
            )}
            {isSuccessAttempt && nextAbilityInfo && (
              <EndExerciseAbilityItem abilityName={nextAbilityInfo.primaryName} onClick={openNextCard} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.EndExerciseBackground}></div>
    </div>
  )
}

export default EndExerciseModal
