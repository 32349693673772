import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './examPausedByTeacherPopup.module.scss'

import Dialog from '../../../../ui/dialog'
import { Button } from '../../../../ui/button/Button'
import { navigateToAssignmentsList } from '../../../../helpers/exercises'

export const ExamPausedByTeacherPopup = () => {
  const { t } = useTranslation()
  return (
    <Dialog isOpen>
      <Dialog.Title text={t('cheatDetection.examPausedTeacherTitle')} />
      <Dialog.Content text={t('cheatDetection.examPausedTeacherContent')} />
      <Dialog.Actions>
        <Button
          text={t('cheatDetection.examPausedButtonText')}
          variant='success'
          onClick={navigateToAssignmentsList}
          className={styles.ExamPausedByTeacherPopupButton}
        />
      </Dialog.Actions>
    </Dialog>
  )
}
