import React, { useState, useMemo } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'

import { AppLanguages, nativeLanguages } from '../../../config/allLanguages'
import { ReactComponent as ExitIcon } from '../../../assets/images/exit.svg'
import clsx from 'clsx'
import LanguageList from './LanguageList'

const NativeLanguageList = ({ onChangeLanguage, showSelector, setShowSelector }) => {
  const { t, i18n } = useTranslation()
  const [languageSearchTerm, setLanguageSearchTerm] = useState('')

  const handleClearSearchTerm = () => {
    setLanguageSearchTerm('')
  }

  const handleSetLanguage = (language) => {
    onChangeLanguage(language)
    setShowSelector(false)
    setLanguageSearchTerm('')
  }

  const filteredLanguages = useMemo(() => {
    const availableLanguages = [...nativeLanguages].filter(
      (language) => !language.includes(AppLanguages[i18n.language])
    )

    if (!languageSearchTerm) return availableLanguages

    return availableLanguages.filter((language) => {
      return language.toLowerCase().includes(languageSearchTerm.toLowerCase())
    })
  }, [languageSearchTerm])

  return (
    <>
      <div className={clsx('native-language-list-wrapper', { show: showSelector })}>
        <div className='native-language-container'>
          <h2 className='native-language-heading'>{t('translationText')}</h2>
          <button className='native-language-close' onClick={() => setShowSelector(false)}>
            <ExitIcon />
          </button>

          <div className='native-language-search-input-wrapper'>
            <input
              type='search'
              className='native-language-search-input'
              placeholder={t('searchLanguagePlaceholder')}
              value={languageSearchTerm}
              onChange={(event) => setLanguageSearchTerm(event.target.value)}
            />

            {!!languageSearchTerm && (
              <button className='native-language-search-input-clear' onClick={handleClearSearchTerm}>
                <ExitIcon />
              </button>
            )}
          </div>

          <LanguageList
            isSearchTermEmpty={!languageSearchTerm.length}
            filteredLanguages={filteredLanguages}
            onLanguageSelect={handleSetLanguage}
          />
        </div>
      </div>

      <div
        onClick={() => setShowSelector(false)}
        className={clsx('native-language-list-background', { show: showSelector })}
      />
    </>
  )
}

export default NativeLanguageList
