import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const ResumeIcon = ({ size = 16, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_123_234)'>
        <path
          d='M23.9549 10.7962L2.69228 0.175452C2.40543 0.0426588 2.08994 -0.0162957 1.77446 0.00386366C1.45898 0.0240231 1.15359 0.12271 0.885973 0.2909C0.618359 0.45909 0.397205 0.69151 0.242455 0.966943C0.0877057 1.24226 0.00440619 1.55204 6.5226e-05 1.86779V23.1C-0.0026332 23.4243 0.0784372 23.7436 0.235416 24.0272C0.392394 24.3108 0.620001 24.5493 0.89618 24.7196C1.17236 24.8897 1.48785 24.9858 1.81212 24.9985C2.1364 25.0114 2.45845 24.9402 2.74719 24.7924L23.9548 14.1717C24.269 14.0142 24.5331 13.7725 24.7178 13.4736C24.9022 13.1748 25 12.8305 25 12.4794C25 12.1282 24.9022 11.784 24.7178 11.4851C24.5332 11.1862 24.269 10.9446 23.9548 10.787V10.7962H23.9549Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_123_234'>
          <rect width='25' height='25' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
