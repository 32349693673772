import React, { ComponentType } from 'react'
import styles from './styles.module.scss'

import CircleLoader from '../../../../ui/Loaders/CircleLoader'
import { AnswerType } from '../../types.answer'
import ProblemAnswerSingleChoice from '../../../../components/Problem/ProblemAnswerChoice/ProblemAnswerSingleChoice'
import ProblemAnswerMultipleChoice from '../../../../components/Problem/ProblemAnswerChoice/ProblemAnswerMultipleChoice'
import ProblemAnswerOrderedChoice from '../../../../components/Problem/ProblemAnswerChoice/ProblemAnswerOrderedChoice'
import { ProblemAnswerGeogebra } from './variants/ProblemAnswerGeogebra'
import { HandwritingAnswerType } from './variants/HandwritingAnswerType'
import { UnsupportedAnswerType } from './UnsupportedAnswerType/UnsupportedAnswerType'
import { EmptyAssignment } from './EmptyAssignment'
import { ProblemAnswerStatus } from '../../models/answer'
import { UserSettings } from '../../../../lib/types'
import { AssignmentProblem } from '../../../ExerciseItem/types'
import { MyScriptMathCharacterTypes } from '../../../../constants/myScriptMathCharacterTypes'

export type ExerciseAnswerProps = {
  answerType: AnswerType
  answerStatus: ProblemAnswerStatus
  currentTask: AssignmentProblem
  characterType: MyScriptMathCharacterTypes
  allowAnswerLooking: boolean
  isLoading: boolean
  isEmpty: boolean
  isUnitRequired: boolean
  isSolved: boolean
  isExamFinished: boolean
  isExamStopped: boolean
  isExerciseInExamMode: boolean
  isCorrectAnswerStatus: boolean
  previousAnswer: string | string[]
  onNextButtonPress: () => void
  onSubmitClick: (answer: string | string[]) => void
  onAnswerChangeStart: () => void
  wiggleAnimation: boolean
  submitButtonProps: {
    text: string
    style: string
  }
  userGrade: UserSettings['grade']
  assignmentClassGrade: number
}

const ANSWER_TYPE_VARIANTS: Record<AnswerType, ComponentType<ExerciseAnswerProps>> = {
  [AnswerType.HANDWRITING]: HandwritingAnswerType,
  [AnswerType.SINGLE_CHOICE]: ProblemAnswerSingleChoice,
  [AnswerType.MULTIPLE_CHOICE]: ProblemAnswerMultipleChoice,
  [AnswerType.ORDERED_CHOICE]: ProblemAnswerOrderedChoice,
  [AnswerType.GEOGEBRA]: ProblemAnswerGeogebra,
}

export const ExerciseAnswer = ({
  answerType,
  isLoading,
  isEmpty,
  onNextButtonPress,
  isExamStopped,
  ...props
}: ExerciseAnswerProps) => {
  if (isLoading) return <CircleLoader />
  if (isEmpty) return <EmptyAssignment />

  const AnswerVariant = ANSWER_TYPE_VARIANTS[answerType]
  if (!AnswerVariant) {
    return <UnsupportedAnswerType onClick={onNextButtonPress} isExamStopped={isExamStopped} />
  }

  return (
    <div className={styles.ExerciseAnswerContainer}>
      <AnswerVariant
        answerType={answerType}
        isLoading={isLoading}
        isEmpty={isEmpty}
        onNextButtonPress={onNextButtonPress}
        isExamStopped={isExamStopped}
        {...props}
      />
    </div>
  )
}
