import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { Button, BUTTON_VARIANT, SIZE, COLORS } from '@magmamath/ui'

import styles from './styles.module.scss'

import { ChangeGradeActions } from '../types'
import { getChangedGrade, getIsChangeYearDisabled } from './helpers'
import TreeNavigationMenu from './PracticePageNavigationMenu/TreeNavigationMenu'
import useOutsideClick from '../../../lib/hooks/useOutsideClick'
import { MapIcon } from '../../../ui/icons/MapIcon/MapIcon'
import { useUnit } from 'effector-react'
import { $practiceGrade, setPracticeGrade } from '../models/currentPracticeGrade'
import { $availableGrades } from '../models/availableGrades'
import { practiceSkillsModel } from '../../practiceModeSkills/model/skillsTreeModel'
import { inViewSectionModel } from '../../practiceModeSkills/model/inViewSectionModel'

const PracticePageTreeNavigation = () => {
  const { t } = useTranslation()
  const [isNavigationMenuShown, setIsShownNavigationMenu] = useState(false)
  const practiceGrade = useUnit($practiceGrade)
  const availableGrades = useUnit($availableGrades)

  const intersectedElementId = useUnit(inViewSectionModel.$intersectedTreeElementId)
  const gradeSubtree = useUnit(practiceSkillsModel.$gradeSubtree)

  useEffect(() => {
    if (availableGrades.includes(practiceGrade) || !availableGrades.length) {
      return
    }

    const newGrade = getChangedGrade({
      type: ChangeGradeActions.DECREASE,
      currentGrade: practiceGrade,
      filteredGrades: availableGrades,
      newGrade: practiceGrade - 1,
    })

    setPracticeGrade(Math.max(1, newGrade))
  }, [availableGrades])

  const onChangeGrade = (type: ChangeGradeActions) => {
    const newGrade: number = type === ChangeGradeActions.DECREASE ? practiceGrade - 1 : practiceGrade + 1
    setPracticeGrade(getChangedGrade({ type, currentGrade: practiceGrade, filteredGrades: availableGrades, newGrade }))
  }

  const isChangingYearDisabled = (type: ChangeGradeActions) => {
    return getIsChangeYearDisabled({ type, currentGrade: practiceGrade, filteredGrades: availableGrades })
  }

  const ref = useOutsideClick(() => setIsShownNavigationMenu(false))

  if (!gradeSubtree) {
    return null
  }

  return (
    <div className={styles.PracticePageNavigationContainer} ref={ref}>
      <Button
        size={SIZE.LARGE}
        variant={BUTTON_VARIANT.SECONDARY}
        onClick={() => setIsShownNavigationMenu(!isNavigationMenuShown)}
        icon={<MapIcon size={20} color={!availableGrades.length ? COLORS.NEUTRAL_5 : COLORS.NEUTRAL_9} />}
        isActive={isNavigationMenuShown}
        disabled={!availableGrades.length}
      >
        {t('practiceMap')}
      </Button>
      {isNavigationMenuShown && (
        <TreeNavigationMenu
          grade={practiceGrade}
          practiceGradeTree={gradeSubtree}
          currentMainContentBlockId={intersectedElementId}
          getIsChangeGradeDisabled={isChangingYearDisabled}
          onChangeGrade={onChangeGrade}
        />
      )}
    </div>
  )
}

export default PracticePageTreeNavigation
