import { AnswerType } from '../../../../Problem/types.answer'

type getSelectedAnswersProps = {
  selectedAnswers: number[]
  type: AnswerType
  answerId: number
}

export const getSelectedAnswers = ({ selectedAnswers, type, answerId }: getSelectedAnswersProps): number[] => {
  if (type === AnswerType.SINGLE_CHOICE) {
    return [answerId]
  }

  const selectedAnswersCopy = [...selectedAnswers]
  const answerIndex = selectedAnswersCopy.findIndex((selectedAnswer) => {
    return selectedAnswer === answerId
  })

  if (answerIndex === -1) {
    return [...selectedAnswersCopy, answerId]
  }

  const deletedCount = type === AnswerType.ORDERED_CHOICE ? Infinity : 1
  selectedAnswersCopy.splice(answerIndex, deletedCount)
  return [...selectedAnswersCopy]
}
