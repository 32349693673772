import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

import SubmitAnswerButton from '../../../../ui/buttons/SubmitAnswerButton'
import AnswerFeedback from '../../../../components/AnswerFeedback'
import { ReactComponent as TrashIcon } from '../../../../assets/images/trashcan.svg'

import { resetGeogebraState } from '../../helpers/geogebraApiHelpers'
import type { AssignmentProblem } from '../../../ExerciseItem/types'
import AnswerHintContent from '../../../../components/AnswerHint/AnswerHintContent'
import { MISTAKE } from '../../../../constants/answerStatusConstants'
import { currentProblemAnswerModel } from '../../../Problem/models/answer'
import { GEOGEBRA_ANSWER_CLEARED } from '../../constants'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../../../Problem/problem.style.mode.helpers'
import AnswerInfoContext from '../../../../context/answerInfoContext'

type Props = {
  buttonText: string
  buttonStyle: string
  isDisabled: boolean
  currentTask: AssignmentProblem
  onAnswerSubmit: () => void
  wiggleAnimation: boolean
  answerStatus: string
  isExamFinished: boolean
}

export const AnswerAreaGeogebra = ({
  buttonText = 'Submit',
  buttonStyle,
  isDisabled = false,
  currentTask,
  onAnswerSubmit,
  wiggleAnimation,
  answerStatus,
  isExamFinished = false,
}: Props) => {
  const { onAnswerHintChecked: sendAnswerSeenNotification } = useContext(AnswerInfoContext)
  const [hasPreviewed, setHasPreviewed] = useState(false)
  const [isHintVisible, setIsHintVisible] = useState(isExamFinished)
  const isHintEnabled = answerStatus === MISTAKE || isExamFinished
  const { exerciseMode } = useCurrentExerciseMode()

  const clearCanvasUserInput = () => {
    currentProblemAnswerModel.resetStatus()
    currentProblemAnswerModel.setText(GEOGEBRA_ANSWER_CLEARED)
    resetGeogebraState(currentTask)
  }

  const previewAnswerHint = () => {
    if (isHintVisible) {
      setIsHintVisible(false)
      return
    }

    setIsHintVisible(true)
    if (!hasPreviewed) {
      sendAnswerSeenNotification()
      setHasPreviewed(true)
    }
  }

  return (
    <div className={styles.AnswerAreaGeogebra}>
      <button
        onClick={clearCanvasUserInput}
        className={clsx(styles.ClearIconButton, { [styles.Disabled]: isExamFinished })}
        disabled={isExamFinished}
      >
        <TrashIcon width={25} height={25} />
      </button>

      <div className={styles.HintWrapper}>
        <div className={styles.AnswerFeedback}>
          {isHintEnabled && (
            <button className={styles.HintButton} onClick={previewAnswerHint}>
              <AnswerHintContent showAnswerHint={isHintVisible} />
            </button>
          )}

          <AnswerFeedback />
        </div>

        <SubmitAnswerButton
          onClick={() => {
            if (!isExamFinished) {
              setIsHintVisible(false)
            }

            onAnswerSubmit()
          }}
          style={buttonStyle}
          modeStyle={ExerciseThemeVariantMapper[exerciseMode]}
          disabled={isDisabled}
          animation={wiggleAnimation}
        >
          {buttonText}
        </SubmitAnswerButton>
      </div>
    </div>
  )
}
