export enum CheatType {
  LEAVING_BUTTON = 0,
  TEXT_REPLACEMENT = 1,
  LEAVING_BROWSER_OR_TAB = 2,
}

export type SendCheatReportPayload = {
  exerciseId: string
  cheatType: CheatType
}
