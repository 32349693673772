export const USER_DATA = 'userData'
export const PREVIOUS_DRAWING = 'previousDrawing'
export const LOCALE = 'i18nextLng'
export const REDIRECT_URL = 'redirectURL'
export const RECENT_PROBLEM_ID = 'recentProblemId'
export const RECENT_SUBPROBLEM_ID = 'recentSubProblemId'
export const SPREADSHEET_DATA = 'spreadsheetData'
export const ACTIVE_SUBCATEGORY_ID = 'activeAbilityId'
export const PRACTICE_PAGE_SCROLL_POSITION = 'practicePageScrollPosition'
export const CURRENT_ABILITY_INFO = 'currentAbilityInfo'
export const NEXT_PROMPTED_ABILITY_INFO = 'nextPromptedAbilityInfo'
export const CURRENT_ABILITIES_STATISTIC = 'currentAbilitiesStatistic'
export const CURRENT_PRACTICE_GRADE = 'currentPracticeGrade'
