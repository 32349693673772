import React, { memo } from 'react'
import styles from './zoomableImage.module.scss'
import Zoom from 'react-medium-image-zoom'
import { setUpExportableZoomContent } from './ExportableZoomContent'
import { useLongPressDrag } from '../../lib/hooks/useLongPressDrag'
import { Image } from '../../components/Image/Image'
import { ImageProps } from '../../components/Image/types'
import { createImageSrc } from '../../components/Image/createImageSrc'

type ProblemDescriptionImageProps = {
  alt?: string
  exportable?: boolean
  onExport?: (src: string) => void
  onDragStart?: (event: React.DragEvent, imageSrc: string) => {}
  style?: React.CSSProperties
  isZoomDisabled?: boolean
  imageProps: ImageProps
}

export const ZoomableImage = memo(
  ({
    alt,
    exportable,
    onExport,
    onDragStart,
    style,
    isZoomDisabled = false,
    imageProps,
  }: ProblemDescriptionImageProps) => {
    const dndImageSrc = createImageSrc({
      src: imageProps.src,
      cdnSrc: imageProps.cdnSrc,
      format: 'webp',
      size: 'large',
    })

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
      if (!exportable) {
        event.preventDefault()
        return
      }

      onDragStart?.(event, dndImageSrc)
    }

    const draggable = useLongPressDrag({
      onDragStart: handleDragStart,
      delayMs: 100,
    })

    return (
      <div
        onContextMenu={(event) => event.preventDefault()}
        className={styles.Container}
        draggable={!!exportable}
        {...draggable.bind}
      >
        {isZoomDisabled ? (
          <Image
            key={imageProps.src}
            style={style}
            alt={alt}
            size='small'
            src={imageProps.src}
            cdnSrc={imageProps.cdnSrc}
          />
        ) : (
          <Zoom
            key={imageProps.src}
            ZoomContent={
              exportable
                ? setUpExportableZoomContent({
                    onExportButtonPressed: () => onExport?.(dndImageSrc),
                  })
                : undefined
            }
            classDialog={styles.Zoomed}
            zoomMargin={140}
          >
            <Image
              style={style}
              alt={alt}
              size='medium'
              src={imageProps.src}
              cdnSrc={imageProps.cdnSrc}
              draggable
              {...draggable.bind}
            />
          </Zoom>
        )}
      </div>
    )
  }
)
