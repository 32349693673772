import React, { useEffect, useRef } from 'react'
import EmptyContainer from '../../ui/emptyContainer/EmptyContainer'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'
import { activeSubCategoryModel, useActiveSubCategoryModel } from '../PracticeMode/models/activeSubCategory'
import useHighlightPracticeTreeItemsOnScroll from './hooks/useHighlightPracticeTreeItemsOnScroll'
import { practicePageSessionActions } from '../PracticeMode/sessionStorage'
import { $availableGrades } from '../PracticeMode/models/availableGrades'
import { DomainSubtree } from './components/DomainSubtree/DomainSubtree'
import { useOnIntersect } from '../../lib/hooks/useOnIntersect'
import { inViewSectionModel } from './model/inViewSectionModel'

const SkillsTreeRoot = () => {
  const { t } = useTranslation()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)

  const availableGrades = useUnit($availableGrades)
  const intersectedDomain = useUnit(inViewSectionModel.$intersectedDomain)

  useHighlightPracticeTreeItemsOnScroll({ scrollArea: scrollContainerRef })

  useEffect(() => {
    if (!intersectedDomain) return

    const isActiveTreeInMainTree = intersectedDomain.children?.some(
      (practiceTree) => practiceTree?._id === activeSubCategoryId
    )
    !isActiveTreeInMainTree && activeSubCategoryModel.setId(intersectedDomain.children?.[0]?._id || '')
  }, [activeSubCategoryId, intersectedDomain])

  const restoreScrollPosition = () => {
    const previousScroll = practicePageSessionActions.lastScrollPosition.get()
    if (!previousScroll) return

    practicePageSessionActions.lastScrollPosition.remove()
    scrollContainerRef.current?.scroll({ top: Number(previousScroll), behavior: 'smooth' })
  }

  useOnIntersect({
    ref: scrollContainerRef,
    callback: restoreScrollPosition,
  })

  return (
    <div className='practice-page-info' ref={scrollContainerRef} id='practice-page-scroll-area'>
      {!availableGrades.length ? (
        <EmptyContainer message={t('noAvailableGradesText')} />
      ) : (
        <DomainSubtree ref={scrollContainerRef} />
      )}
    </div>
  )
}

export default SkillsTreeRoot
