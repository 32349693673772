import React, { useState, useEffect, useContext } from 'react';

import AnswerInfoContext from '../../context/answerInfoContext';
import AnswerHintContent from './AnswerHintContent';

const AnswerHint = () => {
    const { answerHintChecked, onAnswerHintChecked } = useContext(AnswerInfoContext);
    const [showAnswerHint, setShowAnswerHint] = useState(false);
    const [answerHintError, setAnswerHintError] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const sendRequestAndManageHint = async () => {
        const response = await onAnswerHintChecked();
        if (!response) {
            !answerHintError && setAnswerHintError(true);
        } else {
            answerHintError && setAnswerHintError(false);
        }
    };

    const onRefreshButtonPress = async () => {
        setIsRefreshing(true);
        await sendRequestAndManageHint();
        setIsRefreshing(false);
    };

    const manageClickingAnswerHint = async (e) => {
        const answerHintIcon = document.getElementById('answer-hint-icon');
        const answerHintContainer = document.getElementById('answer-hint-container');
        if (!answerHintIcon || !answerHintContainer) {
            return;
        }

        const zoomOutButton = document.querySelector('[aria-label="Unzoom image"]');
        if (zoomOutButton && zoomOutButton.contains(e.target) && showAnswerHint) {
            return;
        }

        if (answerHintIcon.contains(e.target)) {
            if (showAnswerHint) {
                setShowAnswerHint(false);
                return;
            }

            if (!answerHintChecked) {
                await sendRequestAndManageHint();
            }

            setShowAnswerHint(true);
            return;
        }

        if (answerHintContainer.contains(e.target)) {
            return;
        }

        setShowAnswerHint(false);
    };

    useEffect(() => {
        document.addEventListener('click', manageClickingAnswerHint);

        return () => {
            document.removeEventListener('click', manageClickingAnswerHint);
        };
    });

    return (
        <AnswerHintContent
            showAnswerHint={showAnswerHint}
            answerHintError={answerHintError}
            isRefreshing={isRefreshing}
            onRefreshButtonPress={onRefreshButtonPress}
        />
    )
};

export default AnswerHint;