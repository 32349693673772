import { ApiMethod } from '../lib/apiManager/types'
import { AutoLoginRequestProps, AutoLoginResponse } from './types.auth'
import { MeResponse, UpdateUserSettingsProps } from './types.user'
import {
  CheckAnswerHintProps,
  GetProblemHintFeedbackProps,
  ProblemSessionsPayload,
  SolutionStatusesPayload,
} from './types.solutions'
import { GetAssignmentProps } from './types.assignments'
import { practiceApi } from './api.practice'
import { googleApi } from './api.google'
import { apiManager } from './apiManager'
import { chatApi } from './api.chat'
import { helpQueueApi } from './api.helpQueue'
import { drawBoardHistoryApi } from './api.drawBoard'
import { SendCheatReportPayload } from './types.cheats'
import { Assignment } from '../features/ExerciseItem/types'

type UserStatisticsResponse = {
  correctSolutionsCount: number
  goodDrawingCount: number
  starsInPracticeModeCount: number
  assignmentCompletedCount: number
}

type JoinNewClassProps = {
  userId: string
  classCode: string
}

type JoinNewClassResponse = {
  name: string
}

export type ImageMetaData = {
  altText?: string
  containsData?: boolean
}

type GetAssignmentsListProps = {
  status?: string
  page: number
  type: string
  sortBy?: string
}

type WithToken<T> = { token: string; body: T }

export class api {
  public static readonly manager = apiManager
  public static readonly chat = chatApi
  public static readonly practice = practiceApi
  public static readonly google = googleApi
  public static readonly helpQueue = helpQueueApi
  public static readonly drawBoardHistory = drawBoardHistoryApi

  public static readonly uploadImage = apiManager.post<UploadImageResponse, string | FormData>('images/upload')
  public static readonly imageMetaData = apiManager.get<ImageMetaData, string>(
    (imageName) => `images/${imageName}/metadata`
  )

  public static readonly autoLogin = apiManager.unprotectedRequest<AutoLoginResponse, AutoLoginRequestProps>(
    ApiMethod.POST,
    'autologin'
  )
  public static readonly me = apiManager.get<MeResponse>('auth/me')
  public static readonly updateUserSettings = apiManager.put<void, UpdateUserSettingsProps>('users/me/settings')
  public static readonly sendSolutionStatusesFromQueue = apiManager.post<
    SendSolutionStatusesResponse,
    WithToken<SolutionStatusesPayload>
  >({
    driver: 'fetch',
    keepalive: true,
    data: ({ token, body }) => ({
      url: 'solution-statuses',
      headers: {
        Authorization: token,
      },
      body,
    }),
  })
  public static readonly sendProblemSessionFromQueue = apiManager.post<
    SendProblemSessionResponse,
    WithToken<ProblemSessionsPayload>
  >({
    driver: 'fetch',
    keepalive: true,
    data: ({ token, body }) => ({
      url: 'problem-sessions',
      headers: {
        Authorization: token,
      },
      body,
    }),
  })
  public static readonly sendSolutionStatuses = apiManager.post<SendSolutionStatusesResponse, SolutionStatusesPayload>(
    'solution-statuses'
  )
  public static readonly sendProblemSession = apiManager.post<SendProblemSessionResponse, ProblemSessionsPayload>(
    'problem-sessions'
  )
  public static readonly answerHintSeen = apiManager.post<AnswerHintSeenResponse, CheckAnswerHintProps>(
    ({ exerciseId, problemId, subProblemId }) => {
      const subProblemAddon = subProblemId ? `subproblems/${subProblemId}/` : ''
      return `/statistics/exercises/${exerciseId}/problems/${problemId}/${subProblemAddon}seen`
    }
  )
  public static readonly getAssignmentsList = apiManager.get<AssignmentsListResponse, GetAssignmentsListProps>(
    'exercises'
  )
  public static readonly getAssignment = apiManager.get<AssignmentResponse, GetAssignmentProps>((props) => {
    if (typeof props === 'object') {
      const params: Record<string, string | number> = {}
      if (props.fetchAll !== undefined) params.fetchAll = +props.fetchAll
      return { url: `exercises/${props.id}`, params }
    }
    return `exercises/${props}`
  })
  public static readonly getAssignmentStatistics = apiManager.get<AssignmentStatisticsResponse, string>(
    'statistics/exercises'
  )
  public static readonly getProblemTheory = apiManager.get<ProblemTheoryResponse, string>(
    (problemId) => `problems/${problemId}/theory`
  )
  public static readonly getHintFeedback = apiManager.post<HintFeedbackResponse, GetProblemHintFeedbackProps>(
    'hints/feedback'
  )
  public static readonly getProblemSpecificHint = apiManager.get<ProblemSpecificHintResponse, string>('hintTypes')
  public static readonly userStatistics = apiManager.get<UserStatisticsResponse>('statistics/badge')
  public static readonly joinClass = apiManager.post<JoinNewClassResponse, JoinNewClassProps>(
    ({ userId, classCode }) => ({
      url: `/students/${userId}/classes`,
      body: { code: classCode },
    })
  )
  public static readonly cheatReport = apiManager.post<CheatReportResponse, SendCheatReportPayload>(
    ({ exerciseId, cheatType }) => ({
      url: `/statistics/exercises/${exerciseId}/cheats`,
      body: { cheatType },
    })
  )
}

type UploadImageResponse = any
type SendSolutionStatusesResponse = any
type SendProblemSessionResponse = any
type AnswerHintSeenResponse = any
type AssignmentsListResponse = {
  total: number
  pages: number
  _embedded: { exercises: Assignment[] }
  _links: {
    next: { href: string }
    self: { href: string }
  }
}

type AssignmentResponse = any
type AssignmentStatisticsResponse = any
type ProblemTheoryResponse = any
type HintFeedbackResponse = any
type ProblemSpecificHintResponse = any
type CheatReportResponse = any
