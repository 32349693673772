import React from 'react'

import { IconProps } from './_types'
import { COLORS } from '@magmamath/ui'

const RedoIcon: React.FC<IconProps> = ({ size = 25, color = COLORS.NEUTRAL_10 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.3638 24.1504C19.3638 24.1504 19.2653 24.1504 8.73267 24.1504C-1.79997 24.1504 -1.35272 6.3504 8.73267 6.3504C18.818 6.3504 22.7645 6.3504 22.7645 6.3504'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
      />
      <path
        d='M23.3544 6.9373C23.6786 6.61317 23.6786 6.08764 23.3544 5.7635L18.0723 0.481417C17.7482 0.157281 17.2227 0.157281 16.8985 0.481417C16.5744 0.805552 16.5744 1.33108 16.8985 1.65521L21.5937 6.3504L16.8985 11.0456C16.5744 11.3697 16.5744 11.8953 16.8985 12.2194C17.2227 12.5435 17.7482 12.5435 18.0723 12.2194L23.3544 6.9373ZM15.0859 7.1804H22.7675V5.5204H15.0859V7.1804Z'
        fill={color}
      />
    </svg>
  )
}

export default RedoIcon
