import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { PracticeProblem } from '../../Problem/types'

export enum PracticeMode {
  REGULAR = 'regular',
  SIMPLE = 'simple',
}

export type PracticeProblemsStates = {
  problemsList: PracticeProblem[]
  _id: string
  currentProblemIndex: number
  currentProblem: PracticeProblem | null
  endProblems: boolean
  rightAnswersCount: number
  practiceMode: PracticeMode
}

export type PracticeProblemsModel = PracticeProblemsStates & {
  setProblemsList: (problemsList: PracticeProblem[]) => void
  setCurrentProblemIndex: (index: number) => void
  updateCurrentProblem: (updatedProblem: PracticeProblem) => void
  setId: (id: string) => void
  setEndProblems: (endProblems: boolean) => void
  setRightAnswer: () => void
  setIsSubmitted: () => void
  resetRightAnswersCount: () => void
  setPracticeMode: (mode: PracticeMode) => void
}

const initialState: PracticeProblemsStates = {
  problemsList: [],
  _id: '',
  currentProblemIndex: 0,
  currentProblem: null,
  endProblems: false,
  rightAnswersCount: 0,
  practiceMode: PracticeMode.REGULAR,
}

const usePracticeProblemsStore = create(
  immer<PracticeProblemsModel>((set) => ({
    ...initialState,
    setProblemsList: (problemsList) =>
      set((state) => {
        state.problemsList = problemsList
      }),
    setCurrentProblemIndex: (index) =>
      set((state) => {
        const problem = state.problemsList[index]
        state.currentProblem = problem ?? null
        state.currentProblemIndex = index
      }),
    updateCurrentProblem: (updatedProblem) =>
      set((state) => {
        state.currentProblem = updatedProblem
      }),
    setId: (id) =>
      set((state) => {
        state._id = id
      }),
    setEndProblems: (endProblems) =>
      set((state) => {
        state.endProblems = endProblems
      }),
    setRightAnswer: () =>
      set((state) => {
        state.rightAnswersCount = state.rightAnswersCount + 1
      }),
    resetRightAnswersCount: () =>
      set((state) => {
        state.rightAnswersCount = 0
      }),
    setIsSubmitted: () =>
      set((state) => {
        const problemsListCopy = [...state.problemsList]
        problemsListCopy[state.currentProblemIndex] = {
          ...problemsListCopy[state.currentProblemIndex],
          isSubmittedInCurrentSession: true,
        }

        state.problemsList = problemsListCopy
      }),
    setPracticeMode: (practiceMode) =>
      set((state) => {
        state.practiceMode = practiceMode
      }),
  }))
)

export default usePracticeProblemsStore
