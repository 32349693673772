import { COLORS } from '@magmamath/ui'
import React from 'react'

type IconProps = {
  width?: number
  height?: number
  fill?: string
  stroke?: string
}

export const ExitIcon = ({ width = 25, height = 25, fill = 'none', stroke = COLORS.NEUTRAL_10 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 25 25' fill={fill}>
      <g clipPath='url(#clip0_764_2616)'>
        <path d='M0.796875 0.796753L24.1839 24.1838' stroke={stroke} strokeWidth='1.66' strokeLinecap='round' />
        <path d='M24.1839 0.796753L0.796875 24.1838' stroke={stroke} strokeWidth='1.66' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_764_2616'>
          <rect width='25' height='25' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
