import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const ChevronLeftIcon = ({ size = 25, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_40)'>
        <path
          d='M16.4419 1.90244L7.62248 10.3544C7.26889 10.6932 7.26889 11.2584 7.62248 11.5973L16.4419 20.0492'
          stroke='#ffffff'
          strokeWidth='3.62935'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_40'>
          <rect width='21.7761' height='22.2691' fill={fill} transform='matrix(-1 0 0 1 21.8859 0.0877533)' />
        </clipPath>
      </defs>
    </svg>
  )
}
