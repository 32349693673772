import i18next from 'i18next'
import { AppLanguage, AppLocale, Detector } from './i18n.config'
import { LocalStorageKeys } from '../api/localStorage/keys'
import { noop } from '../lib/helpers'

// TODO: use AppLanguage enum and double-check 'sv' usage
export const getLocaleCode = (appLanguage: AppLanguage) => {
  return {
    [AppLanguage.EN]: AppLocale.EN,
    [AppLanguage.SV]: AppLocale.SV,
    [AppLanguage.UK]: AppLocale.UK,
  }[appLanguage]
}

export const setI18nextLocale = async (userAppLanguage: AppLanguage) => {
  const i18nextLocale = getLocaleCode(userAppLanguage)
  const currentLocale = localStorage.getItem(LocalStorageKeys.LOCALE)
  if (currentLocale === i18nextLocale) return

  localStorage.setItem(LocalStorageKeys.LOCALE, i18nextLocale)
  await i18next.changeLanguage(i18nextLocale)
}

export function initLocaleFromOrigin() {
  const locationOrigin = window.location.origin
  const originLocale = locationOrigin.includes('com') ? AppLocale.EN : AppLocale.SV
  const currentLocale = localStorage.getItem(LocalStorageKeys.LOCALE)
  if (currentLocale !== originLocale) {
    localStorage.setItem(LocalStorageKeys.LOCALE, originLocale)
  }
  if (i18next.language !== originLocale) {
    i18next.changeLanguage(originLocale).catch(noop)
  }
}

const isActiveLocale = (value: string): value is AppLanguage => {
  return Object.values(AppLanguage).includes(value as AppLanguage)
}

export const customSearchParamDetector = {
  name: Detector.CUSTOM_SEARCH_PARAM_DETECTOR,
  lookup() {
    const { searchParams } = new URL(window.location.href)

    const locale = searchParams.get('locale')

    if (locale && isActiveLocale(locale)) {
      return locale
    }

    return undefined
  },
}

export const customDomainDetector = {
  name: Detector.CUSTOM_DOMAIN_DETECTOR,
  lookup() {
    const { hostname } = window.location

    if (hostname.endsWith('.se')) {
      return AppLanguage.SV
    }
    if (hostname.endsWith('.com')) {
      return AppLanguage.EN
    }

    return undefined
  },
}
