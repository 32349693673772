import { Assignment, AssignmentProblem } from '../ExerciseItem/types'

export const mergeAssignmentProblemsStats = (problems: AssignmentProblem[], newProblems: AssignmentProblem[]) => {
  return newProblems.reduce((acc, newProblem) => {
    const existingProblem = problems.find((p) => p.name === newProblem.name)
    acc.push({
      ...newProblem,
      studentStats: existingProblem ? existingProblem.studentStats : undefined,
    })
    return acc
  }, [] as AssignmentProblem[])
}

export const updateSingleAssignment = (state: Assignment[], newAssignment: Assignment) =>
  state.map((assignment) =>
    assignment._id === newAssignment._id
      ? {
          ...newAssignment,
          problems: mergeAssignmentProblemsStats(assignment.problems, newAssignment.problems),
        }
      : assignment
  )
