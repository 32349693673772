import { useStoreMap } from 'effector-react'
import { $messages } from '../models/messages'
import { useAssignmentCredentials } from '../../assignment/hooks/useAssignmentCredentials'

export const useChatMessages = () => {
  const { id, problemId } = useAssignmentCredentials()
  return useStoreMap({
    store: $messages,
    keys: [id, problemId],
    fn: (store, [assignmentId, problemId]) => store?.[assignmentId]?.[problemId],
    defaultValue: [],
  })
}
