import { useEffect, useState } from 'react'
import { createEvent, createStore } from 'effector'
import { useUnit } from 'effector-react'
import { api, ImageMetaData } from '../../../api'

type ImageCache = {
  imageSrc: string
  metadata: ImageMetaData | null
}

type Store = Record<string, ImageMetaData | null>

const addedMetaData = createEvent<ImageCache>()
const $imagesMetadataCache = createStore<Store>({}).on(addedMetaData, (store, { imageSrc, metadata }) => ({
  ...store,
  [imageSrc]: metadata,
}))

export const useImageMetaData = (imageSrc: string) => {
  const cache = useUnit($imagesMetadataCache)
  const [metadata, setMetadata] = useState<ImageMetaData | null>(null)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (!imageSrc) return

    const imageName = imageSrc.split('/').at(-1)
    if (!imageName) return

    if (cache[imageSrc]) {
      setMetadata(cache[imageSrc])
      return
    }

    const fetchImageMetaData = async () => {
      try {
        setIsFetching(true)
        const metadata = await api.imageMetaData(imageName)
        setMetadata(metadata)
        addedMetaData({
          imageSrc,
          metadata,
        })
      } catch (error) {
        console.error('Error loading image metadata:', error)
        setMetadata(null)

        addedMetaData({
          imageSrc,
          metadata: null,
        })
      } finally {
        setIsFetching(false)
      }
    }

    fetchImageMetaData()
  }, [cache, imageSrc])

  return {
    data: metadata,
    isFetching,
  }
}
