import React from 'react'
import { InlineMath } from 'react-katex'

import Markdown from '../components/katexMarkdown/Markdown'
import { GREATER_SYMBOL } from '../constants/katexConstants'
import { COLORS } from '@magmamath/ui'

export const takeNeededProblemDescription = (
  richDescription,
  katexDescription,
  description,
  translatedDescription,
  mergeLinebreaks
) => {
  if (richDescription) {
    return <Markdown mergeLinebreaks={mergeLinebreaks}>{translatedDescription || richDescription}</Markdown>
  }

  if (katexDescription) {
    return <InlineMath math={translatedDescription || katexDescription} />
  }

  return translatedDescription || description
}

export const getCorrectAnswerVariantDisplay = (answerVariant) => {
  if (!answerVariant?.length) {
    return ''
  }

  if (answerVariant[0] === GREATER_SYMBOL) return answerVariant

  return <Markdown>{answerVariant}</Markdown>
}

export const uniteCanvasElements = (canvasDraw) => {
  const [firstCanvas, secondCanvas, thirdCanvas, fourthCanvas] = canvasDraw?.firstChild?.children

  const canvasElement = document.createElement('canvas')
  canvasElement.style.background = COLORS.NEUTRAL_1
  canvasElement.background = COLORS.NEUTRAL_1
  canvasElement.width = firstCanvas.width
  canvasElement.height = firstCanvas.height
  const canvasElementCtx = canvasElement.getContext('2d')

  canvasElementCtx.drawImage(fourthCanvas, 0, 0)
  canvasElementCtx.drawImage(secondCanvas, 0, 0)
  canvasElementCtx.drawImage(thirdCanvas, 0, 0)
  canvasElementCtx.drawImage(firstCanvas, 0, 0)

  const textPortal = document.getElementById('portal-for-text')
  const textPortalChildren = textPortal?.children
  if (textPortalChildren?.length) {
    const firstTextArea = textPortalChildren[0]
    const computedStyle = window.getComputedStyle(firstTextArea, null)
    const fontSize = computedStyle.getPropertyValue('font-size')
    const fontFamily = computedStyle.getPropertyValue('font-family')
    canvasElementCtx.font = `${fontSize} ${fontFamily}`
    for (let textArea of textPortalChildren) {
      const lineHeight = Number(window.getComputedStyle(textArea).getPropertyValue('line-height').replace('px', ''))
      const lines = textArea.value.split('\n')
      let offsetTop = textArea.offsetTop
      for (let line of lines) {
        if (offsetTop >= canvasElement.height) {
          break
        }

        canvasElementCtx.fillText(line, textArea.offsetLeft, offsetTop)
        offsetTop += lineHeight
      }
    }
  }

  return { unitedCanvas: canvasElement, unitedCanvasCtx: canvasElementCtx }
}

export const getArrayFromHTMLCollection = (className) => {
  return Array.from(document.getElementsByClassName(className))
}
