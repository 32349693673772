import {
  cleanEmptyTags,
  performFractionWithoutTags,
  performFractionWithSlashWithoutMathExpressions,
  performSquareRoot,
  surroundFractionItemsWithBrackets,
} from '../../exercises/answerProblemHelper'

export const cleanLatexAnswer = (latexWithHTML: string) => {
  latexWithHTML.replace(/×/g, '*')
  latexWithHTML = performFractionWithoutTags(latexWithHTML)
  latexWithHTML = performFractionWithSlashWithoutMathExpressions(latexWithHTML)
  latexWithHTML = surroundFractionItemsWithBrackets(latexWithHTML)
  latexWithHTML = cleanEmptyTags(latexWithHTML)

  latexWithHTML = latexWithHTML.replace(/<div class=['|"]latex-square-root['|"]>/g, '[')
  latexWithHTML = performSquareRoot(latexWithHTML)

  return latexWithHTML
    .replace(/<sup[^>]*>?/gm, '^[')
    .replace(/<div class=['|"]fraction['|"]>/g, '[')
    .replace(/<span[^>]*denominator['|"]>/g, '/')
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/<\/(sup|div)>?/gm, ']')
    .replace(/<div>|<\/div>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/<br>/g, '')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
}
