import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { NativeButtonProps } from '../../lib/types/HTMLElements.types'

type ButtonProps = {
  text: string
  variant: 'success' | 'error' | 'warning' | 'exam' | 'outlined' | 'primary' | 'practice'
  isDisabled?: boolean
  className?: string
  fadeOnHover?: boolean
} & NativeButtonProps

export const Button = ({ text, className, variant, isDisabled, fadeOnHover, ...restProps }: ButtonProps) => {
  const mergedClassNames = clsx(className, styles.ButtonExit, {
    [styles.Success]: variant === 'success',
    [styles.Error]: variant === 'error',
    [styles.Warning]: variant === 'warning',
    [styles.Exam]: variant === 'exam',
    [styles.Outlined]: variant === 'outlined',
    [styles.Primary]: variant === 'primary',
    [styles.Practice]: variant === 'practice',
    [styles.FadeOnHover]: fadeOnHover,
    [styles.Disabled]: isDisabled,
  })

  return (
    <button className={mergedClassNames} disabled={isDisabled} {...restProps}>
      {text}
    </button>
  )
}
