import React from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { JoinClass } from '../../features/joinClass/JoinClass'
import { setIsJoinClassOpen } from '../../features/joinClass/model/model'

const AddNewClass = () => {
  const { t } = useTranslation()

  return (
    <>
      <button className='add-new-class-button' onClick={() => setIsJoinClassOpen(true)}>
        {t('joinNewClassText')}
      </button>

      <JoinClass />
    </>
  )
}

export default AddNewClass
