import { AppletApiRef, GeogebraSettings, ToolbarTools, ToolbarToolsNames } from '../types'
import { createFormDataFromBase64 } from '../../../components/Problem/helpers.images'
import { removeWhitespaces } from '../../../helpers'
import type { AssignmentProblem } from '../../ExerciseItem/types'
import { useGeogebraModel } from '../model'
import { currentProblemAnswerModel } from '../../Problem/models/answer'

type LimitPointsCountProps = {
  event: string
  appletApi: AppletApiRef
  limitCount: number
}

type DeleteAllInputFromGeogebraAppletProps = {
  appletApi?: AppletApiRef
  removePrefilledFunction: boolean
}

export const limitPointsCount = ({ event, appletApi, limitCount }: LimitPointsCountProps) => {
  if (!limitCount) return

  const objType = appletApi.getObjectType(event)
  const allObjects = appletApi.getAllObjectNames()
  const points = allObjects.filter((objName) => appletApi.getObjectType(objName) === ToolbarToolsNames.POINT)

  if (objType === ToolbarToolsNames.POINT || objType === ToolbarToolsNames.LINE) {
    if (points.length <= limitCount) return

    appletApi.setMode(ToolbarTools.MOVE)

    const lastPoint = allObjects.at(-1)
    if (lastPoint) {
      appletApi.deleteObject(lastPoint)
    }
  }
}

export const transformPointsStringToArray = (answer: string) => {
  const matchBetweenParenthesesRegex = /\(([^)]*)\)/gm

  return answer
    .split(matchBetweenParenthesesRegex)
    .map((point) => {
      const pointWithoutWhitespaces = removeWhitespaces(point)
      return pointWithoutWhitespaces ? `(${pointWithoutWhitespaces})` : ''
    })
    .filter((point) => point)
}

const getValuesString = (appletApi: AppletApiRef) => {
  const currentMode = appletApi.getMode()
  const pointsAndLines = appletApi.getAllObjectNames().filter((canvasObjectName: string) => {
    const type = appletApi.getObjectType(canvasObjectName)
    return type === ToolbarToolsNames.POINT || type === ToolbarToolsNames.LINE
  })

  const points = pointsAndLines.filter(
    (objName: string) => appletApi.getObjectType(objName) === ToolbarToolsNames.POINT
  )
  const lines = pointsAndLines.filter((objName: string) => appletApi.getObjectType(objName) === ToolbarToolsNames.LINE)

  const getPoints = () => {
    const matchBetweenParenthesesRegex = /\(([^)]*)\)/gm // Matches "(2, 0)" in "A = (2, 0)" string
    const pointsWithParentheses = points.map((point: string) => {
      return appletApi.getValueString(point).match(matchBetweenParenthesesRegex)?.[0] ?? ''
    })

    return pointsWithParentheses.join(' ')
  }

  const getLastLine = () => {
    const lastLineObjName = lines.at(-1)
    if (!lastLineObjName) return ''

    const lastLine = appletApi.getValueString(lastLineObjName)
    return lastLine ? lastLine.substring(lastLine.indexOf(':') + 2) : ''
  }

  if (currentMode === ToolbarTools.POINT) return getPoints()
  if (currentMode === ToolbarTools.JOIN) return getLastLine()

  if (currentMode === ToolbarTools.MOVE) {
    const lastObjName = pointsAndLines.at(-1)
    if (!lastObjName) return ''

    const lastObjType = appletApi.getObjectType(lastObjName)

    if (lastObjType === ToolbarToolsNames.POINT) return getPoints()
    if (lastObjType === ToolbarToolsNames.LINE) return getLastLine()
  }

  return ''
}

export const setUserAnswer = (appletApi: AppletApiRef, geogebraSettings: GeogebraSettings) => {
  if (geogebraSettings?.disableSettingAnswer) return
  if (appletApi.getMode() === ToolbarTools.PEN) return

  const valuesString = getValuesString(appletApi)

  if (geogebraSettings?.prefilledFunction) {
    const geogebraValueWithoutWhitespaces = removeWhitespaces(valuesString)
    const prefilledFunctionWithoutWhitespaces = removeWhitespaces(geogebraSettings.prefilledFunction)

    currentProblemAnswerModel.setText(
      geogebraValueWithoutWhitespaces === prefilledFunctionWithoutWhitespaces ? '' : valuesString
    )
    currentProblemAnswerModel.resetStatus()
    return
  }

  currentProblemAnswerModel.setText(valuesString)
  currentProblemAnswerModel.resetStatus()
}

const deleteAllInputFromGeogebraApplet = ({
  appletApi,
  removePrefilledFunction,
}: DeleteAllInputFromGeogebraAppletProps) => {
  const geogebraApplet = appletApi || window.ggbApplet
  if (!geogebraApplet) return

  const objects = geogebraApplet.getAllObjectNames()

  if (removePrefilledFunction) {
    objects.shift()
  }

  for (const geogebraObject of objects) {
    geogebraApplet.deleteObject(geogebraObject)
  }
}

export const resetGeogebraState = (currentProblem: AssignmentProblem) => {
  const mainTool = currentProblem?.geogebraSettings?.toolbarTools[0] ?? ToolbarTools.MOVE

  deleteAllInputFromGeogebraApplet({
    removePrefilledFunction: Boolean(currentProblem?.geogebraSettings?.prefilledFunction),
  })

  setGeogebraTool(ToolbarTools[mainTool as keyof typeof ToolbarTools])
}

export const setGeogebraTool = (tool: ToolbarTools, appletApi?: AppletApiRef) => {
  const geogebraApplet = appletApi || window.ggbApplet
  if (!geogebraApplet) return

  geogebraApplet.setMode(tool)
}

export const getGeogebraCanvasBase64 = (appletApi?: AppletApiRef) => {
  const geogebraApplet = appletApi || window.ggbApplet
  if (!geogebraApplet) return

  const image = geogebraApplet.getPNGBase64(1, true, 72)
  return image ? createFormDataFromBase64(image) : ''
}

export const getGeogebraBase64State = (appletApi?: AppletApiRef) => {
  const geogebraApplet = appletApi || window.ggbApplet
  if (!geogebraApplet) return
  return geogebraApplet.getBase64()
}

export const prefillLineEquations = (prefilledFunction: string, appletApiRef: AppletApiRef) => {
  if (!prefilledFunction) return

  if (prefilledFunction.includes('|')) {
    const prefilledFunctions = prefilledFunction.split('|')

    return prefilledFunctions.forEach((lineEquation: string) => {
      appletApiRef.evalCommand(lineEquation)
      const allObjects = appletApiRef.getAllObjectNames().slice(0, prefilledFunctions.length)

      allObjects.forEach((objName) => {
        appletApiRef.setFixed(objName, true, false)
      })
    })
  }

  appletApiRef.evalCommand(prefilledFunction)
  appletApiRef.setFixed('f', true, false)
}

export const removeNodeByText = (textArr: string[], parentElement?: Element) => {
  const parent = parentElement ?? document.querySelector('[role="menubar"]')
  if (!parent) return

  for (const child of parent.children) {
    if (child.textContent && textArr.includes(child.textContent)) {
      parent.removeChild(child)
    }
  }
}

export function isPointOnLine(lineEquation: string, point: string[]) {
  const [x, y] = point

  const dependant = lineEquation.split(' ').at(-1) ?? '0'
  const leftSide = lineEquation.replace('x', `*(${x})`).replace('y', `*(${y})`).split(' ').slice(0, -2).join('')

  const equationEvalRounded = parseFloat(String(eval(leftSide))).toFixed(2)
  const dependantRounded = parseFloat(dependant).toFixed(2)

  return equationEvalRounded === dependantRounded
}

export const addCommas = (str: string) => {
  // Add separator between point coords (2,2)(4,5) -> (2,2), (4,5)
  const coordsRegex = /\(.*?,.*?\)/gm
  const separator = ', '
  const matchedCommas = str.match(coordsRegex) || []

  if (matchedCommas.length > 1) {
    let currentMatchIndex = 0

    return str.replace(coordsRegex, (match) => {
      ++currentMatchIndex
      const isLastMatch = currentMatchIndex === matchedCommas.length
      return match + (isLastMatch ? '' : separator)
    })
  }

  return str
}

export const setGeogebraPointsLimitReached = (appletApi: AppletApiRef, geogebraSettings: GeogebraSettings) => {
  const setPointsLimitReached = useGeogebraModel.getState().actions.setPointsLimitReached

  if (geogebraSettings?.pointsLimit) {
    const points = appletApi
      .getAllObjectNames()
      .filter((objName) => appletApi.getObjectType(objName) === ToolbarToolsNames.POINT)

    setPointsLimitReached({
      pointsLimit: geogebraSettings.pointsLimit,
      currentPointsCount: points.length,
    })
  }
}
