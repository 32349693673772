import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_VARIANT, SIZE, COLORS } from '@magmamath/ui'

import styles from './styles.module.scss'

import { ChangeGradeActions } from '../types'
import { getChangedGrade, getIsChangeYearDisabled } from './helpers'
import PracticePageNavigationMenu from './PracticePageNavigationMenu/PracticePageNavigationMenu'
import useOutsideClick from '../../../lib/hooks/useOutsideClick'
import { MapIcon } from '../../../ui/icons/MapIcon/MapIcon'
import { useUnit } from 'effector-react'
import { $practiceGrade, setPracticeGrade } from '../models/currentPracticeGrade'
import { $availableGrades } from '../models/availableGrades'
import { filteredGradesAccordingToExistedAbility } from '../practice.helpers'
import usePracticeStore from '../models/practice'

const PracticePageNavigation = () => {
  const { t } = useTranslation()
  const [isNavigationMenuShown, setIsShownNavigationMenu] = useState(false)
  const { categories, filteredCategoriesByGrade, currentCategoryId } = usePracticeStore()
  const practiceGrade = useUnit($practiceGrade)
  const availableGrades = useUnit($availableGrades)

  const filteredGrades = useMemo(() => {
    if (!categories?.length) {
      return availableGrades
    }

    return filteredGradesAccordingToExistedAbility(availableGrades, categories)
  }, [categories, availableGrades])

  useEffect(() => {
    if (filteredGrades.includes(practiceGrade) || !filteredGrades.length) {
      return
    }

    let newGrade = getChangedGrade({
      type: ChangeGradeActions.DECREASE,
      currentGrade: practiceGrade,
      filteredGrades,
      newGrade: practiceGrade - 1,
    })

    if (newGrade < 1) newGrade = 1
    setPracticeGrade(newGrade)
  }, [filteredGrades])

  const onChangeGrade = (type: ChangeGradeActions) => {
    const newGrade: number = type === ChangeGradeActions.DECREASE ? practiceGrade - 1 : practiceGrade + 1
    setPracticeGrade(getChangedGrade({ type, currentGrade: practiceGrade, filteredGrades, newGrade }))
  }

  const isChangingYearDisabled = (type: ChangeGradeActions) => {
    return getIsChangeYearDisabled({ type, currentGrade: practiceGrade, filteredGrades })
  }

  const ref = useOutsideClick(() => setIsShownNavigationMenu(false))

  return (
    <div className={styles.PracticePageNavigationContainer} ref={ref}>
      <Button
        size={SIZE.LARGE}
        variant={BUTTON_VARIANT.SECONDARY}
        onClick={() => setIsShownNavigationMenu(!isNavigationMenuShown)}
        icon={<MapIcon size={20} color={!filteredGrades.length ? COLORS.NEUTRAL_5 : COLORS.NEUTRAL_9} />}
        isActive={isNavigationMenuShown}
        disabled={!filteredGrades.length}
      >
        {t('practiceMap')}
      </Button>
      {isNavigationMenuShown && (
        <PracticePageNavigationMenu
          grade={practiceGrade}
          categories={filteredCategoriesByGrade}
          currentCategoryId={currentCategoryId}
          getIsChangeGradeDisabled={isChangingYearDisabled}
          onChangeGrade={onChangeGrade}
        />
      )}
    </div>
  )
}

export default PracticePageNavigation
