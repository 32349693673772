import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { showErrorToast } from '../../constants/toasts'
import { autologinError } from '../../constants/errorCodeMessages'
import { RoutesPaths } from '../../containers/paths'
import { getToken } from '../../api/apiManager'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResolveAuth = (props) => {
  const { t } = useTranslation()
  const query = useQuery()

  const token = query.get('token')
  const userId = query.get('userId')
  const error = query.get('auth-error-code')
  const locale = query.get('locale')

  if (token && userId) {
    const redirectQueryURL = query.get('redirectURL')
    const searchObject = { autologinToken: token, userId: userId }
    locale && (searchObject['locale'] = locale)
    redirectQueryURL && (searchObject['redirectURL'] = redirectQueryURL)

    return (
      <Redirect
        to={{
          pathname: RoutesPaths.AUTOLOGIN,
          search: new URLSearchParams(searchObject).toString(),
        }}
      />
    )
  }

  //TODO: if we want to get rid of get token and subscribe to token, need to move this logic to exercise page
  // const isTokenLoading = useUnit(api.manager.tokenManager.$isTokenLoading)
  // const isTokenExists = useStoreMap(api.manager.$token, Boolean)
  // if (isTokenExists || isTokenLoading) {
  //   return <Redirect to={RoutesPaths.EXERCISES_AVAILABLE} />
  // }

  if (getToken()) {
    return <Redirect to={RoutesPaths.EXERCISES_AVAILABLE} />
  }

  if (error) {
    showErrorToast({ message: t(`${autologinError[error]}`), toastId: error })
  }

  const redirectURL = props.location?.state?.redirectURL
  const urlWithParams = new URL(`${window._env_.AUTH_WEB_INTERNATIONAL_URL}/signin`)
  urlWithParams.searchParams.append('logout', '1')
  redirectURL && urlWithParams.searchParams.append('redirectURL', redirectURL)
  window.location.replace(urlWithParams.href)
  return null
}

export default ResolveAuth
