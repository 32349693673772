import { MutableRefObject, useEffect } from 'react'
import {
  BoardController,
  DrawBoardStatisticModule,
  HTMLDrawAreaPreset,
  KeyboardShortcutsModule,
} from '@magmamath/react-native-draw-board'
export const drawBoardStatisticModule = new DrawBoardStatisticModule()
export const keyboardShortcutsModule = new KeyboardShortcutsModule()
export const useDrawBoardModules = (boardRef: MutableRefObject<BoardController | null>) => {
  useEffect(() => {
    if (!boardRef.current) return
    keyboardShortcutsModule.setModel(boardRef.current.model as HTMLDrawAreaPreset['model'])
    keyboardShortcutsModule.start()
    drawBoardStatisticModule.setModel(boardRef.current.model)

    return () => {
      keyboardShortcutsModule.stop()
      keyboardShortcutsModule.setModel(null)
      drawBoardStatisticModule.setModel(null)
    }
  }, [])
}
