import { createStore, createEffect } from 'effector'
import { msCompanion } from '../DrawBoard/hook.myScript'
import { validateLatexAnswer } from './validation'

type DrawingValidation = {
  isValid: boolean
}

type ValidateCurrentDrawingFxProps = {
  assignmentId: string
  problemId: string
  userAnswer: string | string[]
  problemDescription: string
  recognitionResult?: { latex?: string } | null
}

export const validateCurrentDrawingFx = createEffect(
  async ({
    problemId,
    assignmentId,
    userAnswer,
    problemDescription,
    recognitionResult,
  }: ValidateCurrentDrawingFxProps): Promise<
    Omit<ValidateCurrentDrawingFxProps, 'userAnswer' | 'problemDescription'> & DrawingValidation
  > => {
    if (recognitionResult === undefined) {
      recognitionResult = await msCompanion.recognizeBoard()
    }

    return {
      assignmentId,
      problemId,
      isValid:
        !!recognitionResult?.latex &&
        validateLatexAnswer({
          mathExp: recognitionResult.latex,
          userAnswer,
          problemDescription,
        }),
    }
  }
)

export const $validatedDrawings = createStore<Record<string, Record<string, DrawingValidation>>>({}).on(
  validateCurrentDrawingFx.doneData,
  (state, { isValid, problemId, assignmentId }) => ({
    ...state,
    [assignmentId]: {
      ...state[assignmentId],
      [problemId]: { isValid },
    },
  })
)

validateCurrentDrawingFx.fail.watch((error) => {
  console.error('Error validating drawing: ', error)
})
