import { useEffect, useRef, useState } from 'react'

export const useOverflowDetection = (deps: any[] = []) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const childRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleCheckOverflow = () => {
      if (parentRef.current && childRef.current) {
        setIsOverflowing(childRef.current.scrollWidth > parentRef.current.clientWidth)
      }
    }

    handleCheckOverflow()

    window.addEventListener('resize', handleCheckOverflow)
    return () => window.removeEventListener('resize', handleCheckOverflow)
  }, deps)

  return { isOverflowing, parentRef, childRef }
}
