import { checkIsSwedenLocale } from '../../../helpers/userLocale'

export const NUMPAD_MATH_MOBILE_BUTTONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  checkIsSwedenLocale() ? ',' : '.',
  '7',
  '8',
  '9',
  '0',
]
