import React, { useMemo } from 'react'
import { useUnit } from 'effector-react'
import styles from './styles.module.scss'
import { PracticeSkill, SkillStatisticsMap } from '../../../PracticeMode/types'
import AbilityCard from '../../../PracticeMode/AbilityCard/AbilityCard'
import useOpenSkill from '../../../PracticeMode/hooks/useOpenSkill'
import { RoutesPaths } from '../../../../containers/paths'
import { fullscreenModeOn } from '../../../../helpers'
import { practicePageSessionActions } from '../../../PracticeMode/sessionStorage'
import { practiceTheoryModel } from '../../../PracticeMode/models/practiceTheory'
import { useHistory } from 'react-router-dom'
import { calculateSkillProgress, getNextPromptedSkill } from '../../skillsTree.helpers'
import { checkIsSwedenLocale } from '../../../../helpers/userLocale'
import { useActiveSubCategoryModel } from '../../../PracticeMode/models/activeSubCategory'
import { AppLanguage } from '../../../../locales/i18n.config'
import { findFirstUnsolved } from '../../../PracticeMode/practice.helpers'
import { practiceSkillsModel } from '../../model/skillsTreeModel'
import { getSkillTheoryFx } from '../../model/skillTheory'

type PracticeSkillsBlockProps = {
  skills: PracticeSkill[]
  scrollArea: HTMLDivElement | null
}

const Skills = ({ skills, scrollArea }: PracticeSkillsBlockProps) => {
  const history = useHistory()
  const statistics = useUnit(practiceSkillsModel.$statistics)
  const gradeSubtree = useUnit(practiceSkillsModel.$gradeSubtree)

  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const { openSkill } = useOpenSkill()

  const goToExercise = async (skill: PracticeSkill) => {
    const nextSkill = gradeSubtree
      ? getNextPromptedSkill({ practiceTree: gradeSubtree, currentSkillId: skill._id })
      : undefined

    const successCallback = () => {
      history.push(RoutesPaths.PRACTICE_PAGE)
      fullscreenModeOn()
    }

    const currentScrollTopPosition = scrollArea?.scrollTop
    const saveCurrentPracticePageData = () => {
      currentScrollTopPosition && practicePageSessionActions.lastScrollPosition.set(currentScrollTopPosition)
      practicePageSessionActions.lastActiveSubCategoryId.set(activeSubCategoryId)
    }

    practiceTheoryModel.setTheory(null)
    const languageCode = checkIsSwedenLocale() ? AppLanguage.SV : AppLanguage.EN
    const theory = skill.theory.find((theoryData) => {
      return theoryData.languageCode === languageCode
    })

    if (theory) {
      await getSkillTheoryFx(theory.theory)
    }

    openSkill({ skill, nextSkill: nextSkill, onSuccess: successCallback, onSaveData: saveCurrentPracticePageData })
  }

  const firstUnsolvedSkillId = useMemo(() => findFirstUnsolved(skills, statistics), [skills, statistics])

  return (
    <div className={styles.SkillsContainer}>
      {skills.map((skill, index) => (
        <div className={styles.SkillCardWrapper} id={skill._id} key={index}>
          <AbilityCard
            title={skill.nameTranslations}
            stats={calculateSkillProgress(statistics?.[skill._id])}
            onClick={() => goToExercise(skill)}
            isActionAbility={firstUnsolvedSkillId === skill._id}
          />
        </div>
      ))}
    </div>
  )
}

export default Skills
