import React from 'react'
import { useUnit } from 'effector-react'
import styles from './styles.module.scss'

import { IconButton } from '../../../../ui/IconButton'
import { ExitIcon } from '../../../../ui/icons/Exit/ExitIcon'
import { MagmaChatIcon } from '../../../../ui/icons/MagmaChat/MagmaChatIcon'
import { COLORS } from '@magmamath/ui'
import { $isChatOpen, chatVisibilityToggled } from '../../models/isChatOpen'

type ToggleButtonProps = {
  onClick?: () => void
}

export const ToggleButton = ({ onClick }: ToggleButtonProps) => {
  const isChatOpen = useUnit($isChatOpen)

  if (isChatOpen) {
    return (
      <IconButton
        variant='outlined'
        rounded='full'
        size='md'
        onClick={() => {
          chatVisibilityToggled()
          onClick?.()
        }}
        className={styles.ToggleButton}
      >
        <ExitIcon width={25} height={25} stroke={COLORS.NEUTRAL_9} />
      </IconButton>
    )
  }

  return (
    <button
      onClick={() => {
        chatVisibilityToggled()
        onClick?.()
      }}
      className={styles.ToggleButtonInactive}
    >
      <MagmaChatIcon />
    </button>
  )
}
