import { useEffect, useRef } from 'react'
import { $exerciseId } from '../model/credentials.model'

export const useOnProblemChange = (callback: (problemId?: string) => void) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    return $exerciseId.watch((problemId) => {
      callbackRef.current(problemId)
    })
  }, [])
}
