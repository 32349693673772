import { createValuePersistorModel } from '../../../lib/helpers/createValuePersistorModel'

type CreateChatProps = {
  assignmentId: string
  problemId: string
  isChatAvailable?: boolean
}

export const availableChatsModel = createValuePersistorModel({
  mapper: ({ assignmentId, problemId, isChatAvailable = true }: CreateChatProps) => ({
    entityId: assignmentId,
    fieldId: problemId,
    data: { isChatAvailable },
  }),
})
