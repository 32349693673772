import clsx from 'clsx'
import styles from './zoomableImage.module.scss'
import PlusIcon from '../../ui/icons/PlusIcon'
import React, { ReactElement } from 'react'

export type ZoomContentProps = {
  img: ReactElement | null
  buttonUnzoom: ReactElement<HTMLButtonElement>
  modalState: string
  onUnzoom: () => void
  onExportButtonPressed?: () => void
}
type ImageComponentWithRef = {
  ref: { current: HTMLImageElement | null }
}

enum ModalState {
  LOADED = 'LOADED',
}

const retrieveImagePosition = (image: ReactElement | null) => {
  const nativeImage = (image as ImageComponentWithRef | null)?.ref.current
  return {
    style: nativeImage?.style,
    offsetTop: nativeImage?.offsetTop || 0,
    offsetLeft: nativeImage?.offsetLeft || 0,
    width: nativeImage?.clientWidth || 0,
  }
}
const ExportableZoomContent = ({ img, modalState, onExportButtonPressed }: ZoomContentProps) => {
  const position = retrieveImagePosition(img)

  return (
    <>
      <button
        onClick={onExportButtonPressed}
        className={clsx(styles.ExportButton, !(modalState === ModalState.LOADED) && styles.Hidden)}
        style={{
          transform: position.style?.transform,
          top: position.offsetTop,
          left: position.offsetLeft + position.width - 55,
        }}
      >
        <PlusIcon />
      </button>
      {img}
    </>
  )
}

type SetUpProps = {
  onExportButtonPressed?: () => void
}

export const setUpExportableZoomContent = ({ onExportButtonPressed }: SetUpProps) => {
  return (props: ZoomContentProps) => {
    return <ExportableZoomContent {...props} onExportButtonPressed={onExportButtonPressed} />
  }
}
