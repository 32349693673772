import { MutableRefObject } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'

type FocusSpreadsheetCellProps = {
  timeoutMs?: number
  boardRef: MutableRefObject<BoardController | null>
}

export const focusSpreadsheetCell = ({ timeoutMs = 0, boardRef }: FocusSpreadsheetCellProps) => {
  setTimeout(() => {
    boardRef.current?.model.spreadsheet?.controller.focusCenter({
      offsetX: -3,
      offsetY: -1,
    })
  }, timeoutMs)
}
