import React from 'react'
import styles from './styles.module.scss'

import SimpleModePauseIcon from '../../../../ui/icons/SimpleModePauseIcon'
import SimpleModePlayIcon from '../../../../ui/icons/SimpleModePlayIcon'
import SimpleModeSpeakerIcon from '../../../../ui/icons/SimpleModeSpeakerIcon'
import TextToSpeech from '../../../TextToSpeech/TextToSpeech'

type SimpleModeTextToSpeechButtonProps = {
  textToSpeechContent: string
}

export const SimpleModeTextToSpeechButton = ({ textToSpeechContent }: SimpleModeTextToSpeechButtonProps) => {
  return (
    <TextToSpeech
      value={textToSpeechContent}
      variant='unstyled'
      className={styles.SimpleModeTextToSpeech}
      PauseIcon={SimpleModePauseIcon}
      PlayIcon={SimpleModePlayIcon}
      SpeakerIcon={SimpleModeSpeakerIcon}
    />
  )
}
