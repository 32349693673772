import type { UserSettings } from '../../lib/types'

export const canUserAccessChat = (userSettings: UserSettings, classGrade?: number) => {
  if (!userSettings.chat) return false
  if (userSettings.grade === null || userSettings.grade === undefined || userSettings.grade === -1) {
    return classGrade ? classGrade >= 4 : false
  }

  return userSettings.grade >= 4
}
