import { transformLatexToText } from '../../helpers/myScript/transformLatexToText'
import { featuresSolution } from './drawingValidation.helpers'
import { basicTrim } from './drawingValidation.helpers'

//New model
function validateTextExpression(mathExp: string, userAnswer: string, problemDescription: string) {
  // Get features to classify solution based on string, answer, and problem description
  let trimmedMathExpression = basicTrim(mathExp)
  const mathExpressionText = transformLatexToText(trimmedMathExpression, { multiple: '*' })
  const features = featuresSolution(mathExpressionText, userAnswer, problemDescription)

  const digits = Array.isArray(features.digits) ? features.digits.length : 0
  const ops = Array.isArray(features.ops) ? features.ops.length : 0
  const digitsNoAns = Array.isArray(features.digitsNoAns) ? features.digitsNoAns.length : 0
  const opsNoAns = Array.isArray(features.opsNoAns) ? features.opsNoAns.length : 0
  const digitsNoProb = Array.isArray(features.digitsNoProb) ? features.digitsNoProb.length : 0
  const opsNoProb = Array.isArray(features.opsNoProb) ? features.opsNoProb.length : 0
  const prob = Array.isArray(features.prob) ? 0 : features.prob
  const ans = Array.isArray(features.ans) ? 0 : features.ans
  const ratio = Array.isArray(features.ratio) ? 0 : features.ratio

  const charsToRemove = ['[', ']', '"']
  const sanitizedUserAnswer = Array.from(userAnswer)
    .filter((char) => !charsToRemove.includes(char))
    .join('')

  if (digits > 1 && ops > 0) {
    // Check if solution contains the answer and is well-formed
    if (ratio === 1 && digits >= 2 && ops >= 2 && prob > 0) {
      return 1
    }
    // Check if solution contains additional digits and operators beyond the answer
    if (ans === 1) {
      if (sanitizedUserAnswer.length > 4) {
        return 1
      }
      if (digitsNoAns > 0 && opsNoAns > 0 && ratio >= 0.3) {
        return 1
      }
      return 0
    }
    if (prob > 0 && digitsNoProb > 0 && opsNoProb > 0 && ratio >= 0.5) {
      return 1
    }
  }
  return 0
}

type ValidateLatexAnswerProps = {
  mathExp: string
  userAnswer: string | string[]
  problemDescription: string
}

export function validateLatexAnswer({ mathExp, userAnswer, problemDescription }: ValidateLatexAnswerProps) {
  const answer = Array.isArray(userAnswer) ? userAnswer[0] : userAnswer
  return !!validateTextExpression(mathExp, answer, problemDescription)
}
