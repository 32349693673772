import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const TheoryIcon = ({ size = 20, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.743 14.0002C7.73719 11.5959 3.6971 12.5465 1.80699 13.421C1.6846 13.4776 1.54297 13.3895 1.54297 13.2547V2.37712C1.54297 2.31769 1.57269 2.26502 1.62224 2.23222C4.76879 0.149074 9.00522 1.69368 10.743 2.73617M10.743 14.0002C12.9009 13.1125 14.2085 11.3502 19.6922 13.452C19.811 13.4975 19.943 13.4124 19.943 13.2852V2.38396C19.943 2.32145 19.912 2.2662 19.8581 2.23457C18.3367 1.34196 14.5258 0.260547 10.743 2.73617M10.743 14.0002V2.73617M12.9319 5.29562C13.516 4.84508 15.2976 4.12421 17.7509 4.84508M12.9319 7.81749C13.516 7.36695 15.2976 6.64608 17.7509 7.36695M12.9319 10.1066C13.516 9.65601 15.2976 8.93515 17.7509 9.65601'
        stroke={fill}
        strokeWidth='1.328'
        strokeLinecap='round'
      />
    </svg>
  )
}
