import { MutableRefObject, useEffect } from 'react'
import { BoardController, drawBoardGlobals } from '@magmamath/react-native-draw-board'
import { useTranslation } from 'react-i18next'
import { SE_LOCALE } from '../../constants/defaults'

export const useDrawBoardLocalization = () => {
  const [, { language }] = useTranslation()

  useEffect(() => {
    drawBoardGlobals.setLocalization((defaultStructure) => {
      if (language === SE_LOCALE) {
        return defaultStructure.SE
      }

      return defaultStructure.EN
    })
  }, [language])
}

export const useAutoFocusSpreadsheetCell = (
  boardRef: MutableRefObject<BoardController | null>,
  shouldFocus = false
) => {
  useEffect(() => {
    if (!shouldFocus) return
    let currentTimeout: ReturnType<typeof setTimeout> | null = null

    const unwatch = boardRef.current?.model.layers.$isAttached.watch((isAttached) => {
      if (!isAttached) return
      if (currentTimeout) clearTimeout(currentTimeout)
      currentTimeout = setTimeout(() => {
        boardRef.current?.model.spreadsheet?.controller.focusCenter({
          offsetX: -3,
          offsetY: -1,
        })
        currentTimeout = null
      }, 200)
    })

    return () => {
      unwatch?.()
      if (currentTimeout) clearTimeout(currentTimeout)
    }
  }, [shouldFocus, boardRef])
}
