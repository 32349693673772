import { getSolutionImage } from '../assignments/requests'

type Attempt = { background?: string }
type AttemptsList = Record<string, Record<string, Attempt> | Attempt>

/*
 * Now we use draw board for uploading background images so modern solutions do not have such field at all.
 * But we should leave it here for compatibility with very old solutions.
 * For getting rid of this code, we need to go through all solutions and test solution which have
 * not empty background filed in attempts (and also backgroundImage field)
 */
export const getAttemptBackgroundImage = (
  attempts: AttemptsList | undefined,
  problemId: string,
  subProblemId: string
) => {
  const attempt = attempts?.[problemId]
  if (!attempt) return ''
  if (!subProblemId) return attempt.background ? getSolutionImage(attempt.background as string) : ''
  const subAttempt = attempt[subProblemId as keyof typeof attempt] as Attempt | undefined
  return subAttempt?.background ? getSolutionImage(subAttempt.background) : ''
}
