import React from 'react'

export const TrophyIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.1754 12.8311L19.8581 14.6452L20.7651 14.8036C21.2107 14.8815 21.6397 14.9202 22.0509 14.9202C22.9199 14.9208 23.711 14.7465 24.4186 14.4003C24.7911 14.2181 25.1397 13.9885 25.465 13.7111C27.9382 11.6006 28.2469 7.29662 28.2113 4.91849C28.1954 3.89604 27.6872 2.93374 26.8507 2.34458C26.3327 1.9788 25.7307 1.77935 25.1151 1.7591C24.7843 1.74743 24.4499 1.78794 24.1222 1.88122C23.3274 2.10768 22.6627 2.62197 22.2466 3.32038L20.454 2.52685L19.7089 4.21088L21.8772 5.17011C22.2246 5.32354 22.6106 5.33151 22.9641 5.19159C23.317 5.05228 23.5937 4.78286 23.7423 4.43304C23.9043 4.05008 24.2271 3.76532 24.6266 3.65178C24.7923 3.60453 24.9617 3.58919 25.128 3.60453C25.3631 3.62601 25.5914 3.70886 25.7902 3.8494C26.1468 4.10041 26.3634 4.51098 26.3702 4.94672C26.4346 9.20097 25.3281 11.1599 24.5972 11.986C24.4757 12.1241 24.364 12.2303 24.2701 12.3107C23.46 13.0011 22.4173 13.2232 21.0818 12.99L20.1754 12.8311Z'
        fill='#FFBE4E'
      />
      <path
        d='M7.94903 14.9202C8.36034 14.9202 8.78908 14.8812 9.23476 14.8033L10.1416 14.6447L9.82435 12.8311L8.91759 12.9897C7.58185 13.2234 6.53952 13.001 5.72997 12.3102C5.07342 11.75 3.55442 9.91091 3.62966 4.94635C3.63623 4.51055 3.85311 4.10034 4.20962 3.84903C4.54925 3.6095 4.97339 3.53745 5.37316 3.65141C5.77293 3.76532 6.09537 4.05014 6.25776 4.43273C6.40628 4.78255 6.6827 5.05203 7.03607 5.19171C7.38951 5.33133 7.77547 5.32347 8.1229 5.1698L10.2905 4.21038L9.54536 2.52679L7.75313 3.32001C7.33716 2.62185 6.67269 2.10737 5.87781 1.88079C4.94018 1.61352 3.94554 1.78241 3.14876 2.34414C2.31263 2.93362 1.80417 3.89598 1.78871 4.91848C1.75262 7.29625 2.06144 11.6003 4.53483 13.7108C5.47798 14.5155 6.62261 14.9202 7.94903 14.9202Z'
        fill='#FFBE4E'
      />
      <path
        d='M7.3143 10.5265L8.44905 0.741825C8.4863 0.420608 8.75836 0.178253 9.08179 0.178253H20.9186C21.2421 0.178253 21.5141 0.420608 21.5514 0.741825L22.6861 10.5265C22.9332 12.6571 22.0802 14.7556 20.5237 15.8463L20.0709 16.1636C19.2146 16.7636 18.2918 17.2048 17.334 17.4781C16.6773 17.6656 16.2434 18.4091 16.3351 19.206L16.6506 21.948H13.3498L13.6654 19.206C13.7571 18.4091 13.3232 17.6655 12.6665 17.4781C11.7087 17.2048 10.7859 16.7636 9.92957 16.1636L9.47677 15.8463C7.92021 14.7556 7.06721 12.657 7.3143 10.5265Z'
        fill='#FFBE4E'
      />
      <path
        d='M15.2244 19.2056L15.4018 21.9483H16.6501L16.3346 19.2056C16.2432 18.409 16.6771 17.6652 17.3338 17.478C18.2911 17.2049 19.2142 16.7636 20.0703 16.1634L20.5232 15.8461C22.0796 14.7555 22.9327 12.6573 22.6859 10.5265L21.5509 0.741551C21.5136 0.420334 21.2416 0.177979 20.9181 0.177979H18.1205L18.7956 10.5264C18.9343 12.6572 18.4549 14.7555 17.5798 15.8461L17.3251 16.1633C16.8433 16.7636 16.3248 17.2048 15.7859 17.4779C15.4171 17.6652 15.1729 18.409 15.2244 19.2056Z'
        fill='#FFAE22'
      />
      <path
        d='M9.36356 23.2566H20.6362V22.884C20.6362 21.9796 16.2645 21.2465 15.3601 21.2465H11.001C10.0966 21.2465 9.36356 21.9796 9.36356 22.884V23.2566Z'
        fill='#FFBE4E'
      />
      <path
        d='M15.3601 21.2465C16.2641 21.2465 16.9975 21.9799 16.9975 22.8839V23.257H20.6362V22.8839C20.6362 21.9799 19.9028 21.2465 18.9988 21.2465H15.3601Z'
        fill='#FFAE22'
      />
      <path
        d='M7.85041 24.2848V28.9626C7.85041 29.4371 8.23305 29.8218 8.70757 29.8218C10.1561 29.8218 13.1143 29.8218 15.0002 29.8218H21.3048C21.7715 29.8218 22.1499 29.4434 22.1499 28.9767V24.2848C22.1499 23.449 21.4723 22.7714 20.6365 22.7714H9.36382C8.52801 22.7714 7.85041 23.449 7.85041 24.2848Z'
        fill='#55ABE0'
      />
      <path
        d='M17.4599 22.7712C18.2952 22.7712 18.9727 23.4487 18.9727 24.2846V29.8216H21.3048C21.7715 29.8216 22.1499 29.4433 22.1499 28.9765V24.2846C22.1499 23.4488 21.4723 22.7712 20.6365 22.7712H17.4599Z'
        fill='#2488BB'
      />
      <path
        d='M24.4186 14.4003C24.7911 14.218 25.1397 13.9885 25.465 13.7111C27.9382 11.6006 28.2469 7.29659 28.2113 4.91846C28.1954 3.89601 27.6872 2.93371 26.8507 2.34455C26.3327 1.97877 25.7307 1.77932 25.1151 1.75906C25.2993 1.96343 25.9068 2.7588 25.128 3.6045C25.3631 3.62598 25.5914 3.70883 25.7902 3.84937C26.1468 4.10038 26.3634 4.51095 26.3702 4.94669C26.4346 9.20094 25.3281 11.1599 24.5972 11.986C24.5972 11.986 25.7964 13.3423 24.4186 14.4003Z'
        fill='#FFAE22'
      />
    </svg>
  )
}
