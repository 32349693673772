import React from 'react'
import styles from './ExerciseDate.module.scss'
import dayjs from 'dayjs'

type ExerciseDateProps = {
  createdAt: string
}

const ExerciseDate = ({ createdAt = '' }: ExerciseDateProps) => {
  return <span className={styles.Date}>{dayjs(createdAt).format('YYYY-MM-DD')}</span>
}

export default ExerciseDate
