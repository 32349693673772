import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { COLORS } from '@magmamath/ui'

import { LoaderProps } from './types'

const RotatingLinesLoader: React.FC<LoaderProps> = ({ color = COLORS.NEUTRAL_6, width = 30 }) => {
  return (
    <RotatingLines
      strokeColor={color}
      strokeWidth='3'
      animationDuration='0.75'
      width={width.toString()}
      visible={true}
    />
  )
}

export default RotatingLinesLoader
