import React from 'react'

import styles from './styles.module.scss'

import PracticeNavigationSubCategories from '../PracticeNavigationContentBlocks/PracticeNavigationSubCategories'
import { filterSubcategoriesByGrade } from '../../../practice.helpers'
import { Category } from '../../../types'

type PracticeNavigationCategoryProps = {
  category: Category
  openedCategoryId: string
  openCategory: (id: string) => void
  grade: number
}

const PracticeNavigationCategory = ({
  category,
  openedCategoryId,
  openCategory,
  grade,
}: PracticeNavigationCategoryProps) => {
  return (
    <div className={styles.CategoriesListItemContainer}>
      <li
        className={styles.CategoriesListItem}
        onClick={() => openCategory(openedCategoryId === category._id ? '' : category._id)}
      >
        <a href={`#${category._id}`} className={styles.CategoryTitle}>
          {category.primaryName}
        </a>
      </li>
      <PracticeNavigationSubCategories
        subcategories={filterSubcategoriesByGrade(category.subcategories, grade)}
        isOpened={openedCategoryId === category._id}
      />
    </div>
  )
}

export default PracticeNavigationCategory
