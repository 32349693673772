import React from 'react'
import { TailSpin } from 'react-loader-spinner'

import Loader from './LoaderWrapper'
import { LoaderProps } from './types'
import { COLORS } from '@magmamath/ui'

const CircleLoader: React.FC<LoaderProps> = ({ color = COLORS.PRIMARY_BLUE, width = 120, height = 120 }) => {
  return (
    <Loader>
      <TailSpin color={color} height={height} width={width} />
    </Loader>
  )
}

export default CircleLoader
