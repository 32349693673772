import * as mathjs from 'mathjs'

export const addUnits = () => {
  mathjs.createUnit(
    'mil',
    {
      definition: '10 km',
      prefixes: 'none',
    },
    { override: true }
  )

  const degrees = [
    'grader',
    'degreefarenheit',
    'degreesfarenheit',
    'degreesc',
    'degreesf',
    'degreecelcius',
    'degreescelcius',
    'graderfarenheit',
    'gradercelcius',
  ]
  mathjs.createUnit('grader', {
    definition: '1 degrees',
    prefixes: 'none',
    aliases: degrees,
  })

  const seconds = [
    // "sec", //conflict with sec function
    'sekunder',
    'sekund',
  ]
  mathjs.createUnit('sekund', {
    definition: '1 seconds',
    prefixes: 'none',
    aliases: seconds,
  })

  const minutes = [
    // "min", //conflict with min function (similar to max)
    'minut',
    'minuter',
  ]
  mathjs.createUnit('minut', {
    definition: '1 minute',
    prefixes: 'none',
    aliases: minutes,
  })

  const hours = ['timmar', 'timme']
  mathjs.createUnit('timme', {
    definition: '1 hour',
    prefixes: 'none',
    aliases: hours,
  })

  const kronor = ['kr', 'kronor']
  mathjs.createUnit('kr', {
    prefixes: 'none',
    aliases: kronor,
  })

  const yards = ['yds']

  mathjs.createUnit('yds', {
    definition: '1 yd',
    prefixes: 'none',
    aliases: yards,
  })
  const squareMilimeters = [
    'squaremm',
    'squaremillimeter',
    'squaremillimeters',
    'kvadratmillimeter',
    'mmsq',
    'mmsquare',
    'mmsquared',
    'millimetersq',
    'millimetersquare',
    'millimetersquared',
    'millimeterssq',
    'millimeterssquare',
    'millimeterssquared',
  ]

  mathjs.createUnit('sqmm', {
    definition: '1 mm^2',
    prefixes: 'none',
    aliases: squareMilimeters,
  })

  const squareCentimeters = [
    'squarecm',
    'squarecentimeter',
    'squarecentimeters',
    'kvadratcentimeter',
    'cmsq',
    'cmsquare',
    'cmsquared',
    'centimetersq',
    'centimetersquare',
    'centimetersquared',
    'centimeterssq',
    'centimeterssquare',
    'centimeterssquared',
  ]
  mathjs.createUnit('sqcm', {
    definition: '1 cm^2',
    prefixes: 'none',
    aliases: squareCentimeters,
  })

  const squareDecimeters = [
    'squaredm',
    'squaredecimeter',
    'squaredecimeters',
    'kvadratdecimeter',
    'dmsq',
    'dmsquare',
    'dmsquared',
    'decimetersq',
    'decimetersquare',
    'decimetersquared',
    'decimeterssq',
    'decimetersquare',
    'decimeterssquared',
  ]
  mathjs.createUnit('sqdm', {
    definition: '1 dm^2',
    prefixes: 'none',
    aliases: squareDecimeters,
  })

  const squareMeters = [
    'squarem',
    'squaremeter',
    'squaremeters',
    'kvadratmeter',
    'msq',
    'msquare',
    'msquared',
    'metersq',
    'metersquare',
    'metersquared',
    'meterssq',
    'metersquare',
    'meterssquared',
  ]
  mathjs.createUnit('sqm', {
    definition: '1 m^2',
    prefixes: 'none',
    aliases: squareMeters,
  })

  const squareKilometers = [
    'squarekm',
    'squarekilometer',
    'squarekilometers',
    'kvadratkilometer',
    'kmsq',
    'kmsquare',
    'kmsquared',
    'kilometersq',
    'kilometersquare',
    'kilometersquared',
    'kilometerssq',
    'kilometersquare',
    'kilometerssquared',
  ]
  mathjs.createUnit('sqkm', {
    definition: '1 km^2',
    prefixes: 'none',
    aliases: squareKilometers,
  })

  const squareInches = [
    'insq',
    'insquare',
    'insquared',
    'inchsq',
    'inchessq',
    'inchsquared',
    'inchessquared',
    'sqinch',
    'sqinches',
    'squarein',
    'squareinch',
    'squareinches',
    'squaredin',
    'squaredinch',
    'squaredinches',
  ]

  mathjs.createUnit('in2', {
    definition: '1 in^2',
    prefixes: 'none',
    aliases: squareInches,
  })

  const squareFeet = [
    'ft2',
    'ftsq',
    'ftsquare',
    'ftsquared',
    'footsq',
    'feetsq',
    'footsquared',
    'feetsquared',
    'sqfoot',
    'squareft',
    'squarefoot',
    'squarefeet',
    'squaredft',
    'squaredfoot',
    'squaredfeet',
  ]

  mathjs.createUnit('ft2', {
    definition: '1 ft^2',
    prefixes: 'none',
    aliases: squareFeet,
  })

  const squareYards = [
    'yd2',
    'ydsq',
    'ydsquare',
    'ydsquared',
    'yardsq',
    'yardssq',
    'yardsquared',
    'yardssquared',
    'sqyards',
    'squareyd',
    'squareyard',
    'squareyards',
    'squaredyd',
    'squaredyard',
    'squaredyards',
  ]

  mathjs.createUnit('yd2', {
    definition: '1 yd^2',
    prefixes: 'none',
    aliases: squareYards,
  })

  const squareMiles = [
    'mi2',
    'misq',
    'misquare',
    'misquared',
    'milesq',
    'milessq',
    'milesquared',
    'milessquared',
    'squaremi',
    'squaremile',
    'squaremiles',
    'squaredmi',
    'squaredmile',
    'squaredmiles',
  ]
  mathjs.createUnit('mi2', {
    definition: '1 mi^2',
    prefixes: 'none',
    aliases: squareMiles,
  })

  const cubicMilimeters = [
    'cubicmm',
    'cubicmillimeter',
    'cubicmillimeters',
    'kubikmillimeter',
    'mmcube',
    'mmcubed',
    'millimetercube',
    'millimetercubed',
    'millimeterscube',
    'millimeterscubed',
  ]
  mathjs.createUnit('cubicmm', {
    definition: '1 mm^3',
    prefixes: 'none',
    aliases: cubicMilimeters,
  })

  const cubicCentimeters = [
    'cubiccentimeter',
    'cubiccentimeters',
    'kubikcentimeter',
    'cmcube',
    'cmcubed',
    'centimetercube',
    'centimetercubed',
    'centimeterscube',
    'centimeterscubed',
  ]
  mathjs.createUnit('cubiccm', {
    definition: '1 cm^3',
    prefixes: 'none',
    aliases: cubicCentimeters,
  })

  const cubicDecimeters = [
    'cubicdm',
    'cubicdecimeter',
    'cubicdecimeters',
    'kubikdecimeter',
    'dmcube',
    'dmcubed',
    'decimetercube',
    'decimetercubed',
    'decimeterscube',
    'decimeterscubed',
  ]
  mathjs.createUnit('cubicdm', {
    definition: '1 dm^3',
    prefixes: 'none',
    aliases: cubicDecimeters,
  })

  const cubicMeters = [
    'cubicm',
    'cubicmeter',
    'cubicmeters',
    'kubikmeter',
    'mcube',
    'mcubed',
    'metercube',
    'metercubed',
    'meterscube',
    'meterscubed',
  ]
  mathjs.createUnit('cubicm', {
    definition: '1 m^3',
    prefixes: 'none',
    aliases: cubicMeters,
  })
  const cubicKilometers = [
    'cubickm',
    'cubickilometer',
    'cubickilometers',
    'kubikkilometer',
    'kmcube',
    'kmcubed',
    'kilometercube',
    'kilometercubed',
    'kilometerscube',
    'kilometerscubed',
  ]
  mathjs.createUnit('cubickm', {
    definition: '1 km^3',
    prefixes: 'none',
    aliases: cubicKilometers,
  })

  const cubicInches = [
    'incube',
    'incubed',
    'inchcube',
    'inchescubed',
    'cubein',
    'cubeinch',
    'cubeinches',
    'cubein',
    'cubedinch',
    'cubedinches',
  ]
  mathjs.createUnit('in3', {
    definition: '1 in^3',
    prefixes: 'none',
    aliases: cubicInches,
  })

  const cubicFeet = [
    'ftcube',
    'ftcubed',
    'footcube',
    'feetcubed',
    'cubeft',
    'cubefoot',
    'cubefeet',
    'cubedft',
    'cubedfoot',
    'cubedfeet',
  ]
  mathjs.createUnit('ft3', {
    definition: '1 ft^3',
    prefixes: 'none',
    aliases: cubicFeet,
  })

  const cubicYards = [
    'ydcube',
    'ydcubed',
    'yardcube',
    'yardscubed',
    'cubeyd',
    'cubeyard',
    'cubeyards',
    'cubeyd',
    'cubedyard',
    'cubedyards',
  ]
  mathjs.createUnit('yd3', {
    definition: '1 yd^3',
    prefixes: 'none',
    aliases: cubicYards,
  })

  const cubicMiles = [
    'micube',
    'micubed',
    'milecube',
    'milescubed',
    'cubemi',
    'cubemile',
    'cubemiles',
    'cubedmile',
    'cubedmiles',
  ]
  mathjs.createUnit('mi3', {
    definition: '1 mi^3',
    prefixes: 'none',
    aliases: cubicMiles,
  })

  const lbs = ['pound', 'pounds']
  mathjs.createUnit('pound', {
    definition: '1 lbs',
    prefixes: 'none',
    aliases: lbs,
  })

  const ton = ['tonnes', 't', 'tonne']
  mathjs.createUnit('tonnes', { definition: '1 ton', prefixes: 'none', aliases: ton }, { override: true })

  const floz = []
  mathjs.createUnit('fluidoz', { definition: '1 floz', prefixes: 'none', aliases: floz }, { override: true })

  const cups = []
  mathjs.createUnit('c', { definition: '1 cup', prefixes: 'none', aliases: cups }, { override: true })
}
