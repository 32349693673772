import React, { useEffect, useRef } from 'react'
import './styles.scss'
import { Editor } from 'iink-ts'
import { useTranslation } from 'react-i18next'
import { writeMyscriptLogg } from '../../helpers'
import { applyStylesToEditor, cleanLatex, getMyscriptSetUpSettings, isRecognitionFailed } from './helpers'
import { ExportType, MyScriptExportResult } from './types'
import { MathGrammarCode } from './mathGrammarCode'
import { transformLatexToText } from '../../helpers/myScript/transformLatexToText'
import { answerEditorModel } from '../Problem/models/editor'
import { answerAreaMyScriptModel } from './model'
import MyScriptPlaceholder, { MyScriptPlaceholderDrawingProps } from './components/MyScriptPlaceholder'
import { useMatchesMobile } from '../../lib/device/hook'
import { currentProblemAnswerModel } from '../Problem/models/answer'
import clsx from 'clsx'
import { noop } from '../../lib/helpers'

type MyScriptProps = {
  showForceDrawing: boolean
  onRecognition?: (isRecognizing: boolean) => void
  characterType?: MathGrammarCode
  isDisabled?: boolean
  onPressIn?: () => void
  forceDrawingProps?: MyScriptPlaceholderDrawingProps
  emptyDrawingProps?: MyScriptPlaceholderDrawingProps
}

const MyScriptView = ({
  showForceDrawing,
  onRecognition,
  characterType = MathGrammarCode.DEFAULT,
  isDisabled,
  onPressIn,
  forceDrawingProps,
  emptyDrawingProps,
}: MyScriptProps) => {
  const { i18n } = useTranslation()
  const editorElementRef = useRef<HTMLDivElement | null>(null)
  const editorRef = useRef<Editor | null>(null)
  const wasPressed = useRef(false)
  const isMobileDevice = useMatchesMobile()

  const handlePressIn = () => {
    if (showForceDrawing) return

    wasPressed.current = true
    onPressIn?.()
    answerEditorModel.setPlaceholderVisible(false)
  }

  const handlePressUp = () => {
    if (showForceDrawing) return

    if (wasPressed.current && editorRef.current) onRecognition?.(true)
    wasPressed.current = false
  }

  function handleExport(event: MyScriptExportResult) {
    const jiixExport = event.detail?.[ExportType.JIIX]
    const jiixString = jiixExport ? JSON.stringify(jiixExport) : ''
    if (isRecognitionFailed(jiixString)) {
      event.target.editor.clear().catch(noop)
      currentProblemAnswerModel.clearText()
      return
    }
    answerAreaMyScriptModel.setDrawing(jiixExport?.expressions ? jiixString : null)
    const latexExport = event.detail?.[ExportType.LATEXT] ?? ''
    const latex = cleanLatex(latexExport)
    if (latexExport) currentProblemAnswerModel.setText(transformLatexToText(latex))
  }

  useEffect(() => {
    if (!editorElementRef.current) return

    const onExport = (e: MyScriptExportResult) => {
      handleExport(e)
      onRecognition?.(false)
    }

    const onError = (error: any) => {
      writeMyscriptLogg(error)
      onRecognition?.(false)
    }

    const settings = getMyscriptSetUpSettings({
      grammarCode: characterType,
      language: i18n.language,
    })

    const editor = new Editor(editorElementRef.current, settings)

    editor
      .initialize()
      .then(() => {
        applyStylesToEditor(editor)
        editor.events.addEventListener('error', onError)
        editor.events.addEventListener('exported', onExport as any)
        editorRef.current = editor
        answerAreaMyScriptModel.currentEditorChanged(editor)
      })
      .catch(noop)

    return () => {
      editor.behaviors.destroy().catch(noop)
      editor.events.removeEventListener('error', onError)
      editor.events.removeEventListener('exported', onExport as any)
      editorRef.current = null
      answerAreaMyScriptModel.currentEditorChanged(null)
      onRecognition?.(false)
    }
  }, [characterType, i18n.language])

  useEffect(() => {
    const resizeListener = () => {
      editorRef.current?.resize()
    }

    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  if (isMobileDevice) return null

  return (
    <div
      className={clsx('editor-container', isDisabled && 'disabled')}
      onMouseDown={handlePressIn}
      onTouchStart={handlePressIn}
      onMouseUp={handlePressUp}
      onTouchEnd={handlePressUp}
      onMouseOut={handlePressUp}
      onTouchCancel={handlePressUp}
    >
      {/*
       Do not add classNames, because myScript adds its own classes to the editor,
       so they will be overwritten
      */}
      <div key={characterType + i18n.language} ref={editorElementRef} />

      <MyScriptPlaceholder
        showForceDrawing={showForceDrawing}
        emptyDrawingProps={emptyDrawingProps}
        forceDrawingProps={forceDrawingProps}
      />
    </div>
  )
}

export default MyScriptView
