import React from 'react'

import { currentProblemAnswerModel } from './models/answer'
import { performAnswerForNumpad } from '../../helpers/exercises/answerProblemHelper'
import {
  EMPTY_HTML_ELEMENT,
  FRACTION_HTML_ELEMENT,
  GREATER_THAN_ELEMENT,
  LESS_THAN_ELEMENT,
  POWER_CUBE_HTML_ELEMENT,
  POWER_HTML_ELEMENT,
  POWER_SQUARE_HTML_ELEMENT,
  TEXT_AREA_NODE,
  TEXT_NODE_TYPE,
} from '../../constants/HTMLElementsConstants'
import { BACKSPACE, CLEAR_ALL, FRACTION, POWER, POWER_CUBE, POWER_SQUARE } from '../../constants/mathOperationConstants'
import { clearLeadingZeros, compareAnswers, evalMathExpression } from '../../helpers/smart-eval'
import {
  MULTIPLE_CHOICE,
  ORDERED,
  PREFILLED_BOXES,
  PREFILLED_CHOICES,
  SINGLE_CHOICE,
} from '../../constants/answerTypes'
import { useGeogebraModel } from '../Geogebra/model'
import { focusAndSetCaretAtEnd } from '../../components/Problem/ProblemAnswer/ProblemAnswerInput/helpers'
import { CONTENT_EDITABLE_ID } from '../../components/Problem/ProblemAnswer/ProblemAnswerInput/components/AnswerContentEditable'
import { setCaret, setCursorPosition } from './answerInput.helpers'
import { writeLogg } from '../../helpers'

export const onTabPress = () => {
  const activeElement = document.activeElement
  if (!activeElement || !activeElement.classList.contains('fraction-item')) {
    return
  }

  const childNodes = activeElement.childNodes
  if (!childNodes || !childNodes.length) {
    setCaret(activeElement, activeElement, 0)
    return
  }

  const lastChild: any = childNodes[childNodes.length - 1]

  let offset = 0
  if (lastChild) {
    lastChild.length && (offset = lastChild.length)
  }

  setCaret(activeElement, lastChild, offset)
}

const getCurrentFocusedElement = () => {
  return document.getSelection()?.anchorNode
}

export const getInputElement = () => {
  const isGeogebraEditorOpen = useGeogebraModel.getState().isTextEditorOpen
  if (isGeogebraEditorOpen) return null

  return document.getElementById('answer-input')
}

const checkIsCurrentFocusedElementInInputField = (element: any) => {
  return getInputElement()?.contains(element)
}

const replaceSpecialSymbolsToHTMLElements = (value: string) => {
  return value
    .replace(/GREATER_THAN/g, GREATER_THAN_ELEMENT)
    .replace(/LESS_THAN/g, LESS_THAN_ELEMENT)
    .replace(/POWER_SQUARE/g, POWER_SQUARE_HTML_ELEMENT)
    .replace(/POWER_CUBE/g, POWER_CUBE_HTML_ELEMENT)
    .replace(/POWER/g, POWER_HTML_ELEMENT)
    .replace(/FRACTION/g, FRACTION_HTML_ELEMENT)
}

const isNeedSetEmptyElement = (value: string) => {
  return value.includes(POWER) || value.includes(POWER_SQUARE) || value.includes(POWER_CUBE) || value.includes(FRACTION)
}

const isNestedFunctions = (currentElement: any, value: string) => {
  let isNested = false

  if (value === FRACTION || value === POWER || value === POWER_SQUARE) {
    const classList = currentElement.classList || currentElement.parentNode.classList
    if (!classList) {
      isNested = false
    }

    if (value === FRACTION) {
      isNested = classList.contains('fraction-item')
    }

    if (isNested) {
      return isNested
    }

    isNested = currentElement.nodeName === 'SUP' || currentElement.parentNode.nodeName === 'SUP'
  }

  return isNested
}

export const setElementInFocusedPosition = (
  value: string,
  isMistakeAnswerStatus: boolean,
  setCaretPositionCallback: (() => void) | null,
  answerText: string,
  setAnswerText: (answerText: string) => void
) => {
  if (isMistakeAnswerStatus) {
    currentProblemAnswerModel.resetStatus()
  }

  const currentElement: any = getCurrentFocusedElement()
  const focusOffset = document.getSelection()?.focusOffset
  if (!currentElement || !checkIsCurrentFocusedElementInInputField(currentElement)) {
    const newAnswer = answerText + replaceSpecialSymbolsToHTMLElements(value)
    const { performedAnswer, needToSetCursor } = performAnswerForNumpad(
      `${newAnswer}${isNeedSetEmptyElement(value) ? EMPTY_HTML_ELEMENT : ''}`
    )
    setAnswerText(performedAnswer)
    if (needToSetCursor) {
      setCursorPosition('fraction-item denominator')
      return
    }

    if (setCaretPositionCallback) {
      setCaretPositionCallback()
    } else {
      const inputElement: any = getInputElement()
      if (!inputElement) {
        return
      }

      if (!inputElement.lastChild) {
        inputElement.focus()
        return
      }

      setCaret(inputElement, inputElement.lastChild, inputElement.lastChild.length)
    }

    return
  }

  if (isNestedFunctions(currentElement, value)) {
    setNewCaretPosition(currentElement, focusOffset || 0, 0)
    return
  }

  if (currentElement.nodeType === TEXT_NODE_TYPE) {
    const content = currentElement.data
    currentElement.data = [content.slice(0, focusOffset), value, content.slice(focusOffset)].join('')
  } else {
    let content = currentElement.innerHTML
    currentElement.innerHTML = [content.slice(0, focusOffset), value, content.slice(focusOffset)].join('')
  }

  const answerInput = getInputElement()

  if (answerInput && answerInput.innerHTML) {
    const indexOfValue = answerInput.innerHTML.indexOf(value)
    const newAnswerText = [
      answerInput.innerHTML.slice(0, indexOfValue),
      replaceSpecialSymbolsToHTMLElements(value),
      isNeedSetEmptyElement(value) ? EMPTY_HTML_ELEMENT : '',
      answerInput.innerHTML.slice(indexOfValue + value.length),
    ].join('')

    const { performedAnswer, needToSetCursor } = performAnswerForNumpad(newAnswerText)
    setAnswerText(performedAnswer)
    if (needToSetCursor) {
      setCursorPosition('fraction-item denominator')
      return
    }
  }

  if (setCaretPositionCallback) {
    setCaretPositionCallback()
    return
  }

  let increaseOffset
  if (
    value.includes(POWER_CUBE) ||
    value.includes(POWER_SQUARE) ||
    value.includes(POWER) ||
    value.includes(FRACTION) ||
    value.includes(CLEAR_ALL) ||
    value.includes(BACKSPACE)
  ) {
    increaseOffset = 1
  } else {
    increaseOffset = value.length
  }

  setNewCaretPosition(currentElement, focusOffset || 0, increaseOffset)
}

const setNewCaretPosition = (currentElement: any, focusOffset: number, increaseOffset: number) => {
  let element = currentElement
  if (currentElement.nodeType === TEXT_NODE_TYPE) {
    element = currentElement.parentNode
  }

  if (!element) {
    return
  }

  let childNode
  if (currentElement.nodeType === TEXT_NODE_TYPE) {
    childNode = currentElement
  } else {
    childNode = element.childNodes[0] || element
  }

  let offset = focusOffset + increaseOffset
  if (!childNode || !childNode.length || childNode.length <= offset) {
    offset = childNode.length || 0
  }

  setCaret(element, childNode, offset)
}

export const specialSymbolPress = (
  symbol: string,
  isMistakeAnswerStatus: boolean,
  answerText: string,
  setAnswerText: (answerText: string) => void,
  resetAnswerText: () => void
) => {
  switch (symbol) {
    case POWER_SQUARE:
      setElementInFocusedPosition(POWER_SQUARE, isMistakeAnswerStatus, null, answerText, setAnswerText)
      break
    case POWER:
      const setCursorToPowerCallback = () => {
        setCursorPosition('a-pow-b')
      }

      setElementInFocusedPosition(POWER, isMistakeAnswerStatus, setCursorToPowerCallback, answerText, setAnswerText)
      break
    case FRACTION:
      const setCursorToFractionCallback = () => {
        setCursorPosition('fraction-item numerator')
      }

      setElementInFocusedPosition(
        FRACTION,
        isMistakeAnswerStatus,
        setCursorToFractionCallback,
        answerText,
        setAnswerText
      )
      break
    case CLEAR_ALL:
      resetAnswerText()
      getInputElement()?.focus()
      if (isMistakeAnswerStatus) {
        currentProblemAnswerModel.resetStatus()
      }
      break
    default:
      break
  }

  if (symbol.includes(POWER_CUBE)) {
    setElementInFocusedPosition(symbol, isMistakeAnswerStatus, null, answerText, setAnswerText)
  }
}

const checkIsCurrentElementFraction = (element: HTMLElement) => {
  return element && element.nodeName === 'DIV' && element.classList.contains('fraction')
}
const checkIsFractionEmpty = (currentElement: any) => {
  let fractionElement
  if (checkIsCurrentElementFraction(currentElement)) {
    fractionElement = currentElement
  } else {
    const parentElement = currentElement.parentNode
    if (parentElement && checkIsCurrentElementFraction(parentElement)) {
      fractionElement = parentElement
    } else {
      const nestedParentElement = parentElement.parentNode
      if (nestedParentElement && checkIsCurrentElementFraction(nestedParentElement)) {
        fractionElement = nestedParentElement
      }
    }
  }

  return fractionElement
}

const fractionBackspaceHelper = (element: any) => {
  const fractionDenominator = element.lastChild
  const fractionNumerator = element.firstChild
  if (fractionDenominator && fractionDenominator.innerHTML) {
    const childNode = fractionDenominator.childNodes[0]
    if (childNode) {
      setCaret(getInputElement(), childNode, fractionDenominator.innerHTML.length)
    }
  } else if (fractionNumerator && fractionNumerator.innerHTML) {
    const childNode = fractionNumerator.childNodes[0]
    if (childNode) {
      setCaret(getInputElement(), childNode, fractionNumerator.innerHTML.length)
    }
  } else {
    element.remove()
    const previousSibling = element.previousSibling
    if (!previousSibling) {
      getInputElement()?.dispatchEvent(new Event('input', { bubbles: true }))
      return
    }

    let childNode = previousSibling
    let offsetToSet
    if (previousSibling.nodeType !== TEXT_NODE_TYPE) {
      childNode = previousSibling.childNodes[0]
      offsetToSet = previousSibling.innerHTML.length
    } else {
      offsetToSet = previousSibling.nodeValue.length
    }

    if (childNode) {
      setCaret(getInputElement(), childNode, offsetToSet)
    }

    getInputElement()?.dispatchEvent(new Event('input', { bubbles: true }))
  }
}

const getTextChildNode: (node: any) => undefined | any = (node: any) => {
  if (!node) {
    return
  }

  if (node.nodeType !== TEXT_NODE_TYPE) {
    if (node.lastChild) {
      return getTextChildNode(node.lastChild)
    } else {
      return getTextChildNode(node.previousSibling)
    }
  }

  return node
}

const getChildNodeLength: (node: any) => number | any = (node: any) => {
  if (!node) {
    return 0
  }

  if (node.nodeType !== TEXT_NODE_TYPE) {
    return getChildNodeLength(node.lastChild)
  }

  return node.nodeValue.length
}

const previousSiblingTagHelper = (previousSibling: any) => {
  if (previousSibling.nodeType !== TEXT_NODE_TYPE && previousSibling.innerHTML) {
    if (checkIsCurrentElementFraction(previousSibling)) {
      fractionBackspaceHelper(previousSibling)
      return
    }

    const regexpOnSpace = /&nbsp;$/
    const match = regexpOnSpace.exec(previousSibling.innerHTML)
    if (match) {
      previousSibling.innerHTML = previousSibling.innerHTML.slice(0, match.index)
    }

    let childNode = previousSibling.lastChild
    let offsetToSet = previousSibling.innerHTML.length

    if (childNode) {
      if (checkIsCurrentElementFraction(childNode)) {
        fractionBackspaceHelper(childNode)
        return
      }
      setCaret(getInputElement(), childNode, offsetToSet)
    }

    return
  }

  if (previousSibling.nodeType !== TEXT_NODE_TYPE && !previousSibling.innerHTML) {
    const deeperPreviousSibling = previousSibling.previousSibling
    if (previousSibling.nodeName === 'SUP') {
      previousSibling.remove()
    }

    let childNode = deeperPreviousSibling
    if (checkIsCurrentElementFraction(childNode)) {
      fractionBackspaceHelper(childNode)
      return
    }

    let offsetToSet
    if (deeperPreviousSibling.nodeType !== TEXT_NODE_TYPE) {
      childNode = getTextChildNode(childNode)
      offsetToSet = getChildNodeLength(childNode)
    } else {
      offsetToSet = deeperPreviousSibling.nodeValue.length
    }

    if (childNode) {
      if (checkIsCurrentElementFraction(childNode)) {
        fractionBackspaceHelper(childNode)
        return
      }

      setCaret(getInputElement(), childNode, offsetToSet)
    }

    getInputElement()?.dispatchEvent(new Event('input', { bubbles: true }))
  }
}

export const getHTMLTextContent = (htmlString = '') => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const textContent = doc.body.textContent || ''
  return textContent.trim()
}

export const cleanupContentEditableMathPower = (htmlString = '') => {
  const mathPowerElementRegex = /<sup class=["']a-pow-b["']>/

  const value = htmlString ?? document.querySelector(`#${CONTENT_EDITABLE_ID}`)?.innerHTML
  const textLength = getHTMLTextContent(value).length

  if (!textLength || (textLength === 1 && !value.match(mathPowerElementRegex))) {
    currentProblemAnswerModel.clearText()
    return focusAndSetCaretAtEnd()
  }
}

export const backspaceClick = (e: React.MouseEvent) => {
  e.preventDefault()
  const currentFocusedElement = getCurrentFocusedElement()
  if (!checkIsCurrentFocusedElementInInputField(currentFocusedElement)) {
    const inputElement: any = getInputElement()
    if (!inputElement || !inputElement.lastChild || !(inputElement.lastChild.nodeType === TEXT_NODE_TYPE)) {
      return
    }

    if (inputElement.lastChild.nodeType === TEXT_NODE_TYPE) {
      setCaret(inputElement, inputElement.lastChild, inputElement.lastChild.length)
    } else {
      getInputElement()?.focus()
    }

    backspaceClick(e)
    return
  }

  const input: any = getInputElement()
  const selection: any = document.getSelection()
  const offset = selection?.focusOffset

  const fractionElement = checkIsFractionEmpty(selection?.anchorNode)
  if (fractionElement) {
    if (
      fractionElement.firstChild &&
      !fractionElement.firstChild.innerHTML &&
      fractionElement.lastChild &&
      !fractionElement.lastChild.innerHTML
    ) {
      fractionElement.remove()
      input.dispatchEvent(new Event('input', { bubbles: true }))
      return
    }
  }

  if (selection.anchorNode.nodeType === TEXT_NODE_TYPE && selection.anchorNode.nodeValue && offset) {
    const nodeValue = selection.anchorNode.nodeValue
    selection.anchorNode.nodeValue = [nodeValue.slice(0, offset - 1), nodeValue.slice(offset, nodeValue.length)].join(
      ''
    )
    const offsetToSet = offset > 0 ? offset - 1 : offset
    setCaret(input, selection.anchorNode, offsetToSet)
    input.dispatchEvent(new Event('input', { bubbles: true }))
  } else if (selection.anchorNode.nodeType === 1 && selection.anchorNode.innerHTML && offset) {
    setCaret(input, selection.anchorNode.lastChild, selection.anchorNode.lastChild.length)
  } else {
    const previousSibling = selection.anchorNode.previousSibling
    if (!previousSibling) {
      if (
        selection.anchorNode.parentNode &&
        (selection.anchorNode.parentNode.nodeName === 'SUP' || selection.anchorNode.parentNode.nodeName === 'SPAN')
      ) {
        const parentNodePreviousSibling = selection.anchorNode.parentNode.previousSibling
        if (!parentNodePreviousSibling) {
          return
        }

        if (parentNodePreviousSibling.nodeType !== TEXT_NODE_TYPE) {
          previousSiblingTagHelper(parentNodePreviousSibling)
          return
        }

        setCaret(input, parentNodePreviousSibling, parentNodePreviousSibling.nodeValue.length)
        return
      }
      return
    }

    if (previousSibling.nodeType !== TEXT_NODE_TYPE) {
      previousSiblingTagHelper(previousSibling)
      return
    }

    setCaret(input, previousSibling, previousSibling.nodeValue.length)
  }
}

export const isAnswerCorrect = (
  userAnswer: string[] | string,
  currentTask: any,
  isRequiredUnit: boolean,
  language: string,
  badAnswerFormat: string,
  setBadAnswerFormat: (badAnswerFormat: string) => void
) => {
  setBadAnswerFormat('')

  if (!currentTask.answer) {
    return false
  }

  if (!Array.isArray(userAnswer)) {
    const cleanedTaskAnswer = currentTask.answer.map((answer: string) => answer.replace(/,/g, '.'))
    const evaluatedAnswers = cleanedTaskAnswer.map((answer: string) => evalMathExpression(answer))
    const evaluatedUserAnswer = evalMathExpression(userAnswer)

    if (!currentTask.smartEvaluation) {
      return evaluatedAnswers.includes(evaluatedUserAnswer.replace(/,/g, '.'))
    }

    return evaluatedAnswers
      .map((answer: string) => answer.toLowerCase())
      .includes(evaluatedUserAnswer.replace(/,/g, '.').toLowerCase())
  }

  if (
    currentTask.answerType === ORDERED ||
    currentTask.answerType === PREFILLED_BOXES ||
    currentTask.answerType === PREFILLED_CHOICES
  ) {
    if (userAnswer.length !== currentTask.answer.length) {
      return false
    }

    let transformedTaskAnswer = currentTask.answer.map((answer: string) => answer.replace(/\n/g, ''))
    let transformedUserAnswer = userAnswer.map((answer) => answer.replace(/\n/g, ''))
    if (currentTask.smartEvaluation) {
      transformedTaskAnswer = transformedTaskAnswer.map((answer: string) => clearLeadingZeros(answer))
      transformedUserAnswer = transformedUserAnswer.map((answer) => clearLeadingZeros(answer))
    }

    return JSON.stringify(transformedUserAnswer) === JSON.stringify(transformedTaskAnswer)
  }

  if (currentTask.answerType === SINGLE_CHOICE || currentTask.answerType === MULTIPLE_CHOICE) {
    if (userAnswer.length !== currentTask.answer.length) {
      return false
    }
    const clearedUserAnswer = userAnswer.map((answer) => answer.replace(/\n/g, ''))
    const clearedTaskAnswer = currentTask.answer.map((answer: string) => answer.replace(/\n/g, ''))
    return clearedUserAnswer.every((answer) => clearedTaskAnswer.includes(answer))
  }

  return compareAnswers(currentTask, userAnswer, badAnswerFormat, setBadAnswerFormat, isRequiredUnit, language)
}

export const checkIsActiveElementInInput = (inputElement: any) => {
  const activeElement = document.activeElement

  return (
    inputElement === activeElement || inputElement.contains(activeElement) || activeElement?.nodeName === TEXT_AREA_NODE
  )
}

export const setCaretInInputFieldOnKeyPress = (inputElement: any) => {
  if (!inputElement) {
    writeLogg('no input element')
  }
  if (!inputElement?.innerText) {
    setCaret(inputElement, inputElement, 0)
    return
  }

  if (!inputElement || !inputElement.lastChild || !(inputElement.lastChild.nodeType === TEXT_NODE_TYPE)) {
    return
  }

  setCaret(inputElement, inputElement.lastChild, inputElement.lastChild.length)
}

export const focusOnEmptyFractionPart = () => {
  const denominatorsElements = document.getElementsByClassName('fraction-item denominator')
  const denominatorsArray = Array.from(denominatorsElements)
  const firstEmptyDenominator = denominatorsArray.find((denominator) => !denominator.innerHTML)

  if (firstEmptyDenominator) {
    setCaret(firstEmptyDenominator, firstEmptyDenominator, 0)
    return
  }

  const numeratorsElements = document.getElementsByClassName('fraction-item numerator')
  const numeratorsArray = Array.from(numeratorsElements)
  const firstEmptyNumerator = numeratorsArray.find((numerator) => !numerator.innerHTML)
  firstEmptyNumerator && setCaret(firstEmptyNumerator, firstEmptyNumerator, 0)
}
