import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Switch from 'react-switch'
import './styles.scss'

import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { Context as SidebarContext } from '../../context/sidebarContext'
import { ReactComponent as SoundIcon } from '../../assets/images/speaker_light.svg'
import { TEXT_TO_SPEECH_SWITCH_WIDTH, TEXT_TO_SPEECH_SWITCH_HEIGHT } from '../../constants/styleConstants'
import { changeUserSettings } from '../../helpers/users/getUserInfo'
import LanguageSelector from './LanguageSelector'
import NativeLanguageList from './NativeLanguageList'
import TextSizeSlider from '../TextSizeSlider'
import AddNewClass from '../AddNewClass'
import clsx from 'clsx'
import ChangeCanvasType from 'src/components/Profile/ChangeCanvasType'
import { COLORS } from '@magmamath/ui'
import { assignmentModel } from '../../features/assignment/model/assignment.model'
import { AuthModel } from '../../features/auth/authModel'

const Profile = ({ userData }) => {
  const { t } = useTranslation()
  const { state, enableTextToSpeech, disableTextToSpeech, changeTranslationLanguage, changeTextSize } =
    useContext(UserSettingsContext)
  const { clearSidebarContext } = useContext(SidebarContext)
  const [nativeLangSelectorOpen, setNativeLangSelectorOpen] = useState(false)
  const [textSize, setTextSize] = useState(16)

  useEffect(() => {
    setTextSize(state.textSize)
  }, [state.textSize])

  const logoutUser = () => {
    AuthModel.logout()
    assignmentModel.reset()
    clearSidebarContext()
  }

  const onTextSizeChange = (e, value) => {
    setTextSize(value)
  }

  const saveTextSize = async (textSize) => {
    changeTextSize(textSize)
    await changeUserSettings({ textSize: Number(textSize) })
  }

  const nativeLanguageChanged = async (translationLanguage) => {
    changeTranslationLanguage(translationLanguage)
    setNativeLangSelectorOpen(false)
    await changeUserSettings({ translationLanguage })
  }

  const onSwitchChange = async (checked) => {
    checked ? enableTextToSpeech() : disableTextToSpeech()
    await changeUserSettings({ textToSpeech: checked })
  }

  return (
    <div className='profile-info-content-container'>
      <div className='profile-info-box'>
        <h2 className='profile-info-heading'>{t('profileInfoHeadingText')}</h2>
        <div className='profile-info-grid'>
          <div className='profile-info-column'>
            <div className='sound-settings-container'>
              <span className='sound-settings-label'>{t('textToSpeech')}</span>
              <div className='sound-settings-controller'>
                <SoundIcon />
                <Switch
                  className='sound-switch'
                  checked={state.textToSpeech}
                  onChange={onSwitchChange}
                  onColor={COLORS.PRIMARY_GREEN}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  activeBoxShadow=''
                  offColor={COLORS.NEUTRAL_6}
                  width={TEXT_TO_SPEECH_SWITCH_WIDTH}
                  height={TEXT_TO_SPEECH_SWITCH_HEIGHT}
                />
              </div>
            </div>
          </div>
          <div className='profile-info-column'>
            <div className='text-size-container'>
              <span className='text-size-label'>{t('textSize')}</span>
              <div className='text-slider-container'>
                <TextSizeSlider value={textSize} changeTextSize={onTextSizeChange} saveTextSize={saveTextSize} />

                <div className='text-slider-letters-wrapper'>
                  <span className='text-slider-small-letter'>A</span>
                  <span className='text-slider-medium-letter'>A</span>
                  <span className='text-slider-big-letter'>A</span>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-info-column'>
            <LanguageSelector
              onSelectorClick={() => setNativeLangSelectorOpen((prevState) => !prevState)}
              languageName={state.translationLanguage}
              title={t('translationText')}
            >
              <NativeLanguageList
                onChangeLanguage={nativeLanguageChanged}
                showSelector={nativeLangSelectorOpen}
                setShowSelector={setNativeLangSelectorOpen}
              />
            </LanguageSelector>
          </div>

          <div className='profile-info-column'>
            <div className='text-size-container'>
              <span className='text-size-label'>{t('canvasType')}</span>
              <ChangeCanvasType />
            </div>
          </div>
        </div>
      </div>

      <div className='profile-info-user-details'>
        <div className='profile-user-data-grid'>
          <div className='profile-info-column profile-info-center'>
            <span className='profile-info-text'>{t('profileInfoNameText')}</span>
            <h2
              className={clsx('profile-info-user-data-heading', {
                'text-exceeded': userData?.firstName.length + userData?.lastName.length > 30,
              })}
            >
              {`${userData.firstName} ${userData.lastName}`}
            </h2>
          </div>
          <div className='profile-info-column profile-info-center'>
            <span className='profile-info-text'>{t('usernameText')}</span>
            <h2
              className={clsx('profile-info-user-data-heading', {
                'text-exceeded': userData?.username?.length > 30,
              })}
            >
              {userData.username}
            </h2>
          </div>
          <div className='profile-info-column profile-info-center'>
            <span className='profile-info-text'>{t('schoolText')}</span>
            <h2
              className={clsx('profile-info-user-data-heading', {
                'text-exceeded': userData?.school?.length > 30,
              })}
            >
              {userData.school}
            </h2>
          </div>
        </div>
      </div>

      <div className='profile-info-buttons-wrapper'>
        <button className='profile-info-logout-button' onClick={logoutUser}>
          {t('logoutText')}
        </button>

        <div className='add-class-wrapper'>
          <div className='add-class-container'>
            <AddNewClass />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
