export const DEFAULT_LOCALE = 'sv-SE'
export const SE_LOCALE = 'sv-SE'
export const NETWORK_ERROR_MESSAGE = 'Network Error'

export const CLASS_CODE_LENGTH = 6

export const SMALL_SCREEN_WIDTH = 600

export const DEFAULT_FONT = 'open sans'

export const COMPARE_APP_VERSION_TIME = 600000

export const NO_LANGUAGE_SELECTED = null
export const DEPRECATED_NONE_LANGUAGE = 'None' // WARNING: Left just until migration is done on the backend to not break functionality for current users with language set to "None" instead of null

export const SPECIAL_PLACE_NOT_TRANSLATE = '{123_123_123}'
export const LINE_BREAK_SYMBOL = '\n'
export const DEFAULT_USER_SETTINGS_CANVAS_TYPE = 1
