import { zustandEvent, zustandStore } from '../../lib/zustand'
import { DrawBoardMode, ModeChangeProps, SpreadsheetController } from '@magmamath/react-native-draw-board'
export { DrawBoardMode } from '@magmamath/react-native-draw-board'

export type DrawBoardModel = {
  mode: DrawBoardMode
}

type SpreadsheetModel = {
  isCellActive: boolean
  controller: SpreadsheetController | null
}

export const drawBoardModel = {
  setMode: zustandEvent<ModeChangeProps>(),
  setInputFocused(value: boolean) {
    drawBoardModel.ref.inputFocused = value
  },
  ref: {
    inputFocused: false,
  },
}
export const spreadsheetModel = {
  setIsCellActive: zustandEvent<boolean>(),
}

export const useSpreadsheetModel = zustandStore<SpreadsheetModel>({
  isCellActive: false,
  controller: null,
})
  .add(drawBoardModel.setMode, (mode) => ({
    controller: mode.name === DrawBoardMode.SPREADSHEET ? mode.controller : null,
  }))
  .add(spreadsheetModel.setIsCellActive, (isCellActive) => ({ isCellActive }))

export const useDrawBoardModel = zustandStore<DrawBoardModel>({
  mode: DrawBoardMode.COMMON,
}).add(drawBoardModel.setMode, ({ name }) => ({ mode: name }))
