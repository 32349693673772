import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const ReloadPage = () => {
    const { t } = useTranslation();

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <div className="reload-page-container">
            <span className="new-version-text">{t('newVersionAvailableMessage')}</span>
            <button
                className="update-button"
                onClick={reloadPage}
            >
                {t('updateText')}
            </button>
        </div>
    );
};

export default ReloadPage;