import React from 'react'
import ReactMinimalPieChart from 'react-minimal-pie-chart'
import type { ChartData } from '../../features/ExerciseItem/types'

type ExerciseProgressChartProps = {
  data: ChartData[]
}

const ExerciseProgressChart = ({ data }: ExerciseProgressChartProps) => {
  return (
    <ReactMinimalPieChart
      cx={50}
      cy={50}
      data={data}
      animate={true}
      animationDuration={500}
      animationEasing='ease-out'
      startAngle={-90}
      label={false}
      labelPosition={50}
      lengthAngle={360}
      lineWidth={35}
      radius={50}
      viewBoxSize={[100, 100]}
    />
  )
}

export default ExerciseProgressChart
