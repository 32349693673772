import React, { useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'
import EmptyContainer from '../../../ui/emptyContainer/EmptyContainer'
import usePracticeStore from '../models/practice'
import PracticeCategory from '../PracticeCategory/PracticeCategory'
import { practicePageSessionActions } from '../sessionStorage'
import { activeSubCategoryModel, useActiveSubCategoryModel } from '../models/activeSubCategory'
import {
  filterCategoriesByGrade,
  filterSubcategoriesByGrade,
  getFirstUnsolvedAbility,
  getSolvedCategoryPercent,
  getPreviousActiveCategoryAndSubCategoryId,
} from '../practice.helpers'
import { $practiceGrade } from '../models/currentPracticeGrade'
import { practiceModeModel } from '../models'
import useHighlightItemsOnScrollEffect from '../hooks/useHighlightItemsOnScrollEffect'
import { $availableGrades } from '../models/availableGrades'
import { getSubcategoryStatistic } from '../requests'
import { useOnIntersect } from '../../../lib/hooks/useOnIntersect'

const PracticeBody = () => {
  const { t } = useTranslation()
  const {
    categories,
    filteredCategoriesByGrade,
    setFilteredCategoriesByGrade,
    openedCategory,
    setOpenedCategory,
    currentCategoryId,
    setCurrentCategoryId,
    statistics,
    setStatistics,
  } = usePracticeStore()
  const practiceGrade = useUnit($practiceGrade)
  const availableGrades = useUnit($availableGrades)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const activeSubChapter = useUnit(practiceModeModel.activeSubChapter.$store)

  useEffect(() => {
    const filteredCategories = filterCategoriesByGrade(categories, practiceGrade)
    setFilteredCategoriesByGrade(filteredCategories)

    if (filteredCategories.length) {
      const { category, subCategoryId } = getPreviousActiveCategoryAndSubCategoryId(filteredCategories, practiceGrade)
      setCurrentCategoryId(category._id)
      activeSubCategoryModel.setId(subCategoryId)
    }
  }, [practiceGrade, categories])

  useEffect(() => {
    const openedCategory = filteredCategoriesByGrade.find((category) => category._id === currentCategoryId)
    if (openedCategory) setOpenedCategory(openedCategory)
  }, [currentCategoryId])

  const filteredSubCategoriesByGrade = useMemo(() => {
    if (!openedCategory || !openedCategory.subcategories) {
      return []
    }

    return filterSubcategoriesByGrade(openedCategory.subcategories, practiceGrade)
  }, [openedCategory, practiceGrade])

  const currentSubCategory = useMemo(
    () => filteredSubCategoriesByGrade.find((subcategory) => subcategory._id === activeSubChapter.id),
    [filteredSubCategoriesByGrade, activeSubChapter.id]
  )

  useEffect(() => {
    if (!filteredCategoriesByGrade?.length) {
      return
    }

    practiceModeModel.firstUnsolvedCardId.setId(
      getFirstUnsolvedAbility(filteredCategoriesByGrade, practiceGrade, statistics)?._id || ''
    )
  }, [filteredCategoriesByGrade, statistics])

  useEffect(() => {
    if (!filteredCategoriesByGrade?.length) {
      return
    }
    const loadStatistic = async () => {
      const promises = filteredCategoriesByGrade
        .map((category) => {
          return category?.subcategories?.map((subcategory) => getSubcategoryStatistic(subcategory?._id))
        })
        .flat()
      const data = await Promise.all(promises)
      setStatistics(data)
    }

    loadStatistic()
  }, [filteredCategoriesByGrade])

  useEffect(() => {
    if (!openedCategory) return

    const isActiveSubCategoryInOpenedCategory = filteredSubCategoriesByGrade?.some(
      (subcategory) => subcategory?._id === activeSubCategoryId
    )
    !isActiveSubCategoryInOpenedCategory && activeSubCategoryModel.setId(filteredSubCategoriesByGrade?.[0]?._id || '')
  }, [filteredSubCategoriesByGrade])

  useEffect(() => {
    practiceModeModel.activeSubChapter.setName(currentSubCategory?.primaryName || '')
  }, [currentSubCategory])

  useEffect(() => {
    if (!currentSubCategory) {
      return
    }

    practiceModeModel.currentSubChapterSolvedPercent.setData(
      getSolvedCategoryPercent([currentSubCategory], practiceGrade, statistics) ?? 0
    )
  }, [currentSubCategory, practiceGrade, statistics])

  useHighlightItemsOnScrollEffect({
    scrollArea: scrollContainerRef,
    grade: practiceGrade,
    categories: filteredCategoriesByGrade,
    onSetActiveCategory: setCurrentCategoryId,
    onSetActiveSubCategory: activeSubCategoryModel.setId,
    onSetStickySubChapter: practiceModeModel.activeSubChapter.setId,
  })

  const restoreScrollPosition = () => {
    const previousScroll = practicePageSessionActions.lastScrollPosition.get()
    if (!previousScroll) return

    practicePageSessionActions.lastScrollPosition.remove()
    scrollContainerRef.current?.scroll({ top: Number(previousScroll), behavior: 'smooth' })
  }

  useOnIntersect({
    ref: scrollContainerRef,
    callback: restoreScrollPosition,
  })

  return (
    <div className='practice-page-info' ref={scrollContainerRef} id='practice-page-scroll-area'>
      {!availableGrades.length ? (
        <EmptyContainer message={t('noAvailableGradesText')} />
      ) : (
        filteredCategoriesByGrade.map((category) => (
          <PracticeCategory
            key={category._id}
            category={category}
            statistics={statistics}
            scrollArea={scrollContainerRef?.current}
          />
        ))
      )}
    </div>
  )
}

export default PracticeBody
