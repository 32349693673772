import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const ChevronRightIcon = ({ size = 25, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 20 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.25 2.08398L16.3751 11.7872C16.781 12.1762 16.781 12.8251 16.3751 13.2141L6.25 22.9173'
        stroke={fill}
        strokeWidth='4.16667'
        strokeLinecap='round'
      />
    </svg>
  )
}
