import React from 'react'
import piIcon from '../../../assets/images/pi_grey.png'
import { RiAddLine, RiDivideLine, RiSubtractLine } from 'react-icons/ri'
import {
  DEGREE,
  ONE_FOOT_NOTE,
  POWER,
  POWER_SQUARE,
  STRIKETHROUGH,
  TEN,
} from '../../../constants/mathOperationConstants'
import { checkIsSwedenLocale } from '../../../helpers/userLocale'
import squareRootIcon from '../../../assets/images/square_root.png'
import Sup from '../../Sup'
import IconFraction from '../../icons/Icon.Fraction'
import { MathNumpadSymbol } from './types'
import {
  createFractionSymbol,
  createMathLetter,
  createSpecialSign,
  createSpecialSymbol,
  createSymbol,
} from './helpers.symbols'

const squareRootSymbol = createSymbol('√', {
  render: <img src={squareRootIcon} alt='square root' />,
  styleClass: 'special-sign square-root',
})

export const MATH_BUTTONS: MathNumpadSymbol[] = [
  createSpecialSign('π', <img src={piIcon} alt='pi' />),
  createMathLetter('x'),
  createMathLetter('y'),
  createSymbol('+', { render: <RiAddLine /> }),
  createSymbol('-', { render: <RiSubtractLine /> }),
  createSpecialSymbol(POWER_SQUARE, <Sup value={'a'} supValue={'2'} />),
  createSymbol(':'),
  createSymbol('('),
  createSymbol(')'),
  createSymbol('/', { render: <RiDivideLine /> }),
  createSymbol('×'),
  createSpecialSymbol(POWER, <Sup value={'a'} supValue={'b'} />),
  createSymbol(checkIsSwedenLocale() ? '.' : ','),
  squareRootSymbol,
  createSpecialSign('‰'),
  createSpecialSign('%', <span>&#37;</span>),
  createSymbol('='),
  createSpecialSymbol(DEGREE, '°'),
]

export const MATH_BUTTONS_DISABLED_FRACTION: MathNumpadSymbol[] = [createFractionSymbol(<IconFraction />)]

export const MATH_BUTTONS_EXTENDED: MathNumpadSymbol[] = [
  createFractionSymbol(<span className='spreadsheet-fraction-button'>U</span>),
  createSymbol(STRIKETHROUGH, { render: <span>/</span> }),
  createSymbol(TEN, { render: <span className='spreadsheet-special ten-button'>10</span> }),
  createSymbol(ONE_FOOT_NOTE, { render: <span className='spreadsheet-special'>1</span> }),
  createSymbol('⇒'),
  createSymbol('≈'),
  createSymbol('⇔'),
  createSymbol('≠'),
  createSymbol('⇐'),
]
