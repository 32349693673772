import React, { useMemo, useRef } from 'react'
import { useUnit } from 'effector-react'
import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'

import { Ability, PracticeStatistics } from '../types'
import AbilityCard from '../AbilityCard/AbilityCard'
import {
  filterAbilitiesByGrade,
  findFirstUnsolved,
  getNextOrderedAbility,
  getNextPromptedAbility,
} from '../practice.helpers'
import { practicePageSessionActions } from '../sessionStorage'
import usePracticeStore from '../models/practice'
import { useActiveSubCategoryModel } from '../models/activeSubCategory'
import { fullscreenModeOn } from '../../../helpers'
import useOpenAbilityCallback from '../hooks/useOpenAbilityCallback'
import useLoadTheoryCallback from '../hooks/useLoadTheoryCallback'
import { practiceTheoryModel } from '../models/practiceTheory'
import { RoutesPaths } from '../../../containers/paths'
import { practiceModeModel } from '../models'
import { $practiceGrade } from '../models/currentPracticeGrade'

type SubcategoriesBlockProps = {
  blockName: string
  blockId: string
  abilities: Ability[]
  statistics: PracticeStatistics
  scrollArea: HTMLDivElement | null
}

const SubcategoriesBlock = ({ blockName, blockId, abilities, statistics, scrollArea }: SubcategoriesBlockProps) => {
  const history = useHistory()
  const { categories } = usePracticeStore()
  const practiceGrade = useUnit($practiceGrade)

  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const subChapterTitleRef = useRef<HTMLSpanElement>(null)
  const activeSubChapter = useUnit(practiceModeModel.activeSubChapter.$store)

  const filteredAbilities = filterAbilitiesByGrade(abilities, practiceGrade)
  const { openAbilityCard } = useOpenAbilityCallback()
  const { loadTheory } = useLoadTheoryCallback()

  const goToExercise = async (ability: Ability) => {
    const nextAbility =
      getNextOrderedAbility(filteredAbilities, ability.order) ||
      getNextPromptedAbility({
        categories,
        grade: practiceGrade,
        currentAbilityId: ability._id,
      })

    const successCallback = () => {
      history.push(RoutesPaths.PRACTICE_PAGE)
      fullscreenModeOn()
    }

    const currentScrollTopPosition = scrollArea?.scrollTop
    const saveCurrentPracticePageData = () => {
      currentScrollTopPosition && practicePageSessionActions.lastScrollPosition.set(currentScrollTopPosition)
      practicePageSessionActions.lastActiveSubCategoryId.set(activeSubCategoryId)
    }

    practiceTheoryModel.setTheory(null)

    await loadTheory(ability._id)
    openAbilityCard({
      ability,
      nextAbility,
      onSuccess: successCallback,
      onSaveData: saveCurrentPracticePageData,
    })
  }

  const firstUnsolvedAbilityId = useMemo(
    () => findFirstUnsolved(filteredAbilities, statistics),
    [filteredAbilities, statistics]
  )

  return (
    <div className={styles.SubcategoryBlock} id={blockId}>
      <span className={styles.SubcategoryTitle} ref={subChapterTitleRef}>
        {blockId === activeSubChapter.id ? '' : blockName}
      </span>
      <div className={styles.AbilitiesContainer}>
        {filteredAbilities.map((ability, index) => (
          <div className={styles.AbilityCardWrapper} id={ability._id} key={index}>
            <AbilityCard
              title={ability.primaryName}
              stats={statistics[ability._id] || 0}
              onClick={() => goToExercise(ability)}
              isActionAbility={firstUnsolvedAbilityId === ability._id}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SubcategoriesBlock
