export enum MyScriptMathCharacterTypes {
  DEFAULT = 1,
  NUMBERS = 2,
  NUMBERS_AND_X = 3,
  NUMBERS_AND_MULTIPLICATION = 4,
  NUMBERS_AND_UNITS = 5,
  DEFAULT_MATH = 6,
  NUMBERS_WHOLE = 10,
  NUMBERS_RATIONAL = 11,
  OPERATORS = 12,
  PERCENT = 13,
  UNITS = 14,
  TIME = 15,
  CURRENCY = 16,
  GEOM_ANGLES = 17,
  GEOM_COORDINATE = 18,
  ALGEBRA_BASIC = 19,
  ALGEBRA_ADVANCED = 20,
  ALGEBRA_GREEK = 21,
  GENERAL_MATH = 22,
}
