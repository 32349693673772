import React from 'react'
import { PRACTICE_ICONS } from '../../constants'

export const PracticeIcons = () => {
  return PRACTICE_ICONS.map(({ Icon, size, color }, index) => (
    <div className='practice-background-icon' key={index}>
      <Icon size={size} stroke={color} />
    </div>
  ))
}
