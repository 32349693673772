import { persist } from 'effector-storage/session'
import { createEvent, restore } from 'effector'
import usePracticeProblemsStore, { PracticeMode } from './practiceProblems'
import { isBelowK2Grade } from '../../simpleMode/helpers'
import { CURRENT_PRACTICE_GRADE } from '../../../config/localStorageKeys'

export const setPracticeGrade = createEvent<number>()
export const $practiceGrade = restore(setPracticeGrade, -1)

$practiceGrade.watch((practiceGrade) => {
  const setPracticeMode = usePracticeProblemsStore.getState().setPracticeMode

  if (isBelowK2Grade(practiceGrade)) {
    setPracticeMode(PracticeMode.SIMPLE)
  } else {
    setPracticeMode(PracticeMode.REGULAR)
  }
})

persist({ store: $practiceGrade, key: CURRENT_PRACTICE_GRADE })
