import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { ALLOWED_TAGS } from './constants'

const fetchContentHTML = async (contentUrl) => {
  const response = await fetch(contentUrl)
  const dirtyHTML = await response.text()
  const purifiedHTML = DOMPurify.sanitize(dirtyHTML, {
    ALLOWED_TAGS,
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return purifiedHTML
}

const HtmlContent = ({ contentUrl }) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    if (!contentUrl) return

    fetchContentHTML(contentUrl).then((result) => {
      setContent(result)
    })
  }, [contentUrl])

  if (!content) {
    return <div />
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} />
}

export default HtmlContent
