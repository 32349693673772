type GetDotStyleProps = {
  index: number
  size: number
  speedMultiplier: number
  color: string
  margin: number
}

const ANIMATION_DELAY = 0.07
const BASE_DURATION = 0.6

export const getDotStyle = ({ index, size, speedMultiplier, color, margin }: GetDotStyleProps) => {
  return {
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    margin: `${margin}px`,
    animation: `DotsLoader ${BASE_DURATION / speedMultiplier}s ${index * ANIMATION_DELAY}s infinite ease-in-out`,
    animationFillMode: 'both',
    backgroundColor: color,
    borderRadius: '100%',
  }
}
