import React, { useEffect, useState } from 'react'
import { useStoreMap, useUnit } from 'effector-react'
import styles from './styles.module.scss'
import { ToggleButton } from './components/ToggleButton/ToggleButton'
import { ChatConversation } from './components/ChatConversation/ChatConversation'
import { $isChatOpen } from './models/isChatOpen'
import { SlideInRight } from '../../ui/SlideInRight/SlideInRight'
import { useHintMyScriptModel } from '../AnswerHints/model'
import { availableChatsModel } from './models/availableChatsModel'
import { $initialAlternatives, fetchInitialAlternativesFx } from './models/initialAlternatives'
import { useAssignmentCredentials } from '../assignment/hooks/useAssignmentCredentials'

type MagmaChatProps = {
  description: string
  isEnabled: boolean
  strokeCount?: number
  height: number
  answerType: number
  problemAnswer: string[]
}

export const MagmaChat = ({ height, isEnabled, description, answerType, problemAnswer }: MagmaChatProps) => {
  const answerHint = useHintMyScriptModel((state) => state.hint?.text)
  const isChatOpen = useUnit($isChatOpen)
  const initialAlternatives = useUnit($initialAlternatives)
  const { id, problemId } = useAssignmentCredentials()
  const [animate, setAnimate] = useState(true)

  const isChatAvailable = useStoreMap({
    store: availableChatsModel.$state,
    keys: [id, problemId],
    fn: (availableChats, [assignmentId, problemId]) => !!availableChats[assignmentId]?.[problemId]?.isChatAvailable,
  })

  useEffect(() => {
    if (isEnabled && !initialAlternatives) fetchInitialAlternativesFx()
  }, [initialAlternatives, isEnabled])

  if (!isEnabled || !isChatAvailable) return null

  return (
    <div className={styles.MagmaChat}>
      {isChatOpen && (
        <ChatConversation
          description={description}
          height={height}
          answerType={answerType}
          problemAnswer={problemAnswer}
        />
      )}

      <SlideInRight shouldAnimate={!answerHint && animate}>
        <ToggleButton onClick={() => setAnimate(false)} />
      </SlideInRight>
    </div>
  )
}
