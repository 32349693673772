import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const GreaterThanIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.273496 11.2393L9.8316 7.19873L0.273496 2.66992V0.863281L12.0167 6.67383V7.8335L0.273496 13.0337V11.2393Z'
        fill={fill}
      />
    </svg>
  )
}
