import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import './styles.scss'
import {
  BoardController,
  ClearResult,
  DrawBoard as NativeDrawBoard,
  DrawBoardMode,
  ModeChangeProps,
  ToolBarVariants,
  useDrawBoardListeners,
} from '@magmamath/react-native-draw-board'
import { useAutoFocusSpreadsheetCell, useDrawBoardLocalization } from './hooks'
import { useDrawBoardModules } from './model.modules'
import { useMyScriptRecognitionModule } from './hook.myScript'
import { useDrawBoardCheatingDetection } from './hook.cheatingDetection'

import { MathGrammarCode } from '../MyScript/mathGrammarCode'
import { AnswerType } from '../Problem/types.answer'
import { IS_IPAD_OR_IPHONE_DEVICE } from '../../helpers'

import useInputTypeStore, { InputType } from '../Problem/models/inputType'
import { drawBoardModel, spreadsheetModel, useDrawBoardModel } from './model'

import { useOnProblemChange } from '../assignment/hooks/useOnProblemChange'
import { SIDEBAR_AREA_WIDTH } from '../Geogebra/constants'
import type { DrawBoardAvailableFeatures } from '../ExerciseItem/types'
import { HistorySaveCredits, useDrawBoardPersist } from './model.persist'
import { useUnit } from 'effector-react'
import { $focusedInputType, FocusedInput } from '../Problem/models/initialInputFocus'
import { currentProblemAnswerModel } from '../Problem/models/answer'
import { focusAnswerInput } from '../../lib/helpers/answerInput'
import { focusSpreadsheetCell } from './helpers'
import { COLORS } from '@magmamath/ui'
import UploadPhoto from '../UploadPhoto'
import { getCalculatorPallet } from '../Calculators/calculatorsMap'
import { useDrawBoardStateSave } from './hook.stateSaving'
import { sideBarModel } from '../Problem/Sidebar/model'

type DrawBoardProps = {
  problemCredits?: HistorySaveCredits | null
  hideToolbar?: boolean
  imgSrc?: string
  controller?: MutableRefObject<BoardController | null>
  onDrawboardClear?: (event: ClearResult) => void
  answerType: AnswerType
  withGrid?: boolean
  disabled?: boolean
  initialMode?: DrawBoardMode
  characterType?: MathGrammarCode
  isCheatingDetectionEnabled?: boolean
  toolBarActiveColor?: string
  tools?: DrawBoardAvailableFeatures
  variant?: ToolBarVariants
}

const setIsCellActive = (cell: unknown) => spreadsheetModel.setIsCellActive(!!cell)

export default function DrawBoard({
  problemCredits,
  withGrid,
  hideToolbar,
  imgSrc,
  controller,
  onDrawboardClear,
  answerType,
  disabled,
  initialMode = DrawBoardMode.COMMON,
  isCheatingDetectionEnabled,
  characterType = MathGrammarCode.DEFAULT,
  toolBarActiveColor = COLORS.PRIMARY_BLUE,
  tools,
  variant = ToolBarVariants.COMMON,
}: DrawBoardProps) {
  const isSideBarOpened = useUnit(sideBarModel.$isOpened)
  const boardRef = useRef<BoardController | null>(null)
  const setInputType = useInputTypeStore((state) => state.set)
  const isSpreadsheetMode = useDrawBoardModel(({ mode }) => mode === DrawBoardMode.SPREADSHEET)
  const focusedInput = useUnit($focusedInputType)
  useDrawBoardLocalization()
  // TODO: rewrite to use flushSync for focus management
  useAutoFocusSpreadsheetCell(boardRef, focusedInput === FocusedInput.SPREADSHEET_CELL)

  const onModeChange = useCallback(
    (mode: ModeChangeProps) => {
      drawBoardModel.setMode(mode)
      if (mode.name === DrawBoardMode.SPREADSHEET) {
        mode.controller.focusCenter()
      }
    },
    [initialMode, setInputType]
  )

  useDrawBoardListeners(boardRef, {
    spreadsheetButtonPressed: () => boardRef.current?.model.spreadsheet?.controller.focusCenter(),
    clearButtonPressed: (result) => {
      onDrawboardClear?.(result)
      boardRef.current?.model.spreadsheet?.controller.focusCenter()
    },
    internalInputFocused: drawBoardModel.setInputFocused,
    spreadsheetActiveCellChanged: setIsCellActive,
    spreadsheetAnswerDetected: ({ value }) => {
      currentProblemAnswerModel.resetStatus()
      currentProblemAnswerModel.setText(value)
    },
  })

  useDrawBoardStateSave(boardRef, problemCredits?.exerciseId ?? null)

  useDrawBoardPersist(boardRef, problemCredits)

  useDrawBoardCheatingDetection(boardRef, {
    isCheatingDetectionEnabled,
  })

  useDrawBoardModules(boardRef)

  useMyScriptRecognitionModule(boardRef, {
    characterType,
    answerType,
  })

  useOnProblemChange(() => {
    const board = boardRef.current
    if (!board) return
    board.spreadsheetController?.blur()
    board.modules.calculator.model?.clear()

    // TODO: rewrite later
    if (focusedInput === FocusedInput.SPREADSHEET_CELL) {
      focusSpreadsheetCell({
        boardRef,
        timeoutMs: 100,
      })
    } else {
      focusAnswerInput({ timeoutMs: 100 })
    }
  })

  useEffect(() => {
    if (!isSpreadsheetMode) return

    const unwatchTouchStart = boardRef.current?.model.pointerActions.started.watch(() => {
      spreadsheetModel.setIsCellActive(true)
      setInputType(InputType.KEYBOARD)
    })

    return () => unwatchTouchStart?.()
  }, [isSpreadsheetMode])

  useEffect(() => {
    boardRef.current?.model.transform.isActive.set(!!disabled)
  }, [disabled])

  const CalculatorPallet = getCalculatorPallet(tools?.calculator)

  return (
    <>
      <UploadPhoto />
      <NativeDrawBoard
        fitToDrawing
        disabled={disabled}
        historyDisabled={disabled}
        initialMode={initialMode}
        ref={(ref) => {
          if (controller) controller.current = ref
          boardRef.current = ref
        }}
        hideGrid={!withGrid}
        hideToolbar={hideToolbar}
        imgSrc={imgSrc}
        initialState={{ offset: { x: isSideBarOpened ? SIDEBAR_AREA_WIDTH : 0, y: 0 } }}
        onModeChange={onModeChange}
        toolsSettings={{
          variant,
          styles: {
            activeButton: { backgroundColor: toolBarActiveColor },
            thicknessPickerActiveButton: { backgroundColor: toolBarActiveColor },
          },
          mask: {
            ...tools,
            spreadsheet: !IS_IPAD_OR_IPHONE_DEVICE,
            calculator: !!tools?.calculator,
          },
          components: { CalculatorPallet },
        }}
      />
    </>
  )
}
