import React, { useEffect, useCallback } from 'react'
import './styles.scss'
import { useUnit } from 'effector-react'
import clsx from 'clsx'

import ProblemSelectorContent from './ProblemSelectorContent'
import { sideBarModel } from '../../features/Problem/Sidebar/model'
import { $assignmentPopup, AssignmentPopup } from '../../features/assignmentPopups/model'

// FIXME: rewrite this component
// TODO: move to /components in /sidebar
const ProblemSelector = () => {
  const assignmentPopup = useUnit($assignmentPopup)
  const isProblemsListOpen = useUnit(sideBarModel.$isProblemsListOpened)

  // TODO: get rid of it later
  const manageProblemSelector = useCallback(
    (e) => {
      if (assignmentPopup === AssignmentPopup.FINISHED) {
        return
      }

      const problemNumberSelectorIcon = document.getElementById('problem-number-selector')
      const mobileProblemSelectorIcon = document.getElementsByClassName('problem-selector')[0]
      const [previousProblemButton, nextProblemButton] = document.getElementsByClassName('problem-header-button')
      const submitButton = document.getElementsByClassName('submit-button')[0]
      const submitPrefilledButton = document.getElementsByClassName('prefilled-answer-button')[0]
      if (!problemNumberSelectorIcon || !mobileProblemSelectorIcon) {
        return
      }

      const target = e.target
      if (problemNumberSelectorIcon.contains(target) || mobileProblemSelectorIcon.contains(target)) {
        sideBarModel.toggleProblemsList()
        return
      }

      const selectorWindow = document.getElementsByClassName('problem-selector-window')[0]
      if (!selectorWindow) {
        return
      }

      if (
        [selectorWindow, previousProblemButton, nextProblemButton, submitButton, submitPrefilledButton].some(
          (element) => element?.contains(target)
        )
      ) {
        return
      }

      sideBarModel.setIsProblemsListOpened(false)
    },
    [assignmentPopup]
  )

  useEffect(() => {
    document.addEventListener('click', manageProblemSelector)
    document.addEventListener('keyup', sideBarModel.closeProblemsList)

    return () => {
      document.removeEventListener('click', manageProblemSelector)
      document.removeEventListener('keyup', sideBarModel.closeProblemsList)
    }
  }, [manageProblemSelector])

  useEffect(() => {
    return sideBarModel.closeProblemsList
  }, [])

  return (
    <div className={clsx('problem-selector-window', { show: isProblemsListOpen })}>
      <ProblemSelectorContent />
    </div>
  )
}

export default ProblemSelector
