import { DEFAULT_LOCALE } from '../../constants/defaults'
import { getGrammarName, MathGrammarCode } from './mathGrammarCode'
import { ExportType, GetMyScriptImageSettings, MyScriptExportResult } from './types'
import { COLORS } from '@magmamath/ui'
import { createFormDataFromBase64 } from '../../components/Problem/helpers.images'
import { MyScriptImageExportFormat } from '../Problem/helpers'
import { Editor, EventType, PartialDeep, TBehaviorOptions } from 'iink-ts'

type GetSolverSettingsProps = {
  language: string
}

export function getSolverSettings({ language }: GetSolverSettingsProps) {
  if (language === DEFAULT_LOCALE) {
    return {
      'decimal-separator': ',',
    }
  }
  return {
    enable: false,
  }
}

type MyScriptSetupSettingsProps = {
  grammarCode: MathGrammarCode
  language: string
}

export function getMyscriptSetUpSettings({
  grammarCode,
  language,
}: MyScriptSetupSettingsProps): PartialDeep<TBehaviorOptions> {
  return {
    configuration: {
      server: {
        scheme: 'https',
        protocol: 'WEBSOCKET',
        host: window._env_.MY_SCRIPT_HOST,
        applicationKey: window._env_.MY_SCRIPT_APPLICATION_KEY,
        hmacKey: window._env_.MY_SCRIPT_HMAC_KEY,
        version: '2.2.0',
        websocket: {
          pingEnabled: true,
          autoReconnect: true,
        },
      },

      recognition: {
        type: 'MATH',
        alwaysConnected: true,
        math: {
          mimeTypes: [ExportType.JIIX, ExportType.LATEXT],
          customGrammarId: getGrammarName(grammarCode),
          solver: getSolverSettings({ language }),
        },
        export: {
          jiix: {
            strokes: true,
          },
        },
      },
    },
  }
}

export function cleanLatex(latexExport: string | number) {
  if (typeof latexExport === 'number') {
    latexExport = latexExport.toString()
  }
  if (latexExport.includes('\\\\')) {
    return latexExport.replace(/\\begin{aligned}/g, '').replace(/\\end{aligned}/g, '')
  }
  return latexExport
}

export const isRecognitionFailed = (jiixString: string) => {
  return jiixString.includes('FailureToObtainAValidResult')
}

export const applyStylesToEditor = (editor: Editor) => {
  editor.theme = { '.handwritingPencilThick': { '-myscript-pen-width': 2, color: COLORS.NEUTRAL_9 } }
  editor.penStyleClasses = 'handwritingPencilThick'
}

export async function getMyScriptImage(
  editor: Editor,
  { format = MyScriptImageExportFormat.FormData }: GetMyScriptImageSettings = {}
) {
  if (editor.context.empty) return null
  return new Promise((resolve, reject) => {
    function listener(e: MyScriptExportResult) {
      const exports = e.detail?.[ExportType.IMAGE]
      if (!exports) return resolve(null)
      const image = `data:image/png;base64,${exports}`
      editor.events.removeEventListener(EventType.EXPORTED, listener as any)
      resolve(format === MyScriptImageExportFormat.Base64 ? image : createFormDataFromBase64(image))
    }

    editor.events.addEventListener('exported', listener as any)
    editor.export([ExportType.IMAGE]).catch(reject)
  })
}
