import React, { ComponentType } from 'react'
import { useUnit } from 'effector-react'

import { $assignmentPopup, AssignmentPopup } from './model'

import { ExamPausedPopup } from './components/ExamPausedPopup/ExamPausedPopup'
import { ExamPausedByTeacherPopup } from './components/ExamPausedByTeacherPopup/ExamPausedByTeacher'
import { AssignmentFinishedPopup } from './components/AssignmentFinishedPopup/AssignmentFinishedPopup'
import { CheatingDetectedPopup } from '../CheatDetection/CheatingDetectedPopup'

const ASSIGNMENT_POPUPS_MAP: Record<AssignmentPopup, ComponentType> = {
  [AssignmentPopup.PAUSED]: ExamPausedPopup,
  [AssignmentPopup.PAUSED_BY_TEACHER]: ExamPausedByTeacherPopup,
  [AssignmentPopup.FINISHED]: AssignmentFinishedPopup,
  [AssignmentPopup.CHEAT_DETECTED]: CheatingDetectedPopup,
}

export const AssignmentPopups = () => {
  const assignmentPopup = useUnit($assignmentPopup)

  const PopupVariant = assignmentPopup && ASSIGNMENT_POPUPS_MAP[assignmentPopup]
  if (!PopupVariant) return null

  return <PopupVariant />
}
