import React, { PropsWithChildren, useContext } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

import { SidebarNavigation } from './components/SidebarNavigation'
import type { Problem, ProblemBase, SubProblem } from '../types'
import { useAssignmentsProgress } from '../models/progress'
import { IconButton, IconButtonProps } from '../../../ui/IconButton'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'
import { useTextToSpeechStore } from '../../TextToSpeech/model'
import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../constants/defaults'
import { getUserType } from '../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../constants/userTypes'
import TheoryWindow from '../../../components/TheoryWindow'
import { useGetTheory } from './hooks/useGetTheory'
import TextToSpeech from '../../TextToSpeech/TextToSpeech'
import { removeKatexPartFromTextToSpeech } from '../../../helpers'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { GlobeIcon } from '../../../ui/icons/Globe'
import { ProgressBar, ProgressBarProps } from '../../../ui/ProgressBar'
import { ExerciseMode, useCurrentExerciseMode } from '../models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../problem.style.mode.helpers'
import { COLORS } from '@magmamath/ui'
import { HelpQueue } from '../../helpQueue/HelpQueue'
import { sideBarModel } from './model'
import { useStoreMap, useUnit } from 'effector-react'
import { assignmentModel } from '../../assignment/model/assignment.model'
import { getDescription } from '../helpers'

type SidebarProps = {
  currentExerciseId: string
  currentProblemObject: Problem & { translatedText?: string; translatedTextToSpeech?: string }
  currentSubProblem: SubProblem & { translatedText?: string; translatedTextToSpeech?: string }
  onForwardButtonPress: () => void
  onBackButtonPress: () => void
  assignmentName: string
  onTranslateButtonPress: () => void
  activeProblemName: string
  isExamFinished: boolean
  isCheatingDetectionEnabled: boolean
  isGeogebraAnswerType: boolean
}

export const Sidebar = ({
  currentExerciseId,
  currentProblemObject,
  currentSubProblem,
  onForwardButtonPress,
  onBackButtonPress,
  assignmentName = '',
  onTranslateButtonPress,
  activeProblemName,
  isExamFinished,
  isCheatingDetectionEnabled,
  isGeogebraAnswerType,
  children,
}: PropsWithChildren<SidebarProps>) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const { problemTheory } = useGetTheory(currentProblemObject?._id)
  const classroomId = useStoreMap(assignmentModel.$assignment, (assignment) => assignment?.classes?.[0]?._id)

  const isSideBarOpened = useUnit(sideBarModel.$isOpened)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)
  const isTranslationEnabled =
    userSettings.translationLanguage !== NO_LANGUAGE_SELECTED &&
    userSettings.translationLanguage !== DEPRECATED_NONE_LANGUAGE &&
    getUserType() !== TEACHER
  const assignmentProgress = useAssignmentsProgress((store) => store.assignmentsProgressById[currentExerciseId])
  const { exerciseMode } = useCurrentExerciseMode()
  const isExamMode = exerciseMode === ExerciseMode.EXAM

  const textToSpeechText = () => {
    const addDotIfNotEmpty = (text: string) => (text ? `${text}.` : text)

    const problemDescription = getDescription(currentProblemObject)
    const subProblemDescription = getDescription(currentSubProblem)

    if (isTranslated) {
      return `${addDotIfNotEmpty(currentProblemObject?.translatedTextToSpeech || '') || ''} ${
        currentSubProblem?.translatedTextToSpeech || ''
      }`.trim()
    }

    if (subProblemDescription) {
      const mainTextWithDot = addDotIfNotEmpty(removeKatexPartFromTextToSpeech(problemDescription)) || ''
      return `${mainTextWithDot} ${removeKatexPartFromTextToSpeech(subProblemDescription) || ''}`.trim()
    }

    return removeKatexPartFromTextToSpeech(problemDescription).trim()
  }

  return (
    <div className={clsx(styles.Sidebar, { [styles.Show]: isSideBarOpened })}>
      <SidebarNavigation
        isExamFinished={isExamFinished}
        isCheatingDetectionEnabled={isCheatingDetectionEnabled}
        activeProblemName={activeProblemName}
        onForwardButtonPress={onForwardButtonPress}
        onBackButtonPress={onBackButtonPress}
      />

      <h6 className={styles.AssignmentName}>{assignmentName}</h6>
      <ProgressBar
        progress={assignmentProgress?.progress}
        variant={ExerciseThemeVariantMapper[exerciseMode] as ProgressBarProps['variant']}
      />

      <div className={styles.ActionsList}>
        <HelpQueue classroomId={classroomId} />

        {isTranslationEnabled && (
          <IconButton
            className={styles.ModifierButton}
            onClick={onTranslateButtonPress}
            isActive={isTranslated}
            rounded='full'
            variant='gray'
            activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']}
            withShadow
          >
            {isTranslated ? <GlobeIcon fill={COLORS.NEUTRAL_1} stroke={COLORS.NEUTRAL_1} /> : <GlobeIcon />}
          </IconButton>
        )}

        {userSettings.textToSpeech && <TextToSpeech className={styles.TextToSpeechButton} value={textToSpeechText()} />}
        {!!problemTheory.length && !isExamMode && <TheoryWindow data={problemTheory} />}
      </div>

      {children}

      <div
        className={clsx({
          [styles.SlideOutButton]: isSideBarOpened,
          [styles.HideSidebar]: !isGeogebraAnswerType,
        })}
      >
        <IconButton
          variant='outlined'
          onClick={sideBarModel.toggle as () => void}
          className={styles.FullSizeButton}
          size='sm'
        >
          {<AiOutlineDoubleRight size={24} />}
        </IconButton>
      </div>
    </div>
  )
}
