import React, { useState } from 'react'
import styles from './styles.module.scss'

import { IconButton } from '../../../../../ui/IconButton'
import { navigateToAssignmentsList } from '../../../../../helpers/exercises'
import { sideBarModel } from '../../model'
import EndExamPopup from '../../../../../components/Problem/EndExamPopup'
import { FullscreenModeButton } from '../../../../Fullscreen'
import { ArrowLeftIcon } from '../../../../../ui/icons/ArrowLeft'
import { ChevronLeftIcon } from '../../../../../ui/icons/ChevronLeft'
import { ChevronRightIcon } from '../../../../../ui/icons/ChevronRight'
import { useCurrentExerciseMode } from '../../../models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../../../problem.style.mode.helpers'
import { IconButtonProps } from '../../../../../ui/IconButton'
import { cheatDetected } from '../../../../CheatDetection/models/cheatDetected'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../assignment/model/assignment.model'

import { CheatType } from '../../../../../api/types.cheats'

type SidebarNavigationProps = {
  onForwardButtonPress: () => void
  onBackButtonPress: () => void
  activeProblemName: string
  isExamFinished: boolean
  isCheatingDetectionEnabled: boolean
}

export const SidebarNavigation = ({
  onForwardButtonPress,
  onBackButtonPress,
  activeProblemName,
  isExamFinished,
  isCheatingDetectionEnabled,
}: SidebarNavigationProps) => {
  const [isExitPopupOpen, setIsExitPopupOpen] = useState(false)
  const { exerciseMode } = useCurrentExerciseMode()
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  const exitAssignment = () => {
    if (isCheatingDetectionEnabled) {
      return setIsExitPopupOpen(true)
    }

    navigateToAssignmentsList()

    if (!isExamFinished) {
      cheatDetected(CheatType.LEAVING_BUTTON)
    }
  }

  return (
    <div className={styles.SidebarNav}>
      <EndExamPopup
        isOpen={isExitPopupOpen}
        onExit={() => setIsExitPopupOpen(false)}
        onBackToExercises={() => {
          navigateToAssignmentsList()

          if (!isExamFinished) {
            cheatDetected(CheatType.LEAVING_BUTTON)
          }
        }}
      />

      <IconButton variant='outlined' onClick={exitAssignment}>
        <ArrowLeftIcon />
      </IconButton>

      <IconButton
        className={styles.NavButton}
        onClick={onBackButtonPress}
        variant={ExerciseThemeVariantMapper[exerciseMode] as IconButtonProps['variant']}
        rounded='sl'
        disabled={isAnswerPending}
      >
        <ChevronLeftIcon size={13} fill='#ffffff' />
      </IconButton>

      {/* TODO: remove id later */}
      <IconButton
        id='problem-number-selector'
        className={styles.NavButton}
        onClick={sideBarModel.toggleProblemsList as () => void}
        variant={ExerciseThemeVariantMapper[exerciseMode] as IconButtonProps['variant']}
        rounded='sl'
        disabled={isAnswerPending}
      >
        <span>{activeProblemName}</span>
      </IconButton>

      <IconButton
        className={styles.NavButton}
        onClick={onForwardButtonPress}
        variant={ExerciseThemeVariantMapper[exerciseMode] as IconButtonProps['variant']}
        rounded='sl'
        disabled={isAnswerPending}
      >
        <ChevronRightIcon size={12} fill='#ffffff' />
      </IconButton>

      <FullscreenModeButton className={styles.FullscreenSidebarIcon} />
    </div>
  )
}
