import React from 'react'
import './styles.scss'
import { CalculatorPalletProps } from '@magmamath/react-native-draw-board'
import { GRAPHING_CALCULATOR_BASE64 } from '../../constants'
import GeogebraCalculator from '../GeogebraCalculator'

const GraphingCalculator = React.memo((props: CalculatorPalletProps) => {
  return (
    <GeogebraCalculator
      {...props}
      initialValue={GRAPHING_CALCULATOR_BASE64}
      perspective={'AG'}
      size={{ width: 450, height: 395 }}
      className={'graphing-calculator'}
      id={'graphing-calculator'}
    />
  )
})

export default GraphingCalculator
