export const setCaret = (element: any, startElement: any, offset: any) => {
  const range = document.createRange()
  const sel = window.getSelection()
  range.setStart(startElement, offset)
  range.collapse(true)
  sel?.removeAllRanges()
  sel?.addRange(range)
  setTimeout(() => element.focus(), 0)
}
export const setCursorPosition = (elementSelector: string) => {
  setTimeout(() => {
    const selectedElements = document.getElementsByClassName(elementSelector)
    if (!selectedElements) {
      return
    }

    const element = selectedElements[selectedElements.length - 1]
    if (!element) {
      return
    }

    setCaret(element, element, 0)
  })
}
