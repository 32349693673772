import { TOOL_BAR_CONSTANTS } from '@magmamath/react-native-draw-board'

const TOOL_BAR_BUTTON_SIZE_OFFSET_MAP = new Map<number, number>([
  [TOOL_BAR_CONSTANTS.BUTTON_SIZE.M, -50],
  [TOOL_BAR_CONSTANTS.BUTTON_SIZE.L, 0],
])

/*
A bit hacky way to determine simple mode in drawboard and chose right offset
so when button size is M it means it is default mode, and if size is L - mode is simple
 */
export const buttonSizeToOffset = (buttonSize: number) => {
  return TOOL_BAR_BUTTON_SIZE_OFFSET_MAP.get(buttonSize) ?? 0
}
