import React from 'react'
import styles from './helpQueueModal.module.scss'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import { Backdrop } from '../../../../components/Backdrop'
import { useUnit } from 'effector-react'
import { $helpQueue } from '../../model/model'
import { ExerciseMode, useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { useAssignmentCredentials } from '../../../assignment/hooks/useAssignmentCredentials'
import { addToHelpQueueFx, removeFromHelpQueueFx } from '../../model/effects'
import debounce from 'lodash/debounce'

type HelpQueueModalProps = {
  onClickBackdrop: () => void
}

export const HelpQueueModal = ({ onClickBackdrop }: HelpQueueModalProps) => {
  const { t } = useTranslation()
  const credits = useAssignmentCredentials()
  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const helpQueue = useUnit($helpQueue)
  const userQueueId = helpQueue?.self?._id

  const handleQueueJoining = () => {
    if (userQueueId) {
      removeFromHelpQueueFx(userQueueId)
      return
    }

    addToHelpQueueFx({
      exerciseId: credits.id,
      problemId: credits.problemId,
    })
  }

  // workaround for "tap to click" on MacBook trackpad - without debounce it sometimes duplicates this POST request
  const debouncedQueueJoin = debounce(handleQueueJoining, 100)

  const queuePositionMessage = () => {
    if (!userQueueId) return t('helpQueue.peopleInQueueCount', { count: helpQueue?.position ?? 0 })

    if (helpQueue?.position === 0) return t('helpQueue.firstInQueue')
    if (helpQueue?.position === 1) return t('helpQueue.aheadOfYouSingle')

    if (helpQueue?.position && helpQueue?.position > 1) {
      return t('helpQueue.aheadOfYouPlural', { count: helpQueue?.position })
    }

    return null
  }

  return (
    <Backdrop onClick={onClickBackdrop}>
      <div className={styles.HelpQueueModal}>
        <div className={styles.HelpQueueHeader}>
          <h4>{t('helpQueue.heading')}</h4>
          <Button
            onClick={debouncedQueueJoin}
            size={SIZE.SMALL}
            classes={{ button: styles.HelpQueueModalButton }}
            color={isExamMode ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.BLUE}
            variant={userQueueId ? BUTTON_VARIANT.SECONDARY : BUTTON_VARIANT.PRIMARY}
          >
            {userQueueId ? t('helpQueue.leaveQueue') : t('helpQueue.needHelp')}
          </Button>
        </div>

        <div className={styles.HelpQueueBody}>
          <p>{queuePositionMessage()}</p>
        </div>
      </div>
    </Backdrop>
  )
}
