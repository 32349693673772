import React from 'react'

import './styles.scss'

import { ReactComponent as OfflineModeIcon } from '../../../assets/images/offline_mode.svg'

// TODO: move outside Problem directory, features/offlineMode (move models there too)
const OfflineModeAnswerAreaIcon = () => {
  return (
    <div className='offline-mode-icon'>
      <OfflineModeIcon />
    </div>
  )
}

export default OfflineModeAnswerAreaIcon
