import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { DEFAULT_ICON_FILL } from '../constants'

export const GlobeIcon = ({ size = 20, fill = DEFAULT_ICON_FILL, stroke = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ overflow: 'visible' }}
    >
      <path
        d='M4.43889 5.06764L4.03417 2.60768C4.01268 2.47708 4.05325 2.34348 4.14637 2.24943C4.70234 1.68791 6.25636 0.300859 9.77409 0C14.6361 0 18.3102 4.55198 19.0824 7.02186C19.1593 7.26804 18.9647 7.5 18.7068 7.5H14.6566C14.4265 7.5 14.2399 7.68655 14.2399 7.91667V12.8764C14.2399 12.9032 14.2373 12.9299 14.2322 12.9561L13.8517 14.9088C13.84 14.9689 13.8152 15.0256 13.7791 15.075L10.3049 19.8292C10.2265 19.9365 10.1015 20 9.9685 20H9.28253C9.09362 20 8.92835 19.8729 8.87984 19.6903L7.79177 15.5958C7.76147 15.4817 7.68427 15.3859 7.57931 15.3321L4.72275 13.8662C4.58373 13.7949 4.49631 13.6517 4.49631 13.4955V10.2387C4.49631 10.0908 4.57466 9.95405 4.7022 9.87926L7.24058 8.39058C7.3045 8.3531 7.37726 8.33333 7.45136 8.33333H10.9814C11.2115 8.33333 11.398 8.14678 11.398 7.91667V4.58333C11.398 4.35321 11.2115 4.16667 10.9814 4.16667H8.78234C8.64127 4.16667 8.50977 4.23805 8.43292 4.35636L7.8674 5.22697C7.79055 5.34528 7.65905 5.41667 7.51798 5.41667H4.85003C4.64602 5.41667 4.47201 5.26895 4.43889 5.06764Z'
        fill={fill}
      />
      <circle cx='10' cy='10' r='9.336' stroke={stroke} strokeWidth='1.328' overflow='visible' />
    </svg>
  )
}
