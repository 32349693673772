import { createEvent, createStore, restore, sample } from 'effector'
import { joinNewClassFx } from './effect'

export const joinClassInitialState = {
  digit1: '',
  digit2: '',
  digit3: '',
  digit4: '',
  digit5: '',
  digit6: '',
}

type UpdateJoinClassField = {
  name: string
  value: string
}

export type JoinClassInputBoxKeys = keyof typeof joinClassInitialState

export const setIsJoinClassOpen = createEvent<boolean>()
export const $isJoinClassOpen = restore(setIsJoinClassOpen, false)

export const updateJoinClassField = createEvent<UpdateJoinClassField>()
export const setFormJoinClass = createEvent<typeof joinClassInitialState>()
export const $formJoinClass = restore(setFormJoinClass, joinClassInitialState)

$formJoinClass.on(updateJoinClassField, (store, { name, value }) => ({
  ...store,
  [name]: value,
}))

export const $isJoinClassFormFilled = $formJoinClass.map((inputValues) =>
  Object.values(inputValues).every((value) => value.trim() !== '')
)

export const joinClassSubmitted = createEvent()

sample({
  clock: joinClassSubmitted,
  source: $formJoinClass,
  target: joinNewClassFx,
  fn: (form) => Object.values(form).join(''),
})
