import { translateText, writeDrawBoardLogg } from '../../helpers'
import { InputType } from './models/inputType'
import { createFormDataFromBase64 } from '../../components/Problem/helpers.images'
import { BoardController } from '@magmamath/react-native-draw-board'
import type { ProblemBase } from './types'

export enum MyScriptImageExportFormat {
  Base64 = 'base64',
  FormData = 'formData',
}

export const translateCurrentTask = async ({
  isTranslated,
  setIsTranslated,
  problem,
  subProblems,
  translationLanguage,
}: {
  isTranslated: boolean
  setIsTranslated: (isTranslated: boolean) => void
  problem: any
  subProblems: any
  translationLanguage: string
}) => {
  if (isTranslated) {
    setIsTranslated(false)
    return
  }

  problem && !problem?.translatedText && (await translateText(problem, translationLanguage))
  if (!subProblems?.length) {
    setIsTranslated(true)
    return
  }

  const promises: any = []
  subProblems.forEach((subProblem: any) => {
    !subProblem.translatedText && promises.push(translateText(subProblem, translationLanguage))
  })
  await Promise.all(promises)

  setIsTranslated(true)
}

export const inputTypeMyScript = (inputType: InputType) => {
  return inputType === InputType.MY_SCRIPT
}

export const inputTypeKeyboard = (inputType: InputType) => {
  return inputType === InputType.KEYBOARD
}

export const getDrawnImage = async (drawBoard: BoardController | null) => {
  if (!drawBoard) return ''
  try {
    const image = await drawBoard.getImage()
    if (!image) return null
    return createFormDataFromBase64(image)
  } catch (e) {
    writeDrawBoardLogg(e)
    return null
  }
}

export const isExpressionContainsOnlyMathCharacters = (expression: string) => {
  const regex = /^[\s$\/.0-9+-=*]*$/g
  return regex.test(expression)
}

export const getDescription = (object: ProblemBase) => {
  return object?.richDescription || object?.laTeXDescription || object?.description
}
