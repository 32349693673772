import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'

type ActiveSubCategoryModel = {
  id: string
}

export const useActiveSubCategoryModel = create<ActiveSubCategoryModel>(() => ({
  id: '',
}))

export const activeSubCategoryModel = {
  setId: addEvent(useActiveSubCategoryModel, (id: string) => ({
    id,
  })),
}
