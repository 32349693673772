import React, { useContext, useEffect, useState, useCallback } from 'react'

import {
  isMistakeAnswerStatus,
  isExamModeAnswerStatus,
  transformPreviousSelectedTypeAnswer,
} from '../../../../helpers/exercises/exerciseAnswerHelper'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { currentProblemAnswerModel } from '../../../../features/Problem/models/answer'
import { useChoiceAnswersModel } from '../model'
import { AnswerChoice } from '../AnswerChoice/AnswerChoice'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../features/Problem/models/exerciseMode'
import { getUserType } from '../../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../../constants/userTypes'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../features/assignment/model/assignment.model'

const ProblemAnswerOrderedChoice = (props) => {
  const {
    answerResultStatus,
    examMode,
    isExamFinished,
    problemData,
    practiceMode,
    showRequireDrawingMessage,
    examStopped,
  } = useContext(AnswerInfoContext)

  const selectedOrderedAnswers = useChoiceAnswersModel((state) => state.selectedOrderedAnswers)
  const setSelectedOrderedAnswers = useChoiceAnswersModel((state) => state.setSelectedOrderedAnswers)
  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  const [answerVariants, setAnswerVariants] = useState([])

  useEffect(() => {
    if (!problemData) {
      return
    }

    setAnswerVariants(transformPreviousSelectedTypeAnswer(problemData.answerVariants))
  }, [problemData])

  useEffect(() => {
    if (!props.previousAnswer) {
      setSelectedOrderedAnswers(new Map())
      return
    }

    const selected = new Map()
    props.previousAnswer.forEach((answer) => {
      const index = answerVariants.findIndex((answerVariant) => answerVariant.variant === answer)

      if (index !== -1) {
        selected.set(index, answer)
      }
    })

    setSelectedOrderedAnswers(new Map(selected))
  }, [props.previousAnswer, answerVariants])

  const submitAnswer = async () => {
    if (
      (examMode && (isExamModeAnswerStatus(answerResultStatus) || isExamFinished())) ||
      (answerResultStatus && practiceMode)
    ) {
      props.onNextButtonPress()
      return
    }

    if (isMistakeAnswerStatus(answerResultStatus)) {
      currentProblemAnswerModel.resetStatus()
      setSelectedOrderedAnswers(new Map())
      return
    }

    if (props.isSolved) {
      props.onNextButtonPress()
      return
    }

    if (!selectedOrderedAnswers.size) {
      return
    }

    const answers = []
    for (let key of selectedOrderedAnswers.values()) {
      answers.push(key)
    }

    await props.onSubmitClick(answers)
  }

  const selectAnswerVariant = (answer) => {
    const stateCopy = new Map(selectedOrderedAnswers)
    currentProblemAnswerModel.resetStatus()

    const value = stateCopy.get(answer.id)
    if (!value) {
      stateCopy.set(answer.id, answer.variant)
      setSelectedOrderedAnswers(new Map(stateCopy))
      return
    }

    const order = getSelectedItemOrder(answer.variant)
    if (order <= stateCopy.size) {
      for (let key of stateCopy.keys()) {
        if (getSelectedItemOrder(stateCopy.get(key)) > order - 1) {
          stateCopy.delete(key)
        }
      }
    }

    setSelectedOrderedAnswers(new Map(stateCopy))
  }

  const checkIsElementSelected = (element) => {
    return !!selectedOrderedAnswers.get(element.id)
  }

  const getSelectedItemOrder = (answerVariant) => {
    let counter = 1
    for (let value of selectedOrderedAnswers.values()) {
      if (value === answerVariant) {
        break
      }
      counter++
    }

    return counter
  }

  const disableSubmitButton = useCallback(() => {
    return (
      !selectedOrderedAnswers.size ||
      !answerVariants.length ||
      selectedOrderedAnswers.size !== answerVariants.length ||
      (isAnswerPending && !(isExamMode && isExamFinished()))
    )
  }, [answerVariants.length, isExamFinished, isExamMode, isAnswerPending, selectedOrderedAnswers.size])

  const isDrawingRequired = showRequireDrawingMessage && !selectedOrderedAnswers.size

  const isOptionDisabled =
    (isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))) ||
    props.isSolved ||
    isAnswerPending ||
    isDrawingRequired

  return (
    <AnswerChoice
      answerOptions={answerVariants}
      submitAnswer={submitAnswer}
      isOptionDisabled={!!isOptionDisabled}
      isSubmitDisabled={disableSubmitButton()}
      isDrawingRequired={isDrawingRequired}
      showAnswerPreview={props.allowAnswerLooking}
      handleSelectAnswer={selectAnswerVariant}
      checkIsOptionSelected={checkIsElementSelected}
      getSelectedItemOrder={getSelectedItemOrder}
      showNumberIcons
    />
  )
}

export default ProblemAnswerOrderedChoice
