import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { COLORS } from '@magmamath/ui'

const StyledSlider = withStyles({
  root: {
    padding: '14px 0',
    height: 8,
    color: COLORS.NEUTRAL_4,
  },
  mark: {
    marginTop: 2,
    width: 6,
    height: 6,
    borderRadius: '50%',
    background: COLORS.NEUTRAL_6,
    '&:nth-of-type(3)': { background: 'transparent' },
    '&:nth-of-type(7)': { background: 'transparent' },
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: COLORS.NEUTRAL_1,
    marginTop: -3,
    marginLeft: -7,
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.15)',
    '&:focus, &:hover, &:active': {
      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.15)',
    },

    '& .MuiSlider-valueLabel': {
      color: COLORS.NEUTRAL_10,
    },
  },
  valueLabel: {
    left: 'calc(-50% + 1px)',
  },
  track: {
    height: 8,
    borderRadius: 12,
    boxShadow: '0px 0px 2px rgb(0 0 0 / 10%), inset 4px 2px 2px rgb(0 0 0 / 15%)',
  },
  rail: {
    height: 9,
    borderRadius: 12,
    opacity: 1,
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.1), inset 4px 2px 2px rgba(0, 0, 0, 0.15)',
  },
})(Slider)

export default StyledSlider
