import React, { useContext, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_COLOR, COLORS, SIZE } from '@magmamath/ui'

import styles from './styles.module.scss'

import { removeKatexPartFromTextToSpeech } from '../../../helpers'
import TextToSpeech from '../../TextToSpeech/TextToSpeech'

import useOutsideClick from '../../../lib/hooks/useOutsideClick'
import { translateCurrentTask, isExpressionContainsOnlyMathCharacters } from '../../Problem/helpers'
import { useTextToSpeechStore } from '../../TextToSpeech/model'
import { usePracticeTheory } from '../models/practiceTheory'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'
import Markdown from '../../../components/katexMarkdown/Markdown'
import { LanguageTag, nativeLanguageTagMap } from '../../../config/allLanguages'
import { useAutoReplayDrawBoard } from '../hooks/useAutoPlayDrawBoard'
import TheoryDrawBoard from './TheoryDrawBoard/TheoryDrawBoard'
import { theoryModel, useTheoryModel } from '../models/theory'
import { IconButton } from '../../../ui/IconButton'
import { ExerciseThemeVariantMapper } from '../../Problem/problem.style.mode.helpers'
import { useCurrentExerciseMode } from '../../Problem/models/exerciseMode'
import { GlobeIcon } from '../../../ui/icons/Globe'
import { IconButtonProps } from '../../../ui/IconButton'
import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../constants/defaults'

const PracticeTheory = () => {
  const { t } = useTranslation()
  const { isTranslated, setIsTranslated } = useTextToSpeechStore((state) => state)
  const { theory, drawingHistory } = usePracticeTheory()
  const { state: userSettings } = useContext(UserSettingsContext)

  const richDescription = theory?.problem?.richDescription
  const translatedDescription = theory?.problem?.translatedText
  const description = isTranslated ? translatedDescription || richDescription : richDescription
  const { isShowTheory } = useTheoryModel()
  const preview = useAutoReplayDrawBoard({ isVisible: isShowTheory })
  const theoryDescriptionRef = useRef<HTMLDivElement>(null)
  const { exerciseMode } = useCurrentExerciseMode()

  const [largeTheoryMode, setLargeTheoryMode] = useState(false)

  useEffect(() => {
    const theoryDescription = theoryDescriptionRef.current
    if (!theoryDescription) return

    const isOverflow = theoryDescription.scrollHeight > theoryDescription.clientHeight
    setLargeTheoryMode(isOverflow)
  }, [theoryDescriptionRef])

  useEffect(() => {
    const preventKeypress = (e: KeyboardEvent) => {
      e.preventDefault()
    }

    if (isShowTheory) {
      document.addEventListener('keydown', preventKeypress)
    } else {
      document.removeEventListener('keydown', preventKeypress)
    }

    return () => {
      document.removeEventListener('keydown', preventKeypress)
    }
  }, [isShowTheory])

  const onCloseTheory = () => {
    theoryModel.setIsShowTheory(false)
  }

  const ref = useOutsideClick(onCloseTheory)

  useEffect(() => {
    theoryModel.setIsShowTheory(true)
  }, [])

  useEffect(() => {
    if (!drawingHistory?.history || !isShowTheory) return

    preview.setDrawing(drawingHistory.history)
  }, [drawingHistory, isShowTheory])

  const onTranslateButtonPress = async () => {
    await translateCurrentTask({
      isTranslated,
      setIsTranslated,
      problem: theory?.problem,
      subProblems: null,
      translationLanguage: userSettings.translationLanguage,
    })
  }

  const isGoogleTranslationEnabled =
    userSettings.translationLanguage !== DEPRECATED_NONE_LANGUAGE &&
    userSettings.translationLanguage !== NO_LANGUAGE_SELECTED &&
    nativeLanguageTagMap[userSettings.translationLanguage as LanguageTag]

  const isShowTextTooSpeech = userSettings.textToSpeech && (!isTranslated || isGoogleTranslationEnabled)

  return (
    <>
      <div className={styles.PracticeTheoryWrapper}>
        <div
          className={clsx(styles.PracticeTheoryContainer, {
            [styles.Visible]: isShowTheory,
            [styles.LargeMode]: largeTheoryMode,
          })}
          ref={ref}
        >
          <div className={clsx(styles.TheoryContent, { [styles.LargeMode]: largeTheoryMode })}>
            <div className={styles.TheoryHeader}>
              <div className={styles.EmptyBlock} />
              <div className={styles.TheoryTitle} ref={theoryDescriptionRef}>
                <Markdown>{description}</Markdown>
              </div>
              <div className={styles.ControlButtons}>
                {isShowTextTooSpeech && (
                  <div className={styles.ControlButton}>
                    <TextToSpeech
                      value={removeKatexPartFromTextToSpeech(description || '')}
                      className={clsx(styles.ControlButton)}
                      variant={'control'}
                    />
                  </div>
                )}
                {isGoogleTranslationEnabled && !isExpressionContainsOnlyMathCharacters(description || '') && (
                  <div className={styles.ControlButton}>
                    <IconButton
                      className={clsx(styles.ControlButton, { [styles.Active]: isTranslated })}
                      onClick={onTranslateButtonPress}
                      isActive={isTranslated}
                      rounded='full'
                      variant='control'
                      activeVariant={
                        `active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']
                      }
                      withShadow
                    >
                      {isTranslated ? <GlobeIcon fill={COLORS.NEUTRAL_1} stroke={COLORS.NEUTRAL_1} /> : <GlobeIcon />}
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.TheoryBody}>
              <div className={styles.TheoryDrawBoard}>
                <TheoryDrawBoard preset={preview.preset} />
              </div>
            </div>
          </div>
          <div className={styles.TheoryFooter}>
            <Button onClick={onCloseTheory} size={SIZE.LARGE} color={BUTTON_COLOR.YELLOW}>
              {t('startText')}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={clsx(styles.PracticeTheoryBackground, {
          [styles.Visible]: isShowTheory,
        })}
      ></div>
    </>
  )
}

export default PracticeTheory
