import createDataContext from './createDataContext';
import { SET_SHOW_SIDEBAR, SET_HIDE_SIDEBAR, CLOSE_SIDEBAR, CLEAR_CONTEXT, SET_HIDDEN_ON_EXERCISE_PAGE, REMOVE_HIDDEN_ON_EXERCISE_PAGE } from '../constants/actionTypes';

const initialState = {
    show: false,
    hide: false,
    hiddenOnExercisePage: false
};

const sidebarReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SHOW_SIDEBAR:
            return { ...state, show: true, hide: false };
        case SET_HIDE_SIDEBAR:
            return { ...state, hide: true };
        case CLOSE_SIDEBAR:
            return { ...state, show: false, hide: false };
        case CLEAR_CONTEXT:
            return initialState;
        case SET_HIDDEN_ON_EXERCISE_PAGE:
            return { ...state, hiddenOnExercisePage: true };
        case REMOVE_HIDDEN_ON_EXERCISE_PAGE:
            return { ...state, hiddenOnExercisePage: false };
        default:
            return state;
    }
};

const setShowSidebar = (dispatch) => {
    return () => {
        dispatch({ type: SET_SHOW_SIDEBAR })
    };
};

const closeSidebar = (dispatch) => {
    return () => {
        dispatch({ type: SET_HIDE_SIDEBAR });
        setTimeout(() => {
            dispatch({ type: CLOSE_SIDEBAR });
        }, 200);
    };
};

const clearSidebarContext = (dispatch) => {
    return () => {
        dispatch({ type: CLEAR_CONTEXT });
    };
};

const setHiddenOnExercisePage = (dispatch) => {
    return () => {
        dispatch({ type: SET_HIDDEN_ON_EXERCISE_PAGE });
    };
};

const removeHiddenOnExercisePage = (dispatch) => {
    return () => {
        dispatch({ type: REMOVE_HIDDEN_ON_EXERCISE_PAGE });
    };
};

export const { Context, Provider } = createDataContext(
    sidebarReducer,
    { setShowSidebar, closeSidebar, clearSidebarContext, setHiddenOnExercisePage, removeHiddenOnExercisePage },
    initialState
);