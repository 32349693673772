import i18n from 'i18next'
import { badAnswerFormatMap } from '../constants'
import type { Problem, SubProblem } from '../../Problem/types'
import type { HintResponse } from '../../AnswerHints/hooks'

type Props = {
  currentTask: Problem | SubProblem
  badAnswerFormat: keyof typeof badAnswerFormatMap
  fetchHints: () => Promise<HintResponse>
}

export async function getAnswerHint({ currentTask, badAnswerFormat, fetchHints }: Props) {
  if (currentTask?.smartEvaluation && badAnswerFormat) {
    const hintText = i18n.t(badAnswerFormatMap[badAnswerFormat] || badAnswerFormatMap['default'])

    return {
      hint: hintText,
      errorType: null,
      isSmartEvalHint: true,
    }
  }

  const response = await fetchHints()
  return {
    hint: response?.hint ?? '',
    errorType: response?.errorType ?? '',
    isSmartEvalHint: false,
  }
}
