import React, { FC } from 'react'
import './styles.scss'
import { FirstRow, SecondRow, ThirdRow } from './symbols'
import Letters from './Letters'
import { NumpadVariantProps } from '../types'
import clsx from 'clsx'

const NumpadLetters: FC<NumpadVariantProps> = ({ onPress, isButtonDisabled }) => {
  return (
    <div className={clsx('numpad-letters-container', { disabled: isButtonDisabled })} aria-disabled='true'>
      <Letters letters={FirstRow} onPress={onPress} />
      <Letters letters={SecondRow} onPress={onPress} />
      <Letters letters={ThirdRow} onPress={onPress} />
    </div>
  )
}

export default NumpadLetters
