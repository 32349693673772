import { createEffect } from 'effector'
import type { SendAnswerFxProps } from './saveAnswer.types'
import { uploadSolutionImages } from '../../requests/images'
import { drawingImagesModel } from '../drawingImages/drawingImages'
import { getSolutionImage } from '../../../assignments/requests'
import { api } from '../../../../api'
import { writeLogg } from '../../../../helpers'

const sendAnswerFx = createEffect(
  async ({ solution, session, drawingBase64, answerDrawingBase64, assignmentId, problemId }: SendAnswerFxProps) => {
    let canvasImg = null
    let handwritingAnswerImg = null
    try {
      const { canvasImage, handwritingAnswerImage } = await uploadSolutionImages(drawingBase64, answerDrawingBase64)
      canvasImg = canvasImage
      handwritingAnswerImg = handwritingAnswerImage
    } catch (error) {
      console.error(error)
      writeLogg(error)
    }

    drawingImagesModel.setField({
      assignmentId,
      problemId,
      data: {
        drawboardImageURL: getSolutionImage(canvasImg),
        handwritingImageURL: getSolutionImage(handwritingAnswerImg),
      },
    })

    const [solutionResponse, sessionResponse] = await Promise.all([
      api.sendSolutionStatuses({
        ...solution,
        drawingImage: canvasImg ?? '',
        handwritingImage: handwritingAnswerImg ?? '',
      }),
      api.sendProblemSession(session),
    ])

    return { solutionResponse, sessionResponse }
  }
)

export { sendAnswerFx }
