import React from 'react'
import { Button, BUTTON_VARIANT, SIZE, Modal, COLORS, Typography } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import styles from './magmaStats.module.scss'

import { $isStatsPopupOpen, $userStats, setStatsPopupOpen } from './model'
import { TrophyIcon } from '../../ui/icons/TrophyIcon'
import { StatsRibbon } from './assets/StatsRibbon'
import { StatsCard } from './components/StatsCard/StatsCard'
import { SolvedIcon } from './assets/SolvedIcon'
import { GoodCalculationIcon } from './assets/GoodCalculationIcon'
import { CompletedAssignmentsIcon } from './assets/CompletedAssignmentsIcon'
import { PracticeStarsIcon } from './assets/PracticeStarsIcon'
import { StatsTrophyBadge } from './assets/StatsTrophyBadge'
import { useTranslation } from 'react-i18next'
import { getUserFullProfileInfo } from '../../helpers/users/getUserInfo'

export const MagmaStats = () => {
  const { t } = useTranslation()
  const isStatsPopupOpen = useUnit($isStatsPopupOpen)
  const userStats = useUnit($userStats)
  const userDetails = getUserFullProfileInfo()

  return (
    <>
      <Button
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.LARGE}
        icon={<TrophyIcon />}
        onClick={() => setStatsPopupOpen(!isStatsPopupOpen)}
        classes={{
          button: styles.MagmaStatsButton,
          content: styles.ButtonContent,
        }}
      />

      <Modal
        isOpen={isStatsPopupOpen}
        onClose={() => setStatsPopupOpen(false)}
        classes={{ Dialog: styles.MagmaStatsPopup }}
      >
        <div className={styles.StatsPopupRibbon}>
          <StatsRibbon />
        </div>

        <div className={styles.StatsCardsWrapper}>
          <div className={styles.StatsUsername}>
            <StatsTrophyBadge />
            <Typography variant='h1' noWrap className={styles.StatsUsernameText}>
              {userDetails.firstName}
            </Typography>
          </div>

          <div className={styles.StatsInsights}>
            <StatsCard
              icon={<SolvedIcon />}
              title={t('magmaStats.problemsSolved', { count: userStats.correctSolutionsCount })}
              value={userStats.correctSolutionsCount}
              color={COLORS.PRIMARY_ORANGE}
            />
            <StatsCard
              icon={<GoodCalculationIcon />}
              title={t('magmaStats.goodCalculations', { count: userStats.goodDrawingCount })}
              value={userStats.goodDrawingCount}
              color={COLORS.PRIMARY_GREEN}
            />
            <StatsCard
              icon={<CompletedAssignmentsIcon />}
              title={t('magmaStats.assignmentsCompleted', { count: userStats.assignmentCompletedCount })}
              value={userStats.assignmentCompletedCount}
              color={COLORS.PRIMARY_BLUE}
            />
            {/* TODO: will be added in the next iteration */}
            {/*<StatsCard*/}
            {/*  icon={<PracticeStarsIcon />}*/}
            {/*  title={t('magmaStats.starsInPractice', { count: userStats.starsInPracticeModeCount })}*/}
            {/*  value={userStats.starsInPracticeModeCount}*/}
            {/*  color={COLORS.PRIMARY_YELLOW}*/}
            {/*/>*/}
          </div>
        </div>
      </Modal>
    </>
  )
}
