import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'

type TheoryModel = {
  isShowTheory: boolean
}

export const useTheoryModel = create<TheoryModel>(() => ({
  isShowTheory: false,
}))

export const theoryModel = {
  setIsShowTheory: addEvent(useTheoryModel, (isShowTheory: boolean) => ({
    isShowTheory,
  })),
}
