import React from 'react'
import { getIconSize } from '../helpers'
import type { IconProps } from '../types'
import { COLORS } from '@magmamath/ui'

export const ArrowLeftIcon = ({ size = { width: 31, height: 24 }, fill = COLORS.NEUTRAL_8 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 31 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.8848 13.5C29.7132 13.5 30.3848 12.8284 30.3848 12C30.3848 11.1716 29.7132 10.5 28.8848 10.5V13.5ZM0.975515 10.9393C0.389729 11.5251 0.389729 12.4749 0.975515 13.0607L10.5215 22.6066C11.1072 23.1924 12.057 23.1924 12.6428 22.6066C13.2286 22.0208 13.2286 21.0711 12.6428 20.4853L4.1575 12L12.6428 3.51472C13.2286 2.92893 13.2286 1.97919 12.6428 1.3934C12.057 0.807611 11.1072 0.807611 10.5215 1.3934L0.975515 10.9393ZM28.8848 10.5L2.03617 10.5V13.5L28.8848 13.5V10.5Z'
        fill={fill}
      />
    </svg>
  )
}
