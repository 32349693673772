import React, { PropsWithChildren, ReactNode } from 'react'
import styles from './styles.module.scss'
import CircleLoader from '../../../ui/Loaders/CircleLoader'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type AnswerSectionProps = {
  isLoading: boolean
  isEmpty: boolean
  isHandwritingProblem: boolean
  description: ReactNode
}

export const AnswerSectionBlock = ({
  isLoading,
  isEmpty,
  isHandwritingProblem,
  children,
  description,
}: PropsWithChildren<AnswerSectionProps>) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <CircleLoader />
  }

  if (isEmpty) {
    return (
      <div className={styles.AnswerSection}>
        <div className='no-problems'>
          <span className='no-problems-message'>{t('emptyProblems')}</span>
        </div>
      </div>
    )
  }

  if (!children) return null

  return (
    <div className={styles.AnswerSectionBlock}>
      {description}
      <div className={clsx(styles.AnswerSection, { [styles.HandwritingAnswerSection]: isHandwritingProblem })}>
        {children}
      </div>
    </div>
  )
}
