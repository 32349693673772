import React, { useCallback, useEffect } from 'react'
import Confetti from 'react-confetti'
import {
  FALL_DURATION_IN_MS,
  PROBLEM_FEEDBACK_VARIANTS,
  SNOWFALL_DURATION_IN_MS,
} from '../../../constants/problemFeedback'

import './styles.scss'
import { useWindowSize } from '../../../lib/hooks/useWindowSize'
import { Snowfall } from './confetti/Snowfall'
import { FallConfetti } from './confetti/FallConfetti'

const variantDuration = {
  [PROBLEM_FEEDBACK_VARIANTS.SNOWFALL]: SNOWFALL_DURATION_IN_MS,
  [PROBLEM_FEEDBACK_VARIANTS.FALL]: FALL_DURATION_IN_MS,
}
const ProblemFeedback = ({
  problemArea,
  showConfettiAnimation,
  showWiggleButtonAnimation,
  setWiggleButtonState,
  setConfettiState,
  allowShowConfetti,
  variant,
}) => {
  const { width, height } = useWindowSize()
  const onProblemAreaClick = useCallback(() => {
    if (!showConfettiAnimation && allowShowConfetti) {
      setConfettiState(true)
      const triggerVariants = [PROBLEM_FEEDBACK_VARIANTS.SNOWFALL, PROBLEM_FEEDBACK_VARIANTS.FALL]

      if (triggerVariants.includes(variant)) {
        setTimeout(() => {
          setConfettiState(false)
        }, variantDuration[variant])
      }
    }

    if (!showWiggleButtonAnimation) {
      setWiggleButtonState(true)
      setTimeout(() => setWiggleButtonState(false), 1000)
    }
  }, [
    allowShowConfetti,
    showConfettiAnimation,
    showWiggleButtonAnimation,
    setWiggleButtonState,
    setConfettiState,
    variant,
  ])

  useEffect(() => {
    if (!problemArea) {
      return
    }

    problemArea.addEventListener('click', onProblemAreaClick)

    return () => {
      problemArea.removeEventListener('click', onProblemAreaClick)
    }
  }, [problemArea, onProblemAreaClick])

  if (!(allowShowConfetti && showConfettiAnimation)) return null

  if (variant === PROBLEM_FEEDBACK_VARIANTS.FALL) {
    return <FallConfetti />
  }

  if (variant === PROBLEM_FEEDBACK_VARIANTS.SNOWFALL) {
    return <Snowfall />
  }

  return (
    <Confetti
      recycle={false}
      numberOfPieces={350}
      gravity={0.2}
      onConfettiComplete={() => setConfettiState(false)}
      width={width}
      height={height}
      style={{
        width: '100%',
        zIndex: 10009,
      }}
    />
  )
}

export default ProblemFeedback
