import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const StarRoundedFilledIcon: React.FC<IconProps> = ({ size = 25, fill = COLORS.PRIMARY_YELLOW }) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.215 1.35083C13.4382 0.0561541 11.5619 0.0561504 10.7851 1.35083L7.53568 6.76649L1.97098 8.15766C0.462807 8.53471 -0.0574265 10.4129 1.04184 11.5122L5.16991 15.6402L3.80115 22.1418C3.45189 23.8008 5.1957 25.1147 6.69403 24.3214L12.4886 21.2537L16.3534 23.4009L18.3604 24.4044C19.8574 25.1529 21.5567 23.8413 21.2119 22.2035L19.8302 15.6402L23.9583 11.5122C25.0575 10.4129 24.5373 8.53471 23.0291 8.15766L17.4644 6.76649L14.215 1.35083Z'
        fill={fill}
      />
    </svg>
  )
}

export default StarRoundedFilledIcon
