import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export const Backdrop = ({
  children,
  className,
  ...restProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className={clsx(styles.Backdrop, className)} {...restProps}>
      <div className={styles.BackdropChildren} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}
