import React, { useContext } from 'react'
import styles from './styles.module.scss'
import { NumpadButton } from '../NumpadButton'
import { CharTypeNumpadContext } from '../../'
import { NUMERIC } from '../../constants/symbols'

export type NumericKeypadProps = {
  hideSeparatorButton?: boolean
  hideFractionButton?: boolean
}

export const NumericKeypad = (props: NumericKeypadProps) => {
  const { onClick, onSpecialSymbolPress } = useContext(CharTypeNumpadContext)
  return (
    <div className={styles.NumbersWhole}>
      {NUMERIC(props).map(({ value, symbol, isHidden, isSpecialSymbol }) =>
        isHidden ? (
          <span key={value} />
        ) : (
          <NumpadButton
            key={value}
            onClick={() => {
              if (isSpecialSymbol) {
                return onSpecialSymbolPress?.(value)
              }

              onClick?.(value)
            }}
          >
            {symbol}
          </NumpadButton>
        )
      )}
    </div>
  )
}
