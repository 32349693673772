import { BUTTON_COLOR, COLORS } from '@magmamath/ui'
import { ExerciseMode } from './models/exerciseMode'

export const ExerciseThemeColorMapper = {
  [ExerciseMode.REGULAR]: COLORS.PRIMARY_BLUE,
  [ExerciseMode.EXAM]: COLORS.PRIMARY_PURPLE,
  [ExerciseMode.PRACTICE]: COLORS.PRIMARY_YELLOW,
}

export const ExerciseThemeVariantMapper = {
  [ExerciseMode.REGULAR]: 'primary',
  [ExerciseMode.EXAM]: 'exam',
  [ExerciseMode.PRACTICE]: 'practice',
}

export const UIExerciseModeButtonColor = {
  [ExerciseMode.REGULAR]: BUTTON_COLOR.BLUE,
  [ExerciseMode.EXAM]: BUTTON_COLOR.PURPLE,
  [ExerciseMode.PRACTICE]: BUTTON_COLOR.YELLOW,
}

export const UIAnswerStatusButtonColor = {
  mistake: BUTTON_COLOR.RED,
  success: BUTTON_COLOR.YELLOW,
  'success-first-attempt': BUTTON_COLOR.GREEN,
  'exam-mode': BUTTON_COLOR.PURPLE,
}
