import React, { ReactNode } from 'react'
import { FRACTION } from '../../../constants/mathOperationConstants'
import { MathNumpadSymbol } from './types'

export const createSymbol = (
  sign: string,
  props: Partial<Omit<MathNumpadSymbol, 'buttonsSing'>> = {}
): MathNumpadSymbol => {
  return { buttonSign: sign, render: <span>{sign}</span>, styleClass: '', specialSymbol: false, ...props }
}
export const createMathLetter = (sing: string) => {
  return createSymbol(sing, { styleClass: 'math-letter' })
}
export const createSpecialSign = (sing: string, render?: ReactNode) => {
  return createSymbol(sing, render ? { styleClass: 'special-sign', render } : { styleClass: 'special-sign' })
}
export const createSpecialSymbol = (sign: string, render: ReactNode) => {
  return createSymbol(sign, { render, specialSymbol: true })
}
export const createFractionSymbol = (render: ReactNode) => {
  return createSymbol(FRACTION, {
    render,
    specialSymbol: true,
    styleClass: 'fraction-item',
  })
}
