import React from 'react'

import StarRoundedIcon from '../icons/StarRoundedIcon'
import StarRoundedFilledIcon from '../icons/StarRoundedFilledIcon'

interface StarRateProps {
  isFilled: boolean
  size?: number
}

const StarRate: React.FC<StarRateProps> = ({ isFilled, size }) => {
  if (isFilled) {
    return <StarRoundedFilledIcon size={size} />
  }

  return <StarRoundedIcon size={size} />
}

export default StarRate
