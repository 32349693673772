import React, { useContext, useMemo } from 'react'
import { LanguageTag, nativeLanguageTagMap } from '../../../../../config/allLanguages'
import { SimpleModeTextToSpeechButton } from '../../../../../features/simpleMode/components/SimpleModeTextToSpeechButton/SimpleModeTextToSpeechButton'
import TextToSpeech from '../../../../../features/TextToSpeech/TextToSpeech'
import { Context as UserSettingsContext } from '../../../../../context/userSettingsContext'
import { AnswerChoiceOption } from '../types'
import { combineSpeechText } from './helpers'
import { useTextToSpeechStore } from '../../../../../features/TextToSpeech/model'
import { useUnit } from 'effector-react'
import { $toolsMode, ToolsMode } from '../../../../../features/toolsMode/model'

type ChoiceTextToSpeechProps = {
  answerOptions: AnswerChoiceOption[]
  isTranslationPending: boolean
}

export const ChoiceTextToSpeech = ({ answerOptions, isTranslationPending }: ChoiceTextToSpeechProps) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)
  const hasTranslationT2S = nativeLanguageTagMap[userSettings.translationLanguage as LanguageTag]
  const toolsMode = useUnit($toolsMode)

  const textToSpeechContent = useMemo(() => {
    if (isTranslated && isTranslationPending) return ''
    return combineSpeechText(answerOptions)
  }, [answerOptions, isTranslated, isTranslationPending])

  if (!userSettings.textToSpeech) return
  if (isTranslated && !hasTranslationT2S) return

  if (toolsMode === ToolsMode.SIMPLE) {
    return <SimpleModeTextToSpeechButton textToSpeechContent={textToSpeechContent} />
  }

  return <TextToSpeech value={textToSpeechContent} />
}
