import React, { FC } from 'react'
import { NUMPAD_MATH_MOBILE_BUTTONS } from './symbols'
import { NumpadVariantProps } from '../types'

const NumpadMathMobile: FC<NumpadVariantProps> = ({ onPress, isButtonDisabled }) => {
  return (
    <div className='shortcut-numpad-mobile'>
      <div className='shortcut-container'>
        <div className='shortcut-buttons'>
          {NUMPAD_MATH_MOBILE_BUTTONS.map((symbol) => (
            <div className='buttons-row' key={symbol}>
              <button
                className='shortcut-button'
                key={`shortcut-button-${symbol}`}
                onClick={() => onPress?.(symbol)}
                disabled={isButtonDisabled}
              >
                {symbol}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NumpadMathMobile
