import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const FullscreenIcon = ({ size = 25, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 27 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_417_1219)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.11523 0H8.90062C9.35902 0 9.73062 0.371604 9.73062 0.83V0.83C9.73062 1.2884 9.35902 1.66 8.90062 1.66H4.11523C2.82289 1.66 1.77523 2.70765 1.77523 4V8.78539C1.77523 9.24378 1.40363 9.61539 0.945234 9.61539V9.61539C0.486838 9.61539 0.115234 9.24378 0.115234 8.78539V4C0.115234 1.79086 1.9061 0 4.11523 0ZM0.945234 15.3846C0.486838 15.3846 0.115234 15.7562 0.115234 16.2146V21C0.115234 23.2091 1.9061 25 4.11523 25H8.90062C9.35902 25 9.73062 24.6284 9.73062 24.17V24.17C9.73062 23.7116 9.35902 23.34 8.90062 23.34H4.11523C2.82289 23.34 1.77523 22.2923 1.77523 21V16.2146C1.77523 15.7562 1.40363 15.3846 0.945234 15.3846V15.3846ZM16.3299 25C15.8715 25 15.4999 24.6284 15.4999 24.17V24.17C15.4999 23.7116 15.8715 23.34 16.3298 23.34H21.1152C22.4076 23.34 23.4552 22.2923 23.4552 21V16.2146C23.4552 15.7562 23.8268 15.3846 24.2852 15.3846V15.3846C24.7436 15.3846 25.1152 15.7562 25.1152 16.2146V21C25.1152 23.2091 23.3244 25 21.1152 25H16.3299ZM24.2852 9.61539C24.7436 9.61539 25.1152 9.24378 25.1152 8.78539V4C25.1152 1.79086 23.3244 0 21.1152 0H16.3299C15.8715 0 15.4999 0.371604 15.4999 0.83V0.83C15.4999 1.2884 15.8715 1.66 16.3298 1.66H21.1152C22.4076 1.66 23.4552 2.70765 23.4552 4V8.78539C23.4552 9.24378 23.8268 9.61539 24.2852 9.61539V9.61539Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_417_1219'>
          <rect width='26.8486' height='25' fill='#ffffff' transform='translate(0.115234)' />
        </clipPath>
      </defs>
    </svg>
  )
}
