import React, { useState, useEffect, useCallback } from 'react'
import './styles.scss'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronLeft } from '../../../assets/images/chevron-left.svg'

import { BLOCKED_RESOURCE } from '../../../constants/theoryHintConstants'
import ThreeDotsLoader from '../../../ui/Loaders/ThreeDotsLoader'
import SectionButton from './SectionButton'
import LinkMessage from './LinkMessage'
import HtmlContent from '../TheoryContent/HtmlContent'

const TheoryContent = (props) => {
  const { t } = useTranslation()
  const [currentSection, setCurrentSection] = useState({ title: '', link: '', data: '' })

  useEffect(() => {
    const setInitialSection = async () => {
      if (!props.theoryContent || !props.theoryContent.length) {
        return
      }

      try {
        const response = await axios.get(props.theoryContent[0].link)
        setCurrentSection({
          title: props.theoryContent[0].title,
          link: props.theoryContent[0].link,
          data: response.data,
        })
      } catch (error) {
        setCurrentSection({
          title: props.theoryContent[0].title,
          link: props.theoryContent[0].link,
          data: BLOCKED_RESOURCE,
        })
      }
    }

    setInitialSection()
  }, [props.theoryContent])

  const onSectionButtonClick = async ({ title, link }) => {
    if (currentSection.title === title) {
      return
    }

    try {
      setCurrentSection({ title, link, data: '' })
      const responseTheory = await axios.get(link)
      setCurrentSection({ title, link, data: responseTheory.data })
    } catch (error) {
      setCurrentSection({ title, link, data: BLOCKED_RESOURCE })
    }
  }

  const renderSections = () => {
    return props.theoryContent.map((section, index) => {
      return (
        <SectionButton
          onClick={() => onSectionButtonClick({ title: section.title, link: section.link })}
          title={section.title}
          link={section.link}
          key={`${section.link}_${index}`}
          active={currentSection.title === section.title}
        />
      )
    })
  }

  const renderTheoryBody = useCallback(() => {
    if (!currentSection.data) {
      return (
        <div className='theory-loader-wrapper'>
          <ThreeDotsLoader />
        </div>
      )
    }

    if (currentSection.data === BLOCKED_RESOURCE) {
      return <LinkMessage link={currentSection.link} />
    }

    if (/html$/.test(currentSection.link)) {
      return <HtmlContent contentUrl={currentSection.link} />
    }

    try {
      return ReactHtmlParser(currentSection.data)
    } catch (err) {
      return currentSection.data
    }
  }, [currentSection])

  return (
    <div className={`theory-content-container ${props.show ? 'show' : ''}`}>
      <div className='theory-selector-panel'>
        <button className='close-button' onClick={props.close}>
          <ChevronLeft size={30} />
          {t('backText')}
        </button>

        <div className='theory-sections-wrapper'>{renderSections()}</div>
      </div>
      <div className='theory-body'>{renderTheoryBody()}</div>
    </div>
  )
}

export default TheoryContent
