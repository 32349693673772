import React, { useContext } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import AnswerFeedback from '../../../../AnswerFeedback'
import { useTranslation } from 'react-i18next'
import AnswerInfoContext from '../../../../../context/answerInfoContext'
import { ChoiceHint } from './ChoiceHint'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../../features/Problem/models/exerciseMode'
import { AppLocale } from '../../../../../locales/i18n.config'

type ChoiceAnswerFeedbackProps = {
  className?: string
  showAnswerPreview: boolean
  textClassName?: string
}

export const ChoiceAnswerFeedback = ({ className, textClassName, showAnswerPreview }: ChoiceAnswerFeedbackProps) => {
  const { i18n } = useTranslation()
  const { answerResultStatus } = useContext(AnswerInfoContext)

  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const isEnglishLocale = i18n.language === AppLocale.EN

  return (
    <div
      className={clsx(className || styles.ChoiceAnswerFeedback, {
        [styles.ChoiceAnswerFeedbackSlim]: isExamMode || isEnglishLocale,
      })}
    >
      <ChoiceHint showAnswerPreview={showAnswerPreview} />
      {answerResultStatus && <AnswerFeedback className={textClassName} />}
    </div>
  )
}
