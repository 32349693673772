import { AppLocale } from '../locales/i18n.config'

const AFRIKAANS = 'Afrikaans'
const ALBANIAN = 'Albanian'
const AMHARIC = 'Amharic'
const ARABIC = 'Arabic'
const ARMENIAN = 'Armenian'
const ASSAMESE = 'Assamese'
const AYMARA = 'Aymara'
const AZERBAIJANI = 'Azerbaijani'
const BAMBARA = 'Bambara'
const BASQUE = 'Basque'
const BELARUSIAN = 'Belarusian'
const BENGALI = 'Bengali'
const BHOJPURI = 'Bhojpuri'
const BOSNIAN = 'Bosnian'
const BULGARIAN = 'Bulgarian'
const CATALAN = 'Catalan'
const CEBUANO = 'Cebuano'
const CHINESE_SIMPLIFIED = 'Chinese (Simplified)'
const CHINESE_TRADITIONAL = 'Chinese (Traditional)'
const CORSICAN = 'Corsican'
const CROATIAN = 'Croatian'
const CZECH = 'Czech'
const DANISH = 'Danish'
const DHIVEHI = 'Dhivehi'
const DOGRI = 'Dogri'
const DUTCH = 'Dutch'
const ENGLISH = 'English'
const BRITISH_ENGLISH = 'British English'
const ESPERANTO = 'Esperanto'
const ESTONIAN = 'Estonian'
const EWE = 'Ewe'
const FINNISH = 'Finnish'
const FRENCH = 'French'
const FRISIAN = 'Frisian'
const GALICIAN = 'Galician'
const GEORGIAN = 'Georgian'
const GERMAN = 'German'
const GREEK = 'Greek'
const GUARANI = 'Guarani'
const GUJARATI = 'Gujarati'
const HAITIAN_CREOLE = 'Haitian Creole'
const HAUSA = 'Hausa'
const HAWAIIAN = 'Hawaiian'
const HEBREW = 'Hebrew'
const HINDI = 'Hindi'
const HMONG = 'Hmong'
const HUNGARIAN = 'Hungarian'
const ICELANDIC = 'Icelandic'
const IGBO = 'Igbo'
const ILOCANO = 'Ilocano'
const INDONESIAN = 'Indonesian'
const IRISH = 'Irish'
const ITALIAN = 'Italian'
const JAPANESE = 'Japanese'
const JAVANESE = 'Javanese'
const KANNADA = 'Kannada'
const KAZAKH = 'Kazakh'
const KHMER = 'Khmer'
const KINYARWANDA = 'Kinyarwanda'
const KONKANI = 'Konkani'
const KOREAN = 'Korean'
const KRIO = 'Krio'
const KURDISH = 'Kurdish'
const KURDISH_SORANI = 'Kurdish (Sorani)'
const KYRGYZ = 'Kyrgyz'
const LAO = 'Lao'
const LATVIAN = 'Latvian'
const LINGALA = 'Lingala'
const LITHUANIAN = 'Lithuanian'
const LUGANDA = 'Luganda'
const LUXEMBOURGISH = 'Luxembourgish'
const MACEDONIAN = 'Macedonian'
const MAITHILI = 'Maithili'
const MALAGASY = 'Malagasy'
const MALAY = 'Malay'
const MALAYALAM = 'Malayalam'
const MALTESE = 'Maltese'
const MAORI = 'Maori'
const MARATHI = 'Marathi'
const MEITEILON_MANIPURI = 'Meiteilon (Manipuri)'
const MIZO = 'Mizo'
const MONGOLIAN = 'Mongolian'
const MYANMAR_BURMESE = 'Myanmar (Burmese)'
const NEPALI = 'Nepali'
const NORWEGIAN = 'Norwegian'
const NYANJA_CHICHEWA = 'Nyanja (Chichewa)'
const ODIA_ORIYA = 'Odia (Oriya)'
const OROMO = 'Oromo'
const PASHTO = 'Pashto'
const PERSIAN = 'Persian'
const POLISH = 'Polish'
const PORTUGUESE_PORTUGAL_BRAZIL = 'Portuguese (Portugal, Brazil)'
const PUNJABI = 'Punjabi'
const QUECHUA = 'Quechua'
const ROMANIAN = 'Romanian'
const RUSSIAN = 'Russian'
const SAMOAN = 'Samoan'
const SANSKRIT = 'Sanskrit'
const SCOTS_GAELIC = 'Scots Gaelic'
const SEPEDI = 'Sepedi'
const SERBIAN = 'Serbian'
const SESOTHO = 'Sesotho'
const SHONA = 'Shona'
const SINDHI = 'Sindhi'
const SINHALA_SINHALESE = 'Sinhala (Sinhalese)'
const SLOVAK = 'Slovak'
const SLOVENIAN = 'Slovenian'
const SOMALI = 'Somali'
const SPANISH = 'Spanish'
const SUNDANESE = 'Sundanese'
const SWAHILI = 'Swahili'
const SWEDISH = 'Swedish'
const TAGALOG_FILIPINO = 'Tagalog (Filipino)'
const TAJIK = 'Tajik'
const TAMIL = 'Tamil'
const TATAR = 'Tatar'
const TELUGU = 'Telugu'
const THAI = 'Thai'
const TIGRINYA = 'Tigrinya'
const TSONGA = 'Tsonga'
const TURKISH = 'Turkish'
const TURKMEN = 'Turkmen'
const TWI_AKAN = 'Twi (Akan)'
const UKRAINIAN = 'Ukrainian'
const URDU = 'Urdu'
const UYGHUR = 'Uyghur'
const UZBEK = 'Uzbek'
const VIETNAMESE = 'Vietnamese'
const WELSH = 'Welsh'
const XHOSA = 'Xhosa'
const YIDDISH = 'Yiddish'
const YORUBA = 'Yoruba'
const ZULU = 'Zulu'

export const nativeLanguages = [
  AFRIKAANS,
  ALBANIAN,
  AMHARIC,
  ARABIC,
  ARMENIAN,
  ASSAMESE,
  AYMARA,
  AZERBAIJANI,
  BAMBARA,
  BASQUE,
  BELARUSIAN,
  BENGALI,
  BHOJPURI,
  BOSNIAN,
  BULGARIAN,
  CATALAN,
  CEBUANO,
  CHINESE_SIMPLIFIED,
  CHINESE_TRADITIONAL,
  CORSICAN,
  CROATIAN,
  CZECH,
  DANISH,
  DHIVEHI,
  DOGRI,
  DUTCH,
  ENGLISH,
  ESPERANTO,
  ESTONIAN,
  EWE,
  FINNISH,
  FRENCH,
  FRISIAN,
  GALICIAN,
  GEORGIAN,
  GERMAN,
  GREEK,
  GUARANI,
  GUJARATI,
  HAITIAN_CREOLE,
  HAUSA,
  HAWAIIAN,
  HEBREW,
  HINDI,
  HMONG,
  HUNGARIAN,
  ICELANDIC,
  IGBO,
  ILOCANO,
  INDONESIAN,
  IRISH,
  ITALIAN,
  JAPANESE,
  JAVANESE,
  KANNADA,
  KAZAKH,
  KHMER,
  KINYARWANDA,
  KONKANI,
  KOREAN,
  KRIO,
  KURDISH,
  KURDISH_SORANI,
  KYRGYZ,
  LAO,
  LATVIAN,
  LINGALA,
  LITHUANIAN,
  LUGANDA,
  LUXEMBOURGISH,
  MACEDONIAN,
  MAITHILI,
  MALAGASY,
  MALAY,
  MALAYALAM,
  MALTESE,
  MAORI,
  MARATHI,
  MEITEILON_MANIPURI,
  MIZO,
  MONGOLIAN,
  MYANMAR_BURMESE,
  NEPALI,
  NORWEGIAN,
  NYANJA_CHICHEWA,
  ODIA_ORIYA,
  OROMO,
  PASHTO,
  PERSIAN,
  POLISH,
  PORTUGUESE_PORTUGAL_BRAZIL,
  PUNJABI,
  QUECHUA,
  ROMANIAN,
  RUSSIAN,
  SAMOAN,
  SANSKRIT,
  SCOTS_GAELIC,
  SEPEDI,
  SERBIAN,
  SESOTHO,
  SHONA,
  SINDHI,
  SINHALA_SINHALESE,
  SLOVAK,
  SLOVENIAN,
  SOMALI,
  SPANISH,
  SUNDANESE,
  SWAHILI,
  SWEDISH,
  TAGALOG_FILIPINO,
  TAJIK,
  TAMIL,
  TATAR,
  TELUGU,
  THAI,
  TIGRINYA,
  TSONGA,
  TURKISH,
  TURKMEN,
  TWI_AKAN,
  UKRAINIAN,
  URDU,
  UYGHUR,
  UZBEK,
  VIETNAMESE,
  WELSH,
  XHOSA,
  YIDDISH,
  YORUBA,
  ZULU,
]

export const nativeLanguageISOCodeMap = {
  [AFRIKAANS]: 'af',
  [ALBANIAN]: 'sq',
  [AMHARIC]: 'am',
  [ARABIC]: 'ar',
  [ARMENIAN]: 'hy',
  [ASSAMESE]: 'as',
  [AYMARA]: 'ay',
  [AZERBAIJANI]: 'az',
  [BAMBARA]: 'bm',
  [BASQUE]: 'eu',
  [BELARUSIAN]: 'be',
  [BENGALI]: 'bn',
  [BHOJPURI]: 'bho',
  [BOSNIAN]: 'bs',
  [BULGARIAN]: 'bg',
  [CATALAN]: 'ca',
  [CEBUANO]: 'ceb',
  [CHINESE_SIMPLIFIED]: 'zh-CN',
  [CHINESE_TRADITIONAL]: 'zh-TW',
  [CORSICAN]: 'co',
  [CROATIAN]: 'hr',
  [CZECH]: 'cs',
  [DANISH]: 'da',
  [DHIVEHI]: 'dv',
  [DOGRI]: 'doi',
  [DUTCH]: 'nl',
  [ENGLISH]: 'en',
  [ESPERANTO]: 'eo',
  [ESTONIAN]: 'et',
  [EWE]: 'ee',
  [FINNISH]: 'fi',
  [FRENCH]: 'fr',
  [FRISIAN]: 'fy',
  [GALICIAN]: 'gl',
  [GEORGIAN]: 'ka',
  [GERMAN]: 'de',
  [GREEK]: 'el',
  [GUARANI]: 'gn',
  [GUJARATI]: 'gu',
  [HAITIAN_CREOLE]: 'ht',
  [HAUSA]: 'ha',
  [HAWAIIAN]: 'haw',
  [HEBREW]: 'he',
  [HINDI]: 'hi',
  [HMONG]: 'hmn',
  [HUNGARIAN]: 'hu',
  [ICELANDIC]: 'is',
  [IGBO]: 'ig',
  [ILOCANO]: 'ilo',
  [INDONESIAN]: 'id',
  [IRISH]: 'ga',
  [ITALIAN]: 'it',
  [JAPANESE]: 'ja',
  [JAVANESE]: 'jv',
  [KANNADA]: 'kn',
  [KAZAKH]: 'kk',
  [KHMER]: 'km',
  [KINYARWANDA]: 'rw',
  [KONKANI]: 'gom',
  [KOREAN]: 'ko',
  [KRIO]: 'kri',
  [KURDISH]: 'ku',
  [KURDISH_SORANI]: 'ckb',
  [KYRGYZ]: 'ky',
  [LAO]: 'lo',
  [LATVIAN]: 'lv',
  [LINGALA]: 'ln',
  [LITHUANIAN]: 'lt',
  [LUGANDA]: 'lg',
  [LUXEMBOURGISH]: 'lb',
  [MACEDONIAN]: 'mk',
  [MAITHILI]: 'mai',
  [MALAGASY]: 'mg',
  [MALAY]: 'ms',
  [MALAYALAM]: 'ml',
  [MALTESE]: 'mt',
  [MAORI]: 'mi',
  [MARATHI]: 'mr',
  [MEITEILON_MANIPURI]: 'mni-Mtei',
  [MIZO]: 'lus',
  [MONGOLIAN]: 'mn',
  [MYANMAR_BURMESE]: 'my',
  [NEPALI]: 'ne',
  [NORWEGIAN]: 'no',
  [NYANJA_CHICHEWA]: 'ny',
  [ODIA_ORIYA]: 'or',
  [OROMO]: 'om',
  [PASHTO]: 'ps',
  [PERSIAN]: 'fa',
  [POLISH]: 'pl',
  [PORTUGUESE_PORTUGAL_BRAZIL]: 'pt',
  [PUNJABI]: 'pa',
  [QUECHUA]: 'qu',
  [ROMANIAN]: 'ro',
  [RUSSIAN]: 'ru',
  [SAMOAN]: 'sm',
  [SANSKRIT]: 'sa',
  [SCOTS_GAELIC]: 'gd',
  [SEPEDI]: 'nso',
  [SERBIAN]: 'sr',
  [SESOTHO]: 'st',
  [SHONA]: 'sn',
  [SINDHI]: 'sd',
  [SINHALA_SINHALESE]: 'si',
  [SLOVAK]: 'sk',
  [SLOVENIAN]: 'sl',
  [SOMALI]: 'so',
  [SPANISH]: 'es',
  [SUNDANESE]: 'su',
  [SWAHILI]: 'sw',
  [SWEDISH]: 'sv',
  [TAGALOG_FILIPINO]: 'tl',
  [TAJIK]: 'tg',
  [TAMIL]: 'ta',
  [TATAR]: 'tt',
  [TELUGU]: 'te',
  [THAI]: 'th',
  [TIGRINYA]: 'ti',
  [TSONGA]: 'ts',
  [TURKISH]: 'tr',
  [TURKMEN]: 'tk',
  [TWI_AKAN]: 'ak',
  [UKRAINIAN]: 'uk',
  [URDU]: 'ur',
  [UYGHUR]: 'ug',
  [UZBEK]: 'uz',
  [VIETNAMESE]: 'vi',
  [WELSH]: 'cy',
  [XHOSA]: 'xh',
  [YIDDISH]: 'yi',
  [YORUBA]: 'yo',
  [ZULU]: 'zu',
}

// https://cloud.google.com/text-to-speech/docs/voices
export const nativeLanguageTagMap = {
  [AFRIKAANS]: { languageCode: 'af-ZA', voiceName: 'af-ZA-Standard-A' },
  // albanian
  // amharic
  [ARABIC]: { languageCode: 'ar-XA', voiceName: 'ar-XA-Wavenet-C' },
  // armenian
  // assamese
  // aymara
  // azerbaijani
  // bambara
  // basque
  // belarusian
  [BENGALI]: { languageCode: 'bn-IN', voiceName: 'bn-IN-Wavenet-B' },
  // bhojpuri
  // bosnian
  [BULGARIAN]: { languageCode: 'bg-BG', voiceName: 'bg-bg-Standard-A' },
  [CATALAN]: { languageCode: 'ca-ES', voiceName: 'ca-es-Standard-A' },
  // cebuano
  [CHINESE_SIMPLIFIED]: { languageCode: 'cmn-CN', voiceName: 'cmn-CN-Wavenet-A' },
  [CHINESE_TRADITIONAL]: { languageCode: 'yue-HK', voiceName: 'yue-HK-Standard-C' },
  // corsican
  // croatian
  [CZECH]: { languageCode: 'cs-CZ', voiceName: 'cs-CZ-Wavenet-A' },
  [DANISH]: { languageCode: 'da-DK', voiceName: 'da-DK-Wavenet-C' },
  // dhivehi
  // dogri
  [DUTCH]: { languageCode: 'nl-NL', voiceName: 'nl-NL-Wavenet-C' },
  // esperanto
  // estonian
  // ewe
  // finnish
  [ENGLISH]: { languageCode: 'en-US', voiceName: 'en-US-Wavenet-C' },
  [BRITISH_ENGLISH]: { languageCode: 'en-GB', voiceName: 'en-GB-Neural2-A' },
  [FINNISH]: { languageCode: 'fi-FI', voiceName: 'fi-FI-Wavenet-A' },
  [FRENCH]: { languageCode: 'fr-FR', voiceName: 'fr-FR-Wavenet-C' },
  // frisian
  // galician
  // georgian
  [GERMAN]: { languageCode: 'de-DE', voiceName: 'de-DE-Wavenet-C' },
  [GREEK]: { languageCode: 'el-GR', voiceName: 'el-GR-Wavenet-A' },
  // guarani
  [GUJARATI]: { languageCode: 'gu-IN', voiceName: 'gu-IN-Wavenet-B' },
  // haitian creole
  // hausa
  // hawaiian
  // hebrew
  [HINDI]: { languageCode: 'hi-IN', voiceName: 'hi-IN-Wavenet-C' },
  // hmong
  [HUNGARIAN]: { languageCode: 'hu-HU', voiceName: 'hu-HU-Wavenet-A' },
  [ICELANDIC]: { languageCode: 'is-IS', voiceName: 'is-is-Standard-A' },
  // igbo
  // ilocano
  [INDONESIAN]: { languageCode: 'id-ID', voiceName: 'id-ID-Wavenet-C' },
  // irish
  [ITALIAN]: { languageCode: 'it-IT', voiceName: 'it-IT-Wavenet-C' },
  [JAPANESE]: { languageCode: 'ja-JP', voiceName: 'ja-JP-Wavenet-C' },
  // javanese
  [KANNADA]: { languageCode: 'kn-IN', voiceName: 'kn-IN-Wavenet-B' }, // Kannada???
  // kazakh
  // khmer
  // kinyarwanda
  // konkani
  [KOREAN]: { languageCode: 'ko-KR', voiceName: 'ko-KR-Wavenet-C' },
  // krio
  // kurdish
  // kurdish (sorani)
  // kyrgyz
  // lao
  [LATVIAN]: { languageCode: 'lv-LV', voiceName: 'lv-lv-Standard-A' },
  // lingala
  // lithuanian
  // luganda
  // luxembourgish
  // macedonian
  // maithili
  // malagasy
  [MALAY]: { languageCode: 'ms-MY', voiceName: 'ms-MY-Wavenet-C' },
  [MALAYALAM]: { languageCode: 'ml-IN', voiceName: 'ml-IN-Wavenet-B' },
  // maltese
  // maori
  // marathi
  // meiteilon (manipuri)
  // mizo
  // mongolian
  // myaanmar
  // nepali
  [NORWEGIAN]: { languageCode: 'nb-NO', voiceName: 'nb-NO-Wavenet-C' },
  // nyanja
  // odia
  // oromo
  // pashto
  // persian
  [POLISH]: { languageCode: 'pl-PL', voiceName: 'pl-PL-Wavenet-C' },
  [PORTUGUESE_PORTUGAL_BRAZIL]: { languageCode: 'pt-PT', voiceName: 'pt-PT-Wavenet-C' },
  [PUNJABI]: { languageCode: 'pa-IN', voiceName: 'pa-IN-Wavenet-C' },
  // quechua
  [ROMANIAN]: { languageCode: 'ro-RO', voiceName: 'ro-RO-Wavenet-A' },
  [RUSSIAN]: { languageCode: 'ru-RU', voiceName: 'ru-RU-Wavenet-C' },
  // samoan
  // sanskrit
  // scots gaelic
  // sepedi
  [SERBIAN]: { languageCode: 'sr-RS', voiceName: 'sr-rs-Standard-A' },
  // sesotho
  // shona
  // sindhi
  // sinhala
  [SLOVAK]: { languageCode: 'sk-SK', voiceName: 'sk-SK-Wavenet-A' },
  // slovenian
  // somali
  [SPANISH]: { languageCode: 'es-ES', voiceName: 'es-ES-Wavenet-C' },
  // sundanese
  // swahili
  [SWEDISH]: { languageCode: 'sv-SE', voiceName: 'sv-SE-Wavenet-C' },
  // tagalog (filipino)
  // tajik
  [TAMIL]: { languageCode: 'ta-IN', voiceName: 'ta-IN-Wavenet-B' },
  // tatar
  [TELUGU]: { languageCode: 'te-IN', voiceName: 'te-IN-Standard-B' },
  [THAI]: { languageCode: 'th-TH', voiceName: 'th-TH-Standard-A' },
  // tigrinya
  // tsonga
  [TURKISH]: { languageCode: 'tr-TR', voiceName: 'tr-TR-Wavenet-C' },
  // turkmen
  // twi (akan)
  [UKRAINIAN]: { languageCode: 'uk-UA', voiceName: 'uk-UA-Wavenet-A' },
  // urdu
  // uyghur
  // uzbek
  [VIETNAMESE]: { languageCode: 'vi-VN', voiceName: 'vi-VN-Wavenet-C' },
  //   welsh
  //   xhosa
  //   yiddish
  //   yoruba
  //   zulu
}

export const AppLanguages = {
  [AppLocale.EN]: ENGLISH,
  [AppLocale.SV]: SWEDISH,
  [AppLocale.UK]: BRITISH_ENGLISH,
}

export type LanguageTag = keyof typeof nativeLanguageTagMap

// TODO: simplify this file, move to constants/languages.ts
