import React, { useContext, useMemo, useRef, useState } from 'react'
import styles from './exerciseDescription.module.scss'
import clsx from 'clsx'

import ProblemSelector from '../../components/ProblemSelector'
import { DescriptionImage } from './components/DescriptionImage/DescriptionImage'
import { DescriptionText } from './components/DescriptionText/DescriptionText'
import { getSidebarSizeParams } from './helpers'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { getCurrentImage } from '../../components/Problem/helpers'
import { useImageMetaData } from './hooks/useImageMetaData'
import { useMatchesMobile } from '../../lib/device/hook'
import { resizeKaTeX } from '../../lib/helpers/katex'
import { useOnProblemChange } from '../assignment/hooks/useOnProblemChange'
import { usePreventSwipeGesture } from './hooks/usePreventSwipeGesture'
import { AssignmentProblem } from '../ExerciseItem/types'
import { WebStartDraggingProps } from './types'
import { ExerciseDescriptionModal } from './components/ExerciseDescriptionModal/ExerciseDescriptionModal'
import { $exerciseModalOpen, setExerciseModalOpen } from './model'
import { useTextToSpeechStore } from '../TextToSpeech/model'
import { removeKatexPartFromTextToSpeech } from '../../helpers'
import { useUnit } from 'effector-react'

export type DescriptionExercise = AssignmentProblem & { translatedText?: string; translatedTextToSpeech?: string }

type ExerciseDescriptionProps = {
  onDragStart?: (event: React.DragEvent, src: string, options?: WebStartDraggingProps) => void
  exercise: DescriptionExercise
  onTranslateButtonPress: () => void
  hideProblemSelector?: boolean
  mergeLinebreaks?: boolean
}

export const DESCRIPTION_IMAGE_ALT_FALLBACK = 'illustration for the exercise'

export const ExerciseDescription = ({
  onDragStart,
  exercise,
  onTranslateButtonPress,
  hideProblemSelector,
  mergeLinebreaks,
}: ExerciseDescriptionProps) => {
  const isMobileDevice = useMatchesMobile()
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)
  const { state: userSettings } = useContext(UserSettingsContext)
  const [fontResized, setFontResized] = useState(false)
  const exerciseModalOpen = useUnit($exerciseModalOpen)

  const textToSpeechContent = removeKatexPartFromTextToSpeech(
    exercise?.richDescription || exercise?.kaTeXDescription || exercise?.description
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  usePreventSwipeGesture(containerRef)

  const imageSrc = getCurrentImage(exercise)
  const { data: imageMetaData } = useImageMetaData(imageSrc)

  const { fontSize, isScrollable } = useMemo(() => {
    if (!fontResized) {
      return {
        isScrollable: false,
        fontSize: userSettings?.textSize,
      }
    }

    return getSidebarSizeParams({
      contentRef,
      containerRef,
      hasImage: !!imageSrc,
      defaultFontSize: userSettings?.textSize,
    })
  }, [imageSrc, userSettings?.textSize, fontResized])

  const handleResizeKaTeX = (fontSize: number) => {
    resizeKaTeX({
      contentRef,
      fontSize,
    })

    setTimeout(() => setFontResized(true), 20)
  }

  useOnProblemChange(() => {
    setFontResized(false)
  })

  return (
    <>
      {!hideProblemSelector && <ProblemSelector />}

      {/* TODO: we will probably hide it in geogebra but it should be left for now */}
      {exerciseModalOpen && (
        <ExerciseDescriptionModal
          imageSrc={imageSrc}
          alt={imageMetaData?.altText}
          cdnSrc={exercise.cdnImage}
          exercise={exercise}
          onTranslateButtonPress={onTranslateButtonPress}
          textToSpeechContent={isTranslated ? exercise.translatedTextToSpeech : textToSpeechContent}
        />
      )}

      <div className={styles.ExerciseDescription} ref={containerRef} onClick={() => setExerciseModalOpen(true)}>
        <div className={clsx(styles.ExerciseContent, { [styles.Visible]: fontResized })}>
          {(exercise.cdnImage || imageSrc) && (
            <DescriptionImage
              key={imageSrc}
              src={imageSrc}
              alt={imageMetaData?.altText}
              cdnSrc={exercise.cdnImage}
              isScaledDown={!imageMetaData?.containsData && isScrollable}
              onDragStart={onDragStart}
              isDraggable={!isMobileDevice}
            />
          )}

          <DescriptionText
            ref={contentRef}
            onReadyTextfit={handleResizeKaTeX}
            exercise={exercise}
            fontSize={fontSize}
            onDragStart={onDragStart}
            isDraggable={!isMobileDevice}
            mergeLinebreaks={mergeLinebreaks}
          />
        </div>
      </div>
    </>
  )
}
