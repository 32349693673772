import React, { useState, useRef } from 'react'

const getEventCoordinates = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
  const isTouchDevice = event.type.startsWith('touch')

  if (isTouchDevice) {
    const touchEvent = event as React.TouchEvent<HTMLDivElement>
    return { x: touchEvent.touches[0].clientX, y: touchEvent.touches[0].clientY }
  }

  const mouseEvent = event as React.MouseEvent<HTMLDivElement>
  return { x: mouseEvent.clientX, y: mouseEvent.clientY }
}

const clearTimeoutIfExists = (timeoutId: NodeJS.Timeout | null) => {
  if (!timeoutId) return
  clearTimeout(timeoutId)
}

type useLongPressDragProps = {
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void
  delayMs: number
}

/**
 * Custom hook to handle long press drag functionality on touch devices.
 *
 * On touch devices, distinguishing between a scroll and a drag action is tricky and does not work out of the box.
 * this hook uses a timer to delay the drag initiation to help determine if the user intends to scroll or drag.
 * (if DOM element has "draggable" property, it will be unable to scroll by default on touch device because instead of scrolling it will drag the element),
 */
export const useLongPressDrag = ({ onDragStart, delayMs = 200 }: useLongPressDragProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const dragTimer = useRef<NodeJS.Timeout | null>(null)
  const startPos = useRef({ x: 0, y: 0 })
  const isTouchDevice = useRef(false)

  const startDrag = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const isTouchDeviceEventType = event.type.startsWith('touch')
    isTouchDevice.current = isTouchDeviceEventType

    const { x, y } = getEventCoordinates(event)
    startPos.current = { x, y }

    clearTimeoutIfExists(dragTimer.current)

    if (isTouchDeviceEventType) {
      dragTimer.current = setTimeout(() => setIsDragging(true), delayMs)
    } else {
      setIsDragging(true) // Immediate drag for non-touch devices
    }
  }

  const checkForScroll = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isTouchDevice.current) return

    const moveX = event.touches[0].clientX
    const moveY = event.touches[0].clientY

    // Check if the movement is significant
    if (Math.abs(moveX - startPos.current.x) > 10 || Math.abs(moveY - startPos.current.y) > 10) {
      clearTimeoutIfExists(dragTimer.current)
    }
  }

  const endDrag = () => {
    clearTimeoutIfExists(dragTimer.current)
    setIsDragging(false)
  }

  const initiateDrag = (event: React.DragEvent<HTMLDivElement>) => {
    if (!isDragging) {
      event.preventDefault()
      return
    }
    onDragStart(event)
  }

  return {
    bind: {
      onTouchStart: startDrag,
      onTouchMove: checkForScroll,
      onTouchEnd: endDrag,
      onDragStart: initiateDrag,
      onMouseDown: startDrag, // for Desktop Web device
    },
    isDragging,
  }
}
