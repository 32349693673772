import React from 'react'
import { useUnit } from 'effector-react'
import { COLORS } from '@magmamath/ui'

import styles from './styles.module.scss'

import { practiceModeModel } from '../../models'
import ArrowControlLeft from '../../../../ui/icons/ArrowControlLeft'

const ScrollToUnsolvedAbilityButton = () => {
  const cardIdToScroll = useUnit(practiceModeModel.firstUnsolvedCardId.$store)

  const scrollToFirstUnsolvedItem = () => {
    const abilityToScroll = document.getElementById(cardIdToScroll)

    if (!abilityToScroll) {
      return
    }

    abilityToScroll.scrollIntoView(false)
  }

  return (
    <div className={styles.ScrollButton} onClick={scrollToFirstUnsolvedItem}>
      <ArrowControlLeft size={12} color={COLORS.NEUTRAL_1} />
    </div>
  )
}

export default ScrollToUnsolvedAbilityButton
