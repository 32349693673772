import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { getExerciseProblemsCount } from '../../../helpers/exercises/exercisesHelper'
import type { Assignment } from '../../ExerciseItem/types'
import { getUserId } from '../../../helpers/users/getUserInfo'

type AssignmentProgress = {
  totalProblemsCount: number
  submittedProblemsIds: string[]
  progress: number
}

type State = {
  assignmentsProgressById: Record<string, AssignmentProgress>
}

type AddSubmittedProblemProps = { currentExercise: Assignment; problemId: string }
type RemoveSubmittedProblem = { assignmentId: string; problemId: string }

type AssignmentsProgressModel = State & {
  calculateInitialProgress: (currentExercise: Assignment, isExamMode?: boolean) => void
  addSubmittedProblem: ({ currentExercise, problemId }: AddSubmittedProblemProps) => void
  removeSubmittedProblem: ({ assignmentId, problemId }: RemoveSubmittedProblem) => void
}

const initialState: State = {
  assignmentsProgressById: {},
}

export const useAssignmentsProgress = create(
  immer<AssignmentsProgressModel>((set, getState) => ({
    ...initialState,
    calculateInitialProgress: (currentExercise, isExamMode) =>
      set((state) => {
        if (!currentExercise?.problems?.length) return
        const currentUserId = getUserId()

        const submittedProblemsIds = currentExercise.problems.reduce<string[]>((acc, problem) => {
          const currentUserStatistic = problem.studentStats?.find(
            (statisticItem) => statisticItem.studentId === currentUserId
          )

          if (currentUserStatistic?.stats?.attempt) {
            if (isExamMode) {
              acc.push(currentUserStatistic.stats.problemId)
            } else {
              const isLastAnswerCorrect = currentUserStatistic?.stats.answerArray.at(-1)?.correct
              if (isLastAnswerCorrect) acc.push(currentUserStatistic.stats.problemId)
            }
          }

          return acc
        }, [])

        const totalProblemsCount = getExerciseProblemsCount(currentExercise)

        state.assignmentsProgressById[currentExercise._id] = {
          totalProblemsCount,
          submittedProblemsIds,
          progress: (submittedProblemsIds.length / totalProblemsCount) * 100,
        }
      }),
    addSubmittedProblem: ({ currentExercise, problemId }) =>
      set((state) => {
        const currentAssignment = getState().assignmentsProgressById[currentExercise._id]
        const submittedProblemsIds = [...new Set([...(currentAssignment?.submittedProblemsIds || []), problemId])]
        const totalProblemsCount = getExerciseProblemsCount(currentExercise)

        state.assignmentsProgressById[currentExercise._id] = {
          totalProblemsCount,
          submittedProblemsIds,
          progress: (submittedProblemsIds.length / totalProblemsCount) * 100,
        }
      }),
    removeSubmittedProblem: ({ assignmentId, problemId }) =>
      set((state) => {
        state.assignmentsProgressById[assignmentId]?.submittedProblemsIds?.filter((id) => id !== problemId)
      }),
  }))
)
