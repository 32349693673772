import React, { MouseEvent, useEffect } from 'react'
import useInputTypeStore, { InputType } from '../../../../../features/Problem/models/inputType'
import type { AnswerChoiceOption } from '../types'
import { ChoiceAnswerOptions } from '../ChoiceAnswerOptions/ChoiceAnswerOptions'
import { useOnEnterSubmit } from '../hooks/useOnEnterSubmit'
import { useDrawBoardModel } from '../../../../../features/DrawBoard/model'
import { DrawBoardMode } from '@magmamath/react-native-draw-board'
import { assignmentModel } from '../../../../../features/assignment/model/assignment.model'
import { useUnit } from 'effector-react'

type ChoiceInputProps = {
  answerOptions: AnswerChoiceOption[]
  checkIsOptionSelected: (option: AnswerChoiceOption) => boolean
  getSelectedItemOrder?: (text: string) => number
  handleSelectAnswer: (option: AnswerChoiceOption) => void
  handleSubmitAnswer: () => void
  isSelectingDisabled: boolean
  onPress: (event: MouseEvent<HTMLDivElement>) => void
  onPressRelease: (event: MouseEvent<HTMLDivElement>) => void
  showNumberIcons: boolean
  isTranslationPending: boolean
}

export const ChoiceInput = ({
  answerOptions,
  checkIsOptionSelected,
  getSelectedItemOrder,
  handleSelectAnswer,
  handleSubmitAnswer,
  isSelectingDisabled,
  onPress,
  onPressRelease,
  showNumberIcons,
  isTranslationPending,
}: ChoiceInputProps) => {
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const inputType = useInputTypeStore((state) => state.value)
  const setInputType = useInputTypeStore((state) => state.set)
  const isSpreadsheetActive = useDrawBoardModel((state) => state.mode === DrawBoardMode.SPREADSHEET)

  useEffect(() => {
    if (inputType === InputType.KEYBOARD) {
      setInputType(InputType.MY_SCRIPT)
    }
  }, [isSpreadsheetActive])

  useOnEnterSubmit((event) => {
    if (isAnswerPending) return
    event.preventDefault()
    handleSubmitAnswer()
  })

  return (
    <ChoiceAnswerOptions
      checkIsOptionSelected={checkIsOptionSelected}
      getSelectedItemOrder={getSelectedItemOrder}
      handleSelectAnswer={handleSelectAnswer}
      handleSubmitAnswer={handleSubmitAnswer}
      isSelectingDisabled={isSelectingDisabled}
      onPress={onPress}
      onPressRelease={onPressRelease}
      options={answerOptions}
      showNumberIcons={showNumberIcons}
      isTranslationPending={isTranslationPending}
    />
  )
}
