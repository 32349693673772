import { TFunction } from 'react-i18next'
import {
  Ability,
  Category,
  PracticeSkill,
  PracticeTree,
  SkillStatisticsMap,
  SkillStats,
  SubCategory,
} from '../PracticeMode/types'
import { practicePageSessionActions } from '../PracticeMode/sessionStorage'
import { CorrectAnswerRatePercentage } from '../PracticeMode/constants'

type GetNextSkillArguments = {
  practiceTree: PracticeTree
  currentSkillId: string
}

const getPracticeTreeSkills = (practiceGradeTree: PracticeTree) => {
  return practiceGradeTree.children
    .map((practiceMainTree) => {
      return practiceMainTree.children.map((practiceTree) => practiceTree.skills)
    })
    .flat(2)
}

export const getFirstUnsolvedSkill = (practiceGradeTreeTree: PracticeTree, statistics: SkillStatisticsMap | null) => {
  const skills = getPracticeTreeSkills(practiceGradeTreeTree)

  if (!skills.length) {
    return
  }

  return skills.find((skill) => {
    const statistic = statistics?.[skill?._id]
    const progress = calculateSkillProgress(statistic)
    return !progress || progress < CorrectAnswerRatePercentage.ONE_STAR
  })
}

export const getSolvedBlockPercent = (contentBlocks: PracticeTree[], statistics: SkillStatisticsMap | null = {}) => {
  const skills = contentBlocks.map((contentBlock) => contentBlock.skills).flat()
  if (!skills?.length) {
    return
  }

  const percent = skills.reduce((count: number, skill: PracticeSkill) => {
    const stats = statistics?.[skill._id]
    const progress = calculateSkillProgress(stats)
    const isSolved = progress >= CorrectAnswerRatePercentage.ONE_STAR
    return isSolved ? count + 1 : count
  }, 0)

  return Math.round((percent / skills.length) * 100)
}

const findPreviousOpenedBlock = (practiceTree: PracticeTree, previousActiveBlockId: string) => {
  return practiceTree.children.find((practiceTree) => practiceTree._id === previousActiveBlockId)
}

const findPreviousOpenedMainBlock = (practiceMainTree: PracticeTree, previousActiveBlockId: string) => {
  return practiceMainTree.children.find((practiceTree) => {
    return findPreviousOpenedBlock(practiceTree, previousActiveBlockId)
  })
}

export const getPreviousActiveBlockIds: (practiceGradeTree: PracticeTree) => {
  mainBlock: PracticeTree
  currentBlockId: string
} = (practiceGradeTree) => {
  const firstMainBlock = practiceGradeTree.children[0] || null
  const previousActiveBlockId = practicePageSessionActions.lastActiveSubCategoryId.get()
  practicePageSessionActions.lastActiveSubCategoryId.remove()
  if (!previousActiveBlockId) {
    return { mainBlock: firstMainBlock, currentBlockId: firstMainBlock?.children?.[0]?._id || '' }
  }

  const previousMainTree = findPreviousOpenedMainBlock(practiceGradeTree, previousActiveBlockId)
  if (!previousMainTree) {
    return { mainBlock: firstMainBlock, currentBlockId: firstMainBlock?.children?.[0]?._id || '' }
  }

  const previousTree = findPreviousOpenedBlock(previousMainTree, previousActiveBlockId)
  if (!previousTree) {
    return {
      mainBlock: previousMainTree,
      currentBlockId: previousMainTree.children?.[0]?._id || '',
    }
  }

  return { mainBlock: previousMainTree, currentBlockId: previousTree._id }
}

const findNextItem = (
  itemsToFind: Category[] | SubCategory[] | Ability[] | PracticeTree[] | PracticeSkill[],
  currentItemId: string
) => {
  return itemsToFind[itemsToFind.findIndex((item) => item._id === currentItemId) + 1]
}

export const getCorrectGradeName = (grade: number, t: TFunction) => {
  if (grade === 0) {
    return t('zeroGradeText')
  }

  return grade
}

export const getNextPromptedSkill = ({ practiceTree, currentSkillId }: GetNextSkillArguments) => {
  const domain = practiceTree.children.find((domain) =>
    domain.children.find((standard) => standard.skills.find((skill) => skill._id === currentSkillId))
  )

  if (!domain) return

  const standard = domain.children.find((standard) => standard.skills.find((skill) => skill._id === currentSkillId))
  if (!standard) return

  const nextSkill = findNextItem(standard.skills, currentSkillId) as PracticeSkill
  if (nextSkill) return nextSkill

  const nextStandardSubtree = findNextItem(domain.children, standard._id) as PracticeTree
  const nextSkillNode = nextStandardSubtree?.skills?.[0]
  if (nextSkillNode) return nextSkillNode

  const nextDomainSubtree = findNextItem(practiceTree.children, domain._id) as PracticeTree
  return nextDomainSubtree?.children?.[0]?.skills?.[0] || practiceTree.children?.[0]?.children?.[0]?.skills?.[0]
}

export const calculateSkillProgress = (stats?: SkillStats) => {
  if (!stats) return 0
  return (stats.correct / (stats.correct + stats.wrong + stats.notAnswered)) * 100
}
