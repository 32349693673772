import React, { MouseEvent, useContext, useState } from 'react'
import styles from '../ChoiceInput/styles.module.scss'
import type { AnswerChoiceOption } from '../types'
import ChoiceVariant from '../ChoiceVariant/ChoiceVariant'
import AnswerInfoContext from '../../../../../context/answerInfoContext'
import { Context as UserSettingsContext } from '../../../../../context/userSettingsContext'
import { DEFAULT_FONT_SIZE } from '../../../../../constants/styleConstants'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../../features/Problem/models/exerciseMode'
import { getCorrectAnswerVariantDisplay } from '../../../../../helpers/problemHelper'
import { ChoiceVariantIcon } from './ChoiceVariantIcon'
import { useTextToSpeechStore } from '../../../../../features/TextToSpeech/model'
import { createGridTemplateColumns, hasLaTeXFraction } from '../helpers'
import { useUnit } from 'effector-react'
import { $toolsMode, ToolsMode } from '../../../../../features/toolsMode/model'
import clsx from 'clsx'

type ChoiceAnswerOptionsProps = {
  checkIsOptionSelected: (option: AnswerChoiceOption) => boolean
  getSelectedItemOrder?: (text: string) => number
  handleSelectAnswer: (option: AnswerChoiceOption) => void
  handleSubmitAnswer: () => void
  isSelectingDisabled: boolean
  onPress: (event: MouseEvent<HTMLDivElement>) => void
  onPressRelease: (event: MouseEvent<HTMLDivElement>) => void
  options: AnswerChoiceOption[]
  showNumberIcons: boolean
  isTranslationPending: boolean
}

const stylesVariant = {
  [ToolsMode.SIMPLE]: styles.ChoiceInputButtonsSimple,
  [ToolsMode.REGULAR]: styles.ChoiceInputButtonsRegular,
}

export const ChoiceAnswerOptions = ({
  checkIsOptionSelected,
  getSelectedItemOrder,
  handleSelectAnswer,
  isSelectingDisabled,
  onPress,
  onPressRelease,
  options,
  showNumberIcons,
  isTranslationPending,
}: ChoiceAnswerOptionsProps) => {
  const { style: statusStyle, isExamFinished } = useContext(AnswerInfoContext)
  const { state: userSettings } = useContext(UserSettingsContext)
  const toolsMode = useUnit($toolsMode)
  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)
  const [textSize, setTextSize] = useState(userSettings.textSize ?? DEFAULT_FONT_SIZE)

  const resizeAnswerVariantText = (hasOverflown: boolean) => {
    if (!hasOverflown) return

    const smallerTextSize = textSize - 1
    if (smallerTextSize < DEFAULT_FONT_SIZE) return

    setTextSize(smallerTextSize)
  }

  const optionContentMarkdown = (option: AnswerChoiceOption) => {
    if (isTranslated && option.translation && !isTranslationPending) {
      return getCorrectAnswerVariantDisplay(option.translation)
    }

    return getCorrectAnswerVariantDisplay(option.variant)
  }

  return (
    <div
      className={stylesVariant[toolsMode]}
      onMouseDown={onPress}
      onMouseUp={onPressRelease}
      style={{
        gridTemplateColumns: toolsMode === ToolsMode.SIMPLE ? createGridTemplateColumns(options.length) : undefined,
      }}
    >
      {options.map((option, index) => (
        <ChoiceVariant
          key={option.id}
          id={`${option.id}`}
          onClick={() => handleSelectAnswer(option)}
          disabled={isSelectingDisabled}
          orderIcon={
            <ChoiceVariantIcon
              option={option}
              order={index + 1}
              isSelected={checkIsOptionSelected(option)}
              showNumberIcons={showNumberIcons}
              getSelectedItemOrder={getSelectedItemOrder}
            />
          }
          content={optionContentMarkdown(option)}
          textSize={textSize}
          changeTextSize={resizeAnswerVariantText}
          hasLaTeXFraction={hasLaTeXFraction(option.variant)}
          className={clsx(styles.ChoiceInputButton, {
            [styles[statusStyle]]: !isExamMode || isExamFinished,
            [styles.Selected]: checkIsOptionSelected(option) && (isExamMode ? isExamFinished() : true),
            [styles.SelectedExamMode]: checkIsOptionSelected(option) && isExamMode && !isExamFinished(),
            [styles.LargeFont]: !hasLaTeXFraction(option.variant),
          })}
        />
      ))}
    </div>
  )
}
