import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import styles from './ExerciseProblemsInfo.module.scss'
import cardStyles from '../../../../ui/Card/Card.module.scss'
import { getRightSolvedSubProblemsCount } from '../../../../helpers/exercises/exercisesHelper'
import type { AssignmentStats } from '../../types'

type ExerciseProblemsInfoProps = {
  problemsCount: number
  testModeEnabled: boolean
  stats: AssignmentStats | null
  isExamFinished: boolean
}

const ExerciseProblemsInfo = ({ problemsCount, testModeEnabled, stats, isExamFinished }: ExerciseProblemsInfoProps) => {
  const { t } = useTranslation()

  const getCorrectProblemsCount = () => {
    if (!testModeEnabled || !stats) return 0
    return getRightSolvedSubProblemsCount(stats)
  }

  return (
    <span className={clsx(cardStyles.CardText, styles.Problems)}>
      {testModeEnabled && isExamFinished ? `${getCorrectProblemsCount()}/` : ''}
      {problemsCount}{' '}
      {testModeEnabled && isExamFinished ? t('rightAnswersText') : t('problemsText', { count: problemsCount })}
    </span>
  )
}

export default ExerciseProblemsInfo
