import { RefObject, useEffect } from 'react'

/**
 * Prevents swipe back navigation gesture on touch devices.
 *
 * This hook disable the system default swipe gesture that triggers
 * navigation to the previous page in touch devices like Android and Chromebook.
 *
 * Vertical swipes or diagonal swipes that are more vertical than horizontal are allowed
 * to keep scrolling functionality on scrollable elements.
 * @param {React.RefObject} ref - The ref of the element on which swipe gestures should be prevented.
 */
export const usePreventSwipeGesture = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const refElement = ref.current
    if (!refElement) return

    let startX = 0
    let startY = 0

    const handleTouchStart = (event: globalThis.TouchEvent) => {
      if (event.touches && event.touches[0]) {
        startX = event.touches[0].clientX
        startY = event.touches[0].clientY
      }
    }

    const handleTouchMove = (event: globalThis.TouchEvent) => {
      if (!event.cancelable) return

      if (event.touches && event.touches[0]) {
        const moveX = event.touches[0].clientX - startX
        const moveY = event.touches[0].clientY - startY

        const hasSwipedToLeft = moveX > 0
        const hasSwipedVertical = Math.abs(moveY) > Math.abs(moveX)

        if (hasSwipedToLeft && !hasSwipedVertical) {
          event.preventDefault()
        }
      }
    }

    refElement.addEventListener('touchstart', handleTouchStart, { passive: false })
    refElement.addEventListener('touchmove', handleTouchMove, { passive: false })

    return () => {
      refElement.removeEventListener('touchstart', handleTouchStart)
      refElement.removeEventListener('touchmove', handleTouchMove)
    }
  }, [ref])
}
