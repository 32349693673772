import React from 'react'
import styles from './styles.module.scss'
import { ArrowLeftIcon } from '../icons/ArrowLeft'

interface LeaveExerciseButtonProps {
  onClick: () => void
}

const LeaveExerciseButton: React.FC<LeaveExerciseButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.LeaveExerciseButton} onClick={onClick}>
      <ArrowLeftIcon />
    </button>
  )
}

export default LeaveExerciseButton
