import React, { MouseEvent } from 'react'
import styles from './styles.module.scss'

import { BackspaceIcon } from '../../../../../../ui/icons/Backspace'
import { ExerciseMode } from '../../../../../../features/Problem/models/exerciseMode'
import { ExerciseThemeColorMapper } from '../../../../../../features/Problem/problem.style.mode.helpers'
import { IconButton } from '../../../../../../ui/IconButton'

type Props = {
  exerciseMode: ExerciseMode
  isVisible: boolean
  onBackspacePress?: (event: MouseEvent) => void
}

export const BackspaceButton = ({ exerciseMode, isVisible, onBackspacePress }: Props) => {
  if (!isVisible) return null

  return (
    <IconButton variant='outlined' className={styles.BackspaceButton} onClick={onBackspacePress}>
      <BackspaceIcon fill={ExerciseThemeColorMapper[exerciseMode]} />
    </IconButton>
  )
}
