import React from 'react'
import { COLORS } from '@magmamath/ui'
import { getIconSize } from '../helpers'
import { IconProps } from '../types'

export const ProfileIcon = ({ size = 25, fill = COLORS.NEUTRAL_9 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.81161 20.6764C3.57224 17.4201 6.81206 13.3573 12.4909 13.3573C18.6955 13.3573 21.5591 17.4297 22.203 20.7021C22.466 22.039 21.3378 23.0015 20.2063 23.0015H4.77555C3.65357 23.0015 2.49434 22.0346 2.81161 20.6764ZM12.4909 15.0718C7.75183 15.0718 5.0953 18.4373 4.48119 21.0664C4.47355 21.0991 4.47667 21.1179 4.47987 21.1293C4.48366 21.1427 4.49276 21.1626 4.5143 21.186C4.56013 21.2357 4.65152 21.2869 4.77555 21.2869H20.2063C20.3299 21.2869 20.4246 21.2362 20.476 21.1813C20.5197 21.1346 20.5319 21.0903 20.5207 21.0331C20.0068 18.4213 17.7384 15.0718 12.4909 15.0718Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4909 3.71306C10.5997 3.71306 9.06186 5.24869 9.06186 7.14211C9.06186 9.06396 10.4201 10.5712 12.4855 10.5712C14.5576 10.5712 15.9296 9.05792 15.92 7.14643C15.9104 5.24573 14.3782 3.71306 12.4909 3.71306ZM7.34734 7.14211C7.34734 4.30101 9.65356 1.99854 12.4909 1.99854C15.3322 1.99854 17.6202 4.30404 17.6345 7.13779C17.6487 9.96075 15.5462 12.2857 12.4855 12.2857C9.4181 12.2857 7.34734 9.95477 7.34734 7.14211Z'
        fill={fill}
      />
    </svg>
  )
}
