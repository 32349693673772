import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './availableExercises.module.scss'

import Exercises from '../../../components/Exercises'
import InternalHeader from '../../../components/Headers/InternalHeader'
import { MagmaStats } from '../../../features/magmaStats/MagmaStats'

export const AvailableExercises = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.AvailableExercises}>
      <div className={styles.ExercisesHeader}>
        <InternalHeader title={t('exercisesText')} />
        <MagmaStats />
      </div>
      <Exercises />
    </div>
  )
}
