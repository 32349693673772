import React from 'react'
import styles from '../styles.module.scss'
import { Letters } from '../components/Letters'
import { NumpadSymbols } from '../components/NumpadSymbols'
import { NumericKeypad } from '../components/NumericKeypad'
import { ALGEBRA_BASIC, ALGEBRA_LETTERS } from '../constants/symbols'

export const AlgebraBasic = () => {
  return (
    <div className={styles.NumpadContainer}>
      <Letters letters={ALGEBRA_LETTERS} />
      <div className={styles.NumpadWrapper}>
        <NumpadSymbols symbols={ALGEBRA_BASIC} />
        <NumericKeypad />
      </div>
    </div>
  )
}
