import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export type IconButtonProps = {
  variant?:
    | 'success'
    | 'error'
    | 'warning'
    | 'exam'
    | 'outlined'
    | 'primary'
    | 'gray'
    | 'control'
    | 'practice'
    | 'unstyled'
  activeVariant?: 'active-exam' | 'active-primary' | 'active-practice'
  rounded?: 'none' | 'xs' | 'sm' | 'sl' | 'md' | 'lg' | 'full'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  isActive?: boolean
  withShadow?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export const IconButton = forwardRef(
  (
    {
      children,
      variant = 'primary',
      activeVariant = 'active-primary',
      rounded = 'xs',
      size = 'md',
      className,
      disabled,
      isActive = false,
      withShadow = false,
      ...restProps
    }: IconButtonProps,
    ref: any
  ) => {
    const isUnstyledVariant = variant === 'unstyled'

    const mergedClassNames = clsx(className, styles.IconButton, {
      [styles[variant]]: variant && !isUnstyledVariant,
      [styles[`rounded-${rounded}`]]: rounded && !isUnstyledVariant,
      [styles[`size-${size}`]]: size && !isUnstyledVariant,
      [styles.isActive]: isActive,
      [styles[activeVariant]]: activeVariant,
      [styles.BoxShadow]: withShadow,
    })

    return (
      <button ref={ref} className={mergedClassNames} disabled={disabled} {...restProps}>
        {children}
      </button>
    )
  }
)
