import { useEffect, useState } from 'react'
import { cheatDetected, tabBlurred } from '../models/cheatDetected'

export const useDetectTabChange = () => {
  const [tabLeaveTimestamps, setTabLeaveTimestamps] = useState<number[]>([])

  const resetTabChangeState = () => {
    cheatDetected(null)
    setTabLeaveTimestamps([])
  }

  useEffect(() => {
    const handleBlur = () => {
      tabBlurred()
      setTabLeaveTimestamps((prevState) => [...prevState, Date.now()])
    }

    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  return {
    tabLeaveCount: tabLeaveTimestamps.length,
    tabLeaveTimestamps,
    resetTabChangeState,
  }
}
