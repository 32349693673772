import React, { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputType } from '../../../../Problem/models/inputType'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../../../Problem/models/answer'
import { useSpreadsheetModel } from '../../../../DrawBoard/model'
import { useTextToSpeechStore } from '../../../../TextToSpeech/model'
import { Context as UserSettingsContext } from '../../../../../context/userSettingsContext'
import { checkIsSmallScreen, removeKatexPartFromTextToSpeech, translateChoiceButton } from '../../../../../helpers'
import { SelectedAnswerChoice, ChoiceProps, PracticeChoiceAnswerProps } from '../types'
import usePracticeProblemsStore, { PracticeMode } from '../../../models/practiceProblems'
import { selectedAnswersModel, useSelectedAnswers } from '../../../models/selectedAnswers'
import { transformPreviousSelectedTypeAnswer } from '../../../../../helpers/exercises/exerciseAnswerHelper'
import { AnswerType } from '../../../../Problem/types.answer'
import { useCurrentExerciseMode } from '../../../../Problem/models/exerciseMode'
import { useUnit } from 'effector-react'
import { PracticeChoiceAnswerRegular } from './variants/PracticeChoiceAnswerRegular/PracticeChoiceAnswerRegular'
import { PracticeChoiceAnswerSimple } from './variants/PracticeChoiceAnswerSimple/PracticeChoiceAnswerSimple'

const CHOICE_ANSWER_VARIANTS: Record<PracticeMode, ComponentType<PracticeChoiceAnswerProps>> = {
  [PracticeMode.REGULAR]: PracticeChoiceAnswerRegular,
  [PracticeMode.SIMPLE]: PracticeChoiceAnswerSimple,
}

const PracticeChoiceAnswer: React.FC<ChoiceProps> = ({ onSubmitAnswer, choiceType }) => {
  const { t } = useTranslation()
  const [inputType, setInputType] = useState(checkIsSmallScreen() ? InputType.KEYBOARD : InputType.MY_SCRIPT)
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const spreadsheetController = useSpreadsheetModel((state) => state.controller)
  const isActiveSpreadsheet = !!spreadsheetController
  const { isTranslated } = useTextToSpeechStore((state) => state)
  const [isTranslationCompleted, setIsTranslationCompleted] = useState(false)
  const { state: userSettings } = useContext(UserSettingsContext)
  const currentProblem = usePracticeProblemsStore((state) => state.currentProblem)
  const [answerVariants, setAnswerVariants] = useState<SelectedAnswerChoice[]>([])
  const selectedAnswers = useSelectedAnswers((state) => state.selectedAnswers)
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  const practiceMode = usePracticeProblemsStore((state) => state.practiceMode)

  const submitButtonText = t(!answerStatus ? 'replyText' : 'nextText')
  const feedbackText = t(answerStatus === ProblemAnswerStatus.SUCCESS ? 'rightFirstTryText' : 'wrongText')
  const answerStyle = !answerStatus
    ? ''
    : answerStatus === ProblemAnswerStatus.SUCCESS
    ? 'success-first-attempt'
    : 'mistake'

  useEffect(() => {
    setAnswerVariants(transformPreviousSelectedTypeAnswer(currentProblem?.answerVariants))
    selectedAnswersModel.resetSelectedAnswers()
  }, [currentProblem])

  const toggleInputType = () => {
    setInputType(inputType === InputType.MY_SCRIPT ? InputType.KEYBOARD : InputType.MY_SCRIPT)
  }

  const textToSpeechContent = useMemo(() => {
    return answerVariants.reduce(
      (result: string, { textToSpeech }: { textToSpeech: string }) =>
        textToSpeech ? removeKatexPartFromTextToSpeech(`${result} ${textToSpeech}`) : result,
      ''
    )
  }, [isTranslationCompleted, answerVariants])

  useEffect(() => {
    setIsTranslationCompleted(false)
  }, [answerVariants])

  useEffect(() => {
    if (!answerVariants || !answerVariants.length) {
      return
    }

    if (!isTranslated) {
      setIsTranslationCompleted(false)
      return
    }

    if (answerVariants.every((variant) => variant.translation)) {
      setIsTranslationCompleted(true)
      return
    }

    const translateText = async () => {
      const promises: any = []
      answerVariants.forEach((answerVariant) => {
        if (!answerVariant.translation) {
          const variantText = answerVariant.variant
          if (
            !variantText ||
            (variantText.length > 2 && variantText[0] === '$' && variantText[variantText.length - 1] === '$')
          ) {
            return
          }

          promises.push(translateChoiceButton(answerVariant, userSettings.translationLanguage))
        }
      })

      await Promise.all(promises)
      setIsTranslationCompleted(true)
    }

    translateText()
  }, [answerVariants, isTranslated, userSettings.translationLanguage])

  const isSubmitButtonDisabled = useMemo(() => {
    if (!selectedAnswers.length) {
      return true
    }

    if (choiceType === AnswerType.ORDERED_CHOICE) {
      return selectedAnswers.length !== answerVariants.length
    }

    return false
  }, [choiceType, selectedAnswers, answerVariants])

  const submitAnswer = async () => {
    const answer = selectedAnswers.map((answer) => {
      return answerVariants.find((answerVariant) => answerVariant.id === answer)?.variant || ''
    })

    await onSubmitAnswer(answer)
  }

  useEffect(() => {
    const handleEnterSubmit = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isSubmitButtonDisabled && !isActiveSpreadsheet) {
        event.preventDefault()
        submitAnswer()
      }
    }

    window.addEventListener('keydown', handleEnterSubmit, { capture: true })
    return () => {
      window.removeEventListener('keydown', handleEnterSubmit, { capture: true })
    }
  }, [isActiveSpreadsheet, isSubmitButtonDisabled, submitAnswer])

  const PracticeChoice = CHOICE_ANSWER_VARIANTS[practiceMode]
  if (!PracticeChoice) {
    console.error('No such component variant for practice choice answer')
    return null
  }

  return (
    <PracticeChoice
      textToSpeechContent={textToSpeechContent}
      isTranslated={isTranslated}
      isTranslationCompleted={isTranslationCompleted}
      inputType={inputType}
      submitButtonText={submitButtonText}
      choiceType={choiceType}
      feedbackText={feedbackText}
      answerStyle={answerStyle}
      exerciseMode={exerciseMode}
      answerStatus={answerStatus}
      answerVariants={answerVariants}
      isActiveSpreadsheet={isActiveSpreadsheet}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      setInputType={setInputType}
      submitAnswer={submitAnswer}
      toggleInputType={toggleInputType}
    />
  )
}

export default PracticeChoiceAnswer
