import React, { useState } from 'react'
import '../styles.scss'
import clsx from 'clsx'
import { SIDEBAR_LEFT_ICON_SIZE } from '../../../../constants/styleConstants'
import { COLORS } from '@magmamath/ui'

const SidebarItem = ({ onClick, text, isActive, Icon, subLinks, stroke, fill, className }) => {
  const [isHovering, setIsHovering] = useState(false)

  const iconColor = isActive || isHovering ? COLORS.PRIMARY_ORANGE : COLORS.NEUTRAL_7
  return (
    <>
      <div
        className={clsx('sidebar-item', className, {
          'sidebar-item-active': isActive,
        })}
        onClick={onClick}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className='sidebar-dropdown-header'>
          <Icon size={SIDEBAR_LEFT_ICON_SIZE} stroke={stroke ? iconColor : 'none'} fill={fill ? iconColor : 'none'} />
          <span>{text}</span>
        </div>
      </div>

      {subLinks && isActive && <ul className='sub-links-container'>{subLinks}</ul>}
    </>
  )
}

export default SidebarItem
