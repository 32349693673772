import { useEffect } from 'react'
import { drawBoardModel } from '../../../../../features/DrawBoard/model'
import { checkIsInputActiveOnDrawingArea } from '../../../../../helpers/drawingAreaHelper'
import { getArrayFromHTMLCollection } from '../../../../../helpers/problemHelper'
import { KeyboardKeys } from '../../../../../constants/keyboardKeys'

export const useOnEnterSubmit = (callback: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const onEnterPress = (event: KeyboardEvent) => {
      if (drawBoardModel.ref.inputFocused) return
      if (checkIsInputActiveOnDrawingArea(getArrayFromHTMLCollection('text-input-drawing'))) return

      if (event.key === KeyboardKeys.ENTER) {
        callback(event)
      }
    }

    document.addEventListener('keypress', onEnterPress)
    return () => document.removeEventListener('keypress', onEnterPress)
  }, [callback])
}
