import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import { GEOGEBRA } from '../../../constants/answerTypes'
import { EXAM_MODE, MISTAKE, SUCCESS, SUCCESS_FIRST_ATTEMPT } from '../../../constants/answerStatusConstants'
import { TEACHER } from '../../../constants/userTypes'
import { getUserType } from '../../../helpers/users/getUserInfo'
import AnswerInfoContext from '../../../context/answerInfoContext'
import ProblemAnswerButton from '../../../features/Problem/ProblemAnswerButton/ProblemAnswerButton'
import DrawingRequiredMessage from '../DrawingRequiredMessage'
import ToolContent from './ToolContent'
import MyScriptView from '../../../features/MyScript/MyScriptView'
import { CLEAR_ALL } from '../../../constants/mathOperationConstants'
import { InputType } from '../../../features/Problem/models/inputType'
import { SMALL_SCREEN_WIDTH } from '../../../constants/defaults'
import { transformAnswer } from '../../../helpers/exercises/answerProblemHelper'
import { checkIsInputActiveOnDrawingArea } from '../../../helpers/drawingAreaHelper'
import { getArrayFromHTMLCollection } from '../../../helpers/problemHelper'
import OfflineModeAnswerAreaIcon from '../OfflineModeAnswerAreaIcon'
import Keyboards from '../Keyboards'
import AnswerFeedback from '../../AnswerFeedback'
import clsx from 'clsx'
import { drawBoardModel, useSpreadsheetModel } from '../../../features/DrawBoard/model'
import { currentProblemAnswerModel } from '../../../features/Problem/models/answer'
import { answerAreaMyScriptModel } from '../../../features/MyScript/model'
import useInputTypeStore from '../../../features/Problem/models/inputType'
import {
  setElementInFocusedPosition,
  specialSymbolPress,
  backspaceClick,
  onTabPress,
  checkIsActiveElementInInput,
  setCaretInInputFieldOnKeyPress,
  cleanupContentEditableMathPower,
  focusOnEmptyFractionPart,
} from '../../../features/Problem/problem.answer.helpers'
import { inputTypeMyScript, inputTypeKeyboard } from '../../../features/Problem/helpers'
import { cleanLatexAnswer } from '../../../helpers/myScript/cleanLatexAnswer'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'
import { useGeogebraModel } from '../../../features/Geogebra/model'
import { useCurrentExerciseMode, ExerciseMode } from '../../../features/Problem/models/exerciseMode'
import { ProblemAnswerInput } from './ProblemAnswerInput'
import { isEnglishLanguage } from '../../../helpers/userLocale'
import { useAssignmentCredentials } from '../../../features/assignment/hooks/useAssignmentCredentials'
import { useUnit } from 'effector-react'
import { focusAndSetCaretAtEnd } from './ProblemAnswerInput/helpers'
import { CaretPosition } from './ProblemAnswerInput/components/AnswerContentEditable'
import { KeyboardKeys } from '../../../constants/keyboardKeys'
import { assignmentModel } from '../../../features/assignment/model/assignment.model'

const ProblemAnswer = (props) => {
  const { t } = useTranslation()
  const { submitButtonText, wiggleButton, examStopped, isExamFinished, showRequireDrawingMessage, offlineMode } =
    useContext(AnswerInfoContext)
  const { state: userSettings } = useContext(UserSettingsContext)
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const assignment = useUnit(assignmentModel.$assignment)

  const spreadsheetController = useSpreadsheetModel((state) => state.controller)
  const isActiveSpreadsheet = !!spreadsheetController
  //workaround for content editable because it has known issue with state change https://www.npmjs.com/package/react-contenteditable
  const spreadsheetRef = useRef(spreadsheetController)
  spreadsheetRef.current = spreadsheetController
  const isMistakeAnswerStatus = props.answerStatus === MISTAKE
  const isSuccessAnswerStatus = props.answerStatus === SUCCESS
  const isSuccessFirstAttemptAnswerStatus = props.answerStatus === SUCCESS_FIRST_ATTEMPT
  const isSuccessAnswer = isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus
  const isProblemAnswered = isSuccessAnswer || isMistakeAnswerStatus
  const inputType = useInputTypeStore((state) => state)
  const answerText = useUnit(currentProblemAnswerModel.$text)
  const isFractionCompleted = useUnit(currentProblemAnswerModel.$isFractionCompleted)
  const credits = useAssignmentCredentials()
  const [disableAnswerBeforeMyscriptResponse, setDisableAnswerBeforeMyscriptResponse] = useState(false)
  const isGeogebraAnswerType = props.answerType === GEOGEBRA
  const { exerciseMode } = useCurrentExerciseMode()
  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isCellActive = useSpreadsheetModel((state) => state.isCellActive)

  const isMyscriptDisabled =
    isGeogebraAnswerType || isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus || isExamFinished()

  const isGeogebraPointsLimitReached = useGeogebraModel((state) => state.isPointsLimitReached)
  const isProblemAnswerButtonDisabled =
    (!answerText && !(isExamMode && isExamFinished())) ||
    disableAnswerBeforeMyscriptResponse ||
    (isGeogebraAnswerType && !isGeogebraPointsLimitReached)

  useEffect(() => {
    inputType.set(userSettings.canvasType)
  }, [credits.problemId, credits.id])

  useEffect(() => {
    if (isActiveSpreadsheet) inputType.set(InputType.KEYBOARD)
  }, [isActiveSpreadsheet])

  const manageResizeScreen = useCallback(() => {
    const screenWidth = window.innerWidth
    if (screenWidth > SMALL_SCREEN_WIDTH) {
      return
    }

    if (inputTypeMyScript(inputType.value)) {
      inputType.set(InputType.KEYBOARD)
    }
  }, [inputType.value])

  useEffect(() => {
    currentProblemAnswerModel.setText(props.previousAnswer ? transformAnswer(props.previousAnswer) : '')
  }, [props.previousAnswer, credits.problemId])

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isMistakeAnswerStatus && event.key === KeyboardKeys.BACKSPACE) {
        if (answerText.length <= 1) {
          resetAnswerStatusIsMistakeOrExamMode()
          currentProblemAnswerModel.reset()
          focusAndSetCaretAtEnd()
          return
        }

        focusAndSetCaretAtEnd()
      }
    }

    document.addEventListener('keypress', onKeyPress)
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onBackspacePress)

    return () => {
      document.removeEventListener('keypress', onKeyPress)
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onBackspacePress)
    }
  })

  useEffect(() => {
    window.addEventListener('resize', manageResizeScreen)

    return () => {
      window.removeEventListener('resize', manageResizeScreen)
    }
  }, [manageResizeScreen])

  const onFocusInput = () => {
    props.onAnswerChangeStart?.()
    spreadsheetRef.current?.blur?.()
  }

  const resetAnswerStatusIsMistakeOrExamMode = () => {
    if (isMistakeAnswerStatus || isExamMode) {
      currentProblemAnswerModel.resetStatus()
    }
  }

  const submitAnswer = async () => {
    if (isExamMode && (props.answerStatus === EXAM_MODE || isExamFinished())) {
      props.onNextButtonPress()
      return
    }

    if (isMistakeAnswerStatus) {
      currentProblemAnswerModel.reset()
      return
    }

    if (isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus) {
      props.onNextButtonPress()
      return
    }

    if (!isFractionCompleted) {
      focusOnEmptyFractionPart()
      return
    }

    const editor = answerAreaMyScriptModel.currentEditor
    const isMyScriptEmpty = editor?.context.empty
    const isMyScriptUsed = isMyScriptEmpty !== undefined && !isMyScriptEmpty

    const cleanedLatex = cleanLatexAnswer(answerText)
    await props.onSubmitClick(cleanedLatex, isMyScriptUsed)
  }

  const onContentEditableChange = (e) => {
    if (isCellActive || e.key === KeyboardKeys.ENTER) return
    resetAnswerStatusIsMistakeOrExamMode()
    currentProblemAnswerModel.changeFromInput(e.target.value)
  }

  const onKeyPress = async (event) => {
    if (drawBoardModel.ref.inputFocused) return
    const inputElement = getInputElement()

    if (checkIsInputActiveOnDrawingArea(getArrayFromHTMLCollection('text-input-drawing'))) {
      return
    }

    if (!inputElement) {
      return
    }

    if (event.key === KeyboardKeys.BACKSPACE) inputElement.focus()

    if (event.key === KeyboardKeys.ENTER) {
      event.preventDefault()
      if (!answerText) {
        return
      }

      if (isAnswerPending) return

      inputElement.blur()
      await submitAnswer()

      return
    }

    if (checkIsActiveElementInInput(inputElement) || isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus) {
      return
    }

    if (isMistakeAnswerStatus || isExamMode) {
      currentProblemAnswerModel.reset()
      inputElement.focus()
      return
    }

    if (isCellActive) return null

    setCaretInInputFieldOnKeyPress(inputElement)
  }

  const onBackspacePress = (event) => {
    if (drawBoardModel.ref.inputFocused || event.key !== 'Backspace') return

    if (checkIsInputActiveOnDrawingArea(getArrayFromHTMLCollection('text-input-drawing'))) {
      return
    }

    if (isMistakeAnswerStatus) {
      const input = getInputElement()
      input && input.focus()
    }
  }

  const onSpecialSymbolPress = (value) => {
    specialSymbolPress(value, false, answerText, currentProblemAnswerModel.setText, currentProblemAnswerModel.clearText)
  }

  const getInputElement = () => {
    const isGeogebraEditorOpen = useGeogebraModel.getState().isTextEditorOpen
    if (isGeogebraEditorOpen) return null

    return document.getElementById('answer-input')
  }

  const onNumpadButtonPress = (value) => {
    setElementInFocusedPosition(
      value.toString(),
      isMistakeAnswerStatus,
      null,
      answerText,
      currentProblemAnswerModel.setText
    )
  }

  const getAnswerResultStyle = () => {
    if (!props.answerStatus) return ''
    if (isExamMode && !isExamFinished()) return 'exam-mode'
    if (isMistakeAnswerStatus) return 'mistake'
    if (isSuccessAnswerStatus) return 'success'
    return 'success-first-attempt'
  }

  const showAnswerLookingInExamMode = () => {
    return isExamMode && isExamFinished() && (isMistakeAnswerStatus || !props.answerStatus)
  }

  const isAnswerSubmittedInExamMode = exerciseMode === ExerciseMode.EXAM && props.answerStatus === EXAM_MODE

  const getAnswerInputWay = () => {
    return (
      <>
        {inputTypeMyScript(inputType.value) ? (
          <MyScriptView
            showForceDrawing={showRequireDrawingMessage}
            onRecognition={setDisableAnswerBeforeMyscriptResponse}
            characterType={props.characterType}
            isDisabled={isMyscriptDisabled}
            onPressIn={() => {
              resetAnswerStatusIsMistakeOrExamMode()
              props.onAnswerChangeStart?.()
            }}
          />
        ) : (
          <>
            {(() => {
              if (
                showRequireDrawingMessage ||
                (props.answerStatus && props.answerStatus !== EXAM_MODE) ||
                (isExamMode && examStopped && getUserType() !== TEACHER)
              ) {
                return null
              }

              return (
                <Keyboards
                  onPress={onNumpadButtonPress}
                  onSpecialSymbolPress={onSpecialSymbolPress}
                  onBackspacePress={backspaceClick}
                  characterType={props.characterType}
                  isButtonsDisabled={isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))}
                />
              )
            })()}
          </>
        )}

        <ProblemAnswerInput
          answerType={props.answerType}
          characterType={props.characterType}
          exerciseMode={exerciseMode}
          inputType={inputType.value}
          isDisabled={
            isGeogebraAnswerType ||
            (isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))) ||
            Boolean(props.isSolved) ||
            !!showRequireDrawingMessage
          }
          isPracticeMode={props.practiceMode}
          isUnitRequired={assignment?.isRequiredUnit}
          onBackspacePress={(e) => {
            backspaceClick(e)
            cleanupContentEditableMathPower()
          }}
          onChange={onContentEditableChange}
          onFocus={onFocusInput}
          onTabPress={onTabPress}
          showAnswerPreview={props.allowAnswerLooking}
          showAnswerPreviewExamMode={showAnswerLookingInExamMode()}
          styleVariant={getAnswerResultStyle()}
          value={answerText}
          initialCaretPosition={CaretPosition.END}
        />
      </>
    )
  }

  const clearAnswer = (event) => {
    event.preventDefault()

    if (inputTypeKeyboard(inputType.value)) {
      onSpecialSymbolPress(CLEAR_ALL)
      return
    }

    currentProblemAnswerModel.clearText()
    resetAnswerStatusIsMistakeOrExamMode()
  }

  return (
    <>
      {showRequireDrawingMessage && (
        <div className='handwriting-force-drawing'>
          <DrawingRequiredMessage />
        </div>
      )}
      {offlineMode ? (
        <div className='offline-mode'>
          <OfflineModeAnswerAreaIcon />
        </div>
      ) : (
        <>
          {getAnswerInputWay()}
          <div
            className={clsx('answer-buttons-container', {
              'answer-buttons-exam-mode-order': isExamMode,
            })}
          >
            <div
              className={clsx('answer-tools-and-feedback', {
                'answer-submitted-exam-mode': isAnswerSubmittedInExamMode,
              })}
            >
              {isProblemAnswered ? null : (
                <ToolContent
                  inputType={inputType.value}
                  changeInputType={inputType.toggle}
                  clearAnswer={clearAnswer}
                  isTrashButtonDisabled={isAnswerPending}
                  isInputSelectorHidden={isAnswerSubmittedInExamMode}
                />
              )}

              <AnswerFeedback className='handwriting-mobile-feedback' />
            </div>

            <div
              className={clsx('submit-answer-button', { 'exam-mode': isExamMode, 'eng-locale': isEnglishLanguage() })}
            >
              <ProblemAnswerButton
                style={getAnswerResultStyle()}
                disabled={isProblemAnswerButtonDisabled}
                onClick={submitAnswer}
                specialButtonText={!isFractionCompleted ? t('nextText') : ''}
                text={submitButtonText}
                exerciseMode={exerciseMode}
                examStopped={examStopped}
                animation={wiggleButton}
                userType={getUserType()}
                assignmentType={props.answerType}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProblemAnswer
