import React, { useContext } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

import { changeUserSettings } from '../../../helpers/users/getUserInfo'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'

import IconButton from '../../../ui/buttons/IconButton'
import { ReactComponent as PenIcon } from '../../../assets/images/userSettingsPen.svg'
import { ReactComponent as SpreadsheetIcon } from '../../../assets/images/userSettingsSpreadsheet.svg'

export enum CanvasType {
  DRAWING = 1,
  SPREADSHEET = 2,
}

const ChangeCanvasType = () => {
  const { state: userSettings, setUserCanvasType } = useContext(UserSettingsContext)
  const isDrawingTypeActive = userSettings.canvasType === CanvasType.DRAWING
  const isSpreadsheetTypeActive = userSettings.canvasType === CanvasType.SPREADSHEET

  const onCanvasTypeChange = async (canvasType: CanvasType) => {
    setUserCanvasType(canvasType)
    // @ts-ignore
    await changeUserSettings({ canvasType })
  }

  return (
    <div className={styles.ChangeCanvasTypesContainer}>
      <IconButton
        Icon={PenIcon}
        onClick={() => onCanvasTypeChange(CanvasType.DRAWING)}
        className={clsx(
          {
            [styles.ChangeCanvasButtonActive]: isDrawingTypeActive,
          },
          styles.ChangeCanvasIconButton
        )}
      />

      <IconButton
        Icon={SpreadsheetIcon}
        onClick={() => onCanvasTypeChange(CanvasType.SPREADSHEET)}
        className={clsx({ [styles.ChangeCanvasButtonActive]: isSpreadsheetTypeActive }, styles.ChangeCanvasIconButton)}
      />
    </div>
  )
}

export default ChangeCanvasType
