import React from 'react'
import clsx from 'clsx'
import styles from './descriptionImage.module.scss'
import { Image } from '../../../../components/Image/Image'
import { useLongPressDrag } from '../../../../lib/hooks/useLongPressDrag'
import { WebStartDraggingProps } from '../../types'
import { createImageSrc } from '../../../../components/Image/createImageSrc'
import { DESCRIPTION_IMAGE_ALT_FALLBACK } from '../../ExerciseDescription'

type DescriptionImageProps = {
  src: string
  cdnSrc?: string // if provided, will be used over regular src
  alt?: string
  onDragStart?: (event: React.DragEvent, src: string, options?: WebStartDraggingProps) => void
  isScaledDown: boolean
  isDraggable: boolean
}

export const DescriptionImage = ({
  src,
  cdnSrc,
  alt,
  onDragStart,
  isScaledDown,
  isDraggable,
}: DescriptionImageProps) => {
  const handleDragStart = (event: React.DragEvent) => {
    onDragStart?.(
      event,
      createImageSrc({
        src,
        cdnSrc,
        size: 'large',
      })
    )
  }

  const draggable = useLongPressDrag({
    onDragStart: handleDragStart,
    delayMs: 100,
  })

  return (
    <div
      className={styles.DescriptionImage}
      onContextMenu={(event) => event.preventDefault()}
      draggable={isDraggable}
      {...draggable.bind}
    >
      <Image
        key={src}
        alt={alt?.length ? alt : DESCRIPTION_IMAGE_ALT_FALLBACK}
        size='small'
        src={src}
        cdnSrc={cdnSrc}
        className={clsx(isScaledDown ? styles.DescImgScaledDown : styles.DescImgRegularSize)}
      />
    </div>
  )
}
