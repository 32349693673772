import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './ExerciseItem.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import type { Assignment, AssignmentSettings, AssignmentStats } from './types'
import { ExamStatuses } from './types'
import { fullscreenModeOn, lockMobileOrientation } from '../../helpers'

import UICard from '../../ui/Card/Card'
import ExerciseProgressChart from '../../components/ExerciseProgressChart'
import ExerciseTitle from './components/ExerciseTitle'
import ExerciseExamMode from './components/ExerciseExamMode'
import ExerciseDate from './components/ExerciseDate'
import ExerciseProblemsInfo from './components/ExerciseProblemsInfo'
import { showErrorToast } from '../../constants/toasts'
import { UseTranslationTFunction } from '../../locales/i18n'
import { useMatchesMobile } from '../../lib/device/hook'
import { currentExerciseMode, ExerciseMode } from '../Problem/models/exerciseMode'
import { COLORS } from '@magmamath/ui'
import { EXERCISE_CARD_TEST_ID } from '../../config/dataTestIDs'
import { getProblemsCount } from '../../helpers/exercises/exercisesHelper'
import { assignmentModel } from '../assignment/model/assignment.model'

type ExerciseItemProps = {
  exercise: Assignment
}

const pieChartData = (isShowDiagramInExamMode: boolean, t: UseTranslationTFunction, stats: AssignmentStats | null) => {
  return [
    {
      color: isShowDiagramInExamMode ? COLORS.PRIMARY_PURPLE : COLORS.PRIMARY_GREEN,
      title: isShowDiagramInExamMode ? t('chartHidden') : t('chartFirstAttempt'),
      value: stats?.firstAttempt ?? 0,
    },
    {
      color: isShowDiagramInExamMode ? COLORS.PRIMARY_PURPLE : COLORS.PRIMARY_YELLOW,
      title: isShowDiagramInExamMode ? t('chartHidden') : t('chartAfterFirstAttempt'),
      value: stats?.afterFirstAttempt ?? 0,
    },
    {
      color: isShowDiagramInExamMode ? COLORS.PRIMARY_PURPLE : COLORS.PRIMARY_RED,
      title: isShowDiagramInExamMode ? t('chartHidden') : t('chartWrong'),
      value: stats?.wrong ?? 0,
    },
    {
      color: COLORS.NEUTRAL_4,
      title: isShowDiagramInExamMode ? t('chartHidden') : t('chartNotAnswered'),
      value: stats?.notAnswered ?? 0,
    },
  ]
}

const isExamLocked = (settings: AssignmentSettings, stats: AssignmentStats | null) => {
  return (
    !settings ||
    (settings?.state === ExamStatuses.STOPPED && settings?.assessed === true) ||
    stats?.examStats?.state === ExamStatuses.FINISHED ||
    settings?.state === ExamStatuses.DISTRICT_LOCKED
  )
}

const ExerciseItem = ({ exercise }: ExerciseItemProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const isMobileDevice = useMatchesMobile()

  const isLocked = isExamLocked(exercise.settings, exercise.stats)
  const testModeEnabled = exercise.testModeEnabled
  const isCheatDetectionEnabled = exercise.cheatDetection
  const exerciseId = exercise._id

  const isShowDiagramInExamMode = testModeEnabled ? !isLocked : false
  const examStatus = exercise.stats?.examStats?.state ?? exercise.settings?.state

  const navigateToExercise = () => {
    if (!testModeEnabled || isLocked || examStatus === ExamStatuses.ONGOING) {
      currentExerciseMode.setExerciseMode(testModeEnabled ? ExerciseMode.EXAM : ExerciseMode.REGULAR)
      assignmentModel.setAssignment(exercise)
      history.push(`${history.location.pathname}/${exerciseId}`)

      fullscreenModeOn()
      return isMobileDevice && lockMobileOrientation()
    }

    if (examStatus === ExamStatuses.NOT_STARTED) {
      return showErrorToast({
        message: t('examNotStartedYetText'),
        toastId: 'examNotStartedYetText',
      })
    }

    if (examStatus === ExamStatuses.STOPPED && !exercise.settings?.assessed) {
      return showErrorToast({
        message: isCheatDetectionEnabled ? t('cheatDetection.examPausedToast') : t('examHasBeenStopped'),
        toastId: 'examHasBeenStopped',
      })
    }
  }

  return (
    <div className={styles.ExerciseContainer}>
      <UICard className={styles.Card} onClick={navigateToExercise} data-testid={EXERCISE_CARD_TEST_ID}>
        <div className={styles.PieChartContainer}>
          <div className={styles.PieChart}>
            <ExerciseProgressChart data={pieChartData(isShowDiagramInExamMode, t, exercise.stats)} />
          </div>
        </div>

        <ExerciseTitle title={exercise.name} />
        <ExerciseExamMode testModeEnabled={testModeEnabled} settings={exercise.settings} examStatus={examStatus} />
        <ExerciseProblemsInfo
          problemsCount={getProblemsCount(exercise.problems)}
          stats={exercise.stats}
          testModeEnabled={testModeEnabled}
          isExamFinished={isLocked}
        />
        <ExerciseDate createdAt={exercise.createdAt} />

        <span
          className={clsx(styles.ColoredBorderRight, {
            [styles.ExamMode]: testModeEnabled,
            [styles.NonExamMode]: !testModeEnabled,
          })}
        />
      </UICard>
    </div>
  )
}

export default ExerciseItem
