import i18next from 'i18next'

import { getPracticeHistoryDrawing, getPracticeTheory } from '../requests'
import { practiceTheoryModel } from '../models/practiceTheory'
import { showErrorToast } from '../../../constants/toasts'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'

const useLoadTheoryCallback = () => {
  const loadTheory = async (abilityId: string) => {
    practiceTheoryModel.setIsLoading(true)
    try {
      const theoryResponse = await getPracticeTheory(abilityId)
      const historyDrawingResponse = await getPracticeHistoryDrawing(theoryResponse?.historyId)
      practiceTheoryModel.setTheory(theoryResponse)
      practiceTheoryModel.setDrawingHistory(historyDrawingResponse)
    } catch (error: any) {
      if (error?.response?.status === 404) return
      showErrorToast({
        message: error.response
          ? i18next.t(
              `${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`
            )
          : error.message,
        toastId: 'loadTheoryToast',
      })
    } finally {
      practiceTheoryModel.setIsLoading(false)
    }
  }

  return { loadTheory }
}

export default useLoadTheoryCallback
