import { useEffect, RefObject } from 'react'

// TODO: rewrite to flushSync for focus management

export const useAutoFocus = (shouldAutoFocusGetter: () => boolean, ref: RefObject<HTMLElement>, delay: number = 50) => {
  useEffect(() => {
    const shouldAutoFocus = shouldAutoFocusGetter()
    if (!shouldAutoFocus) return

    const timeoutId = setTimeout(() => {
      ref.current?.focus()
    }, delay)

    return () => clearTimeout(timeoutId)
  }, [shouldAutoFocusGetter, ref, delay])
}
