import React from 'react'
import styles from './exerciseDescriptionModal.module.scss'
import DescriptionModal from '../DescriptionModal'
import { $exerciseModalOpen, setExerciseModalOpen } from '../../model'
import { useUnit } from 'effector-react'
import { DescriptionContent } from '../DescriptionContent/DescriptionContent'
import { Image } from '../../../../components/Image/Image'
import { DESCRIPTION_IMAGE_ALT_FALLBACK, DescriptionExercise } from '../../ExerciseDescription'
import { useTextToSpeechStore } from '../../../TextToSpeech/model'

type ExerciseDescriptionModalProps = {
  exercise: DescriptionExercise
  imageSrc?: string
  alt?: string
  cdnSrc?: string
  onTranslateButtonPress: () => void
  textToSpeechContent?: string
}

export const ExerciseDescriptionModal = ({
  imageSrc = '',
  alt = DESCRIPTION_IMAGE_ALT_FALLBACK,
  cdnSrc,
  exercise,
  onTranslateButtonPress,
  textToSpeechContent,
}: ExerciseDescriptionModalProps) => {
  const exerciseModalOpen = useUnit($exerciseModalOpen)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)

  const handleToggleModal = () => {
    setExerciseModalOpen(!exerciseModalOpen)
  }

  return (
    <DescriptionModal
      isOpen
      onClose={handleToggleModal}
      textToSpeechContent={textToSpeechContent}
      onGlobeIconClick={onTranslateButtonPress}
    >
      {(cdnSrc || imageSrc) && (
        <Image className={styles.ExerciseDescriptionModalImg} src={imageSrc} alt={alt} cdnSrc={cdnSrc} size='medium' />
      )}

      <DescriptionContent exercise={exercise} isTranslated={isTranslated} />
    </DescriptionModal>
  )
}
