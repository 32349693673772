import React, { forwardRef, RefObject } from 'react'
import './descriptionText.scss'
import { Textfit } from 'react-textfit'
import { useLongPressDrag } from '../../../../lib/hooks/useLongPressDrag'
import { DescriptionContent } from '../DescriptionContent/DescriptionContent'
import { DESCRIPTION_TEXT_TEST_ID } from '../../../../config/dataTestIDs'
import { useDomToImage } from '../../../../lib/hooks/useDomToImage'
import { WebStartDraggingProps } from '../../types'
import { DescriptionExercise } from '../../ExerciseDescription'
import { useTextToSpeechStore } from '../../../TextToSpeech/model'

type DescriptionTextProps = {
  onDragStart?: (event: React.DragEvent, src: string, options?: WebStartDraggingProps) => void
  fontSize: number
  exercise: DescriptionExercise
  isDraggable: boolean
  onReadyTextfit: (fontSize: number) => void
  mergeLinebreaks?: boolean
}

export const DescriptionText = forwardRef<HTMLDivElement, DescriptionTextProps>(
  ({ onDragStart, fontSize, exercise, isDraggable, onReadyTextfit, mergeLinebreaks }, ref) => {
    const isTranslated = useTextToSpeechStore((state) => state.isTranslated)

    const domImage = useDomToImage({ ref: ref as RefObject<HTMLDivElement> }, [
      exercise.description,
      exercise.richDescription,
      exercise.kaTeXDescription,
      exercise.laTeXDescription,
      isTranslated,
    ])

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
      const image = domImage.current
      if (!image) return
      onDragStart?.(event, image?.toString())
    }

    const draggable = useLongPressDrag({
      onDragStart: handleDragStart,
      delayMs: 100,
    })

    return (
      <div ref={ref} onContextMenu={(event) => event.preventDefault()} draggable={isDraggable} {...draggable.bind}>
        <div className='descriptionText' data-testid={DESCRIPTION_TEXT_TEST_ID}>
          <Textfit onReady={onReadyTextfit} min={fontSize} max={fontSize}>
            <DescriptionContent exercise={exercise} isTranslated={isTranslated} mergeLinebreaks={mergeLinebreaks} />
          </Textfit>
        </div>
      </div>
    )
  }
)
