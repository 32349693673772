import React, { useContext, useEffect, useRef, useState } from 'react'
import stylesScss from './styles.module.scss'
import { StyleSheet, View } from 'react-native'
import { COLORS } from '@magmamath/ui'
import { SimpleModeDigitsNumpad } from '../SimpleModeDigitsNumpad/SimpleModeDigitsNumpad'
import { AnswerType } from '../../../Problem/types.answer'
import useInputTypeStore, { InputType } from '../../../Problem/models/inputType'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { DrawBoardMode, drawBoardModel, useDrawBoardModel, useSpreadsheetModel } from '../../../DrawBoard/model'
import {
  focusOnEmptyFractionPart,
  getInputElement,
  onTabPress,
  setCaretInInputFieldOnKeyPress,
  setElementInFocusedPosition,
} from '../../../Problem/problem.answer.helpers'
import { ContentEditableEvent } from 'react-contenteditable'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../../Problem/models/answer'
import { ExerciseMode, useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { ProblemAnswerInput } from '../../../../components/Problem/ProblemAnswer/ProblemAnswerInput'
import { SimpleModeToolAction } from '../SimpleModeToolAction/SimpleModeToolAction'
import MyScriptView from '../../../MyScript/MyScriptView'
import ProblemAnswerButton from '../../../Problem/ProblemAnswerButton/ProblemAnswerButton'
import { getUserType } from '../../../../helpers/users/getUserInfo'
import { useTranslation } from 'react-i18next'
import { TEACHER } from '../../../../constants/userTypes'
import { getLabelText } from '../../../../components/Problem/ProblemAnswer/ProblemAnswerInput/helpers'
import { cleanLatexAnswer } from '../../../../helpers/myScript/cleanLatexAnswer'
import { usePreviousAnswer } from '../../hooks/usePreviousAnswer'
import AnswerFeedback from '../../../../components/AnswerFeedback'
import OfflineModeAnswerAreaIcon from '../../../../components/Problem/OfflineModeAnswerAreaIcon'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { useAssignmentCredentials } from '../../../assignment/hooks/useAssignmentCredentials'
import { useUnit } from 'effector-react'
import { answerAreaMyScriptModel } from '../../../MyScript/model'
import { KeyboardKeys } from '../../../../constants/keyboardKeys'

type SimpleModeAnswerProps = {
  wiggleAnimation: boolean
  answerType: AnswerType
  isLoading: boolean
  onAnswerChangeStart?: () => void
  allowAnswerLooking: boolean
  isUnitRequired: boolean
  isSolved: boolean
  onNextButtonPress: () => void
  onSubmitClick: (LaTeX: string, isMyScriptUsed: boolean) => void
  previousAnswer?: string | string[]
}

export const HandwritingAnswerSimpleMode = ({
  wiggleAnimation,
  answerType,
  isLoading,
  onAnswerChangeStart,
  allowAnswerLooking,
  isUnitRequired,
  isSolved,
  onNextButtonPress,
  onSubmitClick,
  previousAnswer,
}: SimpleModeAnswerProps) => {
  const { t } = useTranslation()
  const credits = useAssignmentCredentials()
  const controller = useSpreadsheetModel((state) => state.controller)
  const { state: userSettings } = useContext(UserSettingsContext)
  const { characterType, isExamFinished, showRequireDrawingMessage, submitButtonText, examStopped, offlineMode } =
    useContext(AnswerInfoContext)
  const isFractionCompleted = useUnit(currentProblemAnswerModel.$isFractionCompleted)
  const answerText = useUnit(currentProblemAnswerModel.$text)
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  const inputType = useInputTypeStore((state) => state.value)
  const setInputType = useInputTypeStore((state) => state.set)

  const [disableMyScriptAnswer, setDisableMyScriptAnswer] = useState(false)

  const spreadsheetController = useSpreadsheetModel((state) => state.controller)
  const spreadsheetRef = useRef(spreadsheetController)
  spreadsheetRef.current = spreadsheetController

  const isSuccessFirstAttemptAnswerStatus = answerStatus === ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT
  const isSuccessAnswerStatus = answerStatus === ProblemAnswerStatus.SUCCESS
  const isSuccessAnswer = isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus

  const isMistakeAnswerStatus = answerStatus === ProblemAnswerStatus.MISTAKE
  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isCellActive = useSpreadsheetModel((state) => state.isCellActive)
  const isSpreadsheetActive = useDrawBoardModel((state) => state.mode === DrawBoardMode.SPREADSHEET)

  const allowAnswerLookingInExamMode = isExamMode && isExamFinished() && (isMistakeAnswerStatus || !answerStatus)
  const isMyscriptDisabled = isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus || isExamFinished()
  const isSubmitButtonDisabled = (!answerText && !(isExamMode && isExamFinished())) || disableMyScriptAnswer
  const isInputDisabled =
    answerType === AnswerType.GEOGEBRA || // TODO: is this check for geogebra even needed?
    (isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))) ||
    isSolved ||
    !!showRequireDrawingMessage

  const onFocus = () => {
    onAnswerChangeStart?.()
    spreadsheetRef.current?.blur?.()
  }

  const resetAnswerStatus = () => {
    if (isMistakeAnswerStatus || isExamMode) currentProblemAnswerModel.resetStatus()
  }

  const onChange = (event: ContentEditableEvent) => {
    if (drawBoardModel.ref.inputFocused) return
    if ((event as any).key === KeyboardKeys.ENTER) return // TODO: no idea why we have this check since there is no `key` field on event or maybe there is but only on chromebooks??

    resetAnswerStatus()
    currentProblemAnswerModel.changeFromInput(event.target.value)
  }

  const handleSubmitAnswer = () => {
    const isExamStatus = answerStatus === ProblemAnswerStatus.EXAM_MODE

    if (isExamMode && (isExamStatus || isExamFinished())) {
      return onNextButtonPress()
    }

    if (isMistakeAnswerStatus) return currentProblemAnswerModel.reset()
    if (isSuccessAnswerStatus || isSuccessFirstAttemptAnswerStatus) return onNextButtonPress()
    if (!isFractionCompleted) return focusOnEmptyFractionPart()

    const editor = answerAreaMyScriptModel.currentEditor
    const isMyScriptEmpty = editor?.context.empty
    const isMyScriptUsed = isMyScriptEmpty !== undefined && !isMyScriptEmpty

    // answerEditorModel.setNumpadVisible(false)

    const cleanedLatex = cleanLatexAnswer(answerText)
    onSubmitClick(cleanedLatex, isMyScriptUsed)
  }

  const getStyleVariant = () => {
    if (!answerStatus) return ''
    if (isExamMode && !isExamFinished()) return 'exam-mode'
    if (isMistakeAnswerStatus) return 'mistake'
    if (isSuccessAnswerStatus) return 'success'
    return 'success-first-attempt'
  }

  const handleResetAnswerStatus = () => {
    const isInputFocused = document.activeElement === document.getElementById('answer-input')
    if (isInputFocused) resetAnswerStatus()
  }

  const handleFocusInputOnKeyDown = () => {
    if (isSpreadsheetActive || isCellActive) return
    setCaretInInputFieldOnKeyPress(getInputElement())
  }

  useEffect(() => {
    setInputType(userSettings.canvasType)
  }, [credits.problemId, credits.id])

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === KeyboardKeys.ENTER && answerText.length) {
        event.preventDefault()
        handleSubmitAnswer()
        return
      }

      if (event.key === KeyboardKeys.BACKSPACE) {
        handleResetAnswerStatus()
        return
      }

      handleFocusInputOnKeyDown()
    }

    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [answerText.length, handleSubmitAnswer])

  usePreviousAnswer(previousAnswer)

  const handleDigitPress = (value: string) => {
    if (isSuccessAnswerStatus) return

    if (isCellActive) {
      setCaretInInputFieldOnKeyPress(getInputElement())
    }

    if (controller?.isCellSelected) {
      return controller.updateSelected(value)
    }

    setElementInFocusedPosition(value, isMistakeAnswerStatus, null, answerText, currentProblemAnswerModel.setText)
  }

  if (offlineMode) {
    return (
      <div className='offline-mode'>
        <OfflineModeAnswerAreaIcon />
      </div>
    )
  }

  if (showRequireDrawingMessage) {
    return (
      <View style={styles.container}>
        <MyScriptView
          showForceDrawing={showRequireDrawingMessage}
          onRecognition={setDisableMyScriptAnswer}
          characterType={characterType}
          isDisabled={isMyscriptDisabled}
          forceDrawingProps={{
            message: t('drawingRequiredSimpleModeText'),
            className: stylesScss.SimpleModeDrawingRequired,
          }}
          emptyDrawingProps={{
            message: getLabelText(characterType, isUnitRequired),
            className: stylesScss.SimpleModeEmptyDrawing,
          }}
          onPressIn={() => {
            resetAnswerStatus()
            onAnswerChangeStart?.()
          }}
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.input}>
        {inputType === InputType.KEYBOARD && !isSuccessAnswer ? (
          <View style={styles.numpad}>
            <SimpleModeDigitsNumpad onDigitPress={handleDigitPress} />
          </View>
        ) : (
          <MyScriptView
            showForceDrawing={showRequireDrawingMessage}
            onRecognition={setDisableMyScriptAnswer}
            characterType={characterType}
            isDisabled={isMyscriptDisabled}
            forceDrawingProps={{
              message: t('drawingRequiredSimpleModeText'),
              className: stylesScss.SimpleModeDrawingRequired,
            }}
            emptyDrawingProps={{
              message: getLabelText(characterType, isUnitRequired),
              className: stylesScss.SimpleModeEmptyDrawing,
            }}
            onPressIn={() => {
              resetAnswerStatus()
              onAnswerChangeStart?.()
            }}
          />
        )}
      </View>

      <View style={styles.actions}>
        <ProblemAnswerInput
          answerType={answerType}
          characterType={characterType}
          exerciseMode={exerciseMode}
          inputType={inputType}
          isDisabled={isInputDisabled || isLoading}
          isPracticeMode={false}
          isUnitRequired={isUnitRequired}
          onChange={onChange}
          onFocus={onFocus}
          onTabPress={onTabPress}
          showAnswerPreview={allowAnswerLooking}
          showAnswerPreviewExamMode={allowAnswerLookingInExamMode}
          styleVariant={getStyleVariant()}
          value={answerText}
          showLabel={false}
          showBackspaceButton={false}
          classNames={{
            root: stylesScss.SimpleModeAnswerRoot,
            container: stylesScss.SimpleModeAnswerContainer,
            input: stylesScss.SimpleModeAnswerInput,
            answerPopup: stylesScss.SimpleModeAnswerPopup,
          }}
          placeholder={t('answerWithNumbers')}
        />

        {!isSuccessAnswer && (
          <SimpleModeToolAction
            isMistakeAnswerStatus={isMistakeAnswerStatus}
            isExamMode={isExamMode}
            showClearButton={!isSubmitButtonDisabled || !!answerText}
          />
        )}

        <View style={styles.submit}>
          <AnswerFeedback className={stylesScss.AnswerFeedbackText} />
          <ProblemAnswerButton
            style={getStyleVariant()}
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmitAnswer}
            specialButtonText={isFractionCompleted ? undefined : t('nextText')}
            text={submitButtonText}
            exerciseMode={exerciseMode}
            examStopped={examStopped}
            animation={wiggleAnimation}
            userType={getUserType()}
            assignmentType={answerType}
            classNames={{
              button: stylesScss.SimpleModeButton,
              content: stylesScss.SimpleModeButtonContent,
            }}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    height: 170,
    borderTopWidth: 2,
    borderTopColor: COLORS.NEUTRAL_5,
    backgroundColor: COLORS.NEUTRAL_1,
    zIndex: 1,
    minWidth: 'auto',
  },
  input: {
    flexShrink: 1,
    width: '100%',
    alignItems: 'flex-end',
    minWidth: 'auto',
  },
  numpad: {
    paddingVertical: 8,
    minWidth: 'auto',
  },
  actions: {
    paddingVertical: 8,
    paddingRight: 16,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flexShrink: 1,
    height: '100%',
    width: 'auto',
    minWidth: 'auto',
  },
  submit: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    minWidth: 'auto',
  },
})
