import { useEffect, useRef } from 'react'
import { $assignmentId } from '../model/credentials.model'

export const useOnAssignmentChange = (callback: (assignmentId?: string) => void) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    return $assignmentId.watch((assignmentId) => {
      callbackRef.current(assignmentId)
    })
  }, [])
}
