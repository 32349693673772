import { StyleSheet } from 'react-native'
import { COLORS } from '@magmamath/ui'

const createButtonVariant = (backgroundColor: string, borderColor: string) => ({
  backgroundColor,
  borderColor,
  shadowColor: borderColor,
})

export const styles = StyleSheet.create({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    borderRadius: 12,
    borderWidth: 2,
    width: '100%',
    height: '100%',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 4,
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  badge: {
    position: 'absolute',
    top: -28,
    right: -28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
    borderRadius: 20,
    shadowColor: '#333333',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 3,
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.NEUTRAL_1,
  },
  badgeText: { color: COLORS.NEUTRAL_9 },
  badgeTextWhite: { color: COLORS.NEUTRAL_1 },
  text: {
    color: COLORS.NEUTRAL_9,
    fontSize: 40,
    fontWeight: '400',
    fontFamily: 'Open Sans, sans-serif',
  },
  textWhite: {
    color: COLORS.NEUTRAL_1,
    fontSize: 40,
    fontWeight: '400',
  },
  pressed: {
    shadowOffset: { width: 0, height: 0 },
    transform: [{ translateY: 4 }],
  },
  primary: createButtonVariant(COLORS.PRIMARY_BLUE, COLORS.BLUE_6),
  primaryHover: {},
  successFirstAttempt: createButtonVariant(COLORS.PRIMARY_GREEN, COLORS.GREEN_6),
  successFirstAttemptHover: {},
  success: createButtonVariant(COLORS.PRIMARY_YELLOW, COLORS.YELLOW_6),
  successHover: {},
  error: createButtonVariant(COLORS.PRIMARY_RED, COLORS.RED_6),
  errorHover: {},
  exam: createButtonVariant(COLORS.PRIMARY_PURPLE, COLORS.PURPLE_6),
  examHover: {},
  district: createButtonVariant(COLORS.PRIMARY_ORANGE, COLORS.ORANGE_6),
  districtHover: {},
  grey: createButtonVariant(COLORS.NEUTRAL_3, COLORS.NEUTRAL_5),
  greyHover: createButtonVariant(COLORS.NEUTRAL_4, COLORS.NEUTRAL_5),
  dark: createButtonVariant(COLORS.NEUTRAL_9, COLORS.NEUTRAL_10),
  darkHover: createButtonVariant(COLORS.NEUTRAL_8, COLORS.NEUTRAL_10),
  default: createButtonVariant(COLORS.NEUTRAL_1, COLORS.NEUTRAL_4),
  defaultHover: createButtonVariant(COLORS.NEUTRAL_3, COLORS.NEUTRAL_5),
  primaryBadge: { backgroundColor: COLORS.PRIMARY_BLUE },
  successFirstAttemptBadge: { backgroundColor: COLORS.PRIMARY_GREEN },
  successBadge: { backgroundColor: COLORS.PRIMARY_YELLOW },
  errorBadge: { backgroundColor: COLORS.PRIMARY_RED },
  examBadge: { backgroundColor: COLORS.PRIMARY_PURPLE },
  districtBadge: { backgroundColor: COLORS.PRIMARY_ORANGE },
  greyBadge: { backgroundColor: COLORS.NEUTRAL_3 },
  darkBadge: { backgroundColor: COLORS.NEUTRAL_9 },
  defaultBadge: { backgroundColor: COLORS.NEUTRAL_1 },
})
