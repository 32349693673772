import React from 'react'
import { IconProps } from './_types'
import styles from './styles.module.scss'

const IconFraction = ({ color, size }: IconProps) => {
  return (
    <span className={styles.FractionIcon}>
      <span>a</span>
      <span>b</span>
    </span>
  )
}

export default IconFraction
