export enum SocketEvents {
  EXERCISE_ADDED = 'exerciseAdded',
  EXERCISE_DELETED = 'exerciseDeleted',
  EXERCISE_UPDATED = 'exerciseUpdated',
  EXERCISE_ARCHIVED = 'exerciseArchived',
  EXERCISE_PUBLISHED = 'exercisePublished',
  SOLUTION_UPDATED = 'solutionUpdated',
  STUDENT_CHEATING_UPDATED = 'studentStateUpdated',
  STATISTICS_UPDATED = 'statisticsUpdate',
  USER_DELETED = 'userDeleted',
  USER_ADDED_TO_HELP_QUEUE = 'requestAdded',
  USER_REMOVED_FROM_HELP_QUEUE = 'requestRemoved',
}
