export const TextToSpeechState = {
  ON: 'on',
  OFF: 'off',
}

export const MULTIPLE_SIGN_SPEECH = '×'
export const DIVIDE_SIGN_SPEECH = '÷'
export const SQUARE_ROOT_SPEECH = '√'
export const DEGREE_SIGN_SPEECH = '°'
export const GREATER_OR_EQUAL_SIGN_SPEECH = '≥'
export const LESS_OR_EQUAL_SIGN_SPEECH = '≤'
export const ANGLE_SIGN_SPEECH = '∠'
export const PARALLEL_SIGN_SPEECH = 'parallel'
export const APPROXIMATE_SIGN_SPEECH = '≈'
export const BACK_SIMILAR_SIGN_SPEECH = '∽'
export const SIMILAR_SIGN_SPEECH = '∼'
export const THICK_APPROXIMATE_SIGN_SPEECH = '≈'
export const NOT_EQUALS_SIGN_SPEECH = '≠'
export const DOTS_SIGN_SPEECH = '...'
export const POUNDS_SIGN_SPEECH = '£'
export const PI_SIGN_SPEECH = 'π'
export const ALPHA_SIGN_SPEECH = 'α'
export const BETA_SIGN_SPEECH = 'β'
export const GAMMA_SIGN_SPEECH = 'γ'
export const THETA_SIGN_SPEECH = 'θ'
export const LAMBDA_SIGN_SPEECH = 'λ'
export const PLUS_MINUS_SIGN_SPEECH = '±'
export const BIG_DELTA_SIGN_SPEECH = 'Δ'
export const DELTA_SIGN_SPEECH = 'δ'
export const UNDERLINE_SIGN_SPEECH = 'blank'
export const TO_THE_POWER_OF = ' to the power of '
export const TO_THE_POWER_OF_SWEDISH = ' upphöjt med '
