import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

import LeaveExerciseButton from '../../../../ui/buttons/LeaveExerciseButton'
import { isNeedToResize } from './helpers'
import { FullscreenModeButton } from '../../../Fullscreen'
import { ProgressBar } from '../../../../ui/ProgressBar'
import { IconButton, IconButtonProps } from '../../../../ui/IconButton'
import { ExerciseThemeVariantMapper } from '../../../Problem/problem.style.mode.helpers'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'

interface PracticeModeHeaderProps {
  onClick: () => void
  title: string
  problemNumber: number
  problemProgress: number
}

const PracticeModeHeader: React.FC<PracticeModeHeaderProps> = ({ onClick, title, problemProgress, problemNumber }) => {
  const { exerciseMode } = useCurrentExerciseMode()

  return (
    <div className={styles.PracticeModeHeader}>
      <div className={styles.PracticeHeaderButtons}>
        <LeaveExerciseButton onClick={onClick} />
        <div className={styles.PracticeHeaderProblemsButton}>
          <IconButton
            id='problem-number-selector'
            className={styles.ProblemNumberButton}
            variant={ExerciseThemeVariantMapper[exerciseMode] as IconButtonProps['variant']}
            rounded='sl'
          >
            <span>{problemNumber}</span>
          </IconButton>
        </div>

        <FullscreenModeButton />
      </div>
      <div className={styles.TitleContainer}>
        <span className={clsx(styles.Title, { [styles.Resized]: isNeedToResize(title) })} title={title}>
          {title}
        </span>
      </div>
      <ProgressBar progress={problemProgress} variant='practice' />
    </div>
  )
}

export default PracticeModeHeader
