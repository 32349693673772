import { GeogebraSettings } from '../Geogebra/types'
import { CalculatorType } from '../Calculators/calculatorsMap'
import { MyScriptMathCharacterTypes } from '../../constants/myScriptMathCharacterTypes'

export type DrawBoardAvailableFeatures = {
  calculator?: CalculatorType
}

type StudentStats = {
  isTeacher: boolean
  studentId: string
  stats: {
    problemId: string
    solved: number
    solvedOnFirstAttempt: number
    attempt: number
    solvedOnLast: number
    watched: boolean
    _id: string
    laTeX: string
    focalPoint: any
    scaleFactor: any
    strokeCount: number
    lastAnswer: string
    lastJiixAnswer: any[]
    initiallyCorrect: boolean
    answer: string[]
    drawing: {
      drawingData: any
      createdAt: string
      updatedAt: string
      drawingUrl: string
      geogebra: any
    }
    background: {
      url: string
    }
    answerArray: {
      drawing: string
      answer: string[]
      correctAnswer: string[]
      createdAt: string
      _id: string
      correct: boolean
    }[]
    studentId: string
    exerciseId: string
    isTeacher: boolean
  }
}

export type Assignment = {
  __v: number
  _id: string
  _links: AssignmentLinks
  allowedAnswerLooking: boolean
  archived: boolean
  cheatDetection: boolean
  classes: any[] // TODO: ask mikita
  createdAt: string
  deletedAt: string
  enabledForEditing: boolean
  endDate?: string
  groups: any[] // TODO: ask mikita
  isEnabled?: boolean
  isRequiredDrawing: boolean
  isRequiredUnit: boolean
  isSectional: boolean
  name: string
  problems: AssignmentProblem[]
  published: boolean
  settings: AssignmentSettings
  startDate: string
  stats: AssignmentStats | null
  students: AssignmentStudent[]
  teacher: AssignmentTeacher
  testModeEnabled: boolean
  updatedAt: string
  drawBoardAvailableFeatures?: DrawBoardAvailableFeatures
}

export type AssignmentProblem = {
  __v: number
  _id: string
  additionalInfo: any[] // TODO: ask mikita
  allowSimplification: boolean
  answer: string[]
  answerType: number
  answerVariants: string[]
  calculator: boolean
  centralContent: number
  cdnImage?: string
  chapterId: string
  characterType: MyScriptMathCharacterTypes
  createdAt: string
  deletedAt: string
  description: string
  drawingImageLines: number
  drawingRequired: boolean
  externalId: string
  geogebraSettings?: GeogebraSettings
  hint: string
  hintTypeId?: string
  image: string
  imageOrigin: string
  isPublic: boolean
  kaTeXDescription: string
  laTeXDescription: string
  level: number
  locale: string
  mathExpression?: string
  name: string
  order: number
  richDescription: string
  sectionId: string
  smartEvaluation: boolean
  studentStats?: StudentStats[]
  subProblems: ExerciseSubProblem[]
  unitInAnswer: boolean
  updatedAt: string
}

export type ExerciseSubProblem = {
  __v: number
  _id: string
  allowSimplification: boolean
  answer: string[]
  answerFormat: boolean
  answerType: number
  answerVariants: any[] // TODO: ask mikita
  condition: any // TODO: ask mikita
  createdAt: string
  description: string
  drawingImageLines: number
  drawingRequired: boolean
  externalId: string
  hint: string
  image: string
  imageOrigin: string
  isPublic: boolean
  laTeXDescription: string
  mainCategory: string
  name: string
  order: number
  richDescription: string
  smartEvaluation: boolean
  subCategory: string
  subProblemType: number
  unitInAnswer: boolean
  updatedAt: string
}

export type AssignmentStudent = {
  _id: string
  firstName: string
  lastName: string
}

export type AssignmentTeacher = {
  _id: string
  firstName: string
  lastName: string
}

export type AssignmentLinks = {
  self: AssignmentLink
  teacher: AssignmentLink
}

export type AssignmentLink = {
  href: string
}

export enum ExamStatuses {
  NOT_STARTED = 0,
  ONGOING = 1,
  STOPPED = 2,
  FINISHED = 3,
  DISTRICT_LOCKED = 4,
}

type ExamStats = {
  state: ExamStatuses
  cheatTime: Date[]
  _id: string
}

export type AssignmentStats = {
  __v: number
  _id: string
  afterFirstAttempt: number
  completedAmount: number
  createdAt: string
  exerciseId: string
  firstAttempt: number
  notAnswered: number
  studentId: string
  updatedAt: string
  wrong: number
  examStats: ExamStats
}

export type AssignmentSettings = {
  state: number
  assessed?: boolean
}

export type ChartData = {
  color: string
  title: string
  value: number
}
