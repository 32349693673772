import type { GeogebraProps } from './components/Geogebra'

// https://wiki.geogebra.org/en/Reference:Toolbar
export enum ToolbarTools {
  MOVE = 0,
  POINT = 1,
  JOIN = 2,
  PARALLEL = 3,
  ORTHOGONAL = 4,
  INTERSECT = 5,
  DELETE = 6,
  VECTOR = 7,
  LINE_BISECTOR = 8,
  ANGULAR_BISECTOR = 9,
  CIRCLE_TWO_POINTS = 10,
  CIRCLE_THREE_POINTS = 11,
  CONIC_FIVE_POINTS = 12,
  TANGENTS = 13,
  RELATION = 14,
  SEGMENT = 15,
  POLYGON = 16,
  TEXT = 17,
  RAY = 18,
  MIDPOINT = 19,
  CIRCLE_ARC_THREE_POINTS = 20,
  CIRCLE_SECTOR_THREE_POINTS = 21,
  CIRCUMCIRCLE_ARC_THREE_POINTS = 22,
  CIRCUMCIRCLE_SECTOR_THREE_POINTS = 23,
  SEMICIRCLE = 24,
  SLIDER = 25,
  IMAGE = 26,
  SHOW_HIDE_OBJECT = 27,
  SHOW_HIDE_LABEL = 28,
  MIRROR_AT_POINT = 29,
  MIRROR_AT_LINE = 30,
  TRANSLATE_BY_VECTOR = 31,
  ROTATE_BY_ANGLE = 32,
  DILATE_FROM_POINT = 33,
  CIRCLE_POINT_RADIUS = 34,
  COPY_VISUAL_STYLE = 35,
  ANGLE = 36,
  VECTOR_FROM_POINT = 37,
  DISTANCE = 38,
  MOVE_ROTATE = 39,
  TRANSLATEVIEW = 40,
  ZOOM_IN = 41,
  ZOOM_OUT = 42,
  SELECTION_LISTENER = 43,
  POLAR_DIAMETER = 44,
  SEGMENT_FIXED = 45,
  ANGLE_FIXED = 46,
  LOCUS = 47,
  MACRO = 48,
  AREA = 49,
  SLOPE = 50,
  REGULAR_POLYGON = 51,
  SHOW_HIDE_CHECKBOX = 52,
  COMPASSES = 53,
  MIRROR_AT_CIRCLE = 54,
  ELLIPSE_THREE_POINTS = 55,
  HYPERBOLA_THREE_POINTS = 56,
  PARABOLA = 57,
  FITLINE = 58,
  RECORD_TO_SPREADSHEET = 59,
  BUTTON_ACTION = 60,
  TEXTFIELD_ACTION = 61,
  PEN = 62,
  RIGID_POLYGON = 64,
  POLYLINE = 65,
  PROBABILITY_CALCULATOR = 66,
  ATTACH_DETACH = 67,
  FUNCTION_INSPECTOR = 68,
  INTERSECT_SURFACES = 69,
  VECTOR_POLYGON = 70,
  CREATE_LIST = 71,
  COMPLEX_NUMBER = 72,
  FREEHAND_MODE = 73,
  POINT_ON_OBJECT = 501,
}

export type AppletObject = {
  name: string
  value: string
  type: string
}

export enum ToolbarToolsNames {
  POINT = 'point',
  LINE = 'line',
}

export enum GeogebraAppNames {
  CLASSIC = 'classic',

  // NOTE: Usage of app names other than CLASSIC is restricted due to our subscription limits (we only paid for CLASSIC).
  // If there's a need to use another app, please confirm with management for proper clearance before use.
  // If the subscription is not active and you will use it - it will cause problems with "token" in localStorage
  // Geogebra request upon fail will clear the token and cause issues with app because we use this for storing user auth token

  // GRAPHING = 'graphing',
  // GEOMETRY = 'geometry',
  // '3D' = '3d',
  // SUITE = 'suite',
  // EVALUATOR = 'evaluator',
  // SCIENTIFIC = 'scientific',
  // NOTES = 'notes',
}

export type GeogebraSettings = {
  toolbarTools: Array<keyof typeof ToolbarTools>
  pointsLimit?: number
  prefilledFunction?: string
  disableSettingAnswer?: boolean
}

export enum GridTypes {
  NO_GRID = -1,
  MAJOR = 0,
  ISOMETRIC = 1,
  POLAR = 2,
  MAJOR_AND_MINOR = 3,
}

export enum SetPointCaptureMode {
  NO_CAPTURING = 0, // Points can be placed freely without snapping to any grid or object.
  SNAP_TO_GRID = 1, // Points snap to the nearest grid point but can still be moved freely.
  FIXED_TO_GRID = 2, //  Points can only be placed exactly on grid points. (only to round integer numbers)
  AUTOMATIC = 3, // Points snap to grid points, lines, or other geometric objects automatically.
}

export type ObjectRefValue = {
  name: string
  value: string
  type: Lowercase<keyof typeof ToolbarTools> | ToolbarToolsNames
}

// ** IMPORTANT **
// these types might not be 100% accurate or changed in different versions
// it is recommended to double-check with geogebra docs

type GraphicsOptions = {
  axes: {
    x: unknown
    y: unknown
    z: unknown
  }
  grid: boolean
  gridDistance: { x: number | null; y: number | null }
  bgColor: string
  axesColor: string
  gridColor: string
  gridIsBold: boolean
  gridType: GridTypes
  pointCapturing: number
  rightAngleStyle: number
  rulerType: number
}

export type AppletApiRef = {
  $ib: (args?: unknown) => unknown
  Vjb: (args?: unknown) => unknown
  addGeoToTV: (args?: unknown) => unknown
  addImage: (args?: unknown) => unknown
  addMultiuserSelection: (args?: unknown) => unknown
  asyncEvalCommand: (args?: unknown) => unknown
  asyncEvalCommandGetLabels: (args?: unknown) => unknown
  checkSaved: (args?: unknown) => unknown
  copyTextToClipboard: (args?: unknown) => unknown
  debug: (args?: unknown) => unknown
  deleteObject: (objectName: string) => void
  disableFpsMeasurement: (args?: unknown) => unknown
  enable3D: (args?: unknown) => unknown
  enableCAS: (args?: unknown) => unknown
  enableFpsMeasurement: (args?: unknown) => unknown
  enableLabelDrags: (args?: unknown) => unknown
  enableRightClick: (args?: unknown) => unknown
  enableShiftDragZoom: (args?: unknown) => unknown
  endDrawRecordingAndLogResults: (args?: unknown) => unknown
  equals: (args?: unknown) => unknown
  evalCommand: (command: string) => boolean
  evalCommandCAS: (args?: unknown) => unknown
  evalCommandGetLabels: (args?: unknown) => unknown
  evalGeoGebraCAS: (args?: unknown) => unknown
  evalLaTeX: (args?: unknown) => unknown
  evalMathML: (args?: unknown) => unknown
  evalXML: (args?: unknown) => unknown
  exists: (args?: unknown) => unknown
  exportAsymptote: (args?: unknown) => unknown
  exportCollada: (args?: unknown) => unknown
  exportConstruction: (args?: unknown) => unknown
  exportGIF: (args?: unknown) => unknown
  exportPDF: (args?: unknown) => unknown
  exportPGF: (args?: unknown) => unknown
  exportPSTricks: (args?: unknown) => unknown
  exportSVG: (args?: unknown) => unknown
  exportSimple3d: (args?: unknown) => unknown
  getActivePage: (args?: unknown) => unknown
  getAlgorithmXML: (args?: unknown) => unknown
  getAllObjectNames: () => string[]
  getAxisLabels: (args?: unknown) => unknown
  getAxisUnits: (args?: unknown) => unknown
  getBase64: () => string
  getCASObjectNumber: (args?: unknown) => unknown
  getCaption: (args?: unknown) => unknown
  getColor: (args?: unknown) => unknown
  getCommandString: (args?: unknown) => unknown
  getConstructionSteps: (args?: unknown) => unknown
  getDefinitionString: (args?: unknown) => unknown
  getEditorState: (args?: unknown) => unknown
  getEmbeddedCalculators: (args?: unknown) => unknown
  getExerciseFraction: (args?: unknown) => unknown
  getFileJSON: (args?: unknown) => unknown
  getFilling: (args?: unknown) => unknown
  getFrame: (args?: unknown) => unknown
  getFrameRate: (args?: unknown) => unknown
  getGraphicsOptions: (viewId: number) => GraphicsOptions
  getGridVisible: (args?: unknown) => unknown
  getImageFileName: (args?: unknown) => unknown
  getInputBoxState: (args?: unknown) => unknown
  getLaTeXBase64: (args?: unknown) => unknown
  getLaTeXString: (args?: unknown) => unknown
  getLabelStyle: (args?: unknown) => unknown
  getLabelVisible: (args?: unknown) => unknown
  getLayer: (args?: unknown) => unknown
  getLineStyle: (args?: unknown) => unknown
  getLineThickness: (args?: unknown) => unknown
  getListValue: (args?: unknown) => unknown
  getMode: () => ToolbarTools
  getObjectName: (args?: unknown) => unknown
  getObjectNumber: (args?: unknown) => unknown
  getObjectType: (objectName: string) => Lowercase<keyof typeof ToolbarTools> | ToolbarToolsNames
  getObjectsOfItsGroup: (args?: unknown) => unknown
  getOrdering: (args?: unknown) => unknown
  getPNGBase64: (exportScale: number, isTransparent: boolean, DPI: number | string) => string
  getPageContent: (args?: unknown) => unknown
  getPages: (args?: unknown) => unknown
  getPenColor: (args?: unknown) => unknown
  getPenSize: (args?: unknown) => unknown
  getPerspectiveXML: (args?: unknown) => unknown
  getPointSize: (args?: unknown) => unknown
  getPointStyle: (args?: unknown) => unknown
  getRounding: (args?: unknown) => unknown
  getScreenReaderOutput: (args?: unknown) => unknown
  getScreenshotBase64: (args?: unknown) => unknown
  getSiblingObjectNames: (args?: unknown) => unknown
  getStyleXML: (args?: unknown) => unknown
  getThumbnailBase64: (args?: unknown) => unknown
  getToolName: (args?: unknown) => unknown
  getValue: (objectName: string) => number
  getValueString: (objectName: string) => string
  getVersion: () => string
  getViewProperties: (args?: unknown) => unknown
  getVisible: (args?: unknown) => unknown
  getXML: (args?: unknown) => unknown
  getXcoord: (args?: unknown) => unknown
  getYcoord: (args?: unknown) => unknown
  getZcoord: (args?: unknown) => unknown
  groupObjects: (args?: unknown) => unknown
  handlePageAction: (args?: unknown) => unknown
  hasUnlabeledPredecessors: (args?: unknown) => unknown
  hashCode: (args?: unknown) => unknown
  hideCursorWhenDragging: (args?: unknown) => unknown
  initCAS: (args?: unknown) => unknown
  insertImage: (args?: unknown) => unknown
  isAnimating: (args?: unknown) => unknown
  isAnimationRunning: (args?: unknown) => unknown
  isDefined: (args?: unknown) => unknown
  isExercise: (args?: unknown) => unknown
  isFixed: (args?: unknown) => unknown
  isIndependent: (args?: unknown) => unknown
  isMoveable: (args?: unknown) => unknown
  isSaved: (args?: unknown) => unknown
  isSelectionAllowed: (args?: unknown) => unknown
  isTracing: (args?: unknown) => unknown
  lockTextElement: (args?: unknown) => unknown
  login: (args?: unknown) => unknown
  logout: (args?: unknown) => unknown
  newConstruction: (args?: unknown) => unknown
  nextConstructionStep: (args?: unknown) => unknown
  openFile: (args?: unknown) => unknown
  openMaterial: (args?: unknown) => unknown
  previewRefresh: (args?: unknown) => unknown
  previousConstructionStep: (args?: unknown) => unknown
  recalculateEnvironments: (args?: unknown) => unknown
  redo: (args?: unknown) => unknown
  refreshViews: (args?: unknown) => unknown
  registerAddListener: (listener: (event: string) => void) => void
  registerClearListener: (listener: (event: string) => void) => void
  registerClickListener: (listener: (event: string) => void) => void
  registerClientListener: (listener: (event: string) => void) => void
  registerObjectClickListener: (listener: (event: string) => void) => void
  registerObjectUpdateListener: (listener: (event: string) => void) => void
  registerRemoveListener: (listener: (event: string) => void) => void
  registerRenameListener: (listener: (event: string) => void) => void
  registerStoreUndoListener: (listener: (event: string) => void) => void
  registerUpdateListener: (listener: (event: string) => void) => void
  remove: (args?: unknown) => unknown
  removeGeoFromTV: (args?: unknown) => unknown
  removeMultiuserSelections: (args?: unknown) => unknown
  renameObject: (args?: unknown) => unknown
  reset: (args?: unknown) => unknown
  resetAfterSaveLoginCallbacks: (args?: unknown) => unknown
  selectPage: (args?: unknown) => unknown
  setAlgebraOptions: (args?: unknown) => unknown
  setAnimating: (args?: unknown) => unknown
  setAnimationSpeed: (args?: unknown) => unknown
  setAuxiliary: (args?: unknown) => unknown
  setAxesVisible: (args?: unknown) => unknown
  setAxisLabels: (args?: unknown) => unknown
  setAxisSteps: (viewId: number, xAxis: number, yAxis: number, zAxis: number) => void
  setAxisUnits: (args?: unknown) => unknown
  setBase64: (args?: unknown) => unknown
  setCaption: (args?: unknown) => unknown
  setColor: (args?: unknown) => unknown
  setConstructionStep: (args?: unknown) => unknown
  setCoordSystem: (args?: unknown) => unknown
  setCoords: (args?: unknown) => unknown
  setCorner: (args?: unknown) => unknown
  setCustomToolBar: (args?: unknown) => unknown
  setDisplayStyle: (args?: unknown) => unknown
  setEditorState: (args?: unknown) => unknown
  setErrorDialogsActive: (args?: unknown) => unknown
  setExternalPath: (args?: unknown) => unknown
  setFileJSON: (args?: unknown) => unknown
  setFilling: (args?: unknown) => unknown
  setFixed: (objectName: string, isFixed: boolean, isSelectionAllowed: boolean) => void
  setFont: (args?: unknown) => unknown
  setGlobalOptions: (args?: unknown) => unknown
  setGraphicsOptions: (viewId: number, options: Partial<GraphicsOptions>) => void
  setGridVisible: (args?: unknown) => unknown
  setHeight: (args?: unknown) => unknown
  setInputBoxState: (args?: unknown) => unknown
  setLabelStyle: (args?: unknown) => unknown
  setLabelVisible: (args?: unknown) => unknown
  setLanguage: (args?: unknown) => unknown
  setLayer: (args?: unknown) => unknown
  setLayerVisible: (args?: unknown) => unknown
  setLineStyle: (args?: unknown) => unknown
  setLineThickness: (args?: unknown) => unknown
  setListValue: (args?: unknown) => unknown
  setMode: (mode: ToolbarTools) => void
  setOnTheFlyPointCreationActive: (args?: unknown) => unknown
  setPageContent: (args?: unknown) => unknown
  setPenColor: (args?: unknown) => unknown
  setPenSize: (args?: unknown) => unknown
  setPerspective: (perspective: string) => void
  setPointCapture: (viewId: number, mode: SetPointCaptureMode) => void
  setPointSize: (args?: unknown) => unknown
  setPointStyle: (args?: unknown) => unknown
  setRepaintingActive: (args?: unknown) => unknown
  setRounding: (args?: unknown) => unknown
  setSaved: (args?: unknown) => unknown
  setSize: (width: number, height: number) => void
  setTextValue: (args?: unknown) => unknown
  setTrace: (args?: unknown) => unknown
  setUndoPoint: (args?: unknown) => unknown
  setValue: (args?: unknown) => unknown
  setValuesOfTV: (args?: unknown) => unknown
  setVisible: (args?: unknown) => unknown
  setWidth: (args?: unknown) => unknown
  setXML: (args?: unknown) => unknown
  showAlgebraInput: (args?: unknown) => unknown
  showMenuBar: (args?: unknown) => unknown
  showPointsTV: (args?: unknown) => unknown
  showResetIcon: (args?: unknown) => unknown
  showToolBar: (args?: unknown) => unknown
  showTooltip: (args?: unknown) => unknown
  startAnimation: (args?: unknown) => unknown
  startDrawRecording: (args?: unknown) => unknown
  startSaveCallback: (args?: unknown) => unknown
  stopAnimation: (args?: unknown) => unknown
  switchCalculator: (args?: unknown) => unknown
  testDraw: (args?: unknown) => unknown
  toString: (args?: unknown) => unknown
  translate: (args?: unknown) => unknown
  undo: (args?: unknown) => unknown
  ungroupObjects: (args?: unknown) => unknown
  unlockTextElement: (args?: unknown) => unknown
  unregisterAddListener: (args?: unknown) => unknown
  unregisterClearListener: (args?: unknown) => unknown
  unregisterClickListener: (args?: unknown) => unknown
  unregisterClientListener: (args?: unknown) => unknown
  unregisterObjectClickListener: (args?: unknown) => unknown
  unregisterObjectUpdateListener: (args?: unknown) => unknown
  unregisterRemoveListener: (args?: unknown) => unknown
  unregisterRenameListener: (args?: unknown) => unknown
  unregisterStoreUndoListener: (args?: unknown) => unknown
  unregisterUpdateListener: (args?: unknown) => unknown
  updateConstruction: (args?: unknown) => unknown
  updateOrdering: (args?: unknown) => unknown
  we: (args?: unknown) => unknown
  writePNGtoFile: (args?: unknown) => unknown
  xe: (args?: unknown) => unknown
  ye: (args?: unknown) => unknown
  _ib: (args?: unknown) => unknown
}

type GGBAppletReturnType = {
  getAppletObject: unknown
  getHTML5CodebaseVersion: unknown
  getLoadedAppletType: unknown
  getParameters: unknown
  getViews: () => Record<string, boolean>
  inject: (id: string) => void
  isCompiledInstalled: unknown
  isHTML5Installed: unknown
  isJavaInstalled: unknown
  onExitFullscreen: unknown
  refreshHitPoints: unknown
  removeExistingApplet: unknown
  resize: unknown
  setFontsCSSURL: unknown
  setGiacJSURL: unknown
  setHTML5Codebase: unknown
  setHTML5CodebaseVersion: unknown
  setJNLPBaseDir: unknown
  setJNLPFile: unknown
  setJavaCodebase: unknown
  setJavaCodebaseVersion: unknown
  setPreCompiledResourcePath: unknown
  setPreCompiledScriptPath: unknown
  setPreviewImage: unknown
  startAnimation: unknown
  stopAnimation: unknown
}

export type GGBAppletConstructor = {
  // https://wiki.geogebra.org/en/Reference:GeoGebra_App_Parameters
  new (appProps: Partial<GeogebraProps>, geogebraAppVersion: boolean | string): GGBAppletReturnType
}
