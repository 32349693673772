import { practiceLoadingModel } from '../models/practiceLoading'
import { createNewAttempt } from '../requests'
import { PracticeProblem } from '../../Problem/types'
import { Ability } from '../types'
import { showErrorToast } from '../../../constants/toasts'
import i18next from 'i18next'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'
import usePracticeProblemsStore from '../models/practiceProblems'
import usePracticeStore from '../models/practice'

export const useLoadAbilityCallback = () => {
  const { setId, setProblemsList, setCurrentProblemIndex, resetRightAnswersCount } = usePracticeProblemsStore()
  const { setOpenedAbilityInfo } = usePracticeStore()
  const loadAbility = async (ability: Ability) => {
    practiceLoadingModel.setLoadingAbility(true)
    try {
      const response = await createNewAttempt(ability._id)
      setOpenedAbilityInfo(ability)
      setId(response._id)
      setProblemsList(response.problems.map((problem) => ({ ...problem, isSubmittedInCurrentSession: false })))
      setCurrentProblemIndex(0)
    } catch (error: any) {
      showErrorToast({
        message: error.response
          ? i18next.t(
              `${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`
            )
          : error.message,
        toastId: error.response ? error.response.data.code : error.message,
      })
    } finally {
      practiceLoadingModel.setLoadingAbility(false)
      resetRightAnswersCount()
    }
  }

  return { loadAbility }
}
