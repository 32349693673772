import { MyScriptImageExportFormat } from '../Problem/helpers'
import { Editor, TJIIXExport } from 'iink-ts'

export enum ExportType {
  LATEXT = 'application/x-latex',
  JIIX = 'application/vnd.myscript.jiix',
  IMAGE = 'image/png',
}

export type MyScriptExportResult = {
  detail: {
    [ExportType.LATEXT]?: string
    [ExportType.JIIX]?: TJIIXExport & { expressions?: TJIIXExport['elements'] }
    [ExportType.IMAGE]?: string
  } | null
  target: {
    editor: Editor
  }
} & Event

export type GetMyScriptImageSettings = {
  format?: MyScriptImageExportFormat
}
