import React, { FC } from 'react'
import { POWER_CUBE, POWER_SQUARE } from '../../../constants/mathOperationConstants'
import { Unit } from '../types'

interface UnitsProps {
  units: Unit[]
  isButtonDisabled?: boolean
  onPress?: (unit: string) => void
}

const MATH_POWER_MAP = {
  2: POWER_SQUARE,
  3: POWER_CUBE,
}

const getMathPowerString = (value: number | undefined) => {
  if (!value) return ''
  return MATH_POWER_MAP[value as keyof typeof MATH_POWER_MAP] || ''
}

const Units: FC<UnitsProps> = ({ units, isButtonDisabled, onPress }) => {
  return (
    <div className='units-container'>
      {units
        ? units.map(({ symbol, mathPower }) => (
            <button
              key={symbol}
              className='units-row-button'
              onClick={() => onPress?.(`${symbol}${getMathPowerString(mathPower)}`)}
              disabled={isButtonDisabled}
            >
              {symbol}
              {mathPower && <sup>{mathPower}</sup>}
            </button>
          ))
        : null}
    </div>
  )
}

export default Units
