import React, { useContext } from 'react'
import styles from './styles.module.scss'
import { NumpadButton } from '../NumpadButton'
import { CharTypeNumpadContext } from '../../'
import { ROWS_COUNT } from '../../constants/common'

type LettersProps = {
  letters: string[]
  isUppercase?: boolean
}

export const Letters = ({ letters, isUppercase = false }: LettersProps) => {
  const { onClick } = useContext(CharTypeNumpadContext)
  return (
    <div
      className={styles.Letters}
      style={{ gridTemplateColumns: `repeat(${Math.round(letters.length / ROWS_COUNT)}, minmax(30px, 50px))` }}
    >
      {letters.map((letter) => (
        <NumpadButton key={letter} className={styles.Italic} onClick={() => onClick?.(letter)}>
          {letter}
        </NumpadButton>
      ))}
    </div>
  )
}
