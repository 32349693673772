export const GET_EXERCISES = 'GET_EXERCISES'
export const GET_EXERCISE = 'GET_EXERCISE'
export const UPDATE_CHEATING_STATUS = 'UPDATE_CHEATING_STATUS'

export const SET_SHOW_SIDEBAR = 'SET_SHOW_SIDEBAR'
export const SET_HIDE_SIDEBAR = 'SET_HIDE_SIDEBAR'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
export const SET_HIDDEN_ON_EXERCISE_PAGE = 'SET_HIDDEN_ON_EXERCISE_PAGE'
export const REMOVE_HIDDEN_ON_EXERCISE_PAGE = 'REMOVE_HIDDEN_ON_EXERCISE_PAGE'

export const CLEAR_CONTEXT = 'CLEAR_CONTEXT'

export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const ENABLE_TEXT_TO_SPEECH = 'ENABLE_TEXT_TO_SPEECH'
export const DISABLE_TEXT_TO_SPEECH = 'DISABLE_TEXT_TO_SPEECH'
export const CHANGE_TRANSLATION_LANGUAGE = 'CHANGE_TRANSLATION_LANGUAGE'
export const CHANGE_TEXT_SIZE = 'CHANGE_TEXT_SIZE'
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_USER_CANVAS_TYPE = 'SET_USER_CANVAS_TYPE'
