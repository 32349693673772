import React, { useContext, useEffect } from 'react'
import styles from './exercisesPage.module.scss'

import { Context as SidebarContext } from '../../context/sidebarContext'
import CustomSidebar from '../../components/CustomSidebar'
import { AvailableExercises } from './AvailableExercises/AvailableExercises'
import { closeAssignmentPopup } from '../../features/assignmentPopups/model'
import { assignmentModel } from '../../features/assignment/model/assignment.model'
import { useProblemStore } from '../../features/Problem/models/model'
import { resetAssignmentCredentials } from '../../features/assignment/model/credentials.model'

export const ExercisesPage = () => {
  const { state } = useContext(SidebarContext)
  const setCurrentProblemIndex = useProblemStore((state) => state.setCurrentProblemIndex)

  useEffect(() => {
    resetAssignmentCredentials()
    closeAssignmentPopup()
    assignmentModel.reset()
    setCurrentProblemIndex(0)
  }, [])

  return (
    <div className={styles.ExercisesPage}>
      <CustomSidebar hiddenOnExercisePage={state.hiddenOnExercisePage} />
      <AvailableExercises />
    </div>
  )
}
