import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Ability, Category, PracticeStatistics } from '../types'

export type PracticeStates = {
  categories: Category[]
  filteredCategoriesByGrade: Category[]
  openedCategory: Category | null
  currentCategoryId: string
  statistics: PracticeStatistics
  openedAbilityInfo: Ability
  nextAbilityInfo: Ability
  availableGrades: number[]
}

export type PracticeModel = PracticeStates & {
  setCategories: (categories: Category[]) => void
  setFilteredCategoriesByGrade: (categories: Category[]) => void
  setOpenedCategory: (category: Category) => void
  setCurrentCategoryId: (categoryId: string) => void
  setStatistics: (statistics: any) => void
  setOpenedAbilityInfo: (ability: Ability) => void
  setNextAbilityInfo: (ability: Ability) => void
}

const initialState: PracticeStates = {
  categories: [],
  filteredCategoriesByGrade: [],
  openedCategory: null,
  currentCategoryId: '',
  statistics: {},
  openedAbilityInfo: { grades: [0], order: 0, primaryName: '', _id: '' },
  nextAbilityInfo: { grades: [0], order: 0, primaryName: '', _id: '' },
  availableGrades: [],
}

const usePracticeStore = create(
  immer<PracticeModel>((set) => ({
    ...initialState,
    setCategories: (categories: Category[]) =>
      set((state: PracticeModel) => {
        state.categories = categories
      }),
    setFilteredCategoriesByGrade: (categories: Category[]) =>
      set((state: PracticeModel) => {
        state.filteredCategoriesByGrade = categories
      }),
    setOpenedCategory: (category: Category) =>
      set((state: PracticeModel) => {
        state.openedCategory = category
      }),
    setCurrentCategoryId: (categoryId: string) =>
      set((state: PracticeModel) => {
        state.currentCategoryId = categoryId
      }),
    setStatistics: (statistics: any) =>
      set((state: PracticeModel) => {
        const newStatistics = { ...state.statistics }
        statistics.forEach((statistic: any) => {
          Object.keys(statistic).forEach((key) => {
            const lastStat = statistic[key]

            if (newStatistics[key]) {
              newStatistics[key] = lastStat
              return
            }

            Object.assign(newStatistics, { [key]: lastStat })
          })
        })

        state.statistics = { ...newStatistics }
      }),
    setOpenedAbilityInfo: (ability: Ability) =>
      set((state: PracticeModel) => {
        state.openedAbilityInfo = ability
      }),
    setNextAbilityInfo: (ability: Ability) =>
      set((state: PracticeModel) => {
        state.nextAbilityInfo = ability
      }),
  }))
)

export default usePracticeStore
