import { createEvent, createStore } from 'effector'
import type { QueuedAnswer } from './offlineQueue.types'

const OFFLINE_QUEUE = 'offlineQueue'

const addedToOfflineQueue = createEvent<Omit<QueuedAnswer, 'id'>>()
const removedFromOfflineQueue = createEvent<string>()
const resetOfflineQueue = createEvent()

const $offlineQueue = createStore<QueuedAnswer[]>([])

$offlineQueue.on(addedToOfflineQueue, (state, payload) => [
  ...state,
  { ...payload, createdAt: Date.now(), id: crypto.randomUUID() },
])
$offlineQueue.on(removedFromOfflineQueue, (state, idToRemove) => state.filter(({ id }) => id !== idToRemove))
$offlineQueue.reset(resetOfflineQueue)

export { $offlineQueue, addedToOfflineQueue, removedFromOfflineQueue, resetOfflineQueue, OFFLINE_QUEUE }
