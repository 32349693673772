import screenfull from 'screenfull'
import Bowser from 'bowser'
import axios from 'axios'

import history from '../history'
import { DRAW_BOARD_IMAGE_ERROR } from '../constants/customErrorMessages'
import {
  COMPARE_APP_VERSION_TIME,
  LINE_BREAK_SYMBOL,
  SMALL_SCREEN_WIDTH,
  SPECIAL_PLACE_NOT_TRANSLATE,
} from '../constants/defaults'
import {
  ALPHA_COMMAND,
  AND_COMMAND,
  ANGLE_SIGN,
  APPROXIMATE_COMMAND,
  BACK_SIMILAR_COMMAND,
  BEGIN_END_COMMAND,
  BETA_COMMAND,
  BIG_COMMAND,
  BIG_DELTA_COMMAND,
  CARET_DEGREE_SIGN,
  COLOR_COMMAND,
  COMMA_SEPARATOR_COMMAND,
  DEGREE_SIGN,
  DELTA_COMMAND,
  DIVIDE_COMMAND,
  DOTS_COMMAND,
  EN_SPACE_COMMAND,
  EXTRA_SQUARE_ROOT,
  FOOT_NOTE_SIZE_COMMAND,
  FRACTION_SIGN,
  GAMMA_COMMAND,
  GREATER_OR_EQUAL,
  HUGE_COMMAND,
  LAMBDA_COMMAND,
  LARGE_COMMAND,
  LEFT_COMMAND,
  LESS_OR_EQUAL,
  MED_SPACE_COMMAND,
  MIDDLE_COMMAND,
  MULTIPLE_COMMAND,
  NO_BREAK_SPACE_COMMAND,
  NORMAL_SIZE_COMMAND,
  NOT_EQUALS_COMMAND,
  PARALLEL_SIGN,
  PI_COMMAND,
  PLUS_MINUS_COMMAND,
  POUNDS_COMMAND,
  POWER_OF_COMMAND,
  QUAD_COMMAND,
  RIGHT_COMMAND,
  SIMILAR_COMMAND,
  SMALL_COMMAND,
  SPACE_COMMAND,
  SQUARE_ROOT,
  THETA_COMMAND,
  THICK_APPROXIMATE_COMMAND,
  THICK_SPACE_COMMAND,
  THIN_SPACE_COMMAND,
  TIMES_COMMAND,
  TINY_COMMAND,
  UNDERLINE_COMMAND,
  HSPACE_COMMAND,
  CENTER_EQUATION_COMMAND,
  VAR_DELTA_COMMAND,
} from '../constants/katexConstants'
import {
  ALPHA_SIGN_SPEECH,
  ANGLE_SIGN_SPEECH,
  APPROXIMATE_SIGN_SPEECH,
  BACK_SIMILAR_SIGN_SPEECH,
  BETA_SIGN_SPEECH,
  BIG_DELTA_SIGN_SPEECH,
  CARET_DEGREE_SPEECH,
  DEGREE_SIGN_SPEECH,
  DELTA_SIGN_SPEECH,
  DIVIDE_SIGN_SPEECH,
  DOTS_SIGN_SPEECH,
  GAMMA_SIGN_SPEECH,
  GREATER_OR_EQUAL_SIGN_SPEECH,
  LAMBDA_SIGN_SPEECH,
  LESS_OR_EQUAL_SIGN_SPEECH,
  MULTIPLE_SIGN_SPEECH,
  NOT_EQUALS_SIGN_SPEECH,
  PARALLEL_SIGN_SPEECH,
  PI_SIGN_SPEECH,
  PLUS_MINUS_SIGN_SPEECH,
  POUNDS_SIGN_SPEECH,
  SIMILAR_SIGN_SPEECH,
  SQUARE_ROOT_SPEECH,
  THETA_SIGN_SPEECH,
  THICK_APPROXIMATE_SIGN_SPEECH,
  TO_THE_POWER_OF,
  TO_THE_POWER_OF_SWEDISH,
  UNDERLINE_SIGN_SPEECH,
} from '../constants/textToSpeechConstants'
import { logglyMessage } from '../constants/logglyMessageTypes'
import { getUsername } from './users/getUserInfo'
import { makeApiCall } from './apiCallHelper'
import { showReloadPageToast } from '../constants/toasts'
import { translateProblemInfo } from '../features/assignments/requests'
import { getTranslatedText } from '../lib/translation/helpers'
import logger from '../logger'
import { nativeLanguageISOCodeMap } from '../config/allLanguages'
import { RoutesPaths } from '../containers/paths'

export const redirectToHomePage = () => {
  history.push(RoutesPaths.EXERCISES)
}

export const checkIsSmallScreen = () => {
  return window.matchMedia(`(max-width: ${SMALL_SCREEN_WIDTH}px)`).matches
}

export const getCurrentUrl = () => {
  return window.location?.href
}

export const getBrowserInfo = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  return browser.getBrowser()
}

const compareApplicationVersion = async () => {
  const url = `${getCorrespondingStudentsURL()}/version.js`
  const getVersionFile = async () => {
    const response = await axios.get(url)
    return response.data
  }

  const versionResponse = await makeApiCall(getVersionFile)
  if (!versionResponse) {
    return true
  }

  const version = versionResponse.split('=')[1]?.replace(/\s/g, '')?.replace(/'/g, '')
  return window.studentsWebVersion !== version
}

const showReloadPagePopup = async () => {
  const showReloadPopup = await compareApplicationVersion()
  showReloadPopup && showReloadPageToast({ toastId: new Date().getTime() })
  setTimeout(showReloadPagePopup, COMPARE_APP_VERSION_TIME)
}

export const setIntervalToCompareVersionsAndShowReloadPopup = () => {
  setTimeout(showReloadPagePopup, 0)
}

export const writeLogg = (error) => {
  const loggBody = {
    level: logglyMessage.error,
    message: error.message,
    browserName: getBrowserInfo().name,
    browserVersion: getBrowserInfo().version,
    studentsWebVersion: window._env_.STUDENTS_WEB_VERSION,
    currentURL: getCurrentUrl(),
    username: getUsername(),
  }

  const errorResponse = error.response
  const requestMethod = errorResponse?.config?.method
  const requestUrl = errorResponse?.request?.responseURL
  requestMethod && (loggBody.requestMethod = requestMethod)
  requestUrl && (loggBody.requestUrl = requestUrl)

  logger.push(loggBody)
}

export const writeMyscriptLogg = (error) => {
  const loggBody = {
    level: logglyMessage.error,
    type: error?.detail?.type,
    message: error?.detail?.message,
    code: error?.detail?.code,
    browserName: getBrowserInfo().name,
    browserVersion: getBrowserInfo().version,
    studentsWebVersion: window._env_.STUDENTS_WEB_VERSION,
    currentURL: getCurrentUrl(),
    username: getUsername(),
  }

  logger.push(loggBody)
}

export const writeDrawBoardLogg = (error) => {
  const loggBody = {
    level: logglyMessage.error,
    type: error?.type,
    message: error?.message || DRAW_BOARD_IMAGE_ERROR,
    browserName: getBrowserInfo().name,
    browserVersion: getBrowserInfo().version,
    studentsWebVersion: window._env_.STUDENTS_WEB_VERSION,
    currentURL: getCurrentUrl(),
    username: getUsername(),
  }

  logger.push(loggBody)
}

export const fullscreenModeOn = () => {
  if (process.env.NODE_ENV === 'development') return

  if (checkIOSDevice()) {
    return
  }

  screenfull.isEnabled && screenfull.request()
}

export const fullscreenModeOff = () => {
  if (checkIOSDevice()) {
    return
  }

  screenfull.isFullscreen && screenfull.exit()
}

export const checkIOSDevice = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const getIsIpadOrIphoneDevice = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
  const isIpadOS = navigator?.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
  return isIOS || isIpadOS
}

export const IS_IPAD_OR_IPHONE_DEVICE = getIsIpadOrIphoneDevice()

export const getCorrespondingTeachersURL = () => {
  return window.location.origin.includes('.com') ? window._env_.TEACHERS_INTERNATIONAL_URL : window._env_.TEACHERS_URL
}

export const getCorrespondingStudentsURL = () => {
  return window.location.origin.includes('.com')
    ? window._env_.STUDENTS_WEB_INTERNATIONAL_URL
    : window._env_.STUDENTS_WEB_URL
}

export const lockMobileOrientation = () => {
  window.screen?.orientation?.lock && window.screen.orientation.lock('portrait')
}

const replaceKatexMinusSign = (text) => {
  return text.replace(/-/g, '−')
}

const replaceKatexFraction = (text) => {
  const fractionRegex = /frac{[\w+-/*=]+}{[\w+-/*=]+}/
  let replacedText = text
  let match
  while ((match = fractionRegex.exec(replacedText)) !== null) {
    const fraction = replacedText.slice(match.index, match.index + match[0].length)
    const fracPartRegex = /{[\w+-/*=]+}/g
    const fractionPartsArray = []
    let fractionPartMatch
    while ((fractionPartMatch = fracPartRegex.exec(fraction)) !== null) {
      fractionPartsArray.push(
        fraction.slice(fractionPartMatch.index + 1, fractionPartMatch.index + fractionPartMatch[0].length - 1)
      )
    }

    const [numerator, denominator] = fractionPartsArray
    replacedText = [
      replacedText.slice(0, match.index),
      numerator,
      '÷',
      denominator,
      replacedText.slice(match.index + match[0].length),
    ].join('')
  }

  return replacedText
}

const removeCommands = (text) => {
  const commands = [
    LARGE_COMMAND,
    QUAD_COMMAND,
    BIG_COMMAND,
    LEFT_COMMAND,
    MIDDLE_COMMAND,
    RIGHT_COMMAND,
    THIN_SPACE_COMMAND,
    THICK_SPACE_COMMAND,
    MED_SPACE_COMMAND,
    EN_SPACE_COMMAND,
    NO_BREAK_SPACE_COMMAND,
    SPACE_COMMAND,
    HUGE_COMMAND,
    TINY_COMMAND,
    FOOT_NOTE_SIZE_COMMAND,
    SMALL_COMMAND,
    NORMAL_SIZE_COMMAND,
    COLOR_COMMAND,
    BEGIN_END_COMMAND,
    AND_COMMAND,
  ]

  return commands.reduce((result, command) => {
    return result.replace(command, '')
  }, text)
}

export const replaceCommands = (text) => {
  return text
    .replace(MULTIPLE_COMMAND, MULTIPLE_SIGN_SPEECH)
    .replace(TIMES_COMMAND, MULTIPLE_SIGN_SPEECH)
    .replace(DIVIDE_COMMAND, DIVIDE_SIGN_SPEECH)
    .replace(SQUARE_ROOT, SQUARE_ROOT_SPEECH)
    .replace(EXTRA_SQUARE_ROOT, SQUARE_ROOT_SPEECH)
    .replace(DEGREE_SIGN, DEGREE_SIGN_SPEECH)
    .replace(CARET_DEGREE_SIGN, DEGREE_SIGN_SPEECH)
    .replace(GREATER_OR_EQUAL, GREATER_OR_EQUAL_SIGN_SPEECH)
    .replace(LESS_OR_EQUAL, LESS_OR_EQUAL_SIGN_SPEECH)
    .replace(ANGLE_SIGN, ANGLE_SIGN_SPEECH)
    .replace(PARALLEL_SIGN, PARALLEL_SIGN_SPEECH)
    .replace(APPROXIMATE_COMMAND, APPROXIMATE_SIGN_SPEECH)
    .replace(BACK_SIMILAR_COMMAND, BACK_SIMILAR_SIGN_SPEECH)
    .replace(SIMILAR_COMMAND, SIMILAR_SIGN_SPEECH)
    .replace(THICK_APPROXIMATE_COMMAND, THICK_APPROXIMATE_SIGN_SPEECH)
    .replace(NOT_EQUALS_COMMAND, NOT_EQUALS_SIGN_SPEECH)
    .replace(DOTS_COMMAND, DOTS_SIGN_SPEECH)
    .replace(POUNDS_COMMAND, POUNDS_SIGN_SPEECH)
    .replace(PI_COMMAND, PI_SIGN_SPEECH)
    .replace(ALPHA_COMMAND, ALPHA_SIGN_SPEECH)
    .replace(BETA_COMMAND, BETA_SIGN_SPEECH)
    .replace(GAMMA_COMMAND, GAMMA_SIGN_SPEECH)
    .replace(THETA_COMMAND, THETA_SIGN_SPEECH)
    .replace(LAMBDA_COMMAND, LAMBDA_SIGN_SPEECH)
    .replace(PLUS_MINUS_COMMAND, PLUS_MINUS_SIGN_SPEECH)
    .replace(VAR_DELTA_COMMAND, BIG_DELTA_SIGN_SPEECH)
    .replace(BIG_DELTA_COMMAND, BIG_DELTA_SIGN_SPEECH)
    .replace(DELTA_COMMAND, DELTA_SIGN_SPEECH)
    .replace(UNDERLINE_COMMAND, UNDERLINE_SIGN_SPEECH)
    .replace(HSPACE_COMMAND, '')
    .replace(CENTER_EQUATION_COMMAND, '')
    .replace(COMMA_SEPARATOR_COMMAND, ',')
}

const performCommands = (text) => {
  return replaceCommands(removeCommands(text))
}

const replaceSlashOnBigNumbers = (text) => {
  const regex = /\d+\s*\\\s*\d+/
  let match
  while ((match = regex.exec(text)) !== null) {
    text = text.replace(/\s*\\\s*/, '')
  }

  return text
}

const replaceKatex = (text) => {
  text = replaceSlashOnBigNumbers(text)
  const symbols = ['-', '+', '=']

  const symbolsRegex = [
    LARGE_COMMAND,
    QUAD_COMMAND,
    GREATER_OR_EQUAL,
    LESS_OR_EQUAL,
    PI_COMMAND,
    BIG_COMMAND,
    HUGE_COMMAND,
    COLOR_COMMAND,
    BEGIN_END_COMMAND,
    MULTIPLE_COMMAND,
    TIMES_COMMAND,
    DIVIDE_COMMAND,
    FRACTION_SIGN,
    SQUARE_ROOT,
    EXTRA_SQUARE_ROOT,
    DEGREE_SIGN,
    CARET_DEGREE_SIGN,
    ANGLE_SIGN,
    PARALLEL_SIGN,
    APPROXIMATE_COMMAND,
    BACK_SIMILAR_COMMAND,
    SIMILAR_COMMAND,
    THICK_APPROXIMATE_COMMAND,
    NOT_EQUALS_COMMAND,
    DOTS_COMMAND,
    POUNDS_COMMAND,
    ALPHA_COMMAND,
    BETA_COMMAND,
    GAMMA_COMMAND,
    THETA_COMMAND,
    LAMBDA_COMMAND,
    LEFT_COMMAND,
    MIDDLE_COMMAND,
    RIGHT_COMMAND,
    THIN_SPACE_COMMAND,
    THICK_SPACE_COMMAND,
    MED_SPACE_COMMAND,
    EN_SPACE_COMMAND,
    NO_BREAK_SPACE_COMMAND,
    SPACE_COMMAND,
    TINY_COMMAND,
    FOOT_NOTE_SIZE_COMMAND,
    SMALL_COMMAND,
    NORMAL_SIZE_COMMAND,
    PLUS_MINUS_COMMAND,
    VAR_DELTA_COMMAND,
    BIG_DELTA_COMMAND,
    DELTA_COMMAND,
    COMMA_SEPARATOR_COMMAND,
    UNDERLINE_COMMAND,
    CENTER_EQUATION_COMMAND,
    HSPACE_COMMAND,
  ]

  if (!symbols.some((symbol) => text.includes(symbol)) && !symbolsRegex.some((symbol) => text.match(symbol))) {
    return text
  }

  return performCommands(replaceKatexFraction(replaceKatexMinusSign(text)))
}

export const removeKatexPartFromTextToSpeech = (text = '') => {
  text = text.replace(/\*/g, '')
  const separator = '$'
  const separatedText = text.split(separator)
  const cleanedTextArray = []
  let i = 0
  while (i < separatedText.length) {
    let textPart = separatedText[i]
    textPart = textPart[textPart.length - 1] === '\\' ? textPart + '$' : replaceKatex(textPart)

    cleanedTextArray.push(textPart)
    i += 1
  }

  return cleanedTextArray.join('')
}

export const splitTextAndSeparatorsToTranslate = (text) => {
  const separator = '$'
  const separatedText = text.split(separator)
  const plainTextArray = []
  const specialContentArray = []
  let i = 0
  while (i < separatedText.length) {
    const regexp = new RegExp(LINE_BREAK_SYMBOL, 'g')
    let textPart = separatedText[i]
    if (textPart[textPart.length - 1] === '\\') {
      textPart += '$'
      i += 1
    } else {
      i + 1 < separatedText.length && specialContentArray.push(separator + separatedText[i + 1] + separator)
      i += 2
    }

    plainTextArray.push(textPart.replace(regexp, ` ${SPECIAL_PLACE_NOT_TRANSLATE} `))
  }

  return { plainTextArray, specialContentArray }
}

export const translateText = async (currentTask, nativeLanguage) => {
  const text = currentTask?.richDescription || currentTask?.laTeXDescription || currentTask?.description
  const { plainTextArray, specialContentArray } = splitTextAndSeparatorsToTranslate(text)
  const response = await translateProblemInfo(
    [TO_THE_POWER_OF, ...plainTextArray],
    nativeLanguageISOCodeMap[nativeLanguage]
  )

  const powerOfTranslated = response?.data?.translations.shift()
  const translations = response?.data?.translations

  if (!translations || !translations.length) {
    return
  }

  const translatedParts = translations.map((translatedPart) => {
    const regexp = new RegExp(SPECIAL_PLACE_NOT_TRANSLATE, 'g')
    return translatedPart.translatedText.replace(regexp, LINE_BREAK_SYMBOL)
  })

  let translatedText = ''
  translatedParts.forEach((translatedPart) => {
    translatedText += translatedPart
    const specialContent = specialContentArray.shift()
    specialContent && (translatedText += ` ${specialContent} `)
  })

  if (!translatedText) return
  let translatedTextToSpeech = removeKatexPartFromTextToSpeech(translatedText)

  if (powerOfTranslated?.translatedText) {
    const textToReplaceWith =
      powerOfTranslated?.translatedText === 'till makten av'
        ? TO_THE_POWER_OF_SWEDISH
        : powerOfTranslated?.translatedText

    translatedTextToSpeech = translatedTextToSpeech.replace(
      POWER_OF_COMMAND,
      ` ${textToReplaceWith} ` ?? TO_THE_POWER_OF
    )
  }

  currentTask.translatedTextToSpeech = translatedTextToSpeech
  currentTask.translatedText = translatedText
}

export const translateChoiceButton = async (choiceButton, nativeLanguage) => {
  const [translatedText, translatedTextToSpeech] = await Promise.all([
    getTranslatedText(choiceButton.variant, nativeLanguage),
    getTranslatedText(choiceButton.textToSpeech, nativeLanguage),
  ])

  if (translatedText) choiceButton.translation = translatedText
  if (translatedTextToSpeech) choiceButton.textToSpeech = translatedTextToSpeech
}

export const removeWhitespaces = (str) => {
  return str.replaceAll(' ', '')
}
