import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface IconButtonProps {
  Icon: React.ElementType
  className: string
  onClick?: () => void
  active?: boolean
}

const IconButton: React.FC<IconButtonProps> = ({ Icon, className, onClick, active }) => {
  return (
    <button className={clsx(styles.IconButton, className, { [styles.Active]: active })} onClick={onClick}>
      <Icon />
    </button>
  )
}

export default IconButton
