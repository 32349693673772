import React from 'react'
import { ExerciseAnswerProps } from '../../index'
import { HandwritingAnswerSimpleMode } from '../../../../../simpleMode/components/HandwritingAnswerSimpleMode/HandwritingAnswerSimpleMode'
import ProblemAnswer from '../../../../../../components/Problem/ProblemAnswer'
import { useUnit } from 'effector-react'
import { $toolsMode, ToolsMode } from '../../../../../toolsMode/model'

export const HandwritingAnswerType = ({
  previousAnswer,
  isLoading,
  isUnitRequired,
  isSolved,
  allowAnswerLooking,
  answerType,
  onSubmitClick,
  onNextButtonPress,
  onAnswerChangeStart,
  wiggleAnimation,
  answerStatus,
  characterType,
}: ExerciseAnswerProps) => {
  const toolsMode = useUnit($toolsMode)

  if (toolsMode === ToolsMode.SIMPLE) {
    return (
      <HandwritingAnswerSimpleMode
        wiggleAnimation={wiggleAnimation}
        answerType={answerType}
        isLoading={isLoading}
        allowAnswerLooking={allowAnswerLooking}
        isUnitRequired={isUnitRequired}
        isSolved={isSolved}
        onAnswerChangeStart={onAnswerChangeStart}
        onNextButtonPress={onNextButtonPress}
        onSubmitClick={onSubmitClick}
        previousAnswer={previousAnswer}
      />
    )
  }

  return (
    <ProblemAnswer
      previousAnswer={previousAnswer}
      answerStatus={answerStatus}
      answerType={answerType}
      onAnswerChangeStart={onAnswerChangeStart}
      onNextButtonPress={onNextButtonPress}
      onSubmitClick={onSubmitClick}
      isSolved={isSolved}
      allowAnswerLooking={allowAnswerLooking}
      practiceMode={false}
      characterType={characterType}
    />
  )
}
