import { persist } from 'effector-storage'
import { attach, combine, createEffect, createEvent, createStore, Effect } from 'effector'
import { days, minutes } from './helpers'

export type TokenManagerProps = {
  refresher?: (currentToken: TokenData) => Promise<TokenData>
  /**
    Define how to get token if there is no value in localStorage
   */
  transitionToNew?: (current: TokenData | null) => Promise<TokenData | null>
  accessLifetime?: number
  refreshLifetime?: number
  saveKey?: string
}

const DEFAULT_TOKEN_KEY = '@token_v2'

export type TokenData = {
  access: string
  refresh: string
  startTime: number
}

enum TokenStatus {
  FRESH = 1,
  EXPIRED,
  REFRESH_EXPIRED,
}

enum UpdateSource {
  EXTERNAL = 1,
}

type TokenGetterProps = { source: UpdateSource }

export class TokenManager {
  private readonly refresher

  private readonly accessLifetime: number
  private readonly refreshLifetime: number
  public readonly refreshTokenExpired = createEvent()
  public readonly tokenChanged = createEvent<TokenData | null>()
  public readonly $state = createStore<TokenData | null>(null)
    .on(this.tokenChanged, (_, data) => data)
    .reset(this.refreshTokenExpired)

  public isInitiated = false
  public readonly initiated = createEvent<TokenData | null>()

  public readonly $isLoading

  public constructor({
    accessLifetime = minutes(45),
    refreshLifetime = days(30),
    saveKey = DEFAULT_TOKEN_KEY,
    transitionToNew,
    refresher,
  }: TokenManagerProps = {}) {
    this.refresher = refresher
    this.accessLifetime = accessLifetime
    this.refreshLifetime = refreshLifetime

    const temp_handleTransition = async (token: TokenData | null, prevRawValue: string | null) => {
      if (!transitionToNew) return token
      const transited = await transitionToNew(token)
      const stringValue = JSON.stringify(transited)
      if (prevRawValue !== stringValue) {
        localStorage.setItem(saveKey, JSON.stringify(transited))
      }
      return transited
    }

    const tokenGetter = createEffect(async (props?: TokenGetterProps): Promise<TokenData | null> => {
      const rawValue = localStorage.getItem(saveKey)
      let tokenData: TokenData | null = null
      if (rawValue) tokenData = JSON.parse(rawValue)
      if (props?.source === UpdateSource.EXTERNAL) return tokenData
      tokenData = await temp_handleTransition(tokenData, rawValue)
      if (!tokenData) return null
      const status = this.checkTokenStatus(tokenData)
      if (status === TokenStatus.FRESH) return tokenData
      if (status === TokenStatus.REFRESH_EXPIRED || !this.refresher) {
        localStorage.removeItem(saveKey)
        return null
      }
      const newTokenData = await this.refresh(tokenData)
      localStorage.setItem(saveKey, JSON.stringify(newTokenData))
      return newTokenData
    })

    tokenGetter.finally.watch((result) => {
      if (this.isInitiated) return
      this.isInitiated = true
      let token: TokenData | null = null
      if (result.status === 'done') token = result.result
      this.initiated(token)
    })

    this.$isLoading = combine([tokenGetter.pending, this.refresh.pending], (state) => state.some(Boolean))

    persist<TokenData | null>({
      store: this.$state,
      key: saveKey,
      adapter: (key, update) => {
        window.addEventListener('storage', (event) => {
          if (event.key !== key) return
          update({ source: UpdateSource.EXTERNAL })
        })

        return {
          get: tokenGetter as Effect<any, any>,
          set: (value) => {
            if (!value) return localStorage.removeItem(key)
            const nextValue = JSON.stringify(value)
            if (nextValue === localStorage.getItem(key)) return
            localStorage.setItem(key, JSON.stringify(value))
          },
        }
      },
    })
  }

  private checkTokenStatus(token: TokenData): TokenStatus {
    const tokenExistTime = Date.now() - token.startTime
    if (tokenExistTime < this.accessLifetime * 0.9) return TokenStatus.FRESH
    if (tokenExistTime < this.refreshLifetime) return TokenStatus.EXPIRED
    return TokenStatus.REFRESH_EXPIRED
  }

  public readonly get = attach({
    source: this.$state,
    mapParams: (_: void, token) => token,
    effect: createEffect(async (token: TokenData | null): Promise<string | null> => {
      if (!this.isInitiated) token = await this.waitInit()
      if (!token) return null
      const tokenExistTime = Date.now() - token.startTime
      if (tokenExistTime < this.accessLifetime * 0.9) return token.access
      if (tokenExistTime < this.refreshLifetime) {
        return this.refresh(token).then((data) => {
          this.tokenChanged({
            access: data.access,
            refresh: data.refresh,
            startTime: Date.now(),
          })
          return data.access
        })
      }
      throw new Error('Refresh token expired')
    }),
  })

  private pendingRefreshRequestPromise: Promise<TokenData> | null = null
  private refresh = createEffect((currentToken: TokenData) => {
    if (!this.refresher) return currentToken
    if (!this.pendingRefreshRequestPromise) {
      this.pendingRefreshRequestPromise = this.refresher(currentToken).finally(() => {
        this.pendingRefreshRequestPromise = null
      })
    }
    return this.pendingRefreshRequestPromise
  })

  public waitInit = attach({
    source: this.$state,
    mapParams: (_: void, state) => state,
    effect: createEffect((token: TokenData | null) => {
      if (this.isInitiated) return token
      return new Promise<TokenData | null>((resolve) => {
        const unwatch = this.initiated.watch((state) => {
          resolve(state)
          unwatch()
        })
      })
    }),
  })
}
