import { createEvent, restore } from 'effector'

export const createIdModel = () => {
  const setId = createEvent<string>()
  const $store = restore(setId, '')

  return {
    setId,
    $store,
  }
}
