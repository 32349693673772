export const createSessionStorageRequest = <T>(key: string) => {
  return {
    get: () => {
      const value = sessionStorage.getItem(key)
      if (!value) return null
      try {
        return JSON.parse(value) as T
      } catch {
        return null
      }
    },
    set: (value: T) => sessionStorage.setItem(key, JSON.stringify(value)),
    remove: () => sessionStorage.removeItem(key),
  }
}
