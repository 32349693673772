import React from 'react'
import clsx from 'clsx'
import useMeasure from 'react-use-measure'
import { animated, useSpring, AnimatedProps } from '@react-spring/web'

import styles from './styles.module.scss'

import { SubCategory } from '../../../types'
import { useActiveSubCategoryModel } from '../../../models/activeSubCategory'

type PracticeSubCategoriesProps = {
  subcategories: SubCategory[]
  isOpened: boolean
}

const PracticeNavigationSubCategories = ({ subcategories, isOpened }: PracticeSubCategoriesProps) => {
  const activeSubCategoryId = useActiveSubCategoryModel(({ id }) => id)
  const [ref, bounds] = useMeasure()

  const animationStyles = useSpring({
    from: { height: 0, marginTop: 0, visibility: 'hidden', display: 'none' },
    to: {
      height: isOpened ? bounds.height : 0,
      visibility: isOpened ? 'visible' : 'hidden',
      display: isOpened ? 'block' : 'none',
    },
  } as AnimatedProps<any>)

  return (
    <animated.div style={animationStyles}>
      <ul className={styles.SubcategoriesList} ref={ref}>
        {subcategories.map((subcategory: SubCategory) => {
          return (
            <li
              className={clsx(styles.SubcategoryItem, {
                [styles.Active]: activeSubCategoryId === subcategory._id,
              })}
              key={subcategory._id}
            >
              <a href={`#${subcategory._id}`} className={styles.SubcategoryTitle}>
                {subcategory.primaryName}
              </a>
            </li>
          )
        })}
      </ul>
    </animated.div>
  )
}

export default PracticeNavigationSubCategories
