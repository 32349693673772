import React, { FC } from 'react'
import './styles.scss'
import clsx from 'clsx'
import { NumpadProps, NumpadTypes } from './types'

import NumpadMath from './NumpadMath'
import NumpadMathMobile from './NumpadMathMobile'
import NumpadLetters from './NumpadLetters'
import NumpadUnits from './NumpadUnits'

const Numpad: FC<NumpadProps> = React.memo(
  ({ numpadType, onPress, onSpecialSymbolPress, isButtonDisabled, className, mathNumpadAdditionalButtons }) => {
    return (
      <div className={clsx('numpad-container', { 'not-selected': !numpadType }, className)}>
        {numpadType === NumpadTypes.NUMBERS && (
          <NumpadMath
            additionalButtons={mathNumpadAdditionalButtons}
            onPress={onPress}
            onSpecialSymbolPress={onSpecialSymbolPress}
          />
        )}

        {numpadType === NumpadTypes.LETTERS && <NumpadLetters onPress={onPress} isButtonDisabled={isButtonDisabled} />}

        {numpadType === NumpadTypes.UNITS && <NumpadUnits onPress={onPress} isButtonDisabled={isButtonDisabled} />}

        {numpadType === NumpadTypes.NUMBERS_MOBILE && (
          <NumpadMathMobile onPress={onPress} isButtonDisabled={isButtonDisabled} />
        )}
      </div>
    )
  }
)

export default Numpad
