import { CONTENT_EDITABLE_ID } from '../components/AnswerContentEditable'
import { MyScriptMathCharacterTypes } from '../../../../../constants/myScriptMathCharacterTypes'
import i18next from 'i18next'

const NUMBER_CHAR_TYPES = [
  MyScriptMathCharacterTypes.NUMBERS,
  MyScriptMathCharacterTypes.NUMBERS_WHOLE,
  MyScriptMathCharacterTypes.NUMBERS_RATIONAL,
]

const UNITS_CHAR_TYPES = [MyScriptMathCharacterTypes.UNITS, MyScriptMathCharacterTypes.NUMBERS_AND_UNITS]

export const focusAndSetCaretAtEnd = () => {
  const contentEditableElement = document.getElementById(CONTENT_EDITABLE_ID)
  if (!contentEditableElement) return

  const range = createRangeForElement(contentEditableElement)

  contentEditableElement.focus()

  const selection = window.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)
}

const createRangeForElement = (element: HTMLElement) => {
  const range = document.createRange()
  const lastNode = getLastNode(element)

  if (!lastNode) return range

  if (lastNode.nodeType === Node.TEXT_NODE) {
    setRangeForTextNode(range, lastNode as Text)
    return range
  }

  if (lastNode.nodeType === Node.ELEMENT_NODE) {
    setRangeForElementNode(range, lastNode)
    return range
  }

  return range
}

const getLastNode = (element: HTMLElement) => {
  const childNodes = element.childNodes
  return childNodes[childNodes.length - 1]
}

const setRangeForTextNode = (range: Range, textNode: Text) => {
  range.setEnd(textNode, textNode.length)
  range.setStart(textNode, textNode.length)
}

const setRangeForElementNode = (range: Range, elementNode: Node) => {
  range.setStartAfter(elementNode)
  range.setEndAfter(elementNode)
}

export const getLabelText = (characterType: MyScriptMathCharacterTypes, isUnitRequired: boolean) => {
  const isAnswerWithNumbers = NUMBER_CHAR_TYPES.includes(characterType)
  const isUnitAnswer = UNITS_CHAR_TYPES.includes(characterType) && isUnitRequired

  if (isAnswerWithNumbers) return i18next.t('answerWithNumbers')
  if (isUnitAnswer) return i18next.t('answerTextUnitRequired')

  return i18next.t('answerText')
}
