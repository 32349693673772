import { createEvent, createStore, restore, sample } from 'effector'
import type { Assignment } from '../../ExerciseItem/types'
import type { AttemptInfo } from '../types'
import { getExercisesAttemptsData } from '../../../helpers/exercises/exercisesHelper'

const resetAssignmentModel = createEvent()

const setIsAnswerPending = createEvent<boolean>()
const $isAnswerPending = restore(setIsAnswerPending, false).reset(resetAssignmentModel)

const setAssignment = createEvent<Assignment | null>()
const $assignment = restore(setAssignment, null).reset(resetAssignmentModel)

const updateAttemptInfo = createEvent<Record<string, AttemptInfo>>()
const setAttemptInfo = createEvent<Assignment>()
const $attemptInfo = createStore<Record<string, AttemptInfo>>({})
  .on(setAttemptInfo, (_, assignment) => getExercisesAttemptsData(assignment))
  .on(updateAttemptInfo, (_, attemptInfo) => attemptInfo)
  .reset(resetAssignmentModel)

export class assignmentModel {
  static readonly $assignment = $assignment
  static readonly setAssignment = setAssignment

  static readonly $attemptInfo = $attemptInfo
  static readonly setAttemptInfo = setAttemptInfo
  static readonly updateAttemptInfo = updateAttemptInfo

  static readonly $isAnswerPending = $isAnswerPending
  static readonly setIsAnswerPending = setIsAnswerPending
  static readonly $exercises = $assignment.map((assignment) => assignment?.problems ?? [])
  static readonly reset = resetAssignmentModel
}
