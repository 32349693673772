import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SimpleModeNumpadButton } from '../SimpleModeNumpadButton/SimpleModeNumpadButton'
import { DIGITS } from '../../../CharTypeNumpad/constants/symbols'

type DigitsNumpadProps = {
  onDigitPress: (value: string) => void
}

export const SimpleModeDigitsNumpad = ({ onDigitPress }: DigitsNumpadProps) => (
  <View style={styles.container}>
    {DIGITS.map((digit) => (
      <SimpleModeNumpadButton key={digit} width={70} height={70} onPress={() => onDigitPress(digit)}>
        {digit}
      </SimpleModeNumpadButton>
    ))}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: 400,
    height: '100%',
    gap: 8,
  },
})
