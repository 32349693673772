import React from 'react'
import Markdown from '../../../../components/katexMarkdown/Markdown'
import { InlineMath } from 'react-katex'
import { DescriptionExercise } from '../../ExerciseDescription'

type DescriptionContentProps = {
  exercise: DescriptionExercise
  isTranslated: boolean
  mergeLinebreaks?: boolean
}

export const DescriptionContent = ({ exercise, isTranslated, mergeLinebreaks }: DescriptionContentProps) => {
  const content = isTranslated ? exercise.translatedText : exercise.richDescription

  if (exercise.richDescription) {
    return <Markdown mergeLinebreaks={mergeLinebreaks}>{content}</Markdown>
  }

  if (exercise.kaTeXDescription) {
    return <InlineMath math={exercise.kaTeXDescription} />
  }

  return isTranslated ? exercise.translatedText : exercise.description
}
