import React from 'react'
import { Button, SIZE, BUTTON_VARIANT, COLORS } from '@magmamath/ui'

import ArrowControlLeft from '../../../../../ui/icons/ArrowControlLeft'
import ArrowControlRight from '../../../../../ui/icons/ArrowControlRight'

type GradeSelectorControlProps = {
  isDisabled: boolean
  onClick: () => void
  isReversed?: boolean
}

const GradeSelectorControl = ({ isDisabled, onClick, isReversed }: GradeSelectorControlProps) => {
  return (
    <Button
      size={SIZE.MEDIUM}
      variant={BUTTON_VARIANT.TERTIARY}
      onClick={onClick}
      icon={
        isReversed ? (
          <ArrowControlRight size={12} color={isDisabled ? COLORS.NEUTRAL_5 : COLORS.NEUTRAL_9} />
        ) : (
          <ArrowControlLeft size={12} color={isDisabled ? COLORS.NEUTRAL_5 : COLORS.NEUTRAL_9} />
        )
      }
      disabled={isDisabled}
    />
  )
}

export default GradeSelectorControl
