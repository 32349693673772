import React from 'react'
import { IconProps } from './types'

export const SimpleModePenIcon = ({ fill = '#666679' }: IconProps) => (
  <svg width='45' height='37' viewBox='0 0 45 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.5696 2.67096C30.7696 1.47101 32.7151 1.47101 33.9151 2.67096L38.3291 7.08502C39.5291 8.28498 39.5291 10.2305 38.3291 11.4304L15.6804 34.0791C14.9441 34.8154 13.9455 35.2291 12.9042 35.2291H7.13663C6.38241 35.2291 5.771 34.6177 5.771 33.8635V28.0959C5.771 27.0546 6.18465 26.056 6.92095 25.3196L29.5696 2.67096ZM31.9838 4.60227C31.8504 4.46894 31.6343 4.46894 31.5009 4.60227L28.5525 7.55069L33.4494 12.4476L36.3978 9.49915C36.5311 9.36582 36.5311 9.14965 36.3978 9.01632L31.9838 4.60227ZM31.5181 14.3789L26.6212 9.48199L8.85226 27.2509C8.62816 27.475 8.50227 27.779 8.50227 28.0959V32.4978H12.9042C13.2211 32.4978 13.525 32.3719 13.7491 32.1478L31.5181 14.3789Z'
      fill={fill}
    />
  </svg>
)
