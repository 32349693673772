import { MutableRefObject, useEffect, useRef } from 'react'
import { BoardController, MSCompanion } from '@magmamath/react-native-draw-board'
import { getGrammarName, MathGrammarCode } from '../MyScript/mathGrammarCode'
import { AnswerType } from '../Problem/types.answer'
import { transformLatexToText } from '../../helpers/myScript/transformLatexToText'
import { currentProblemAnswerModel } from '../Problem/models/answer'

type RecognitionModuleSettings = {
  characterType: MathGrammarCode
  answerType: AnswerType
}

export const msCompanion = new MSCompanion({
  modules: {
    circleRecognition: {
      on: true,
      onRecognition: (value) => {
        currentProblemAnswerModel.reset()
        currentProblemAnswerModel.setText(transformLatexToText(value))
      },
    },
  },
  rest: {
    host: `https://${window._env_.MY_SCRIPT_HOST}`,
    appKey: window._env_.MY_SCRIPT_REST_APP_KEY,
    hmac: window._env_.MY_SCRIPT_REST_HMAC_KEY,
  },
})

export const useMyScriptRecognitionModule = (
  boardRef: MutableRefObject<BoardController | null>,
  { characterType, answerType }: RecognitionModuleSettings
) => {
  useEffect(() => {
    if (!boardRef.current) return
    msCompanion.setModel(boardRef.current.model)

    if (answerType === AnswerType.HANDWRITING) {
      msCompanion.start()
    }

    return () => {
      msCompanion.setModel(null)
      msCompanion.stop()
    }
  }, [boardRef, answerType])

  useEffect(() => {
    msCompanion.setGrammarId(getGrammarName(characterType))
  }, [characterType])
}
