import { AddMessagePayload, AssignmentMessages } from './models/messages'

export const addMessage = (
  store: AssignmentMessages | null,
  { assignmentId, problemId, ...message }: AddMessagePayload
) => {
  const updatedMessages = { ...store }

  if (!updatedMessages[assignmentId]) updatedMessages[assignmentId] = {}
  if (!updatedMessages[assignmentId][problemId]) updatedMessages[assignmentId][problemId] = []

  updatedMessages[assignmentId][problemId].push(message)

  return updatedMessages
}
