import { MutableRefObject, useEffect, useRef } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'
import { LocalStorageKeys } from '../../api/localStorage/keys'

const RELOAD_MAX_DELAY = 5000

export function useDrawBoardStateSave(boardRef: MutableRefObject<BoardController | null>, exerciseId: string | null) {
  const exerciseIdRef = useRef(exerciseId)
  exerciseIdRef.current = exerciseId

  useEffect(() => {
    const model = boardRef.current?.model
    if (!model || !exerciseIdRef.current) return
    const checkStorage = () => {
      const state = localStorage.getItem(LocalStorageKeys.DRAW_BOARD_STATE)
      if (!state) return
      const parsed = JSON.parse(state)
      const currentTime = Date.now()
      if (exerciseIdRef.current === parsed.exerciseId && currentTime - parsed.timestamp < RELOAD_MAX_DELAY) {
        model.tools.color.set(parsed.color)
        model.tools.size.set(parsed.size)
        model.tools.eraseSize.set(parsed.eraseSize)
        model.tools.shapeName.set(parsed.name)
        model.tools.toolVariant.set(parsed.variant)
      }
      localStorage.removeItem(LocalStorageKeys.DRAW_BOARD_STATE)
    }

    checkStorage()

    const listener = () => {
      localStorage.setItem(
        LocalStorageKeys.DRAW_BOARD_STATE,
        JSON.stringify({
          color: model.tools.color.$store.getState(),
          size: model.tools.size.$store.getState(),
          eraseSize: model.tools.eraseSize.$store.getState(),
          name: model.tools.shapeName.$store.getState(),
          variant: model.tools.toolVariant.$store.getState(),
          exerciseId: exerciseIdRef.current,
          timestamp: Date.now(),
        })
      )
    }

    window.addEventListener('beforeunload', listener)
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [boardRef])
}
