import { createEvent, restore } from 'effector'

const setPlaceholderVisible = createEvent<boolean>()
const $isPlaceholderVisible = restore(setPlaceholderVisible, false)

const setNumpadVisible = createEvent<boolean>()
const $isNumpadVisible = restore(setNumpadVisible, false)

export class answerEditorModel {
  static readonly $isPlaceholderVisible = $isPlaceholderVisible
  static readonly setPlaceholderVisible = setPlaceholderVisible
  static readonly $isNumpadVisible = $isNumpadVisible
  static readonly setNumpadVisible = setNumpadVisible
}
