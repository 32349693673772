import React from 'react'
import type { IconProps } from '../_types'
import { COLORS } from '@magmamath/ui'

export const MapIcon = ({ size = 25, color = COLORS.NEUTRAL_10 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99878 6.33948C9.10132 6.33948 8.37378 7.06701 8.37378 7.96448C8.37378 8.86194 9.10132 9.58948 9.99878 9.58948C10.8962 9.58948 11.6238 8.86194 11.6238 7.96448C11.6238 7.06701 10.8962 6.33948 9.99878 6.33948ZM6.62378 7.96448C6.62378 6.10052 8.13482 4.58948 9.99878 4.58948C11.8627 4.58948 13.3738 6.10052 13.3738 7.96448C13.3738 9.82844 11.8627 11.3395 9.99878 11.3395C8.13482 11.3395 6.62378 9.82844 6.62378 7.96448Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.02698C7.15966 3.02698 4.87638 5.29827 4.875 8.07834C4.87417 9.75665 5.62632 11.1573 6.81632 12.922C7.60777 14.0957 9.11737 15.8457 9.99706 16.8385C10.8695 15.8452 12.3663 14.0963 13.1581 12.922C14.3527 11.1505 15.125 9.73388 15.125 8.07793C15.1249 5.29855 12.8407 3.02698 10 3.02698ZM3.125 8.07747C3.12687 4.31194 6.21292 1.27698 10 1.27698C13.7867 1.27698 16.8749 4.31166 16.875 8.07788C16.8751 10.3101 15.8121 12.1164 14.6091 13.9004C13.6791 15.2795 11.924 17.3022 11.1119 18.2203C10.5207 18.8888 9.48246 18.891 8.88832 18.2252C8.07009 17.3083 6.29726 15.2823 5.36539 13.9004C4.15773 12.1096 3.12389 10.325 3.125 8.07747Z'
        fill={color}
      />
    </svg>
  )
}
