import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import useMeasure from 'react-use-measure'

import styles from './styles.module.scss'

import IconOrder from '../../../../../../../ui/icons/IconOrder'
import { SelectedAnswerChoice } from '../../../types'
import { IconOrderProps } from '../../../../../../../ui/icons/IconOrder'

type AnswerVariantProps = {
  content: string | React.ReactElement
  isDisabled: boolean
  isSelected: boolean
  order: number | undefined
  style: string
  answerVariant: SelectedAnswerChoice
  onClick: (answerVariant: any) => void
  modeStyle: string
}

const AnswerVariant: React.FC<AnswerVariantProps> = ({
  content,
  isDisabled,
  isSelected,
  order,
  style,
  modeStyle,
  answerVariant,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [isScroll, setIsScroll] = useState(false)
  const [ref, bounds] = useMeasure()

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    if (bounds.width > containerRef.current?.offsetWidth) {
      setIsScroll(true)
    }
  }, [bounds])

  return (
    <div
      aria-disabled={isDisabled}
      className={clsx(styles.AnswerVariantContainer, {
        [styles.Selected]: isSelected,
        [styles[style]]: style,
        [styles[modeStyle]]: modeStyle,
        [styles.ScrollLeft]: isScroll,
      })}
      ref={containerRef}
      onClick={() => onClick(answerVariant)}
    >
      {order && <IconOrder style={style} order={order} variant={modeStyle as IconOrderProps['variant']} />}
      <div className={styles.AnswerVariantTextContainer}>
        <span className={styles.AnswerVariantText} ref={ref}>
          {content}
        </span>
      </div>
    </div>
  )
}

export default AnswerVariant
