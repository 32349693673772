import { createEvent, restore } from 'effector'
import {
  autoCreateFractionWithSlash,
  autoSuperscript,
  checkIsFractionCompleted,
  cleanEmptyString,
  cleanEmptyTags,
  fullFractionHelper,
} from '../../../helpers/exercises/answerProblemHelper'
import { setCursorPosition } from '../answerInput.helpers'

export enum ProblemAnswerStatus {
  SUCCESS_FIRST_ATTEMPT = 'SUCCESS_FIRST_ATTEMPT',
  SUCCESS = 'SUCCESS',
  MISTAKE = 'MISTAKE',
  EXAM_MODE = 'EXAM_MODE',
}

const setStatus = createEvent<ProblemAnswerStatus | ''>()
const $status = restore(setStatus, '')

const inputChanged = createEvent<string>()
const setText = createEvent<string>()
const $text = restore(setText, '').on(inputChanged, (_, text) => text)

const setISFractionCompleted = createEvent<boolean>()
const $isFractionCompleted = restore(setISFractionCompleted, true)

function changeFromInput(input: string) {
  input = input.replace(/<br>/g, '')
  currentProblemAnswerModel.setIsFractionCompleted(checkIsFractionCompleted(input))

  input = cleanEmptyTags(input)
  input = cleanEmptyString(input)
  input = fullFractionHelper(input)
  input = autoSuperscript(input)
  const result = autoCreateFractionWithSlash(input)
  setText(result.performedAnswer)
  if (result.needToSetCursor) setCursorPosition('fraction-item denominator')
}

export class currentProblemAnswerModel {
  static readonly $text = $text
  static readonly setText = setText
  static readonly clearText = () => setText('')
  static readonly $status = $status
  static readonly setStatus = setStatus
  static readonly inputChanged = inputChanged
  static readonly changeFromInput = changeFromInput

  static readonly $isFractionCompleted = $isFractionCompleted
  static readonly setIsFractionCompleted = setISFractionCompleted
  static readonly resetStatus = () => setStatus('')
  static readonly reset = () => {
    setStatus('')
    setText('')
  }
}
