import React, { FC } from 'react'
import { MATH_BUTTONS } from './symbols'
import MathButtons from './components/MathButtons'
import type { NumpadVariantProps } from '../types'
import type { MathNumpadSymbol } from './types'

const ROWS_COUNT = 3

const NumpadMath: FC<NumpadVariantProps<MathNumpadSymbol>> = ({ onPress, onSpecialSymbolPress, additionalButtons }) => {
  return (
    <>
      <MathButtons
        symbolsList={MATH_BUTTONS}
        columnsCount={MATH_BUTTONS.length / ROWS_COUNT}
        onPress={onPress}
        onSpecialSymbolPress={onSpecialSymbolPress}
      />

      {additionalButtons && (
        <MathButtons
          symbolsList={additionalButtons}
          columnsCount={additionalButtons.length / ROWS_COUNT}
          onPress={onPress}
          onSpecialSymbolPress={onSpecialSymbolPress}
        />
      )}
    </>
  )
}

export default NumpadMath
