import React, { useEffect, useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { Router, Switch, Route } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import 'katex/dist/katex.min.css'
import 'react-medium-image-zoom/dist/styles.css'

import './styles.scss'

import PrivateRoute from './PrivateRoute'
import { Context as UserSettingsContext } from '../context/userSettingsContext'
import SocketContext from '../context/socketContext'
import { SocketEvents } from '../constants/socketEventsConstants'
import { userDeleted } from '../helpers/users/userEvents'
import ClosePopupButton from '../components/ClosePopupButton'
import AutoLogin from './AutoLogin'
import HomePage from './HomePage'
import ProfilePage from './ProfilePage'
import PracticePage from './PracticePage'
import ResolveAuth from '../components/ResolveAuth'
import PracticeExercise from '../screens/practiceExercise'

import history from '../history'
import { RoutesPaths } from './paths'
import { api } from '../api'
import { getStudentSettings } from '../helpers/users/getUserInfo'
import { initLocaleFromOrigin, setI18nextLocale } from '../locales/i18n.helpers'
import { noop } from '../lib/helpers'
import { useUnit } from 'effector-react'
import { AuthModel } from '../features/auth/authModel'

api.manager.onInit(async (token) => {
  if (!token) initLocaleFromOrigin()
})

const App = () => {
  const isAuth = useUnit(AuthModel.$isAuth)
  const { setSettings } = useContext(UserSettingsContext)
  const { socket } = useContext(SocketContext)

  useEffect(() => {
    const onUserDeleted = ({ userId }) => {
      userDeleted(userId)
    }

    socket.on(SocketEvents.USER_DELETED, onUserDeleted)
    return () => {
      socket.off(SocketEvents.USER_DELETED, onUserDeleted)
    }
  }, [socket])

  useEffect(() => {
    if (!isAuth) return
    const initUserSettings = async () => {
      const userSettings = await getStudentSettings()
      setSettings(userSettings)
      if (userSettings.appLanguage) {
        setI18nextLocale(userSettings.appLanguage).catch(noop)
      }
    }

    initUserSettings().catch(noop)
  }, [isAuth])

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={RoutesPaths.INDEX} component={ResolveAuth} />
        <Route exact path={RoutesPaths.LOGIN} component={ResolveAuth} />
        <Route exact path={RoutesPaths.REGISTER} component={ResolveAuth} />
        <Route exact path={RoutesPaths.AUTOLOGIN} component={AutoLogin} />
        <PrivateRoute exact path={RoutesPaths.PRACTICE} component={PracticePage} />
        <PrivateRoute exact path={RoutesPaths.PRACTICE_PAGE} component={PracticeExercise} />
        <PrivateRoute path={RoutesPaths.PROFILE} component={ProfilePage} />
        <PrivateRoute path={RoutesPaths.EXERCISES} component={HomePage} />
      </Switch>
      <ToastContainer closeButton={<ClosePopupButton />} newestOnTop={true} pauseOnFocusLoss={false} limit={1} />
    </Router>
  )
}

export default App
