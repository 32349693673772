export const ROWS_COUNT = 3

export enum LOCALES {
  SWE = 'SWE',
  EN = 'EN',
}

export enum UNITS_CATEGORIES {
  LENGTH = 'LENGTH',
  AREA = 'AREA',
  VOLUME = 'VOLUME',
  WEIGHT = 'WEIGHT',
  TIME = 'TIME',
}

export const SIGNS = {
  DOT: '.',
  COMMA: ',',
  PERCENTAGE: '%',
  PERMILLE: '‰',
  SEMICOLON: ';',
  SUBTRACTION: '-',
  X: 'x',
  PLUS: '+',
  DOLLAR: '$',
  EURO: '€',
  KRONA: 'kr',
  POUND: '£',
  PENCE: 'p',
  COLON: ':',
  BRACKET_LEFT: '(',
  BRACKET_RIGHT: ')',
  EQUAL: '=',
  SQUARE_ROOT: '√',
  PI: 'π',
  DIVIDE: '/',
  DIVIDE_WITH_DOTS: '÷',
  DEGREE: '°',
  MULTIPLY_SWE: '⋅',
  MULTIPLY_EN: '×',
  LESS_THAN: 'LESS_THAN', // it is replaced in problem.answer.treeHelpers.ts L:92 (replaceSpecialSymbolsToHTMLElements func)
  LESS_OR_EQUAL_THAN: '≤',
  GREATER_THAN: 'GREATER_THAN', // it is replaced in problem.answer.treeHelpers.ts L:91 (replaceSpecialSymbolsToHTMLElements func)
  GREATER_OR_EQUAL_THAN: '≥',
} as const
