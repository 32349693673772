export const EMPTY_DRAWING = '{"lines":[],"width":"100%","height":"100%"}'
export const DEFAULT_DRAWING = {
  drawing: EMPTY_DRAWING,
  scaleData: { scale: 1, x: 0, y: 0 },
  backgroundType: '',
  textAreas: null,
}
export const ZOOM_IN = 'ZOOM_IN'
export const ZOOM_OUT = 'ZOOM_OUT'
export const RESET_ZOOM = 'RESET_ZOOM'

export const MIN_LINES_AMOUNT = 3
export const MIN_LINES_AMOUNT_IF_NO_DRAWING_REQUIRED = 1
export const MIN_SCALE_LEVEL = 0.1

export const MIN_INPUT_WIDTH = 50
export const MIN_INPUT_HEIGHT = 30
export const ONE_LETTER_SPACE = 15

export const ClearResult = {
  SHAPE_CLEARED: 'SHAPE_CLEARED',
  BOARD_CLEARED: 'BOARD_CLEARED',
}
