import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

import { MyScriptMathCharacterTypes } from '../../../../../../constants/myScriptMathCharacterTypes'
import { getLabelText } from '../../helpers'

type InputFieldLabelProps = {
  characterType: MyScriptMathCharacterTypes
  isUnitRequired: boolean
  styleVariant: string
  modeStyles: string
  isLoading?: boolean
}

export const InputFieldLabel = ({ characterType, isUnitRequired, styleVariant, modeStyles }: InputFieldLabelProps) => (
  <span
    className={clsx(styles.InputFieldLabel, {
      [styles[modeStyles]]: !!modeStyles,
      [styles[styleVariant]]: !!styleVariant,
    })}
  >
    {getLabelText(characterType, isUnitRequired)}
  </span>
)
