import React, { useContext } from 'react'
import './styles.scss'
import { Context as SidebarContext } from '../../context/sidebarContext'
import exitIcon from '../../assets/images/exit.png'

import BurgerButton from '../BurgerButton'
import SidebarOptions from '../../helpers/users/SidebarOptions'
import { IconButton } from '../../ui/IconButton'

export const CustomSidebar = ({ hiddenOnExercisePage }) => {
  const { state, closeSidebar, setShowSidebar } = useContext(SidebarContext)

  const hideSidebarClicked = (event) => {
    if (event.target.classList.contains('sidebar-wrapper')) {
      closeSidebar()
    }
  }

  return (
    <>
      <BurgerButton onClick={setShowSidebar} isHidden={state.hiddenOnExercisePage} />
      <div className={`${hiddenOnExercisePage ? 'sidebar-wrapper-hidden-on-exercise-page' : ''}`}>
        <div className={`sidebar-wrapper${state.show ? ' show-responsive-sidebar' : ''}`} onClick={hideSidebarClicked}>
          <div className={`sidebar-container${state.hide ? ' hide-sidebar' : ''}`}>
            <SidebarOptions />

            <IconButton size='sm' variant='outlined' onClick={closeSidebar} className='sidebar-close-icon'>
              <img src={exitIcon} alt='exit' />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomSidebar
