import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const TriangleFigure = ({ stroke = COLORS.NEUTRAL_2, size = 181 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 188 181' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.7489 4C12.3694 4.5692 12.7547 9.92584 12.7547 15.6375C12.7547 21.5182 12.6405 26.2475 12.408 26.7669C12.1754 27.2862 12.5036 27.0175 12.7542 26.9219C13.0049 26.8263 10.2295 27.1461 19.1285 26.7669C28.0276 26.3876 30.0821 13.5833 13.6508 17.0061C28.7121 11.53 17.7594 1.26259 13.6503 4.68513'
        stroke={stroke}
        strokeWidth='6.41406'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M184.106 151.86C183.419 145.7 173.152 145.016 171.098 162.129C170.275 168.976 175.889 175.137 183.419 170.345C183.9 170.039 184.104 169.661 184.104 168.976'
        stroke={stroke}
        strokeWidth='6.41406'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M28.8355 31.4977C28.2218 32.3488 28.1968 43.6482 28.3127 57.9421C28.3841 66.7407 28.7615 76.6742 28.8355 86.0036C28.9241 97.1785 28.687 107.487 28.45 113.941C27.6713 135.148 29.6956 150.475 28.788 152.317C27.8804 154.16 29.0996 153.234 30.0161 152.923C30.7196 152.684 28.0366 153.061 51.8162 153.249C59.021 153.307 74.3982 151.712 81.5477 152.317C88.6971 152.923 102.006 153.272 110.345 153.163C144.462 152.716 158.018 151.675 157.532 152.662C158.9 154.602 152.837 154.153 134.939 133.494C127.139 124.491 112.063 110.724 96.7686 95.6247C91.7244 90.6448 86.8511 85.3012 81.5477 80.6246C55.825 57.9421 34.9806 35.0684 36.1007 36.9354'
        stroke={stroke}
        strokeWidth='6.41406'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M23.2353 175.137C23.9195 175.135 22.9523 171.697 21.1805 165.044C19.1279 157.338 17.5224 152.97 17.107 152.424C16.6913 151.878 16.7864 152.38 16.743 152.701C16.6997 153.021 14.4928 156.845 10.2558 165.474C6.01899 174.101 4.27709 177.792 4 177.428'
        stroke={stroke}
        strokeWidth='6.41406'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M8.94405 166.295C8.81673 166.465 8.81673 166.465 9.33867 166.324C9.86062 166.184 13.6164 165.809 15.4497 165.588C17.283 165.367 23.3556 164.866 23.1647 165.121C22.9737 165.378 22.8337 165.007 22.7064 165.177'
        stroke={stroke}
        strokeWidth='6.41406'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default TriangleFigure
