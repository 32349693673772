import { createEvent, restore, sample } from 'effector'
import { assignmentModel } from './assignment.model'
import { RECENT_PROBLEM_ID } from '../../../config/localStorageKeys'
import { useProblemStore } from '../../Problem/models/model'

export const resetAssignmentCredentials = createEvent()

export const setAssignmentId = createEvent<string>()
export const $assignmentId = restore<string>(setAssignmentId, '').reset(resetAssignmentCredentials)

export const setExerciseId = createEvent<string>()
export const $exerciseId = restore<string>(setExerciseId, '').reset(resetAssignmentCredentials)

type SetCurrentExerciseByIdProps = {
  problemId: string
  subProblemId?: string
}

//The flow of setting current problem:
//We have index of current problem in zustand
//when we navigate we set current index
//it cause rerender in Problem component and in useEffect we set $exerciseId
//when we move zustand problemIndex to effector we will be able to get rid of
//effect in Problem component in favor of sample
//source - currentAssignment, clock - $currentIndex, target - $exerciseId
//if we don't have for direct set exercise by id (and we set it only though changing index) - we can use combine
export class currentExerciseModel {
  public static readonly setById = createEvent<SetCurrentExerciseByIdProps>()
  public static readonly saveCredits = createEvent()
}

sample({
  source: assignmentModel.$exercises,
  clock: currentExerciseModel.setById,
  fn: (assignments, { problemId, subProblemId }) => {
    const problemIndex = assignments.findIndex((problem) => problem._id === problemId)
    if (problemIndex === -1) return null
    if (!subProblemId) return { problem: problemIndex, subProblem: 0 }
    const problem = assignments[problemIndex]
    const subProblems = problem.subProblems
    const subProblemIndex = subProblems.findIndex((subProblem) => subProblem._id === subProblemId)
    if (subProblemIndex === -1) return null
    return { problem: problemIndex, subProblem: subProblemIndex }
  },
}).watch((indexes) => {
  if (!indexes) return
  const methods = useProblemStore.getState()
  methods.setCurrentProblemIndex(indexes.problem)
  methods.setCurrentSubProblemIndex(indexes.subProblem)
})

sample({
  source: { exerciseId: $exerciseId },
  clock: currentExerciseModel.saveCredits,
}).watch(({ exerciseId }) => {
  if (!exerciseId) return
  sessionStorage.setItem(RECENT_PROBLEM_ID, exerciseId)
})
