import React, { useContext } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import '../styles.scss'

import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../../constants/defaults'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import LanguageListItem from './LanguageListItem'

const LanguageList = ({ isSearchTermEmpty, filteredLanguages, onLanguageSelect }) => {
  const { t } = useTranslation()
  const { state } = useContext(UserSettingsContext)
  const { translationLanguage } = state

  return (
    <ul
      className={clsx('native-language-list', {
        'native-language-list-no-results-center': !filteredLanguages?.length,
      })}
    >
      {isSearchTermEmpty && (
        <li
          className={clsx('native-language-list-item', {
            active: translationLanguage === NO_LANGUAGE_SELECTED || translationLanguage === DEPRECATED_NONE_LANGUAGE,
          })}
          onClick={() => onLanguageSelect(NO_LANGUAGE_SELECTED)}
        >
          {t('languageListNothingSelected')}
        </li>
      )}

      {filteredLanguages?.length ? (
        filteredLanguages.map((language) => (
          <LanguageListItem
            key={language}
            isActive={translationLanguage === language}
            language={language}
            onLanguageSelect={onLanguageSelect}
          />
        ))
      ) : (
        <p className='native-language-list-no-results'>{t('languageListNoResults')}</p>
      )}
    </ul>
  )
}

export default LanguageList
