import React from 'react'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../../../models/answer'
import { cleanLatexAnswer } from '../../../../../../helpers/myScript/cleanLatexAnswer'
import { ToolbarToolsNames } from '../../../../../Geogebra/types'
import { resetGeogebraState, transformPointsStringToArray } from '../../../../../Geogebra/helpers/geogebraApiHelpers'
import { GEOGEBRA_ANSWER_CLEARED } from '../../../../../Geogebra/constants'
import { ExamStatuses, type AssignmentProblem } from '../../../../../ExerciseItem/types'
import { AnswerAreaGeogebra } from '../../../../../Geogebra/components/AnswerAreaGeogebra'
import { useGeogebraModel } from '../../../../../Geogebra/model'
import { useUnit } from 'effector-react'
import { useAssignmentCredentials } from '../../../../../assignment/hooks/useAssignmentCredentials'
import { assignmentModel } from '../../../../../assignment/model/assignment.model'

type ProblemAnswerGeogebraProps = {
  isExamFinished: boolean
  isExerciseInExamMode: boolean
  isCorrectAnswerStatus: boolean
  answerStatus: ProblemAnswerStatus
  onNextButtonPress: () => void
  currentTask: AssignmentProblem
  wiggleAnimation: boolean
  onSubmitClick: (answer: string[] | string, isMyScript: boolean) => void
  previousAnswer: string | string[]
  submitButtonProps: {
    text: string
    style: string
  }
}

export const ProblemAnswerGeogebra = ({
  isExamFinished,
  isExerciseInExamMode,
  isCorrectAnswerStatus,
  answerStatus,
  onNextButtonPress,
  currentTask,
  wiggleAnimation,
  onSubmitClick,
  previousAnswer,
  submitButtonProps,
}: ProblemAnswerGeogebraProps) => {
  const answerText = useUnit(currentProblemAnswerModel.$text)
  const isPointToolSelected = currentTask?.geogebraSettings?.toolbarTools[0] === ToolbarToolsNames.POINT.toUpperCase()
  const isPointsLimitReached = useGeogebraModel((state) => isPointToolSelected && state.isPointsLimitReached)
  const isExamModeAndFinished = isExerciseInExamMode && isExamFinished
  const isAnswerEmpty = (!previousAnswer?.[0] && !answerText) || answerText === GEOGEBRA_ANSWER_CLEARED
  const credits = useAssignmentCredentials()
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const assignment = useUnit(assignmentModel.$assignment)

  const isSubmitButtonDisabled =
    isAnswerEmpty ||
    (currentTask?.geogebraSettings?.pointsLimit && !isPointsLimitReached) ||
    isExamModeAndFinished ||
    isAnswerPending ||
    (isExerciseInExamMode && assignment?.stats?.examStats?.state === ExamStatuses.STOPPED)

  const onAnswerSubmit = async () => {
    if (isExerciseInExamMode && (answerStatus === ProblemAnswerStatus.EXAM_MODE || isExamFinished)) {
      return onNextButtonPress()
    }

    if (answerStatus === ProblemAnswerStatus.MISTAKE) {
      currentProblemAnswerModel.resetStatus()
      resetGeogebraState(currentTask)
      currentProblemAnswerModel.setText(GEOGEBRA_ANSWER_CLEARED)
      return
    }

    if (isCorrectAnswerStatus) {
      return onNextButtonPress()
    }

    const cleanedLatex = cleanLatexAnswer(answerText)

    if (currentTask.geogebraSettings?.toolbarTools[0] === ToolbarToolsNames.POINT.toUpperCase()) {
      const pointsArray = transformPointsStringToArray(cleanedLatex)
      onSubmitClick(pointsArray, false)
      return
    }

    onSubmitClick(cleanedLatex, false)
  }

  return (
    <AnswerAreaGeogebra
      key={credits.problemId}
      buttonText={submitButtonProps.text}
      buttonStyle={submitButtonProps.style}
      isDisabled={!isExamFinished && isSubmitButtonDisabled}
      currentTask={currentTask}
      onAnswerSubmit={onAnswerSubmit}
      wiggleAnimation={wiggleAnimation}
      answerStatus={answerStatus}
      isExamFinished={isExamFinished}
    />
  )
}
