import { sendDrawnImage } from '../assignments/requests'

type Config = {
  filename?: string
  type: string
  isPublic: boolean
}

const DEFAULT_CONFIG: Config = {
  filename: undefined,
  type: '3',
  isPublic: true,
}

export const saveImageRequest = async (file?: File | Blob, config = DEFAULT_CONFIG) => {
  if (!file) throw new Error('No file selected')

  const uploadedImageFormData = new FormData()
  uploadedImageFormData.append('images', file, config?.filename)
  uploadedImageFormData.append('type', config?.type)

  !config?.isPublic && uploadedImageFormData.append('isPublic', '0')

  const response = await sendDrawnImage(uploadedImageFormData)
  if (!response?.image) throw new Error('There was an error uploading file')

  return response.image
}
