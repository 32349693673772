import React, { useEffect } from 'react'
import { removeNodeByText } from './geogebraApiHelpers'
import { useGeogebraModel } from '../model'

const removeLineSeparator = () => {
  const [lineSeparator] = document.querySelectorAll('.menuSeparator')
  lineSeparator?.remove()
}

// Workaround to remove LI elements from popup list
// in Geogebra applet after user right-clicks on the canvas
// for different type of geogebra applet objects
export const useMutateGeogebraNodes = (ref: React.RefObject<HTMLDivElement> | null) => {
  const setIsTextEditorOpen = useGeogebraModel((state) => state.actions.setIsTextEditorOpen)

  useEffect(() => {
    if (!ref?.current) return

    const observer = new MutationObserver(() => {
      const textEditor = document.querySelector('.GeoGebraFrame .textEditor')
      setIsTextEditorOpen(Boolean(textEditor))

      const popupPanel = document.querySelector('.gwt-PopupPanel .popupContent .gwt-MenuBar')
      const popupHeading = popupPanel?.children[0].textContent

      if (!popupPanel || !popupHeading) return

      if (popupHeading === 'Graphics') {
        removeNodeByText(['Show Axes', 'Show Grid', 'Navigation Bar', 'Standard View', 'Graphics ...'], popupPanel)
      }

      if (popupHeading.includes('Line') || popupHeading.includes('Parabola')) {
        removeNodeByText(['Show Object', 'Show Label', 'Show trace', 'Select Another', 'Rename'], popupPanel)
      }

      if (popupHeading.includes('Point')) {
        removeNodeByText(['Show Object', 'Show Label', 'Show trace', 'Rename'], popupPanel)

        // removes (x, y) coordinates from POINT string: "Point A(2, 4)" -> "Point A"
        popupPanel.children[0].textContent = popupHeading.replace(/\(.*?\)/g, '')
      }

      removeLineSeparator()
    })

    observer.observe(ref.current, {
      subtree: true,
      childList: true,
    })

    return () => observer.disconnect()
  }, [setIsTextEditorOpen, ref])
}
