import React, { useContext, useState } from 'react'
import styles from './style.module.scss'
import { MagmaChatIcon } from '../../../../ui/icons/MagmaChat/MagmaChatIcon'
import { hintMyScriptModel, useHintMyScriptModel } from '../../../AnswerHints/model'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { ChatMessage } from '../../../MagmaChat/components/ChatMessage/ChatMessage'
import { setChatOpen } from '../../../MagmaChat/models/isChatOpen'
import { canUserAccessChat } from '../../helpers'
import { setHintMessage } from '../../../MagmaChat/models/messages'
import { useAssignmentCredentials } from '../../../assignment/hooks/useAssignmentCredentials'
import { SlideInRight } from '../../../../ui/SlideInRight/SlideInRight'

type SolutionHintProps = {
  assignmentClassGrade?: number
}

export const SolutionHint = ({ assignmentClassGrade }: SolutionHintProps) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const answerHint = useHintMyScriptModel((state) => state.hint?.text)
  const [isBubbleOpen, setIsBubbleOpen] = useState(true)
  const credits = useAssignmentCredentials()

  const onClickIcon = () => {
    const canOpenChat = canUserAccessChat(userSettings, assignmentClassGrade) && answerHint

    if (canOpenChat) {
      hintMyScriptModel.setHintOpen(false)
      setChatOpen(true)
      setHintMessage({
        message: answerHint,
        problemId: credits.problemId,
        assignmentId: credits.id,
        isOwnMessage: false,
        options: [],
        threadId: '',
      })
      return
    }

    setIsBubbleOpen((prevState) => !prevState)
  }

  return (
    <SlideInRight className={styles.SolutionHintContainer}>
      <div className={styles.SolutionHint}>
        {isBubbleOpen && (
          <ChatMessage
            content={answerHint ?? ''}
            isIconHidden
            isOwnMessage={false}
            containerClassName={styles.HintBubble}
          />
        )}

        <button className={styles.HintChatIcon} onClick={onClickIcon}>
          <MagmaChatIcon />
        </button>
      </div>
    </SlideInRight>
  )
}
