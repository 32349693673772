import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const GraphFigure = ({ stroke = COLORS.NEUTRAL_2, size = 218 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 419 218' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M86.9405 170.622C82.7298 176.519 80.9447 175.874 82.7291 172.307C84.6755 168.416 103.878 153.571 120.988 126.434C126.611 117.516 135.995 107.867 143.354 96.8287C157.844 75.0944 164.689 67.4765 167.533 62.7831C171.121 56.8621 183.323 42.6081 207.154 42.6082C223.61 42.6084 237.418 57.6071 251.895 77.5855C256.28 83.6359 259.414 91.2679 265.325 96.8287C272.143 103.243 274.089 109.528 278.751 115.884C290.038 131.275 300.493 145.436 312.181 156.038C327.409 169.854 338.735 179.083 347.82 179.053C354.81 179.03 362.937 179.053 369.846 174.118C374.06 171.108 379.644 168.515 385.636 163.737C393.563 157.417 402.961 148.538 414.598 136.09'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M131.607 211.901C131.61 218.974 132.499 209.978 132.499 195.898C132.499 176.527 134.491 144.146 132.499 87.9472C130.573 33.6011 133.582 40.5796 132.818 13.908C132.054 -12.7636 137.726 22.814 136.661 23.228C135.596 23.6421 133.194 8.55877 132.818 9.68484C132.349 11.0925 129.784 19.7212 129.079 21.5432M6.43173 112.01C0.883563 112.772 4.8969 110.469 17.779 111.22C24.0534 111.585 45.6505 109.164 71.8032 110.822C84.4546 111.624 98.2144 110.5 111.772 110.46C130.672 110.403 149.225 110.565 164.078 111.209C186.158 112.164 200.294 111.093 211.448 111.209C228.286 111.382 237.705 112.351 252.405 111.924C262.738 111.623 275.925 111.37 296.72 111.438C305.311 111.466 310.312 111.765 309.975 112.123C309.731 112.382 306.679 111.864 299.097 115.232C291.517 118.6 311.852 112.076 310.913 111.793C309.74 111.438 298.821 107.278 297.413 106.809'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M206.23 107.036C206.555 106.125 205.948 105.121 205.599 106.266C205.249 107.41 205.203 110.234 205.017 112.102C204.831 113.969 206.583 115.323 207.214 116.093'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M130.668 178.755C129.292 178.382 127.68 177.985 130.716 178.288C133.75 178.59 133.984 178.613 135.174 178.497C136.365 178.379 137.578 178.029 138.559 177.655'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M125.77 42.6245C125.374 41.8776 125.864 41.6907 126.564 41.7604C127.265 41.8302 129.366 42.0395 131.934 42.2953C134.502 42.5511 137.863 41.9429 138.844 41.569'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M61.5612 105.479C61.4673 104.055 60.9451 102.982 61.2734 106.788C61.6017 110.592 60.2535 111.69 61.7956 115.144'
        stroke={stroke}
        strokeWidth='6.90825'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default GraphFigure
