import React, { useState, useContext, useCallback } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Logo from '../../../components/Logo'

import { AVAILABLE, ARCHIVED } from '../../../constants/exercisesTypesConstants'
import { Context as SidebarContext } from '../../../context/sidebarContext'
import { getCorrespondingStudentsURL } from '../../index'
import { isSwedenOrigin } from '../../userLocale'
import SidebarItem from './SidebarItem'
import { ProfileIcon } from '../../../ui/icons/Profile/ProfileIcon'
import { PracticeIcon } from '../../../ui/icons/Practice/PracticeIcon'
import { AssignmentIcon } from '../../../ui/icons/Assignment/AssignmentIcon'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'
import { RoutesPaths } from '../../../containers/paths'
import { setAssignmentId, setExerciseId } from '../../../features/assignment/model/credentials.model'

const SidebarOptions = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { state, closeSidebar } = useContext(SidebarContext)
  const { state: userSettings } = useContext(UserSettingsContext)
  const isProfilePage = history.location.pathname.includes('profile')
  const isExercisePage = history.location.pathname.includes('exercises')

  const setCurrentExercisesLink = useCallback(() => {
    if (!isExercisePage) return

    return history.location.pathname.includes(ARCHIVED) ? ARCHIVED : AVAILABLE
  }, [isExercisePage, history.location.pathname])

  const [activeExercisesLink, setActiveExercisesLink] = useState(setCurrentExercisesLink())

  const hideSidebar = useCallback(() => {
    if (state.show) {
      closeSidebar()
    }
  }, [state.show, closeSidebar])

  const goToProfile = useCallback(() => {
    if (isProfilePage) return

    history.push(RoutesPaths.PROFILE)
    hideSidebar()
  }, [isProfilePage, history, hideSidebar])

  const goToPracticePage = () => {
    setExerciseId('')
    setAssignmentId('')

    history.push(RoutesPaths.PRACTICE)
  }

  const setActiveExerciseLinkAndRedirect = useCallback(
    (exercisesType, redirectLink) => {
      setActiveExercisesLink(exercisesType)
      history.push(redirectLink)
      hideSidebar()
    },
    [hideSidebar, history]
  )

  const onExercisesLogoClick = useCallback(() => {
    if (activeExercisesLink) {
      return
    }

    setActiveExerciseLinkAndRedirect(AVAILABLE, `/exercises/${AVAILABLE}`)
  }, [activeExercisesLink, setActiveExerciseLinkAndRedirect])

  const isPracticeTabHidden = !isSwedenOrigin() && !userSettings.practiceMode
  return (
    <>
      <div className='logo-container' key='logo'>
        <a className='sidebar-logo' href={getCorrespondingStudentsURL()}>
          <Logo />
        </a>
      </div>
      <div className='sidebar-content-container' key='sidebar'>
        <SidebarItem
          onClick={onExercisesLogoClick}
          text={t('exercisesText')}
          isActive={isExercisePage}
          Icon={AssignmentIcon}
          fill
          stroke
          className='sidebar-assignment-item'
        />

        {!isPracticeTabHidden && (
          <SidebarItem onClick={goToPracticePage} text={t('studentPractiseText')} Icon={PracticeIcon} stroke fill />
        )}

        <SidebarItem
          onClick={goToProfile}
          text={t('accountText')}
          isActive={isProfilePage}
          Icon={ProfileIcon}
          stroke
          fill
        />
      </div>
    </>
  )
}

export default SidebarOptions
