import React from 'react'
import { toast, Zoom } from 'react-toastify'

import './styles.scss'

import ReloadPage from '../../components/ReloadPage'
import LogoutToast from '../../components/LogoutToast'

export const showErrorToast = ({ message, toastId, additionalStyle = '' }) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    transition: Zoom,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    className: `toast-container ${additionalStyle || ''}`,
    toastId: toastId,
  })
}

export const showSuccessToast = ({ message, toastId, additionalStyle }) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    transition: Zoom,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    className: `toast-container ${additionalStyle || ''}`,
    toastId: toastId,
  })
}

export const showWarningToast = ({ message, toastId }) => {
  toast.warn(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    transition: Zoom,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    className: 'toast-container warn-toast',
    toastId: toastId,
  })
}

export const showReloadPageToast = ({ toastId }) => {
  toast(<ReloadPage />, {
    position: toast.POSITION.TOP_CENTER,
    transition: Zoom,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    autoClose: false,
    className: 'toast-container pink-toast',
    toastId: toastId,
  })
}

export const showLogoutToast = ({ toastId, callback }) => {
  toast(<LogoutToast />, {
    position: toast.POSITION.BOTTOM_CENTER,
    transition: Zoom,
    pauseOnHover: false,
    closeOnClick: false,
    toastId: toastId,
    className: 'toast-container pink-toast',
    progressClassName: 'custom-progress-bar',
    autoClose: 4000,
    onClose: callback,
  })
}
