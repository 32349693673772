import { createEvent, createStore, restore, sample } from 'effector'
import { ChatMessage, EntityIds } from '../types'
import { addMessage } from '../helpers'
import { sendChatMessageFx } from './fetchChatMessageModel'
import { errorMessage } from '../constants'
import { $assignmentId, $exerciseId } from '../../assignment/model/credentials.model'

type ProblemMessages = Record<string, ChatMessage[]>
export type AssignmentMessages = Record<string, ProblemMessages>
export type AddMessagePayload = ChatMessage & EntityIds

export const resetChatMessages = createEvent()
export const removedLastMessage = createEvent<EntityIds>()
export const createInitialMessage = createEvent<AddMessagePayload>()
export const addedNewMessage = createEvent<AddMessagePayload>()
export const setHintMessage = createEvent<AddMessagePayload>()
const setMessages = createEvent<AssignmentMessages>()

export const $lastAddedMessageIds = createStore<EntityIds | null>(null).on(
  addedNewMessage,
  (_, { assignmentId, problemId }) => ({
    assignmentId,
    problemId,
  })
)

export const $messages = restore(setMessages, null)
  .on(createInitialMessage, (store, payload) => {
    const messagesCount = store?.[payload.assignmentId]?.[payload.problemId]?.length
    if (messagesCount) return
    return addMessage(store, payload)
  })
  .on(setHintMessage, (store, { assignmentId, problemId, ...message }) => {
    const messagesCount = Number(store?.[assignmentId]?.[problemId].length)
    if (messagesCount > 1) return

    const updatedMessages = { ...store }

    updatedMessages[assignmentId] = {
      ...updatedMessages[assignmentId],
      [problemId]: [message],
    }

    return updatedMessages
  })
  .on(addedNewMessage, addMessage)
  .on(removedLastMessage, (store, { assignmentId, problemId }) => {
    const updatedMessages = { ...store }

    if (updatedMessages[assignmentId] && updatedMessages[assignmentId][problemId]) {
      updatedMessages[assignmentId][problemId].pop()
    }

    return updatedMessages
  })
  .reset(resetChatMessages)

sample({
  clock: sendChatMessageFx.fail,
  source: { $assignmentId, $problemId: $exerciseId },
  fn: ({ $assignmentId: assignmentId, $problemId: problemId }, { error }) => ({
    ...errorMessage,
    problemId,
    assignmentId,
  }),
  filter: (_, { error }) => error.message !== 'Request aborted manually',
  target: addedNewMessage,
})
