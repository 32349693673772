import { createEffect, createEvent, restore } from 'effector'
import { api } from '../../../api'
import { InitialAlternatives } from '../../../api/api.chat'

export const fetchInitialAlternativesFx = createEffect(api.chat.alternatives)

export const resetInitialAlternatives = createEvent()
const setInitialAlternatives = createEvent<InitialAlternatives | null>()
export const $initialAlternatives = restore<InitialAlternatives | null>(setInitialAlternatives, null)
  .reset(resetInitialAlternatives)
  .on(fetchInitialAlternativesFx.doneData, (_, payload) => payload)
  .on(fetchInitialAlternativesFx.fail, () => ({
    options: {
      default: [],
      hints: [],
    },
  }))
