import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FiRefreshCw } from 'react-icons/fi'

import './styles.scss'
import AnswerInfoContext from '../../../context/answerInfoContext'
import AnswerLookingIcon from '../../AnswerLookingIcon'
import { Answer, Separator } from '@magmamath/ui'
import { hideHardBracketsFromKatex } from './helpers'
import { useOnProblemChange } from '../../../features/assignment/hooks/useOnProblemChange'
import { ZoomableImage } from '../../../features/zoomableImage/ZoomableImage'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { $toolsMode, ToolsMode } from '../../../features/toolsMode/model'
import { availableChatsModel } from '../../../features/MagmaChat/models/availableChatsModel'
import { useAssignmentCredentials } from '../../../features/assignment/hooks/useAssignmentCredentials'
import { canUserAccessChat } from '../../../features/FeedbackSolution/helpers'
import { Context as UserSettingsContext } from '../../../context/userSettingsContext'
import { useOverflowDetection } from '../../../hooks/useOverflowDetection'
import { assignmentModel } from '../../../features/assignment/model/assignment.model'

const AnswerHintContent = (props) => {
  const { t } = useTranslation()
  const { answerHint, characterType, answerType } = useContext(AnswerInfoContext)
  const { state: userSettings } = useContext(UserSettingsContext)
  const { parentRef, childRef, isOverflowing } = useOverflowDetection([props.showAnswerHint])
  const toolsMode = useUnit($toolsMode)
  const assignment = useUnit(assignmentModel.$assignment)

  const { id, problemId } = useAssignmentCredentials()
  const isChatAvailable = useStoreMap({
    store: availableChatsModel.$state,
    keys: [id, problemId],
    fn: (availableChats, [assignmentId, problemId]) =>
      !!availableChats[assignmentId]?.[problemId]?.isChatAvailable &&
      canUserAccessChat(userSettings, assignment?.classes[0]?.grade),
  })

  const onRefreshButtonClick = () => {
    if (!props.onRefreshButtonPress) {
      return
    }

    props.onRefreshButtonPress()
  }

  useOnProblemChange(() => {
    // Workaround for hiding additional pair of [] brackets from handwriting answer type
    hideHardBracketsFromKatex(answerType)
  })

  const renderAnswerHintContent = () => {
    if (props.answerHintError) {
      return (
        <div className='answer-hint-error-container'>
          <span className='answer-hint-error-text'>{t('answerHintError')}</span>
          <div
            className={`answer-hint-error-refresh ${props.isRefreshing ? 'refreshing' : ''}`}
            onClick={onRefreshButtonClick}
          >
            <FiRefreshCw />
          </div>
        </div>
      )
    }

    return (
      <div className='answer-hint-body' ref={parentRef}>
        {!!answerHint.example && (
          <ZoomableImage alt='answer-hint' imageProps={{ src: answerHint.example }} style={{ width: '100%' }} />
        )}

        <span className='answer-hint-text'>{t('rightAnswerText')}: </span>
        <div className='answer-hint-content-container'>
          <div className={clsx('answer-hint-content', { leftToRightAnimation: isOverflowing })} ref={childRef}>
            {answerHint.rightAnswer
              ? answerHint.rightAnswer?.map((answer, index) => (
                  <Answer
                    key={index}
                    answerValue={answer}
                    answerType={answerType}
                    characterType={characterType}
                    index={index}
                    length={answerHint.rightAnswer.length}
                    separator={Separator.COMMA}
                    keepLargeKatex={true}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='eye-icon'>
      <span id='answer-hint-icon'>
        <AnswerLookingIcon />
      </span>
      <div
        className={clsx('answer-hint-container', {
          show: props.showAnswerHint,
          'error-container': props.answerHintError,
          'answer-hint-container-simple-mode': toolsMode === ToolsMode.SIMPLE,
          'answer-hint-container-overflowing': (isOverflowing || isChatAvailable) && toolsMode !== ToolsMode.SIMPLE,
        })}
        id='answer-hint-container'
      >
        {renderAnswerHintContent()}
      </div>
    </div>
  )
}

export default AnswerHintContent
