import React from 'react'

export const StatsTrophyBadge = () => {
  return (
    <svg width='65' height='74' viewBox='0 0 65 74' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_696_201'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='4'
        width='65'
        height='65'
      >
        <circle cx='32.5' cy='36.5' r='32.5' fill='#FF3055' />
      </mask>
      <g mask='url(#mask0_696_201)'>
        <circle cx='32.5' cy='36.5' r='32.5' fill='#FF3056' />
        <path
          d='M45.406 30.8423L44.6326 35.2643L46.8436 35.6502C47.9297 35.8402 48.9753 35.9344 49.9776 35.9344C52.0958 35.9359 54.0241 35.5111 55.7489 34.6674C56.6569 34.2231 57.5066 33.6636 58.2995 32.9874C64.3281 27.8429 65.0806 17.3519 64.9938 11.5552C64.9549 9.06294 63.7163 6.71732 61.6773 5.28122C60.4147 4.38964 58.9472 3.90346 57.4468 3.8541C56.6405 3.82567 55.8252 3.92441 55.0264 4.15179C53.0891 4.70379 51.469 5.95738 50.4548 7.65976L46.0852 5.72551L44.2691 9.83036L49.5542 12.1685C50.4009 12.5425 51.3419 12.5619 52.2035 12.2209C53.0637 11.8813 53.7384 11.2246 54.1004 10.3719C54.4953 9.43842 55.2822 8.74431 56.256 8.46756C56.6599 8.35238 57.0728 8.31498 57.4782 8.35237C58.0512 8.40473 58.6076 8.60668 59.0923 8.94925C59.9615 9.56109 60.4895 10.5619 60.506 11.624C60.6631 21.9938 57.9659 26.7688 56.1842 28.7824C55.888 29.1189 55.6158 29.3777 55.3869 29.5737C53.4123 31.2566 50.8707 31.7982 47.6155 31.2297L45.406 30.8423Z'
          fill='#FFBE4E'
        />
        <path
          d='M15.6042 35.935C16.6067 35.935 17.6518 35.8402 18.7382 35.6502L20.9486 35.2636L20.1753 30.843L17.9651 31.2295C14.7092 31.7992 12.1685 31.2569 10.1952 29.5732C8.59482 28.2076 4.89223 23.7249 5.07563 11.6236C5.09164 10.5614 5.6203 9.5615 6.48929 8.94892C7.31714 8.36505 8.35098 8.18943 9.32544 8.46723C10.2999 8.74487 11.0858 9.43913 11.4817 10.3717C11.8437 11.2244 12.5175 11.8812 13.3788 12.2217C14.2403 12.562 15.1811 12.5429 16.028 12.1683L21.3116 9.82972L19.4952 5.72592L15.1267 7.65942C14.1127 5.95764 12.4931 4.7036 10.5555 4.1513C8.27005 3.49982 5.84559 3.9115 3.90342 5.28073C1.86535 6.71758 0.625975 9.06336 0.588276 11.5557C0.500317 17.3516 1.25307 27.8427 7.28199 32.9871C9.58094 34.9486 12.371 35.935 15.6042 35.935Z'
          fill='#FFBE4E'
        />
        <path
          d='M55.7489 34.6673C56.6569 34.223 57.5066 33.6635 58.2995 32.9874C64.3281 27.8429 65.0805 17.3519 64.9938 11.5551C64.9549 9.06288 63.7163 6.71725 61.6773 5.28116C60.4147 4.38958 58.9472 3.9034 57.4468 3.85403C57.8956 4.35218 59.3765 6.29091 57.4782 8.35231C58.0512 8.40467 58.6076 8.60662 59.0923 8.94919C59.9615 9.56103 60.4895 10.5618 60.506 11.6239C60.6631 21.9937 57.9659 26.7688 56.1842 28.7823C56.1842 28.7823 59.1073 32.0883 55.7489 34.6673Z'
          fill='#FFAE22'
        />
        <path
          opacity='0.5'
          d='M3.98909 5.21879C1.95013 6.65489 0.652791 8.79543 0.611153 11.5773C0.563193 14.7815 0.729693 19.3678 1.90644 23.6981C1.80317 20.9162 3.05355 19.6843 5.67706 19.5253C5.31387 17.3483 5.10527 14.7461 5.15228 11.6429C5.16873 10.5807 5.6968 9.57997 6.56594 8.96813C7.05062 8.62556 7.35503 8.37852 7.92797 8.32616C6.02963 6.26476 7.5106 4.32603 7.95938 3.82788C6.45896 3.87725 5.25166 4.32722 3.98909 5.21879Z'
          fill='white'
        />
        <path
          d='M14.0563 25.2246L16.8222 1.37427C16.9131 0.591294 17.5762 0.000549316 18.3646 0.000549316H47.2171C48.0055 0.000549316 48.6686 0.591294 48.7594 1.37427L51.5252 25.2246C52.1275 30.418 50.0483 35.5332 46.2545 38.1916L45.1508 38.965C43.0635 40.4276 40.8141 41.503 38.4795 42.1693C36.8788 42.6262 35.8211 44.4386 36.0446 46.381L36.8138 53.0648H28.768L29.5372 46.381C29.7607 44.4386 28.703 42.626 27.1023 42.1693C24.7678 41.503 22.5183 40.4278 20.431 38.965L19.3273 38.1916C15.5332 35.5332 13.454 30.4178 14.0563 25.2246Z'
          fill='#FFBE4E'
        />
        <path
          d='M33.3379 46.3802L33.7703 53.0655H36.813L36.0441 46.3802C35.8212 44.4384 36.8788 42.6254 38.4795 42.1691C40.8131 41.5034 43.063 40.4278 45.1499 38.9648L46.2539 38.1914C50.0475 35.5331 52.1269 30.4185 51.5255 25.2246L48.7588 1.37375C48.668 0.590775 48.0048 3.05176e-05 47.2165 3.05176e-05H40.3971L42.0426 25.2245C42.3807 30.4184 41.2124 35.533 39.0792 38.1913L38.4584 38.9646C37.2841 40.4277 36.02 41.5033 34.7066 42.1689C33.8077 42.6254 33.2123 44.4384 33.3379 46.3802Z'
          fill='#FFAE22'
        />
        <path
          d='M19.0515 56.2545H46.5288V55.3461C46.5288 53.1417 35.8725 51.3548 33.6681 51.3548H23.0428C20.8384 51.3548 19.0515 53.1417 19.0515 55.3461V56.2545Z'
          fill='#FFBE4E'
        />
        <path
          d='M33.6683 51.3548C35.8718 51.3548 37.6594 53.1425 37.6594 55.346V56.2555H46.5288V55.346C46.5288 53.1425 44.7412 51.3548 42.5377 51.3548H33.6683Z'
          fill='#FFAE22'
        />
        <path
          opacity='0.5'
          d='M20.491 0.179484C20.248 0.267835 19.933 0.469859 19.6702 0.746047C19.3983 1.03178 19.2949 1.27608 19.2786 1.40028C18.7434 5.4684 18.325 9.00288 17.8351 13.1415L17.8351 13.1415C17.4152 16.6892 16.5067 22.9033 16.1094 25.6851C15.7121 28.4669 15.7121 28.8487 15.7121 30.454C15.7121 33.4345 17.103 36.0176 17.103 36.0176C16.3082 35.4215 15.211 33.0371 15.007 32.5483C14.2989 30.8514 13.6286 27.9741 13.933 25.5373C14.5714 20.4275 15.0437 16.4387 15.4644 12.8847L15.4644 12.8845C15.9571 8.72375 16.3082 6.60973 16.7056 2.437C16.9043 0.350633 17.4106 3.05176e-05 18.5855 3.05176e-05H21.0483C20.8526 0.0561819 20.6657 0.115949 20.491 0.179484Z'
          fill='white'
        />
        <path
          d='M15.3634 59.9528V71.3548C15.3634 72.5115 16.2961 73.4491 17.4528 73.4491C20.9835 73.4491 28.1942 73.4491 32.791 73.4491H48.1588C49.2964 73.4491 50.2187 72.5269 50.2187 71.3892V59.9528C50.2187 57.9155 48.567 56.2638 46.5297 56.2638H19.0524C17.0151 56.2638 15.3634 57.9155 15.3634 59.9528Z'
          fill='#55ABE0'
        />
        <path
          opacity='0.5'
          d='M15.3644 71.1879C15.3644 71.8464 15.8982 72.3801 16.5566 72.3801C17.215 72.3801 17.7488 71.8464 17.7488 71.1879V59.2658C17.7488 58.3792 17.9192 57.8436 18.0982 57.4775C18.2772 57.1114 18.893 56.484 19.8865 56.2853H19.4891C18.6943 56.2853 17.6582 56.2853 16.7073 57.0801C15.8054 57.8339 15.3644 58.8852 15.3644 60.0606V71.1879Z'
          fill='white'
        />
        <path
          d='M38.7868 56.2638C40.8227 56.2638 42.4742 57.9153 42.4742 59.9528V73.4494H48.1588C49.2964 73.4494 50.2187 72.5272 50.2187 71.3895V59.9528C50.2187 57.9155 48.567 56.2638 46.5297 56.2638H38.7868Z'
          fill='#2488BB'
        />
      </g>
      <path
        d='M45.406 30.8423L44.6326 35.2643L46.8436 35.6502C47.9297 35.8402 48.9753 35.9344 49.9776 35.9344C52.0958 35.9359 54.0241 35.5111 55.7489 34.6674C56.6569 34.2231 57.5066 33.6636 58.2995 32.9874C64.3281 27.8429 65.0806 17.3519 64.9938 11.5552C64.9549 9.06294 63.7163 6.71732 61.6773 5.28122C60.4147 4.38964 58.9472 3.90346 57.4468 3.8541C56.6405 3.82567 55.8252 3.92441 55.0264 4.15179C53.0891 4.70379 51.469 5.95738 50.4548 7.65976L46.0852 5.72551L44.2691 9.83036L49.5542 12.1685C50.4009 12.5425 51.3419 12.5619 52.2035 12.2209C53.0637 11.8813 53.7384 11.2246 54.1004 10.3719C54.4953 9.43842 55.2822 8.74431 56.256 8.46756C56.6599 8.35238 57.0728 8.31498 57.4782 8.35237C58.0512 8.40473 58.6076 8.60668 59.0923 8.94925C59.9615 9.56109 60.4895 10.5619 60.506 11.624C60.6631 21.9938 57.9659 26.7688 56.1842 28.7824C55.888 29.1189 55.6158 29.3777 55.3869 29.5737C53.4123 31.2566 50.8707 31.7982 47.6155 31.2297L45.406 30.8423Z'
        fill='#FFBE4E'
      />
      <path
        d='M15.6042 35.935C16.6067 35.935 17.6518 35.8402 18.7382 35.6502L20.9486 35.2636L20.1753 30.843L17.9651 31.2295C14.7092 31.7992 12.1685 31.2569 10.1952 29.5732C8.59482 28.2076 4.89223 23.7249 5.07563 11.6236C5.09164 10.5614 5.6203 9.5615 6.48929 8.94892C7.31714 8.36505 8.35098 8.18943 9.32544 8.46723C10.2999 8.74487 11.0858 9.43913 11.4817 10.3717C11.8437 11.2244 12.5175 11.8812 13.3788 12.2217C14.2403 12.562 15.1811 12.5429 16.028 12.1683L21.3116 9.82972L19.4952 5.72592L15.1267 7.65942C14.1127 5.95764 12.4931 4.7036 10.5555 4.1513C8.27005 3.49982 5.84559 3.9115 3.90342 5.28073C1.86535 6.71758 0.625975 9.06336 0.588276 11.5557C0.500317 17.3516 1.25307 27.8427 7.28199 32.9871C9.58094 34.9486 12.371 35.935 15.6042 35.935Z'
        fill='#FFBE4E'
      />
      <path
        d='M55.7489 34.6673C56.6569 34.223 57.5066 33.6635 58.2995 32.9874C64.3281 27.8429 65.0805 17.3519 64.9938 11.5551C64.9549 9.06288 63.7163 6.71725 61.6773 5.28116C60.4147 4.38958 58.9472 3.9034 57.4468 3.85403C57.8956 4.35218 59.3765 6.29091 57.4782 8.35231C58.0512 8.40467 58.6076 8.60662 59.0923 8.94919C59.9615 9.56103 60.4895 10.5618 60.506 11.6239C60.6631 21.9937 57.9659 26.7688 56.1842 28.7823C56.1842 28.7823 59.1073 32.0883 55.7489 34.6673Z'
        fill='#FFAE22'
      />
      <path
        opacity='0.5'
        d='M3.98909 5.21879C1.95013 6.65489 0.652791 8.79543 0.611153 11.5773C0.563193 14.7815 0.729693 19.3678 1.90644 23.6981C1.80317 20.9162 3.05355 19.6843 5.67706 19.5253C5.31387 17.3483 5.10527 14.7461 5.15228 11.6429C5.16873 10.5807 5.6968 9.57997 6.56594 8.96813C7.05062 8.62556 7.35503 8.37852 7.92797 8.32616C6.02963 6.26476 7.5106 4.32603 7.95938 3.82788C6.45896 3.87725 5.25166 4.32722 3.98909 5.21879Z'
        fill='white'
      />
      <path
        d='M14.0563 25.2246L16.8222 1.37427C16.9131 0.591294 17.5762 0.000549316 18.3646 0.000549316H47.2171C48.0055 0.000549316 48.6686 0.591294 48.7594 1.37427L51.5252 25.2246C52.1275 30.418 50.0483 35.5332 46.2545 38.1916L45.1508 38.965C43.0635 40.4276 40.8141 41.503 38.4795 42.1693C36.8788 42.6262 35.8211 44.4386 36.0446 46.381L36.8138 53.0648H28.768L29.5372 46.381C29.7607 44.4386 28.703 42.626 27.1023 42.1693C24.7678 41.503 22.5183 40.4278 20.431 38.965L19.3273 38.1916C15.5332 35.5332 13.454 30.4178 14.0563 25.2246Z'
        fill='#FFBE4E'
      />
      <path
        d='M33.3379 46.3802L33.7703 53.0655H36.813L36.0441 46.3802C35.8212 44.4384 36.8788 42.6254 38.4795 42.1691C40.8131 41.5034 43.063 40.4278 45.1499 38.9648L46.2539 38.1914C50.0475 35.5331 52.1269 30.4185 51.5255 25.2246L48.7588 1.37375C48.668 0.590775 48.0048 3.05176e-05 47.2165 3.05176e-05H40.3971L42.0426 25.2245C42.3807 30.4184 41.2124 35.533 39.0792 38.1913L38.4584 38.9646C37.2841 40.4277 36.02 41.5033 34.7066 42.1689C33.8077 42.6254 33.2123 44.4384 33.3379 46.3802Z'
        fill='#FFAE22'
      />
      <path
        d='M19.0515 56.2545H46.5288V55.3461C46.5288 53.1417 35.8725 51.3548 33.6681 51.3548H23.0428C20.8384 51.3548 19.0515 53.1417 19.0515 55.3461V56.2545Z'
        fill='#FFBE4E'
      />
      <path
        d='M33.6683 51.3548C35.8718 51.3548 37.6594 53.1425 37.6594 55.346V56.2555H46.5288V55.346C46.5288 53.1425 44.7412 51.3548 42.5377 51.3548H33.6683Z'
        fill='#FFAE22'
      />
      <path
        opacity='0.5'
        d='M20.491 0.179484C20.248 0.267835 19.933 0.469859 19.6702 0.746047C19.3983 1.03178 19.2949 1.27608 19.2786 1.40028C18.7434 5.4684 18.325 9.00288 17.8351 13.1415L17.8351 13.1415C17.4152 16.6892 16.5067 22.9033 16.1094 25.6851C15.7121 28.4669 15.7121 28.8487 15.7121 30.454C15.7121 33.4345 17.103 36.0176 17.103 36.0176C16.3082 35.4215 15.211 33.0371 15.007 32.5483C14.2989 30.8514 13.6286 27.9741 13.933 25.5373C14.5714 20.4275 15.0437 16.4387 15.4644 12.8847L15.4644 12.8845C15.9571 8.72375 16.3082 6.60973 16.7056 2.437C16.9043 0.350633 17.4106 3.05176e-05 18.5855 3.05176e-05H21.0483C20.8526 0.0561819 20.6657 0.115949 20.491 0.179484Z'
        fill='white'
      />
    </svg>
  )
}
