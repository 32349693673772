import { useEffect, useRef, useCallback } from 'react'

const useTimeMeasure = (deps = []) => {
  const startTime = useRef(0)

  const getTimeSpentInMilliseconds = () => {
    return Date.now() - startTime.current
  }

  const resetStartTime = useCallback(() => {
    startTime.current = Date.now()
  }, [])

  useEffect(() => {
    resetStartTime()

    return resetStartTime
  }, deps)

  return { getTimeSpentInMilliseconds, resetStartTime }
}

export default useTimeMeasure
