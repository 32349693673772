import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const HelpQueueIcon = ({ size = 20, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_417_1233)'>
        <path
          d='M14.8339 13.2812L16.4324 10.2147C16.6725 9.75414 16.7976 9.245 16.7976 8.72858V1.8479C16.8522 1.4585 17.2386 0.679688 18.349 0.679688C19.1463 0.679688 19.6263 1.08098 19.874 1.4515C20.0375 1.69567 20.0638 1.99543 20.0638 2.28676V8.89951C20.0638 9.30569 19.9864 9.70842 19.8354 10.0872L17.5326 15.8661M17.4307 15.2365C17.7681 15.6744 18.3322 17.1507 18.5048 18.5392C18.5582 18.9683 18.1991 19.3197 17.7549 19.3197H2.1802C1.69457 19.3197 1.32183 18.903 1.44893 18.4469C2.28934 15.4309 5.43804 11.2095 10.1121 11.4937C12.364 11.4937 14.5354 12.2885 15.3397 12.8105M9.97822 9.18825C12.1252 9.18825 13.8656 7.49464 13.8656 5.40551C13.8656 3.31636 12.1252 1.62278 9.97822 1.62278C7.83135 1.62278 6.09093 3.31636 6.09093 5.40551C6.09093 7.49464 7.83135 9.18825 9.97822 9.18825Z'
          stroke={fill}
          strokeWidth='1.328'
        />
      </g>
      <defs>
        <clipPath id='clip0_417_1233'>
          <rect width='20' height='20' fill='#ffffff' transform='translate(0.742188)' />
        </clipPath>
      </defs>
    </svg>
  )
}
