import React from 'react'
import { IconProps } from '../_types'
import { COLORS } from '@magmamath/ui'

export const TrashIcon = ({ size = 25, color = COLORS.NEUTRAL_1 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_122_1657)'>
        <path
          d='M7.03125 4.64552L9.40333 2.27356C9.69605 1.98083 10.0931 1.81641 10.5071 1.81641H14.3846C14.7986 1.81641 15.1955 1.98083 15.4883 2.27356L17.8604 4.64552'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M20.7 21.7365L21.8358 6.32213C21.9026 5.4173 21.1865 4.64648 20.2792 4.64648H4.84627C3.926 4.64648 3.20548 5.43847 3.29225 6.35464L4.7526 21.7691C4.8285 22.5704 5.50154 23.1827 6.30662 23.1827H19.1434C19.9609 23.1827 20.64 22.5519 20.7 21.7365Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path d='M0.789062 4.64648H24.2032' stroke={color} strokeWidth='1.66' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_122_1657'>
          <rect width='25' height='25' fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
