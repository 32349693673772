import React from 'react'

const SimpleModePlayIcon = () => {
  return (
    <svg width='38' height='37' viewBox='0 0 38 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.57373 6.02248C3.57373 2.91544 6.89704 0.939176 9.62696 2.42283L32.5855 14.9003C35.4396 16.4515 35.4397 20.5485 32.5856 22.0996L9.62697 34.5771C6.89705 36.0608 3.57373 34.0845 3.57373 30.9775V6.02248Z'
        fill='#666679'
      />
    </svg>
  )
}

export default SimpleModePlayIcon
