import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface SubmitAnswerButtonProps {
  onClick: () => void
  animation?: boolean
  style: string
  modeStyle: string
  disabled: boolean
  withMargin?: boolean
  className?: string
}

const SubmitAnswerButton: React.FC<PropsWithChildren<SubmitAnswerButtonProps>> = ({
  children,
  onClick,
  animation,
  style,
  modeStyle,
  disabled,
  withMargin,
  className = '',
}) => {
  return (
    <div
      className={clsx(
        styles.SubmitButton,
        {
          [styles.Wiggle]: animation,
          [styles.WithMargin]: withMargin,
          [styles.Disabled]: disabled,
        },
        className
      )}
    >
      <button
        className={clsx(styles.SubmitAnswerButton, {
          [styles[style]]: style,
          [styles[modeStyle]]: modeStyle,
        })}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  )
}

export default SubmitAnswerButton
