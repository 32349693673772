import React from 'react'
import Dialog from '../../../ui/dialog'
import { Button } from '../../../ui/button/Button'
import { useTranslation } from 'react-i18next'

type EndExamPopupProps = {
  isOpen: boolean
  onExit: () => void
  onBackToExercises: () => void
}

// TODO: exam popups feature
const EndExamPopup = ({ isOpen, onExit, onBackToExercises }: EndExamPopupProps) => {
  const { t } = useTranslation()

  return (
    <Dialog isOpen={isOpen} onExitIconClick={onExit}>
      <Dialog.Title text={t('cheatDetection.sureYouWantToExit')} />
      <Dialog.Content text={t('cheatDetection.cannotGoBackIn')} />
      <Dialog.Actions>
        <Button text={t('endExerciseButtonText')} variant='error' onClick={onBackToExercises} />
        <Button text={t('stayText')} variant='success' onClick={onExit} />
      </Dialog.Actions>
    </Dialog>
  )
}

export default EndExamPopup
