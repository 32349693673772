import React from 'react'
import styles from './examPausedPopup.module.scss'
import { useTranslation } from 'react-i18next'

import Dialog from '../../../../ui/dialog'
import { Button } from '../../../../ui/button/Button'
import { navigateToAssignmentsList } from '../../../../helpers/exercises'

export const ExamPausedPopup = () => {
  const { t } = useTranslation()
  return (
    <Dialog isOpen containerStyles={styles.ExamPausedPopup}>
      <Dialog.Title text={t('cheatDetection.examPausedTitle')} />
      <Dialog.Content text={t('cheatDetection.examPausedContent')} />
      <Dialog.Actions>
        <Button
          text={t('cheatDetection.examPausedButtonText')}
          variant='success'
          onClick={navigateToAssignmentsList}
          className={styles.ExamPausedPopupButton}
        />
      </Dialog.Actions>
    </Dialog>
  )
}
