import { attach, createEffect, createEvent, createStore, sample } from 'effector'
import { $isCheatingDetectionEnabled } from './isCheatingDetectionEnabled'
import { $exam } from '../../Problem/models/exam/exam'
import { assignmentModel } from '../../assignment/model/assignment.model'

import { CheatType } from '../../../api/types.cheats'
import { api } from '../../../api'

type CheatDetected = {
  time: number
  cheatType: CheatType
}

type SendCheatReportParams = {
  cheatType: CheatType | null
  exerciseId?: string
}

export const tabBlurred = createEvent()
export const resetCheatDetection = createEvent()

export const cheatDetected = attach({
  source: assignmentModel.$assignment,
  mapParams: (cheatType: CheatType | null, exercise) => ({
    cheatType,
    exerciseId: exercise?._id,
  }),
  effect: createEffect(({ cheatType, exerciseId }: SendCheatReportParams) => {
    if (!exerciseId || cheatType === null) return null
    return api.cheatReport({ exerciseId, cheatType })
  }),
})

export const $detectedCheat = createStore<CheatDetected | null>(null)
  .on(cheatDetected, (_, cheatType) => {
    if (!cheatType) {
      return null
    }

    return {
      time: Date.now(),
      cheatType: cheatType,
    }
  })
  .reset(resetCheatDetection)

sample({
  source: { isCheatingDetectionEnabled: $isCheatingDetectionEnabled, exam: $exam },
  clock: tabBlurred,
  filter: ({ isCheatingDetectionEnabled, exam }) =>
    !(exam.isPaused || exam.isPausedByTeacher || !isCheatingDetectionEnabled),
  fn: () => CheatType.LEAVING_BROWSER_OR_TAB,
  target: cheatDetected,
})
