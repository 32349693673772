import React, { useState, useEffect, useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import i18next from 'i18next'

import './styles.scss'
import { LOADING_ERROR } from '../../constants/loadingConstants'
import NotFoundMessageComponent from '../../components/NotFoundMessageComponent'
import { autologinAndRedirectUser } from '../../features/auth/loginModel'
import SocketContext from '../../context/socketContext'
import { RoutesPaths } from '../paths'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const AutoLogin = () => {
  const query = useQuery()
  const { socket } = useContext(SocketContext)

  const [loadingError, setLoadingError] = useState(false)

  useEffect(() => {
    localStorage.clear()
    const sendRequest = async () => {
      const autologinToken = query.get('autologinToken')
      const userId = query.get('userId')
      const exerciseId = query.get('exerciseId')
      const problemId = query.get('problemId')
      const subProblemId = query.get('subproblemId')
      const redirectURL = query.get('redirectURL')

      const redirectResponse = await autologinAndRedirectUser(
        autologinToken,
        userId,
        exerciseId,
        problemId,
        subProblemId,
        redirectURL,
        socket
      )
      if (redirectResponse === LOADING_ERROR) {
        setLoadingError(true)
        return
      }

      setLoadingError(false)
    }

    sendRequest()
  }, [])

  const renderLoadingMessage = () => {
    if (!loadingError) {
      return <NotFoundMessageComponent message={i18next.t('waitingForAutologinText')} />
    }

    return <Redirect to={RoutesPaths.LOGIN} />
  }

  return <div className='autologin-page-container'>{renderLoadingMessage()}</div>
}

export default AutoLogin
