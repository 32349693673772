import React from 'react'
import styles from './ExerciseExamMode.module.scss'
import clsx from 'clsx'
import type { AssignmentSettings } from '../../types'
import { ExamStatuses } from '../../types'
import { getTextButtonFn } from './helpers'

type ExerciseExamModeProps = {
  testModeEnabled: boolean
  settings: AssignmentSettings
  examStatus?: ExamStatuses
}

const ExerciseExamMode = ({
  testModeEnabled,
  settings,
  examStatus = ExamStatuses.NOT_STARTED,
}: ExerciseExamModeProps) => {
  const isInActiveTestMode = testModeEnabled && (!settings || (settings && !settings?.assessed))

  if (!isInActiveTestMode && !settings?.assessed) return <span className={styles.CardTagEmpty} />

  const isAssessed = settings?.assessed || examStatus === ExamStatuses.FINISHED
  const isLockedDistrict = settings?.state === ExamStatuses.DISTRICT_LOCKED
  const buttonTextFn = getTextButtonFn(examStatus)
  const buttonText = buttonTextFn?.(settings)

  return (
    <span
      className={clsx(styles.CardTag, {
        [styles.Assessed]: isAssessed || isLockedDistrict,
        [styles.NonAssessed]: !isAssessed,
      })}
    >
      {buttonText}
    </span>
  )
}

export default ExerciseExamMode
