import React from 'react'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { DEFAULT_ICON_FILL } from '../constants'

export const MassIcon = ({ size = 28, fill = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_32_2777)'>
        <path
          d='M16.6763 6.50223C16.6763 4.29832 14.8963 2.48999 12.6641 2.48999C10.4319 2.48999 8.65186 4.27007 8.65186 6.50223C8.65186 8.70614 10.4319 10.5145 12.6641 10.5145C14.868 10.5145 16.6763 8.70614 16.6763 6.50223ZM12.6641 9.52554C10.997 9.52554 9.64079 8.16929 9.64079 6.50223C9.64079 4.83517 10.997 3.47892 12.6641 3.47892C14.3312 3.47892 15.6874 4.83517 15.6874 6.50223C15.6874 8.16929 14.3312 9.52554 12.6641 9.52554Z'
          fill={fill}
        />
        <path
          d='M25.0964 23.512L22.7795 12.3795C22.4405 10.7407 20.9994 9.55396 19.3041 9.55396H18.1174C18.6543 8.62153 18.9368 7.57609 18.9086 6.50239C18.9086 3.05526 16.1113 0.229736 12.6359 0.229736C9.16051 0.229736 6.36324 3.027 6.36324 6.50239C6.36324 7.57609 6.64579 8.62153 7.15439 9.55396H5.96767C4.30061 9.55396 2.83134 10.7407 2.49228 12.3795L0.231863 23.512C-0.16371 25.4334 1.05126 27.2982 2.97262 27.6938C3.19866 27.7503 3.45296 27.7786 3.70725 27.7786H21.6211C23.5707 27.7786 25.1812 26.1963 25.1812 24.2184C25.1812 23.9924 25.153 23.7663 25.0964 23.512ZM23.5989 25.8572C23.1186 26.4506 22.3839 26.7896 21.6211 26.7896H3.70725C2.29449 26.7896 1.13603 25.6312 1.13603 24.2184C1.13603 24.0489 1.16428 23.8793 1.19254 23.7098L3.50947 12.6055C3.76376 11.4188 4.80921 10.5711 6.02418 10.5711H8.14332C8.42587 10.5711 8.62366 10.3451 8.62366 10.0625C8.62366 9.94953 8.5954 9.83651 8.51064 9.75174C7.776 8.81932 7.38043 7.68911 7.38043 6.50239C7.38043 3.59211 9.75387 1.21867 12.6642 1.21867C15.5744 1.21867 17.9479 3.59211 17.9479 6.50239C17.9479 7.68911 17.5523 8.81932 16.8177 9.75174C16.6481 9.97778 16.6764 10.2886 16.9024 10.4581C16.9872 10.5146 17.1002 10.5711 17.2132 10.5711H19.3324C20.5474 10.5711 21.5928 11.4188 21.8471 12.6055L24.164 23.7381C24.3053 24.4727 24.1075 25.2639 23.5989 25.8572Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_32_2777'>
          <rect width='25.2903' height='28' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
