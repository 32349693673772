import React, { PropsWithChildren } from 'react'
import styles from '../styles.module.scss'
import clsx from 'clsx'

type DialogActionProps = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const DialogActions = ({ children, className, ...restProps }: PropsWithChildren<DialogActionProps>) => {
  const mergedClassNames = clsx(styles.ModalButtons, className)
  return (
    <div className={mergedClassNames} {...restProps}>
      {children}
    </div>
  )
}
