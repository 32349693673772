import React from 'react'
import styles from '../styles.module.scss'

type DialogContentProps = {
  text: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>

export const DialogContent = ({ text, ...restProps }: DialogContentProps) => {
  return (
    <p id='description' className={styles.ModalDescription} {...restProps}>
      {text}
    </p>
  )
}
