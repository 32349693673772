import { POWER_OF_COMMAND } from '../../../constants/katexConstants'
import { nativeLanguageISOCodeMap } from '../../../config/allLanguages'
import { SWEDISH } from '../../../config/languages'
import { TO_THE_POWER_OF, TO_THE_POWER_OF_SWEDISH } from '../../../constants/textToSpeechConstants'

export const formatTextToSpeechValue = (text: string, language: string): string => {
  return text.replace(
    POWER_OF_COMMAND,
    language === nativeLanguageISOCodeMap[SWEDISH] ? TO_THE_POWER_OF_SWEDISH : TO_THE_POWER_OF
  )
}
