import React from 'react'
import styles from './helpQueueButton.module.scss'
import { IconButton, IconButtonProps } from '../../../../ui/IconButton'
import { HelpQueueIcon } from '../../../../ui/icons/HelpQueue'
import { COLORS } from '@magmamath/ui'
import { ExerciseThemeVariantMapper } from '../../../Problem/problem.style.mode.helpers'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { useStoreMap, useUnit } from 'effector-react'
import { $helpQueue } from '../../model/model'
// import { $isUserInHelpQueue } from '../../model/model'

type HelpQueueButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const HelpQueueButton = ({ onClick }: HelpQueueButtonProps) => {
  const isUserInQueue = useStoreMap($helpQueue, (state) => !!state?.self?._id)
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  return (
    <IconButton
      className={styles.HelpQueueButton}
      rounded='full'
      variant='gray'
      withShadow
      isActive={isUserInQueue}
      activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']}
      onClick={onClick}
    >
      {isUserInQueue ? <HelpQueueIcon fill={COLORS.NEUTRAL_1} /> : <HelpQueueIcon />}
    </IconButton>
  )
}
