import { StoreApi, UseBoundStore } from 'zustand'
import { ZustandEvent, ZustandEventReducer } from './types.event'

export const addEvent = <T, P = void>(model: UseBoundStore<StoreApi<T>>, fn: ZustandEventReducer<T, P>) => {
  return zustandEvent<P>().addTo(model, fn)
}

export const zustandEvent = <P = void>() => {
  const watchers: ((payload: P) => void)[] = []

  const event = ((payload) => {
    watchers.forEach((watcher) => {
      watcher(payload)
    })
  }) as ZustandEvent<P>

  event.watch = (watcher: (value: P) => void) => {
    watchers.push(watcher)
    return () => {
      const index = watchers.findIndex((e) => e === watcher)
      if (index === -1) return
      watchers.splice(index, 1)
    }
  }

  event.addTo = (model, fn) => {
    watchers.push((payload: P) => {
      const state = model.getState()
      const result = fn(payload, state)
      if (result === undefined) return
      model.setState(result)
    })
    return event
  }

  return event
}
