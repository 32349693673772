import { useEffect, useState } from 'react'

export enum ScriptLoadingStatus {
  READY = 'ready',
  LOADING = 'loading',
  IDLE = 'idle',
  ERROR = 'error',
}

export const useLoadExternalScript = (url: string, id?: string) => {
  const [loadingStatus, setLoadingStatus] = useState<ScriptLoadingStatus>(
    url ? ScriptLoadingStatus.LOADING : ScriptLoadingStatus.IDLE
  )

  useEffect(() => {
    if (!url) return setLoadingStatus(ScriptLoadingStatus.IDLE)

    const isScriptUsed = document.querySelector(`script[src="${url}"]`)
    const scriptToInject = document.createElement('script')

    const handleUpdateLoadingStatus = (event: Event) => {
      if (event.type === 'load') {
        return setLoadingStatus(ScriptLoadingStatus.READY)
      }

      return setLoadingStatus(ScriptLoadingStatus.ERROR)
    }

    if (!isScriptUsed) {
      scriptToInject.type = 'application/javascript'
      scriptToInject.src = url
      scriptToInject.async = true
      scriptToInject.id = id ?? ''

      document.body.appendChild(scriptToInject)
      scriptToInject.addEventListener('load', handleUpdateLoadingStatus)
      scriptToInject.addEventListener('error', handleUpdateLoadingStatus)

      return
    }

    scriptToInject.addEventListener('load', handleUpdateLoadingStatus)
    scriptToInject.addEventListener('error', handleUpdateLoadingStatus)
    setLoadingStatus(ScriptLoadingStatus.READY)

    return () => {
      scriptToInject.removeEventListener('load', handleUpdateLoadingStatus)
      scriptToInject.removeEventListener('error', handleUpdateLoadingStatus)
    }
  }, [id, url])

  return loadingStatus
}
