import { createEffect } from 'effector'
import { getSkillsTheory } from './requests'
import { getPracticeHistoryDrawing } from '../../PracticeMode/requests'
import { practiceTheoryModel } from '../../PracticeMode/models/practiceTheory'
import { showErrorToast } from '../../../constants/toasts'
import i18next from 'i18next'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'

export const getSkillTheoryFx = createEffect(async (theoryId: string) => {
  const theoryResponse = await getSkillsTheory(theoryId)
  const historyDrawingResponse = await getPracticeHistoryDrawing(theoryResponse?.historyId)
  return { theory: theoryResponse, historyDrawing: historyDrawingResponse }
})

getSkillTheoryFx.doneData.watch(({ theory, historyDrawing }) => {
  practiceTheoryModel.setTheory(theory)
  practiceTheoryModel.setDrawingHistory(historyDrawing)
})

getSkillTheoryFx.fail.watch((error: any) => {
  if (error?.response?.status === 404) return
  showErrorToast({
    message: error.response
      ? i18next.t(`${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`)
      : error.message,
    toastId: 'loadTheoryToast',
  })
})
