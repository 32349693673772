import React from 'react'

export const CompletedAssignmentsIcon = () => {
  return (
    <svg width='70' height='71' viewBox='0 0 70 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.449493' width='70' height='70' rx='35' fill='#55ABE0' />
      <path
        d='M29.9805 26.1275C29.9805 25.3355 29.3384 24.6934 28.5464 24.6934C27.7543 24.6934 27.1122 25.3355 27.1122 26.1275V28.2788H24.961C24.1689 28.2788 23.5268 28.9208 23.5268 29.7129C23.5268 30.505 24.1689 31.147 24.961 31.147H27.1122V33.2983C27.1122 34.0903 27.7543 34.7324 28.5464 34.7324C29.3384 34.7324 29.9805 34.0903 29.9805 33.2983V31.147H32.1317C32.9238 31.147 33.5659 30.505 33.5659 29.7129C33.5659 28.9208 32.9238 28.2788 32.1317 28.2788H29.9805V26.1275Z'
        fill='white'
      />
      <path
        d='M24.997 44.1414C24.4369 44.7014 24.4369 45.6095 24.997 46.1695C25.5571 46.7296 26.4651 46.7296 27.0252 46.1695L28.5463 44.6484L30.0675 46.1696C30.6276 46.7296 31.5356 46.7296 32.0957 46.1696C32.6557 45.6095 32.6557 44.7014 32.0957 44.1414L30.5745 42.6202L32.0957 41.0991C32.6557 40.539 32.6557 39.6309 32.0957 39.0709C31.5356 38.5108 30.6276 38.5108 30.0675 39.0709L28.5463 40.592L27.0252 39.0709C26.4651 38.5108 25.5571 38.5108 24.997 39.0709C24.4369 39.631 24.4369 40.539 24.997 41.0991L26.5182 42.6202L24.997 44.1414Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.1512 29.7129C37.1512 28.9208 37.7933 28.2788 38.5854 28.2788H45.039C45.8311 28.2788 46.4732 28.9208 46.4732 29.7129C46.4732 30.505 45.8311 31.147 45.039 31.147H38.5854C37.7933 31.147 37.1512 30.505 37.1512 29.7129Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.1512 40.469C37.1512 39.6769 37.7933 39.0349 38.5854 39.0349H45.039C45.8311 39.0349 46.4732 39.6769 46.4732 40.469C46.4732 41.2611 45.8311 41.9031 45.039 41.9031H38.5854C37.7933 41.9031 37.1512 41.2611 37.1512 40.469Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.1512 44.7714C37.1512 43.9794 37.7933 43.3373 38.5854 43.3373H45.039C45.8311 43.3373 46.4732 43.9794 46.4732 44.7714C46.4732 45.5635 45.8311 46.2056 45.039 46.2056H38.5854C37.7933 46.2056 37.1512 45.5635 37.1512 44.7714Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0732 21.8251C17.0732 19.4489 18.9994 17.5227 21.3756 17.5227H48.6244C51.0006 17.5227 52.9268 19.4489 52.9268 21.8251V49.0739C52.9268 51.4501 51.0006 53.3763 48.6244 53.3763H21.3756C18.9994 53.3763 17.0732 51.4501 17.0732 49.0739V21.8251ZM21.3756 20.391C20.5836 20.391 19.9415 21.033 19.9415 21.8251V49.0739C19.9415 49.8659 20.5836 50.508 21.3756 50.508H48.6244C49.4165 50.508 50.0585 49.8659 50.0585 49.0739V21.8251C50.0585 21.033 49.4165 20.391 48.6244 20.391H21.3756Z'
        fill='white'
      />
    </svg>
  )
}
