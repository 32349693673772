import { AREA, CURRENCY, LENGTH, MASS, TIME, VOLUME } from '../../../constants/unitTypes'
import lengthIcon from '../../../assets/images/ruler.svg'
import areaIcon from '../../../assets/images/square-meter.svg'
import volumeIcon from '../../../assets/images/volume.svg'
import massIcon from '../../../assets/images/weight.svg'
import timeIcon from '../../../assets/images/time.svg'
import currencyIcon from '../../../assets/images/currency.svg'
import { Unit } from '../types'

export const UNITS_BUTTONS = [
  { title: LENGTH, icon: lengthIcon },
  { title: AREA, icon: areaIcon },
  { title: VOLUME, icon: volumeIcon },
  { title: MASS, icon: massIcon, fullWidth: true },
  { title: TIME, icon: timeIcon },
  { title: CURRENCY, icon: currencyIcon },
]

export const LOCALIZED_UNITS_SYMBOLS: {
  [key: string]: { [key: string]: Unit[] }
} = {
  sv: {
    [LENGTH]: [
      { symbol: 'mm' },
      { symbol: 'cm' },
      { symbol: 'dm' },
      { symbol: 'm' },
      { symbol: 'km' },
      { symbol: 'mil' },
    ],
    [MASS]: [{ symbol: 'mg' }, { symbol: 'g' }, { symbol: 'hg' }, { symbol: 'kg' }, { symbol: 'ton' }],
    [AREA]: [
      { symbol: 'mm', mathPower: 2 },
      { symbol: 'cm', mathPower: 2 },
      { symbol: 'dm', mathPower: 2 },
      { symbol: 'm', mathPower: 2 },
      { symbol: 'km', mathPower: 2 },
    ],
    [VOLUME]: [
      { symbol: 'mm', mathPower: 3 },
      { symbol: 'cm', mathPower: 3 },
      { symbol: 'dm', mathPower: 3 },
      { symbol: 'm', mathPower: 3 },
      { symbol: 'ml' },
      { symbol: 'cl' },
      { symbol: 'dl' },
      { symbol: 'l' },
    ],
    [TIME]: [{ symbol: 's' }, { symbol: 'min' }, { symbol: 'h' }],
    [CURRENCY]: [{ symbol: 'kr' }, { symbol: '$' }, { symbol: '€' }, { symbol: '¢' }],
  },
  en: {
    [LENGTH]: [
      { symbol: 'mm' },
      { symbol: 'cm' },
      { symbol: 'm' },
      { symbol: 'km' },
      { symbol: 'in' },
      { symbol: 'ft' },
      { symbol: 'yd' },
      { symbol: 'mi' },
    ],
    [MASS]: [
      { symbol: 'mg' },
      { symbol: 'g' },
      { symbol: 'kg' },
      { symbol: 'ton' },
      { symbol: 'T' },
      { symbol: 'oz' },
      { symbol: 'lb' },
    ],
    [AREA]: [
      { symbol: 'mm', mathPower: 2 },
      { symbol: 'cm', mathPower: 2 },
      { symbol: 'm', mathPower: 2 },
      { symbol: 'km', mathPower: 2 },
      { symbol: 'in', mathPower: 2 },
      { symbol: 'ft', mathPower: 2 },
      { symbol: 'yd', mathPower: 2 },
      { symbol: 'mi', mathPower: 2 },
    ],
    [VOLUME]: [
      { symbol: 'mm', mathPower: 3 },
      { symbol: 'cm', mathPower: 3 },
      { symbol: 'm', mathPower: 3 },
      { symbol: 'in', mathPower: 3 },
      { symbol: 'ft', mathPower: 3 },
      { symbol: 'ml' },
      { symbol: 'l' },
      { symbol: 'floz' },
      { symbol: 'cup' },
      { symbol: 'pt' },
      { symbol: 'qt' },
      { symbol: 'gal' },
    ],
    [TIME]: [{ symbol: 'sec' }, { symbol: 'min' }, { symbol: 'h' }],
    [CURRENCY]: [{ symbol: '$' }, { symbol: '€' }, { symbol: '¢' }],
  },
}
