import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const NotFoundMessageComponent = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className="not-found-message-container">
            <span className="not-found-message">{t(message)}</span>
        </div>
    );
};

export default NotFoundMessageComponent;