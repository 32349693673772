import React, { useContext } from 'react'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import type { AnswerChoiceOption } from '../types'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../../features/Problem/models/exerciseMode'
import AnswerInfoContext from '../../../../../context/answerInfoContext'
import { assignmentModel } from '../../../../../features/assignment/model/assignment.model'

type ChoiceVariantIconProps = {
  option: AnswerChoiceOption
  showNumberIcons: boolean
  isSelected: boolean
  getSelectedItemOrder?: (text: string) => number
  order: number
}

export const ChoiceVariantIcon = ({
  showNumberIcons,
  isSelected,
  getSelectedItemOrder,
  option,
  order,
}: ChoiceVariantIconProps) => {
  const { style, isExamFinished } = useContext(AnswerInfoContext)
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  if (!showNumberIcons || !isSelected) return null
  return (
    <span
      className={clsx('selected-icon', style, {
        'exam-mode-not-selected': isExamMode && !isExamFinished(),
        [style]: isAnswerPending ? false : isExamMode && isExamFinished(),
      })}
    >
      {getSelectedItemOrder ? getSelectedItemOrder(option.variant) : order}
    </span>
  )
}
