import { createEvent, restore } from 'effector'

export enum FocusedInput {
  SPREADSHEET_CELL = 'spreadsheetCell',
  TEXT_INPUT = 'textInput',
}

export const updateInitialFocus = createEvent<FocusedInput>()

export const $focusedInputType = restore(updateInitialFocus, null)

export const setHasCanvasHistory = createEvent<boolean>()
export const $hasCanvasHistory = restore(setHasCanvasHistory, false)
