import React from 'react'

import './styles.scss'

import { LETTERS } from '../../../../constants/numpadTypes'
import { checkIsSwedenLocale } from '../../../../helpers/userLocale'
import NumpadMathButton from '../Numpads/NumpadMath/NumpadMathButton'
import backspaceIcon from '../../../../assets/images/backspace.png'

const ShortcutButtonsPanel = ({ currentNumpad, onPress, backspacePress, disableButton }) => {
  if (currentNumpad === LETTERS) {
    return (
      <div className='shortcut-backspace-container'>
        <div className='shortcut-backspace'>
          <NumpadMathButton styleClass='backspace-button' onClick={backspacePress} key='shortcut-button-backspace'>
            <img src={backspaceIcon} alt='backspace' />
          </NumpadMathButton>
        </div>
      </div>
    )
  }
  const array = ['1', '2', '3', '4', '5', '6', checkIsSwedenLocale() ? ',' : '.', '7', '8', '9', '0']

  const renderArrayMapper = (arrayPart) => {
    const mappedArray = []
    arrayPart.forEach((button) => {
      mappedArray.push(
        <button
          className='shortcut-button'
          key={`shortcut-button-${button}`}
          onClick={() => onPress(button)}
          disabled={disableButton}
        >
          {button}
        </button>
      )
    })

    return mappedArray
  }

  const renderShortcutButtons = () => {
    const PARTS_COUNT = 3
    const partLength = array.length / PARTS_COUNT
    const buttonsPart = Array(PARTS_COUNT)
      .fill(null)
      .map((_, i) => {
        const part = array.slice(partLength * i, partLength * (i + 1))
        return renderArrayMapper(part)
      })

    buttonsPart[0].push(
      <NumpadMathButton styleClass='backspace-button' onClick={backspacePress} key='shortcut-button-backspace'>
        <img src={backspaceIcon} alt='backspace' />
      </NumpadMathButton>
    )

    return (
      <>
        {buttonsPart.map((part) => {
          return (
            <div className='buttons-row' key={part[0].key}>
              {part}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className='shortcut-container'>
      <div className='shortcut-buttons'>{renderShortcutButtons()}</div>
    </div>
  )
}

export default ShortcutButtonsPanel
