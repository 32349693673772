import React from 'react'

export const SolvedIcon = () => {
  return (
    <svg width='70' height='71' viewBox='0 0 70 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.449493' width='70' height='70' rx='35' fill='#FD7E14' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35 17.5227C25.0993 17.5227 17.0732 25.5488 17.0732 35.4495C17.0732 45.3502 25.0993 53.3763 35 53.3763C44.9007 53.3763 52.9268 45.3502 52.9268 35.4495C52.9268 25.5488 44.9007 17.5227 35 17.5227ZM13.6585 35.4495C13.6585 23.6629 23.2134 14.108 35 14.108C46.7866 14.108 56.3415 23.6629 56.3415 35.4495C56.3415 47.2361 46.7866 56.791 35 56.791C23.2134 56.791 13.6585 47.2361 13.6585 35.4495Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.7858 28.1705C44.5056 28.7796 44.5953 29.8569 43.9863 30.5767L34.9693 41.2332C34.1349 42.2192 32.6446 42.3048 31.7029 41.4207L26.1485 36.2064C25.4611 35.5611 25.4269 34.4806 26.0723 33.7931C26.7177 33.1057 27.7982 33.0716 28.4856 33.7169L33.1506 38.0963L41.3796 28.3711C41.9887 27.6512 43.0659 27.5615 43.7858 28.1705Z'
        fill='white'
      />
    </svg>
  )
}
