import i18next from 'i18next'

import { SWEDEN, SWEDISH, ENGLISH } from '../config/languages'
import { LOCALE } from '../config/localStorageKeys'
import { getLocaleCode } from '../locales/i18n.helpers'
import { AppLocale, languageSettings } from '../locales/i18n.config'

export const getCurrentOrigin = () => {
  return window.location.origin
}

const getOriginLocale = () => {
  return getCurrentOrigin().includes('com') ? AppLocale.EN : AppLocale.SV
}

export const isSwedenOrigin = () => {
  return getCurrentOrigin().includes('se')
}

export const getUserLocale = () => {
  const i18nLanguage = languageSettings[i18next.language]
  if (i18nLanguage) {
    return i18nLanguage
  }

  const language = getOriginLocale()
  return languageSettings[language]
}

export const getLocaleName = () => {
  return getUserLocale().name
}

export const checkIsSwedenLocale = () => {
  return getLocaleName() === SWEDEN
}

export const getUserLanguage = () => {
  return checkIsSwedenLocale() ? SWEDISH : ENGLISH
}

const compareLocalesAndSet = async (backendLanguage) => {
  const lang = getLocaleCode(backendLanguage)
  const localStorageLanguage = localStorage.getItem(LOCALE)
  if (localStorageLanguage === lang) {
    return
  }

  localStorage.setItem(LOCALE, lang)
  await i18next.changeLanguage(lang)
}

export const setI18Locale = async (backendLanguage) => {
  if (backendLanguage) {
    return compareLocalesAndSet(backendLanguage)
  }

  const originLocale = getOriginLocale()
  localStorage.getItem(LOCALE) !== originLocale && localStorage.setItem(LOCALE, originLocale)

  if (i18next.language !== originLocale) {
    await i18next.changeLanguage(originLocale)
  }
}

export const isEnglishLanguage = () => {
  return i18next.language === AppLocale.EN
}
