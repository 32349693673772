import React from 'react'

const SimpleModePauseIcon = () => {
  return (
    <svg width='38' height='37' viewBox='0 0 38 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.07935 4.84366C2.07935 2.9581 3.60789 1.42957 5.49344 1.42957H12.3216C14.2072 1.42957 15.7357 2.95811 15.7357 4.84366V32.1564C15.7357 34.0419 14.2072 35.5705 12.3216 35.5705H5.49344C3.60789 35.5705 2.07935 34.0419 2.07935 32.1564V4.84366Z'
        fill='#666679'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5639 4.84366C22.5639 2.9581 24.0924 1.42957 25.978 1.42957H32.8062C34.6917 1.42957 36.2203 2.95811 36.2203 4.84366V32.1564C36.2203 34.0419 34.6917 35.5705 32.8062 35.5705H25.978C24.0924 35.5705 22.5639 34.0419 22.5639 32.1564V4.84366Z'
        fill='#666679'
      />
    </svg>
  )
}

export default SimpleModePauseIcon
