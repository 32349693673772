import { createEvent, sample, restore, attach, createEffect } from 'effector'
import { $assignmentId, $exerciseId } from '../assignment/model/credentials.model'
import { GetMyScriptImageSettings } from './types'
import { getMyScriptImage } from './helpers'
import { Editor } from 'iink-ts'
import { noop } from '../../lib/helpers'
import { currentProblemAnswerModel } from '../Problem/models/answer'

type FlushFxProps = {
  settings: GetMyScriptImageSettings | void
  editor: Editor | null
}

const currentEditorChanged = createEvent<Editor | null>()
const $currentEditor = restore(currentEditorChanged, null)

const setDrawing = createEvent<string | null>()
const $currentMyScriptDrawing = restore(setDrawing, null).on(currentEditorChanged, () => null)

export const clearEditor = createEvent()
sample({
  source: $currentEditor,
  clock: clearEditor,
}).watch((editor) => {
  setDrawing(null)
  editor && !editor.context.empty && editor.clear()
})

sample({
  clock: [$assignmentId, $exerciseId],
  target: clearEditor,
})

sample({
  clock: currentProblemAnswerModel.$text,
  filter: (value) => !value,
  target: clearEditor,
})

const flush = attach({
  source: $currentEditor,
  mapParams: (settings: GetMyScriptImageSettings | void, editor) => ({ settings, editor }),
  effect: createEffect(async ({ editor, settings }: FlushFxProps) => {
    if (!editor) return null
    const image = await getMyScriptImage(editor, settings as GetMyScriptImageSettings | undefined)
    !editor.context.empty && editor.clear().catch(noop)
    return image
  }),
})

export class answerAreaMyScriptModel {
  static readonly setDrawing = setDrawing
  static readonly $currentDrawing = $currentMyScriptDrawing
  static readonly currentEditorChanged = currentEditorChanged
  static readonly flush = flush

  static get currentEditor() {
    return $currentEditor.getState()
  }
}
