import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import './styles.scss'
import AnswerInfoContext from '../../context/answerInfoContext'
import { MISTAKE, SUCCESS, SUCCESS_FIRST_ATTEMPT, EXAM_MODE } from '../../constants/answerStatusConstants'

const AnswerFeedback = ({ className = '' }) => {
  const { t } = useTranslation()
  const { style, examMode, answerResultStatus } = useContext(AnswerInfoContext)

  const FEEDBACK_TEXT = {
    [EXAM_MODE]: (t) => t('exam.feedback'),
    [SUCCESS]: (t) => t('rightText'),
    [SUCCESS_FIRST_ATTEMPT]: (t) => t('rightFirstTryText'),
    [MISTAKE]: (t) => t(examMode ? 'wrongAnswerText' : 'wrongText'),
  }

  const feedbackText = FEEDBACK_TEXT[answerResultStatus]?.(t) || ''

  return (
    <div className={clsx('answer-feedback-input', !answerResultStatus && 'hide', className)}>
      <span className={`feedback-text ${style}`}>{feedbackText}</span>
    </div>
  )
}

export default AnswerFeedback
