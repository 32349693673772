import React, { useContext } from 'react'
import clsx from 'clsx'

import './styles.scss'

import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../../constants/defaults'
import { useTextToSpeechStore } from '../../../TextToSpeech/model'
import { theoryModel, useTheoryModel } from '../../models/theory'
import usePracticeProblemsStore from '../../models/practiceProblems'
import { translateCurrentTask } from '../../../Problem/helpers'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { usePracticeTheory } from '../../models/practiceTheory'
import { IconButton, IconButtonProps } from '../../../../ui/IconButton'
import TextToSpeech from '../../../TextToSpeech/TextToSpeech'
import { GlobeIcon } from '../../../../ui/icons/Globe'
import { LightBulb } from '../../../../ui/icons/LightBulb'
import { ExerciseThemeVariantMapper } from '../../../Problem/problem.style.mode.helpers'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { COLORS } from '@magmamath/ui'
import { PracticeProblem } from '../../../Problem/types'

type ToolBarPracticeProps = {
  textToSpeechContent?: string
}

const ToolBarPractice = ({ textToSpeechContent = '' }: ToolBarPracticeProps) => {
  const { isTranslated, setIsTranslated } = useTextToSpeechStore((state) => state)
  const { currentProblem } = usePracticeProblemsStore((currentProblem) => currentProblem)
  const { updateCurrentProblem } = usePracticeProblemsStore((updateCurrentProblem) => updateCurrentProblem)
  const { theory, isLoading } = usePracticeTheory()
  const { isShowTheory } = useTheoryModel()
  const { state: userSettingsState } = useContext(UserSettingsContext)
  const enableTranslateFeature =
    userSettingsState.translationLanguage !== NO_LANGUAGE_SELECTED &&
    userSettingsState.translationLanguage !== DEPRECATED_NONE_LANGUAGE
  const { exerciseMode } = useCurrentExerciseMode()

  const onTranslateButtonPress = async () => {
    const problemCopy = { ...currentProblem }
    await translateCurrentTask({
      isTranslated,
      setIsTranslated,
      problem: problemCopy,
      subProblems: null,
      translationLanguage: userSettingsState.translationLanguage,
    })

    updateCurrentProblem(problemCopy as PracticeProblem)
  }

  return (
    <div className={clsx('tool-bar-practice')}>
      {enableTranslateFeature && (
        <IconButton
          onClick={onTranslateButtonPress}
          isActive={isTranslated}
          activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']}
          rounded='full'
          variant='gray'
          className='toolbar-button'
          withShadow
        >
          {isTranslated ? <GlobeIcon fill={COLORS.NEUTRAL_1} stroke={COLORS.NEUTRAL_1} /> : <GlobeIcon />}
        </IconButton>
      )}

      {userSettingsState.textToSpeech && (
        <TextToSpeech value={textToSpeechContent} className='practice-sidebar-speech' />
      )}

      {theory && !isLoading && (
        <IconButton
          rounded='full'
          variant='gray'
          className='toolbar-button'
          withShadow
          isActive={isShowTheory}
          onClick={() => theoryModel.setIsShowTheory(true)}
          activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']}
        >
          <LightBulb fill={isShowTheory ? COLORS.NEUTRAL_1 : COLORS.NEUTRAL_10} />
        </IconButton>
      )}
    </div>
  )
}

export default ToolBarPractice
