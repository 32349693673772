import React, { useContext, useEffect, useState } from 'react'

import {
  isMistakeAnswerStatus,
  isExamModeAnswerStatus,
  transformPreviousSelectedTypeAnswer,
} from '../../../../helpers/exercises/exerciseAnswerHelper'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { currentProblemAnswerModel } from '../../../../features/Problem/models/answer'
import { useChoiceAnswersModel } from '../model'
import { AnswerChoice } from '../AnswerChoice/AnswerChoice'
import { getUserType } from '../../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../../constants/userTypes'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../features/Problem/models/exerciseMode'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../features/assignment/model/assignment.model'

const ProblemAnswerMultipleChoice = (props) => {
  const {
    answerResultStatus,
    examMode,
    isExamFinished,
    problemData,
    practiceMode,
    showRequireDrawingMessage,
    examStopped,
  } = useContext(AnswerInfoContext)

  const selectedAnswers = useChoiceAnswersModel((state) => state.selectedAnswers)
  const setSelectedAnswers = useChoiceAnswersModel((state) => state.setSelectedAnswers)
  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  const [answerVariants, setAnswerVariants] = useState([])

  useEffect(() => {
    if (!problemData) {
      return
    }

    setAnswerVariants(transformPreviousSelectedTypeAnswer(problemData.answerVariants))
  }, [problemData])

  useEffect(() => {
    const selected = []
    if (!props.previousAnswer) {
      setSelectedAnswers([])
      return
    }

    props.previousAnswer.forEach((answer) => {
      const index = answerVariants.findIndex((answerVariant) => answerVariant.variant === answer)

      if (index !== -1) {
        selected.push({ id: index, variant: answer })
      }
    })

    setSelectedAnswers(selected)
  }, [props.previousAnswer, answerVariants])

  const submitAnswer = async () => {
    if (
      (examMode && (isExamModeAnswerStatus(answerResultStatus) || isExamFinished())) ||
      (answerResultStatus && practiceMode)
    ) {
      props.onNextButtonPress()
      return
    }

    if (isMistakeAnswerStatus(answerResultStatus)) {
      currentProblemAnswerModel.resetStatus()
      setSelectedAnswers([])
      return
    }

    if (props.isSolved) {
      props.onNextButtonPress()
      return
    }

    if (!selectedAnswers.length) {
      return
    }

    await props.onSubmitClick(selectedAnswers.map((answer) => answer.variant))
  }

  const selectAnswerVariant = (answer) => {
    const stateCopy = [...selectedAnswers]
    currentProblemAnswerModel.resetStatus()

    const answerIndex = stateCopy.findIndex((answerVariant) => {
      return answerVariant.id === answer.id
    })

    if (answerIndex === -1) {
      setSelectedAnswers([...stateCopy, answer])
      return
    }

    stateCopy.splice(answerIndex, 1)
    setSelectedAnswers([...stateCopy])
  }

  const checkIsElementSelected = (element) => {
    return selectedAnswers.some((answerVariant) => {
      return answerVariant.id === element.id
    })
  }

  const isDrawingRequired = showRequireDrawingMessage && !selectedAnswers.length

  const isOptionDisabled =
    (isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))) ||
    props.isSolved ||
    isAnswerPending ||
    isDrawingRequired

  const isSubmitDisabled =
    !selectedAnswers.length ||
    selectedAnswers.length < problemData.answer.length ||
    (isAnswerPending && !(isExamMode && isExamFinished()))

  return (
    <AnswerChoice
      answerOptions={answerVariants}
      submitAnswer={submitAnswer}
      isOptionDisabled={!!isOptionDisabled}
      isSubmitDisabled={!!isSubmitDisabled}
      isDrawingRequired={isDrawingRequired}
      showAnswerPreview={props.allowAnswerLooking}
      handleSelectAnswer={selectAnswerVariant}
      checkIsOptionSelected={checkIsElementSelected}
    />
  )
}

export default ProblemAnswerMultipleChoice
