import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'

export const ExitFullscreenIcon = ({ size = 25 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1129_7236)'>
        <path
          d='M1.30469 8.90625L5.30469 8.90625C7.51383 8.90625 9.30469 7.11539 9.30469 4.90625L9.30469 0.90625M15.7152 0.900391V4.90039C15.7152 7.10953 17.506 8.90039 19.7152 8.90039H23.7152M23.7305 16.1094H19.7305C17.5213 16.1094 15.7305 17.9002 15.7305 20.1094V24.1094M9.32031 24.1094V20.1094C9.32031 17.9002 7.52945 16.1094 5.32031 16.1094H1.32031'
          stroke='#4d4d63'
          strokeWidth='1.66'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1129_7236'>
          <rect width='25' height='25' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
