import { combine } from 'effector'

import { cheatDetected } from './cheatDetected'
import { $exam } from '../../Problem/models/exam/exam'
import { assignmentModel } from '../../assignment/model/assignment.model'

import { CheatType } from '../../../api/types.cheats'

export const $isCheatingDetectionEnabled = combine(
  assignmentModel.$assignment,
  $exam,
  (state, exam) => state?.cheatDetection && state?.testModeEnabled && !exam.isFinished
)

const reportUserCheat = () => cheatDetected(CheatType.LEAVING_BUTTON)

$isCheatingDetectionEnabled.watch((isEnabled) => {
  if (isEnabled) {
    window.addEventListener('popstate', reportUserCheat)
    window.addEventListener('beforeunload', reportUserCheat)
  } else {
    window.removeEventListener('popstate', reportUserCheat)
    window.removeEventListener('beforeunload', reportUserCheat)
  }
})
