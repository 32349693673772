import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'

export enum ExerciseMode {
  REGULAR = 0,
  EXAM = 1,
  PRACTICE = 2,
}

type CurrentExerciseModeModel = {
  exerciseMode: ExerciseMode
}

const initialState: CurrentExerciseModeModel = {
  exerciseMode: ExerciseMode.REGULAR,
}

export const useCurrentExerciseMode = create(() => initialState)

const setCurrentExerciseMode = addEvent(useCurrentExerciseMode, (exerciseMode: ExerciseMode) => ({
  exerciseMode,
}))

export const currentExerciseMode = {
  setExerciseMode: setCurrentExerciseMode,
}
