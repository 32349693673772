import React from 'react';

import { MdKeyboardArrowLeft } from 'react-icons/md';

import history from '../../history';

const BackButton = () => {
    return (
        <MdKeyboardArrowLeft
            onClick={history.goBack}
            className="left-arrow"
            size={26}
        />
    );
};

export default BackButton;