import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import type { TFunction } from 'react-i18next'

import translationEng from './en/translation.json'
import translationSwe from './sv/translation.json'
import translationUK from './uk/translation.json'
import { AppLocale, Detector } from './i18n.config'
import { customDomainDetector, customSearchParamDetector } from './i18n.helpers'

export type UseTranslationTFunction = TFunction<'en' | 'sw'>

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customSearchParamDetector)
languageDetector.addDetector(customDomainDetector)

i18n
  .use(initReactI18next)
  .use(XHR)
  .use(languageDetector)
  .init({
    supportedLngs: Object.values(AppLocale),
    detection: {
      order: [Detector.CUSTOM_SEARCH_PARAM_DETECTOR, Detector.CUSTOM_DOMAIN_DETECTOR, 'localStorage', 'navigator'],
    },
    react: { useSuspense: false },
    fallbackLng: AppLocale.EN,
    cleanCode: true,
    resources: {
      [AppLocale.EN]: { translations: translationEng },
      [AppLocale.SV]: { translations: translationSwe },
      [AppLocale.UK]: { translations: translationUK },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  })

export default i18n
