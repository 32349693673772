import React from 'react'
import { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'
import { getIconSize } from '../helpers'

export const TimeIcon = ({ size = 28, fill = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_74_1794)'>
        <path
          d='M14.0002 27.3C6.66656 27.3 0.700195 21.3336 0.700195 14C0.700195 6.66632 6.66656 0.699951 14.0002 0.699951C21.3338 0.699951 27.3002 6.66631 27.3002 14C27.3002 21.3336 21.3338 27.3 14.0002 27.3ZM14.0002 1.96662C7.36504 1.96662 1.96686 7.3648 1.96686 14C1.96686 20.6351 7.36504 26.0333 14.0002 26.0333C20.6354 26.0333 26.0335 20.6351 26.0335 14C26.0335 7.3648 20.6354 1.96662 14.0002 1.96662Z'
          fill={fill}
        />
        <path
          d='M20.1926 14.6333H14C13.6502 14.6333 13.3667 14.3497 13.3667 13.9999V4.92215C13.3667 4.57236 13.6502 4.28882 14 4.28882C14.3498 4.28882 14.6334 4.57236 14.6334 4.92215V13.3666H20.1926C20.5424 13.3666 20.826 13.6501 20.826 13.9999C20.826 14.3497 20.5424 14.6333 20.1926 14.6333Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_74_1794'>
          <rect width='28' height='28' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
