import React, { memo, useCallback, useEffect, useRef } from 'react'
import '../styles.scss'
import clsx from 'clsx'

import { ScriptLoadingStatus, useLoadExternalScript } from '../helpers/useLoadExternalScript'
import {
  DEFAULT_TOOLBAR_TOOLS,
  EMPTY_EDITOR_BASE64,
  GEOGEBRA_APP_URL,
  GEOGEBRA_APP_VERSION,
  HANDWRITING_AREA_HEIGHT,
} from '../constants'

import {
  AppletApiRef,
  AppletObject,
  GeogebraAppNames,
  GeogebraSettings,
  GridTypes,
  ObjectRefValue,
  SetPointCaptureMode,
  ToolbarTools,
  ToolbarToolsNames,
} from '../types'

import { GeogebraLoadingStatus, useGeogebraModel, useGeogebraModelActions } from '../model'
import GlobalCircleLoader from '../../../ui/Loaders/GlobalCircleLoader'
import { getToolbarTools } from '../helpers/getToolbarTools'
import { useResizeGeogebraApplet } from '../helpers/useResizeGeogebraApplet'
import { useMutateGeogebraNodes } from '../helpers/useMutateGeogebraNodes'
import {
  isPointOnLine,
  limitPointsCount,
  prefillLineEquations,
  setGeogebraPointsLimitReached,
  setUserAnswer,
} from '../helpers/geogebraApiHelpers'

// https://wiki.geogebra.org/en/Reference:GeoGebra_App_Parameters
export type GeogebraProps = {
  id: string
  appName: GeogebraAppNames
  width?: number
  height?: number
  autoHeight?: boolean
  customToolBar?: string
  showToolBar?: boolean
  showAlgebraInput?: boolean
  showMenuBar?: boolean
  reloadOnPropChange?: boolean
  scaleContainerClass?: string
  useBrowserForJS?: boolean
  showToolBarHelp?: boolean
  LoadingComponent?: React.ReactNode
  appletOnLoad?: (appletApi: AppletApiRef) => void
  isSidebarOpen?: boolean
  geogebraSettings?: GeogebraSettings
  initialBase64AppletState?: string
  isFullWidth?: boolean
  isDefaultType?: boolean
}

const Geogebra = ({
  geogebraSettings = { toolbarTools: ['MOVE'] },
  customToolBar = DEFAULT_TOOLBAR_TOOLS,
  initialBase64AppletState,
  isDefaultType,
  isFullWidth,
  appletOnLoad,
  ...props
}: GeogebraProps) => {
  const externalScriptStatus = useLoadExternalScript(GEOGEBRA_APP_URL, props.id)

  const loadingStatus = useGeogebraModel((state) => state.loadingStatus)
  const { setLoadingStatus } = useGeogebraModelActions()

  const allValuesRef = useRef<ObjectRefValue[] | null>(null)
  const appletElementRef = useRef<HTMLDivElement>(null)
  const appletApiRef = useRef<AppletApiRef | null>(null)
  useResizeGeogebraApplet(isFullWidth ? appletApiRef : null)
  useMutateGeogebraNodes(isDefaultType ? appletElementRef : null)

  const onAppletReady = useCallback(
    (appletApi: AppletApiRef) => {
      appletApi.registerAddListener((event: string) => {
        allValuesRef.current = appletApi.getAllObjectNames().map((name) => ({
          name,
          value: appletApi.getValueString(name),
          type: appletApi.getObjectType(name),
        }))

        if (geogebraSettings?.pointsLimit) {
          setGeogebraPointsLimitReached(appletApi, geogebraSettings)

          limitPointsCount({
            event,
            appletApi,
            limitCount: geogebraSettings.pointsLimit,
          })
        }

        const eventType = appletApi.getObjectType(event)
        if (eventType === ToolbarToolsNames.LINE) {
          // FIXME: figure out a way to not use setTimeout
          // Sets "MOVE" tool after adding LINE on canvas
          // issue with Geogebra and condition race which needs setTimeout to work.
          setTimeout(() => appletApi.setMode(ToolbarTools.MOVE), 5)
        }

        setUserAnswer(appletApi, geogebraSettings)
      })

      appletApi.registerUpdateListener(() => {
        setUserAnswer(appletApi, geogebraSettings)
      })

      appletApi.registerClientListener((event) => {
        const [eventType] = event

        if (eventType === 'undo') {
          setGeogebraPointsLimitReached(appletApi, geogebraSettings)
          setUserAnswer(appletApi, geogebraSettings)
        }
      })

      appletApi.registerClickListener((objName) => {
        appletApi.evalCommand(`SelectObjects(${objName})`)
      })

      appletApi.registerRemoveListener((objName) => {
        const removedObj = allValuesRef.current?.find((value: AppletObject) => value.name === objName)

        // Removes LINE and all connected POINTS
        if (removedObj?.type === ToolbarToolsNames.LINE) {
          const allPoints = allValuesRef.current?.filter(
            (value: AppletObject) => value.type === ToolbarToolsNames.POINT
          )

          allPoints?.forEach((point: AppletObject) => {
            const matchBetweenParenthesesRegex = /\(([^)]*)\)/gm // Matches "(2, 0)" in "A = (2, 0)" string
            const pointValues = point.value.match(matchBetweenParenthesesRegex)?.[0] ?? ''

            if (!pointValues) return

            const pointCoordinates = pointValues.slice(1, -1).split(', ')
            const lineEquation = removedObj?.value.slice(3) ?? ''

            if (isPointOnLine(lineEquation, pointCoordinates)) {
              appletApi.deleteObject(point.name)
            }
          })
        }

        allValuesRef.current = allValuesRef.current?.filter((value: AppletObject) => value.name !== objName) ?? []

        setGeogebraPointsLimitReached(appletApi, geogebraSettings)
        setUserAnswer(appletApi, geogebraSettings)
      })

      appletApi.setGraphicsOptions(1, { gridType: GridTypes.MAJOR })

      if (isDefaultType) {
        appletApi.setPerspective('-A') // Hides sidebar

        setTimeout(() => {
          appletApi.setMode(ToolbarTools[geogebraSettings?.toolbarTools?.[0] ?? ToolbarTools.MOVE])
        }, 10)
      }

      setTimeout(() => {
        appletApi.setMode(ToolbarTools[geogebraSettings.toolbarTools[0] ?? ToolbarTools.MOVE])
        appletApi.setAxisSteps(1, 1, 1, 1) // Block scale so that zooming doesn't change the scale
        appletApi.setPointCapture(1, SetPointCaptureMode.FIXED_TO_GRID) // Limit the ability to plot points to only integer numbers
      }, 10)

      appletApiRef.current = appletApi
      setLoadingStatus(GeogebraLoadingStatus.SUCCESS)
    },
    [isDefaultType, geogebraSettings, setLoadingStatus]
  )

  useEffect(() => {
    if (!window.GGBApplet || externalScriptStatus === ScriptLoadingStatus.LOADING) return

    const { GGBApplet } = window
    const appProps: Partial<{ ggbBase64?: string } & GeogebraProps> = { ...props, customToolBar }

    // Workaround for bug with "Undo" button and "evalCommand" after applet injected
    // After clicking "Undo" geogebra settings were reset do default (Gridlines) without ggBase64 prop.
    appProps.ggbBase64 = initialBase64AppletState ?? EMPTY_EDITOR_BASE64

    appProps.appletOnLoad = (appletApi) => {
      onAppletReady(appletApi)
      appletOnLoad?.(appletApi)

      // set applet state on init
      if (geogebraSettings?.prefilledFunction) prefillLineEquations(geogebraSettings.prefilledFunction, appletApi)
      if (geogebraSettings?.pointsLimit) setGeogebraPointsLimitReached(appletApi, geogebraSettings)
    }

    if (geogebraSettings) {
      appProps.customToolBar = getToolbarTools(geogebraSettings.toolbarTools)
    }

    const geogebraApp = new GGBApplet(appProps, GEOGEBRA_APP_VERSION)
    geogebraApp.inject(appProps.id ?? 'ggb-applet')

    return () => {
      const tag = document.getElementById(`${appProps.id}-holder`)
      if (tag?.lastChild) tag.lastChild.textContent = ''
    }
  }, [
    props,
    initialBase64AppletState,
    geogebraSettings,
    customToolBar,
    externalScriptStatus,
    onAppletReady,
    appletOnLoad,
  ])

  return (
    <div
      className={clsx({
        'applet-wrapper': isFullWidth,
      })}
    >
      <div className={`${props.id}-holder`} ref={appletElementRef}>
        {(externalScriptStatus === ScriptLoadingStatus.LOADING || loadingStatus === GeogebraLoadingStatus.LOADING) && (
          <div style={{ zIndex: 20, height: `calc(100% - ${HANDWRITING_AREA_HEIGHT}px)` }}>
            <GlobalCircleLoader />
          </div>
        )}
        <div id={props.id} />
      </div>
    </div>
  )
}

export default memo(Geogebra)
