import type { RefObject } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'
import { serverPersistModule } from '../../DrawBoard/model.persist'
import { createFormDataFromBase64 } from '../../../components/Problem/helpers.images'
import { writeDrawBoardLogg } from '../../../helpers'
import { sendDrawnImage } from '../../assignments/requests'
import type { QueueImages } from '../models/offlineQueue/offlineQueue.types'
import { offlineQueueImagesDB } from '../models/offlineQueue/offlineQueueImagesDB'

export async function getDrawboardDrawingImage(drawBoard: RefObject<BoardController>) {
  if (!drawBoard.current) return ''
  serverPersistModule.saveCurrent()
  try {
    const image = await drawBoard.current.getImage()

    if (!image) return null

    return createFormDataFromBase64(image)
  } catch (error) {
    console.error('getDrawboardDrawingImage error: ', error)
    writeDrawBoardLogg(error)
    return null
  }
}

export async function uploadSolutionImages(canvasDrawing: string, myScriptDrawing: string) {
  let uploadedCanvasImage
  let uploadedHandwritingImage

  if (canvasDrawing) uploadedCanvasImage = await sendDrawnImage(canvasDrawing)
  if (myScriptDrawing) uploadedHandwritingImage = await sendDrawnImage(myScriptDrawing)

  return {
    canvasImage: uploadedCanvasImage?.image ?? null,
    handwritingAnswerImage: uploadedHandwritingImage?.image ?? null,
  }
}

export async function uploadedSolutionImages(images: QueueImages) {
  const drawboardImg = await offlineQueueImagesDB.get(images.drawboard)
  const myScriptImg = await offlineQueueImagesDB.get(images.handwriting)

  let uploadedCanvasImage
  let uploadedHandwritingImage

  if (drawboardImg?.data) {
    uploadedCanvasImage = await sendDrawnImage(createFormDataFromBase64(drawboardImg.data))
  }

  if (myScriptImg?.data) {
    uploadedHandwritingImage = await sendDrawnImage(createFormDataFromBase64(myScriptImg.data))
  }

  return {
    uploadedCanvasImage: uploadedCanvasImage?.image ?? '',
    uploadedHandwritingImage: uploadedHandwritingImage?.image ?? '',
  }
}
