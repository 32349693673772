import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'

import styles from './styles.module.scss'

import { practiceModeModel } from '../models'
import { ProgressBar } from '../../../ui/ProgressBar'
import { getCorrectGradeName } from '../../practiceModeSkills/skillsTree.helpers'
import { inViewSectionModel } from '../../practiceModeSkills/model/inViewSectionModel'

type CategoryHeaderProps = {
  isActive: boolean
  title: string
  grade: number
  solvedPercent?: number
}

const ContentHeader = ({ isActive, title, grade, solvedPercent }: CategoryHeaderProps) => {
  const { t } = useTranslation()
  const intersectedStandardTitle = useUnit(inViewSectionModel.$activeStandardTitle)
  const currentSubChapterSolvedPercent = useUnit(practiceModeModel.currentSubChapterSolvedPercent.$store)
  const activeSubChapter = useUnit(practiceModeModel.activeSubChapter.$store)

  return (
    <div className={styles.PracticeContentHeader}>
      {!isActive && <div className={clsx(styles.HeaderLine, styles.FullWidth)} />}
      <div className={styles.HeaderInfoContainer}>
        <span
          className={clsx(styles.HeaderText, styles.BookTitle, {
            [styles.ActiveTitle]: isActive,
          })}
        >
          {title}
          {isActive && ` — ${getCorrectGradeName(grade, t)}`}
        </span>
        {!isActive && (
          <span
            className={clsx(styles.HeaderText, styles.SolvedPercent, styles.WithWhiteSpace, {
              [styles.ActiveTitle]: isActive,
            })}
          >
            {solvedPercent} %
          </span>
        )}
        {isActive && (
          <>
            <span className={clsx(styles.HeaderText, styles.ActiveTitle, styles.WhiteSpaceLeft)}>—</span>
            <span className={clsx(styles.HeaderText, styles.SubTitle, styles.WithWhiteSpace)}>
              {intersectedStandardTitle ?? activeSubChapter.name}
            </span>
          </>
        )}
        <div className={styles.ProgressBar}>
          {isActive && <ProgressBar progress={currentSubChapterSolvedPercent} variant={'practice'} rounded />}
        </div>
      </div>
    </div>
  )
}

export default ContentHeader
