import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ApiMethod, MinimumRequestConfig, RequestCreatorProps } from './types'
import { isPrimitive, removeStartSlash, requestCreatorPropsToDescriptor } from './helpers'
import { TokenData, TokenManager, TokenManagerProps } from './TokenManager'
import { FetchDriver } from './FetchDriver'

const noop = () => {}

type RequestCreatorSettings = {
  instance?: AxiosInstance
}

type AxiosApiManagerSettings = {
  requestMiddleware?: <T extends MinimumRequestConfig>(config: T) => T
  tokenSettings?: TokenManagerProps
}

export type RequestOptionalConfig = {
  signal?: AxiosRequestConfig['signal']
}

type RequestMiddlewaresIds = {
  protected: null | number
  unprotected: null | number
}

export class ApiManager {
  private readonly baseURL
  private readonly unprotectedInstance
  private readonly instance
  public readonly tokenManager: TokenManager
  private readonly fetchDriver: FetchDriver

  public readonly $token

  private requestMiddlewareIds: RequestMiddlewaresIds = {
    protected: null,
    unprotected: null,
  }

  public constructor(baseURL: string, { requestMiddleware, tokenSettings }: AxiosApiManagerSettings = {}) {
    this.baseURL = baseURL
    this.tokenManager = new TokenManager(tokenSettings)
    this.$token = this.tokenManager.$state
    this.fetchDriver = new FetchDriver({
      baseURL,
      tokenManager: this.tokenManager,
      requestMiddleware,
    })
    this.unprotectedInstance = axios.create({ baseURL })
    this.instance = axios.create({ baseURL })
    if (requestMiddleware) {
      this.requestMiddlewareIds.protected = this.instance.interceptors.request.use(requestMiddleware)
      this.requestMiddlewareIds.unprotected = this.unprotectedInstance.interceptors.request.use(requestMiddleware)
    }

    this.instance.interceptors.request.use(async (config) => {
      const token = await this.tokenManager.get()
      if (!token) throw new Error('User is not authorized')
      config.headers['Authorization'] = token
      return config
    })

    this.instance.interceptors.response.use((response) => response.data)
    this.unprotectedInstance.interceptors.response.use((response) => response.data)
  }

  public createUrl(url: string) {
    return `${this.baseURL}/${url}`
  }

  public onInit(cb: (token: TokenData | null) => void) {
    if (this.tokenManager.isInitiated) {
      cb(this.tokenManager.$state.getState())
      return noop
    }
    const unwatch = this.tokenManager.initiated.watch((token) => {
      cb(token)
      unwatch()
    })
    return unwatch
  }

  public setRequestMiddleware(fn: <T extends MinimumRequestConfig>(config: T) => T) {
    if (this.requestMiddlewareIds.protected !== null) {
      this.instance.interceptors.request.eject(this.requestMiddlewareIds.protected)
    }
    if (this.requestMiddlewareIds.unprotected !== null) {
      this.unprotectedInstance.interceptors.request.eject(this.requestMiddlewareIds.unprotected)
    }
    this.requestMiddlewareIds.protected = this.instance.interceptors.request.use(fn)
    this.requestMiddlewareIds.unprotected = this.unprotectedInstance.interceptors.request.use(fn)
    this.fetchDriver.setRequestMiddleware(fn)
  }

  public setToken(tokenData: TokenData | null) {
    this.tokenManager.tokenChanged(tokenData)
  }

  public resetToken() {
    this.tokenManager.tokenChanged(null)
  }

  private createBodyRequest<R, P>(
    method: ApiMethod,
    creatorProps: RequestCreatorProps<P>,
    { instance = this.instance }: RequestCreatorSettings = {}
  ): (props: P, config?: RequestOptionalConfig) => Promise<R> {
    const {
      url = '',
      fn,
      headers,
      driver = 'axios',
      protect = true,
      keepalive,
      responseType,
    } = requestCreatorPropsToDescriptor(creatorProps)

    if (driver === 'fetch') {
      return this.fetchDriver.createBodyRequest({ method, url, fn, headers, protect, keepalive })
    }

    if (!fn) {
      return (props, extConfig) =>
        instance.request<R, R>({ ...extConfig, responseType, method, url, data: props, headers })
    }

    return (props, extConfig) => {
      const result = fn(props)
      const config: AxiosRequestConfig<P> = { ...extConfig, responseType, headers, method }
      if (isPrimitive(result)) {
        config.url = result.toString()
      } else {
        const { body, url = '', params } = result
        config.url = url
        config.params = params
        config.data = body
        if (result.headers) {
          config.headers = { ...config.headers, ...result.headers }
        }
      }
      return instance.request<R, R>(config)
    }
  }

  private createParamsRequest<R, P>(
    method: ApiMethod,
    creatorProps: RequestCreatorProps<P>,
    { instance = this.instance }: RequestCreatorSettings = {}
  ): (props: P, config?: RequestOptionalConfig) => Promise<R> {
    const {
      url = '',
      fn,
      headers,
      protect = true,
      driver = 'axios',
      keepalive,
      responseType,
    } = requestCreatorPropsToDescriptor(creatorProps)

    if (driver === 'fetch') {
      return this.fetchDriver.createParamsRequest({ method, protect, url, headers, fn, keepalive })
    }

    if (!fn) {
      return (props, extConfig) => {
        const config: AxiosRequestConfig<P> = { ...extConfig, url, responseType, headers, method }
        if (props === undefined || props === null) {
          return instance.request<R, R>(config)
        }
        if (isPrimitive(props)) {
          config.url += `/${removeStartSlash(props.toString())}`
        } else {
          config.params = props
        }
        return instance.request<R, R>(config)
      }
    }

    return (props, extConfig) => {
      const config: AxiosRequestConfig<P> = { ...extConfig, responseType, headers, method }
      const result = fn(props)
      if (isPrimitive(result)) {
        config.url = result.toString()
      } else {
        const { body, url = '', params } = result
        config.url = url
        config.params = params
        if (body && !isPrimitive(body)) {
          config.params = { ...config.params, ...body }
        }
        if (result.headers) {
          config.headers = { ...config.headers, ...result.headers }
        }
      }
      return instance.request<R, R>(config)
    }
  }

  public get<R = void, P = void>(props: RequestCreatorProps<P>) {
    return this.createParamsRequest<R, P>(ApiMethod.GET, props)
  }

  public post<R = void, P = void>(props: RequestCreatorProps<P>) {
    return this.createBodyRequest<R, P>(ApiMethod.POST, props)
  }

  public put<R = void, P = void>(props: RequestCreatorProps<P>) {
    return this.createBodyRequest<R, P>(ApiMethod.PUT, props)
  }

  public patch<R = void, P = void>(props: RequestCreatorProps<P>) {
    return this.createBodyRequest<R, P>(ApiMethod.PATCH, props)
  }

  public delete<R = void, P = void>(props: RequestCreatorProps<P>) {
    return this.createParamsRequest<R, P>(ApiMethod.DELETE, props)
  }

  public readonly unprotected = {
    get: <R = void, P = void>(props: RequestCreatorProps<P>) =>
      this.createParamsRequest<R, P>(ApiMethod.GET, props, { instance: this.unprotectedInstance }),
    post: <R = void, P = void>(props: RequestCreatorProps<P>) =>
      this.createBodyRequest<R, P>(ApiMethod.POST, props, { instance: this.unprotectedInstance }),
    put: <R = void, P = void>(props: RequestCreatorProps<P>) =>
      this.createBodyRequest<R, P>(ApiMethod.PUT, props, { instance: this.unprotectedInstance }),
    patch: <R = void, P = void>(props: RequestCreatorProps<P>) =>
      this.createBodyRequest<R, P>(ApiMethod.PATCH, props, { instance: this.unprotectedInstance }),
    delete: <R = void, P = void>(props: RequestCreatorProps<P>) =>
      this.createParamsRequest<R, P>(ApiMethod.DELETE, props, {
        instance: this.unprotectedInstance,
      }),
  }

  public unprotectedRequest<R = void, P = void>(method: ApiMethod, props: RequestCreatorProps<P>) {
    if (method === ApiMethod.GET || method === ApiMethod.DELETE) {
      return this.createParamsRequest<R, P>(method, props, { instance: this.unprotectedInstance })
    }
    return this.createBodyRequest<R, P>(method, props, { instance: this.unprotectedInstance })
  }
}
