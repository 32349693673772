import i18next from 'i18next'
import { showErrorToast } from '../../constants/toasts'
import { setI18Locale } from '../../helpers/userLocale'
import CustomError from '../../constants/customError'
import { ERROR } from '../../constants/toasts/toastTypes'
import { NOT_STUDENT, NOT_TEACHER } from '../../constants/customErrorMessages'
import { LOADING_ERROR } from '../../constants/loadingConstants'
import { REDIRECT_URL, USER_DATA } from '../../config/localStorageKeys'
import history from '../../history'
import { createEffect } from 'effector/effector.umd'
import { api } from '../../api'
import { UserType } from '../../api/types.user'

type AutoLoginRequestProps = {
  autologinToken: string
  userId: string
}

const autoLoginRequest = createEffect(async (props: AutoLoginRequestProps) => {
  const response = await api.autoLogin(props)
  api.manager.setToken({
    access: response.token,
    refresh: '',
    startTime: Date.now(),
  })
  return response
})

autoLoginRequest.fail.watch(({ error }) => {
  showErrorToast({ message: i18next.t(`${error.message}`), toastId: error.message })
})

const meRequest = createEffect(async (expectedUserType: UserType) => {
  const response = await api.me()
  if (response.type !== expectedUserType) {
    api.manager.setToken(null)
    throw new CustomError({ type: ERROR, message: expectedUserType === UserType.TEACHER ? NOT_TEACHER : NOT_STUDENT })
  }
  return response
})

meRequest.fail.watch(({ error }) => {
  showErrorToast({ message: i18next.t(`${error.message}`), toastId: error.message })
})

const redirectStudent = (redirectURL: string) => {
  const url = redirectURL || '/'
  history.push(url)
}

const redirectTeacher = (exerciseId: string, problemId: string, subProblemId: string, redirectTeacherURL: string) => {
  if (problemId || subProblemId) {
    localStorage.setItem(REDIRECT_URL, redirectTeacherURL)
  }

  history.push({
    pathname: `/exercises/available/${exerciseId}`,
    state: {
      problemId,
      subProblemId,
    },
  })
}

export const autologinAndRedirectUser = async (
  autologinToken: string,
  userId: string,
  exerciseId: string,
  problemId: string,
  subProblemId: string,
  redirectURL: string,
  socket: any
) => {
  const tokenData = await autoLoginRequest({ autologinToken, userId })
  if (!tokenData) {
    return LOADING_ERROR
  }

  const expectedUserType = exerciseId ? UserType.TEACHER : UserType.STUDENT
  const userData = await meRequest(expectedUserType)
  if (!userData) {
    return LOADING_ERROR
  }

  localStorage.setItem(USER_DATA, JSON.stringify(userData))
  const socketToken = socket.io.opts?.query?.token
  if (!socketToken) {
    socket.io.opts.query.token = tokenData?.token.replace('JWT ', '')
    socket.disconnect()
    socket.connect()
  }

  await setI18Locale(userData.setting.appLanguage)
  expectedUserType === UserType.STUDENT
    ? redirectStudent(redirectURL)
    : redirectTeacher(exerciseId, problemId, subProblemId, redirectURL)
  return
}
