import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface AnswerFeedbackProps {
  text: string
  style?: string
  className?: string
}

const AnswerFeedback: React.FC<AnswerFeedbackProps> = ({ text, style, className }) => {
  return (
    <div className={styles.AnswerFeedback}>
      <span className={clsx(styles.FeedbackText, className, { [styles[style as string]]: !!style })}>{text}</span>
    </div>
  )
}

export default AnswerFeedback
