import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type PracticeDropdownNavItemProps = {
  id: string
  activeSubCategoryId: string
  title: string
}

export const PracticeDropdownNavItem = ({ id, activeSubCategoryId, title }: PracticeDropdownNavItemProps) => {
  const isActive = activeSubCategoryId === id
  const ref = useRef<HTMLLIElement | null>(null)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined

    if (isActive && ref.current) {
      timeoutId = setTimeout(() => {
        ref.current?.scrollIntoView({
          block: 'center',
        })
      }, 300)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [isActive])

  return (
    <li
      ref={ref}
      className={clsx(styles.SubcategoryItem, {
        [styles.Active]: isActive,
      })}
    >
      <a href={`#${id}`} className={styles.SubcategoryTitle}>
        {title}
      </a>
    </li>
  )
}
