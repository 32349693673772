import React, { ReactNode } from 'react'
import styles from './statsCard.module.scss'
import { Typography } from '@magmamath/ui'

type StatsCardProps = {
  title: string
  value: number
  color: string
  icon: ReactNode
}

export const StatsCard = ({ icon, value, title, color }: StatsCardProps) => {
  return (
    <div className={styles.StatsCard}>
      <div>{icon}</div>
      <div className={styles.StatsCardData}>
        <Typography variant='h1' style={{ color }} className={styles.StatsCardValue} noWrap>
          {value}
        </Typography>

        <Typography variant='h6' className={styles.StatsCardTitle}>
          {title}
        </Typography>
      </div>
    </div>
  )
}
