import React, { useState, useEffect, useCallback } from 'react'
import './styles.scss'
import TheoryContent from './TheoryContent'
import showTheoryWindowInPortal from '../../helpers/showTheoryWindowInPortal'
import { IconButton } from '../../ui/IconButton'
import { TheoryIcon } from '../../ui/icons/Theory'

const TheoryWindow = ({ data }) => {
  const [showTheory, setShowTheory] = useState(false)

  const onEscPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showTheory) {
        setShowTheory(false)
      }
    },
    [showTheory]
  )

  useEffect(() => {
    document.addEventListener('keyup', onEscPress)

    return () => {
      document.removeEventListener('keyup', onEscPress)
    }
  }, [onEscPress])

  const renderTheoryWindow = () => {
    return showTheoryWindowInPortal(
      document.body,
      <TheoryContent show={showTheory} close={() => setShowTheory(false)} theoryContent={data} />
    )
  }

  return (
    <>
      <IconButton
        className='theory-button'
        rounded='full'
        variant='gray'
        withShadow
        onClick={() => setShowTheory(true)}
      >
        <TheoryIcon />
      </IconButton>

      {renderTheoryWindow()}
    </>
  )
}

export default TheoryWindow
