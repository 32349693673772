import React from 'react'

import styles from './styles.module.scss'

import RotatingLinesLoader from '../../../../../ui/Loaders/RotatingLinesLoader'
import { COLORS } from '@magmamath/ui'

const SidebarLoader = () => {
  return (
    <div className={styles.LoaderContainer}>
      <RotatingLinesLoader color={COLORS.PRIMARY_ORANGE} width={30} />
    </div>
  )
}

export default SidebarLoader
