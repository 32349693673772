import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const SquareRootIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.7044 6.31159C9.77261 6.01928 10.0332 5.8125 10.3333 5.8125H27.125C27.4816 5.8125 27.7708 6.10165 27.7708 6.45833C27.7708 6.81501 27.4816 7.10417 27.125 7.10417H11.3583C11.0582 7.10417 10.7976 7.31095 10.7294 7.60324L6.44144 25.9801C6.37495 26.2651 6.12511 26.4697 5.8326 26.4789C5.54008 26.4879 5.27801 26.2992 5.19391 26.0189L3.25641 19.5606C3.15392 19.219 3.34778 18.8588 3.68943 18.7564C4.03108 18.6539 4.39112 18.8477 4.49361 19.1894L5.03781 21.0035C5.22797 21.6374 6.13498 21.6091 6.28536 20.9647L9.7044 6.31159Z'
        fill={fill}
      />
    </svg>
  )
}
