import React from 'react'
import './styles.scss'
import clsx from 'clsx'

import { ReactComponent as PenIcon } from '../../../../assets/images/pen.svg'
import { KEYBOARD_NUMBER_ICON } from '../../../../constants/inputTypesConstants'
import { InputType } from '../../../../features/Problem/models/inputType'
import { focusAndSetCaretAtEnd } from '../ProblemAnswerInput/helpers'

type InputTypeProps = {
  inputType: InputType
  changeInputType: () => void
}

const InputTypeSelector = ({ inputType, changeInputType }: InputTypeProps) => (
  <div
    className={clsx('input-type-selector-button', { keyboard: inputType === InputType.MY_SCRIPT })}
    onClick={() => {
      changeInputType()
      focusAndSetCaretAtEnd()
    }}
  >
    {inputType === InputType.MY_SCRIPT ? `${KEYBOARD_NUMBER_ICON}` : <PenIcon />}
  </div>
)

export default InputTypeSelector
