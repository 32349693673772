import { useEffect, RefObject, useRef } from 'react'

type UseOnIntersectProps = {
  ref: RefObject<HTMLDivElement>
  callback: () => void
}

export const useOnIntersect = ({ ref, callback }: UseOnIntersectProps) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const intersecting = entry.isIntersecting
      if (intersecting) {
        callbackRef.current()
      }
    })

    if (!ref?.current) return

    observer.observe(ref.current as Element)
    return () => {
      observer.disconnect()
    }
  }, [ref])
}
