import React from 'react'
import './styles.scss'
import clsx from 'clsx'
import { GiHamburgerMenu } from 'react-icons/gi'

type BurgerButtonProps = {
  onClick: () => void
  isHidden?: boolean
}

const BurgerButton = ({ onClick, isHidden = false }: BurgerButtonProps) => {
  return (
    <div className={clsx({ 'sidebar-button-show-on-exercise-page': isHidden })}>
      <GiHamburgerMenu onClick={onClick} className='sidebar-button' size={32} />
    </div>
  )
}

export default BurgerButton
