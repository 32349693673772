import React, { useContext } from 'react'
import { LanguageTag, nativeLanguageTagMap } from '../../../../../../config/allLanguages'
import { InputType } from '../../../../../Problem/models/inputType'
import TextToSpeech from '../../../../../TextToSpeech/TextToSpeech'
import { Context as UserSettingsContext } from '../../../../../../context/userSettingsContext'

type PracticeChoiceT2SProps = {
  isTranslated: boolean
  inputType: InputType
  textToSpeechContent: string
}

export const PracticeChoiceT2S = ({ isTranslated, inputType, textToSpeechContent }: PracticeChoiceT2SProps) => {
  const { state: userSettings } = useContext(UserSettingsContext)

  if (!userSettings.textToSpeech) return
  if (
    isTranslated &&
    userSettings.translationLanguage &&
    !nativeLanguageTagMap[userSettings.translationLanguage as LanguageTag]
  ) {
    return
  }
  if (inputType === InputType.KEYBOARD) return

  return <TextToSpeech value={textToSpeechContent} />
}
