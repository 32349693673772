import { createEffect } from 'effector'
import { getUserId } from '../../../helpers/users/getUserInfo'
import { showErrorToast, showSuccessToast } from '../../../constants/toasts'
import i18next from 'i18next'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'
import { api } from '../../../api'

export const joinNewClassFx = createEffect(async (classCode: string) => {
  const userId = getUserId()
  return api.joinClass({ userId, classCode })
})

joinNewClassFx.done.watch(({ result }) => {
  const className = result?.name

  showSuccessToast({
    message: `${i18next.t('joinNewClassSuccessText')} ${className}`,
    toastId: `${Date.now()}-joinNewClassSuccess`,
    additionalStyle: 'with-sidebar',
  })
})

joinNewClassFx.fail.watch(({ error }) => {
  const errorCode = (error as any).response?.data?.code
  const message = errorCode
    ? i18next.t(errorCodeMessages[errorCode as keyof typeof errorCodeMessages])
    : error?.message || i18next.t(CODE_NOT_FOUND)

  showErrorToast({
    message: message || i18next.t('somethingWentWrongText'),
    toastId: `${Date.now()}-joinNewClassError`,
    additionalStyle: 'with-sidebar',
  })
})
