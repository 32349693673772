import React, { useEffect, useRef } from 'react'
import { FileUploaderModule } from '@magmamath/react-native-draw-board'
import { getSolutionImage } from '../assignments/requests'
import { saveImageRequest } from './request'

export type PromiseRef = {
  resolve: (value: string | PromiseLike<string>) => void
  reject: (reason?: string) => void
}

const UploadPhoto = () => {
  const labelRef = useRef<HTMLLabelElement | null>(null)
  const promiseRef = useRef<PromiseRef | null>(null)

  useEffect(() => {
    const controller = new AbortController()

    FileUploaderModule.setUpFileUploader({
      upload: () => {
        return new Promise((resolve, reject) => {
          promiseRef.current = { resolve, reject }
          labelRef.current?.click()
          controller.signal.addEventListener('abort', () => reject())
        })
      },
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <label ref={labelRef} htmlFor='photo-upload' style={{ display: 'none' }} />
      <input
        id='photo-upload'
        type='file'
        accept='image/png, image/jpg, image/jpeg, image/gif'
        onChange={(event) => {
          saveImageRequest(event.target?.files?.[0])
            .then((uploadedImageURL) => {
              const imageURL = getSolutionImage(uploadedImageURL)
              promiseRef.current?.resolve(imageURL ?? '')
            })
            .catch(() => {
              promiseRef.current?.reject()
            })
            .finally(() => {
              promiseRef.current = null
            })
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default UploadPhoto
