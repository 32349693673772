import React, { forwardRef } from 'react'
import styles from './Card.module.scss'
import clsx from 'clsx'

type UICardProps = JSX.IntrinsicElements['div']

const UICard = forwardRef<HTMLInputElement, UICardProps>(({ className, ...rest }, ref) => {
  const mergedClassNames = clsx(styles.uiCard, className)

  return <div ref={ref} className={mergedClassNames} {...rest} />
})

export default UICard
