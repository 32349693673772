import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { checkIsSmallScreen } from '../../../helpers'
import { spreadsheetModel } from '../../DrawBoard/model'

export enum InputType {
  MY_SCRIPT = 1,
  KEYBOARD = 2,
}

export type InputTypeStates = {
  value: InputType
}

const initialState = {
  value: checkIsSmallScreen() ? InputType.KEYBOARD : InputType.MY_SCRIPT,
}

export type InputTypeModel = InputTypeStates & {
  set: (inputType: InputType) => void
  toggle: () => void
}

const useInputTypeStore = create(
  immer<InputTypeModel>((set, getState) => ({
    ...initialState,
    set: (inputType: InputType) =>
      set((state: InputTypeModel) => {
        state.value = inputType
      }),
    toggle: () =>
      set((state: InputTypeModel) => {
        const previousValue = getState().value

        if (previousValue === InputType.MY_SCRIPT) {
          spreadsheetModel.setIsCellActive(false)
          state.value = InputType.KEYBOARD
          return
        }

        state.value = InputType.MY_SCRIPT
      }),
  }))
)

export default useInputTypeStore
