import { apiManager } from './apiManager'

export type ChatAnswer = {
  text: string
  promptKey: string
}
export type InitialAlternatives = {
  options: {
    default: ChatAnswer[]
    hints: ChatAnswer[]
  }
}
export type ChatMessageResponse = {
  message: string
  threadId: string
  options: ChatAnswer[]
}

export type ChatMessagePayload = {
  type: string
  language: string
  grade?: string | null
  problem: string
  hint: string | null
  threadId?: string
  answer?: string | string[]
  problemAnswer: string[]
  problemId?: string
  solutionImages: {
    canvasDrawing: string | null
  }
}

export class chatApi {
  public static readonly alternatives = apiManager.get<InitialAlternatives>('chat/alternatives')
  public static readonly sendMessage = apiManager.post<ChatMessageResponse, ChatMessagePayload>('chat/message')
}
