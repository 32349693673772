import { makeApiCall } from '../../helpers/apiCallHelper'
import { writeLogg } from '../../helpers'
import { api } from '../../api'
import { SendPracticeAnswerProps } from '../../api/types.practice'

export const getPracticeCategories = () => {
  return makeApiCall(api.practice.categories)
}

export const getSubcategoryStatistic = (id: string) => {
  return makeApiCall(() => api.practice.getBestCategory(id))
}

export const getAbilityStatistic = (id: string) => {
  return makeApiCall(() => api.practice.getAbilityStatistic(id))
}

export const createNewAttempt = (abilityId: string) => {
  return makeApiCall(() => api.practice.createNewAttempt(abilityId))
}

export const getPracticeTheory = async (abilityId: string) => {
  try {
    return await api.practice.theory(abilityId)
  } catch (e) {
    writeLogg(e)
    throw e
  }
}

export const getPracticeHistoryDrawing = async (historyId?: string) => {
  if (!historyId) return null
  try {
    return await api.practice.getDrawing(historyId)
  } catch (e) {
    writeLogg(e)
    throw e
  }
}

export const sendAnswer = (props: SendPracticeAnswerProps) => {
  return makeApiCall(() => api.practice.sendAnswer(props))
}
