import { createEffect, Effect } from 'effector'
import { CanceledError } from 'axios'
import { RequestOptionalConfig } from '../../apiManager/ApiManager'

type ControlledEffect<P, T> = Effect<P, T> & {
  controller: AbortController
}

type ControlledRequestProps<P> = {
  data: P
  controller?: AbortController
  abortAfter?: number
}

export function controlledRequest<R = void, P = void>(
  request: (props: P, config?: RequestOptionalConfig) => Promise<R>
) {
  return async ({ data, controller = new AbortController(), abortAfter = 20000 }: ControlledRequestProps<P>) => {
    let timeout: ReturnType<typeof setTimeout> | null = null

    if (abortAfter) {
      timeout = setTimeout(() => {
        controller.abort('timeout')
      }, abortAfter)
    }

    try {
      const response = await request(data, { signal: controller?.signal })
      if (timeout !== null) clearTimeout(timeout)
      return response
    } catch (error: unknown) {
      if ((error as CanceledError<any>).code === 'ERR_CANCELED') {
        if (controller.signal.reason === 'timeout') {
          throw new Error('Request timed out')
        }
        throw new Error('Request aborted manually')
      }

      throw error
    }
  }
}

export const createControllerEffect = <T, P>(fn: (props: P, config?: RequestOptionalConfig) => Promise<T>) => {
  const request = controlledRequest<T, P>(fn)
  const effect = createEffect((data: P) => request({ data, controller: effect.controller })) as ControlledEffect<P, T>
  effect.controller = new AbortController()

  effect.fail.watch(() => {
    effect.controller = new AbortController()
  })

  return effect
}
