import i18n from '../../locales/i18n'
import { ChatMessage } from './types'

export const initialMessage: ChatMessage = {
  message: i18n.t('magmaChat.howCanIHelp'),
  threadId: '',
  isOwnMessage: false,
  options: [],
}

export const errorMessage: ChatMessage = {
  message: i18n.t('magmaChat.errorMessage'),
  threadId: '',
  options: [],
  isOwnMessage: false,
}

i18n.on('languageChanged', () => {
  initialMessage.message = i18n.t('magmaChat.howCanIHelp')
  errorMessage.message = i18n.t('magmaChat.errorMessage')
})
