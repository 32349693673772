import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'

export type PracticeLoadingStates = {
  loadingAbility: boolean
  sendingAnswer: boolean
}

const initialState: PracticeLoadingStates = {
  loadingAbility: false,
  sendingAnswer: false,
}

export const usePracticeLoadingModel = create(() => initialState)

const setLoadingAbility = addEvent(usePracticeLoadingModel, (loadingAbility: boolean) => ({ loadingAbility }))
const setSendingAnswer = addEvent(usePracticeLoadingModel, (sendingAnswer: boolean) => ({ sendingAnswer }))

export const practiceLoadingModel = {
  setLoadingAbility,
  setSendingAnswer,
}
