import { createEvent, restore } from 'effector'
import { DEVICE_SIZE } from '../../constants/deviceSizes'

const MOBILE_QUERY = `(max-width: ${DEVICE_SIZE.MOBILE}px)`

export const mobileResolutionDetected = createEvent<boolean>()
export const $isMobile = restore(mobileResolutionDetected, window.matchMedia(MOBILE_QUERY).matches)

const mediaQuery = window.matchMedia(MOBILE_QUERY)
mediaQuery.addEventListener('change', ({ matches }: MediaQueryListEvent) => {
  mobileResolutionDetected(matches)
})
