import React from 'react'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { DEFAULT_ICON_FILL } from '../constants'

export const BackspaceIcon = ({ size, fill = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.5788 5.46876H11.5476C11.0538 5.46809 10.5662 5.5775 10.12 5.78903C9.67387 6.00056 9.2805 6.30889 8.96852 6.69157L1.83727 15.4219C1.35978 16.0092 1.09912 16.7431 1.09912 17.5C1.09912 18.257 1.35978 18.9908 1.83727 19.5781L8.96852 28.3085C9.28062 28.6913 9.67417 28.9997 10.1205 29.2112C10.5668 29.4228 11.0547 29.5321 11.5487 29.5313H30.5799C31.4587 29.5333 32.3024 29.1867 32.926 28.5674C33.5495 27.9482 33.902 27.1069 33.906 26.2281V8.77189C33.902 7.89292 33.5494 7.05147 32.9256 6.4322C32.3018 5.81293 31.4578 5.46643 30.5788 5.46876ZM31.7185 26.2281C31.7148 26.527 31.5927 26.8123 31.3791 27.0214C31.1655 27.2305 30.8777 27.3464 30.5788 27.3438H11.5476C11.3774 27.3446 11.2091 27.3074 11.0551 27.2349C10.9011 27.1624 10.7652 27.0565 10.6573 26.9249L3.53039 18.1967C3.3684 18.0007 3.27977 17.7543 3.27977 17.5C3.27977 17.2457 3.3684 16.9993 3.53039 16.8033L10.6573 8.07517C10.7653 7.94336 10.9014 7.83735 11.0556 7.76488C11.2098 7.6924 11.3783 7.65529 11.5487 7.65626H30.5799C30.8786 7.65392 31.1661 7.76998 31.3795 7.97905C31.5929 8.18812 31.7148 8.47318 31.7185 8.77189V26.2281Z'
        fill={fill}
      />
      <path
        d='M27.2878 12.0859C27.0827 11.8808 26.8045 11.7656 26.5145 11.7656C26.2245 11.7656 25.9463 11.8808 25.7412 12.0859L21.8748 15.9534L18.0084 12.0859C17.8021 11.8866 17.5258 11.7764 17.2391 11.7789C16.9523 11.7814 16.678 11.8964 16.4752 12.0992C16.2724 12.302 16.1573 12.5763 16.1548 12.8631C16.1524 13.1499 16.2626 13.4261 16.4618 13.6324L20.3282 17.4999L16.4618 21.3674C16.2568 21.5725 16.1416 21.8507 16.1416 22.1407C16.1416 22.4307 16.2568 22.7089 16.4618 22.914C16.6691 23.1149 16.9465 23.2272 17.2351 23.2272C17.5238 23.2272 17.8011 23.1149 18.0084 22.914L21.8748 19.0465L25.7412 22.914C25.9485 23.1149 26.2258 23.2272 26.5145 23.2272C26.8031 23.2272 27.0805 23.1149 27.2878 22.914C27.4928 22.7089 27.608 22.4307 27.608 22.1407C27.608 21.8507 27.4928 21.5725 27.2878 21.3674L23.4214 17.4999L27.2878 13.6324C27.4928 13.4273 27.608 13.1492 27.608 12.8591C27.608 12.5691 27.4928 12.291 27.2878 12.0859Z'
        fill={fill}
      />
    </svg>
  )
}
