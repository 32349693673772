import React, { forwardRef } from 'react'
import clsx from 'clsx'
import styles from './Tooltip.module.scss'
import { Tooltip, TooltipProps } from '@material-ui/core'

type Ref = HTMLDivElement
type UITooltipProps = JSX.IntrinsicElements['div'] & TooltipProps & { title: any | undefined }

const UITooltip = forwardRef<Ref, UITooltipProps>(({ title, children, classes, className, ...rest }, ref) => {
  const mergedClassNames = clsx(styles.TooltipContent, className)
  return (
    <Tooltip
      ref={ref}
      title={title ? <div className={mergedClassNames}>{title}</div> : ''}
      TransitionProps={{ timeout: 1 }}
      classes={{ popper: styles.Popper, tooltip: styles.Tooltip, ...classes }}
      {...rest}
    >
      {children}
    </Tooltip>
  )
})

export default UITooltip
