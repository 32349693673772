import { combine, Store } from 'effector'

import { isExamEnabled, isExamFinished, isExamPaused, isPausedByTeacher } from './exam.helpers'
import { $pausedAssignments } from '../../../CheatDetection/models/pausedAssignments'
import { assignmentModel } from '../../../assignment/model/assignment.model'

export type ExamStatus = {
  isEnabled: boolean
  isPaused: boolean
  isFinished: boolean
  isPausedByTeacher: boolean
}

export const $exam: Store<ExamStatus> = combine(
  assignmentModel.$assignment,
  $pausedAssignments,
  (state, pausedAssignments) => ({
    isEnabled: isExamEnabled(state),
    isPaused: isExamPaused(state),
    isFinished: isExamFinished(state),
    isPausedByTeacher: isPausedByTeacher(state, pausedAssignments),
  })
)
