export const errorCodeMessages = {
    1321: 'incorrectUsernameOrPasswordText',
    1311: 'incorrectUsernameOrPasswordText',
    1021: 'classNotFoundText',
    1030: 'studentAlreadyInClassText',
    1035: 'classCodeInAnotherMunicipalityText'
};

export const autologinError = {
    102: 'emptyUsernameText',
    103: 'usernameAlreadyExistsText',
    104: 'notFoundAccountText'
};
