import { restore, createEvent, createEffect, sample } from 'effector'
import { api } from '../../api'

export const setStatsPopupOpen = createEvent<boolean>()
export const $isStatsPopupOpen = restore(setStatsPopupOpen, false)

const fetchUserStatisticsFx = createEffect(() => api.userStatistics())

export const $userStats = restore(fetchUserStatisticsFx.doneData, {
  correctSolutionsCount: 0,
  goodDrawingCount: 0,
  starsInPracticeModeCount: 0,
  assignmentCompletedCount: 0,
})

sample({
  clock: setStatsPopupOpen,
  source: $isStatsPopupOpen,
  filter: (isOpen) => isOpen,
  target: fetchUserStatisticsFx,
})
