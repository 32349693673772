import { getUserType } from '../users/getUserInfo'
import { TEACHER } from '../../constants/userTypes'
import { REDIRECT_URL } from '../../config/localStorageKeys'
import { fullscreenModeOff, getCorrespondingTeachersURL } from '../index'
import history from '../../history'
import { RoutesPaths } from '../../containers/paths'

export const navigateToAssignmentsList = () => {
  if (getUserType() === TEACHER) {
    const redirectToTeachersURL = localStorage.getItem(REDIRECT_URL)
    let redirectURL = getCorrespondingTeachersURL()
    if (redirectToTeachersURL) {
      redirectURL += redirectToTeachersURL
    }

    localStorage.clear()
    window.location.replace(redirectURL)
    return
  }

  history.push(RoutesPaths.EXERCISES)
  fullscreenModeOff()
}
