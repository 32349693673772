import React from 'react'
import { useUnit } from 'effector-react'

import styles from './styles.module.scss'
import ContentHeader from '../../../PracticeMode/ContentHeader/ContentHeader'
import { StandardSubtree } from '../StandardSubtree/StandardSubtree'
import { PracticeTree, SkillStatisticsMap } from '../../../PracticeMode/types'
import { getSolvedBlockPercent } from '../../skillsTree.helpers'
import { $practiceGrade } from '../../../PracticeMode/models/currentPracticeGrade'
import { inViewSectionModel } from '../../model/inViewSectionModel'

type DomainNodeProps = {
  domain: PracticeTree
  statistics: SkillStatisticsMap
  scrollArea: HTMLDivElement | null
}

export const DomainNode = ({ domain, statistics, scrollArea }: DomainNodeProps) => {
  const practiceGrade = useUnit($practiceGrade)
  const intersectedElementId = useUnit(inViewSectionModel.$intersectedTreeElementId)
  const solvedPercent = getSolvedBlockPercent(domain.children, statistics)

  return (
    <div className={styles.PracticeMainContentContainer}>
      <ContentHeader
        isActive={domain._id === intersectedElementId}
        title={domain.nameTranslations}
        grade={practiceGrade}
        solvedPercent={solvedPercent}
      />

      <div className={styles.StandardNodesList} id={domain._id}>
        {domain.children.map((standard) => (
          <StandardSubtree key={standard._id} standard={standard} scrollArea={scrollArea} />
        ))}
      </div>
    </div>
  )
}
