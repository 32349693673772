import { getGoogleSpeechFromText } from '../assignments/requests'

const getGoogleSpeechAudio = async ({ text, languageCode, name }) => {
  const audioContent = await getGoogleSpeechFromText({
    input: {
      text,
    },
    voice: {
      languageCode,
      name,
    },
    audioConfig: {
      audioEncoding: 'LINEAR16',
      pitch: 0,
      speakingRate: 0.8,
    },
  })

  if (!audioContent) return

  const MP3Base64 = 'data:audio/mpeg;base64, ' + audioContent

  return new Audio(MP3Base64)
}

export default getGoogleSpeechAudio
