import { combine, createEvent, createStore, restore } from 'effector'
import { Assignment } from '../../ExerciseItem/types'
import { $page, fetchAssignmentsPageFx, fetchAssignmentStatisticsFx } from './request'
import { createEffect } from 'effector/effector.umd'
import { mergeAssignmentStatistics } from '../../../helpers/exercises/exercisesHelper'
import { updateSingleAssignment } from '../helpers'

export const resetAssignments = createEvent()
export const setExerciseError = createEvent<string | null>()

export const $exercisesError = restore(setExerciseError, null)

export const addAssignment = createEvent<Assignment>()
export const removeAssignment = createEvent<string>()
export const updateAssignment = createEvent<Assignment>()
const setAssignments = createEvent<Assignment[]>()

export const fetchMultipleAssignmentStatisticsFx = createEffect(async (assignments: Assignment[]) => {
  const topAssignments = assignments.slice(0, 3)
  const stats = await Promise.all(
    topAssignments.map((assignment) => {
      if (assignment.stats?.notAnswered === 100 || assignment.problems[0].studentStats) {
        return assignment
      }

      return fetchAssignmentStatisticsFx(assignment._id)
    })
  )
  return topAssignments.map((assignment, index) => mergeAssignmentStatistics(assignment, stats[index]))
})

export const $assignments = restore(setAssignments, [])
  .on(fetchAssignmentsPageFx.doneData, (state, response) => [...state, ...response._embedded.exercises])
  .on(addAssignment, (state, assignment) => [assignment, ...state])
  .on(removeAssignment, (state, assignmentId) => state.filter((assignment) => assignment._id !== assignmentId))
  .on(updateAssignment, (state, newAssignment) => updateSingleAssignment(state, newAssignment))
  .on(fetchMultipleAssignmentStatisticsFx.doneData, (assignments, assignmentsWithStats) => {
    if (!assignments.length) return assignments
    return [...assignmentsWithStats, ...assignments.slice(assignmentsWithStats.length)]
  })
  .reset(resetAssignments)

const $paginationInfo = createStore({
  pages: 0,
  total: 0,
}).on(fetchAssignmentsPageFx.doneData, (_, { pages, total }) => ({
  pages,
  total,
}))

export const $allAssignmentsFetched = combine(
  $page,
  $assignments,
  $paginationInfo,
  (page, assignments, assignmentDetails) => {
    return !!(assignments.length && page + 1 <= assignmentDetails.pages)
  }
)
