import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import { useUnit } from 'effector-react'

import { mergeAssignmentStatistics } from '../../helpers/exercises/exercisesHelper'
import Problem from '../../components/Problem'
import ErrorComponent from '../../components/ErrorComponent'
import OfflineModeBanner from '../../components/Problem/OfflineModeBanner'
import { getStudentSettings } from '../../helpers/users/getUserInfo'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { useHistory, useParams } from 'react-router-dom'
import { RoutesPaths } from '../paths'
import { AssignmentPopups } from '../../features/assignmentPopups/AssignmentPopups'
import { AssignmentPopup, setAssignmentPopup } from '../../features/assignmentPopups/model'
import { isExamPaused } from '../../features/Problem/models/exam/exam.helpers'
import { useDetectTabChange } from '../../features/CheatDetection/hooks/useDetectTabChange'
import { $assignments } from '../../features/assignmentsPagination/model/assignments'
import { $isOnline } from '../../lib/models/isOnline'
import CircleLoader from '../../ui/Loaders/CircleLoader'
import { assignmentModel } from '../../features/assignment/model/assignment.model'
import {
  fetchAssignmentStatisticsFx,
  fetchAssignmentWithStatsFx,
} from '../../features/assignmentsPagination/model/request'
import { setStudentGrade } from '../../features/toolsMode/model'
import { NETWORK_ERROR } from '../../constants/customErrorMessages'
import { SocketEvents } from '../../constants/socketEventsConstants'
import SocketContext from '../../context/socketContext'
import {
  onExerciseDelete,
  onExerciseUpdate,
  onSolutionUpdate,
  onStudentCheatUpdate,
} from '../../components/Problem/exerciseSocketListeners'

const ExercisePage = (props) => {
  const history = useHistory()
  const { id: assignmentId } = useParams()
  const { setSettings } = useContext(UserSettingsContext)
  const { socket } = useContext(SocketContext)
  const [error, setError] = useState(null)

  const isOnline = useUnit($isOnline)
  const assignments = useUnit($assignments)
  const assignment = useUnit(assignmentModel.$assignment)
  const isLoading = useUnit(fetchAssignmentStatisticsFx.pending)
  useDetectTabChange()

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        const assignment = assignments.find((assignment) => assignment._id === assignmentId)

        if (assignment && assignment.problems[0].studentStats) {
          assignmentModel.setAttemptInfo(assignment)
          assignmentModel.setAssignment(assignment)
          return
        }

        if (assignment && !assignment.problems[0].studentStats) {
          const stats = await fetchAssignmentStatisticsFx(assignmentId)
          const assignmentWithStats = mergeAssignmentStatistics(assignment, stats)
          assignmentModel.setAttemptInfo(assignmentWithStats)
          assignmentModel.setAssignment(assignmentWithStats)
          return
        }

        const fetchedAssignment = await fetchAssignmentWithStatsFx(assignmentId)
        assignmentModel.setAttemptInfo(fetchedAssignment)
        assignmentModel.setAssignment(fetchedAssignment)
        if (isExamPaused(fetchedAssignment)) setAssignmentPopup(AssignmentPopup.PAUSED)
      } catch (error) {
        setError(NETWORK_ERROR)
        history.push(RoutesPaths.EXERCISES_AVAILABLE)
      }
    }

    fetchAssignment()
  }, [assignmentId, assignments])

  useEffect(() => {
    getStudentSettings().then((response) => {
      if (!response) return
      setSettings(response)
      setStudentGrade(response.grade || null)
    })
  }, [])

  useEffect(() => {
    socket.on(SocketEvents.EXERCISE_UPDATED, onExerciseUpdate)
    socket.on(SocketEvents.EXERCISE_DELETED, onExerciseDelete)
    socket.on(SocketEvents.SOLUTION_UPDATED, onSolutionUpdate)
    socket.on(SocketEvents.STUDENT_CHEATING_UPDATED, onStudentCheatUpdate)

    return () => {
      socket.off(SocketEvents.EXERCISE_UPDATED, onExerciseUpdate)
      socket.off(SocketEvents.EXERCISE_DELETED, onExerciseDelete)
      socket.off(SocketEvents.SOLUTION_UPDATED, onSolutionUpdate)
      socket.off(SocketEvents.STUDENT_CHEATING_UPDATED, onStudentCheatUpdate)
    }
  }, [socket])

  if (error) {
    return <ErrorComponent refreshCallback={() => fetchAssignmentWithStatsFx(assignmentId)} />
  }

  return (
    <>
      {!isOnline && <OfflineModeBanner />}
      <div className='exercise-page-container'>
        {!assignment || isLoading ? (
          <CircleLoader />
        ) : (
          <Problem
            redirectProblemId={props.location?.state?.problemId}
            redirectSubProblemId={props.location?.state?.subProblemId}
            offlineMode={!isOnline}
            practiceMode={props.location?.state?.practiceMode}
          />
        )}

        <AssignmentPopups />
      </div>
    </>
  )
}

export default ExercisePage
