import { createEvent, restore } from 'effector'

export const createDataModel = <T>(defaultState: T) => {
  const setData = createEvent<T>()
  const $store = restore(setData, defaultState)

  return {
    setData,
    $store,
  }
}
