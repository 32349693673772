import React, { useEffect, useRef, useContext } from 'react'

import './styles.scss'
import {
  MISTAKE,
  SUCCESS,
  SUCCESS_FIRST_ATTEMPT,
  NOT_SOLVED,
  ACTIVE_ISSUE,
} from '../../../constants/answerStatusConstants'
import { SPREADSHEET_DATA } from '../../../config/localStorageKeys'
import AnswerInfoContext from '../../../context/answerInfoContext'
import ProblemSelectorButton from './ProblemSelectorButton'
import { sideBarModel } from '../../../features/Problem/Sidebar/model'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../features/assignment/model/assignment.model'
import { $exerciseId, currentExerciseModel } from '../../../features/assignment/model/credentials.model'

const ProblemSelectorContent = () => {
  const problemSelectorRef = useRef()
  const { attemptInfo } = useContext(AnswerInfoContext)
  const problems = useUnit(assignmentModel.$exercises)
  const currentProblemId = useUnit($exerciseId)
  const isProblemsListOpen = useUnit(sideBarModel.$isProblemsListOpened)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const problemSelector = document.querySelector('#problem-number-selector')

      if (
        problemSelectorRef.current &&
        !problemSelectorRef.current.contains(e.target) &&
        !problemSelector.contains(e.target)
      ) {
        if (!isProblemsListOpen) {
          return null
        }

        sideBarModel.closeProblemsList()
      }
    }

    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [isProblemsListOpen])

  const onSubProblemClick = (credits) => {
    currentExerciseModel.setById(credits)
    localStorage.removeItem(SPREADSHEET_DATA)
    sideBarModel.closeProblemsList()
  }

  const getNeededStatus = (stats) => {
    if (!stats) {
      return
    }

    if (stats.attempt && !stats.solved) {
      return MISTAKE
    }

    if (stats.attempt === 1 && stats.solved) {
      return SUCCESS_FIRST_ATTEMPT
    }

    if (stats.solved) {
      return SUCCESS
    }

    return NOT_SOLVED
  }

  const getCurrentIssueStatus = ({ problemId, subProblemId }) => {
    if (!subProblemId) {
      if (problemId === currentProblemId) {
        return ACTIVE_ISSUE
      }

      const problemAttemptInfo = attemptInfo[problemId]
      return getNeededStatus(problemAttemptInfo)
    }

    if (subProblemId === currentProblemId) {
      return ACTIVE_ISSUE
    }

    const problemAttemptInfo = attemptInfo[problemId]
    if (!problemAttemptInfo) {
      return
    }

    const subProblemAttemptInfo = problemAttemptInfo[subProblemId]
    return getNeededStatus(subProblemAttemptInfo)
  }

  const renderProblemSelector = () => {
    if (!problems || !problems.length) {
      return
    }

    const problemSelector = []
    problems.forEach((problem) => {
      const subProblems = problem.subProblems
      if (!subProblems.length) {
        problemSelector.push(
          <ProblemSelectorButton
            key={problem._id}
            onSelectorButtonClick={() => onSubProblemClick({ problemId: problem._id })}
            name={problem.name}
            status={getCurrentIssueStatus({ problemId: problem._id })}
          />
        )
      } else {
        subProblems.forEach((subProblem) => {
          problemSelector.push(
            <ProblemSelectorButton
              key={subProblem._id}
              onSelectorButtonClick={() => onSubProblemClick({ problemId: problem._id, subProblemId: subProblem._id })}
              name={`${problem.name}${subProblem.name}`}
              status={getCurrentIssueStatus({ problemId: problem._id, subProblemId: subProblem._id })}
            />
          )
        })
      }
    })

    return problemSelector
  }

  return (
    <div className='problem-selector-wrapper'>
      <div className='problem-selector-container' ref={problemSelectorRef}>
        {renderProblemSelector()}
      </div>
    </div>
  )
}

export default ProblemSelectorContent
