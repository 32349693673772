import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { NumericKeypad } from '../../components/NumericKeypad'
import { NumpadButton } from '../../components/NumpadButton'
import { CharTypeNumpadContext } from '../../'
import { getGridRowsStyle, numpadLocale } from '../../helpers'
import { UNITS_CATEGORIES } from '../../constants/common'
import { LOCALIZED_UNITS, UNIT_BUTTONS } from '../../constants/units'

export const Units = () => {
  const { onClick, onSpecialSymbolPress } = useContext(CharTypeNumpadContext)
  const [selectedUnit, setSelectedUnit] = useState<UNITS_CATEGORIES>(UNITS_CATEGORIES.LENGTH)

  const changeSelectedUnit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedUnit(event.currentTarget.name as UNITS_CATEGORIES)
  }

  return (
    <div className={styles.UnitsContainer}>
      <div>
        <div className={styles.UnitsWrapper}>
          {UNIT_BUTTONS.map(({ name, symbol }) => (
            <NumpadButton
              key={name}
              name={name}
              onClick={changeSelectedUnit}
              className={clsx(styles.UnitButton, { [styles.isActive]: name === selectedUnit })}
            >
              {symbol}
            </NumpadButton>
          ))}
        </div>

        <div className={styles.UnitsGrid} style={{ gridTemplateRows: getGridRowsStyle(selectedUnit) }}>
          {LOCALIZED_UNITS[numpadLocale][selectedUnit].map(({ symbol, value, isSpecialSymbol }, index) => (
            <NumpadButton
              key={index}
              className={styles.UnitGridButton}
              onClick={() => {
                if (isSpecialSymbol) {
                  return onSpecialSymbolPress?.(value)
                }

                onClick?.(value)
              }}
            >
              {symbol}
            </NumpadButton>
          ))}
        </div>
      </div>

      <NumericKeypad />
    </div>
  )
}
