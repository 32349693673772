import { createEffect } from 'effector'
import type { PracticeSkill } from '../../PracticeMode/types'
import { createNewSkillAttempt } from './requests'
import usePracticeProblemsStore from '../../PracticeMode/models/practiceProblems'
import { showErrorToast } from '../../../constants/toasts'
import i18next from 'i18next'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'

export const createSkillAttemptFx = createEffect(async (skill: PracticeSkill) => {
  const { functionName, problemCount } = skill.ability
  return await createNewSkillAttempt({ skillId: skill._id, data: { functionName, problemCount } })
})

createSkillAttemptFx.doneData.watch((response) => {
  const practiceProblems = usePracticeProblemsStore.getState()
  practiceProblems.setId(response.data._id)
  practiceProblems.setProblemsList(
    response.data.problems.map((problem) => ({
      ...problem,
      isSubmittedInCurrentSession: false,
    }))
  )
  practiceProblems.setCurrentProblemIndex(0)
  practiceProblems.resetRightAnswersCount()
})

createSkillAttemptFx.fail.watch(({ error }: any) => {
  const errorMessage = error.response
    ? i18next.t(`${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`)
    : error.message

  const toastId = error.response ? error.response.data.code : error.message

  showErrorToast({
    message: errorMessage,
    toastId,
  })
})
