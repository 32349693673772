import React from 'react'
import PracticePageTreeNavigation from '../../PracticePageNavigation/PracticePageTreeNavigation'
import PracticePageNavigation from '../../PracticePageNavigation/PracticePageNavigation'
import { useTranslation } from 'react-i18next'

type PracticeHeaderProps = {
  treeId: string
}

export const PracticeHeader = ({ treeId }: PracticeHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className='practice-page-header'>
      <div className='practice-page-header-element' />
      <span className='practice-page-title'>{t('practiceSidebarText')}</span>
      <div className='practice-page-header-element practice-page-navigation'>
        {treeId ? <PracticePageTreeNavigation /> : <PracticePageNavigation />}
      </div>
    </div>
  )
}
