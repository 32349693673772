import { useEffect } from 'react'

// TODO: narrow targets to look through? could be added inside .watch() and observe only if calculator pallet is open
// works for now so leave
export const useForceInputModeNone = (selector: string) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const targetElement = document.querySelector(selector)
        if (targetElement) {
          targetElement.setAttribute('inputmode', 'none')
        }
      })
    })

    const targetNode = document.body
    const config = { childList: true, subtree: true }

    observer.observe(targetNode, config)

    return () => {
      observer.disconnect()
    }
  }, [selector])
}
