import React, { useCallback, useState } from 'react'
import { useForceInputModeNone } from '../useForceInputModeNone'
import { CALCULATOR_PALLET_OFFSET, calculatorConfig } from '../constants'
import { AppletApiRef, GeogebraAppNames } from '../../Geogebra/types'
import { useOnProblemChange } from '../../assignment/hooks/useOnProblemChange'
import { PalletContainer } from '../../../ui/palletContainer/PalletContainer'
import { buttonSizeToOffset } from '../helpers'
import { IconButton } from '../../../ui/IconButton'
import styles from '../styles.module.scss'
import { ExitIcon } from '../../../ui/icons/Exit/ExitIcon'
import Geogebra from '../../Geogebra/components/Geogebra'
import { CalculatorPalletProps, useObservable } from '@magmamath/react-native-draw-board'

type GeogebraCalculatorProps = {
  initialValue?: string
  perspective: 'A' | 'AG'
  size: { width: number; height: number }
  className?: string
  id: string
} & CalculatorPalletProps

const GeogebraCalculator = ({ initialValue, context, perspective, size, className, id }: GeogebraCalculatorProps) => {
  useForceInputModeNone('[data-test="algebraInput"]')
  const [calculatorState, setCalculatorState] = useState(initialValue)
  const isPalletOpen = useObservable(context.pallets.calculator.$isOpened)

  const appletOnLoad = useCallback(
    (appletApi: AppletApiRef) => {
      appletApi.registerClientListener(([event]) => {
        if (event === 'editorStart') {
          context.drawBoard.spreadsheet?.controller?.blur()
        }
      })

      localStorage.setItem('keyboardwanted', 'true')
      appletApi.setPerspective(perspective) // set to Algebra and Graphing mode and makes keyboard open by default
    },
    [context, perspective]
  )

  const onPressCloseButton = () => {
    setCalculatorState(window.ggbApplet?.getBase64() ?? '')
    context.pallets.calculator.$isOpened.setValue(false)
  }

  useOnProblemChange(() => {
    setCalculatorState('')
    context.pallets.calculator.$isOpened.setValue(false)
  })

  if (!isPalletOpen) return null

  return (
    <PalletContainer
      style={{
        top: context.sizes.button + CALCULATOR_PALLET_OFFSET,
        right: buttonSizeToOffset(context.sizes.button),
      }}
      width={size.width}
      height={size.height}
      className={className}
    >
      <IconButton
        rounded='full'
        variant='gray'
        size='sm'
        withShadow
        className={styles.CloseButton}
        onClick={onPressCloseButton}
      >
        <ExitIcon width={16} height={16} />
      </IconButton>

      <Geogebra
        id={id}
        appName={GeogebraAppNames.CLASSIC}
        scaleContainerClass='ggb-applet'
        appletOnLoad={appletOnLoad}
        showAlgebraInput
        useBrowserForJS
        initialBase64AppletState={calculatorState}
        width={size.width}
        height={size.height}
        geogebraSettings={calculatorConfig}
      />
    </PalletContainer>
  )
}

export default GeogebraCalculator
