import React from 'react'
import styles from '../styles.module.scss'
import type { NumericKeypadProps } from '../components/NumericKeypad'
import { checkIsSwedenLocale } from '../../../helpers/userLocale'
import { SIGNS } from './common'
import Sup from '../../../ui/Sup'
import IconFraction from '../../../ui/icons/Icon.Fraction'
import { SquareRootIcon } from '../../../ui/icons/SquareRoot'
import { LowerThanIcon } from '../../../ui/icons/LowerThan'
import { LowerEqualIcon } from '../../../ui/icons/LowerEqual'
import { GreaterThanIcon } from '../../../ui/icons/GreaterThan'
import { GreaterEqualIcon } from '../../../ui/icons/GreaterEqual'
import { HyphenIcon } from '../../../ui/icons/Hyphen'
import { FRACTION, POWER, POWER_SQUARE } from '../../../constants/mathOperationConstants'
import { createGridGap } from '../helpers'

const isSwedishLocale = checkIsSwedenLocale()
const isUKLocale = Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/London' // TODO: update when backend ready
const localizedSeparator = isSwedishLocale ? SIGNS.COMMA : SIGNS.DOT

export type NumpadSymbol = {
  value: string
  symbol: React.ReactNode
  styles?: string
  isHidden?: boolean
  isSpecialSymbol?: boolean
}

export const NUMERIC = ({ hideSeparatorButton, hideFractionButton }: NumericKeypadProps): NumpadSymbol[] => [
  { value: '1', symbol: '1' },
  { value: '2', symbol: '2' },
  { value: '3', symbol: '3' },
  { value: FRACTION, symbol: <IconFraction />, isHidden: hideFractionButton, isSpecialSymbol: true },
  { value: '4', symbol: '4' },
  { value: '5', symbol: '5' },
  { value: '6', symbol: '6' },
  { value: localizedSeparator, symbol: localizedSeparator, isHidden: hideSeparatorButton },
  { value: '7', symbol: '7' },
  { value: '8', symbol: '8' },
  { value: '9', symbol: '9' },
  { value: '0', symbol: '0' },
]

export const NUMBERS_RATIONAL: NumpadSymbol[] = [
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: POWER_SQUARE, symbol: <Sup value='a' supValue='2' />, isSpecialSymbol: true },
  { value: POWER, symbol: <Sup value='a' supValue='b' />, isSpecialSymbol: true },
]

export const OPERATORS: NumpadSymbol[] = [
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: SIGNS.PLUS, symbol: SIGNS.PLUS },
  isSwedishLocale
    ? { value: SIGNS.MULTIPLY_SWE, symbol: SIGNS.MULTIPLY_SWE }
    : { value: SIGNS.MULTIPLY_EN, symbol: SIGNS.MULTIPLY_EN },
  { value: SIGNS.DIVIDE, symbol: SIGNS.DIVIDE_WITH_DOTS },
  { value: SIGNS.COLON, symbol: SIGNS.COLON },
  { value: SIGNS.EQUAL, symbol: SIGNS.EQUAL },
  { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
  { value: POWER_SQUARE, symbol: <Sup value='a' supValue='2' />, isSpecialSymbol: true },
  { value: SIGNS.SQUARE_ROOT, symbol: <SquareRootIcon /> },
  { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
  { value: POWER, symbol: <Sup value='a' supValue='b' />, isSpecialSymbol: true },
]

export const PERCENT: NumpadSymbol[] = isSwedishLocale
  ? [
      { value: SIGNS.PERCENTAGE, symbol: SIGNS.PERCENTAGE },
      { value: SIGNS.PERMILLE, symbol: SIGNS.PERMILLE },
      { value: 'ppm', symbol: 'ppm', styles: styles.fontMd },
      { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
    ]
  : [
      { value: SIGNS.PERCENTAGE, symbol: SIGNS.PERCENTAGE },
      { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
    ]

export const TIME: NumpadSymbol[] = isSwedishLocale
  ? [{ value: SIGNS.DOT, symbol: SIGNS.DOT }]
  : [
      { value: SIGNS.COLON, symbol: SIGNS.COLON },
      { value: 'am', symbol: 'am', styles: styles.fontMd },
      { value: 'pm', symbol: 'pm', styles: styles.fontMd },
    ]

export const CURRENCY: NumpadSymbol[] = isUKLocale
  ? [
      { value: SIGNS.POUND, symbol: SIGNS.POUND },
      { value: `${SIGNS.PENCE} `, symbol: SIGNS.PENCE },
    ]
  : isSwedishLocale
  ? [
      { value: SIGNS.KRONA, symbol: SIGNS.KRONA },
      { value: SIGNS.DOLLAR, symbol: SIGNS.DOLLAR },
      { value: SIGNS.EURO, symbol: SIGNS.EURO },
    ]
  : [{ value: SIGNS.DOLLAR, symbol: SIGNS.DOLLAR }]

export const GEOMETRIC_COORDINATES: NumpadSymbol[] = isSwedishLocale
  ? [
      { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
      createGridGap(),
      createGridGap(),
      { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
      { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
    ]
  : [
      { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
      createGridGap(),
      createGridGap(),
      { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
      { value: SIGNS.COMMA, symbol: SIGNS.COMMA },
      { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
    ]

export const GEOMETRIC_ANGLES: NumpadSymbol[] = [
  { value: SIGNS.PI, symbol: SIGNS.PI },
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: SIGNS.DEGREE, symbol: SIGNS.DEGREE },
]

export const ALGEBRA_BASIC: NumpadSymbol[] = [
  { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
  { value: POWER_SQUARE, symbol: <Sup value='a' supValue='2' />, isSpecialSymbol: true },
  { value: SIGNS.PI, symbol: SIGNS.PI },
  { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
  { value: POWER, symbol: <Sup value='a' supValue='b' />, isSpecialSymbol: true },
  { value: SIGNS.EQUAL, symbol: SIGNS.EQUAL },
  { value: SIGNS.LESS_THAN, symbol: <LowerThanIcon /> },
  { value: SIGNS.LESS_OR_EQUAL_THAN, symbol: <LowerEqualIcon /> },
  { value: SIGNS.PLUS, symbol: SIGNS.PLUS },
  { value: SIGNS.GREATER_THAN, symbol: <GreaterThanIcon /> },
  { value: SIGNS.GREATER_OR_EQUAL_THAN, symbol: <GreaterEqualIcon /> },
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: SIGNS.SQUARE_ROOT, symbol: <SquareRootIcon /> },
]

export const NUMBERS_AND_X: NumpadSymbol[] = [
  { value: SIGNS.X, symbol: SIGNS.X, styles: styles.XButton },
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: SIGNS.PLUS, symbol: SIGNS.PLUS },
  { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
  { value: POWER_SQUARE, symbol: <Sup value='a' supValue='2' />, isSpecialSymbol: true },
  { value: SIGNS.EQUAL, symbol: SIGNS.EQUAL },
  { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
  { value: POWER, symbol: <Sup value='a' supValue='b' />, isSpecialSymbol: true },
  { value: SIGNS.SQUARE_ROOT, symbol: <SquareRootIcon /> },
]

export const ADVANCED_ALGEBRA = [
  { value: SIGNS.BRACKET_LEFT, symbol: SIGNS.BRACKET_LEFT },
  { value: POWER_SQUARE, symbol: <Sup value='a' supValue='2' />, isSpecialSymbol: true },
  { value: SIGNS.PI, symbol: SIGNS.PI },
  { value: SIGNS.BRACKET_RIGHT, symbol: SIGNS.BRACKET_RIGHT },
  { value: POWER, symbol: <Sup value='a' supValue='b' />, isSpecialSymbol: true },
  { value: SIGNS.EQUAL, symbol: SIGNS.EQUAL },
  { value: SIGNS.LESS_THAN, symbol: <LowerThanIcon /> },
  { value: SIGNS.LESS_OR_EQUAL_THAN, symbol: <LowerEqualIcon /> },
  { value: SIGNS.PLUS, symbol: SIGNS.PLUS },
  { value: SIGNS.GREATER_THAN, symbol: <GreaterThanIcon /> },
  { value: SIGNS.GREATER_OR_EQUAL_THAN, symbol: <GreaterEqualIcon /> },
  { value: SIGNS.SUBTRACTION, symbol: <HyphenIcon /> },
  { value: SIGNS.SQUARE_ROOT, symbol: <SquareRootIcon /> },
  createGridGap(),
  createGridGap(),
]

export const DIGITS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

export const ALGEBRA_LETTERS = ['x', 'y', 'z', 'a', 'b', 'c', 'n', 'p', 't']
export const ADVANCED_ALGEBRA_LETTERS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'h',
  'i',
  'm',
  'n',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]
