import { ComponentType } from 'react'
import ScientificCalculator from './components/ScientificCalculator'
import GraphingCalculator from './components/GraphingCalculator'
import { CalculatorPalletProps } from '@magmamath/react-native-draw-board'

export enum CalculatorType {
  REGULAR = 1,
  SCIENTIFIC = 2,
  GRAPHING = 3,
}

export const CALCULATORS_MAP: Partial<Record<CalculatorType, ComponentType<CalculatorPalletProps>>> = {
  [CalculatorType.SCIENTIFIC]: ScientificCalculator,
  [CalculatorType.GRAPHING]: GraphingCalculator,
}

export function getCalculatorPallet(calculatorType: CalculatorType | undefined) {
  if (!calculatorType) return undefined
  return CALCULATORS_MAP[calculatorType]
}
