import React from 'react'

import styles from './styles.module.scss'

type EmptyPageProps = {
  message: string
}

const EmptyContainer = ({ message }: EmptyPageProps) => {
  return (
    <div className={styles.EmptyContainer}>
      <span className={styles.EmptyContainerText}>{message}</span>
    </div>
  )
}

export default EmptyContainer
