import React from 'react'
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'

export const EmptyAssignment = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.ExerciseAnswerContainer}>
      <span className={styles.EmptyAssignmentText}>{t('emptyProblems')}</span>
    </div>
  )
}
