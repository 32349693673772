import React, { MouseEvent } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { ContentEditableEvent } from 'react-contenteditable'

import { AnswerContentEditable, CaretPosition } from './components/AnswerContentEditable'
import { AnswerType } from '../../../../features/Problem/types.answer'
import { BackspaceButton } from './components/BackspaceButton'
import { CorrectAnswerPopup } from './components/CorrectAnswerPopup'
import { ExerciseMode } from '../../../../features/Problem/models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../../../../features/Problem/problem.style.mode.helpers'
import { InputFieldLabel } from './components/InputFieldLabel'
import { InputType } from '../../../../features/Problem/models/inputType'
import { MyScriptMathCharacterTypes } from '../../../../constants/myScriptMathCharacterTypes'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../features/assignment/model/assignment.model'

type ProblemAnswerInputProps = {
  answerType?: AnswerType
  characterType?: MyScriptMathCharacterTypes
  exerciseMode: ExerciseMode
  inputType: InputType
  isDisabled: boolean
  isPracticeMode: boolean
  isUnitRequired: boolean
  onBackspacePress?: (event: MouseEvent) => void
  onChange: (event: ContentEditableEvent) => void
  onFocus: () => void
  onTabPress: () => void
  showAnswerPreview: boolean
  showAnswerPreviewExamMode: boolean
  styleVariant: string
  value: string
  showLabel?: boolean
  showBackspaceButton?: boolean
  classNames?: {
    root?: string
    container?: string
    input?: string
    answerPopup?: string
  }
  placeholder?: string
  initialCaretPosition?: CaretPosition
}

export const ProblemAnswerInput = ({
  answerType,
  characterType = MyScriptMathCharacterTypes.NUMBERS,
  exerciseMode,
  inputType,
  isDisabled,
  isPracticeMode,
  isUnitRequired,
  onBackspacePress,
  onChange,
  onFocus,
  onTabPress,
  showAnswerPreview,
  showAnswerPreviewExamMode,
  styleVariant,
  value,
  showLabel = true,
  showBackspaceButton = true,
  classNames,
  placeholder,
  initialCaretPosition,
}: ProblemAnswerInputProps) => {
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)
  const isMyscriptInputType = inputType === InputType.MY_SCRIPT
  const isBackspaceVisible = !isMyscriptInputType && !styleVariant
  const modeStyles = ExerciseThemeVariantMapper[exerciseMode]

  return (
    <div className={clsx(styles.ProblemAnswerInput, classNames?.root)}>
      <div
        className={clsx(styles.ContentEditableContainer, classNames?.container, {
          [styles[modeStyles]]: !!modeStyles,
          [styles[styleVariant]]: !!styleVariant,
        })}
      >
        {showLabel && (
          <InputFieldLabel
            characterType={characterType}
            isUnitRequired={isUnitRequired}
            styleVariant={styleVariant}
            modeStyles={modeStyles}
          />
        )}

        {showBackspaceButton && (
          <BackspaceButton
            exerciseMode={exerciseMode}
            isVisible={isBackspaceVisible}
            onBackspacePress={onBackspacePress}
          />
        )}

        <AnswerContentEditable
          answerType={answerType}
          characterType={characterType}
          html={value}
          inputType={inputType}
          isDisabled={isDisabled || isAnswerPending}
          onChange={onChange}
          onFocus={onFocus}
          onTabPress={onTabPress}
          className={classNames?.input}
          placeholder={placeholder}
          initialCaretPosition={initialCaretPosition}
        />

        <CorrectAnswerPopup
          className={classNames?.answerPopup}
          showAnswerPreview={showAnswerPreview}
          showAnswerPreviewExamMode={showAnswerPreviewExamMode}
          isPracticeMode={isPracticeMode}
          isLoading={isAnswerPending}
        />
      </div>
    </div>
  )
}
