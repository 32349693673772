import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SimpleModeNumpadButton } from '../SimpleModeNumpadButton/SimpleModeNumpadButton'
import { SimpleModeTrashIcon } from '../../../../ui/icons/SimpleModeTrashIcon'
import { SimpleModeDigitsIcon } from '../../../../ui/icons/NumpadDigitsIcon'
import { SimpleModePenIcon } from '../../../../ui/icons/PenIcon'
import useInputTypeStore, { InputType } from '../../../Problem/models/inputType'
import { focusAndSetCaretAtEnd } from '../../../../components/Problem/ProblemAnswer/ProblemAnswerInput/helpers'
import { currentProblemAnswerModel } from '../../../Problem/models/answer'
import { CLEAR_ALL } from '../../../../constants/mathOperationConstants'
import { specialSymbolPress } from '../../../Problem/problem.answer.helpers'
import { useUnit } from 'effector-react'

type SimpleModeToolActionProps = {
  isMistakeAnswerStatus: boolean
  isExamMode: boolean
  showClearButton?: boolean
}

export const SimpleModeToolAction = ({
  isMistakeAnswerStatus,
  isExamMode,
  showClearButton = true,
}: SimpleModeToolActionProps) => {
  const inputType = useInputTypeStore((state) => state.value)
  const toggleInputType = useInputTypeStore((state) => state.toggle)
  const answerText = useUnit(currentProblemAnswerModel.$text)

  const handleToggleInputType = () => {
    toggleInputType()
    focusAndSetCaretAtEnd()
  }

  const handleClearUserAnswer = () => {
    if (inputType === InputType.KEYBOARD) {
      specialSymbolPress(
        CLEAR_ALL,
        false,
        answerText,
        currentProblemAnswerModel.setText,
        currentProblemAnswerModel.clearText
      )
    }

    if (isMistakeAnswerStatus || isExamMode) currentProblemAnswerModel.reset()
    currentProblemAnswerModel.clearText()
  }

  return (
    <View style={styles.container}>
      {showClearButton && (
        <SimpleModeNumpadButton width={70} height={70} variant='dark' onPress={handleClearUserAnswer}>
          <SimpleModeTrashIcon />
        </SimpleModeNumpadButton>
      )}

      <SimpleModeNumpadButton width={70} height={70} onPress={handleToggleInputType} variant='grey'>
        {inputType === InputType.MY_SCRIPT ? <SimpleModeDigitsIcon /> : <SimpleModePenIcon />}
      </SimpleModeNumpadButton>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginHorizontal: 8,
    marginBottom: 8,
    marginTop: 4,
  },
})
