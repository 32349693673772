import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'
import clsx from 'clsx'

import styles from './styles.module.scss'

import usePracticeProblemsStore from '../../models/practiceProblems'
import { getNextPromptedSkill } from '../../../practiceModeSkills/skillsTree.helpers'
import { fullscreenModeOff } from '../../../../helpers'
import EndExerciseAbilityItem from './EndExerciseAbilityItem/EndExerciseAbilityItem'
import BackToExercisesButton from '../../../../ui/buttons/backToExercisesButton'
import ArrowFigure from '../../../../ui/icons/ArrowFigure'
import CardProgress from '../../AbilityCard/CardProgress/CardProgress'
import { CorrectAnswerRatePercentage } from '../../constants'
import { skillInfoModel } from '../../../practiceModeSkills/model/skillInfoModel'
import useOpenSkill from '../../hooks/useOpenSkill'
import { fetchSkillsTreeStatisticsFx } from '../../../practiceModeSkills/model/requests'
import { practiceSkillsModel } from '../../../practiceModeSkills/model/skillsTreeModel'

const BIG_STAR_SIZE = 75

const PracticeSkillsExitModal = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { openSkill } = useOpenSkill()

  const { rightAnswersCount, problemsList, setEndProblems } = usePracticeProblemsStore()

  const openedSkillInfo = useUnit(skillInfoModel.$openedSkillInfo)
  const nextSkillInfo = useUnit(skillInfoModel.$nextSkillInfo)
  const gradeSubtree = useUnit(practiceSkillsModel.$gradeSubtree)

  const currentAttemptStatistic = (rightAnswersCount / problemsList.length) * 100
  const isSuccessAttempt = currentAttemptStatistic >= CorrectAnswerRatePercentage.ONE_STAR

  const openNextCard = async () => {
    setEndProblems(false)
    if (!nextSkillInfo) return

    const nextSkill = gradeSubtree
      ? getNextPromptedSkill({
          practiceTree: gradeSubtree,
          currentSkillId: nextSkillInfo?._id || '',
        })
      : undefined

    await openSkill({ skill: nextSkillInfo, nextSkill })
  }

  const tryAgainExercise = async () => {
    if (!openedSkillInfo) return
    await openSkill({ skill: openedSkillInfo })
    setEndProblems(false)
    fetchSkillsTreeStatisticsFx()
  }

  const leaveExercise = () => {
    setEndProblems(false)
    history.goBack()
    fullscreenModeOff()
  }

  return (
    <div>
      <div className={styles.EndExerciseWrapper}>
        <div className={clsx(styles.EndExerciseContainer, { [styles.SuccessContainer]: isSuccessAttempt })}>
          <div className={styles.BackToExercisesButtonWrapper}>
            <BackToExercisesButton onClick={leaveExercise} title={t('allCardsText')} />
          </div>
          <div className={styles.LeaveEndExerciseFigure}>
            <ArrowFigure size={{ width: 108, height: 33 }} />
          </div>
          <div className={styles.EndExerciseTextContainer}>
            <div className={styles.EndExerciseCurrentResults}>
              <CardProgress statistic={currentAttemptStatistic} size={BIG_STAR_SIZE} />
            </div>
            <div className={styles.EndExerciseHeading}>
              {isSuccessAttempt && <span>{t('successExerciseAttemptText')}</span>}
              {!isSuccessAttempt && (
                <>
                  <span>{t('failedExerciseAttemptText')}</span>
                  <span>{t('failedExerciseAttemptInfoText')}</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.CardsContainer}>
            {!isSuccessAttempt && (
              <EndExerciseAbilityItem
                abilityName={openedSkillInfo?.nameTranslations || ''}
                onClick={tryAgainExercise}
              />
            )}
            {isSuccessAttempt && nextSkillInfo && (
              <EndExerciseAbilityItem abilityName={nextSkillInfo.nameTranslations} onClick={openNextCard} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.EndExerciseBackground}></div>
    </div>
  )
}

export default PracticeSkillsExitModal
