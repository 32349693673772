import {
  GoogleText2SpeechProps,
  GoogleText2SpeechResponse,
  GoogleTranslateProps,
  GoogleTranslateResponse,
} from './types.google'
import axios from 'axios'
import { GOOGLE_TEXT_TO_SPEECH_URL, GOOGLE_TRANSLATE_URL } from '../constants/url'

export class googleApi {
  public static translate = ({ text, targetLanguage }: GoogleTranslateProps) => {
    return axios
      .post<GoogleTranslateResponse>(
        GOOGLE_TRANSLATE_URL,
        { q: text, target: targetLanguage },
        { params: { key: window._env_.GOOGLE_TRANSLATE_KEY } }
      )
      .then((response) => response.data)
  }

  public static text2Speech = (props: GoogleText2SpeechProps) => {
    return axios
      .post<GoogleText2SpeechResponse>(GOOGLE_TEXT_TO_SPEECH_URL, props, {
        params: { key: window._env_.GOOGLE_TRANSLATE_KEY },
      })
      .then((response) => response.data?.audioContent)
  }
}
