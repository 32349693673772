import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const GreaterEqualIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.116699 13.0581V11.2637L9.30859 7.19873L0.116699 2.69434V0.887695L11.4082 6.71045V7.87012L0.116699 13.0581ZM0.116699 16V14.3398H11.4204V16H0.116699Z'
        fill={fill}
      />
    </svg>
  )
}
