import base64ToBlob from 'base64toblob'

// TODO: to /lib
export function createImageFormDataFromBlob(blobFile: Blob | null) {
  if (!blobFile) {
    return
  }

  const imageFile = new File([blobFile], `drawing-${Date.now()}.jpg`, {
    type: 'image/jpeg',
  })
  const formData = new FormData()
  formData.append('images', imageFile)
  formData.append('type', '3')

  return formData
}

export function createFormDataFromBase64(base64: string) {
  try {
    const blob = base64ToBlob(base64.replace('data:image/png;base64,', ''), 'image/png')
    return createImageFormDataFromBlob(blob)
  } catch (e) {
    return
  }
}
