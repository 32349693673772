import React from 'react'
import SnowflakesConfetti from 'react-snowfall'

import pumpkinImage from '../../../../assets/images/confettiParticles/pumpkin.png'
import leaf from '../../../../assets/images/confettiParticles/leaf.png'
import leaf2 from '../../../../assets/images/confettiParticles/leaf2.png'
import leaf3 from '../../../../assets/images/confettiParticles/leaf3.png'
import { FALL_DURATION_IN_MS, FALL_FADE_OUT_DELAY_IN_MS } from '../../../../constants/problemFeedback'

const particles = [pumpkinImage, leaf, leaf2, leaf3]

const images = particles.map((particle) => {
  const element = document.createElement('img')
  element.src = particle
  return element
})

export const FallConfetti = () => {
  return (
    <SnowflakesConfetti
      snowflakeCount={100}
      images={images}
      radius={[27, 27]}
      speed={[1, 5]}
      changeFrequency={50}
      rotationSpeed={[-1, 3]}
      wind={[-0.5, 4]}
      style={{
        zIndex: 2,
        opacity: 1,
        animation: 'fadeOut',
        animationDuration: `${FALL_DURATION_IN_MS - FALL_FADE_OUT_DELAY_IN_MS}ms`,
        animationDelay: `${FALL_FADE_OUT_DELAY_IN_MS}ms`,
      }}
    />
  )
}
