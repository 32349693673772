import { RefObject, useLayoutEffect } from 'react'

import { Category } from '../types'
import { getVisibleElements } from '../practice.helpers'

const SCROLL_OFFSET_TOP = 32

interface useHighlightMenuOnScrollEffectParams {
  scrollArea: RefObject<HTMLDivElement>
  grade: number
  categories: Category[]
  onSetActiveCategory: (id: string) => void
  onSetActiveSubCategory: (id: string) => void
  onSetStickySubChapter: (id: string) => void
}

const useHighlightItemsOnScrollEffect = ({
  scrollArea,
  grade,
  categories,
  onSetActiveCategory,
  onSetActiveSubCategory,
  onSetStickySubChapter,
}: useHighlightMenuOnScrollEffectParams): void => {
  const filteredCategories = categories

  useLayoutEffect(() => {
    if (!scrollArea.current) return

    if (scrollArea.current.scrollTop > 0) {
      scrollArea.current.scroll(0, 0)
      return
    }

    const firstCategory = scrollArea.current.children?.[0]?.children?.[1]
    onSetActiveCategory(firstCategory?.id || '')
    if (!firstCategory) {
      return
    }

    const firstSubCategory = firstCategory?.children?.[0]
    onSetActiveSubCategory(firstSubCategory?.id || '')
    onSetStickySubChapter(firstSubCategory?.id)
  }, [filteredCategories, grade])

  useLayoutEffect(() => {
    const scrollAreaRef = scrollArea
    const onScroll = () => {
      if (!scrollArea.current) return

      const { offsetHeight, offsetTop } = scrollArea.current
      const visibleCategories: Element[] = getVisibleElements(
        Array.from(scrollArea.current.children) as Element[],
        offsetTop,
        offsetHeight
      )

      const firstVisibleCategory = visibleCategories[0]
      onSetActiveCategory(firstVisibleCategory?.children?.[1]?.id || '')

      if (!firstVisibleCategory) return

      const subCategoriesBlock = firstVisibleCategory.children?.[1]
      const visibleSubCategories = getVisibleElements(
        Array.from(subCategoriesBlock.children) as Element[],
        offsetTop + SCROLL_OFFSET_TOP,
        offsetHeight
      )

      onSetStickySubChapter(visibleSubCategories[0]?.id)
      onSetActiveSubCategory(visibleSubCategories[0]?.id || '')
    }

    scrollAreaRef.current?.addEventListener('scroll', onScroll)

    return () => {
      scrollAreaRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [grade])
}

export default useHighlightItemsOnScrollEffect
