import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const LowerEqualIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0167 13.0581L0.725156 7.87012V6.71045L12.0167 0.887695V2.68213L2.81256 7.19873L12.0167 11.2637V13.0581ZM0.725156 16V14.3398H12.0289V16H0.725156Z'
        fill={fill}
      />
    </svg>
  )
}
