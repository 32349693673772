import React, { useEffect, useState } from 'react'
import { DrawBoard, Preset } from '@magmamath/react-native-draw-board'

import styles from './styles.module.scss'

import PlayIcon from '../../../../ui/icons/PlayIcon'
import PauseIcon from '../../../../ui/icons/PauseIcon'
import UndoIcon from '../../../../ui/icons/UndoIcon'
import RedoIcon from '../../../../ui/icons/RedoIcon'
import { IconButton } from '../../../../ui/IconButton'

type TheoryDrawBoardProps = {
  preset: Preset
}

const TheoryDrawBoard: React.FC<TheoryDrawBoardProps> = ({ preset }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    return preset.model.historyActions.isPlaying.watch((state) => setIsPlaying(state))
  }, [preset])

  const onPlayPress = () => {
    preset.model.historyActions.togglePlaying()
  }

  const onUndo = () => {
    preset.model.historyActions.stepBack()
  }
  const onRedo = () => {
    preset.model.historyActions.stepForward()
  }

  return (
    <div className={styles.DrawBoardContainer}>
      <DrawBoard preset={preset} transformDisabled disabled hideToolbar fitToDrawing />
      <div className={styles.ControlButtons}>
        <IconButton rounded='full' variant='gray' className={styles.ControlButton} onClick={onUndo}>
          <UndoIcon size={25} />
        </IconButton>
        <IconButton className={styles.ControlButton} onClick={onPlayPress} rounded='full' variant='gray'>
          {isPlaying ? <PauseIcon size={25} /> : <PlayIcon size={25} />}
        </IconButton>
        <IconButton rounded='full' variant='gray' className={styles.ControlButton} onClick={onRedo}>
          <RedoIcon size={25} />
        </IconButton>
      </div>
    </div>
  )
}

export default TheoryDrawBoard
