import React, { useContext, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { useStoreMap, useUnit } from 'effector-react'
import { addedNewMessage } from '../../models/messages'
import { ChatMessage } from '../ChatMessage/ChatMessage'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { useChatMessages } from '../../hooks/useChatMessages'
import { useChatAlternatives } from '../../hooks/useChatAlternatives'
import { ChatOption } from '../ChatOption/ChatOption'
import { getUserAnswer } from '../../../../helpers/exercises/exercisesHelper'
import { getUserLanguage } from '../../../../helpers/userLocale'
import { threadIdsModel } from '../../models/threadIdsModel'
import { useHintMyScriptModel } from '../../../AnswerHints/model'
import { useAssignmentCredentials } from '../../../assignment/hooks/useAssignmentCredentials'
import { sendChatMessageFx } from '../../models/fetchChatMessageModel'
import clsx from 'clsx'

type ChatConversationProps = {
  problemAnswer: string[]
  description: string
  height: number
  answerType: number
}

const resizeMordKaTeX = () => {
  // update all KaTeX "mord" span font size that does not include fraction
  document.querySelectorAll('.chatMessages .mord').forEach((element) => {
    if (!element.querySelector('.mfrac') && !element.classList.contains('mtight')) {
      ;(element as HTMLSpanElement).style.fontSize = '21px'
    }
  })
}

export const ChatConversation = ({ description, height, answerType, problemAnswer }: ChatConversationProps) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const scrollToRef = useRef<HTMLDivElement>(null)
  const hintFeedback = useHintMyScriptModel((state) => state.hint?.text)
  const { id, problemId } = useAssignmentCredentials()
  const isLoading = useUnit(sendChatMessageFx.pending)
  const messages = useChatMessages()
  const alternatives = useChatAlternatives()

  const threadId = useStoreMap({
    store: threadIdsModel.$state,
    keys: [id, problemId],
    fn: (threadIds, [assignmentId, problemId]) => threadIds[assignmentId]?.[problemId]?.threadId ?? null,
  })

  useEffect(() => {
    scrollToRef.current?.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'instant',
    })
  }, [])

  useEffect(() => {
    scrollToRef.current?.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    })

    resizeMordKaTeX()
  }, [messages.length])

  const sendMessage = async (text: string, promptKey: string) => {
    addedNewMessage({
      message: text,
      options: [],
      isOwnMessage: true,
      threadId,
      assignmentId: id,
      problemId,
    })

    try {
      const response = await sendChatMessageFx({
        problemAnswer,
        answer: getUserAnswer(answerType),
        type: promptKey,
        hint: hintFeedback ?? '',
        language: getUserLanguage(),
        problem: description,
        grade:
          userSettings.grade !== null && userSettings.grade >= 0 && userSettings.grade <= 12
            ? `${userSettings.grade}`
            : '',
      })

      addedNewMessage({
        ...response,
        isOwnMessage: false,
        assignmentId: id,
        problemId,
      })

      threadIdsModel.setField({
        assignmentId: id,
        problemId,
        threadId: response.threadId,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <div className={styles.ChatConversation} style={{ maxHeight: height }}>
      <ul className={clsx(styles.MessagesList, 'chatMessages')}>
        {messages.map(({ message, isOwnMessage }, index) => (
          <ChatMessage key={index} content={message} isOwnMessage={isOwnMessage} />
        ))}

        {isLoading && <ChatMessage isOwnMessage={false} isLoading />}
      </ul>

      <ul className={styles.AnswersList}>
        {!isLoading
          ? alternatives.map(({ text, promptKey }, index) => (
              <ChatOption key={index} text={text} onClick={() => sendMessage(text, promptKey)} />
            ))
          : null}
      </ul>

      <div ref={scrollToRef} />
    </div>
  )
}
