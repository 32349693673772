import React from 'react'
import '../styles.scss'
import clsx from 'clsx'

import type { MathNumpadSymbol } from '../types'

type MathButtonsProps = {
  symbolsList: MathNumpadSymbol[]
  columnsCount: number
  onPress?: (sign: string) => void
  onSpecialSymbolPress?: (sign: string) => void
}

const MathButtons = ({ symbolsList, columnsCount, onPress, onSpecialSymbolPress }: MathButtonsProps) => {
  return (
    <div
      className='numpad-math-container'
      style={{
        gridTemplateColumns: `repeat(${Math.floor(columnsCount >= 1 ? columnsCount : 1)}, minmax(30px, 71px))`,
      }}
    >
      {symbolsList.map(({ buttonSign, styleClass = '', render, specialSymbol, key }) => (
        <button
          key={key ?? buttonSign}
          className={clsx('numpad-item-button', styleClass)}
          onClick={() => {
            if (specialSymbol) return onSpecialSymbolPress?.(buttonSign)
            onPress?.(buttonSign)
          }}
        >
          {render}
        </button>
      ))}
    </div>
  )
}

export default MathButtons
