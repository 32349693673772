import {
  ACTIVE_SUBCATEGORY_ID,
  PRACTICE_PAGE_SCROLL_POSITION,
  CURRENT_ABILITY_INFO,
  NEXT_PROMPTED_ABILITY_INFO,
  CURRENT_ABILITIES_STATISTIC,
} from '../../config/localStorageKeys'
import { createSessionStorageRequest } from '../../lib/sessionStorage'
import { PracticeStatistics, PracticeSkill, Ability } from './types'

export const practicePageSessionActions = {
  lastActiveSubCategoryId: createSessionStorageRequest<string>(ACTIVE_SUBCATEGORY_ID),
  lastScrollPosition: createSessionStorageRequest<number>(PRACTICE_PAGE_SCROLL_POSITION),
  lastOpenedAbility: createSessionStorageRequest<PracticeSkill | Ability>(CURRENT_ABILITY_INFO),
  nextPromptedAbility: createSessionStorageRequest<PracticeSkill | Ability>(NEXT_PROMPTED_ABILITY_INFO),
  currentAbilitiesStatistics: createSessionStorageRequest<PracticeStatistics>(CURRENT_ABILITIES_STATISTIC),
}
