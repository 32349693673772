import { createEvent, createStore } from 'effector'

type UpdateField<T> = {
  entityId: string
  fieldId: string
  data: T
}

type CreateValuePersistModelSettings<T, U> = {
  mapper?: (value: U) => UpdateField<T>
}

export function createValuePersistorModel<T, U = UpdateField<T>>({
  mapper = (value: any) => value,
}: CreateValuePersistModelSettings<T, U> = {}) {
  const initialState: Record<string, Record<string, T>> = {}
  const fieldAdded = createEvent<UpdateField<T>>()
  const reset = createEvent()

  const setField = (value: U) => {
    fieldAdded(mapper(value))
  }

  const $state = createStore(initialState)
    .on(fieldAdded, (state, { entityId, fieldId, data }) => {
      if (!fieldId || !entityId) {
        console.error('fieldId or entityId missing in createValuePersistorModel.ts')
        return state
      }

      const newState = { ...state }

      if (!newState[entityId]) {
        newState[entityId] = {}
      }

      newState[entityId][fieldId] = data
      return newState
    })
    .reset(reset)

  return { $state, setField, fieldAdded, reset }
}
