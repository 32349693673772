import React from 'react'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const LightBulb = ({ size = 20, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_748_2386)'>
        <path
          d='M10 2.34326C9.67641 2.34326 9.41406 2.60561 9.41406 2.9292C9.41406 3.25279 9.67641 3.51514 10 3.51514C11.9388 3.51514 13.5162 5.09225 13.5162 7.03076C13.5162 7.35436 13.7786 7.6167 14.1021 7.6167C14.4257 7.6167 14.6881 7.35436 14.6881 7.03076C14.6881 4.44607 12.585 2.34326 10 2.34326Z'
          fill={fill}
        />
        <path
          d='M8.48927 0.157448C5.84201 0.710613 3.69552 2.83655 3.13072 5.50026C2.6538 7.74956 3.2415 10.0078 4.7431 11.6958C5.47712 12.521 5.89806 13.6101 5.89806 14.6871V15.859C5.89806 16.6387 6.40849 17.3011 7.11263 17.5305C7.34419 18.8987 8.53169 19.9996 9.99962 19.9996C11.4672 19.9996 12.655 18.899 12.8866 17.5305C13.5908 17.3011 14.1012 16.6387 14.1012 15.859V14.6871C14.1012 13.608 14.5235 12.5319 15.2903 11.6571C16.4127 10.3766 17.0309 8.73362 17.0309 7.03089C17.0309 2.59475 12.9704 -0.778723 8.48927 0.157448ZM9.99962 18.8278C9.24642 18.8278 8.58603 18.3095 8.34216 17.6129H11.657C11.4132 18.3095 10.7528 18.8278 9.99962 18.8278ZM12.9293 15.859C12.9293 16.1821 12.6665 16.445 12.3434 16.445H7.65587C7.33279 16.445 7.06994 16.1821 7.06994 15.859V15.2731H12.9293V15.859ZM14.4091 10.8847C13.5919 11.817 13.0891 12.9412 12.9615 14.1012H7.03783C6.91041 12.9406 6.40892 11.8053 5.61877 10.917C4.36728 9.51011 3.87826 7.62436 4.27712 5.74335C4.74271 3.54749 6.53177 1.7637 8.729 1.3046C12.4799 0.52073 15.859 3.33761 15.859 7.03089C15.859 8.44932 15.3441 9.81796 14.4091 10.8847Z'
          fill={fill}
        />
        <path
          d='M1.75781 7.03076H0.585938C0.262344 7.03076 0 7.29311 0 7.6167C0 7.94029 0.262344 8.20264 0.585938 8.20264H1.75781C2.08141 8.20264 2.34375 7.94029 2.34375 7.6167C2.34375 7.29311 2.08141 7.03076 1.75781 7.03076Z'
          fill={fill}
        />
        <path
          d='M2.00076 4.10133L1.17213 3.2727C0.94334 3.04387 0.572324 3.04387 0.343496 3.2727C0.114668 3.50152 0.114668 3.8725 0.343496 4.10133L1.17213 4.92996C1.40092 5.15879 1.77193 5.15883 2.00076 4.92996C2.22959 4.70113 2.22959 4.33016 2.00076 4.10133Z'
          fill={fill}
        />
        <path
          d='M2.00076 10.3039C1.77193 10.0751 1.40092 10.0751 1.17213 10.3039L0.343496 11.1326C0.114668 11.3614 0.114668 11.7324 0.343496 11.9612C0.572285 12.19 0.943301 12.1901 1.17213 11.9612L2.00076 11.1326C2.22959 10.9037 2.22959 10.5328 2.00076 10.3039Z'
          fill={fill}
        />
        <path
          d='M19.4141 7.03076H18.2422C17.9186 7.03076 17.6562 7.29311 17.6562 7.6167C17.6562 7.94029 17.9186 8.20264 18.2422 8.20264H19.4141C19.7377 8.20264 20 7.94029 20 7.6167C20 7.29311 19.7377 7.03076 19.4141 7.03076Z'
          fill={fill}
        />
        <path
          d='M19.657 3.2727C19.4282 3.04387 19.0572 3.04387 18.8284 3.2727L17.9997 4.10133C17.7709 4.33016 17.7709 4.70113 17.9997 4.92996C18.2285 5.15875 18.5996 5.15879 18.8284 4.92996L19.657 4.10133C19.8858 3.8725 19.8858 3.50152 19.657 3.2727Z'
          fill={fill}
        />
        <path
          d='M19.657 11.1326L18.8284 10.3039C18.5996 10.0751 18.2286 10.0751 17.9997 10.3039C17.7709 10.5328 17.7709 10.9037 17.9997 11.1326L18.8284 11.9612C19.0572 12.19 19.4282 12.19 19.657 11.9612C19.8858 11.7324 19.8858 11.3614 19.657 11.1326Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_748_2386'>
          <rect width='20' height='20' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
