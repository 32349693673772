import { createEvent, restore, sample } from 'effector'
import { $detectedCheat } from '../CheatDetection/models/cheatDetected'

export enum AssignmentPopup {
  PAUSED = 'paused',
  PAUSED_BY_TEACHER = 'pausedByTeacher',
  FINISHED = 'finished',
  CHEAT_DETECTED = 'cheatDetected',
}

export const closeAssignmentPopup = createEvent()

export const setAssignmentPopup = createEvent<AssignmentPopup | null>()
export const $assignmentPopup = restore(setAssignmentPopup, null).reset(closeAssignmentPopup)

sample({
  clock: $detectedCheat,
  fn: () => AssignmentPopup.CHEAT_DETECTED,
  target: setAssignmentPopup,
})
