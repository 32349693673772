import React from 'react'
import styles from '../styles.module.scss'
import { Letters } from '../components/Letters'
import { NumericKeypad } from '../components/NumericKeypad'
import { ADVANCED_ALGEBRA, ADVANCED_ALGEBRA_LETTERS } from '../constants/symbols'
import { NumpadSymbols } from '../components/NumpadSymbols'

export const AdvancedAlgebra = () => {
  return (
    <div className={styles.NumpadContainer}>
      <Letters letters={ADVANCED_ALGEBRA_LETTERS} />
      <div className={styles.NumpadWrapper}>
        <NumpadSymbols symbols={ADVANCED_ALGEBRA} />
        <NumericKeypad />
      </div>
    </div>
  )
}
