import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './_types'

const ArrowControlLeft = ({ size = 11, color = COLORS.NEUTRAL_9 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 7 12' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.61872 0.381282C6.96043 0.72299 6.96043 1.27701 6.61872 1.61872L2.23744 6L6.61872 10.3813C6.96043 10.723 6.96043 11.277 6.61872 11.6187C6.27701 11.9604 5.72299 11.9604 5.38128 11.6187L0.381282 6.61872C0.0395728 6.27701 0.0395728 5.72299 0.381282 5.38128L5.38128 0.381282C5.72299 0.0395728 6.27701 0.0395728 6.61872 0.381282Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowControlLeft
