import React from 'react'
import { Button, SIZE, BUTTON_VARIANT, BUTTON_COLOR } from '@magmamath/ui'

import styles from './styles.module.scss'

type EndExerciseAbilityItemProps = {
  abilityName: string
  onClick: () => void
}
const EndExerciseAbilityItem = ({ abilityName, onClick }: EndExerciseAbilityItemProps) => {
  return (
    <div className={styles.CardContainer} onClick={onClick}>
      <Button
        size={SIZE.LARGE}
        variant={BUTTON_VARIANT.PRIMARY}
        color={BUTTON_COLOR.YELLOW}
        classes={{ content: styles.ButtonText, button: styles.ButtonContainer }}
      >
        {abilityName}
      </Button>
    </div>
  )
}

export default EndExerciseAbilityItem
