import React, { useContext, useEffect, useState } from 'react'

import {
  isMistakeAnswerStatus,
  isExamModeAnswerStatus,
  transformPreviousSelectedTypeAnswer,
} from '../../../../helpers/exercises/exerciseAnswerHelper'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { currentProblemAnswerModel } from '../../../../features/Problem/models/answer'
import { useChoiceAnswersModel } from '../model'
import { AnswerChoice } from '../AnswerChoice/AnswerChoice'
import { getUserType } from '../../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../../constants/userTypes'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../features/Problem/models/exerciseMode'
import { useUnit } from 'effector-react'
import { assignmentModel } from '../../../../features/assignment/model/assignment.model'

// TODO: move answer choices to separate feature
const ProblemAnswerSingleChoice = (props) => {
  const {
    answerResultStatus,
    examMode,
    isExamFinished,
    examStopped,
    problemData,
    showRequireDrawingMessage,
    practiceMode,
  } = useContext(AnswerInfoContext)
  const singleChoiceAnswer = useChoiceAnswersModel((state) => state.singleChoiceAnswer)
  const setSingleChoiceAnswer = useChoiceAnswersModel((state) => state.setSingleChoiceAnswer)
  const isExamMode = useCurrentExerciseMode((state) => state.exerciseMode === ExerciseMode.EXAM)
  const isAnswerPending = useUnit(assignmentModel.$isAnswerPending)

  const [answerVariants, setAnswerVariants] = useState([])

  useEffect(() => {
    if (!problemData) {
      return
    }

    setAnswerVariants(transformPreviousSelectedTypeAnswer(problemData.answerVariants))
  }, [problemData])

  useEffect(() => {
    if (!props.previousAnswer) {
      setSingleChoiceAnswer(null)
      return
    }

    const prevAnswer = Array.isArray(props.previousAnswer) ? props.previousAnswer[0] : props.previousAnswer
    const previousAnswer = answerVariants.find((answer) => answer.variant === prevAnswer)

    if (previousAnswer) {
      setSingleChoiceAnswer({ id: previousAnswer.id, variant: previousAnswer.variant })
      return
    }

    setSingleChoiceAnswer(null)
  }, [props.previousAnswer, answerVariants])

  const submitAnswer = async () => {
    if (
      (examMode && (isExamModeAnswerStatus(answerResultStatus) || isExamFinished())) ||
      (answerResultStatus && practiceMode)
    ) {
      props.onNextButtonPress()
      return
    }

    if (isMistakeAnswerStatus(answerResultStatus)) {
      currentProblemAnswerModel.resetStatus()
      setSingleChoiceAnswer(null)
      return
    }

    if (props.isSolved) {
      props.onNextButtonPress()
      return
    }

    if (!Object.keys(singleChoiceAnswer).length) {
      return
    }

    await props.onSubmitClick([singleChoiceAnswer?.variant])
  }

  const selectAnswerVariant = (answer) => {
    currentProblemAnswerModel.resetStatus()

    if (singleChoiceAnswer?.id === answer.id) {
      setSingleChoiceAnswer(null)
      return
    }

    setSingleChoiceAnswer(answer)
  }

  const checkIsElementSelected = (element) => {
    return singleChoiceAnswer?.id === element.id
  }

  const isDrawingRequired = showRequireDrawingMessage && !singleChoiceAnswer

  const isOptionDisabled =
    (isExamMode && (isExamFinished() || (examStopped && getUserType() !== TEACHER))) ||
    props.isSolved ||
    isAnswerPending ||
    isDrawingRequired

  const isSubmitDisabled =
    !singleChoiceAnswer ||
    !Object.keys(singleChoiceAnswer).length ||
    (isAnswerPending && !(isExamMode && isExamFinished()))

  return (
    <AnswerChoice
      answerOptions={answerVariants}
      submitAnswer={submitAnswer}
      isOptionDisabled={!!isOptionDisabled}
      isSubmitDisabled={!!isSubmitDisabled}
      isDrawingRequired={isDrawingRequired}
      showAnswerPreview={!!props.allowAnswerLooking}
      handleSelectAnswer={selectAnswerVariant}
      checkIsOptionSelected={checkIsElementSelected}
      showNumberIcons={false}
    />
  )
}

export default ProblemAnswerSingleChoice
