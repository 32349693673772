import React from 'react'
import './animation.css'
import { getDotStyle } from './helpers'
import { COLORS } from '@magmamath/ui'

type DotsLoaderProps = {
  color?: string
  speedMultiplier?: number
  size?: number
  margin?: number
  dotsCount?: number
  className?: string
}

export const DotsLoader = ({
  color = COLORS.PRIMARY_BLUE,
  speedMultiplier = 1,
  size = 10,
  margin = 2,
  dotsCount = 3,
  className = '',
}: DotsLoaderProps) => {
  return (
    <span className={className}>
      {Array.from(Array(dotsCount).keys()).map((_, index) => (
        <span
          key={index}
          style={getDotStyle({
            index,
            size,
            speedMultiplier,
            color,
            margin,
          })}
        />
      ))}
    </span>
  )
}
