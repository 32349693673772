
export const listVariables = () => {
  const variableList = {
    a: 1.413,
    b: 2.122,
    // c: 1.211, //cups
    d: 1.223,
    e: 1.237,
    f: 1.242,
    // g: 1.251, //grams
    // h: 1.201, //hours
    i: 1.267,
    j: 1.273,
    k: 1.281,
    // l: 1.291, //liter
    // m: 1.031, //meter
    n: 1.314,
    o: 1.327,
    p: 1.333,
    q: 1.349,
    r: 1.357,
    // s: 1.363, //seconds
    // t: 1.371, //tons
    u: 1.381,
    v: 1.397,
    w: 1.431,
    x: 1.657,
    y: 1.412,
    z: 1.123,
  };
  return variableList;
};
export const listVariables2 = () => {
  const variableList = {
    c: 1.211, //cups
    g: 1.251, //grams
    l: 1.291, //liter
    m: 1.031, //meter
    t: 1.371, //tons
    h: 1.201, //hours
    s: 1.363, //seconds
  };
  return variableList;
};