import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const HyphenIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='13' height='3' viewBox='0 0 13 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.273496 2.0166V0.356445H12.0289V2.0166H0.273496Z' fill={fill} />
    </svg>
  )
}
