import { useStoreMap } from 'effector-react'
import { $validatedDrawings } from './model'
import { useAssignmentCredentials } from '../assignment/hooks/useAssignmentCredentials'

export function useValidatedDrawings() {
  const credits = useAssignmentCredentials()
  return useStoreMap({
    store: $validatedDrawings,
    fn: (state) => !!state[credits.id]?.[credits.problemId]?.isValid,
    keys: [credits.id, credits.problemId],
  })
}
