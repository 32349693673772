import { splitTextAndSeparatorsToTranslate } from '../../helpers'
import { translateProblemInfo } from '../../features/assignments/requests'
import { nativeLanguageISOCodeMap } from '../../config/allLanguages'
import { LINE_BREAK_SYMBOL, SPECIAL_PLACE_NOT_TRANSLATE } from '../../constants/defaults'

export const getTranslatedText = async (text: string, nativeLanguage: string) => {
  const { plainTextArray, specialContentArray } = splitTextAndSeparatorsToTranslate(text)
  const response = await translateProblemInfo(
    plainTextArray,
    nativeLanguageISOCodeMap[nativeLanguage as keyof typeof nativeLanguageISOCodeMap]
  )

  const translations = response?.data?.translations

  if (!translations || !translations.length) {
    return
  }

  const translatedParts = translations.map(
    (translatedPart: { translatedText: string; detectedSourceLanguage: string }) => {
      const regexp = new RegExp(SPECIAL_PLACE_NOT_TRANSLATE, 'g')
      return translatedPart.translatedText.replace(regexp, LINE_BREAK_SYMBOL)
    }
  )

  return translatedParts.reduce((translatedResult: string, currentText: string) => {
    translatedResult += currentText
    const specialContent = specialContentArray.shift()
    if (specialContent) translatedResult += ` ${specialContent} `
    return translatedResult
  }, '')
}
