import { apiManager } from './apiManager'

export type UserQueueInfo = {
  classroomId: string
  classroomName: string
  exerciseId: string
  exerciseName: string
  problemId: string
  problemName: string
  status: number
  student: {
    _id: string
    firstName: string
    lastName: string
  }
  createdAt: Date
  updatedAt: Date
  _id: string
}

type AddToHelpQueueResponse = {
  _id: string
}

type AddToHelpQueuePayload = {
  exerciseId: string
  problemId: string
}

type GetHelpQueueResponse = {
  requestCount: number | null // position in queue
  self: UserQueueInfo | null
}

export class helpQueueApi {
  public static readonly get = apiManager.get<GetHelpQueueResponse, string>('classroom-helpqueue/classrooms')
  public static readonly removeFrom = apiManager.delete<RemoveFromHelpQueueResponse, string>('classroom-helpqueue')
  public static readonly addTo = apiManager.post<AddToHelpQueueResponse, AddToHelpQueuePayload>('classroom-helpqueue')
}

type RemoveFromHelpQueueResponse = any
