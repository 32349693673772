import { useEffect } from 'react'
import { Hint, hintMyScriptModel, HintType } from './model'
import { useProblemStore } from '../Problem/models/model'
import isEmpty from 'lodash/isEmpty'
import { cleanLatexAnswer } from '../../helpers/myScript/cleanLatexAnswer'
import { getAWSProblemHintFeedback, getProblemSpecificHint } from '../assignments/requests'
import { useUnit } from 'effector-react'
import { currentProblemAnswerModel } from '../Problem/models/answer'
import { assignmentModel } from '../assignment/model/assignment.model'
import { writeLogg } from '../../helpers'

const updateHint = ({ text, type }: Hint) => {
  if (isEmpty(text)) {
    hintMyScriptModel.setHint({
      text: '',
      type,
    })
  } else {
    hintMyScriptModel.setHint({
      text,
      type,
    })

    hintMyScriptModel.setHintOpen(true)
  }
}

export type HintResponse = {
  hint?: string
  errorType?: string
}

export const useProblemHint = () => {
  const answerText = useUnit(currentProblemAnswerModel.$text)
  const currentProblemIndex = useProblemStore((state) => state.currentProblemIndex)
  const exercises = useUnit(assignmentModel.$exercises)
  const currentExercise = exercises[currentProblemIndex] ?? null

  const getProblemHint = async () => {
    if (!currentExercise) return
    hintMyScriptModel.setHint(null)

    if (currentExercise.hint) {
      updateHint({
        text: currentExercise.hint,
        type: HintType.MANUAL,
      })

      hintMyScriptModel.setHintOpen(true)
    }

    if (!currentExercise.hintTypeId) return

    hintMyScriptModel.setIsHintLoading(true)

    try {
      const hintType = await getProblemSpecificHint(currentExercise.hintTypeId)
      const correctAnswer = Array.isArray(currentExercise.answer) ? currentExercise.answer[0] : currentExercise.answer

      if (hintType?.title === 'generalHints' && hintType?.isSolutionSpecific === true) {
        const response = await getAWSProblemHintFeedback({
          hintType: hintType.name,
          answer: cleanLatexAnswer(answerText),
          correctAnswer,
          mathExpression: null,
        })

        updateHint({
          text: response?.hint,
          type: HintType.LAMBDA,
        })

        return response
      }

      if (hintType?.name && hintType?.isSolutionSpecific === true && currentExercise.mathExpression) {
        const response = await getAWSProblemHintFeedback({
          hintType: hintType.name,
          answer: cleanLatexAnswer(answerText),
          correctAnswer: correctAnswer || null,
          mathExpression: currentExercise.mathExpression,
        })

        updateHint({
          text: response?.hint,
          type: HintType.LAMBDA,
        })

        return response
      }

      return hintMyScriptModel.setHint(null)
    } catch (error) {
      writeLogg(error)
      hintMyScriptModel.setHint(null)
    } finally {
      hintMyScriptModel.setIsHintLoading(false)
    }
  }

  useEffect(() => {
    if (!currentProblemIndex) return

    hintMyScriptModel.setHint(null)
    hintMyScriptModel.setIsHintLoading(false)

    return () => {
      hintMyScriptModel.setHint(null)
      hintMyScriptModel.setIsHintLoading(false)
    }
  }, [currentProblemIndex])

  return {
    getProblemHint,
  }
}
