import React, { forwardRef, RefObject } from 'react'
import { useUnit } from 'effector-react'
import { practiceSkillsModel } from '../../model/skillsTreeModel'
import { DomainNode } from '../DomainNode/DomainNode'

export const DomainSubtree = forwardRef<HTMLDivElement>((_, ref) => {
  const statistics = useUnit(practiceSkillsModel.$statistics)
  const gradeSubtreeNodes = useUnit(practiceSkillsModel.$domains)

  return (
    <>
      {gradeSubtreeNodes.map((domain) => (
        <DomainNode
          key={domain._id}
          domain={domain}
          statistics={statistics ?? {}}
          scrollArea={(ref as RefObject<HTMLDivElement>)?.current} //????
        />
      ))}
    </>
  )
})
