import { createControllerEffect } from '../../../lib/helpers/effector/createControllerEffect'
import { attach } from 'effector/effector.umd'
import { $assignmentId, $exerciseId } from '../../assignment/model/credentials.model'
import { threadIdsModel } from './threadIdsModel'
import { drawingImagesModel } from '../../AnswersQueue/models/drawingImages/drawingImages'
import { ChatMessagePayload } from '../../../api/api.chat'
import { api } from '../../../api'

export const fetchChatMessageFx = createControllerEffect(api.chat.sendMessage)

export const sendChatMessageFx = attach({
  source: {
    id: $assignmentId,
    problemId: $exerciseId,
    threadIds: threadIdsModel.$state,
    solutionImages: drawingImagesModel.$state,
  },
  mapParams: (
    params: Omit<ChatMessagePayload, 'problemId' | 'threadId' | 'solutionImages'>,
    { id, problemId, threadIds, solutionImages }
  ) => {
    const threadId = threadIds[id]?.[problemId]?.threadId ?? null
    const canvasDrawing = solutionImages[id]?.[problemId]?.drawboardImageURL || null
    return {
      ...params,
      problemId,
      solutionImages: { canvasDrawing },
      threadId,
    }
  },
  effect: fetchChatMessageFx,
})
