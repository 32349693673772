import React, { useState } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { COLORS } from '@magmamath/ui'

type DrawingRequiredMessageProps = {
  text?: string
}

const DrawingRequiredMessage = ({ text }: DrawingRequiredMessageProps) => {
  const { t } = useTranslation()
  const [isUserDrawing, setIsUserDrawing] = useState(false)

  return (
    <div
      className='drawing-required-container'
      onMouseDown={() => setIsUserDrawing(true)}
      onMouseUp={() => setIsUserDrawing(false)}
    >
      <span className='drawing-required-text' style={{ color: isUserDrawing ? COLORS.NEUTRAL_10 : COLORS.NEUTRAL_6 }}>
        {text ?? t('drawingRequiredText')}
      </span>
    </div>
  )
}

export default DrawingRequiredMessage
