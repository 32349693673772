const numberToOrdinal = (num) => {
  const ordinalRule = new Intl.PluralRules('en', { type: 'ordinal' })
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  }
  const suffix = suffixes[ordinalRule.select(num)]

  return num + suffix
}

export default numberToOrdinal
