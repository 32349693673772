import { RefObject, useEffect } from 'react'
import { HANDWRITING_AREA_HEIGHT, SIDEBAR_AREA_WIDTH } from '../constants'
import { AppletApiRef } from '../types'

export const useResizeGeogebraApplet = (appletApiRef: RefObject<AppletApiRef> | null) => {
  useEffect(() => {
    if (!appletApiRef) return

    const handleResize = () => {
      const { innerWidth = 0, innerHeight = 0 } = window

      if (appletApiRef.current) {
        appletApiRef.current.setSize(innerWidth - SIDEBAR_AREA_WIDTH, innerHeight - HANDWRITING_AREA_HEIGHT)
        appletApiRef.current.evalCommand('ZoomIn()') // Moves zoom focus to center of the graph (0, 0)
        appletApiRef.current.recalculateEnvironments() // Changes to responsive version after exceeding min width
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize() // Call handler right away so state gets updated with initial window size
    return () => window.removeEventListener('resize', handleResize)
  }, [appletApiRef])
}
