import React from 'react'
import type { IconProps } from '../types'
import { DEFAULT_ICON_FILL } from '../constants'

export const LowerThanIcon = ({ fill = DEFAULT_ICON_FILL }: IconProps) => {
  return (
    <svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0167 13.0337L0.273496 7.8335V6.67383L12.0167 0.863281V2.66992L2.47076 7.17432L12.0167 11.2393V13.0337Z'
        fill={fill}
      />
    </svg>
  )
}
