import { createEffect } from 'effector'
import { makeApiCall } from '../../../helpers/apiCallHelper'
import { api } from '../../../api'
import { writeLogg } from '../../../helpers'
import type { CreateNewSkillAttemptProps } from '../../../api/api.practice'

export const getSkillsTheory = async (theoryId: string) => {
  try {
    return await api.practice.skillTheory(theoryId)
  } catch (error) {
    writeLogg(error)
    throw error
  }
}

export const createNewSkillAttempt = (skill: CreateNewSkillAttemptProps) => {
  return makeApiCall(() => api.practice.createNewSkillAttempt(skill))
}

export const fetchSkillsTreeStatisticsFx = createEffect(() => {
  return makeApiCall(api.practice.getPracticeTreeStatistic)
})

export const fetchSkillsTreeFx = createEffect((userTreeId: string) => {
  return makeApiCall(() => api.practice.getPracticeTree(userTreeId))
})
