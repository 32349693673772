import i18next from 'i18next'
import { PracticeSkill } from '../types'
import { practiceLoadingModel } from '../models/practiceLoading'
import { skillInfoModel } from '../../practiceModeSkills/model/skillInfoModel'
import usePracticeProblemsStore from '../models/practiceProblems'
import { showErrorToast } from '../../../constants/toasts'
import { errorCodeMessages } from '../../../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../../../constants/customErrorMessages'
import { createNewSkillAttempt } from '../../practiceModeSkills/model/requests'

type OpenSkillProps = {
  skill: PracticeSkill
  nextSkill?: PracticeSkill
  onSuccess?: () => void
  onSaveData?: () => void
}

const useOpenSkill = () => {
  const { setId, setProblemsList, setCurrentProblemIndex, resetRightAnswersCount } = usePracticeProblemsStore()
  const openSkill = async ({ skill, nextSkill, onSuccess, onSaveData }: OpenSkillProps) => {
    practiceLoadingModel.setLoadingAbility(true)
    try {
      onSuccess?.()
      const { functionName, problemCount } = skill.ability
      const response = await createNewSkillAttempt({ skillId: skill._id, data: { functionName, problemCount } })
      skillInfoModel.setOpenedSkillInfo(skill)
      setId(response.data._id)
      setProblemsList(response.data.problems.map((problem) => ({ ...problem, isSubmittedInCurrentSession: false })))
      setCurrentProblemIndex(0)
      onSaveData?.()

      if (nextSkill) {
        skillInfoModel.setNextSkillInfo(nextSkill)
      }
    } catch (error: any) {
      showErrorToast({
        message: error.response
          ? i18next.t(
              `${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`
            )
          : error.message,
        toastId: error.response ? error.response.data.code : error.message,
      })
    } finally {
      practiceLoadingModel.setLoadingAbility(false)
      resetRightAnswersCount()
    }
  }

  return { openSkill }
}

export default useOpenSkill
