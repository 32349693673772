import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { ChangeGradeActions, PracticeTree } from '../../types'

import PracticeNavigationDomain from './PracticeNavigationMainBlock/PracticePageNavigationMainBlock'
import GradeSelectorControl from './YearSelectorControl/GradeSelectorControl'
import { getCorrectGradeName } from '../../../practiceModeSkills/skillsTree.helpers'

type PracticePageTreeNavigationMenuProps = {
  grade: number
  practiceGradeTree: PracticeTree
  currentMainContentBlockId: string | null
  getIsChangeGradeDisabled: (changeAction: ChangeGradeActions) => boolean
  onChangeGrade: (changeAction: ChangeGradeActions) => void
}

const TreeNavigationMenu = ({
  grade,
  practiceGradeTree,
  currentMainContentBlockId,
  getIsChangeGradeDisabled,
  onChangeGrade,
}: PracticePageTreeNavigationMenuProps) => {
  const { t } = useTranslation()
  const [openedCategoryId, setOpenedCategoryId] = useState(currentMainContentBlockId)

  useEffect(() => {
    if (practiceGradeTree.attributes?.grade === grade) {
      setOpenedCategoryId(practiceGradeTree.children[0]._id)
    }
  }, [grade, practiceGradeTree])

  return (
    <div className={styles.NavigationMenuContainer}>
      <div className={styles.YearSelector}>
        <GradeSelectorControl
          isDisabled={getIsChangeGradeDisabled(ChangeGradeActions.DECREASE)}
          onClick={() => onChangeGrade(ChangeGradeActions.DECREASE)}
        />

        <span className={styles.YearSelectorTitle}>{getCorrectGradeName(grade, t)}</span>

        <GradeSelectorControl
          isDisabled={getIsChangeGradeDisabled(ChangeGradeActions.INCREASE)}
          onClick={() => onChangeGrade(ChangeGradeActions.INCREASE)}
          isReversed
        />
      </div>

      <div className={styles.CategoriesListContainer}>
        {practiceGradeTree.children.map((domain) => (
          <PracticeNavigationDomain
            key={domain._id}
            domain={domain}
            openedCategoryId={openedCategoryId}
            onClick={setOpenedCategoryId}
          />
        ))}
      </div>
    </div>
  )
}

export default TreeNavigationMenu
