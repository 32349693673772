import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const PauseIcon = ({ size = 16, fill = COLORS.NEUTRAL_10 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_123_235)'>
        <path
          d='M7.97316 0H1.84822C0.827473 0 0 0.827473 0 1.84822V23.1519C0 24.1725 0.827473 25 1.84822 25H7.97316C8.99391 25 9.82138 24.1725 9.82138 23.1519V1.84822C9.8215 0.827473 8.99391 0 7.97316 0Z'
          fill={fill}
        />
        <path
          d='M23.1528 0H17.0279C16.0072 0 15.1797 0.827473 15.1797 1.84822V23.1519C15.1797 24.1725 16.0072 25 17.0279 25H23.1528C24.1737 25 25.0012 24.1725 25.0012 23.1519V1.84822C25.0012 0.827473 24.1737 0 23.1528 0Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_123_235'>
          <rect width='25' height='25' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
