import React from 'react'
import ReactMarkdown from 'react-markdown'
import RemarkMathPlugin from 'remark-math'
import { BlockMath, InlineMath } from 'react-katex'

const mapProps = (props) => {
  return {
    ...props,
    escapeHtml: false,
    plugins: [RemarkMathPlugin],
    renderers: {
      ...props.renderers,
      inlineMath: ({ value }) => {
        if (props.mergeLinebreaks) {
          // KaTeX doesn't handle <br> for inlineMath and uses \n
          const formattedValue = value.replace(/<br>/gm, '\n')
          return <InlineMath>{formattedValue}</InlineMath>
        }
        return <InlineMath>{value}</InlineMath>
      },
      math: ({ value }) => {
        return <BlockMath>{value}</BlockMath>
      },
    },
  }
}

const Markdown = (props) => {
  // outside KaTeX we use <br> to keep line breaks
  // Merge two \n into one because all the content uses double \n and we want to change this behavior.
  // Updating it in web-admin would require changing all the books into one \n

  if (props.mergeLinebreaks) {
    const KaTeXMarkdown = props.children?.replace(/\n\s*\n/gm, '\n').replace(/\n/gm, '<br>')
    const formattedMarkdown = { ...props, children: KaTeXMarkdown }

    return <ReactMarkdown {...mapProps(formattedMarkdown)} />
  }

  return <ReactMarkdown {...mapProps(props)} />
}

export default Markdown
