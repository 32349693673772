import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

import { COLORS } from '@magmamath/ui'
import Loader from './LoaderWrapper'

type ButtonLoaderProps = {
  color?: string
  height?: string
  width?: string
}

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ color = COLORS.NEUTRAL_1, height = '40%', width = '40%' }) => {
  return (
    <Loader>
      <ThreeDots color={color} height={height} width={width} />
    </Loader>
  )
}

export default ButtonLoader
