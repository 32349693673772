import React, { MutableRefObject, useContext, useEffect } from 'react'
import { BoardController, ToolBarVariants } from '@magmamath/react-native-draw-board'

import './styles.scss'

import DrawBoard from '../../../DrawBoard/DrawBoard'
import usePracticeProblemsStore, { PracticeMode } from '../../models/practiceProblems'
import {
  ANSWER_TYPE_BACKGROUND_DESCRIPTOR,
  getBackgroundDescriptorFromAnswerType,
} from '../../../../components/Problem/helpers'
import { currentProblemAnswerModel } from '../../../Problem/models/answer'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'
import { ExerciseThemeColorMapper } from '../../../Problem/problem.style.mode.helpers'
import { focusAnswerInput } from '../../../../lib/helpers/answerInput'
import { useUnit } from 'effector-react'

const PracticeModeDrawBoard = React.forwardRef<BoardController | null>((_, ref) => {
  const mutableRef = typeof ref !== 'function' ? (ref as MutableRefObject<BoardController | null>) : undefined
  const { currentProblem } = usePracticeProblemsStore()
  const { state: userSettings } = useContext(UserSettingsContext)
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const { exerciseMode } = useCurrentExerciseMode()
  const practiceMode = usePracticeProblemsStore((state) => state.practiceMode)

  const { withGrid } = getBackgroundDescriptorFromAnswerType(
    (currentProblem?.answerType ?? 0) as keyof typeof ANSWER_TYPE_BACKGROUND_DESCRIPTOR
  )

  useEffect(() => {
    if (!mutableRef) return
    mutableRef.current?.modules.calculator.model?.clear()

    focusAnswerInput({
      drawBoardRef: mutableRef,
    })
  }, [currentProblem, mutableRef])

  return (
    <div className='practice-draw-board' tabIndex={-1}>
      <DrawBoard
        controller={mutableRef}
        withGrid={withGrid}
        answerType={currentProblem?.answerType ?? 0}
        disabled={!!answerStatus}
        initialMode={userSettings.canvasType}
        toolBarActiveColor={ExerciseThemeColorMapper[exerciseMode]}
        variant={practiceMode === PracticeMode.SIMPLE ? ToolBarVariants.SIMPLE : ToolBarVariants.COMMON}
      />
    </div>
  )
})

export default PracticeModeDrawBoard
