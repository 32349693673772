import * as mathjs from 'mathjs';
import { INVALID_FORMAT, INVALID_UNITS, NOT_SIMPLEST_FORM } from '../../constants/answerStatusConstants';
import { addUnits } from './units';
import { listVariables, listVariables2 } from './variables';
let country = 'en';
let commaFacit = false;
let isMixedNumber = false;

export const evalMathExpression = (mathExpression) => {
    // Support to non used function in Student Web
    return mathExpression;
};

export const clearLeadingZeros = (answer) => {
    const leadingZerosRegexp = /^0\d+/g;
    const match = leadingZerosRegexp.exec(answer);
    if (!match) {
        return answer;
    }
    const leadingZerosPart = answer.slice(match.index, match.index + match[0].length);
    return [
        answer.slice(0, match.index),
        leadingZerosPart.replace(/^0+/, ''),
        answer.slice(match.index + match[0].length),
    ].join('');
};
// const changeDegree = (expression) => {
//     return (expression = expression.replace(/°/g, 'degrees'));
// };

const changeDegree = (expression) => {
    // Changes degree sign and power circ to degrees
    return expression.replace(/\^\[\\circ\]/g, 'degrees').replace(/°/g, 'degrees').replace(/º/g, 'degree');
};

const removeCurrency = (expression) => {
    return expression.replace(/\$/g, '').replace(/€/g, '').replace(/£/g, '').replace(/¢/g, '');
};

const infix = (expression) => {
    // fixing the bug with in^2 and in^3
    return expression.replace(/(in)\^(\(2\))/g, '(in^(2))').replace(/(in)\^(\(3\))/g, '(in^(3))');
};

const checkAndFixRatios = (expression) => {
    // Checks for ratio '1:2', transform to '(1)/(2)'
    let ratioExpression = expression;
    const regex = /:/g;
    if (!!regex.exec(ratioExpression)) {
        const ratio = ratioExpression.split(':');
        ratioExpression = `(${ratio[0]})/(${ratio[1]})`;
    }
    return ratioExpression;
};

const mixedNumberFraction = (expression) => {
    // Check for mixed fraction. e.g 2[1/4] and transforms to (2+(1/4))
    const regex = /[\d]+\[\[?\d+\]?\/\[?\d+\]?\]/g; // Digit[digit/digit]
    const regexDigit = /[\d]+\[/g; // Digit[
    let match;

    while ((match = regex.exec(expression)) !== null) {
        isMixedNumber = true;
        let digitMatch = regexDigit.exec(expression).toString().replace(/\[/g, '+[');
        let fixed = match.toString().replace(regexDigit, digitMatch);
        expression = `${expression.slice(0, match.index)}(${fixed})${expression.slice(match.index + match[0].length)}`;
    }
    return expression;
};

const changeCommaToDot = (expression) => {
    // Changes comma to dot
    if (country != 'sv' && commaFacit === false) {
        const regexComma = /,/g;
        const regexCommadig = /,[\d,]*/g;
        const regexDigit = /\b\d{3}([^0-9]|\b)/g;
        let match;

        // Fix for thousand seperator
        if (regexComma.exec(expression)) {
            // Finds all comma digit comma terms, e.g. 1,000,000
            while ((match = regexCommadig.exec(expression)) !== null) {
                let splitExp = match.toString().split(',').slice(1);
                // if all elements contains exactly 3 digits then comma is removed else not
                if (
                  !splitExp.some((e) => {
                      regexDigit.lastIndex = 0;
                      return !regexDigit.exec(e);
                  })
                ) {
                    splitExp = splitExp.join('');
                    expression = `${expression.slice(0, match.index)}${splitExp}${expression.slice(
                      match.index + match[0].length
                    )}`;
                }
            }
        }

        return expression;
    } else {
        return expression.replace(/,/g, '.');
    }
};
const changePiSign = (expression) => {
    // Changes π to pi
    return expression.replace(/π/g, 'pi');
};

const changeSqrtSign = (expression) => {
    // Changes √ to sqrt
    return expression.replace(/√/g, 'sqrt');
};
const changeMultisign = (expression) => {
    // Changes multisign x to *
    return expression.replace(/×/g, '*').replace(/⋅/g, '*');
};
const changeDivsign = (expression) => {
    // Changes multisign x to *
    return expression.replace(/÷/g, '/');
};
const changeBrackets = (expression) => {
    // Changes brackets from [] to ()
    return expression.replace(/\[/g, '(').replace(/]/g, ')');
};
const changePercent = (expression) => {
    // Changes brackets from percent, procent to %
    return expression
      .replace(/percent/g, '%')
      .replace(/procent/g, '%')
      .replace(/‰/g, '%/10')
      .replace(/promille/g, '%/10')
      .replace(/permille/g, '%/10')
      .replace(/ppm/g, '%%%');
};
const removeBlanks = (expression) => {
    // Removes blank space
    return expression.replace(/\s/g, '');
};

const removeInbetweenDot = (expression) => {
    // Removes dots between letters. E.g sq. in. => sq in.
    return expression.replace(/([A-Za-zåäöÅÄÖ])(\.)([A-Za-zåäöÅÄÖ])/g, '$1$3');
};

const removeUnitDot = (expression) => {
    // Removes blank space like 'in.'  => 'in'
    return expression.replace(/\.$/g, '');
};

const changeMinSec = (expression) => {
    // Change sec, min to secs, mins
    return expression.replace(/(min$)/g, 'mins').replace(/(sec$)/g, 'secs');
};

const removeInitialFunc = (expression) => {
    // remove f(x)=, g(x)=, h(x)=
    return expression
      .replace(/^f\([a-zA-Z0-9]\)=/g, '')
      .replace(/^g\([a-zA-Z0-9]\)=/g, '')
      .replace(/^h\([a-zA-Z0-9]\)=/g, '');
};

const changeSpecialPowerSign = (expression) => {
    // Change special power sign to ^2
    const specialPowerSignMap = {
        '⁰': 0,
        '¹': 1,
        '²': 2,
        '³': 3,
        '⁴': 4,
        '⁵': 5,
        '⁶': 6,
        '⁷': 7,
        '⁸': 8,
        '⁹': 9,
    };

    const specialPowerSignRegex = /⁰|¹|²|³|⁴|⁵|⁶|⁷|⁸|⁹/g;
    let replacedAnswer = expression;
    let index = replacedAnswer.search(specialPowerSignRegex);
    while (index !== -1) {
        replacedAnswer = [
            replacedAnswer.slice(0, index),
            '^(',
            specialPowerSignMap[replacedAnswer[index]],
            ')',
            replacedAnswer.slice(index + 1),
        ].join('');

        index = replacedAnswer.search(specialPowerSignRegex);
    }

    return replacedAnswer;
};

const handleEquivalence = (userAnswer, facitAnswer) => {
    let user = userAnswer; //userAnswer
    let facit = facitAnswer;
    let equivalence = false;
    const initialRegex = /(^[a-zA-ZåäöÅÄÖ]\s*)=\s?/; //Find letter + =. E.g. x= in x=2
    const equalRegex = /=/; //Finds '=' in string
    const afterEqualRegex = /[^=]*$/; // Finds a+b in x+y=a+b
    const userRegexed = equalRegex.exec(user); //boolean value for if user answer has a = sign
    const facitRegexed = equalRegex.exec(facit); //boolean value for if facit has a = sign

    if (!!facitRegexed || !!userRegexed) {
        if (!!facitRegexed && !!userRegexed) {
            const userAfterEqual = afterEqualRegex.exec(user).toString();
            var userBeforeEqual =  user.replace(userAfterEqual, "");
            userBeforeEqual =  userBeforeEqual.replace(equalRegex, "");

            const facitAfterEqual = afterEqualRegex.exec(facit).toString();
            var facitBeforeEqual =  facit.replace(facitAfterEqual, "");
            facitBeforeEqual =  facitBeforeEqual.replace(equalRegex, "");

            if (userBeforeEqual != facitBeforeEqual) {
                user = user.replace(afterEqualRegex, `(${userAfterEqual})`).replace(equalRegex, '-');
                facit = facit.replace(afterEqualRegex, `(${facitAfterEqual})`).replace(equalRegex, '-');
                equivalence = true;
            }
        } else {
            user = user.replace(initialRegex, '');
            facit = facit.replace(initialRegex, '');
        }
    }

    return [user, facit, equivalence];
};

const checkNumericFormat = (userAnswer, facitAnswer) => {
    let format = true;
    let regex = /\./g; // Check if facit contains decimal
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /%/g; // Check if facit contains percentage
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /\//g; // Check if facit contains fraction
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /\*/g; // Check if facit contains fraction
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /\+/g; // Check if facit contains fraction
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /-/g; // Check if facit contains fraction
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }

    regex = /\^/g; // Check if facit contains exponent
    if (!!facitAnswer.match(regex)) {
        if (!userAnswer.match(regex)) {
            format = false;
        }
    } else if (!!userAnswer.match(regex)) {
        format = false;
    }
    return format;
};

const primeFactors = (expression) => {
    const factors = [];
    let divisor = 2;
    while (expression >= 2) {
        if (expression % divisor === 0) {
            factors.push(divisor);
            expression = expression / divisor;
        } else {
            divisor++;
        }
    }
    return factors;
};

const polyFactor = (polynom) => {
    const regexx3 = /[A-Za-zåäöÅÄÖ]\^\(3\)/g;
    const regexx2 = /[A-Za-zåäöÅÄÖ]\^\(2\)/g;
    const regexx1 = /[A-Za-zåäöÅÄÖ]/g;

    if (polynom.match(regexx3)) {
        return 3;
    }
    if (polynom.match(regexx2)) {
        return 2;
    }
    if (polynom.match(regexx1)) {
        return 1;
    } else {
        return 0;
    }
};

const checkSimplestFormat = (userAnswer, facitAnswer) => {
    let simplest = false;

    if (
      !facitAnswer.match(/[a-zA-ZåäöÅÄÖ]/g) &&
      !facitAnswer.match(/\+|-|\*/g) &&
      !userAnswer.match(/\+|-|\*/g) &&
      !!userAnswer.match(/\//g)
    ) {
        // If only numerical fraction check for prime factors in de-/nominator. If yes then false
        let user = userAnswer.replace(/\(/g, '').replace(/\)/g, '');
        user = user.split('/');
        let userFactors = user.map((row) => primeFactors(row));
        simplest = !userFactors[0].some((factor) => {
            return userFactors[1].includes(factor) === true;
        });
    } else {
        let facitArray = facitAnswer
          .replace(/\(-/g, '(')
          .split(/\+|-|\)\(|\*\(/)
          .filter((a) => a); //split + remove empty elements due to initial minus sign
        let userArray = userAnswer
          .replace(/\(-/g, '(')
          .split(/\+|-|\)\(|\*\(/)
          .filter((a) => a);
        facitArray = facitArray.map((row) => polyFactor(row));
        userArray = userArray.map((row) => polyFactor(row));
        if (!!(facitArray.sort().join('') === userArray.sort().join(''))) {
            simplest = true;
        }
    }
    return simplest;
};

const superEvaluation = (
  userInput,
  facitInput,
  unitFormat,
  numericFormat,
  simplestFormat,
  setBadAnswerFormatCallback,
  unitRequired
) => {
    const unitRegex = /\(?[A-Za-zåäöÅÄÖ]+\d?(\^\(?[0-9]\)?)?\)?$/;
    const variableList = listVariables();
    const variableList2 = { ...variableList, ...listVariables2() };
    const userVarRegex = /[a-zA-Z(åäöÅÄÖ]{2,}/g;
    let [userAnswer, facitAnswer, equivalence] = handleEquivalence(userInput, facitInput);
    let user;
    let facit;

    facitAnswer = facitAnswer.toString();

    try {
        facit = mathjs.evaluate(facitAnswer, variableList);

    } catch {
        let match;
        while ((match = userVarRegex.exec(facitAnswer)) !== null) {

            let fixed = match.toString().replace(/(.)/g, '$1*').replace(/\*$/g, '');
            facitAnswer = `${facitAnswer.slice(0, match.index)}${fixed}${facitAnswer.slice(
              match.index + match[0].length
            )}`;

        }
        facitAnswer = facitAnswer.replace(/\(\*/g, '(');

        try {
            facit = mathjs.evaluate(facitAnswer, variableList2);
        } catch (err) {
            return false;
        }
    }
    try {
        user = mathjs.evaluate(userAnswer, variableList);
    } catch {
        if (!facit?.units && !facitAnswer.match(/[a-zA-ZåäöÅÄÖ]/g)) {
            // If user entered a non-standard unit like 'hats'
            user = userAnswer.replace(unitRegex, '');
        } else if (
          // facitAnswer.match(/[a-zA-Z]/g) &&
          // userAnswer.match(userVarRegex)
          true
        ) {
            // inserts * where varibles are in row
            let match;
            while ((match = userVarRegex.exec(userAnswer)) !== null) {

                let fixed = match.toString().replace(/(.)/g, '$1*').replace(/\*$/g, '');
                userAnswer = `${userAnswer.slice(0, match.index)}${fixed}${userAnswer.slice(
                  match.index + match[0].length
                )}`;
            }
            userAnswer = userAnswer.replace(/\(\*/g, '(');
            try {
                user = mathjs.evaluate(userAnswer, variableList2);
            } catch (err) {
                return false;
            }
        }
    }
    let isCorrect = false;
    const isFacitUnit = !!facit?.units;
    const isUserUnit = !!user?.units;

    if (isUserUnit === isFacitUnit) {
        // Evalutes if both or neither contains units
        if (isFacitUnit) {
            if (!facit.equalBase(user)) {
                try {
                    if (!!mathjs.equal(userAnswer.replace(unitRegex, ''), facitAnswer.replace(unitRegex, ''))) {
                        setBadAnswerFormatCallback(INVALID_UNITS);
                    }
                } catch (err) {
                    return false;
                }
                return false;
            }
        }
        if (isFacitUnit && unitFormat) {
            // If both contains unit and unitFormat is true. 1m != 10dm, 1m = 1meter

            setBadAnswerFormatCallback(INVALID_UNITS);
            if(!mathjs.equal(facit,user)){
                return false;
            }
            facit = facitAnswer.replace(unitRegex, '');
            user = userAnswer.replace(unitRegex, '');
        }
        // ELSE  1m = 10dm, 1m != 1m^2
    } else if (isFacitUnit && !unitRequired) {
        // Evalutes if user is missing unit but facit contains unit and allowUnit is true
        try {
            facit = mathjs.evaluate(facitAnswer.replace(unitRegex, ''));
        } catch (err) {
            return false;
        }
    } else if (isFacitUnit && !!unitRequired) {
        // Numeric is correct. Facit has units, user not and allowUnit is false
        try {
            if (!!mathjs.equal(user, facitAnswer.replace(unitRegex, ''))) {
                setBadAnswerFormatCallback(INVALID_UNITS);
                return false;
            }
        } catch (err) {
            return false;
        }
    } else {
        // Else false (user contains units and facit not )
        return false;
    }
    try {
        isCorrect = mathjs.equal(user, facit);
    } catch (err) {
        return false;
    }

    if (
      !facitAnswer.match(/[a-zA-ZåäöÅÄÖ]/g) &&
      !facitAnswer.match(/\+|-|\*/g) &&
      !!userAnswer.match(/\+|-|\*/g) &&
      !isMixedNumber &&
      isCorrect
    ) {
        return false;
    }

    if (simplestFormat && isCorrect) {
        // Removes units if any
        facit = isFacitUnit ? facitAnswer.replace(unitRegex, '') : facitAnswer;
        user = isUserUnit ? userAnswer.replace(unitRegex, '') : userAnswer;
        // Check simplest form
        try {
            if (!checkSimplestFormat(user, facit)) {
                setBadAnswerFormatCallback(NOT_SIMPLEST_FORM);
                return false;
            }
        } catch (err) {
            setBadAnswerFormatCallback(NOT_SIMPLEST_FORM);
            return false;
        }
    }

    if (numericFormat & isCorrect) {
        // Removes units if any
        facit = isFacitUnit ? facitAnswer.replace(unitRegex, '') : facitAnswer;
        user = isUserUnit ? userAnswer.replace(unitRegex, '') : userAnswer;
        // Check numeric format
        if (!checkNumericFormat(facit, user)) {
            setBadAnswerFormatCallback(INVALID_FORMAT);
            return false;
        }
    }
    if (!isCorrect && equivalence) {
        // Checks if user input = 0. E.g if input is x=x
        if (user === 0) {
            return false;
        }
        // Test that mod of user and facit is 0 => Correct
        let test = [false, false, false, false];

        test[0] = -0.001 <= user % facit && user % facit <= 0.001 ? true : false;
        test[1] = -0.001 <= facit % user && facit % user <= 0.001 ? true : false;
        test[2] = -0.001 <= mathjs.abs((user % facit))-mathjs.abs(facit) && mathjs.abs((user % facit))-mathjs.abs(facit) <= 0.001 ? true : false;
        test[3] = -0.001 <= mathjs.abs((facit % user))-mathjs.abs(user) && mathjs.abs((facit % user))-mathjs.abs(user) <= 0.001 ? true : false;
        if (
          test.some((testCase) => {
              return testCase === true;
          })
        ) {
            isCorrect = true;
        }
    }

    return isCorrect;
};

const dumbEvaluation = (userInput, facitInput) => {
    const [userAnswer, facitAnswer, equivalence] = handleEquivalence(userInput, facitInput);
    if (userAnswer === facitAnswer) {
        return true;
    } else {
        return false;
    }
};

const evaltime2 = (userInput, facitInput) => {

    const colonReg = /\:/;
    const dotReg = /\./;

    const splitTime = (time) => {
        if (colonReg.test(time)) {
            return time.split(':');
        }

        if (dotReg.test(time)) {
            return time.split('.');
        }

        return [time, '00'];
    };

    const [facitHours, facitMinutes] = splitTime(facitInput);
    const [userHours, userMinutes] = splitTime(userInput);

    return Number(userHours) === Number(facitHours) && userMinutes === facitMinutes;

}

const evaltime = (userInput, facitInput) => {

    userInput = removeBlanks(userInput.toLowerCase());
    facitInput = removeBlanks(facitInput.toLowerCase());

    const amReg = /am/;
    const pmReg = /pm/;

    const userHasAM = amReg.test(userInput);
    const userHasPM = pmReg.test(userInput);
    const facitHasAM = amReg.test(facitInput);
    const facitHasPM = pmReg.test(facitInput);

    if ((facitHasAM && !userHasAM) || (facitHasPM && !userHasPM)) {
        return false;
    }

    userInput = userInput.replace(amReg, "").replace(pmReg, "");
    facitInput = facitInput.replace(amReg, "").replace(pmReg, "");

    return evaltime2(userInput, facitInput);
};

const evalcoordiates = (userInput, facitInput) => {
    //splitting coordinates into array
    let usercoord = userInput.split(',');
    let facitcoord = facitInput.split(',');

    if (usercoord.length <2)
        return false
    //removing parenthesis
    if (usercoord[0].charAt(0) == '(') {
        usercoord[0] = usercoord[0].substring(1);
    }
    if (usercoord[1].charAt(usercoord[1].length - 1) == ')') {
        usercoord[1] = usercoord[1].substring(0, usercoord[1].length - 1);
    }
    facitcoord[0] = facitcoord[0].substring(1);
    facitcoord[1] = facitcoord[1].substring(0, facitcoord[1].length - 1);

    //cleaning coordinates
    usercoord[0] = cleanAnswers(usercoord[0]);
    usercoord[1] = cleanAnswers(usercoord[1]);
    facitcoord[0] = cleanAnswers(facitcoord[0]);
    facitcoord[1] = cleanAnswers(facitcoord[1]);
    //evaluating coordinates

    usercoord[0] = mathjs.evaluate(usercoord[0]);
    usercoord[1] = mathjs.evaluate(usercoord[1]);
    facitcoord[0] = mathjs.evaluate(facitcoord[0]);
    facitcoord[1] = mathjs.evaluate(facitcoord[1]);
    return mathjs.deepEqual(usercoord, facitcoord);
};



const cleanAnswers = (answer) => {
    return removeInbetweenDot(
      infix(
        removeUnitDot(
          clearLeadingZeros(
            changePercent(
              changeMinSec(
                changeMultisign(
                  changeDivsign(
                    changeCommaToDot(
                      changeSqrtSign(
                        changeSpecialPowerSign(
                          checkAndFixRatios(
                            removeInitialFunc(
                              changeBrackets(
                                changePiSign(
                                  removeBlanks(
                                    changeDegree(
                                      removeCurrency(
                                        mixedNumberFraction(answer.toLowerCase())
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
};

export const compareAnswers = (
  currentTask,
  userInput,
  badAnswerFormat,
  setBadAnswerFormatCallback,
  unitRequired,
  userCountry
) => {
    let facitAnswer = currentTask.answer;
    let userAnswer = userInput;
    let isCorrect;
    const format = currentTask.answerFormat;
    const simplestFormat = currentTask.allowSimplification;
    const smartEvaluation = currentTask.smartEvaluation;
    const numericFormat = format;
    const unitFormat = format;
    country = userCountry;
    const mathExpression = currentTask.mathExpression;
    isMixedNumber = false;
    const charactertype = currentTask.characterType;

    // Check for comma in facit (to solve coordinates)
    const commaRegex = /,/g;
    commaFacit = !!commaRegex.exec(facitAnswer);

    if (Array.isArray(facitAnswer)) {
        // Check if currentTask.answer is array and change if length is 1
        if (facitAnswer.length === 1) {
            facitAnswer = facitAnswer[0];
        }
    }

    if (Array.isArray(userAnswer)) {
        // Check if userInput is array and change
        userAnswer = userAnswer[0];
    }

    if (facitAnswer === userAnswer) {
        // Checks if intial strings are identicalif (isCorrect)
        badAnswerFormat && setBadAnswerFormatCallback('');
        return true;
    }

    if (userAnswer === '.' || userAnswer === ',') {
        // Returns false if only dot or comma to avoid error
        return false;
    }

    if (charactertype == 18) {
        //coordinates
        return evalcoordiates(userAnswer, facitAnswer);
    }

    if (charactertype == 15) {
        //time
        return evaltime(userAnswer, facitAnswer);
    }

    try {
        addUnits();
    } catch (err) {} //Add units
    const cleaneduserAnswer = cleanAnswers(userAnswer); // Clean user answer

    // Check for math expression
    if (!!mathExpression) {
        const cleanedMathExp = cleanAnswers(mathExpression);
        const cleanderNoParensMathExp = cleanedMathExp.replace(/([()])/g, '');
        const cleanderNoParensAnswer = cleaneduserAnswer.replace(/([()])/g, '');
        if (
          cleaneduserAnswer === cleanedMathExp ||
          cleaneduserAnswer === cleanderNoParensMathExp ||
          cleanderNoParensAnswer === cleanedMathExp ||
          cleanderNoParensAnswer === cleanderNoParensMathExp
        ) {
            return false;
        }
    }

    if (Array.isArray(facitAnswer)) {
        // Check if string match exactly
        if (
          facitAnswer.some((facit) => {
              return userAnswer === facit;
          })
        ) {
            return true;
        }

        const cleanedFacitAnswer = facitAnswer.map((answer) => cleanAnswers(answer)); // Cleans facit

        if (!smartEvaluation) {
            // Evaluate answers if smartev off
            return cleanedFacitAnswer.some((facit) => dumbEvaluation(cleaneduserAnswer, facit));
        }
        // Evaluate answers if smartev on
        isCorrect = cleanedFacitAnswer.some((facit) =>
          superEvaluation(
            cleaneduserAnswer,
            facit,
            unitFormat,
            numericFormat,
            simplestFormat,
            setBadAnswerFormatCallback,
            unitRequired
          )
        );
        if (isCorrect) {
            badAnswerFormat && setBadAnswerFormatCallback('');
        }
        return isCorrect;
    }

    // Intervall with @
    const intervalRegex = /@/g;
    if (!!intervalRegex.exec(facitAnswer)) {
        if (numericFormat) {
            if (!checkNumericFormat(userAnswer, facitAnswer)) {
                return false;
            }
        }
        const intervalArray = facitAnswer.split('@');
        const cleanedintervalArray = intervalArray.map((answer) => cleanAnswers(answer));
        const intervalExpression = `${cleanedintervalArray[0]}<=${cleaneduserAnswer}<=${cleanedintervalArray[1]}`;
        try {
            isCorrect = mathjs.evaluate(intervalExpression);
        } catch (err) {
            isCorrect = false;
        }
        if (isCorrect) {
            badAnswerFormat && setBadAnswerFormatCallback('');
        }
        return isCorrect;
    }

    const cleanedfacitAnswer = cleanAnswers(facitAnswer);
    // Checks answer if smartev is off
    if (!smartEvaluation) {
        isCorrect = dumbEvaluation(cleaneduserAnswer, cleanedfacitAnswer);
        if (isCorrect) {
            badAnswerFormat && setBadAnswerFormatCallback('');
        }
        return isCorrect;
    }
    // Checks answer if smartev is on
    isCorrect = superEvaluation(
      cleaneduserAnswer,
      cleanedfacitAnswer,
      unitFormat,
      numericFormat,
      simplestFormat,
      setBadAnswerFormatCallback,
      unitRequired
    );
    if (isCorrect) {
        badAnswerFormat && setBadAnswerFormatCallback('');
    }
    return isCorrect;
};
