import { PracticeCategory, PracticeDrawingHistory, PracticeTheory, SendPracticeAnswerProps } from './types.practice'

import { apiManager } from './apiManager'
import { PracticeProblem } from '../features/Problem/types'
import { PracticeTree } from '../features/PracticeMode/types'

export type CreateNewSkillAttemptProps = {
  skillId: string
  data: {
    functionName: string
    problemCount: number
  }
}

export class practiceApi {
  public static readonly sendAnswer = apiManager.post<void, SendPracticeAnswerProps>(({ id, body }) => ({
    url: `led/solutions/${id}`,
    body,
  }))
  public static readonly getDrawing = apiManager.get<PracticeDrawingHistory, string>('led/drawing-history')
  public static readonly theory = apiManager.get<PracticeTheory, string>(
    (abilityId) => `led/abilities/${abilityId}/theory`
  )
  public static readonly createNewAttempt = apiManager.post<CreateNewAttemptResponse, string>(
    (abilityId) => `led/abilities/${abilityId}/attempts`
  )
  public static readonly getAbilityStatistic = apiManager.get<GetAbilityStatisticResponse, string>(
    'statistics/abilities'
  )
  public static readonly getBestCategory = apiManager.get<GetBestCategoryResponse, string>(
    (id) => `/statistics/categories/${id}/best`
  )
  public static readonly categories = apiManager.get<GetCategoriesResponse>('led/categories')
  public static readonly sendDrawingHistory = apiManager.post<SendDrawingHistoryResponse, string>((history) => {
    return {
      url: 'led/drawing-history',
      body: { history },
    }
  })
  public static readonly getPracticeTreeStatistic =
    apiManager.get<GetPracticeTreeStatisticResponse>('statistics/led/best')
  public static readonly getPracticeTree = apiManager.get<PracticeTree, string>(
    (treeId) => `/tree-nodes/${treeId}/tree`
  )
  public static readonly createNewSkillAttempt = apiManager.post<
    CreateNewSkillAttemptResponse,
    CreateNewSkillAttemptProps
  >(({ skillId, data }) => ({
    url: `led/skills/${skillId}/attempts`,
    body: data,
  }))
  public static readonly skillTheory = apiManager.get<PracticeTheory, string>(
    (theoryId) => `led/solutions/theory/${theoryId}`
  )
}

type SendDrawingHistoryResponse = any
type GetCategoriesResponse = PracticeCategory[]
type GetBestCategoryResponse = {
  [exerciseId: string]: number
}
type GetAbilityStatisticResponse = any

type CreateNewAttemptResponse = {
  problems: PracticeProblem[]
  _id: string
}

type CreateNewSkillAttemptResponse = {
  data: {
    problems: PracticeProblem[]
    _id: string
  }
}

type GetPracticeTreeStatisticResponse = {
  [exerciseId: string]: {
    correct: number
    notAnswered: number
    wrong: number
  }
}
