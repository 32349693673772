import createDataContext from './createDataContext'
import {
  ENABLE_TEXT_TO_SPEECH,
  DISABLE_TEXT_TO_SPEECH,
  CHANGE_TRANSLATION_LANGUAGE,
  CHANGE_TEXT_SIZE,
  SET_USER_SETTINGS,
  SET_USER_CANVAS_TYPE,
} from '../constants/actionTypes'
import { NO_LANGUAGE_SELECTED, DEFAULT_USER_SETTINGS_CANVAS_TYPE } from '../constants/defaults'
import { DEFAULT_SETTINGS_FONT_SIZE } from '../constants/styleConstants'
import { getUserGrade } from '../helpers/users/getUserInfo'
import { USER_DATA } from '../config/localStorageKeys'

const initialState = {
  textToSpeech: false,
  translationLanguage: NO_LANGUAGE_SELECTED,
  textSize: DEFAULT_SETTINGS_FONT_SIZE,
  canvasType: DEFAULT_USER_SETTINGS_CANVAS_TYPE,
  chat: false,
  practiceMode: undefined,
  practiceModeLockedGrades: [],
  grade: getUserGrade(),
  practiceModeTreeId: null,
}

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_TEXT_TO_SPEECH:
      return { ...state, textToSpeech: true }
    case DISABLE_TEXT_TO_SPEECH:
      return { ...state, textToSpeech: false }
    case CHANGE_TRANSLATION_LANGUAGE:
      return {
        ...state,
        translationLanguage: action.payload.translationLanguage,
      }
    case CHANGE_TEXT_SIZE:
      return { ...state, textSize: action.payload.textSize }
    case SET_USER_SETTINGS:
      return {
        ...state,
        translationLanguage: action.payload.translationLanguage,
        textSize: action.payload.textSize,
        textToSpeech: action.payload.textToSpeech,
        canvasType: action.payload.canvasType,
        chat: action.payload.chat,
        practiceMode: action.payload.practiceMode,
        practiceModeLockedGrades: action.payload.practiceModeLockedGrades,
        grade: action.payload.grade,
        practiceModeTreeId: action.payload.practiceModeTreeId,
      }
    case SET_USER_CANVAS_TYPE:
      return {
        ...state,
        canvasType: action.payload.canvasType,
      }
    default:
      return state
  }
}

const enableTextToSpeech = (dispatch) => {
  return () => {
    dispatch({ type: ENABLE_TEXT_TO_SPEECH })
  }
}

const disableTextToSpeech = (dispatch) => {
  return () => {
    dispatch({ type: DISABLE_TEXT_TO_SPEECH })
  }
}

const changeTranslationLanguage = (dispatch) => {
  return (translationLanguage) => {
    dispatch({
      type: CHANGE_TRANSLATION_LANGUAGE,
      payload: { translationLanguage },
    })
  }
}

const changeTextSize = (dispatch) => {
  return (textSize) => {
    dispatch({ type: CHANGE_TEXT_SIZE, payload: { textSize } })
  }
}

const setUserCanvasType = (dispatch) => {
  return (canvasType) => {
    dispatch({ type: SET_USER_CANVAS_TYPE, payload: { canvasType } })
  }
}

const setSettings = (dispatch) => {
  return ({
    translationLanguage,
    textToSpeech,
    textSize,
    canvasType,
    chat,
    practiceMode,
    practiceModeLockedGrades = [],
    grade = getUserGrade(),
    practiceModeTreeId = '',
  }) => {
    const userData = JSON.parse(localStorage.getItem(USER_DATA))
    if (userData) {
      userData.grade = grade
      localStorage.setItem(USER_DATA, JSON.stringify(userData))
    }

    dispatch({
      type: SET_USER_SETTINGS,
      payload: {
        translationLanguage: translationLanguage || NO_LANGUAGE_SELECTED,
        textToSpeech: textToSpeech || false,
        textSize: textSize || DEFAULT_SETTINGS_FONT_SIZE,
        canvasType: canvasType || DEFAULT_USER_SETTINGS_CANVAS_TYPE,
        chat: chat || false,
        practiceMode: practiceMode ?? true,
        practiceModeLockedGrades,
        grade,
        practiceModeTreeId,
      },
    })
  }
}

export const { Context, Provider } = createDataContext(
  userSettingsReducer,
  {
    enableTextToSpeech,
    disableTextToSpeech,
    changeTranslationLanguage,
    changeTextSize,
    setUserCanvasType,
    setSettings,
  },
  initialState
)
