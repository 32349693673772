import { MyScriptMathCharacterTypes } from '../../../constants/myScriptMathCharacterTypes'

type restrictOnChangeByInputModeTypeProps = {
  onChangeCallback?: (sign: string) => void
  value: string
  inputModeType: MyScriptMathCharacterTypes
}

export function restrictOnChangeByInputModeType({
  onChangeCallback,
  value,
  inputModeType,
}: restrictOnChangeByInputModeTypeProps) {
  if (!value) return

  const inputModeTypeMap = {
    [MyScriptMathCharacterTypes.DEFAULT]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS_AND_X]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS_AND_MULTIPLICATION]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS_AND_UNITS]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.DEFAULT_MATH]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS_WHOLE]: () => {
      const digitsRegex = /\D/g
      const containsAnyNonDigits = digitsRegex.test(value)

      if (containsAnyNonDigits) return
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.NUMBERS_RATIONAL]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.OPERATORS]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.PERCENT]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.UNITS]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.TIME]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.CURRENCY]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.GEOM_ANGLES]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.GEOM_COORDINATE]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.ALGEBRA_BASIC]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.ALGEBRA_ADVANCED]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.ALGEBRA_GREEK]: () => {
      return onChangeCallback?.(value)
    },
    [MyScriptMathCharacterTypes.GENERAL_MATH]: () => {
      return onChangeCallback?.(value)
    },
  }

  return inputModeTypeMap[inputModeType] ? inputModeTypeMap[inputModeType]?.() : onChangeCallback?.(value)
}
