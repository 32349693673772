import { restore, combine, createEvent, sample } from 'effector'
import { practiceSkillsModel } from './skillsTreeModel'
import { getFirstUnsolvedSkill, getPreviousActiveBlockIds, getSolvedBlockPercent } from '../skillsTree.helpers'
import { activeSubCategoryModel } from '../../PracticeMode/models/activeSubCategory'
import { practiceModeModel } from '../../PracticeMode/models'

export class inViewSectionModel {
  static readonly setIntersectedTreeElementId = createEvent<string>()
  static readonly $intersectedTreeElementId = restore(this.setIntersectedTreeElementId, null)
  static readonly $intersectedDomain = combine(
    [practiceSkillsModel.$domains, this.$intersectedTreeElementId],
    ([domains, intersectedElementId]) => domains.find((domain) => domain._id === intersectedElementId)
  )

  static readonly setIntersectedStandardId = createEvent<string>()
  static readonly $intersectedStandardId = restore(this.setIntersectedStandardId, null)
  static readonly $intersectedStandard = combine(
    [this.$intersectedDomain, this.$intersectedStandardId],
    ([domain, standardId]) => domain?.children?.find((standard) => standard._id === standardId)
  )

  static readonly $activeStandardTitle = this.$intersectedStandard.map((standard) => standard?.nameTranslations)
}

practiceSkillsModel.$gradeSubtree.updates.watch((gradeSubtree) => {
  if (!gradeSubtree) return

  const { mainBlock, currentBlockId } = getPreviousActiveBlockIds(gradeSubtree)
  inViewSectionModel.setIntersectedTreeElementId(mainBlock._id)

  activeSubCategoryModel.setId(currentBlockId)
  practiceModeModel.firstUnsolvedCardId.setId(
    getFirstUnsolvedSkill(gradeSubtree, practiceSkillsModel.$statistics.getState())?._id || ''
  )
})

sample({
  source: { statistics: practiceSkillsModel.$statistics, intersectedStandard: inViewSectionModel.$intersectedStandard },
  clock: inViewSectionModel.$intersectedStandard,
  fn: ({ statistics, intersectedStandard }) => {
    if (!intersectedStandard) return 0
    const calculatedProgress = getSolvedBlockPercent([intersectedStandard], statistics)
    return calculatedProgress ?? 0
  },
  target: practiceModeModel.currentSubChapterSolvedPercent.setData,
})
