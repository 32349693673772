import { createEvent, restore, sample } from 'effector'
import { $assignmentId, $exerciseId } from '../assignment/model/credentials.model'
import { getUserGrade } from '../../helpers/users/getUserInfo'
import { MyScriptMathCharacterTypes } from '../../constants/myScriptMathCharacterTypes'
import { canAccessSimpleMode } from '../simpleMode/helpers'
import { AnswerType } from '../Problem/types.answer'
import { assignmentModel } from '../assignment/model/assignment.model'

export enum ToolsMode {
  REGULAR = 'regular',
  SIMPLE = 'simple',
}

export const setToolsMode = createEvent<ToolsMode>()
export const $toolsMode = restore(setToolsMode, ToolsMode.REGULAR)

export const setStudentGrade = createEvent<number>()
const $studentGrade = restore(setStudentGrade, null)

sample({
  source: {
    currentAssignment: assignmentModel.$assignment,
    assignmentId: $assignmentId,
    exerciseId: $exerciseId,
    studentGrade: $studentGrade,
  },
  clock: [$assignmentId, $exerciseId, $studentGrade],
  fn: ({ currentAssignment, assignmentId, exerciseId, studentGrade }) => {
    if (currentAssignment?._id !== assignmentId) return ToolsMode.REGULAR

    const currentProblem = currentAssignment.problems.find(({ _id }) => _id === exerciseId)
    if (!currentProblem) return ToolsMode.REGULAR

    const classGrade = currentAssignment.classes?.[0]?.grade

    const isSimpleModeAccessible = canAccessSimpleMode(studentGrade, classGrade)
    const isNumbersWhole = currentProblem.characterType === MyScriptMathCharacterTypes.NUMBERS_WHOLE

    if (currentProblem.answerType === AnswerType.GEOGEBRA) return ToolsMode.REGULAR
    if (currentProblem.answerType === AnswerType.HANDWRITING && isNumbersWhole && isSimpleModeAccessible) {
      return ToolsMode.SIMPLE
    }

    const choiceAnswerTypes = [AnswerType.SINGLE_CHOICE, AnswerType.MULTIPLE_CHOICE, AnswerType.ORDERED_CHOICE]
    if (choiceAnswerTypes.includes(currentProblem.answerType) && isSimpleModeAccessible) {
      return ToolsMode.SIMPLE
    }

    return ToolsMode.REGULAR
  },
  target: setToolsMode,
})
