import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const StarRoundedIcon: React.FC<IconProps> = ({ size = 25, fill = COLORS.NEUTRAL_4 }) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.215 1.35083C13.4382 0.0561541 11.5619 0.0561504 10.7851 1.35083L7.53568 6.76649L1.97098 8.15766C0.462807 8.53471 -0.0574265 10.4129 1.04184 11.5122L5.16991 15.6402L3.80115 22.1418C3.45189 23.8008 5.1957 25.1147 6.69403 24.3214L12.4886 21.2537L16.3534 23.4009L18.3604 24.4044C19.8574 25.1529 21.5567 23.8413 21.2119 22.2035L19.8302 15.6402L23.9583 11.5122C25.0575 10.4129 24.5373 8.53471 23.0291 8.15766L17.4644 6.76649L14.215 1.35083ZM9.25066 7.79548L12.5001 2.37982L15.7495 7.79548C16.0224 8.25032 16.4648 8.57813 16.9794 8.70678L22.5441 10.0979L18.416 14.226C17.9381 14.7039 17.7339 15.3909 17.8731 16.0523L19.2548 22.6155L17.2867 21.6314L13.4599 19.5054C12.8684 19.1768 12.1508 19.1696 11.5528 19.4862L5.75825 22.5539L7.12701 16.0523C7.26625 15.3909 7.06204 14.7039 6.58413 14.226L2.45605 10.0979L8.02075 8.70678C8.53534 8.57813 8.97776 8.25032 9.25066 7.79548Z'
        fill={fill}
      />
    </svg>
  )
}

export default StarRoundedIcon
