import { ChangeGradeActions } from '../types'

type ChangeYearProps = {
  type: ChangeGradeActions
  currentGrade: number
  filteredGrades: number[]
}

type ChangedYearProps = ChangeYearProps & {
  newGrade: number
}

export const getIsChangeYearDisabled = ({ type, currentGrade, filteredGrades }: ChangeYearProps) => {
  if (!filteredGrades.length) {
    return true
  }

  if (type === ChangeGradeActions.DECREASE) {
    return currentGrade <= filteredGrades[0]
  }

  return currentGrade >= filteredGrades[filteredGrades.length - 1]
}

export const getChangedGrade = ({ type, currentGrade, newGrade, filteredGrades }: ChangedYearProps) => {
  let tempGrade = newGrade
  while (!filteredGrades.includes(tempGrade) && !getIsChangeYearDisabled({ type, currentGrade, filteredGrades }))
    tempGrade = type === ChangeGradeActions.DECREASE ? tempGrade - 1 : tempGrade + 1

  return tempGrade
}
