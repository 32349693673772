import React, { memo } from 'react'
import clsx from 'clsx'

const LanguageListItem = memo(({ isActive, language, onLanguageSelect }) => {
  return (
    <li className={clsx('native-language-list-item', { active: isActive })} onClick={() => onLanguageSelect(language)}>
      {language}
    </li>
  )
})

export default LanguageListItem
