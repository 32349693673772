import React from 'react'
import { COLORS } from '@magmamath/ui'
import type { IconProps } from './_types'

const ArrowControlRight = ({ size = 11, color = COLORS.NEUTRAL_9 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 7 12' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.381281 0.381282C0.0395727 0.72299 0.0395727 1.27701 0.381281 1.61872L4.76256 6L0.381281 10.3813C0.0395727 10.723 0.0395727 11.277 0.381281 11.6187C0.722991 11.9604 1.27701 11.9604 1.61872 11.6187L6.61872 6.61872C6.96043 6.27701 6.96043 5.72299 6.61872 5.38128L1.61872 0.381282C1.27701 0.0395728 0.722991 0.0395728 0.381281 0.381282Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowControlRight
