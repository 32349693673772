import React from 'react'
import { IconButton, IconButtonProps } from '../../ui/IconButton'
import { useFullscreenMode } from './hook.fullScreen'
import { ExitFullscreenIcon } from '../../ui/icons/ExitFullscreen'
import { FullscreenIcon } from '../../ui/icons/Fullscreen'

type FullscreenModeButtonProps = Omit<IconButtonProps, 'variant' | 'onClick'>

export const FullscreenModeButton = (props: FullscreenModeButtonProps) => {
  const { isFullscreen, toggleFullscreenMode } = useFullscreenMode()

  return (
    <IconButton variant='outlined' onClick={toggleFullscreenMode} {...props}>
      {isFullscreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
    </IconButton>
  )
}
