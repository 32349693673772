import { RefObject, useEffect } from 'react'

/**
 * Custom hook to clean up specific child nodes in a contentEditable element.
 * Removes child nodes that are <span> tags with a font size of 18px, vertical alignment of 'super',
 * and inner HTML of '&nbsp;'.
 *
 * @param {RefObject<HTMLElement>} contentEditableRef - React ref object pointing to the contentEditable element.
 */
export const useCleanUpContentEditable = (contentEditableRef: RefObject<HTMLElement>) => {
  const handleMutation = (mutations: MutationRecord[]) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        Array.from(mutation.target.childNodes).forEach((childNode: any) => {
          if (
            childNode.tagName === 'SPAN' &&
            childNode.style.fontSize === '18px' &&
            childNode.style.verticalAlign === 'super' &&
            childNode.innerHTML === '&nbsp;'
          ) {
            mutation.target.removeChild(childNode)
          }
        })
      }
    })
  }

  useEffect(() => {
    if (!contentEditableRef.current) return

    const observer = new MutationObserver(handleMutation)

    observer.observe(contentEditableRef.current, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [contentEditableRef])
}
