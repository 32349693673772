import React from 'react'

import styles from './styles.module.scss'

import PracticeNavigationContentBlocks from '../PracticeNavigationContentBlocks/PracticeNavigationContentBlocks'
import { PracticeTree } from '../../../types'

type PracticeNavigationMainBlockProps = {
  domain: PracticeTree
  openedCategoryId: string | null
  onClick: (id: string) => void
}

const PracticeNavigationDomain = ({ domain, openedCategoryId, onClick }: PracticeNavigationMainBlockProps) => {
  return (
    <div className={styles.CategoriesListItemContainer}>
      <li
        className={styles.CategoriesListItem}
        onClick={() => onClick(openedCategoryId === domain._id ? '' : domain._id)}
      >
        <a href={`#${domain._id}`} className={styles.CategoryTitle}>
          {domain.nameTranslations}
        </a>
      </li>
      <PracticeNavigationContentBlocks practiceTree={domain.children} isOpened={openedCategoryId === domain._id} />
    </div>
  )
}

export default PracticeNavigationDomain
