import React from 'react'

export const EMPTY_HTML_ELEMENT = "<span class='empty-element'></span>"

export const FRACTION_HTML_ELEMENT =
  "<div class='fraction'><span placeholder='a' tabindex='0' inputmode='none' class='fraction-item numerator'></span><span placeholder='b' tabindex='0' inputmode='none' class='fraction-item denominator'></span></div>"
export const FRACTION_FIRST_PART =
  "<div class='fraction'><span placeholder='a' tabindex='0' inputmode='none' class='fraction-item numerator'>"
export const FRACTION_SECOND_PART =
  "</span><span placeholder='b' tabindex='0' inputmode='none' class='fraction-item denominator'></span></div>"
export const POWER_HTML_ELEMENT = "<sup class='a-pow-b'>&nbsp;</sup>"
export const POWER_SQUARE_HTML_ELEMENT = '<sup>2</sup>'
export const POWER_CUBE_HTML_ELEMENT = '<sup>3</sup>'
export const LESS_THAN_ELEMENT = '<'
export const GREATER_THAN_ELEMENT = '>'

export const TEXT_NODE_TYPE = 3
export const TEXT_AREA_NODE = 'TEXTAREA'

export const LEFT_KEY = 37
export const UP_KEY = 38
export const RIGHT_KEY = 39
export const DOWN_KEY = 40
export const BACKSPACE_KEY = 8

export const LIGHT_BULB = <span role='img'>&#128161;</span>
