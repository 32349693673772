import React from 'react'

import { IconProps } from './_types'
import { COLORS } from '@magmamath/ui'

const UndoIcon: React.FC<IconProps> = ({ size = 25, color = COLORS.NEUTRAL_10 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.63622 24.1504C4.63622 24.1504 4.7347 24.1504 15.2673 24.1504C25.8 24.1504 25.3527 6.3504 15.2673 6.3504C5.18195 6.3504 1.23551 6.3504 1.23551 6.3504'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
      />
      <path
        d='M0.645581 6.9373C0.321445 6.61317 0.321445 6.08764 0.645581 5.7635L5.92767 0.481417C6.2518 0.157281 6.77733 0.157281 7.10147 0.481417C7.4256 0.805552 7.4256 1.33108 7.10147 1.65521L2.40628 6.3504L7.10147 11.0456C7.4256 11.3697 7.4256 11.8953 7.10147 12.2194C6.77733 12.5435 6.2518 12.5435 5.92767 12.2194L0.645581 6.9373ZM8.91406 7.1804H1.23248V5.5204H8.91406V7.1804Z'
        fill={color}
      />
    </svg>
  )
}

export default UndoIcon
