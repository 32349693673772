import React from 'react'
import styles from '../styles.module.scss'

type DialogTitleProps = {
  text: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

export const DialogTitle = ({ text, ...restProps }: DialogTitleProps) => {
  return (
    <h2 id='modal-title' className={styles.ModalTitle} {...restProps}>
      {text}
    </h2>
  )
}
