import { useEffect, useState } from 'react'
import { getProblemTheory } from '../../../assignments/requests'

export const useGetTheory = (problemId: string) => {
  const [problemTheory, setProblemTheory] = useState<any>([])

  useEffect(() => {
    if (!problemId) return

    const getTheory = async () => {
      try {
        const response = await getProblemTheory(problemId)
        if (!response || (!response.sectionTheory && !response.problemTheory)) {
          return
        }

        setProblemTheory([...response.problemTheory, ...response.sectionTheory])
      } catch (error) {
        setProblemTheory([])
      }
    }

    getTheory()
  }, [problemId])

  return { problemTheory }
}
