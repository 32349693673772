import React from 'react'
import { PracticeHandwritingProps } from '../types'
import { StyleSheet, View } from 'react-native'
import { InputType } from '../../../../../Problem/models/inputType'
import { SimpleModeDigitsNumpad } from '../../../../../simpleMode/components/SimpleModeDigitsNumpad/SimpleModeDigitsNumpad'
import MyScriptView from '../../../../../MyScript/MyScriptView'
import stylesScss from '../../../../../simpleMode/components/HandwritingAnswerSimpleMode/styles.module.scss'
import { ProblemAnswerInput } from '../../../../../../components/Problem/ProblemAnswer/ProblemAnswerInput'
import { backspaceClick, onTabPress } from '../../../../../Problem/problem.answer.helpers'
import { SimpleModeToolAction } from '../../../../../simpleMode/components/SimpleModeToolAction/SimpleModeToolAction'
import ProblemAnswerButton from '../../../../../Problem/ProblemAnswerButton/ProblemAnswerButton'
import { getUserType } from '../../../../../../helpers/users/getUserInfo'
import { COLORS } from '@magmamath/ui'
import { useTranslation } from 'react-i18next'
import { ProblemAnswerStatus } from '../../../../../Problem/models/answer'
import AnswerFeedback from '../../../../../../ui/feedback/AnswerFeedback'
import { clearEditor } from '../../../../../MyScript/model'

export const PracticeHandwritingSimple = ({
  inputType,
  answerStatus,
  currentProblem,
  onSubmitClick,
  onAnswerChangeStart,
  setIsRecognitionPending,
  onNumpadButtonPress,
  onContentEditableChange,
  onFocusInput,
  exerciseMode,
  answerStyle,
  answerText,
  feedbackText,
  submitButtonText,
  isRecognitionPending,
}: PracticeHandwritingProps) => {
  const { t } = useTranslation()
  const isSuccessAnswer =
    answerStatus === ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT || answerStatus === ProblemAnswerStatus.SUCCESS
  const isMistakeAnswerStatus = answerStatus === ProblemAnswerStatus.MISTAKE
  const isSubmitButtonDisabled = !answerText || isRecognitionPending

  return (
    <View style={styles.container}>
      <View style={styles.input}>
        {inputType.value === InputType.KEYBOARD && !isSuccessAnswer ? (
          <View style={styles.numpad}>
            <SimpleModeDigitsNumpad onDigitPress={onNumpadButtonPress} />
          </View>
        ) : (
          <MyScriptView
            showForceDrawing={false}
            onRecognition={setIsRecognitionPending}
            characterType={currentProblem?.characterType}
            isDisabled={!!answerStatus}
            emptyDrawingProps={{
              message: t('answerWithNumbers'),
              className: stylesScss.SimpleModeEmptyDrawing,
            }}
            onPressIn={onAnswerChangeStart}
          />
        )}
      </View>

      <View style={styles.actions}>
        <ProblemAnswerInput
          characterType={currentProblem?.characterType}
          exerciseMode={exerciseMode}
          inputType={inputType.value}
          isDisabled={!!answerStatus}
          isPracticeMode
          isUnitRequired={false}
          onBackspacePress={backspaceClick}
          onChange={onContentEditableChange}
          onFocus={onFocusInput}
          onTabPress={onTabPress}
          showAnswerPreview={false}
          showAnswerPreviewExamMode={false}
          styleVariant={answerStyle}
          value={answerText}
          showLabel={false}
          showBackspaceButton={false}
          classNames={{
            root: stylesScss.SimpleModeAnswerRoot,
            container: stylesScss.SimpleModeAnswerContainer,
            input: stylesScss.SimpleModeAnswerInput,
            answerPopup: stylesScss.SimpleModeAnswerPopup,
          }}
        />

        {!answerStatus && (
          <SimpleModeToolAction
            isMistakeAnswerStatus={isMistakeAnswerStatus}
            isExamMode={false}
            showClearButton={!isSubmitButtonDisabled || !!answerText}
          />
        )}

        <View style={styles.submit}>
          {!!answerStatus && (
            <AnswerFeedback text={feedbackText} style={answerStyle} className={stylesScss.AnswerFeedbackText} />
          )}

          <ProblemAnswerButton
            onClick={() => {
              onSubmitClick()
              clearEditor()
            }}
            text={submitButtonText}
            style={answerStyle}
            exerciseMode={exerciseMode}
            disabled={isSubmitButtonDisabled}
            userType={getUserType()}
            classNames={{
              button: stylesScss.SimpleModeButton,
              content: stylesScss.SimpleModeButtonContent,
            }}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    height: 170,
    borderTopWidth: 2,
    borderTopColor: COLORS.NEUTRAL_5,
    backgroundColor: COLORS.NEUTRAL_1,
    zIndex: 1,
    minWidth: 'auto',
  },
  input: {
    flexShrink: 1,
    width: '100%',
    alignItems: 'flex-end',
    minWidth: 'auto',
  },
  numpad: {
    paddingVertical: 8,
    minWidth: 'auto',
  },
  actions: {
    paddingVertical: 8,
    paddingRight: 16,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flexShrink: 1,
    height: '100%',
    width: 'auto',
    minWidth: 'auto',
  },
  submit: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    minWidth: 'auto',
  },
})
