import { RefObject, useLayoutEffect } from 'react'

import { useStoreMap, useUnit } from 'effector-react'
import { $practiceGrade } from '../../PracticeMode/models/currentPracticeGrade'
import { practiceSkillsModel } from '../model/skillsTreeModel'
import { activeSubCategoryModel } from '../../PracticeMode/models/activeSubCategory'
import { inViewSectionModel } from '../model/inViewSectionModel'
import { getVisibleElements } from '../../PracticeMode/practice.helpers'

const SCROLL_OFFSET_TOP = 32

interface useHighlightPracticeTreeItemsOnScrollParams {
  scrollArea: RefObject<HTMLDivElement>
}

const useHighlightPracticeTreeItemsOnScroll = ({ scrollArea }: useHighlightPracticeTreeItemsOnScrollParams): void => {
  const practiceGrade = useUnit($practiceGrade)
  const categories = useStoreMap(practiceSkillsModel.$gradeSubtree, (gradeSubtree) => gradeSubtree?.children || [])

  useLayoutEffect(() => {
    const scrollContainer = scrollArea.current
    if (!scrollContainer) return

    if (scrollContainer.scrollTop > 0) {
      scrollContainer.scroll(0, 0)
      return
    }

    const gradeSubtree = scrollContainer.children?.[0]
    const gradeSubtreeNodesList = gradeSubtree?.children[1]

    inViewSectionModel.setIntersectedTreeElementId(gradeSubtreeNodesList?.id || '')
    if (!gradeSubtreeNodesList) {
      return
    }

    const firstGradeSubtreeNode = gradeSubtreeNodesList?.children?.[0]
    activeSubCategoryModel.setId(firstGradeSubtreeNode?.id || '')

    inViewSectionModel.setIntersectedStandardId(firstGradeSubtreeNode?.id)
  }, [categories, practiceGrade])

  useLayoutEffect(() => {
    const ref = scrollArea.current

    const onScroll = () => {
      if (!ref) return

      const { offsetHeight, offsetTop } = ref
      const visibleDomains: Element[] = getVisibleElements(
        Array.from(ref.children) as Element[],
        offsetTop,
        offsetHeight
      )

      const firstVisibleDomain = visibleDomains[0]
      const standardNodesList = firstVisibleDomain?.children?.[1]
      inViewSectionModel.setIntersectedTreeElementId(standardNodesList?.id || '')

      if (!firstVisibleDomain) return
      const standardNodes = getVisibleElements(
        Array.from(standardNodesList.children) as Element[],
        offsetTop + SCROLL_OFFSET_TOP,
        offsetHeight
      )

      activeSubCategoryModel.setId(standardNodes[0]?.id || '')
      inViewSectionModel.setIntersectedStandardId(standardNodes[0]?.id)
    }

    ref?.addEventListener('scroll', onScroll)

    return () => {
      ref?.removeEventListener('scroll', onScroll)
    }
  }, [scrollArea, practiceGrade])
}

export default useHighlightPracticeTreeItemsOnScroll
