export const CLEAR_ALL = 'CLEAR_ALL'
export const FRACTION = 'FRACTION'
export const POWER = 'POWER'
export const POWER_SQUARE = 'POWER_SQUARE'
export const DEGREE = 'DEGREE'

export const POWER_CUBE = 'POWER_CUBE'
export const BACKSPACE = 'BACKSPACE'
export const ONE = 'ONE'
export const TEN = 'TEN'
export const STRIKETHROUGH = 'STRIKETHROUGH'
export const ONE_FOOT_NOTE = 'ONE_FOOT_NOTE'

export const COLON = 'COLON'
export const DOT = 'DOT'
