import pixelWidth from 'string-pixel-width'
import keyBy from 'lodash/keyBy'

import { getStudentStats } from '../users/getUserStudyInfo'
import { HANDWRITING, MULTIPLE_CHOICE, ORDERED, SINGLE_CHOICE } from '../../constants/answerTypes'
import { DEFAULT_DRAWING } from '../../constants/drawingArea'
import { PREVIOUS_DRAWING } from '../../config/localStorageKeys'
import { DEFAULT_FONT } from '../../constants/defaults'
import { RESIZE_FONT_BORDER } from '../../constants/styleConstants'
import { getUserId, getUserType } from '../users/getUserInfo'
import { TEACHER } from '../../constants/userTypes'
import { defaultStats } from '../../constants/studentStats'
import { useChoiceAnswersModel } from '../../components/Problem/ProblemAnswerChoice/model'
import { cleanLatexAnswer } from '../myScript/cleanLatexAnswer'
import { currentProblemAnswerModel } from '../../features/Problem/models/answer'

export const getExercisesAttemptsData = (exercise) => {
  if (!exercise?.problems?.length || !Object.keys(exercise).length) {
    return {}
  }

  const exercisesAttempts = {}

  exercise.problems.forEach((problem) => {
    if (!isProblemContainsSubProblems(problem)) {
      setAttemptData(exercisesAttempts, problem)
    } else {
      setSubProblemsAttemptData(exercisesAttempts, problem)
    }
  })

  return exercisesAttempts
}

export const makeAttempt = ({
  exercisesAttempts,
  problemId,
  subProblemId,
  answer,
  solved,
  backgroundImage,
  screenshot,
}) => {
  const attemptTarget = subProblemId ? exercisesAttempts[problemId][subProblemId] : exercisesAttempts[problemId]

  applyAttempt({ target: attemptTarget, answer, solved, backgroundImage, screenshot })
  return exercisesAttempts
}

export const getCurrentAttempt = (exercisesAttempts, problemId, subProblemId) => {
  if (!subProblemId) {
    return exercisesAttempts[problemId].attempt
  }

  return exercisesAttempts[problemId][subProblemId].attempt
}

export const getUserAnswer = (answerType) => {
  const { singleChoiceAnswer, selectedAnswers, selectedOrderedAnswers } = useChoiceAnswersModel.getState()
  const answerText = currentProblemAnswerModel.$text.getState()

  switch (answerType) {
    case SINGLE_CHOICE:
      return [singleChoiceAnswer?.variant ?? '']
    case MULTIPLE_CHOICE:
      return selectedAnswers.map((answer) => answer.variant)
    case ORDERED:
      return Array.from(selectedOrderedAnswers.values())
    case HANDWRITING:
      return cleanLatexAnswer(answerText)
    default:
      return ''
  }
}

export const getLastAnswer = (exercisesAttempts, problemId, subProblemId) => {
  if (!Object.keys(exercisesAttempts).length) {
    return ''
  }

  if (!subProblemId) {
    return exercisesAttempts[problemId]?.answer
  }

  return exercisesAttempts[problemId][subProblemId]?.answer
}

export const checkIsSolved = (attemptInfo, problemId, subProblemId) => {
  if (!problemId) {
    return false
  }

  if (!subProblemId) {
    return attemptInfo[problemId].solved
  }

  return attemptInfo[problemId][subProblemId].solved
}

const isProblemContainsSubProblems = (problem) => {
  return problem.subProblems.length
}

const setAttemptData = (targetObject, problem) => {
  const studentStats = getStudentStats(problem)

  if (studentStats) {
    if (problem.answerType === HANDWRITING || problem.answerType === SINGLE_CHOICE) {
      studentStats.stats.lastAnswer = studentStats.stats.lastAnswer ? studentStats.stats.lastAnswer[0] : ''
    }

    const answers = studentStats.stats?.answerArray
    const screenshot = answers?.length ? answers[answers.length - 1].drawing || '' : ''

    const obj = {
      [problem._id]: {
        attempt: studentStats.stats.attempt,
        answer: studentStats.stats.lastAnswer,
        solved: studentStats.stats.solved,
        background: studentStats.stats?.background?.url,
        screenshot,
      },
    }

    Object.assign(targetObject, obj)

    return
  }

  const obj = {
    [problem._id]: {
      attempt: 0,
      answer: '',
      solved: 0,
      background: '',
    },
  }

  Object.assign(targetObject, obj)
}

const setSubProblemsAttemptData = (targetObject, problem) => {
  const subProblemAttempts = {
    [problem._id]: {},
  }
  problem.subProblems.forEach((subproblem) => {
    setAttemptData(subProblemAttempts[problem._id], subproblem)
  })

  Object.assign(targetObject, subProblemAttempts)
}

const applyAttempt = ({ target, answer, solved, backgroundImage, screenshot }) => {
  target.attempt++
  target.answer = answer
  target.solved = solved
  target.background = backgroundImage
  target.screenshot = screenshot
}

const setPropertiesToObject = (object, properties) => {
  object.drawing = properties.drawing
  object.scaleData = properties.scaleData
  object.backgroundType = properties.currentBackgroundType
  object.textAreas = properties.textAreas
}

export const saveDrawingToStorage = (
  drawing,
  exerciseId,
  problemId,
  subProblemId,
  scaleData,
  currentBackgroundType,
  textAreas
) => {
  const previousDrawingData = localStorage.getItem(PREVIOUS_DRAWING)
  if (!previousDrawingData) {
    const obj = {
      draw: {
        [exerciseId]: {
          [problemId]: {},
        },
      },
    }

    if (!subProblemId) {
      setPropertiesToObject(obj['draw'][exerciseId][problemId], {
        drawing,
        scaleData,
        currentBackgroundType,
        textAreas,
      })
    } else {
      obj['draw'][exerciseId][problemId][subProblemId] = {}
      setPropertiesToObject(obj['draw'][exerciseId][problemId][subProblemId], {
        drawing,
        scaleData,
        currentBackgroundType,
        textAreas,
      })
    }

    localStorage.setItem(PREVIOUS_DRAWING, JSON.stringify(obj))
    return
  }

  const previousDrawingObject = JSON.parse(previousDrawingData)
  if (!previousDrawingObject['draw'][exerciseId]) {
    previousDrawingObject['draw'][exerciseId] = {}
  }

  if (!previousDrawingObject['draw'][exerciseId][problemId]) {
    previousDrawingObject['draw'][exerciseId][problemId] = {}
  }

  if (!subProblemId) {
    setPropertiesToObject(previousDrawingObject['draw'][exerciseId][problemId], {
      drawing,
      scaleData,
      currentBackgroundType,
      textAreas,
    })
  } else {
    if (!previousDrawingObject['draw'][exerciseId][problemId][subProblemId]) {
      previousDrawingObject['draw'][exerciseId][problemId][subProblemId] = {}
    }

    setPropertiesToObject(previousDrawingObject['draw'][exerciseId][problemId][subProblemId], {
      drawing,
      scaleData,
      currentBackgroundType,
      textAreas,
    })
  }

  localStorage.setItem(PREVIOUS_DRAWING, JSON.stringify(previousDrawingObject))
}

export const getDrawingFromStorage = (exerciseId, problemId, subProblemId) => {
  const previousDrawingData = localStorage.getItem(PREVIOUS_DRAWING)
  if (!previousDrawingData) {
    return DEFAULT_DRAWING
  }

  const previousDrawingObject = JSON.parse(previousDrawingData)

  const previousDrawing = previousDrawingObject['draw']
  const currentExerciseDrawing = previousDrawing[exerciseId]
  if (!currentExerciseDrawing) {
    return DEFAULT_DRAWING
  }

  const currentProblemDrawing = currentExerciseDrawing[problemId]
  if (!currentProblemDrawing) {
    return DEFAULT_DRAWING
  }

  if (!subProblemId) {
    return (
      {
        drawing: currentProblemDrawing.drawing,
        scaleData: currentProblemDrawing.scaleData,
        backgroundType: currentProblemDrawing.backgroundType,
        textAreas: currentProblemDrawing.textAreas,
      } || DEFAULT_DRAWING
    )
  }

  const currentSubProblemDrawing = currentProblemDrawing[subProblemId]
  if (!currentSubProblemDrawing) {
    return DEFAULT_DRAWING
  }

  return (
    {
      drawing: currentSubProblemDrawing.drawing,
      scaleData: currentSubProblemDrawing.scaleData,
      backgroundType: currentSubProblemDrawing.backgroundType,
      textAreas: currentSubProblemDrawing.textAreas,
    } || DEFAULT_DRAWING
  )
}

export const getTextWidth = (value, fontSize, font) => {
  const settings = {
    size: fontSize,
  }
  font && (settings.font = font)

  return pixelWidth(value, settings)
}

export const checkIsTextFitsInBox = (element, value) => {
  if (!element) {
    return
  }

  const font = window.getComputedStyle(element, null).getPropertyValue('font-size')
  const fontSize = Number(font.replace('px', ''))
  const textWidth = pixelWidth(value, { size: fontSize })
  const boxWidth = element.offsetWidth
  return textWidth <= boxWidth
}

export const getDecreasedFontSize = (fontSize, text, boxWidth, font) => {
  const settings = {
    size: fontSize,
  }
  font && (settings.font = font)

  let textWidth = pixelWidth(text, settings)
  while (textWidth + RESIZE_FONT_BORDER > boxWidth) {
    fontSize -= RESIZE_FONT_BORDER
    const settings = { size: fontSize }
    font && (settings.font = font)
    textWidth = pixelWidth(text, settings)
  }

  return fontSize
}

const getIncreasedFontSize = (fontSize, text, boxWidth, defaultFontSize) => {
  let textWidth = pixelWidth(text, { size: fontSize })
  while (textWidth < boxWidth && fontSize < defaultFontSize) {
    if (pixelWidth(text, { size: fontSize + RESIZE_FONT_BORDER }) > boxWidth) {
      break
    }

    fontSize += RESIZE_FONT_BORDER
    textWidth = pixelWidth(text, { size: fontSize })
  }

  return fontSize
}

export const resizeText = ({ element, value, defaultFontSize, onlyDecrease, returnCallback }) => {
  if (!element) {
    return
  }

  const font = window.getComputedStyle(element, null).getPropertyValue('font-size')
  const fontSize = Number(font.replace('px', ''))
  const textWidth = pixelWidth(value, { size: fontSize, font: DEFAULT_FONT })
  const boxWidth = element.offsetWidth
  if (textWidth + RESIZE_FONT_BORDER > boxWidth) {
    const decreasedFontSize = `${getDecreasedFontSize(fontSize, value, boxWidth, DEFAULT_FONT)}px`
    if (returnCallback) {
      return returnCallback(decreasedFontSize)
    }

    element.style.fontSize = decreasedFontSize
  }

  if (onlyDecrease) {
    return
  }

  if (textWidth < boxWidth && fontSize < defaultFontSize) {
    const increasedFontSize = `${getIncreasedFontSize(fontSize, value, boxWidth, defaultFontSize)}px`
    if (returnCallback) {
      return returnCallback(increasedFontSize)
    }

    element.style.fontSize = increasedFontSize
  }
}

export const resizePrefilled = ({ element, value, defaultFontSize, onlyDecrease, elementsArray, checkOnReadOnly }) => {
  const returnCallback = (fontSize) => fontSize
  const fontSize = resizeText({ element, value, defaultFontSize, onlyDecrease, returnCallback })
  if (!fontSize) {
    return
  }

  elementsArray.forEach((input) => {
    if (!checkOnReadOnly) {
      input.readOnly && (input.style.fontSize = fontSize)
      return
    }

    input.style.fontSize = fontSize
  })
}

export const resizeExerciseInfoText = (boxElement, textElement, value, minFontSize, maxFontSize) => {
  if (!boxElement || !textElement) {
    return
  }

  const font = window.getComputedStyle(textElement, null).getPropertyValue('font-size')
  const padding = Number(window.getComputedStyle(boxElement, null).getPropertyValue('padding-left').replace('px', ''))
  let fontSize = Number(font.replace('px', ''))
  let textWidth = pixelWidth(value, { font: DEFAULT_FONT, size: fontSize })
  const boxWidth = boxElement.offsetWidth - padding * 2

  if (textWidth <= boxWidth) {
    textElement.style.textOverflow = 'unset'
    textElement.style.whiteSpace = 'unset'
    textElement.style.overflow = 'unset'

    if (fontSize >= maxFontSize) {
      return
    }

    while (textWidth < boxWidth) {
      fontSize += 1
      textWidth = pixelWidth(value, { font: DEFAULT_FONT, size: fontSize })
      if (fontSize >= maxFontSize) {
        textElement.style.fontSize = maxFontSize + 'px'
        return
      }
    }

    textElement.style.fontSize = fontSize + 'px'
    return
  }

  while (textWidth >= boxWidth) {
    fontSize -= 1
    textWidth = pixelWidth(value, { font: DEFAULT_FONT, size: fontSize })
    if (fontSize < minFontSize) {
      textElement.style.fontSize = minFontSize + 'px'
      textElement.style.textOverflow = 'ellipsis'
      textElement.style.whiteSpace = 'nowrap'
      textElement.style.overflow = 'hidden'
      return
    }
  }
  textElement.style.fontSize = fontSize + 'px'
}

export const checkIsExercisePublished = (exercise) => {
  return exercise?.published
}

export const getProblemsCount = (problems) => {
  if (!problems.length) {
    return 0
  }

  return problems.reduce((count, problem) => {
    return count + (problem.subProblems.length || 1)
  }, 0)
}

export const getRightSolvedSubProblemsCount = (stats) => {
  return stats?.completedAmount
}

export const getExerciseProblemsCount = (exercise) => {
  return exercise.problems.reduce((count, problem) => count + (problem.subProblems?.length || 1), 0)
}

export const mergeAssignmentStatistics = (exercise, stats) => {
  if (!exercise.problems.length) return
  const currentStudentId = getUserId()
  const isTeacher = TEACHER === getUserType()

  let studentStats = stats.students.find((student) => student.studentId === currentStudentId)

  if (!studentStats) {
    studentStats = {
      problems: [],
      subProblems: [],
    }
  }

  const problemStatsMap = keyBy(studentStats.problems, 'problemId')
  const subProblemStatsMap = keyBy(studentStats.subProblems, 'subProblemId')

  exercise.problems.forEach((problem) => {
    if (!problem.subProblems.length) {
      problem.studentStats = [
        {
          isTeacher,
          studentId: currentStudentId,
          stats: problemStatsMap[problem._id] ?? defaultStats,
        },
      ]
      return
    }

    problem.subProblems.forEach((subProblem) => {
      subProblem.studentStats = [
        {
          isTeacher,
          studentId: currentStudentId,
          stats: subProblemStatsMap[subProblem._id] ?? defaultStats,
        },
      ]
    })
  })

  return exercise
}
