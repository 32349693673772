import { createValuePersistorModel } from '../../../../lib/helpers/createValuePersistorModel'
import { useStoreMap } from 'effector-react'
import { useAssignmentCredentials } from '../../../assignment/hooks/useAssignmentCredentials'

type StoreDrawingImage = {
  assignmentId: string
  problemId: string
  data: {
    drawboardImageURL: string | null
    handwritingImageURL: string | null
  }
}

export const drawingImagesModel = createValuePersistorModel({
  mapper: ({ assignmentId, problemId, data }: StoreDrawingImage) => ({
    entityId: assignmentId,
    fieldId: problemId,
    data,
  }),
})

export const useDrawingImages = () => {
  const credits = useAssignmentCredentials()
  return useStoreMap({
    store: drawingImagesModel.$state,
    keys: [credits.id, credits.problemId],
    fn: (drawingImages, [assignmentId, problemId]) => drawingImages[assignmentId]?.[problemId] ?? null,
  })
}
