import { ToolbarTools } from '../types'

// https://wiki.geogebra.org/en/Reference:Toolbar
type GeogebraToolbarTools = Array<keyof typeof ToolbarTools>

const defaultTools: GeogebraToolbarTools = ['MOVE', 'PEN', 'TEXT', 'ZOOM_IN', 'ZOOM_OUT']

export const getToolbarTools = (tools: GeogebraToolbarTools) => {
  return [...defaultTools, ...tools].map((tool) => ToolbarTools[tool]).join(' | ')
}
