import { createValuePersistorModel } from '../../../lib/helpers/createValuePersistorModel'

type ThreadIdsModel = {
  assignmentId: string
  problemId: string
  threadId: string
}

export const threadIdsModel = createValuePersistorModel({
  mapper: ({ assignmentId, problemId, threadId }: ThreadIdsModel) => ({
    entityId: assignmentId,
    fieldId: problemId,
    data: { threadId },
  }),
})
