import React, { useCallback, useEffect, useState } from 'react'
import './styles.scss'
import ShortcutButtonsPanel from './ShortcutButtonsPanel'
import { SMALL_SCREEN_WIDTH } from '../../../constants/defaults'
import Numpad from '../../../ui/Numpad'
import clsx from 'clsx'
import { MATH_BUTTONS_DISABLED_FRACTION, MATH_BUTTONS_EXTENDED } from '../../../ui/Numpad/NumpadMath/symbols'
import { NumpadListeners, NumpadTypes } from '../../../ui/Numpad/types'
import NumpadSelector from './NumpadSelector'
import { useSpreadsheetModel } from '../../../features/DrawBoard/model'
import { restrictOnChangeByInputModeType } from '../../../ui/Numpad/NumpadMath/helpers'
import { MyScriptMathCharacterTypes } from '../../../constants/myScriptMathCharacterTypes'
import { useMatchesMobile } from '../../../lib/device/hook'
import { CharTypeNumpad } from '../../../features/CharTypeNumpad'

type KeyboardsProps = {
  isButtonsDisabled?: boolean
  isSelectButtonHidden?: boolean
  isMathButtonExtended?: boolean
  characterType?: MyScriptMathCharacterTypes
} & NumpadListeners

const Keyboards = ({
  onPress,
  onSpecialSymbolPress,
  isSelectButtonHidden = false,
  isButtonsDisabled = false,
  isMathButtonExtended = false,
  characterType,
  onBackspacePress,
}: KeyboardsProps) => {
  const isMobileDevice = useMatchesMobile()
  const [currentNumpad, setCurrentNumpad] = useState<NumpadTypes>(NumpadTypes.NUMBERS)
  const { isCellActive, controller } = useSpreadsheetModel()

  useEffect(() => {
    if (isCellActive) setCurrentNumpad(NumpadTypes.NUMBERS)
  }, [isCellActive])

  const backSpacePress = useCallback(
    (e: any) => {
      if (controller?.isCellSelected) {
        return controller.eraseSelected()
      }
      if (onBackspacePress) onBackspacePress(e)
    },
    [controller, onBackspacePress]
  )

  const pressHandler = useCallback(
    (sign: string) => {
      if (controller?.isCellSelected) {
        return controller.updateSelected(sign)
      }

      if (characterType) {
        return restrictOnChangeByInputModeType({
          onChangeCallback: onPress,
          value: sign,
          inputModeType: characterType,
        })
      }

      onPress?.(sign)
    },
    [characterType, controller, onPress]
  )

  const specialSymbolPressHandler = useCallback(
    (sign: string) => {
      if (controller?.isCellSelected) {
        return controller.updateSelected(sign)
      }

      if (characterType) {
        return restrictOnChangeByInputModeType({
          onChangeCallback: onSpecialSymbolPress,
          value: sign,
          inputModeType: characterType,
        })
      }

      if (onSpecialSymbolPress) onSpecialSymbolPress(sign)
    },
    [controller, onSpecialSymbolPress, characterType]
  )

  useEffect(() => {
    const manageResizeScreen = () => {
      const screenWidth = window.innerWidth
      if (screenWidth < SMALL_SCREEN_WIDTH) {
        return
      }

      if (currentNumpad === NumpadTypes.NUMBERS_MOBILE) {
        setCurrentNumpad(NumpadTypes.NUMBERS)
      }
    }

    window.addEventListener('resize', manageResizeScreen)

    return () => {
      window.removeEventListener('resize', manageResizeScreen)
    }
  }, [currentNumpad])

  if (isMobileDevice) return null

  return (
    <div className='numpad-window-wrapper'>
      {isCellActive ? (
        <>
          <NumpadSelector
            onSelect={setCurrentNumpad}
            currentValue={currentNumpad}
            className={clsx({ hidden: isSelectButtonHidden })}
            hideButtons={isCellActive || isSelectButtonHidden}
          />

          <Numpad
            numpadType={currentNumpad}
            onPress={pressHandler}
            onSpecialSymbolPress={specialSymbolPressHandler}
            isButtonDisabled={isButtonsDisabled}
            className={clsx({ 'spreadsheet-numpad': isCellActive })}
            mathNumpadAdditionalButtons={
              isCellActive || isMathButtonExtended ? MATH_BUTTONS_EXTENDED : MATH_BUTTONS_DISABLED_FRACTION
            }
          />

          <ShortcutButtonsPanel
            currentNumpad={currentNumpad}
            onPress={pressHandler}
            backspacePress={backSpacePress}
            disableButton={isButtonsDisabled}
          />
        </>
      ) : (
        <CharTypeNumpad
          charType={characterType ?? MyScriptMathCharacterTypes.ALGEBRA_BASIC}
          onClick={pressHandler}
          onSpecialSymbolPress={onSpecialSymbolPress}
        />
      )}
    </div>
  )
}

export default Keyboards
