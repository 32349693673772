import { restore, combine } from 'effector'
import type { PracticeTree } from '../../PracticeMode/types'
import { $practiceGrade } from '../../PracticeMode/models/currentPracticeGrade'
import { fetchSkillsTreeStatisticsFx, fetchSkillsTreeFx } from './requests'

const findGradeTree = (treeRoot: PracticeTree | null, selectedGrade: number) => {
  if (!treeRoot) return null
  const gradeTree = treeRoot.children.find((subtree) => subtree.attributes?.grade === selectedGrade)
  return gradeTree || treeRoot.children[0]
}

export class practiceSkillsModel {
  static readonly $statistics = restore(fetchSkillsTreeStatisticsFx.doneData, null)
  static readonly $tree = restore(fetchSkillsTreeFx.doneData, null)

  static readonly $gradeSubtree = combine([this.$tree, $practiceGrade]).map(([treeRoot, grade]) =>
    findGradeTree(treeRoot, grade)
  )
  static readonly $domains = this.$gradeSubtree.map((gradeTree) => {
    if (!gradeTree) return []
    return gradeTree.children || []
  })
}
