import { IndexedDB } from '../../../../lib/helpers/indexedDB'
import { OFFLINE_QUEUE } from './offlineQueue'

type AnswerImage = {
  id: string
  data: string
}

const offlineQueueImagesDB = new IndexedDB<AnswerImage>({
  database: OFFLINE_QUEUE,
  store: 'images',
})

export { offlineQueueImagesDB }
