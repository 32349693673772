import { createEvent } from 'effector'
import { PREVIOUS_DRAWING, USER_DATA } from '../../config/localStorageKeys'
import history from '../../history'
import { RoutesPaths } from '../../containers/paths'
import { api } from '../../api'

export class AuthModel {
  public static readonly $isAuth = api.manager.$token.map((token) => !!token)
  public static readonly logout = createEvent()
}

AuthModel.logout.watch(() => {
  api.manager.resetToken()
  localStorage.removeItem(USER_DATA)
  localStorage.removeItem(PREVIOUS_DRAWING)
  history.push(RoutesPaths.INDEX)
})
