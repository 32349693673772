import React from 'react';

import './styles.scss';

const LogoutToast = () => {
    return (
        <div className="logout-toast">
            <span>Account deleted.</span>
            <span>Please sign in with a different account &#128579;</span>
        </div>
    );
};

export default LogoutToast;