export const NUMBERS = 'NUMBERS'
export const NUMBERS_MOBILE = 'NUMBERS_MOBILE'
export const LETTERS = 'LETTERS'
export const UNITS = 'UNITS'

export const NUMPAD_FIRST_PART = 1
export const NUMPAD_SECOND_PART = 2

export enum NumpadTypes {
  NUMBERS = 'NUMBERS',
  NUMBERS_MOBILE = 'NUMBERS_MOBILE',
  LETTERS = 'LETTERS',
  UNITS = 'UNITS',
}
