import html2canvas from 'html2canvas'
import { RefObject, useEffect, useRef } from 'react'

type UseDomImageConfig = {
  type: 'image/png' | 'image/jpeg' | 'image/webp'
  quality: number
}

const DEFAULT_CONFIG: UseDomImageConfig = {
  type: 'image/png',
  quality: 1.0,
}

type UseDomImageProps = {
  ref: RefObject<HTMLElement | null>
  config?: UseDomImageConfig
}

class LocalImage {
  public readonly src
  public remoteUrl = ''

  public constructor(src: string) {
    this.src = src
  }

  public setRemoteUrl(url: string) {
    this.remoteUrl = url
  }

  public toString() {
    return this.remoteUrl || this.src
  }
}

export const useDomToImage = ({ ref, config = DEFAULT_CONFIG }: UseDomImageProps, deps: any[]) => {
  const image = useRef<LocalImage | null>(null)
  const effectTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (effectTimeout.current !== null) {
      clearTimeout(effectTimeout.current)
    }

    effectTimeout.current = setTimeout(() => {
      if (!ref.current) return
      const currentHTML = ref.current.innerHTML
      image.current = null

      html2canvas(ref.current, {
        backgroundColor: null,
        scale: 5,
        logging: false,
      })
        .then((canvas) => {
          if (currentHTML !== ref.current?.innerHTML) return
          image.current = new LocalImage(canvas.toDataURL(config.type, config.quality))
        })
        .catch((error) => {
          console.error('Error capturing canvas:', error)
        })
    }, 300)

    return () => {
      if (effectTimeout.current !== null) {
        clearTimeout(effectTimeout.current)
      }
    }
  }, deps)

  return image
}
