export type Category = {
  order: number
  primaryName: string
  subcategories: SubCategory[]
  _id: string
}

export type SubCategory = {
  abilities: Ability[]
  order: number
  primaryName: string
  _id: string
}

export type Ability = {
  grades: number[]
  order: number
  primaryName: string
  _id: string
}

export type SkillStats = {
  correct: number
  notAnswered: number
  wrong: number
}

export type SkillStatisticsMap = {
  [cardId: string]: SkillStats
}

export type PracticeStatistics = {
  [cardId: string]: number
}

export enum ChangeGradeActions {
  DECREASE = 0,
  INCREASE = 1,
}

export type PracticeSkillAbility = {
  functionName: string
  problemCount: number
  visibility: { languageCode: string; visible: boolean }[]
}

export type PracticeSkill = {
  _id: string
  nameTranslations: string
  descriptionTranslations: string
  order: number
  ability: {
    functionName: string
    problemCount: number
    visibility: {
      languageCode: 'en-SE' | 'sv-SE'
      visible: boolean
    }[]
  }
  theory: {
    languageCode: 'en-SE' | 'sv-SE'
    theory: string //theoryId
  }[]
}

export type PracticeTree = {
  attributes?: { grade: number }
  children: PracticeTree[]
  descriptionTranslations: string
  nameTranslations: string
  order: number
  originalPath: string
  parents: PracticeTree[]
  skills: PracticeSkill[]
  type: 'root' | 'grade' | 'domain' | 'standard'
  _id: string
}

export type PracticeStoreModel = {
  openedSkillInfo: PracticeSkill | null
  nextSkillInfo: PracticeSkill | null
}
