export enum AppLocale {
  EN = 'en-US',
  SV = 'sv-SE',
  UK = 'en-GB',
}

export enum AppLanguage {
  EN = 'en-SE', // previously we used en-US but auth-web requires change
  SV = 'sv-SE',
  UK = 'en-GB',
}

export enum Detector {
  CUSTOM_DOMAIN_DETECTOR = 'customDomainDetector',
  CUSTOM_SEARCH_PARAM_DETECTOR = 'customSearchParamDetector',
}

export const languageSettings = {
  [AppLocale.EN]: {
    key: AppLocale.EN,
    name: 'English',
    header: 'en-SE;q=1.0, sv-SE;q=0.9',
  },
  [AppLocale.SV]: {
    key: AppLocale.SV,
    name: 'Svenska',
    header: 'sv-SE;q=1.0, en-SE;q=0.9',
  },
  [AppLocale.UK]: {
    key: AppLocale.UK,
    name: 'British English',
    header: 'en-SE;q=1.0, sv-SE;q=0.9', // en-SE to use the same English content for UK and US
  },
  // 'ar': {
  //     name: 'Ara',
  //     header: 'ar-SE;q=1.0, sv-SE;q=0.9'
  // },// arabic
}
