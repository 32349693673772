import React, { MutableRefObject } from 'react'
import CircleLoader from '../../ui/Loaders/CircleLoader'
import { GEOGEBRA, HANDWRITING, MULTIPLE_CHOICE, ORDERED, SINGLE_CHOICE } from '../../constants/answerTypes'
import { createImageSrc } from '../../components/Image/createImageSrc'
import { getBackgroundDescriptorFromAnswerType } from '../../components/Problem/helpers'
import DrawBoard from '../DrawBoard/DrawBoard'
import { ClearResult } from '../../constants/drawingArea'
import { currentProblemAnswerModel } from '../Problem/models/answer'
import { ExerciseThemeColorMapper } from '../Problem/problem.style.mode.helpers'
import { canAccessSimpleMode } from '../simpleMode/helpers'
import { BoardController, ToolBarVariants } from '@magmamath/react-native-draw-board'
import GeogebraApplet from '../Geogebra'
import { Problem } from '../Problem/types'
import { useGeogebraModel } from '../Geogebra/model'
import { getStudentStats } from '../../helpers/users/getUserStudyInfo'
import { useChoiceAnswersModel } from '../../components/Problem/ProblemAnswerChoice/model'
import { useCurrentExerciseMode } from '../Problem/models/exerciseMode'
import { HistorySaveCredits } from '../DrawBoard/model.persist'
import { useStoreMap, useUnit } from 'effector-react'
import { assignmentModel } from '../assignment/model/assignment.model'

const ANSWER_TYPE_WITH_DRAWING_WORKING_AREA = [HANDWRITING, MULTIPLE_CHOICE, SINGLE_CHOICE, ORDERED]

type WorkingAreaProps = {
  isLoading: boolean
  currentProblem: Problem | undefined
  currentTask: Problem | undefined
  drawBoardRef: MutableRefObject<BoardController | null>
  isDisabledDrawBoard: boolean
  isDisabledGeogebra: boolean
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  userSettings: any
  manuallyLoadedImage?: string
  isCheatingDetectionEnabled?: boolean
  currentProblemCredits: HistorySaveCredits | null
}
const WorkingArea = ({
  isLoading,
  currentTask,
  currentProblem,
  drawBoardRef,
  isDisabledDrawBoard,
  isDisabledGeogebra,
  onKeyDown,
  userSettings,
  manuallyLoadedImage,
  isCheatingDetectionEnabled,
  currentProblemCredits,
}: WorkingAreaProps) => {
  const assignment = useUnit(assignmentModel.$assignment)
  const exerciseMode = useCurrentExerciseMode(({ exerciseMode }) => exerciseMode)
  const geogebraAnswers = useGeogebraModel((state) => state.answersBase64)
  const resetChoiceAnswers = useChoiceAnswersModel((state) => state.resetAnswers)
  const classroomGrade = useStoreMap(assignmentModel.$assignment, (assignment) => assignment?.classes?.[0]?.grade)

  if (isLoading || !currentProblem) {
    return <CircleLoader />
  }

  if (!currentTask) return null
  const answerType = currentTask?.answerType
  const currentUserStats = getStudentStats(currentTask)

  if (answerType === GEOGEBRA) {
    const answer = assignment ? geogebraAnswers[assignment?._id]?.[currentTask?._id] : null
    return (
      <GeogebraApplet
        initialState={answer ?? currentUserStats?.stats?.drawing?.geogebra}
        geogebraSettings={currentProblem?.geogebraSettings}
        handleArrowNavigation={onKeyDown}
        isDisabled={isDisabledGeogebra}
      />
    )
  }

  if (!ANSWER_TYPE_WITH_DRAWING_WORKING_AREA.includes(currentTask.answerType)) {
    return null
  }

  const bgImageSrc = createImageSrc({
    cdnSrc: currentTask?.cdnDrawingAreaImage,
    src: currentTask?.drawingAreaImage,
    size: 'large',
    format: 'webp',
  })
  const { showImage, withGrid } = getBackgroundDescriptorFromAnswerType(answerType)

  //TODO why do we need here handleArrowNavigation let move it just to Problem component
  return (
    <div tabIndex={-1} className='problem-draw-area' onKeyDown={onKeyDown}>
      <div className='problem-draw-area' id='draw-area'>
        <div className={'drawing-with-inputs'} id='drawing-with-inputs'>
          <DrawBoard
            disabled={isDisabledDrawBoard}
            problemCredits={currentProblemCredits}
            withGrid={withGrid}
            imgSrc={showImage ? manuallyLoadedImage || bgImageSrc : ''}
            controller={drawBoardRef}
            onDrawboardClear={(event) => {
              if (event.result !== ClearResult.BOARD_CLEARED) return
              currentProblemAnswerModel.reset()
              resetChoiceAnswers()
            }}
            answerType={answerType}
            initialMode={userSettings.canvasType}
            characterType={currentProblem?.characterType}
            isCheatingDetectionEnabled={isCheatingDetectionEnabled}
            tools={assignment?.drawBoardAvailableFeatures}
            toolBarActiveColor={ExerciseThemeColorMapper[exerciseMode]}
            variant={
              canAccessSimpleMode(userSettings.grade, classroomGrade) ? ToolBarVariants.SIMPLE : ToolBarVariants.COMMON
            }
          />
        </div>
      </div>
    </div>
  )
}

export default WorkingArea
