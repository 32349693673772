import React from 'react'
import { ReactComponent as AnswerEyeIcon } from '../../assets/images/facit.svg'

import './styles.scss'

const AnswerLookingIcon = () => {
  return <AnswerEyeIcon className='answer-looking-icon' size={24} />
}

export default AnswerLookingIcon
