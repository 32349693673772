import React, { FC } from 'react'

export interface LettersProps {
  letters: string[]
  onPress?: (letter: string) => void
}

const Letters: FC<LettersProps> = ({ letters, onPress }) => {
  return (
    <div className='numpad-letters-row'>
      {letters.map((letter, index) => {
        const key = `${letter}${index}`
        if (!letter) return <button key={key} className='empty-letter' />

        return (
          <button key={key} className='numpad-letters-item' onClick={() => onPress?.(letter)}>
            {letter}
          </button>
        )
      })}
    </div>
  )
}

export default Letters
