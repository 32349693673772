import { BoardController, ServerPersistModule } from '@magmamath/react-native-draw-board'
import { useEffect } from 'react'
import { setHasCanvasHistory } from '../Problem/models/initialInputFocus'
import { api } from '../../api'
import { DrawingHistoryEntity, EntityType } from '../../api/api.drawBoard'

type CoreSaveCredits = {
  exerciseId: string
  problemId: string
  subProblemId: string | null
}

export type HistorySaveCredits = CoreSaveCredits & {
  userId?: string
  id?: string
}

function getEntity({ exerciseId, subProblemId, problemId }: CoreSaveCredits): DrawingHistoryEntity {
  if (subProblemId) {
    return {
      id: `${exerciseId}-${subProblemId}`,
      type: EntityType.SUB_PROBLEM,
    }
  }
  return { id: `${exerciseId}-${problemId}`, type: EntityType.PROBLEM }
}

export const serverPersistModule = new ServerPersistModule<HistorySaveCredits>({
  api: {
    get: ({ userId, ...credits }) => {
      const entity = getEntity(credits)
      return api.drawBoardHistory
        .get({ userId, entity })
        .then(({ history, updatedAt, _id }) => {
          setHasCanvasHistory(history.length > 150)
          return {
            id: _id,
            history,
            updatedAt: Date.parse(updatedAt),
          }
        })
        .catch((error) => {
          setHasCanvasHistory(false)
          throw error
        })
    },
    async set(credits, history): Promise<{ id: string } | void> {
      const id = credits.id
      if (id) {
        await api.drawBoardHistory.update({ id, history })
        return { id }
      }
      const result = await api.drawBoardHistory.create({
        entity: getEntity(credits),
        history,
      })
      return { id: result._id }
    },
  },
})

export const useDrawBoardPersist = (
  boardProvider: { current: BoardController | null },
  credits?: HistorySaveCredits | null
) => {
  useEffect(() => {
    if (!boardProvider.current?.model) return
    serverPersistModule.setUp(boardProvider.current.model)
    return () => {
      serverPersistModule.saveCurrent({ cleanAfter: true })
      serverPersistModule.setUp(null)
    }
  }, [boardProvider])

  useEffect(() => {
    serverPersistModule.setCredits(credits ?? null)
  }, [credits])
}
