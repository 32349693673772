export const ALLOWED_TAGS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'ol',
  'ul',
  'li',
  'blockquote',
  'div',
  'a',
  'strong',
  'iframe',
  'u',
  'em',
  's',
  'img',
]
