import pixelWidth from 'string-pixel-width'

import { TITLE_FONT_SIZE } from '../../../../constants/styleConstants'

export const isNeedToResize = (message: string | undefined) => {
  if (!message) {
    return false
  }

  const width = pixelWidth(message, { size: TITLE_FONT_SIZE })
  return width > 204
}
