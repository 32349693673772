import React from 'react'
import { IconProps } from './types'

export const SimpleModeDigitsIcon = ({ fill = '#666679' }: IconProps) => (
  <svg width='37' height='37' viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M30.4222 18.1285C32.6727 18.5874 33.8963 19.9421 33.8963 21.8212C33.8963 24.4651 31.5365 26.2131 28.696 26.2131C26.358 26.2131 24.326 24.9895 23.3646 22.8263L25.7462 21.5809C26.3143 23.023 27.4724 23.7877 28.8271 23.7877C30.1381 23.7877 31.1869 23.0885 31.1869 21.8212C31.1869 20.3135 29.8978 19.5269 27.5598 19.5269H26.8606V17.3638C29.286 17.3638 30.597 16.5772 30.597 15.0914C30.597 13.9333 29.6574 13.2996 28.4994 13.2996C27.3413 13.2996 26.1395 13.9551 25.5277 15.0695L23.5831 13.824C24.5008 11.8794 26.511 10.7869 28.696 10.7869C31.2525 10.7869 33.3064 12.2727 33.3064 14.4577C33.3064 16.1183 32.1483 17.6041 30.4222 18.1285Z'
      fill={fill}
    />
    <path
      d='M14.8834 23.5692H21.9192V26.082H11.3874V23.7003L16.26 19.2647C17.3088 18.3252 18.6198 17.1016 18.6198 15.5721C18.6198 14.1081 17.5492 13.3215 16.3037 13.3215C14.8834 13.3215 13.5287 14.261 13.4632 16.2931L10.9723 16.162C11.0815 12.5349 13.7691 10.7869 16.4567 10.7869C19.2753 10.7869 21.3511 12.5567 21.3511 15.3973C21.3511 17.6915 19.8871 19.1773 18.8165 20.095L14.8834 23.5692Z'
      fill={fill}
    />
    <path d='M5.28866 26.082V13.5181H3.10364V11.0054H8.01993V26.082H5.28866Z' fill='#666679' />
  </svg>
)
