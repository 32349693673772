import { uploadedSolutionImages } from './images'
import { persist } from 'effector-storage/local'
import { sample } from 'effector'
import { $isOnline } from '../../../lib/models/isOnline'
import { offlineQueueImagesDB } from '../models/offlineQueue/offlineQueueImagesDB'
import { $offlineQueue, OFFLINE_QUEUE, removedFromOfflineQueue } from '../models/offlineQueue/offlineQueue'
import { sendAnswerFx } from '../models/saveAnswer/saveAnswer'
import { ProblemSessionsPayload, SolutionStatusesPayload } from '../../../api/types.solutions'
import { api } from '../../../api'

function sendSolutionAndSessionData(solution: SolutionStatusesPayload, session: ProblemSessionsPayload, token: string) {
  return Promise.all([
    api.sendSolutionStatusesFromQueue({ body: solution, token }),
    api.sendProblemSessionFromQueue({ body: session, token }),
  ])
}

const syncUnsavedAnswers = async () => {
  const answersQueue = $offlineQueue.getState()
  if (!answersQueue.length || !navigator.onLine) return

  for (const { id, token, solution, session, images } of answersQueue) {
    try {
      const { uploadedCanvasImage, uploadedHandwritingImage } = await uploadedSolutionImages(images)
      await sendSolutionAndSessionData(
        {
          ...solution,
          handwritingImage: uploadedHandwritingImage,
          drawingImage: uploadedCanvasImage,
        },
        session,
        token
      )

      await offlineQueueImagesDB.remove(images.drawboard)
      await offlineQueueImagesDB.remove(images.handwriting)
      removedFromOfflineQueue(id)
    } catch (error) {
      console.error('Error syncing unsaved answers for ID:', id, error)
    }
  }
}

export const initAnswerQueue = () => {
  persist({ store: $offlineQueue, key: OFFLINE_QUEUE })
  syncUnsavedAnswers()

  sample({
    source: $offlineQueue,
    clock: $isOnline,
    fn: (answersQueue, isOnline) => ({ answersQueue, isOnline }),
  }).watch(({ answersQueue, isOnline }) => {
    if (isOnline) syncUnsavedAnswers()
  })

  window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
    if ($offlineQueue.getState().length || sendAnswerFx.pending.getState()) {
      return event.preventDefault()
    }

    return null
  })
}
