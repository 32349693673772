import type { AnswerChoiceOption } from '../types'
import { useContext, useEffect, useState } from 'react'
import { Context as UserSettingsContext } from '../../../../../context/userSettingsContext'
import { useTextToSpeechStore } from '../../../../../features/TextToSpeech/model'
import { translateChoiceButton } from '../../../../../helpers'
import { useOnProblemChange } from '../../../../../features/assignment/hooks/useOnProblemChange'

const getChoicesToTranslate = (answerOptions: AnswerChoiceOption[], translationLanguage: string) => {
  return answerOptions
    .filter((option) => !option.translation && option.variant)
    .map((option) => translateChoiceButton(option, translationLanguage))
}

export const useTranslateChoiceOptions = (answerOptions: AnswerChoiceOption[]) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const [isTranslationPending, setIsTranslationPending] = useState(false)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)

  useOnProblemChange(() => {
    setIsTranslationPending(false)
  })

  useEffect(() => {
    if (!answerOptions?.length || !isTranslated) return
    if (answerOptions.every((option) => option.translation)) return

    const translateChoiceOptions = async () => {
      setIsTranslationPending(true)

      try {
        const choicesToTranslate = getChoicesToTranslate(answerOptions, userSettings.translationLanguage)
        await Promise.all(choicesToTranslate)
      } catch (error) {
        console.error('An error occurred translating choice answers: ', error)
      } finally {
        setIsTranslationPending(false)
      }
    }

    translateChoiceOptions()
  }, [answerOptions, isTranslated, userSettings.translationLanguage])

  return { isTranslationPending }
}
