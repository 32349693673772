import React from 'react';

import './styles.scss';

const LinkMessage = (props) => {
    return (
        <div className="link-message-container">
            <a href={props.link} target="_blank" rel="noopener noreferrer" className="link-message">
                {props.link}
            </a>
        </div>
    );
};

export default LinkMessage;