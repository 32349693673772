import { MathNumpadSymbol } from './NumpadMath/types'

export enum NumpadTypes {
  NUMBERS = 'NUMBERS',
  NUMBERS_MOBILE = 'NUMBERS_MOBILE',
  LETTERS = 'LETTERS',
  UNITS = 'UNITS',
}

export type NumpadListeners = {
  onPress?: (sign: string) => void
  onSpecialSymbolPress?: (sign: string) => void
  onBackspacePress?: (e: any) => void
}

export type NumpadVariantProps<Button = string> = {
  isButtonDisabled?: boolean
  additionalButtons?: Button[]
} & NumpadListeners

export type NumpadProps = {
  numpadType: NumpadTypes
  className?: string
  mathNumpadAdditionalButtons?: MathNumpadSymbol[]
} & Omit<NumpadVariantProps, 'additionalButtons'>

export type Unit = {
  symbol: string
  mathPower?: number
}
