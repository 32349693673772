import { create } from 'zustand'
import { ZustandStore } from './types.store'

export function zustandStore<T>(initialState: T): ZustandStore<T> {
  const hook = create(() => initialState) as ZustandStore<T>

  hook.add = (event, reducer) => {
    event.addTo(hook, reducer)
    return hook
  }

  hook.addReset = (event) => {
    event.addTo(hook, () => initialState)
    return hook
  }

  return hook
}
