import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { COLORS } from '@magmamath/ui'
import { useTranslation } from 'react-i18next'

type ChoiceDrawingRequiredProps = {
  isPressed: boolean
  message?: string
  className?: string
}

export const ChoiceDrawingRequired = ({ isPressed, message, className }: ChoiceDrawingRequiredProps) => {
  const { t } = useTranslation()
  return (
    <span
      className={clsx(className ?? styles.ChoiceDrawingRequired)}
      style={{ color: isPressed ? COLORS.NEUTRAL_10 : COLORS.NEUTRAL_6 }}
    >
      {message ?? t('drawingRequiredText')}
    </span>
  )
}
