import { create } from 'zustand'
import { addEvent } from '../../lib/zustand/event'

export enum HintType {
  MANUAL = 'manual',
  SMART_EVAL = 'smartEval',
  LAMBDA = 'lambda',
}

export type Hint = {
  text: string
  type: HintType
}

type ProblemHintModel = {
  hint: Hint | null
  isOpen: boolean
  isHintLoading: boolean
}

const initialState: ProblemHintModel = {
  hint: null,
  isOpen: false,
  isHintLoading: false,
}

export const useHintMyScriptModel = create(() => initialState)

const setHint = addEvent(useHintMyScriptModel, (hint: Hint | null) => ({ hint }))
const setHintOpen = addEvent(useHintMyScriptModel, (isOpen: boolean) => ({ isOpen }))
const setIsHintLoading = addEvent(useHintMyScriptModel, (isHintLoading: boolean) => ({ isHintLoading }))

export const hintMyScriptModel = {
  setHint,
  setHintOpen,
  setIsHintLoading,
}
