import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RoutesPaths } from './paths'
import { getToken } from '../api/apiManager'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const condition = rest.hasOwnProperty('privateCondition') ? rest.privateCondition : getToken()

  return (
    <Route
      {...rest}
      render={(props) => {
        return condition ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: RoutesPaths.INDEX,
              state: { redirectURL: props?.location?.pathname },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
