import React, { useCallback, useContext, useEffect, useState } from 'react'
import SocketContext from '../../context/socketContext'
import { SocketEvents } from '../../constants/socketEventsConstants'
import { getHelpQueueFx } from './model/effects'
import { useOnAssignmentChange } from '../assignment/hooks/useOnAssignmentChange'
import { availableChatsModel } from '../MagmaChat/models/availableChatsModel'
import { useAssignmentCredentials } from '../assignment/hooks/useAssignmentCredentials'
import { createInitialMessage } from '../MagmaChat/models/messages'
import { initialMessage } from '../MagmaChat/constants'
import { useAssignmentStatus } from '../assignment/hooks/useAssignmentStatus'
import { HelpQueueButton } from './components/HelpQueueButton/HelpQueueButton'
import { HelpQueueModal } from './components/HelpQueueModal/HelpQueueModal'

type HelpQueueProps = {
  classroomId: string
}

export const HelpQueue = ({ classroomId }: HelpQueueProps) => {
  const { socket } = useContext(SocketContext)
  const [isQueueOpen, setIsQueueOpen] = useState(false)
  const { id, problemId } = useAssignmentCredentials()
  const assignmentStatus = useAssignmentStatus()

  const handleToggleHelpQueue = () => {
    setIsQueueOpen((prevState) => !prevState)
    if (!isQueueOpen && !assignmentStatus.success) {
      createInitialMessage({
        ...initialMessage,
        assignmentId: id,
        problemId,
      })

      availableChatsModel.setField({ assignmentId: id, problemId })
    }
  }

  const fetchQueue = useCallback(() => {
    if (classroomId) getHelpQueueFx(classroomId)
  }, [classroomId])

  useOnAssignmentChange(fetchQueue)

  useEffect(() => {
    socket.on(SocketEvents.USER_ADDED_TO_HELP_QUEUE, fetchQueue)
    socket.on(SocketEvents.USER_REMOVED_FROM_HELP_QUEUE, fetchQueue)

    return () => {
      socket.off(SocketEvents.USER_ADDED_TO_HELP_QUEUE, fetchQueue)
      socket.off(SocketEvents.USER_REMOVED_FROM_HELP_QUEUE, fetchQueue)
    }
  }, [socket, fetchQueue])

  return (
    <>
      <HelpQueueButton onClick={handleToggleHelpQueue} />
      {isQueueOpen && <HelpQueueModal onClickBackdrop={() => setIsQueueOpen(false)} />}
    </>
  )
}
