import type { MutableRefObject } from 'react'

type GetSidebarSizeParamsProps = {
  contentRef: MutableRefObject<HTMLDivElement | null>
  containerRef: MutableRefObject<HTMLDivElement | null>
  hasImage: boolean
  defaultFontSize: number
}

const AVAILABLE_FONT_SIZES = [16, 20, 24, 28, 32]

export const getSidebarSizeParams = ({
  contentRef,
  containerRef,
  hasImage,
  defaultFontSize = 16,
}: GetSidebarSizeParamsProps) => {
  const containerHeight = containerRef.current?.clientHeight ?? 0
  const contentHeight = contentRef.current?.clientHeight ?? 0

  const isScrollable = contentHeight + (hasImage ? 200 : 0) > containerHeight
  const currentIndex = AVAILABLE_FONT_SIZES.indexOf(defaultFontSize)
  const newFontSize = currentIndex > 0 ? AVAILABLE_FONT_SIZES[currentIndex] - 2 : defaultFontSize

  return {
    isScrollable,
    fontSize: isScrollable ? newFontSize : defaultFontSize,
  }
}
