import { Assignment } from '../../features/ExerciseItem/types'
import { createEvent, sample } from 'effector'
import { $assignments } from '../../features/assignmentsPagination/model/assignments'
import { assignmentModel } from '../../features/assignment/model/assignment.model'

type AssignmentSocketListenerCallback = ({
  assignment,
  exerciseId,
}: {
  assignment: Assignment | null
  exerciseId: string
}) => void

type SocketUpdatePayload = {
  exerciseId: string
}

export const createAssignmentSocketListener = (fn: AssignmentSocketListenerCallback) => {
  const socketEvent = createEvent<SocketUpdatePayload>()

  sample({
    source: { assignments: $assignments, currentAssignment: assignmentModel.$assignment },
    clock: socketEvent,
    fn: ({ assignments, currentAssignment }, { exerciseId }) => {
      return {
        exerciseId,
        assignment: currentAssignment
          ? currentAssignment
          : assignments.find((assignment) => assignment._id === exerciseId) ?? null,
      }
    },
  }).watch(fn)

  return socketEvent
}
