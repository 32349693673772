import React, { useContext } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import ProblemAnswerButton from '../../../../../features/Problem/ProblemAnswerButton/ProblemAnswerButton'
import { getUserType } from '../../../../../helpers/users/getUserInfo'
import { DrawBoardMode, useDrawBoardModel } from '../../../../../features/DrawBoard/model'
import { ExerciseMode, useCurrentExerciseMode } from '../../../../../features/Problem/models/exerciseMode'
import { useTranslation } from 'react-i18next'
import AnswerInfoContext from '../../../../../context/answerInfoContext'
import { $toolsMode, ToolsMode } from '../../../../../features/toolsMode/model'
import { useUnit } from 'effector-react'
import { AppLocale } from '../../../../../locales/i18n.config'

type ChoiceInputTypeSelectorProps = {
  isSubmitDisabled: boolean
  submitAnswer: () => void
  wiggleAnimation: boolean
}

const styleModeVariants = {
  [ToolsMode.REGULAR]: {},
  [ToolsMode.SIMPLE]: {
    button: clsx(styles.ChoiceSubmitButtonSimpleMode, styles.SimpleModeDrawingRequired),
    content: styles.ChoiceSubmitButtonContentSimpleMode,
  },
}

export const ChoiceInputTypeSelector = ({
  isSubmitDisabled,
  submitAnswer,
  wiggleAnimation,
}: ChoiceInputTypeSelectorProps) => {
  const { i18n } = useTranslation()
  const { style: statusStyle, submitButtonText, examStopped } = useContext(AnswerInfoContext)
  const isSpreadsheetActive = useDrawBoardModel((state) => state.mode === DrawBoardMode.SPREADSHEET)
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  const toolsMode = useUnit($toolsMode)

  const isExamMode = exerciseMode === ExerciseMode.EXAM
  const isSmallText = isExamMode || i18n.language === AppLocale.EN
  const userType = getUserType()

  return (
    <div
      className={clsx(styles.ChoiceSubmitContainer, { [styles.ChoiceInputTypeSelectorContainer]: isSpreadsheetActive })}
    >
      <div
        className={clsx(styles.ChoiceSubmitButtonContainer, {
          [styles.ButtonContainerSimpleMode]: toolsMode === ToolsMode.SIMPLE,
          [styles.SmallText]: isSmallText,
        })}
      >
        <ProblemAnswerButton
          animation={wiggleAnimation}
          disabled={isSubmitDisabled}
          examStopped={examStopped}
          exerciseMode={exerciseMode}
          onClick={submitAnswer}
          style={statusStyle}
          text={submitButtonText}
          userType={userType}
          withMargin
          classNames={styleModeVariants[toolsMode]}
        />
      </div>
    </div>
  )
}
