import React from 'react'
import { RulerIcon } from '../../../ui/icons/Ruler'
import { AreaIcon } from '../../../ui/icons/Area'
import { MathVolumeIcon } from '../../../ui/icons/MathVolume'
import { MassIcon } from '../../../ui/icons/Mass'
import { TimeIcon } from '../../../ui/icons/Time'
import { LOCALES, UNITS_CATEGORIES } from './common'
import Sup from '../../../ui/Sup'
import { NumpadSymbol } from './symbols'
import { createPowerCubeValue } from '../helpers'
import { COLORS } from '@magmamath/ui'

export const UNIT_BUTTONS = [
  { name: UNITS_CATEGORIES.LENGTH, symbol: <RulerIcon fill={COLORS.NEUTRAL_10} /> },
  { name: UNITS_CATEGORIES.AREA, symbol: <AreaIcon fill={COLORS.NEUTRAL_10} /> },
  { name: UNITS_CATEGORIES.VOLUME, symbol: <MathVolumeIcon fill={COLORS.NEUTRAL_10} /> },
  { name: UNITS_CATEGORIES.WEIGHT, symbol: <MassIcon fill={COLORS.NEUTRAL_10} /> },
  { name: UNITS_CATEGORIES.TIME, symbol: <TimeIcon fill={COLORS.NEUTRAL_10} /> },
]

export const LOCALIZED_UNITS: Record<LOCALES, Record<UNITS_CATEGORIES, NumpadSymbol[]>> = {
  [LOCALES.EN]: {
    [UNITS_CATEGORIES.LENGTH]: [
      { value: 'mm', symbol: 'mm' },
      { value: 'in', symbol: 'in' },
      { value: 'cm', symbol: 'cm' },
      { value: 'ft', symbol: 'ft' },
      { value: 'm', symbol: 'm' },
      { value: 'yd', symbol: 'yd' },
      { value: 'km', symbol: 'km' },
      { value: 'mi', symbol: 'mi' },
    ],
    [UNITS_CATEGORIES.AREA]: [
      { value: 'mm²', symbol: <Sup value='mm' supValue='2' /> },
      { value: 'in²', symbol: <Sup value='in' supValue='2' /> },
      { value: 'cm²', symbol: <Sup value='cm' supValue='2' /> },
      { value: 'ft²', symbol: <Sup value='ft' supValue='2' /> },
      { value: 'm²', symbol: <Sup value='m' supValue='2' /> },
      { value: 'yd²', symbol: <Sup value='yd' supValue='2' /> },
      { value: 'km²', symbol: <Sup value='km' supValue='2' /> },
      { value: 'mi²', symbol: <Sup value='mi' supValue='2' /> },
    ],
    [UNITS_CATEGORIES.VOLUME]: [
      { value: createPowerCubeValue('mm'), symbol: <Sup value='mm' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('in'), symbol: <Sup value='in' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('cm'), symbol: <Sup value='cm' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('ft'), symbol: <Sup value='ft' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('m'), symbol: <Sup value='m' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('yd'), symbol: <Sup value='yd' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('km'), symbol: <Sup value='km' supValue='3' />, isSpecialSymbol: true },
      { value: createPowerCubeValue('mi'), symbol: <Sup value='mi' supValue='3' />, isSpecialSymbol: true },
      { value: 'mL', symbol: 'mL' },
      { value: 'floz', symbol: 'floz' },
      { value: 'L', symbol: 'L' },
      { value: 'pt', symbol: 'pt' },
      { value: 'cup', symbol: 'cup' },
      { value: 'qt', symbol: 'qt' },
      { value: 'gal', symbol: 'gal' },
    ],
    [UNITS_CATEGORIES.WEIGHT]: [
      { value: 'mg', symbol: 'mg' },
      { value: 'oz', symbol: 'oz' },
      { value: 'g', symbol: 'g' },
      { value: 'lb', symbol: 'lb' },
      { value: 'kg', symbol: 'kg' },
      { value: 'T', symbol: 'T' },
      { value: 't', symbol: 't' },
    ],
    [UNITS_CATEGORIES.TIME]: [
      { value: 's', symbol: 's' },
      { value: 'min', symbol: 'min' },
      { value: 'h', symbol: 'h' },
    ],
  },
  [LOCALES.SWE]: {
    [UNITS_CATEGORIES.LENGTH]: [
      { value: 'mm', symbol: 'mm' },
      { value: 'cm', symbol: 'cm' },
      { value: 'dm', symbol: 'dm' },
      { value: 'm', symbol: 'm' },
      { value: 'km', symbol: 'km' },
      { value: 'mil', symbol: 'mil' },
    ],
    [UNITS_CATEGORIES.AREA]: [
      { value: 'mm²', symbol: 'mm²' },
      { value: 'cm²', symbol: 'cm²' },
      { value: 'dm²', symbol: 'dm²' },
      { value: 'm²', symbol: 'm²' },
      { value: 'km²', symbol: 'km²' },
      { value: 'ha', symbol: 'ha' },
    ],
    [UNITS_CATEGORIES.VOLUME]: [
      { value: createPowerCubeValue('mm'), symbol: <Sup value='mm' supValue='3' />, isSpecialSymbol: true },
      { value: 'ml', symbol: 'ml' },
      { value: createPowerCubeValue('cm'), symbol: <Sup value='cm' supValue='3' />, isSpecialSymbol: true },
      { value: 'cl', symbol: 'cl' },
      { value: createPowerCubeValue('dm'), symbol: <Sup value='dm' supValue='3' />, isSpecialSymbol: true },
      { value: 'dl', symbol: 'dl' },
      { value: createPowerCubeValue('m'), symbol: <Sup value='m' supValue='3' />, isSpecialSymbol: true },
      { value: 'l', symbol: 'l' },
      { value: createPowerCubeValue('km'), symbol: <Sup value='km' supValue='3' />, isSpecialSymbol: true },
    ],
    [UNITS_CATEGORIES.WEIGHT]: [
      { value: 'mg', symbol: 'mg' },
      { value: 'g', symbol: 'g' },
      { value: 'hg', symbol: 'hg' },
      { value: 'kg', symbol: 'kg' },
      { value: 'ton', symbol: 'ton' },
    ],
    [UNITS_CATEGORIES.TIME]: [
      { value: 's', symbol: 's' },
      { value: 'min', symbol: 'min' },
      { value: 'h', symbol: 'h' },
    ],
  },
}
