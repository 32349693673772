import React from 'react'
import './styles.scss'
import StyledSlider from './StyledSlider'

const TextSizeSlider = (props) => {
  return (
    <div className='text-slider'>
      <StyledSlider
        marks
        valueLabelDisplay='off'
        min={16}
        max={32}
        step={4}
        value={props.value}
        onChange={props.changeTextSize}
        onChangeCommitted={(e, value) => props.saveTextSize(value)}
        onTouchEnd={(e, value) => props.saveTextSize(value)}
      />
    </div>
  )
}

export default TextSizeSlider
