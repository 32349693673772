import React from 'react'

const SimpleModeSpeakerIcon = () => {
  return (
    <svg width='35' height='25' viewBox='0 0 35 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.1092 0.609214C27.6426 0.0759 28.5072 0.0759 29.0405 0.609214C32.6935 4.26217 34.2203 8.01718 34.2203 12.5C34.2203 16.9827 32.6935 20.7377 29.0405 24.3907C28.5072 24.924 27.6426 24.924 27.1092 24.3907C26.5759 23.8574 26.5759 22.9927 27.1092 22.4594C30.2845 19.2842 31.489 16.211 31.489 12.5C31.489 8.78891 30.2845 5.71574 27.1092 2.54052C26.5759 2.0072 26.5759 1.14253 27.1092 0.609214ZM13.6138 1.23161C15.8411 -0.602602 19.1983 0.981738 19.1983 3.86705V21.1329C19.1983 24.0182 15.8411 25.6025 13.6138 23.7683L7.39304 18.6453H3.49344C1.60789 18.6453 0.0793457 17.1168 0.0793457 15.2312V9.76868C0.0793457 7.88313 1.60789 6.35459 3.49344 6.35459L7.39304 6.35459L13.6138 1.23161ZM16.467 3.86705C16.467 3.28999 15.7955 2.97312 15.3501 3.33996L9.12933 8.46294C8.64032 8.86566 8.02653 9.08586 7.39304 9.08586H3.49344C3.11633 9.08586 2.81062 9.39157 2.81062 9.76868V15.2312C2.81062 15.6083 3.11633 15.914 3.49344 15.914H7.39304C8.02653 15.914 8.64032 16.1343 9.12933 16.537L15.3501 21.6599C15.7955 22.0268 16.467 21.7099 16.467 21.1329V3.86705ZM23.0123 4.0233C23.5456 3.48999 24.4103 3.48999 24.9436 4.0233C27.1837 6.26342 28.7577 9.32453 28.7577 12.5C28.7577 15.6754 27.1837 18.7365 24.9436 20.9766C24.4103 21.5099 23.5456 21.5099 23.0123 20.9766C22.479 20.4433 22.479 19.5786 23.0123 19.0453C24.8691 17.1885 26.0264 14.7871 26.0264 12.5C26.0264 10.2128 24.8691 7.8114 23.0123 5.95461C22.479 5.42129 22.479 4.55662 23.0123 4.0233Z'
        fill='#666679'
      />
    </svg>
  )
}

export default SimpleModeSpeakerIcon
