import React, { ReactNode } from 'react'
import { Text, Pressable, View, PressableProps } from 'react-native'
import { styles } from './styles'

type ButtonProps = PressableProps & {
  children?: ReactNode | string
  variant?: 'default' | 'primary' | 'successFirstAttempt' | 'success' | 'error' | 'exam' | 'district' | 'grey' | 'dark'
  badge?: string
  width?: number
  height?: number
}

type PressableInteractions = {
  pressed: boolean
  hovered?: boolean
  focused?: boolean
}

export const SimpleModeNumpadButton = ({
  children,
  variant = 'default',
  badge,
  width,
  height,
  ...props
}: ButtonProps) => {
  const greyText = ['default', 'grey'].includes(variant)
  return (
    <Pressable
      style={({ pressed, hovered }: PressableInteractions) => [
        styles.button,
        styles[variant],
        pressed && styles.pressed,
        { width },
        { height },
        hovered && styles[`${variant}Hover`],
      ]}
      {...props}
    >
      <View style={styles.contentContainer}>
        {typeof children === 'string' ? (
          <Text style={greyText ? styles.text : styles.textWhite} numberOfLines={1}>
            {children}
          </Text>
        ) : (
          children
        )}

        {badge && (
          <View style={[styles.badge, styles[`${variant}Badge`]]}>
            <Text style={greyText ? styles.badgeText : styles.badgeTextWhite}>{badge}</Text>
          </View>
        )}
      </View>
    </Pressable>
  )
}
