import { createEvent, restore } from 'effector'

const setIsOnline = createEvent<boolean>()

const $isOnline = restore(setIsOnline, navigator.onLine)

window.addEventListener('online', () => setIsOnline(true))
window.addEventListener('offline', () => setIsOnline(false))

export { $isOnline }
