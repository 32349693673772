import React from 'react'

import styles from './styles.module.scss'
import { PracticeTree } from '../../../PracticeMode/types'
import Skills from '../Skills/Skills'
import { Typography } from '@magmamath/ui'

type PracticeContentBlockProps = {
  standard: PracticeTree
  scrollArea: HTMLDivElement | null
}

export const StandardSubtree = ({ standard, scrollArea }: PracticeContentBlockProps) => {
  return (
    <div className={styles.StandardSubtree} id={standard._id}>
      <Typography variant='h6' className={styles.StandardSubtreeTitle}>
        {standard.nameTranslations}
      </Typography>
      <Skills skills={standard.skills} scrollArea={scrollArea} />
    </div>
  )
}
