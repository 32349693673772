import React from 'react'
import styles from './styles.module.scss'
import { inputTypeKeyboard, inputTypeMyScript } from '../../../../../../Problem/helpers'
import AnswerVariantsBlock from '../../AnswerVariantsBlock/AnswerVariantsBlock'
import Keyboards from '../../../../../../../components/Problem/Keyboards'
import AnswerFeedback from '../../../../../../../ui/feedback/AnswerFeedback'
import clsx from 'clsx'
import InputTypeSelector from '../../../../../../../components/Problem/ProblemAnswer/InputTypeSelector'
import ProblemAnswerButton from '../../../../../../Problem/ProblemAnswerButton/ProblemAnswerButton'
import { InputType } from '../../../../../../Problem/models/inputType'
import { getUserType } from '../../../../../../../helpers/users/getUserInfo'
import { PracticeChoiceAnswerProps } from '../../../types'
import { useTranslation } from 'react-i18next'
import { PracticeChoiceT2S } from '../../PracticeChoiceT2s/PracticeChoiceT2S'

export const PracticeChoiceAnswerRegular = ({
  inputType,
  answerVariants,
  isTranslated,
  isTranslationCompleted,
  answerStatus,
  answerStyle,
  choiceType,
  feedbackText,
  isActiveSpreadsheet,
  toggleInputType,
  setInputType,
  exerciseMode,
  submitAnswer,
  submitButtonText,
  isSubmitButtonDisabled,
  textToSpeechContent,
}: PracticeChoiceAnswerProps) => {
  const { t } = useTranslation()
  return (
    <div className={styles.SelectAnswersContainer}>
      {inputTypeMyScript(inputType) ? (
        <div className={styles.AnswerVariantsContainer}>
          <AnswerVariantsBlock
            answerVariants={answerVariants}
            isTranslated={isTranslated}
            isTranslationCompleted={isTranslationCompleted}
            isChoiceDisabled={!!answerStatus}
            style={answerStyle}
            choiceType={choiceType}
          />
        </div>
      ) : (
        <div className={styles.AnswerVariantsKeyboards}>
          <div />
          <Keyboards isSelectButtonHidden isMathButtonExtended />
        </div>
      )}

      <PracticeChoiceT2S inputType={inputType} isTranslated={isTranslated} textToSpeechContent={textToSpeechContent} />

      <div className={styles.SubmitAnswerButtonWrapper}>
        <div className={styles.AnswerFeedbackWrapper}>
          {answerStatus && <AnswerFeedback text={feedbackText} style={answerStyle} />}
        </div>

        <div className={clsx({ [styles.AnswerSubmitTypeSelectorWrapper]: isActiveSpreadsheet })}>
          {isActiveSpreadsheet && (
            <div className={clsx(styles.InputSelector, { [styles.Hidden]: inputTypeKeyboard(inputType) })}>
              <InputTypeSelector inputType={inputType} changeInputType={toggleInputType} />
            </div>
          )}

          <div className={styles.SubmitAnswerButton}>
            {isActiveSpreadsheet && inputTypeKeyboard(inputType) ? (
              <ProblemAnswerButton
                text={t('backText')}
                onClick={() => setInputType(InputType.MY_SCRIPT)}
                disabled={false}
                userType={getUserType()}
                withMargin
                exerciseMode={exerciseMode}
              />
            ) : (
              <ProblemAnswerButton
                onClick={submitAnswer}
                text={submitButtonText}
                style={answerStyle}
                disabled={isSubmitButtonDisabled}
                withMargin
                userType={getUserType()}
                exerciseMode={exerciseMode}
              />
            )}
          </div>
        </div>
        <div className={styles.Flex} />
      </div>
    </div>
  )
}
