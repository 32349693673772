export const SIDEBAR_LEFT_ICON_SIZE = 25
export const ARROW_ICON_SIZE = 18
export const HEADER_FONT_SIZE = 26
export const TITLE_FONT_SIZE = 16
export const PREFILLED_BOX_FONT_SIZE = 50
export const PREFILLED_BOX_FULL_TEXT_FONT_SIZE = 30
export const SMALL_SCREEN_SIZE = 700
export const EXERCISES_LIST_PAGE_MIN_SIZE = 1000
export const EXERCISE_TITLE_FONT_SIZE = 18
export const EXERCISE_TITLE_MIN_FONT_SIZE = 14
export const DEFAULT_FONT_SIZE = 16
export const DEFAULT_SETTINGS_FONT_SIZE = 20
export const PROBLEM_DESCRIPTION_WIDTH = 330

export const SPREADSHEET_CELL_SIZE = 51.2
export const SPREADSHEET_HEADER_SIZE = 30

export const TEXT_TO_SPEECH_SWITCH_WIDTH = 51
export const TEXT_TO_SPEECH_SWITCH_HEIGHT = 24

export const RESIZE_FONT_BORDER = 5
