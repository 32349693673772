import React, { PropsWithChildren } from 'react'
import { I18nextProvider } from 'react-i18next'
import io from 'socket.io-client'
import { Provider as SidebarProvider } from '../context/sidebarContext'
import { Provider as UserSettingsProvider } from '../context/userSettingsContext'
import SocketContext from '../context/socketContext'
import i18n from '../locales/i18n'
import { getToken } from '../api/apiManager'

const socket = io(window._env_.SOCKET_URL, {
  query: {
    token: getToken(),
  },
  reconnection: true,
  reconnectionDelay: 500,
  transports: ['websocket'],
})

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <I18nextProvider i18n={i18n}>
      <SidebarProvider>
        <UserSettingsProvider>
          <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
        </UserSettingsProvider>
      </SidebarProvider>
    </I18nextProvider>
  )
}
