import React from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

import { ReactComponent as ErrorPicture } from '../../assets/images/error_picture.svg'
import ButtonLoader from '../../ui/Loaders/ButtonLoader'
import { COLORS } from '@magmamath/ui'
import { $exercisesError, setExerciseError } from '../../features/assignmentsPagination/model/assignments'
import { useUnit } from 'effector-react'
import { fetchAssignmentWithStatsFx } from '../../features/assignmentsPagination/model/request'

const ErrorComponent = (props) => {
  const { t } = useTranslation()
  const errorMessage = useUnit($exercisesError)
  const isFetching = useUnit(fetchAssignmentWithStatsFx.pending)

  const onRefreshButtonClick = async () => {
    await props.refreshCallback()
    setExerciseError(null)
  }

  const getRefreshButtonContent = () => {
    return isFetching ? <ButtonLoader color={COLORS.PRIMARY_RED} /> : t('refreshText')
  }

  return (
    <div className='error-component-container'>
      <ErrorPicture />
      <span className='error-message-container text'>{t(errorMessage)}</span>
      <button className='refresh-button text' onClick={onRefreshButtonClick} disabled={isFetching}>
        {getRefreshButtonContent()}
      </button>
    </div>
  )
}

export default ErrorComponent
