import React, { createContext } from 'react'
import styles from './styles.module.scss'
import { MyScriptMathCharacterTypes } from '../../constants/myScriptMathCharacterTypes'
import { NumericKeypad } from './components/NumericKeypad'
import { AlgebraBasic } from './variants/AlgebraBasic'
import { RegularNumpad } from './variants/RegularNumpad'
import { Units } from './variants/Units'
import { AdvancedAlgebra } from './variants/AdvancedAlgebra'
import { checkIsSwedenLocale } from '../../helpers/userLocale'
import {
  CURRENCY,
  GEOMETRIC_ANGLES,
  GEOMETRIC_COORDINATES,
  NUMBERS_AND_X,
  NUMBERS_RATIONAL,
  OPERATORS,
  PERCENT,
  TIME,
} from './constants/symbols'

type Props = {
  charType: MyScriptMathCharacterTypes
  onClick: (sign: string) => void
  onSpecialSymbolPress?: (sign: string) => void
}

const numpadVariants: Partial<Record<MyScriptMathCharacterTypes, React.ReactNode>> = {
  [MyScriptMathCharacterTypes.NUMBERS_WHOLE]: <NumericKeypad hideFractionButton hideSeparatorButton />,
  [MyScriptMathCharacterTypes.NUMBERS_RATIONAL]: <RegularNumpad symbols={NUMBERS_RATIONAL} />,
  [MyScriptMathCharacterTypes.OPERATORS]: <RegularNumpad symbols={OPERATORS} />,
  [MyScriptMathCharacterTypes.PERCENT]: <RegularNumpad symbols={PERCENT} />,
  [MyScriptMathCharacterTypes.NUMBERS_AND_X]: <RegularNumpad symbols={NUMBERS_AND_X} />,
  [MyScriptMathCharacterTypes.GEOM_ANGLES]: <RegularNumpad symbols={GEOMETRIC_ANGLES} />,
  [MyScriptMathCharacterTypes.ALGEBRA_BASIC]: <AlgebraBasic />,
  [MyScriptMathCharacterTypes.UNITS]: <Units />,
  [MyScriptMathCharacterTypes.NUMBERS_AND_UNITS]: <Units />,
  [MyScriptMathCharacterTypes.TIME]: (
    <RegularNumpad symbols={TIME} keypadProps={{ hideFractionButton: true, hideSeparatorButton: true }} />
  ),
  [MyScriptMathCharacterTypes.CURRENCY]: (
    <RegularNumpad symbols={CURRENCY} keypadProps={{ hideFractionButton: true }} />
  ),
  [MyScriptMathCharacterTypes.GEOM_COORDINATE]: (
    <RegularNumpad symbols={GEOMETRIC_COORDINATES} keypadProps={{ hideFractionButton: checkIsSwedenLocale() }} />
  ),
  [MyScriptMathCharacterTypes.DEFAULT]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.NUMBERS]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.NUMBERS_AND_MULTIPLICATION]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.DEFAULT_MATH]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.ALGEBRA_ADVANCED]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.ALGEBRA_GREEK]: <AdvancedAlgebra />,
  [MyScriptMathCharacterTypes.GENERAL_MATH]: <AdvancedAlgebra />,
}

export const CharTypeNumpadContext = createContext<Partial<Omit<Props, 'charType'>>>({})

export const CharTypeNumpad = ({ charType, onClick, onSpecialSymbolPress }: Props) => {
  if (!charType) return null

  return (
    <CharTypeNumpadContext.Provider value={{ onClick, onSpecialSymbolPress }}>
      <div className={styles.CharTypeNumpad}>
        {numpadVariants[charType] ?? numpadVariants[MyScriptMathCharacterTypes.ALGEBRA_BASIC]}
      </div>
    </CharTypeNumpadContext.Provider>
  )
}
