import React from 'react'
import { ExitIcon } from '../../../../ui/icons/Exit/ExitIcon'
import { COLORS } from '@magmamath/ui'
import { RoutesPaths } from '../../../../containers/paths'
import { useHistory } from 'react-router-dom'

export const PracticeClose = () => {
  const history = useHistory()

  const leavePracticePage = () => {
    history.push(RoutesPaths.EXERCISES)
  }

  return (
    <div className='close-practice-page-icon' onClick={leavePracticePage}>
      <ExitIcon width={15} height={15} stroke={COLORS.NEUTRAL_9} />
    </div>
  )
}
