import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type ProblemStates = {
  currentProblemIndex: number
  currentSubProblemIndex: number
}

export type ProblemModel = ProblemStates & {
  setCurrentProblemIndex: (index: number) => void
  setCurrentSubProblemIndex: (index: number) => void
}

const initialState = {
  currentProblemIndex: 0,
  currentSubProblemIndex: 0,
}

export const useProblemStore = create(
  immer<ProblemModel>((set) => ({
    ...initialState,
    setCurrentProblemIndex: (currentProblemIndex: number) =>
      set((state: ProblemModel) => {
        state.currentProblemIndex = currentProblemIndex
      }),
    setCurrentSubProblemIndex: (currentSubProblemIndex: number) =>
      set((state: ProblemModel) => {
        state.currentSubProblemIndex = currentSubProblemIndex
      }),
  }))
)
