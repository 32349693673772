import React from 'react'
import styles from './styles.module.scss'

import AnswerHint from '../../../../../AnswerHint'
import AnswerHintContent from '../../../../../AnswerHint/AnswerHintContent'
import { TEACHER } from '../../../../../../constants/userTypes'
import { getUserType } from '../../../../../../helpers/users/getUserInfo'
import clsx from 'clsx'

type Props = {
  isPracticeMode: boolean
  showAnswerPreview: boolean
  showAnswerPreviewExamMode: boolean
  isLoading: boolean
  className?: string
}

export const CorrectAnswerPopup = ({
  isPracticeMode,
  showAnswerPreview,
  showAnswerPreviewExamMode,
  isLoading,
  className,
}: Props) => {
  const isTeacherAccount = getUserType() === TEACHER

  const shouldRenderAnswerHint = !isPracticeMode && (isTeacherAccount || showAnswerPreview)
  const shouldRenderAnswerHintContent = !isPracticeMode && showAnswerPreviewExamMode

  if (isLoading) return null

  if (shouldRenderAnswerHint) {
    return (
      <div className={clsx(styles.CorrectAnswerPopup, className)}>
        <AnswerHint />
      </div>
    )
  }

  if (shouldRenderAnswerHintContent) {
    return (
      <div className={clsx(styles.CorrectAnswerPopup, className)}>
        <AnswerHintContent showAnswerHint />
      </div>
    )
  }

  return null
}
