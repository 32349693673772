import React, { useEffect, useRef, useCallback, useContext, useState, ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import useInputTypeStore, { InputType } from '../../../../Problem/models/inputType'
import { drawBoardModel, useSpreadsheetModel } from '../../../../DrawBoard/model'
import {
  getInputElement,
  setElementInFocusedPosition,
  specialSymbolPress,
  checkIsActiveElementInInput,
  setCaretInInputFieldOnKeyPress,
} from '../../../../Problem/problem.answer.helpers'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../../../Problem/models/answer'
import usePracticeProblemsStore, { PracticeMode } from '../../../models/practiceProblems'
import { cleanLatexAnswer } from '../../../../../helpers/myScript/cleanLatexAnswer'
import { Context as UserSettingsContext } from '../../../../../context/userSettingsContext'
import { useCurrentExerciseMode } from '../../../../Problem/models/exerciseMode'
import { useAssignmentCredentials } from '../../../../assignment/hooks/useAssignmentCredentials'
import { useUnit } from 'effector-react'
import { PracticeHandwritingRegular } from './variants/PracticeHandwritingRegular'
import { PracticeHandwritingSimple } from './variants/PracticeHandwritingSimple'
import { PracticeHandwritingProps } from './types'
import { ContentEditableEvent } from 'react-contenteditable'
import Practice from '../../../models/practice'
import { KeyboardKeys } from '../../../../../constants/keyboardKeys'

type ProblemAnswerPracticeProps = {
  onSubmitAnswer: (userAnswer: string[] | string) => void
  onAnswerChangeStart?: () => void
}

const PRACTICE_HANDWRITING_VARIANTS: Record<PracticeMode, ComponentType<PracticeHandwritingProps>> = {
  [PracticeMode.REGULAR]: PracticeHandwritingRegular,
  [PracticeMode.SIMPLE]: PracticeHandwritingSimple,
}

const PracticeHandwritingAnswer: React.FC<ProblemAnswerPracticeProps> = ({ onSubmitAnswer, onAnswerChangeStart }) => {
  const { t } = useTranslation()

  const { state: userSettings } = useContext(UserSettingsContext)
  const spreadsheetController = useSpreadsheetModel((state) => state.controller)
  const answerText = useUnit(currentProblemAnswerModel.$text)
  const inputType = useInputTypeStore((state) => state)
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const currentProblem = usePracticeProblemsStore((state) => state.currentProblem)
  const credits = useAssignmentCredentials()
  const { exerciseMode } = useCurrentExerciseMode()
  const [isRecognitionPending, setIsRecognitionPending] = useState(false)
  const practiceMode = usePracticeProblemsStore((state) => state.practiceMode)

  const isActiveSpreadsheet = !!spreadsheetController
  //workaround for content editable because it has known issue with state change https://www.npmjs.com/package/react-contenteditable
  const spreadsheetRef = useRef(spreadsheetController)
  spreadsheetRef.current = spreadsheetController
  const submitButtonText = t(!answerStatus ? 'replyText' : 'nextText')
  const feedbackText = t(answerStatus === ProblemAnswerStatus.SUCCESS ? 'rightFirstTryText' : 'wrongText')
  const answerStyle = !answerStatus
    ? ''
    : answerStatus === ProblemAnswerStatus.SUCCESS
    ? 'success-first-attempt'
    : 'mistake'

  useEffect(() => {
    inputType.set(userSettings.canvasType ?? InputType.MY_SCRIPT)
  }, [credits.problemId, credits.id, userSettings])

  useEffect(() => {
    if (isActiveSpreadsheet) inputType.set(InputType.KEYBOARD)
  }, [isActiveSpreadsheet])

  const onFocusInput = () => {
    onAnswerChangeStart?.()
    if (spreadsheetRef.current) {
      spreadsheetRef.current.blur()
    }
  }

  const onContentEditableChange = (event: ContentEditableEvent) => {
    currentProblemAnswerModel.changeFromInput(event.target.value)
  }

  const onNumpadButtonPress = (value: any) => {
    setElementInFocusedPosition(value.toString(), false, null, answerText, currentProblemAnswerModel.setText)
  }

  const onSpecialSymbolPress = (value: string) => {
    specialSymbolPress(value, false, answerText, currentProblemAnswerModel.setText, currentProblemAnswerModel.clearText)
  }

  const onSubmitClick = useCallback(async () => {
    await onSubmitAnswer(cleanLatexAnswer(answerText))
  }, [answerText, onSubmitAnswer])

  const keyDownHandler = useCallback(
    async (event: KeyboardEvent) => {
      if (spreadsheetController?.isCellSelected || drawBoardModel.ref.inputFocused || isRecognitionPending) return
      if (event.key === KeyboardKeys.ENTER) {
        event.preventDefault()
        if (!answerText) return

        await onSubmitClick()
        return
      }

      const inputElement = getInputElement()
      if (checkIsActiveElementInInput(inputElement) || !!answerStatus) return
      setCaretInInputFieldOnKeyPress(inputElement)
    },
    [isRecognitionPending, answerText, onSubmitClick, answerStatus, spreadsheetController?.isCellSelected]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, { capture: true })

    return () => {
      document.removeEventListener('keydown', keyDownHandler, { capture: true })
    }
  }, [keyDownHandler])

  const PracticeAnswerVariant = PRACTICE_HANDWRITING_VARIANTS[practiceMode]
  if (!PracticeAnswerVariant) {
    console.error('No such component variant for practice handwriting answer')
    return null
  }

  return (
    <PracticeAnswerVariant
      inputType={inputType}
      answerStatus={answerStatus}
      currentProblem={currentProblem}
      onSubmitClick={onSubmitClick}
      onAnswerChangeStart={onAnswerChangeStart}
      setIsRecognitionPending={setIsRecognitionPending}
      onNumpadButtonPress={onNumpadButtonPress}
      onSpecialSymbolPress={onSpecialSymbolPress}
      onContentEditableChange={onContentEditableChange}
      onFocusInput={onFocusInput}
      exerciseMode={exerciseMode}
      answerStyle={answerStyle}
      answerText={answerText}
      feedbackText={feedbackText}
      submitButtonText={submitButtonText}
      isRecognitionPending={isRecognitionPending}
    />
  )
}

export default PracticeHandwritingAnswer
