import React from 'react'
import './styles.scss'
import InputTypeSelector from '../../../../components/Problem/ProblemAnswer/InputTypeSelector'
import { InputType } from '../../../../features/Problem/models/inputType'
import { TrashIcon } from '../../../../ui/icons/Trash'
import { IconButton } from '../../../../ui/IconButton'
import { useCurrentExerciseMode } from '../../../../features/Problem/models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../../../../features/Problem/problem.style.mode.helpers'
import { IconButtonProps } from '../../../../ui/IconButton'

type ToolContentProps = {
  inputType: InputType
  changeInputType: () => void
  clearAnswer: () => void
  isTrashButtonDisabled?: boolean
  isInputSelectorHidden?: boolean
}

const ToolContent = ({
  inputType,
  changeInputType,
  clearAnswer,
  isTrashButtonDisabled = false,
  isInputSelectorHidden,
}: ToolContentProps) => {
  const { exerciseMode } = useCurrentExerciseMode()

  return (
    <div className='input-tool-buttons-container'>
      {!isInputSelectorHidden && (
        <div className='input-tool-selector-button'>
          <InputTypeSelector inputType={inputType} changeInputType={changeInputType} />
        </div>
      )}
      <IconButton
        variant={ExerciseThemeVariantMapper[exerciseMode] as IconButtonProps['variant']}
        disabled={isTrashButtonDisabled}
        className='clear-answer-button'
        onClick={clearAnswer}
      >
        <TrashIcon />
      </IconButton>
    </div>
  )
}

export default ToolContent
