import React from 'react'
import styles from './styles.module.scss'
import ProblemAnswerButton from '../../../ProblemAnswerButton/ProblemAnswerButton'
import { getUserType } from '../../../../../helpers/users/getUserInfo'
import { useTranslation } from 'react-i18next'
import { useCurrentExerciseMode } from '../../../models/exerciseMode'

type UnsupportedAnswerTypeProps = {
  onClick: () => void
  isExamStopped: boolean
}

export const UnsupportedAnswerType = ({ onClick, isExamStopped }: UnsupportedAnswerTypeProps) => {
  const { t } = useTranslation()
  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)

  return (
    <div className={styles.UnsupportedAnswerType}>
      <div>
        <p>{t('unsupportedAnswerType')}</p>
        <ProblemAnswerButton
          onClick={onClick}
          text={t('nextText')}
          exerciseMode={exerciseMode}
          examStopped={isExamStopped}
          animation={false}
          userType={getUserType()}
        />
      </div>
    </div>
  )
}
