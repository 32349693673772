import React from 'react'
import { NumpadTypes } from '../../../ui/Numpad/types'
import clsx from 'clsx'
import { TFunction, useTranslation } from 'react-i18next'

type NumpadSelectorProps = {
  className?: string
  onSelect: (type: NumpadTypes) => void
  currentValue: NumpadTypes
  hideButtons?: boolean
}

type NumpadSelectDescriptor = {
  type: NumpadTypes
  className: string
  label: (t: TFunction) => string
}

const NUMPAD_SELECT_BUTTONS: NumpadSelectDescriptor[] = [
  {
    type: NumpadTypes.UNITS,
    className: '',
    label: (t) => t('unitsText'),
  },
  {
    type: NumpadTypes.LETTERS,
    className: '',
    label: () => 'abc',
  },
  {
    type: NumpadTypes.NUMBERS,
    className: '',
    label: () => '123',
  },
  {
    type: NumpadTypes.NUMBERS_MOBILE,
    className: 'number-section-mobile',
    label: () => '123',
  },
]

const NumpadSelector = React.memo(({ className = '', onSelect, currentValue, hideButtons }: NumpadSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('numpad-type-selector', className)}>
      {!hideButtons
        ? NUMPAD_SELECT_BUTTONS.map(({ type, className, label }, index) => {
            return (
              <span
                key={index}
                className={clsx('numpad-switch-item', className, { active: currentValue === type })}
                onClick={() => onSelect(type)}
              >
                {label(t)}
              </span>
            )
          })
        : null}
    </div>
  )
})

export default NumpadSelector
