import React from 'react'

import { PauseIcon as DefaultPauseIcon } from '../../ui/icons/Pause'
import { ResumeIcon as DefaultResumeIcon } from '../../ui/icons/Resume'
import { SpeakerIcon as DefaultSpeakerIcon } from '../../ui/icons/Speaker'
import CircleLoader from '../../ui/Loaders/CircleLoader'
import { COLORS } from '@magmamath/ui'

const TextToSpeechIcon = ({ audio, isPlaying, isFetching, PauseIcon, PlayIcon, SpeakerIcon }) => {
  const isAudioFinished = audio?.currentTime === 0 || audio?.currentTime === audio?.duration

  if (isFetching) {
    return <CircleLoader width={25} height={25} color={COLORS.PRIMARY_BLUE} />
  }

  if (isPlaying) {
    return PauseIcon ? <PauseIcon /> : <DefaultPauseIcon fill={COLORS.NEUTRAL_1} />
  }

  if (isAudioFinished) {
    return SpeakerIcon ? <SpeakerIcon /> : <DefaultSpeakerIcon />
  }

  return PlayIcon ? <PlayIcon /> : <DefaultResumeIcon fill={COLORS.NEUTRAL_1} />
}

export default TextToSpeechIcon
