import { TFunction } from 'react-i18next'
import { Category, SubCategory, Ability, PracticeStatistics, PracticeSkill, SkillStatisticsMap } from './types'
import { practicePageSessionActions } from './sessionStorage'
import { CorrectAnswerRatePercentage } from './constants'
import { calculateSkillProgress } from '../practiceModeSkills/skillsTree.helpers'

type VisiblePart = { element: Element; height: number; isFullPlacedOnScrollArea: boolean }
type GetNextAbilitiesArguments = {
  categories: Category[]
  grade: number
  currentAbilityId: string
}

const isSubcategoryHasAbilitiesWithGrade = (subcategory: SubCategory, grade: number) => {
  return subcategory.abilities.some((ability: Ability) => ability.grades.includes(grade))
}

export const filterCategoriesByGrade = (categories: Category[], grade: number) => {
  return categories.filter((category: Category) => {
    return category.subcategories.some((subcategory: SubCategory) => {
      return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
    })
  })
}

export const filterSubcategoriesByGrade = (subcategories: SubCategory[], grade: number) => {
  return subcategories.filter((subcategory: SubCategory) => {
    return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
  })
}

export const filterAbilitiesByGrade = (abilities: Ability[], grade: number) => {
  return abilities.filter((ability: Ability) => {
    return ability.grades.includes(grade)
  })
}

const getFilteredSubCategoryAbilities = (filteredSubcategories: SubCategory[], grade: number) => {
  return filteredSubcategories.map((subcategory) => filterAbilitiesByGrade(subcategory.abilities, grade))
}

const getFilteredCategoryAbilities = (filteredCategories: Category[], grade: number) => {
  return filteredCategories
    .map((category) => {
      return getFilteredSubCategoryAbilities(filterSubcategoriesByGrade(category.subcategories, grade), grade)
    })
    .flat(2)
}

export const getFirstUnsolvedAbility = (
  filteredCategories: Category[],
  grade: number,
  statistics: PracticeStatistics
) => {
  const abilities = getFilteredCategoryAbilities(filteredCategories, grade)

  if (!abilities.length) {
    return
  }

  return abilities.find((ability) => {
    const statistic = statistics[ability?._id]
    return !statistic || statistic < CorrectAnswerRatePercentage.ONE_STAR
  })
}

export const getSolvedCategoryPercent = (
  filteredSubCategories: SubCategory[],
  grade: number,
  statistics: PracticeStatistics
) => {
  const filteredAbilities = getFilteredSubCategoryAbilities(filteredSubCategories, grade).flat()
  if (!filteredAbilities?.length) {
    return
  }

  const percent = filteredAbilities.reduce((count: number, ability: Ability) => {
    const isSolved = (statistics[ability._id] ?? 0) >= CorrectAnswerRatePercentage.ONE_STAR
    return count + Number(isSolved)
  }, 0)

  return Math.round((percent / filteredAbilities.length) * 100)
}

export const findPreviousOpenedSubCategory = (subCategories: SubCategory[], openedSubCategoryId: string) => {
  return subCategories?.find((subCategory) => subCategory?._id === openedSubCategoryId)
}

export const findPreviousOpenedCategory = (categories: Category[], openedSubCategoryId: string) => {
  return categories.find((category) => {
    return findPreviousOpenedSubCategory(category?.subcategories, openedSubCategoryId)
  })
}

export const getPreviousActiveCategoryAndSubCategoryId: (
  categories: Category[],
  grade: number
) => {
  category: Category
  subCategoryId: string
} = (categories, grade) => {
  const firstCategory = categories[0] || null
  const previousActiveSubCategoryId = practicePageSessionActions.lastActiveSubCategoryId.get()
  practicePageSessionActions.lastActiveSubCategoryId.remove()
  const filteredSubcategoriesInFirstCategory = filterSubcategoriesByGrade(firstCategory?.subcategories, grade)
  if (!previousActiveSubCategoryId) {
    return { category: firstCategory, subCategoryId: filteredSubcategoriesInFirstCategory?.[0]?._id || '' }
  }

  const previousCategory = findPreviousOpenedCategory(categories, previousActiveSubCategoryId)
  if (!previousCategory) {
    return { category: firstCategory, subCategoryId: filteredSubcategoriesInFirstCategory?.[0]?._id || '' }
  }

  const previousSubCategory = findPreviousOpenedSubCategory(previousCategory.subcategories, previousActiveSubCategoryId)
  if (!previousSubCategory) {
    const filteredSubcategoriesInPreviousOpenedCategory = filterSubcategoriesByGrade(
      previousCategory?.subcategories,
      grade
    )

    return {
      category: previousCategory,
      subCategoryId: filteredSubcategoriesInPreviousOpenedCategory?.[0]?._id || '',
    }
  }

  return { category: previousCategory, subCategoryId: previousSubCategory._id }
}

export const getNextOrderedAbility = (abilities: Ability[], order: number) => {
  return abilities.find((ability) => ability.order > order)
}

const findNextItem = (itemsToFind: Category[] | SubCategory[] | Ability[], currentItemId: string) => {
  return itemsToFind[itemsToFind.findIndex((item) => item._id === currentItemId) + 1]
}

const findFirstAbilityInCategoryAccordingToGrade = (category: Category, grade: number) => {
  return filterAbilitiesByGrade(filterSubcategoriesByGrade(category.subcategories, grade)[0].abilities, grade)[0]
}

export const getNextPromptedAbility = ({ categories, grade, currentAbilityId }: GetNextAbilitiesArguments) => {
  const filteredCategories = filterCategoriesByGrade(categories, grade)
  const currentCategory = filteredCategories.find((category) => {
    return category.subcategories.find((subcategory) => {
      return subcategory.abilities.find((ability) => ability._id === currentAbilityId)
    })
  })
  if (!currentCategory) return

  const filteredCurrentSubcategories = filterSubcategoriesByGrade(currentCategory.subcategories, grade)
  const currentSubcategory = filteredCurrentSubcategories.find((subcategory) => {
    return subcategory.abilities.find((ability) => ability._id === currentAbilityId)
  })

  if (!currentSubcategory) return

  const nextAbilityInSubcategory = findNextItem(
    filterAbilitiesByGrade(currentSubcategory.abilities, grade),
    currentAbilityId
  ) as Ability
  if (nextAbilityInSubcategory) return nextAbilityInSubcategory

  const nextSubcategory = findNextItem(filteredCurrentSubcategories, currentSubcategory._id) as SubCategory
  if (nextSubcategory) {
    return filterAbilitiesByGrade(nextSubcategory.abilities, grade)[0]
  }

  const nextCategory = findNextItem(filteredCategories, currentCategory._id) as Category
  return findFirstAbilityInCategoryAccordingToGrade(nextCategory || filteredCategories[0], grade)
}

export const filteredGradesAccordingToExistedAbility = (grades: number[], categories: Category[]) => {
  return grades.filter((grade) => {
    return categories.some((category: Category) => {
      return category.subcategories.some((subcategory: SubCategory) => {
        return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
      })
    })
  })
}

const getVisiblePart: (element: Element, scrollAreaElement: HTMLElement) => VisiblePart = (
  element,
  scrollAreaElement
) => {
  const rect = element.getBoundingClientRect()
  const scrollAreaHeight: number = scrollAreaElement.offsetHeight
  const topDistance = rect.top - scrollAreaElement.offsetTop
  const bottomDistance = rect.bottom - scrollAreaElement.offsetTop
  if (topDistance < 0 && bottomDistance > scrollAreaHeight) {
    return { element, height: rect.height, isFullPlacedOnScrollArea: false }
  }

  if (bottomDistance > scrollAreaHeight) {
    return { element, height: scrollAreaHeight - topDistance, isFullPlacedOnScrollArea: false }
  }

  if (topDistance >= 0) {
    return { element, height: rect.height, isFullPlacedOnScrollArea: true }
  }

  return { element, height: rect.height - Math.abs(topDistance), isFullPlacedOnScrollArea: false }
}

export const getVisibleElements = (elements: Element[], scrollAreaTop: number, scrollAreaHeight: number) => {
  return elements.filter((element) => {
    const rect = element.getBoundingClientRect()
    const top = rect.top - scrollAreaTop
    const bottom = rect.bottom - scrollAreaTop
    return top < scrollAreaHeight && bottom > 0
  })
}

export const getCorrectGradeName = (grade: number, t: TFunction) => {
  if (grade === 0) {
    return t('zeroGradeText')
  }

  return grade
}

export const findFirstUnsolved = (
  items: PracticeSkill[] | Ability[],
  statistics: PracticeStatistics | SkillStatisticsMap | null = {}
) => {
  const foundSkill = items.find(({ _id: id }) => {
    const stats = statistics?.[id]
    const progress = typeof stats === 'number' ? stats : calculateSkillProgress(stats)
    return !progress || progress < CorrectAnswerRatePercentage.ONE_STAR
  })

  if (foundSkill) return foundSkill._id
  return null
}
