import { AnswerType } from '../../../features/Problem/types.answer'

// Workaround for hiding additional pair of [] brackets from handwriting answer type
export const hideHardBracketsFromKatex = (answerType: AnswerType = 0) => {
  const katex = document.querySelectorAll('.katex')

  katex.forEach((katexBlock) => {
    if (answerType !== AnswerType.HANDWRITING) return

    katexBlock.querySelectorAll('.mclose').forEach((mclose) => {
      if (mclose.textContent?.includes(']')) {
        mclose.setAttribute('style', 'display: none;')
      }
    })

    katexBlock.querySelectorAll('.mopen').forEach((mopen) => {
      if (mopen.textContent?.includes('[')) {
        mopen.setAttribute('style', 'display: none;')
      }
    })
  })
}
