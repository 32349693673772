import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { Category, ChangeGradeActions } from '../../types'

import PracticeNavigationCategory from './PracticeNavigationMainBlock/PracticeNavigationCategory'
import GradeSelectorControl from './YearSelectorControl/GradeSelectorControl'
import { getCorrectGradeName } from '../../practice.helpers'

type PracticePageNavigationMenuProps = {
  grade: number
  categories: Category[]
  currentCategoryId: string
  getIsChangeGradeDisabled: (changeAction: ChangeGradeActions) => boolean
  onChangeGrade: (changeAction: ChangeGradeActions) => void
}

const PracticePageNavigationMenu = ({
  grade,
  categories,
  currentCategoryId,
  getIsChangeGradeDisabled,
  onChangeGrade,
}: PracticePageNavigationMenuProps) => {
  const { t } = useTranslation()
  const [openedCategoryId, setOpenedCategoryId] = useState(currentCategoryId)

  return (
    <div className={styles.NavigationMenuContainer}>
      <div className={styles.YearSelector}>
        <GradeSelectorControl
          isDisabled={getIsChangeGradeDisabled(ChangeGradeActions.DECREASE)}
          onClick={() => onChangeGrade(ChangeGradeActions.DECREASE)}
        />
        <span className={styles.YearSelectorTitle}>{getCorrectGradeName(grade, t)}</span>
        <GradeSelectorControl
          isDisabled={getIsChangeGradeDisabled(ChangeGradeActions.INCREASE)}
          onClick={() => onChangeGrade(ChangeGradeActions.INCREASE)}
          isReversed
        />
      </div>
      <div className={styles.CategoriesListContainer}>
        {categories.map((category) => (
          <PracticeNavigationCategory
            category={category}
            openedCategoryId={openedCategoryId}
            openCategory={setOpenedCategoryId}
            grade={grade}
            key={category._id}
          />
        ))}
      </div>
    </div>
  )
}

export default PracticePageNavigationMenu
