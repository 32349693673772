import React from 'react'
import { IconProps } from './_types'
import { COLORS } from '@magmamath/ui'

const PlusIcon = ({ size = 35, color = COLORS.NEUTRAL_10, secondaryColor = COLORS.NEUTRAL_1 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='17.5' cy='17.5' r='16.5' fill={secondaryColor} stroke={color} strokeWidth='2' />
      <g clipPath='url(#clip0_0_1)'>
        <path d='M17.4414 5.71484V29.1677' stroke={color} strokeWidth='1.66' strokeLinecap='round' />
        <path d='M5.71484 17.4414H29.1677' stroke={color} strokeWidth='1.66' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_0_1'>
          <rect width='25' height='25' fill='#ffffff' transform='translate(5 5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlusIcon
