import React, { useContext } from 'react'
import './styles.scss'
import { useTextToSpeechStore } from '../../../TextToSpeech/model'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { COLORS } from '@magmamath/ui'

import { ReactComponent as ExitIcon } from '../../../../assets/images/exit.svg'
import { GlobeIcon } from '../../../../ui/icons/Globe'
import TextToSpeech from '../../../TextToSpeech/TextToSpeech'
import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../../../constants/defaults'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { nativeLanguageTagMap } from 'src/config/allLanguages'
import { IconButton } from '../../../../ui/IconButton'
import { ExerciseThemeVariantMapper } from '../../../Problem/problem.style.mode.helpers'
import { getUserType } from '../../../../helpers/users/getUserInfo'
import { TEACHER } from '../../../../constants/userTypes'
import { useCurrentExerciseMode } from '../../../Problem/models/exerciseMode'

// TODO: rewrite
const DescriptionModal = ({ isOpen, onClose, textToSpeechContent, onGlobeIconClick, children }) => {
  const { state: userSettings } = useContext(UserSettingsContext)

  const exerciseMode = useCurrentExerciseMode((state) => state.exerciseMode)
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)

  const isTranslationEnabled =
    userSettings.translationLanguage !== NO_LANGUAGE_SELECTED &&
    userSettings.translationLanguage !== DEPRECATED_NONE_LANGUAGE &&
    getUserType() !== TEACHER

  return (
    <Dialog
      aria-labelledby='description-dialog'
      aria-describedby='problem-description'
      open={isOpen}
      onClose={onClose}
      scroll='paper'
      classes={{ paper: 'description-dialog' }}
      fullWidth
      maxWidth='md'
      sx={{ '&.MuiDialog-root': { zIndex: 10020 } }}
    >
      <div className='dialog-container'>
        <button className='icon-button exit-icon' onClick={onClose}>
          <ExitIcon />
        </button>
        <div />

        {userSettings.textToSpeech && <TextToSpeech value={textToSpeechContent} />}

        {isTranslationEnabled && (
          <IconButton
            className='translate-button'
            onClick={onGlobeIconClick}
            isActive={isTranslated}
            rounded='full'
            variant='gray'
            activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}`}
            withShadow
          >
            {isTranslated ? <GlobeIcon fill={COLORS.NEUTRAL_1} stroke={COLORS.NEUTRAL_1} /> : <GlobeIcon />}
          </IconButton>
        )}
      </div>

      <DialogContent classes={{ root: 'dialog-content-root' }}>
        <div className='dialog-content' style={{ fontSize: userSettings.textSize }}>
          <div className='dialog-description'>{children}</div>
        </div>
      </DialogContent>
      <DialogActions />
    </Dialog>
  )
}

export default DescriptionModal
