import React, { useState, useEffect, useCallback, useRef } from 'react'
import './styles.scss'
import Navbar from 'react-bootstrap/Navbar'

import { HEADER_FONT_SIZE } from '../../constants/styleConstants'
import BackButton from './BackButton'
import clsx from 'clsx'

const Header = (props) => {
  const [resize, setResize] = useState(false)
  const headerTextRef = useRef(null)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleResize = useCallback(() => {
    setResize(resizeFontSize(props.title))
  }, [props.title])

  useEffect(() => {
    handleResize()
  }, [handleResize])

  const resizeFontSize = (text) => {
    if (!text) {
      return false
    }

    if (!headerTextRef.current) {
      return false
    }

    const windowWidth = window.innerWidth
    return text.length * HEADER_FONT_SIZE >= windowWidth
  }

  return (
    <Navbar className='header-container'>
      <Navbar.Brand className='header-items'>
        {props.withBackButton && <BackButton />}
        <div className='main-header-item'>
          <span ref={headerTextRef} className={clsx('main-header-text', { resized: resize })}>
            {props.title}
          </span>
        </div>
      </Navbar.Brand>
    </Navbar>
  )
}

export default Header
