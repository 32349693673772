export const PROBLEM_FEEDBACK_VARIANTS = {
  CONFETTI: 'confetti',
  SNOWFALL: 'snowfall',
  FALL: 'fall',
}

export const SNOWFALL_DURATION_IN_MS = 12000
export const SNOWFALL_FADE_OUT_DELAY_IN_MS = 10000

export const FALL_DURATION_IN_MS = 8000
export const FALL_FADE_OUT_DELAY_IN_MS = 6000
