import React, { useEffect } from 'react'
import EmptyAnswerPlaceholder from './EmptyAnswerPlaceholder'
import { answerEditorModel } from '../../Problem/models/editor'
import DrawingRequiredMessage from '../../../components/Problem/DrawingRequiredMessage'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { currentProblemAnswerModel } from '../../Problem/models/answer'
import { answerAreaMyScriptModel } from '../model'

export type MyScriptPlaceholderDrawingProps = {
  message?: string
  className?: string
}

type MyScriptPlaceholderProps = {
  showForceDrawing: boolean
  forceDrawingProps?: MyScriptPlaceholderDrawingProps
  emptyDrawingProps?: MyScriptPlaceholderDrawingProps
}

const MyScriptPlaceholder = ({ showForceDrawing, forceDrawingProps, emptyDrawingProps }: MyScriptPlaceholderProps) => {
  const isPlaceholderVisible = useUnit(answerEditorModel.$isPlaceholderVisible)
  const isNoDrawing = useStoreMap(answerAreaMyScriptModel.$currentDrawing, (drawing) => !drawing)
  const isAnswerEmpty = useStoreMap(currentProblemAnswerModel.$text, (text) => !text)
  const showEmptyPlaceholder = isNoDrawing && isAnswerEmpty && isPlaceholderVisible

  useEffect(() => {
    answerEditorModel.setPlaceholderVisible(isNoDrawing || isAnswerEmpty)
  }, [isAnswerEmpty, isNoDrawing])

  if (showForceDrawing) {
    return (
      <div className={clsx('my-script-force-drawing', forceDrawingProps?.className)}>
        <DrawingRequiredMessage text={forceDrawingProps?.message} />
      </div>
    )
  }

  if (showEmptyPlaceholder) {
    return <EmptyAnswerPlaceholder text={emptyDrawingProps?.message} className={emptyDrawingProps?.className} />
  }

  return null
}

export default MyScriptPlaceholder
