import React from 'react'
import './styles.scss'
import clsx from 'clsx'
import { useOverflowDetection } from '../../../../../hooks/useOverflowDetection'
import { useUnit } from 'effector-react'
import { $toolsMode, ToolsMode } from '../../../../../features/toolsMode/model'

type ChoiceVariantProps = {
  onClick: () => void
  disabled: boolean
  orderIcon: React.ReactElement | null
  content: string | React.ReactElement
  id: string
  textSize: number
  changeTextSize: (isOverflow: boolean) => void
  className?: string
  hasLaTeXFraction?: boolean
}

const SIMPLE_MODE_FONT_INCREMENT = 8

const ChoiceVariant = ({
  onClick,
  disabled,
  orderIcon,
  content,
  id,
  textSize,
  className,
  hasLaTeXFraction,
}: ChoiceVariantProps) => {
  const { parentRef, childRef, isOverflowing } = useOverflowDetection([content])
  const toolsMode = useUnit($toolsMode)

  const getFontSize = () => {
    if (toolsMode === ToolsMode.REGULAR && hasLaTeXFraction && textSize === 32) {
      return 26
    }

    if (toolsMode === ToolsMode.REGULAR) {
      return textSize
    }

    if (hasLaTeXFraction && textSize === 32) {
      return 30
    }

    return textSize + SIMPLE_MODE_FONT_INCREMENT
  }

  return (
    <div
      ref={parentRef}
      onClick={onClick}
      aria-disabled={disabled}
      className={clsx(className, 'answer-variant-container', { leftToRightAnimation: isOverflowing })}
    >
      <div id={id} className='answer-variant-text-and-icon'>
        {orderIcon}
        <div className='answer-variant-text-container'>
          <div
            ref={childRef}
            className='answer-variant-text'
            style={{
              // @ts-ignore set css var dynamically
              '--choice-text-font-size': `${getFontSize()}px`,
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChoiceVariant
