import { MutableRefObject, useEffect } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'
import { cheatDetected } from '../CheatDetection/models/cheatDetected'

import { CheatType } from '../../api/types.cheats'

type CheatDetectionModuleSettings = {
  isCheatingDetectionEnabled?: boolean
}

export const useDrawBoardCheatingDetection = (
  boardRef: MutableRefObject<BoardController | null>,
  { isCheatingDetectionEnabled }: CheatDetectionModuleSettings = {}
) => {
  useEffect(() => {
    if (!boardRef.current) return

    if (isCheatingDetectionEnabled) {
      boardRef.current?.model.text?.input.setPasteDisabled(true)
      boardRef.current?.model.text?.inputChanged.watch(({ prevValue, value, change }) => {
        const inputDiff = value.length - prevValue.length
        const changeValue = change ?? ''

        if (inputDiff >= 0 && changeValue.length > 1) {
          return cheatDetected(CheatType.TEXT_REPLACEMENT)
        }

        return
      })

      return
    }

    boardRef.current?.model.text?.input.setPasteDisabled(false)
  }, [boardRef, isCheatingDetectionEnabled])
}
