export const RoutesPaths = {
  INDEX: '/',
  LOGIN: '/login',
  AUTOLOGIN: '/autologin',
  REGISTER: '/register',
  PRACTICE: '/practice',
  PRACTICE_PAGE: '/practice/exercise',
  PROFILE: '/profile',
  EXERCISES: '/exercises',
  EXERCISES_AVAILABLE: '/exercises/available',
  EXERCISE: '/exercises/available/:id',
  EXERCISE_PAGE: '/exercise/',
} as const
