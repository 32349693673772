import React from 'react'
import { IconProps } from './types'

export const SimpleModeTrashIcon = ({ fill = 'white' }: IconProps) => (
  <svg width='45' height='37' viewBox='0 0 45 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.7203 3.59514C18.2325 3.08292 18.9272 2.79517 19.6516 2.79517H25.3484C26.0728 2.79517 26.7675 3.08292 27.2797 3.59514L30.5767 6.89207H38.2048C38.9591 6.89207 39.5705 7.50349 39.5705 8.25771C39.5705 9.01193 38.9591 9.62335 38.2048 9.62335H35.3302L33.0039 30.5603C32.7734 32.6351 31.0196 34.2048 28.9321 34.2048H16.068C13.9804 34.2048 12.2267 32.6351 11.9961 30.5603L9.66981 9.62335H6.7952C6.04098 9.62335 5.42957 9.01193 5.42957 8.25771C5.42957 7.50349 6.04098 6.89207 6.7952 6.89207H14.4234L17.7203 3.59514ZM18.286 6.89207H26.7141L25.3484 5.52644H19.6516L18.286 6.89207ZM12.4179 9.62335L14.7107 30.2587C14.7876 30.9503 15.3721 31.4735 16.068 31.4735H28.9321C29.6279 31.4735 30.2125 30.9503 30.2893 30.2587L32.5822 9.62335H12.4179Z'
      fill={fill}
    />
  </svg>
)
