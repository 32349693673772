import React, { useState, useEffect, useContext, ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import styles from './styles.module.scss'

import { AppLanguages, LanguageTag, nativeLanguageTagMap } from '../../config/allLanguages'
import { DEPRECATED_NONE_LANGUAGE, NO_LANGUAGE_SELECTED } from '../../constants/defaults'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'

import TextToSpeechIcon from './TextToSpeechIcon'
import { useTextToSpeechStore } from './model'
import { IconButton, IconButtonProps } from '../../ui/IconButton'
import { ExerciseThemeVariantMapper } from '../Problem/problem.style.mode.helpers'
import { useCurrentExerciseMode } from '../Problem/models/exerciseMode'
import { useTextToSpeechAudio } from './useTextToSpeechAudio'
import { useOnProblemChange } from '../assignment/hooks/useOnProblemChange'

interface TextToSpeechProps {
  value?: string
  isFillButtonToContainer?: boolean
  className?: string
  variant?: IconButtonProps['variant']
  PlayIcon?: ElementType
  PauseIcon?: ElementType
  SpeakerIcon?: ElementType
}

const TextToSpeech: React.FC<TextToSpeechProps> = ({
  value = '',
  isFillButtonToContainer,
  className,
  variant = 'gray',
  PlayIcon,
  PauseIcon,
  SpeakerIcon,
}) => {
  const { i18n } = useTranslation()
  const { state: userSettings } = useContext(UserSettingsContext)
  const { exerciseMode } = useCurrentExerciseMode()
  const isTranslated = useTextToSpeechStore((state) => state.isTranslated)

  const [languageSettings, setLanguageSettings] = useState({ languageCode: '', voiceName: '' })

  const { audio, pauseAudio, resetAudio, fetchGoogleSpeech, isFetching, isPlaying } = useTextToSpeechAudio(
    value,
    languageSettings
  )

  useOnProblemChange(() => resetAudio())

  useEffect(() => {
    const getLanguageKey = (lang: string) => lang as LanguageTag

    const isTranslationLangAvailable =
      userSettings.translationLanguage !== NO_LANGUAGE_SELECTED &&
      userSettings.translationLanguage !== DEPRECATED_NONE_LANGUAGE
    const appLanguage = AppLanguages[i18n.language as keyof typeof AppLanguages]
    const translationLang = isTranslationLangAvailable ? userSettings.translationLanguage : appLanguage

    const appLanguageSettings = nativeLanguageTagMap[getLanguageKey(appLanguage)]
    const langSettings = nativeLanguageTagMap[getLanguageKey(isTranslated ? translationLang : appLanguage)]

    setLanguageSettings({
      languageCode: langSettings?.languageCode ?? appLanguageSettings?.languageCode,
      voiceName: langSettings?.voiceName ?? appLanguageSettings?.voiceName,
    })
    resetAudio()
  }, [i18n.language, isTranslated, userSettings.translationLanguage])

  useEffect(() => {
    resetAudio()

    return () => {
      resetAudio()
    }
  }, [value])

  const onClick = async () => {
    if (isPlaying) return pauseAudio()
    await fetchGoogleSpeech()
  }

  return (
    <IconButton
      onClick={onClick}
      className={clsx(className ?? styles.TextToSpeechButton, {
        [styles.FillButtonIntoContainer]: isFillButtonToContainer,
      })}
      isActive={isPlaying}
      rounded='full'
      variant={variant}
      disabled={isFetching}
      activeVariant={`active-${ExerciseThemeVariantMapper[exerciseMode]}` as IconButtonProps['activeVariant']}
      withShadow
    >
      <TextToSpeechIcon
        audio={audio}
        isPlaying={isPlaying}
        isFetching={isFetching}
        PauseIcon={PauseIcon}
        PlayIcon={PlayIcon}
        SpeakerIcon={SpeakerIcon}
      />
    </IconButton>
  )
}

export default TextToSpeech
