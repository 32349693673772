import { useUnit } from 'effector-react'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../Problem/models/answer'

export const useAssignmentStatus = () => {
  const status = useUnit(currentProblemAnswerModel.$status)
  return {
    success: status === ProblemAnswerStatus.SUCCESS || status === ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT,
    successFirstAttempt: status === ProblemAnswerStatus.SUCCESS_FIRST_ATTEMPT,
    mistake: status === ProblemAnswerStatus.MISTAKE,
  }
}
