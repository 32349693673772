//Function for translating numbers (only one digit numbers for now) that are written out in problem describtion
var translateWordsToNumSwe = function (problem) {
  //For swedish numbers we use following dict
  const swedishNumberWords = {
    ' noll ': 0,
    ' ett ': 1,
    ' två ': 2,
    ' tre ': 3,
    ' fyra ': 4,
    ' fem ': 5,
    ' sex ': 6,
    ' sju ': 7,
    ' åtta ': 8,
    ' nio ': 9,
    ' tio ': 10,
  }

  const regex = new RegExp(Object.keys(swedishNumberWords).join('|'), 'gi')

  return problem.replace(regex, (match) => {
    return swedishNumberWords[match.toLowerCase()]
  })
}
exports.translateWordsToNumSwe = translateWordsToNumSwe

//Function for translating numbers (only one digit numbers for now) that are written out in problem describtion
var translateWordsToNumEng = function (problem) {
  //For english translation we use built-in function
  const wordsToNumbers = require('words-to-numbers').wordsToNumbers

  const regex_eng = /\b(?:zero|one|two|three|four|five|six|seven|eight|nine|ten|[-\s])*?\b/g

  return problem.replace(regex_eng, (match) => {
    const converted = wordsToNumbers(match)
    return converted !== null ? converted : match
  })
}
exports.translateWordsToNumEng = translateWordsToNumEng

var basicTrim = function (string) {
  const EMPTY_ELEMENT_REGEXP = /<span class='empty-element'><\/span>/g
  const ARRAY_BEGIN_REGEXP = /\\begin\{array\}\{[a-zA-Z]\}/g
  const ARRAY_END_REGEXP = /end\{array\}/g
  const ALING_BEGIN_REGEXP = /begin\{aligned\}/g
  const ALING_END_REGEXP = /end\{aligned\}/g
  return string
    .replace(EMPTY_ELEMENT_REGEXP, '')
    .replace(ARRAY_BEGIN_REGEXP, '')
    .replace(ARRAY_END_REGEXP, '')
    .replace(ALING_BEGIN_REGEXP, '')
    .replace(ALING_END_REGEXP, '')
    .replace(/\\/g, ' \\')
}
exports.basicTrim = basicTrim

//Function for translating mixed fraction: a[b/c]
var translateMixedFrac = function (frac_string) {
  const pattern = /(\d+)\[(\d+)\/(\d+)\]/
  const match = frac_string.match(pattern)

  if (match) {
    const a = match[1]
    const b = match[2]
    const c = match[3]

    const sum_ab = parseInt(a) + parseInt(b)

    // Format the output strings
    const string_a = `${sum_ab}/${c}`
    const string_b = `${a}'('${b}/${c})`

    return [string_a, string_b]
  } else {
    throw new Error('Input string is not in the expected format')
  }
}
exports.translateMixedFrac = translateMixedFrac

//Funtion for converting list of int/float to strings
var convertNumbersToStrings = function (list) {
  return list.map((item) => {
    if (typeof item === 'number') {
      return item.toString().replace(',', '.')
    }
    return item
  })
}
exports.convertNumbersToStrings = convertNumbersToStrings

//Function for removing all digits and operators that are also present in answer/problem description in store them in new list
var removeCommon = function (removeDigits, removeOps, digits, ops) {
  //Make sure digits from answer/problem are in string format
  removeDigits = convertNumbersToStrings(removeDigits)
  let setAnsDigs = new Set(removeDigits)
  let setDigs = new Set(digits)
  let commonDigs = new Set([...setAnsDigs].filter((x) => setDigs.has(x)))

  if (commonDigs.size > 0) {
    let checkDigDone = false
    while (!checkDigDone) {
      for (let aDig of removeDigits.filter((removeDig) => digits.includes(removeDig))) {
        for (let dig of digits) {
          if (dig === aDig) {
            removeDigits.splice(removeDigits.indexOf(aDig), 1)
            digits.splice(digits.indexOf(dig), 1)
            break
          }
        }
        break
      }
      if (removeDigits.length === 0) {
        checkDigDone = true
      }
      setAnsDigs = new Set(removeDigits)
      setDigs = new Set(digits)
      commonDigs = new Set([...setAnsDigs].filter((x) => setDigs.has(x)))
      if (commonDigs.size === 0) {
        checkDigDone = true
      }
    }
  }

  let setAnsOps = new Set(removeOps)
  let setOps = new Set(ops)
  let commonOps = new Set([...setAnsOps].filter((x) => setOps.has(x)))

  if (commonOps.size > 0) {
    let checkOpsDone = false
    while (!checkOpsDone) {
      for (let aOp of removeOps.filter((removeOp) => ops.includes(removeOp))) {
        for (let op of ops) {
          if (op === aOp) {
            removeOps.splice(removeOps.indexOf(aOp), 1)
            ops.splice(ops.indexOf(op), 1)
            break
          }
        }
        break
      }
      if (removeOps.length === 0) {
        checkOpsDone = true
      }
      setAnsOps = new Set(removeOps)
      setOps = new Set(ops)
      commonOps = new Set([...setAnsOps].filter((x) => setOps.has(x)))
      if (commonOps.size === 0) {
        checkOpsDone = true
      }
    }
  }

  return [digits, ops]
}
exports.removeCommon = removeCommon

//Get all digits, variables and operators present in solution string
var get_math = function (string, problemOps = []) {
  // Regular expression to match numbers (including decimal numbers)
  const digitRegex = /-?\d+([.,]\d+)?/g
  // List of operators
  const BASIC_OPERATORS = ['=', '+', '-', '*', '/']
  const EXTENDED_OPERATORS = [...BASIC_OPERATORS, '^']
  const opsLs = problemOps.includes('^') ? EXTENDED_OPERATORS : BASIC_OPERATORS
  const escapedOps = opsLs.map((op) => `\\${op}`).join('')
  const operatorRegex = new RegExp(`[${escapedOps}]`, 'g')
  // List of variables
  const variables = ['x', 'y', 'z', 'a', 'b']
  const variableRegex = new RegExp(`[${variables.join('')}]`, 'g')

  // Arrays to store the matched tokens
  let all_tokens = []
  let ops = []
  let digits = []
  let vars = []

  // Use matchAll to find all matches of numbers first
  let numberMatches = [...string.matchAll(digitRegex)]
  let lastIndex = 0

  numberMatches.forEach((numberMatch) => {
    // Add all characters between lastIndex and numberMatch.index as operators if they match
    for (let i = lastIndex; i < numberMatch.index; i++) {
      let char = string[i]
      if (opsLs.includes(char)) {
        all_tokens.push(char.replace(',', '.'))
        ops.push(char)
      } else if (variables.includes(char)) {
        all_tokens.push(char)
        vars.push(char)
      }
    }
    // Add the number
    all_tokens.push(numberMatch[0].replace(',', '.'))
    digits.push(numberMatch[0].replace(',', '.'))
    lastIndex = numberMatch.index + numberMatch[0].length
  })

  // Add any remaining operators and variables after the last number
  for (let i = lastIndex; i < string.length; i++) {
    let char = string[i]
    if (operatorRegex.test(char)) {
      all_tokens.push(char)
      ops.push(char)
    } else if (variables.includes(char)) {
      all_tokens.push(char)
      vars.push(char)
    }
  }

  // Process tokens to split elements that start with '-'
  const all_final = []
  const digits_final = []

  all_tokens.forEach((element) => {
    // Check if the element matches the pattern "-x"
    if (element.startsWith('-') && element.length > 1) {
      // Split the element into "-" and the rest
      all_final.push('-', element.substring(1))
      ops.push('-')
    } else {
      // If it doesn't match, keep the element as is
      all_final.push(element)
    }
  })

  digits.forEach((element) => {
    // Check if the element matches the pattern "-x"
    if (element.startsWith('-')) {
      // Replace "-x" with "x"
      digits_final.push(element.substring(1))
    } else {
      // If it doesn't match, keep the element as is
      digits_final.push(element)
    }
  })

  return { all_final, digits_final, ops, vars }
}
exports.get_math = get_math

//Check the ratio of characters in solution string matching format: digit (variable) operator digit (variable) ...
var ratioFormatMatches = function (all_math) {
  const digitRegex = /^-?\d+(\.\d+)?$/
  const opsLs = ['=', '+', '-', '*', '/', '^']
  const variables = ['x', 'y', 'z', 'a', 'b']
  let countValidTransitions = 0
  let totalTransitions = 0

  for (let i = 1; i < all_math.length; i++) {
    if (
      (digitRegex.test(all_math[i - 1]) && (variables.includes(all_math[i]) || opsLs.includes(all_math[i]))) ||
      (variables.includes(all_math[i - 1]) && opsLs.includes(all_math[i])) ||
      (opsLs.includes(all_math[i - 1]) && (digitRegex.test(all_math[i]) || variables.includes(all_math[i])))
    ) {
      countValidTransitions++
    }
    totalTransitions++
  }

  return totalTransitions > 0 ? countValidTransitions / totalTransitions : 0
}
exports.ratioFormatMatches = ratioFormatMatches

//Get features of solution string
var featuresString = function (string, answerLs, problemOps, problemDigits, answerOps, answerDigits, check_round) {
  //Features of solution string
  let strContent = {
    digits: [], //All digits present in solution (currently not used)
    ops: [], //All operators present in solution (currently not used)
    digitsNoProb: [], //All digits present in solution and not in problem description
    opsNoProb: [], //All operators present in solution and not in problem description
    digitsNoAns: [], //All digits present in solution and not in answer
    opsNoAns: [], //All operators present in solution and not in answer
    ratio: 0, //Ratio of digits and operators presented in correct format: "digit, operator, digit, operator..."
    prob: 0, //1 if both digits and operators from problem describtion are present in solution
    ans: 0, //1 if answer is present in solution
    eq: 0, //1 if "=" is present in solution
  }

  //Get all digits and operators used in solution string (excluding variables)
  const all_digits_ops = get_math(string, (problemOps = problemOps))
  let all_math = [...all_digits_ops.all_final]
  let digits = [...all_digits_ops.digits_final]
  let ops = [...all_digits_ops.ops]

  //Remove 0.0 from digits
  if (digits.includes(0.0) && digits.length > 1) {
    digits = digits.filter((digit) => digit !== 0.0)
  }

  //Get ratio of slution string matching format "digit, operator, digit, operator..." using function from previous model
  if (all_math.length >= 3) {
    strContent.ratio = ratioFormatMatches(all_math)
  } else {
    strContent.ratio = 0
  }

  //If there are variables in answer we need to remove all spaces when looking for answer
  if (/[a-zA-Z]/.test(answerLs[0])) {
    if (answerLs.some((shortString) => string.replace(/ /g, '').includes(shortString))) {
      strContent.ans = 1
    }
  } else {
    //If there are no variables or operators in answer its a digit and we can look for it among the digits
    //of the solution right away
    if (answerOps.length === 0) {
      //Check for rounded answer, accept any digit within 10 as the asnwer
      if (check_round) {
        let round_answer = parseFloat(answerLs[0], 10)
        if (
          digits.some((digit) => {
            const numDigit = parseInt(digit, 10)
            return Math.abs(numDigit - round_answer) <= 5
          })
        ) {
          strContent.ans = 1
        }
      } //Check if digits include answer
      else {
        if (digits.includes(answerLs[0])) {
          strContent.ans = 1
        }
      } //If there is more than one fraction, we look for answer digit only when its not included in a fraction
    }
    //If answer has operators but no variables its a fraction and we look for it in the string
    else {
      //We look for answer either after a "=" or " "
      if (answerLs.some((shortString) => string.split(' ').includes(shortString))) {
        strContent.ans = 1
      } else {
        if (answerLs.some((shortString) => string.split('=').includes(shortString))) {
          strContent.ans = 1
        }
      }
    }
  }

  //Check if there are common digits and operators in problem describtion/solution
  problemDigits = convertNumbersToStrings(problemDigits)
  //Set with digits present in both solution and problem describtion
  const sameDigit = new Set(digits).intersection(new Set(problemDigits))
  //Set with operators present in both solution and problem describtion
  const sameOps = new Set(ops).intersection(new Set(problemOps))
  //If 75% of digits from the problem describtion are in solution we ignore operators
  if (sameDigit.size > 0.75 * problemDigits.length) {
    strContent.prob = 1
  } else {
    //Else we look for at least one digit and one operator
    if (problemOps.length > 0 && problemDigits.length > 0) {
      if (sameDigit.size > 0 && sameOps.size > 0) {
        strContent.prob = 1
      }
    } else {
      if (sameDigit.size > 0) {
        strContent.prob = 1
      }
    }
  }
  //If there are no operators in problem describtion we only look for common digits

  //Assign the value of all digits and operators before we modify them
  strContent.digits = [...digits]
  strContent.ops = [...ops]

  //If answer is present we store an additinal list of digits and operators that are present in solution but not present in the answer
  if (strContent.ans === 1) {
    let noAnsData = removeCommon(answerDigits, answerOps, [...digits], [...ops])
    strContent.digitsNoAns = noAnsData[0]
    strContent.opsNoAns = noAnsData[1]
  }

  let noProbData = removeCommon(problemDigits, problemOps, [...digits], [...ops])
  strContent.digitsNoProb = noProbData[0]
  strContent.opsNoProb = noProbData[1]

  return strContent
}
exports.featuresString = featuresString

//Process entire solution data (string, answer, problem describtion)
var featuresSolution = function (string, answer, problem) {
  //List for storing all possible formats of answer that could be present in solution
  let answerLs = []
  //Operators we will consider from answer and problem dedscribtion
  //NOTE: "=" not considered as operator from problem
  const opsLs = ['=', '+', '-', '*', '/']
  const opsLsProb = ['+', '-', '^']

  //List of variables we will consider from problem describtion/answer
  const varLs = ['x', 'a', 'b', 'y']

  const hspaceRegex = /\\hspace\{[^}]*\}/g
  problem = problem.replace(hspaceRegex, '')
  //Make sure all decimal notantions have the same format
  problem = problem.replace(/{,}/g, '.')
  problem = problem.replace(/{\.}/g, '.')
  problem = problem.replace(/,/g, '.')
  answer = answer.replace(/,/g, '.')
  string = string.replace(/,/g, '.')

  //Translate one-digit numbers written in text i problem
  if (/[öäå]/gi.test(problem)) {
    problem = translateWordsToNumSwe(problem)
  } else {
    problem = translateWordsToNumEng(problem)
  }

  answer = answer.replace(/^\[.\]/, '')

  //Store all digits and operators present in answer
  let answerOps = [...answer].filter((char) => opsLs.includes(char))
  let answerDigits = [...answer.matchAll(/-?\d+(\.\d+)?/g)].map((match) => parseFloat(match[0]))
  if (answerOps.includes('-')) {
    answerDigits = [...answer.matchAll(/\d+(\.\d+)?/g)].map((match) => parseFloat(match[0]))
  }

  //Store all digits and oerators present in problem description
  let problemDigits = get_math(problem).digits_final
  let problemOps = [...problem].filter((char) => opsLsProb.includes(char))
  //Identify operators in latex format from problem describtion
  let countFrac = (problem.match(/\\frac/g) || []).length
  if (countFrac === 0) {
    countFrac = (problem.match(/\\\\frac/g) || []).length
  }
  let countMult = (problem.match(/cdot/g) || []).length
  countMult += (problem.match(/\\times/g) || []).length
  for (let j = 0; j < countFrac; j++) {
    problemOps.push('/')
  }
  for (let j = 0; j < countMult; j++) {
    problemOps.push('*')
  }

  //Remove 0.0 from answer digits
  if (answerDigits.includes(0.0) && answerDigits.length > 1) {
    answerDigits = answerDigits.filter((digit) => digit !== 0.0)
  }

  const letters = [...answer].filter((char) => /[a-zA-Z]/.test(char))
  if (letters.length > 1) {
    answer = [...answer].filter((char) => !/[a-zA-Z]/.test(char) || varLs.includes(char)).join('')
  } else if (letters.length === 1 && !varLs.includes(letters[0])) {
    answer = answer.replace(/[a-zA-Z]/g, '')
  }
  if (answer.includes('%')) {
    answer = answer.replace(/%/g, '')
  }

  //Check if answer is a fraction
  if (answer.includes('/')) {
    //True if answer is a mixed fraction
    if (answer[0] !== '[') {
      try {
        //If answer is mixed fraction we translate to 2 forms which are common in solution drawings
        const new_answerLs = translateMixedFrac(answer)
        answerLs.push(new_answerLs[0])
        answerLs.push(new_answerLs[1])
      } catch (e) {
        answerLs.push(answer)
      }
    } else {
      const trimLs = ['[', ']']
      answer = Array.from(answer)
        .filter((char) => !trimLs.includes(char))
        .join('')
      answerLs.push(answer)
    }
  } else {
    answerLs.push(answer)
  }
  //Check if problem describtion asks for rounded answer to adjust search for answer in solution string
  let check_round =
    problem.includes('avrunda') || problem.includes('Avrunda') || problem.includes('round') || problem.includes('Round')

  //Get features of solution string
  let stringFeatures = featuresString(string, answerLs, problemOps, problemDigits, answerOps, answerDigits, check_round)

  return stringFeatures
}
exports.featuresSolution = featuresSolution

module.exports.featuresSolution = function (string, answer, problem) {
  return featuresSolution(string, answer, problem)
}
