import React from 'react'

import styles from './styles.module.scss'

import CircleLoader from './CircleLoader'

const GlobalCircleLoader = () => {
  return (
    <div className={styles.GlobalCircleLoaderContainer}>
      <CircleLoader />
    </div>
  )
}

export default GlobalCircleLoader
