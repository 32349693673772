import React from 'react'
import './styles.scss'
import { CalculatorPalletProps } from '@magmamath/react-native-draw-board'
import GeogebraCalculator from '../GeogebraCalculator'

const ScientificCalculator = React.memo((props: CalculatorPalletProps) => {
  return (
    <GeogebraCalculator
      {...props}
      perspective={'A'}
      size={{ width: 400, height: 350 }}
      className={'scientific-calculator'}
      id={'scientific-calculator'}
    />
  )
})

export default ScientificCalculator
