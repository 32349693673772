import React from 'react'

import StarRate from '../../../../ui/basic/StarRate'
import { CorrectAnswerRatePercentage } from '../../constants'

type CardProgressProps = {
  statistic: number
  size?: number
}

const CardProgress = ({ statistic, size }: CardProgressProps) => {
  return (
    <>
      <StarRate isFilled={statistic >= CorrectAnswerRatePercentage.ONE_STAR} size={size} />
      <StarRate isFilled={statistic >= CorrectAnswerRatePercentage.TWO_STARS} size={size} />
      <StarRate isFilled={statistic >= CorrectAnswerRatePercentage.THREE_STARS} size={size} />
    </>
  )
}

export default CardProgress
