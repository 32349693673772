import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import type { AnswerChoiceOption } from './types'
import OfflineModeAnswerAreaIcon from '../../OfflineModeAnswerAreaIcon'
import { ChoiceInput } from './ChoiceInput/ChoiceInput'
import { ChoiceTextToSpeech } from './ChoiceTextToSpeech/ChoiceTextToSpeech'
import { ChoiceDrawingRequired } from './ChoiceDrawingRequired/ChoiceDrawingRequired'
import { ChoiceAnswerFeedback } from './ChoiceAnswerFeedback/ChoiceAnswerFeedback'
import { ChoiceInputTypeSelector } from './ChoiceInputTypeSelector/ChoiceInputTypeSelector'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { useTranslateChoiceOptions } from './hooks/useTranslateChoiceOptions'
import { $toolsMode, ToolsMode } from '../../../../features/toolsMode/model'
import { useUnit } from 'effector-react'
import clsx from 'clsx'
import { ProblemAnswerStatus } from '../../../../features/Problem/models/answer'

type AnswerChoiceProps = {
  answerOptions: AnswerChoiceOption[]
  checkIsOptionSelected: (option: AnswerChoiceOption) => boolean
  getSelectedItemOrder?: (text: string) => number
  handleSelectAnswer: (option: AnswerChoiceOption) => void
  isDrawingRequired: boolean
  isSubmitDisabled: boolean
  isOptionDisabled: boolean
  showAnswerPreview: boolean
  showNumberIcons?: boolean
  submitAnswer: () => void
}

export const AnswerChoice = ({
  answerOptions,
  checkIsOptionSelected,
  getSelectedItemOrder,
  handleSelectAnswer,
  isDrawingRequired,
  isSubmitDisabled,
  isOptionDisabled,
  showAnswerPreview,
  showNumberIcons = false,
  submitAnswer,
}: AnswerChoiceProps) => {
  const { offlineMode, wiggleButton, answerResultStatus } = useContext(AnswerInfoContext)
  const [isPressed, setIsPressed] = useState(false)
  const { isTranslationPending } = useTranslateChoiceOptions(answerOptions)
  const toolsMode = useUnit($toolsMode)
  const hasAnswerExam = answerResultStatus === ProblemAnswerStatus.EXAM_MODE

  if (offlineMode) {
    return (
      <div className={styles.OfflineAnswerChoice}>
        <OfflineModeAnswerAreaIcon />
      </div>
    )
  }

  return (
    <div className={styles.AnswerChoiceContainer}>
      {isDrawingRequired && toolsMode === ToolsMode.REGULAR && <ChoiceDrawingRequired isPressed={isPressed} />}

      <ChoiceInput
        answerOptions={answerOptions}
        checkIsOptionSelected={checkIsOptionSelected}
        getSelectedItemOrder={getSelectedItemOrder}
        handleSelectAnswer={handleSelectAnswer}
        handleSubmitAnswer={submitAnswer}
        isSelectingDisabled={isOptionDisabled}
        onPress={() => setIsPressed(true)}
        onPressRelease={() => setIsPressed(false)}
        showNumberIcons={showNumberIcons}
        isTranslationPending={isTranslationPending}
      />

      <ChoiceTextToSpeech answerOptions={answerOptions} isTranslationPending={isTranslationPending} />

      <div className={styles.AnswerChoiceSubmitWrapper}>
        <ChoiceAnswerFeedback
          showAnswerPreview={showAnswerPreview}
          className={clsx({
            [styles.ChoiceAnswerFeedbackSimple]: toolsMode === ToolsMode.SIMPLE,
          })}
          textClassName={clsx({
            [styles.ChoiceAnswerFeedbackText]: toolsMode === ToolsMode.SIMPLE,
          })}
        />

        {isDrawingRequired && toolsMode === ToolsMode.SIMPLE && !hasAnswerExam && (
          <ChoiceDrawingRequired isPressed={isPressed} className={styles.ChoiceDrawingRequiredSimple} />
        )}

        <ChoiceInputTypeSelector
          isSubmitDisabled={isSubmitDisabled}
          submitAnswer={submitAnswer}
          wiggleAnimation={!!wiggleButton}
        />
      </div>
    </div>
  )
}
