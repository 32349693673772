import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type TextToSpeechState = {
  isTranslated: boolean
}

export type TextToSpeechModel = TextToSpeechState & {
  setIsTranslated: (isTranslated: boolean) => void
}

const initialState = {
  isTranslated: false,
}

export const useTextToSpeechStore = create(
  immer<TextToSpeechModel>((set) => ({
    ...initialState,
    setIsTranslated: (isTranslated: boolean) =>
      set((state: TextToSpeechModel) => {
        state.isTranslated = isTranslated
      }),
  }))
)
