type Config = {
  database: string
  store: string
}

export class IndexedDB<T extends { id: string }> {
  private readonly database: string
  private readonly store: string

  constructor(config: Config) {
    this.database = config.database
    this.store = config.store
  }

  private async openDB(): Promise<IDBDatabase> {
    try {
      return await new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open(this.database)

        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result
          if (!db.objectStoreNames.contains(this.store)) {
            db.createObjectStore(this.store, { keyPath: 'id' })
          }
        }

        request.onsuccess = (event) => {
          resolve((event.target as IDBOpenDBRequest).result)
        }

        request.onerror = (event) => {
          reject((event.target as IDBRequest).error)
        }
      })
    } catch (error) {
      console.error(`Error opening ${this.database} database:`, error)
      throw error
    }
  }

  async save(data: Omit<T, 'id'>): Promise<string> {
    try {
      const id = crypto.randomUUID()
      const db = await this.openDB()
      const transaction = db.transaction([this.store], 'readwrite')
      const store = transaction.objectStore(this.store)

      await new Promise<void>((resolve, reject) => {
        const request = store.put({ id, data, createdAt: Date.now() })
        request.onsuccess = () => resolve()
        request.onerror = () => reject(request.error)
      })

      return id
    } catch (error) {
      console.error(`Error saving data in ${this.store}:`, error)
      throw error
    }
  }

  async get(id: string): Promise<T | null> {
    try {
      const db = await this.openDB()
      const transaction = db.transaction([this.store], 'readonly')
      const store = transaction.objectStore(this.store)
      const request = store.get(id)

      return await new Promise<T | null>((resolve, reject) => {
        request.onsuccess = () => resolve((request.result as T) || null)
        request.onerror = () => reject(request.error)
      })
    } catch (error) {
      console.error(`Error retrieving data from ${this.store}:`, error)
      return null
    }
  }

  async remove(id: string): Promise<void> {
    try {
      const db = await this.openDB()
      const transaction = db.transaction([this.store], 'readwrite')
      const store = transaction.objectStore(this.store)
      const request = store.delete(id)

      await new Promise<void>((resolve, reject) => {
        request.onsuccess = () => resolve()
        request.onerror = () => reject(request.error)
      })
    } catch (error) {
      console.error(`Error removing item with id ${id} from ${this.store}:`, error)
      throw error
    }
  }
}
