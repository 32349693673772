import React from 'react'
import clsx from 'clsx'
import { AiOutlineDoubleRight } from 'react-icons/ai'

import styles from './styles.module.scss'

interface SlideProblemDescriptionProps {
  isRight: boolean
  onClick: () => void
}

const SlideProblemDescriptionButton: React.FC<SlideProblemDescriptionProps> = ({ isRight, onClick }) => {
  return (
    <button
      className={clsx(styles.SlideProblemDescriptionButton, {
        [styles.SlideOutButton]: !isRight,
      })}
      onClick={onClick}
      id='slide-problem-description-button'
    >
      <AiOutlineDoubleRight size={24} />
    </button>
  )
}

export default SlideProblemDescriptionButton
