import { MutableRefObject, useCallback, useEffect, useState, useRef } from 'react'
import { DrawAreaPreset, Magma, useObservable } from '@magmamath/react-native-draw-board'

type UseAutoReplayDrawBoard = {
  isVisible?: boolean
  drawing?: string
}

export function useConst<T>(initGenerator: () => T) {
  const ref = useRef<T>(null) as MutableRefObject<T>
  if (ref.current === null) {
    ref.current = initGenerator()
  }
  return ref.current
}

export const useAutoReplayDrawBoard = ({ isVisible, drawing }: UseAutoReplayDrawBoard = {}) => {
  const preset = useConst(() => new DrawAreaPreset({ disabledGridFitting: true }))
  const [isImportFinished, setIsImportFinished] = useState(false)
  const isBoardAttachedToView = useObservable(preset.model.layers.$isAttached)

  useEffect(() => {
    if (isBoardAttachedToView && isImportFinished) {
      preset.model.historyActions.startPlaying()
    }
  }, [isBoardAttachedToView, isImportFinished, preset])

  useEffect(() => {
    if (!isVisible) {
      preset.model.historyActions.stopAndReset()
    } else {
      preset.model.historyActions.startPlaying()
    }
  }, [preset, isVisible])

  const setDrawing = useCallback(
    (history: string) => {
      if (!history) {
        preset.model.clear.board({ full: true })
        setIsImportFinished(true)
        return
      }

      const value = Magma.compression.server.decompress(history)
      if (value) {
        setIsImportFinished(false)
        preset.model.import({ drawing: value, settings: { setToIndex: -1, bindToDrawingBounds: true } }).then(() => {
          setIsImportFinished(true)
        })
      }
    },
    [preset]
  )

  useEffect(() => {
    if (drawing === undefined) return
    setDrawing(drawing)
  }, [drawing, setDrawing])

  return { preset, setDrawing }
}
