import React from 'react'
import SnowflakesConfetti from 'react-snowfall'
import { SNOWFALL_DURATION_IN_MS, SNOWFALL_FADE_OUT_DELAY_IN_MS } from '../../../../constants/problemFeedback'
import { COLORS } from '@magmamath/ui'

import snowflake from '../../../../assets/images/confettiParticles/snowflake1.png'
import snowflake2 from '../../../../assets/images/confettiParticles/snowflake2.png'
import snowflake3 from '../../../../assets/images/confettiParticles/snowflake3.png'

const snowflakes = [snowflake, snowflake2, snowflake3]

const images = snowflakes.map((snowflake) => {
  const el = document.createElement('img')
  el.src = snowflake
  return el
})

export const Snowfall = () => {
  return (
    <SnowflakesConfetti
      color={COLORS.BLUE_2}
      snowflakeCount={200}
      radius={[10, 30]}
      speed={[2.0, 3.5]}
      wind={[1.0, 3.5]}
      rotationSpeed={[-1.0, 1.0]}
      images={images}
      style={{
        zIndex: 2000,
        opacity: 1,
        animation: 'fadeOut',
        animationDuration: `${SNOWFALL_DURATION_IN_MS - SNOWFALL_FADE_OUT_DELAY_IN_MS}ms`,
        animationDelay: `${SNOWFALL_FADE_OUT_DELAY_IN_MS}ms`,
      }}
    />
  )
}
