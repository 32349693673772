import React from 'react'
import { COLORS } from '@magmamath/ui'

import { IconProps } from './types'
import { getIconSize } from './helpers'

const ArrowFigure = ({ size = 33, stroke = COLORS.NEUTRAL_2 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 108 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M101.128 11.0768C104.73 19.8475 105.101 27.6791 105.101 27.6791C105.101 27.6791 95.1378 24.3144 89.0735 27.8974M100.05 22.4334C63.3778 -4.15589 38.0084 19.6122 49.2902 26.3459C60.5722 33.0798 88.9892 4.75009 45.458 2.98397C33.6243 2.50387 11.1193 12.1461 3.50947 27.8725C2.24154 30.4928 4.34376 29.3995 5.03727 27.0373'
        stroke={stroke}
        strokeWidth='5.36354'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ArrowFigure
