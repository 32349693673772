import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export type IconOrderProps = {
  variant: 'primary' | 'practice' | 'exam'
  style: string
  order: number
}

const IconOrder: React.FC<IconOrderProps> = ({ variant, style, order }) => {
  return (
    <span className={clsx(styles.SelectedIcon, { [styles[style]]: style, [styles[variant]]: variant })}>{order}</span>
  )
}

export default IconOrder
