import React, { FC, useState } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { LOCALIZED_UNITS_SYMBOLS, UNITS_BUTTONS } from './symbols'
import Units from './Units'
import { NumpadVariantProps } from '../types'

const NumpadUnits: FC<NumpadVariantProps> = ({ onPress, isButtonDisabled }) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [currentUnit, setCurrentUnit] = useState('')

  return (
    <div className='numpad-units-container'>
      <div className='units-titles-row'>
        {UNITS_BUTTONS.map(({ title, icon, fullWidth }, index) => (
          <button
            key={`${title}${index}`}
            onClick={() => setCurrentUnit(title)}
            className={clsx('unit', { active: currentUnit === title })}
          >
            <img className={clsx({ 'full-width': fullWidth })} src={icon} alt='unit' />
          </button>
        ))}
      </div>

      <Units
        units={LOCALIZED_UNITS_SYMBOLS[currentLanguage][currentUnit]}
        isButtonDisabled={isButtonDisabled}
        onPress={onPress}
      />
    </div>
  )
}

export default NumpadUnits
