import { useRef, useState } from 'react'
import getGoogleSpeechAudio from './getGoogleSpeechAudio'
import { showErrorToast } from '../../constants/toasts'
import { useTranslation } from 'react-i18next'
import { formatTextToSpeechValue } from './helpers/formatTextToSpeechValue'

type LanguageSettings = {
  languageCode: string
  voiceName: string
}

const DEFAULT_LANGUAGE_SETTINGS: LanguageSettings = {
  languageCode: 'en-US',
  voiceName: 'en-US-Wavenet-C',
}

export const useTextToSpeechAudio = (text: string, languageSettings = DEFAULT_LANGUAGE_SETTINGS) => {
  const { t, i18n } = useTranslation()
  const audioRef = useRef(new Audio())
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const playAudio = () => {
    audioRef.current.play()
    setIsPlaying(true)
  }

  const pauseAudio = () => {
    audioRef.current.pause()
    setIsPlaying(false)
  }

  const toggleAudio = () => {
    if (isPlaying) {
      pauseAudio()
    } else {
      playAudio()
    }
  }

  const resetAudio = () => {
    audioRef.current.pause()
    audioRef.current.currentTime = 0
    audioRef.current = new Audio()
    setIsPlaying(false)
    setIsFetching(false)
  }

  const fetchGoogleSpeech = async () => {
    if (audioRef.current.currentSrc) return playAudio()

    try {
      setIsFetching(true)

      const googleAudio = await getGoogleSpeechAudio({
        text: formatTextToSpeechValue(text, i18n.language),
        languageCode: languageSettings?.languageCode,
        name: languageSettings?.voiceName,
      })

      if (googleAudio) {
        googleAudio.addEventListener('ended', () => pauseAudio())
        audioRef.current = googleAudio

        await audioRef.current.play()
        setIsPlaying(true)
      }
    } catch (error) {
      showErrorToast({
        message: t('textToSpeechErrorMessage'),
        toastId: 'textToSpeechErrorMessage',
      })
    } finally {
      setIsFetching(false)
    }
  }

  return {
    audio: audioRef.current,
    playAudio,
    pauseAudio,
    toggleAudio,
    resetAudio,
    fetchGoogleSpeech,
    isPlaying,
    isFetching,
  }
}
