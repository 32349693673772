import React, { useContext } from 'react'
import { useValidatedDrawings } from '../drawingValidation/hook'
import { DrawingValidationSuccess } from '../drawingValidation/components/DrawingValidationSuccess'
import { MagmaChat } from '../MagmaChat/MagmaChat'
import { useHintMyScriptModel } from '../AnswerHints/model'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { SolutionHint } from './components/SolutionHint'
import { availableChatsModel } from '../MagmaChat/models/availableChatsModel'
import { canUserAccessChat } from './helpers'
import { useOnAssignmentChange } from '../assignment/hooks/useOnAssignmentChange'
import {
  $lastAddedMessageIds,
  removedLastMessage,
  resetChatMessages,
  setHintMessage,
} from '../MagmaChat/models/messages'
import { resetInitialAlternatives } from '../MagmaChat/models/initialAlternatives'
import { useOnProblemChange } from '../assignment/hooks/useOnProblemChange'
import { useUnit } from 'effector-react'
import { fetchChatMessageFx, sendChatMessageFx } from '../MagmaChat/models/fetchChatMessageModel'

type FeedbackSolutionProps = {
  assignmentId: string
  problemId: string
  description: string
  isExamMode: boolean
  height: number
  answerType: number
  assignmentClassGrade?: number
  problemAnswer: string[]
}

const CANVAS_TOOLBAR_HEIGHT = 220

export const FeedbackSolution = ({
  assignmentId,
  problemId,
  description,
  isExamMode,
  height,
  answerType,
  assignmentClassGrade,
  problemAnswer,
}: FeedbackSolutionProps) => {
  const { state: userSettings } = useContext(UserSettingsContext)
  const isDrawingValid = useValidatedDrawings()
  const showHintFeedback = useHintMyScriptModel((state) => state.isOpen && state.hint?.text)
  const isLoading = useUnit(sendChatMessageFx.pending)
  const lastAddedMessageIds = useUnit($lastAddedMessageIds)
  const answerHint = useHintMyScriptModel((state) => state.hint?.text)

  useOnAssignmentChange(() => {
    resetChatMessages()
    resetInitialAlternatives()
    availableChatsModel.reset()
  })

  useOnProblemChange(() => {
    if (answerHint && canUserAccessChat(userSettings, assignmentClassGrade)) {
      setHintMessage({
        message: answerHint,
        problemId,
        assignmentId,
        isOwnMessage: false,
        options: [],
        threadId: '',
      })
    }

    if (isLoading && lastAddedMessageIds) {
      fetchChatMessageFx.controller.abort()
      removedLastMessage({
        assignmentId: lastAddedMessageIds.assignmentId,
        problemId: lastAddedMessageIds.problemId,
      })
    }
  })

  if (isExamMode) return null

  if (isDrawingValid) {
    return <DrawingValidationSuccess />
  }

  if (showHintFeedback) {
    return <SolutionHint assignmentClassGrade={assignmentClassGrade} />
  }

  return (
    <MagmaChat
      key={problemId}
      answerType={answerType}
      description={description}
      isEnabled={canUserAccessChat(userSettings, assignmentClassGrade)}
      height={height - CANVAS_TOOLBAR_HEIGHT}
      problemAnswer={problemAnswer}
    />
  )
}
