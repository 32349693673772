import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const TriangleVolumeFigure = ({ stroke = COLORS.NEUTRAL_2, size = 258 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 265 258' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M165.219 38.059C164.245 32.475 168.309 31.2635 165.543 34.4824C153.852 48.0887 127.014 80.9138 100.331 112.6C68.7694 150.079 37.8769 186.033 38.9886 187.672C41.3882 191.215 161.644 205.196 222.668 215.272C225.406 215.684 211.606 171.842 197.828 127.25C184.834 85.1937 171.953 42.484 169.975 35.1482'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M148.717 156.085C147.888 161.63 149.643 154.684 151.301 143.648C153.583 128.464 155.253 112.596 159.319 83.57C165.491 39.5031 169.962 13.975 171.067 7.34919C172.172 0.723506 175 18.9454 174.537 17.5251C174.076 16.1049 171.992 3.20066 171.565 4.03911C171.032 5.08716 168.775 14.3722 166.299 17.3754'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M151.751 156.035C147.568 152.301 152.418 157.576 160.743 165.008C172.196 175.234 184.557 185.322 206.616 204.622C240.102 233.924 252.998 241.395 257.936 245.949C262.874 250.504 246.08 242.887 247.521 243.279C248.963 243.67 260.901 248.995 260.433 248.178C259.849 247.158 257.095 239.479 256.742 235.799'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M145.547 156.701C150.866 154.93 143.878 156.512 133.288 160.036C118.72 164.884 104.012 166.527 76.2948 176.058C34.2157 190.527 13.6424 193.342 7.23535 195.359C0.828234 197.376 15.7903 186.598 14.7334 187.653C13.6766 188.709 3.11843 196.415 4.05926 196.416C5.23529 196.416 13.1353 198.289 14.5083 201.215'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M234.508 253.665C233.184 251.874 231.952 250.278 231.057 248.37C229.448 244.943 228.74 243.526 228.281 242.113C227.918 240.998 227.34 241.966 228.124 243.152'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M33.429 203.72C32.3233 202.669 31.1536 201.087 29.9844 199.503C29.3998 198.711 28.6891 197.198 28.437 195.752C28.2438 194.645 27.3651 195.74 28.3622 196.25'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M237.145 241.919C235.689 243.604 234.185 244.918 232.738 246.45C230.284 249.046 226.597 251.166 225.321 251.925C224.311 252.524 224.835 252.771 225.82 251.745'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M27.8916 208.665C29.5309 209.818 31.7413 207.728 33.2056 205.212C35.8359 200.692 36.0531 198.421 36.0834 196.382C36.1079 194.747 35.9776 195.287 36.0074 196.89'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M152.314 166.597C156.234 166.885 154.983 171.352 153.418 174.018C150.968 178.194 147.61 179.746 144.486 178.651C142.056 177.798 141.874 173.357 144.191 170.196C148.232 164.688 151.539 165.852 152.668 166.867C152.885 167.063 151.897 167.103 151.789 167.005'
        stroke={stroke}
        strokeWidth='7.37539'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default TriangleVolumeFigure
