import React, { PropsWithChildren } from 'react'
import { motion, MotionProps } from 'framer-motion'

const initialState: MotionProps['initial'] = {
  opacity: 0,
  width: '80%',
  height: '80%',
  top: '5%',
  left: '5%',
  position: 'absolute',
}

const animatedState: MotionProps['animate'] = {
  opacity: 1,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  position: 'relative',
  transition: { duration: 0.35, ease: 'linear' },
}

export const AnimatedLayout = ({ children }: PropsWithChildren) => {
  return (
    <motion.div initial={initialState} animate={animatedState}>
      {children}
    </motion.div>
  )
}
