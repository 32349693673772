import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import stylesScss from '../../../../../../simpleMode/components/HandwritingAnswerSimpleMode/styles.module.scss'
import { PracticeChoiceAnswerProps, SelectedAnswerChoice } from '../../../types'
import { selectedAnswersModel, useSelectedAnswers } from '../../../../../models/selectedAnswers'
import { AnswerType } from '../../../../../../Problem/types.answer'
import ChoiceVariant from '../../../../../../../components/Problem/ProblemAnswerChoice/AnswerChoice/ChoiceVariant/ChoiceVariant'
import { getCorrectAnswerVariantDisplay } from '../../../../../../../helpers/problemHelper'
import { DEFAULT_FONT_SIZE } from '../../../../../../../constants/styleConstants'
import { Context as UserSettingsContext } from '../../../../../../../context/userSettingsContext'
import clsx from 'clsx'
import {
  createGridTemplateColumns,
  hasLaTeXFraction,
} from '../../../../../../../components/Problem/ProblemAnswerChoice/AnswerChoice/helpers'
import { inputTypeKeyboard, inputTypeMyScript } from '../../../../../../Problem/helpers'
import ProblemAnswerButton from '../../../../../../Problem/ProblemAnswerButton/ProblemAnswerButton'
import { InputType } from '../../../../../../Problem/models/inputType'
import { getUserType } from '../../../../../../../helpers/users/getUserInfo'
import { useTranslation } from 'react-i18next'
import Keyboards from '../../../../../../../components/Problem/Keyboards'
import AnswerFeedback from '../../../../../../../ui/feedback/AnswerFeedback'
import { SimpleModeTextToSpeechButton } from '../../../../../../simpleMode/components/SimpleModeTextToSpeechButton/SimpleModeTextToSpeechButton'

// TODO: toggle between spreadsheet mode and default keyboard
export const PracticeChoiceAnswerSimple = ({
  inputType,
  answerVariants,
  isTranslated,
  isTranslationCompleted,
  answerStatus,
  answerStyle,
  choiceType,
  feedbackText,
  isActiveSpreadsheet,
  setInputType,
  exerciseMode,
  submitAnswer,
  submitButtonText,
  isSubmitButtonDisabled,
  textToSpeechContent,
}: PracticeChoiceAnswerProps) => {
  const { t } = useTranslation()
  const { state: userSettings } = useContext(UserSettingsContext)
  const selectedAnswers = useSelectedAnswers((state) => state.selectedAnswers)
  const [textSize, setTextSize] = useState(userSettings.textSize ?? DEFAULT_FONT_SIZE)
  const isOrderedChoiceAnswerType = choiceType === AnswerType.ORDERED_CHOICE

  const resizeAnswerVariantText = (hasOverflown: boolean) => {
    if (!hasOverflown) return

    const smallerTextSize = textSize - 1
    if (smallerTextSize < DEFAULT_FONT_SIZE) return

    setTextSize(smallerTextSize)
  }

  const isOptionSelected = (option: SelectedAnswerChoice) => selectedAnswers.includes(option.id)

  const optionContentMarkdown = (option: SelectedAnswerChoice) => {
    if (isTranslated && isTranslationCompleted && option.translation) {
      return option.translation
    }

    return getCorrectAnswerVariantDisplay(option.variant)
  }

  // TODO: to separate component
  const getChoiceOrder = (option: SelectedAnswerChoice) => {
    if (isOrderedChoiceAnswerType && isOptionSelected(option)) {
      return <span>{selectedAnswers.findIndex((answer) => answer === option.id) + 1}</span>
    }

    return <span />
  }

  return (
    <div className={styles.PracticeChoiceAnswerSimple}>
      <div
        className={styles.ChoiceOptions}
        style={{ gridTemplateColumns: createGridTemplateColumns(answerVariants.length) }}
      >
        {/* TODO: separate component, add scrollLeft for long content*/}
        {inputTypeMyScript(inputType) ? (
          answerVariants.map((option) => (
            <ChoiceVariant
              key={option.id}
              id={`${option.id}`}
              onClick={() => selectedAnswersModel.selectAnswer({ answerId: option.id, choiceType })}
              disabled={!!answerStatus}
              orderIcon={getChoiceOrder(option)} // TODO:
              content={optionContentMarkdown(option)}
              textSize={textSize}
              changeTextSize={resizeAnswerVariantText}
              className={clsx(styles.PracticeChoiceButton, {
                [styles[answerStyle]]: !!answerStyle,
                [styles.Selected]: isOptionSelected(option),
                [styles.LargeFont]: !hasLaTeXFraction(option.variant),
              })}
            />
          ))
        ) : (
          <div className={styles.AnswerVariantsKeyboards}>
            <div />
            <Keyboards isSelectButtonHidden isMathButtonExtended />
          </div>
        )}
      </div>

      {/* TODO: reset on navigate */}
      <SimpleModeTextToSpeechButton textToSpeechContent={textToSpeechContent} />

      <div className={styles.PracticeChoiceButtonContainer}>
        {answerStatus && (
          <AnswerFeedback text={feedbackText} style={answerStyle} className={styles.PracticeChoiceFeedback} />
        )}
        {isActiveSpreadsheet && inputTypeKeyboard(inputType) ? (
          <ProblemAnswerButton
            text={t('backText')}
            onClick={() => setInputType(InputType.MY_SCRIPT)}
            disabled={false}
            userType={getUserType()}
            withMargin
            exerciseMode={exerciseMode}
            classNames={{
              button: stylesScss.SimpleModeButton,
              content: stylesScss.SimpleModeButtonContent,
            }}
          />
        ) : (
          <ProblemAnswerButton
            onClick={submitAnswer}
            text={submitButtonText}
            style={answerStyle}
            disabled={isSubmitButtonDisabled}
            withMargin
            userType={getUserType()}
            exerciseMode={exerciseMode}
            classNames={{
              button: stylesScss.SimpleModeButton,
              content: stylesScss.SimpleModeButtonContent,
            }}
          />
        )}
      </div>
    </div>
  )
}
