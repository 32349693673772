export enum MathGrammarCode {
  DEFAULT = 1,
  NUMBERS,
  NUMBERS_AND_X,
  NUMBERS_AND_MULTIPLICATION,
  NUMBERS_AND_UNITS,
  DEFAULT_MATH,
  NUMBERS_WHOLE = 10,
  NUMBERS_RATIONAL,
  OPERATORS,
  PERCENT,
  UNITS,
  TIME,
  CURRENCY,
  GEOM_ANGLES,
  GEOM_COORDINATE,
  ALGEBRA_BASIC,
  ALGEBRA_ADVANCED,
  ALGEBRA_GREEK,
  GENERAL_MATH,
}

export const GRAMMARS_NAMES = {
  [MathGrammarCode.DEFAULT]: 'default',
  [MathGrammarCode.NUMBERS]: 'numbers',
  [MathGrammarCode.NUMBERS_AND_X]: 'numbersAndX',
  [MathGrammarCode.NUMBERS_AND_MULTIPLICATION]: 'numbersAndMultiplication',
  [MathGrammarCode.NUMBERS_AND_UNITS]: 'numbersAndUnits',
  [MathGrammarCode.DEFAULT_MATH]: 'defaultMath',
  [MathGrammarCode.NUMBERS_WHOLE]: 'NumbersWhole',
  [MathGrammarCode.NUMBERS_RATIONAL]: 'NumbersRational',
  [MathGrammarCode.OPERATORS]: 'Operators',
  [MathGrammarCode.PERCENT]: 'Percent',
  [MathGrammarCode.UNITS]: 'Units',
  [MathGrammarCode.TIME]: 'Time',
  [MathGrammarCode.CURRENCY]: 'Currency',
  [MathGrammarCode.GEOM_ANGLES]: 'GeomAngles',
  [MathGrammarCode.GEOM_COORDINATE]: 'GeomCoordinate',
  [MathGrammarCode.ALGEBRA_BASIC]: 'AlgebraBasic',
  [MathGrammarCode.ALGEBRA_ADVANCED]: 'AlgebraAdvanced',
  [MathGrammarCode.ALGEBRA_GREEK]: 'AlgebraGreek',
  [MathGrammarCode.GENERAL_MATH]: 'GeneralMath',
}

export const getGrammarName = (code: MathGrammarCode) => {
  return GRAMMARS_NAMES[code] ?? GRAMMARS_NAMES[MathGrammarCode.DEFAULT]
}
