import React from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

// TODO: move outside Problem directory, features/offlineMode (move models there too)
const OfflineModeBanner = () => {
  const { t } = useTranslation()

  return (
    <div className='offline-mode-banner'>
      <span>{t('offlineModeText')}</span>
    </div>
  )
}

export default OfflineModeBanner
