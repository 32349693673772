import React from 'react'
import { Button, SIZE, BUTTON_VARIANT, BUTTON_COLOR } from '@magmamath/ui'

import styles from './styles.module.scss'

import { CorrectAnswerRatePercentage } from '../constants'
import CardProgress from './CardProgress/CardProgress'

type AbilityCardProps = {
  title: string
  stats: number
  onClick: () => void
  isActionAbility?: boolean
}

const AbilityCard = ({ title, stats, onClick, isActionAbility }: AbilityCardProps) => {
  const isUnsolvedCard = !isActionAbility && (stats ?? 0) < CorrectAnswerRatePercentage.ONE_STAR

  return (
    <div className={styles.StudentLedCardWrapper}>
      <Button
        size={SIZE.LARGE}
        variant={isUnsolvedCard ? BUTTON_VARIANT.SECONDARY : BUTTON_VARIANT.PRIMARY}
        color={isActionAbility ? BUTTON_COLOR.YELLOW : BUTTON_COLOR.GREEN}
        onClick={onClick}
        classes={{ button: styles.Button }}
      >
        {title}
      </Button>
      <div className={styles.StudentLedCardProcess}>
        <CardProgress statistic={stats} />
      </div>
    </div>
  )
}

export default AbilityCard
