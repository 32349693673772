import { makeApiCall } from '../../helpers/apiCallHelper'
import { api } from '../../api'
import { GetProblemHintFeedbackProps } from '../../api/types.solutions'
import { GoogleText2SpeechProps } from '../../api/types.google'

export const getSolutionImage = (imgSrc: string) => {
  if (!imgSrc) return null
  return `${window._env_.IMAGE_SOLUTION_HOST}/${imgSrc}`
}

export const sendDrawnImage = (image: string | FormData | undefined) => {
  if (!image) return Promise.resolve()
  return makeApiCall(() => api.uploadImage(image))
}

export const sendDrawingHistory = (history: string) => {
  return makeApiCall(() => api.practice.sendDrawingHistory(history))
}

export const sendCheckAnswerHint = (exerciseId: string, problemId: string, subProblemId?: string) => {
  return makeApiCall(() => api.answerHintSeen({ exerciseId, problemId, subProblemId }))
}

export const getCurrentAssignment = (id: string) => {
  return makeApiCall(() => api.getAssignment(id))
}

export const getAssignmentById = (id: string) => {
  return makeApiCall(() => api.getAssignment({ id, fetchAll: false }))
}

export const getAssignmentStatistics = async (id: string) => {
  return makeApiCall(() => api.getAssignmentStatistics(id))
}

export const getAssignmentsList = async (page: number) => {
  return makeApiCall(() =>
    api.getAssignmentsList({
      page,
      type: 'available',
      sortBy: 'startDate',
    })
  )
}

export const getProblemTheory = (problemId: string) => {
  return makeApiCall(() => api.getProblemTheory(problemId))
}

export const translateProblemInfo = (text: string[], targetLanguage: string) => {
  return makeApiCall(() => api.google.translate({ text, targetLanguage }))
}

export const getGoogleSpeechFromText = (data: GoogleText2SpeechProps) => {
  return makeApiCall(() => api.google.text2Speech(data))
}

export const getProblemSpecificHint = (problemHintId: string) => {
  return makeApiCall(() => api.getProblemSpecificHint(problemHintId))
}

export const getAWSProblemHintFeedback = (payload: GetProblemHintFeedbackProps) => {
  return makeApiCall(() => api.getHintFeedback(payload))
}
