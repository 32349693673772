import { useEffect, useState } from 'react'
import screenfull from 'screenfull'
import { fullscreenModeOff, fullscreenModeOn } from '../../helpers'

export const useFullscreenMode = () => {
  const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen)

  const toggleFullscreenMode = () => {
    setIsFullscreen((prevState) => !prevState)

    if (isFullscreen) {
      return fullscreenModeOff()
    }

    fullscreenModeOn()
  }

  useEffect(() => {
    const manageFullscreenState = () => {
      setIsFullscreen(screenfull.isFullscreen)
    }

    document.addEventListener('fullscreenchange', manageFullscreenState)
    document.addEventListener('mozfullscreenchange', manageFullscreenState)
    document.addEventListener('MSFullscreenChange', manageFullscreenState)
    document.addEventListener('webkitfullscreenchange', manageFullscreenState)

    return () => {
      document.removeEventListener('fullscreenchange', manageFullscreenState)
      document.removeEventListener('mozfullscreenchange', manageFullscreenState)
      document.removeEventListener('MSFullscreenChange', manageFullscreenState)
      document.removeEventListener('webkitfullscreenchange', manageFullscreenState)
    }
  }, [])

  return {
    isFullscreen,
    setIsFullscreen,
    toggleFullscreenMode,
  }
}
