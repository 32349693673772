import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export enum GeogebraLoadingStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
}

type State = {
  answersBase64: {
    [key: string]: Record<string, string>
  }
  loadingStatus: GeogebraLoadingStatus
  isTextEditorOpen: boolean
  isPointsLimitReached: boolean
}

export type GeogebraModel = State & {
  actions: {
    setIsTextEditorOpen: (isOpen: boolean) => void
    setBase64Answer: ({
      assignmentId,
      problemId,
      base64Answer,
    }: {
      assignmentId: string
      problemId: string
      base64Answer: string
    }) => void
    setLoadingStatus: (loadingStatus: GeogebraLoadingStatus) => void
    setPointsLimitReached: ({
      pointsLimit,
      currentPointsCount,
    }: {
      pointsLimit: number
      currentPointsCount: number
    }) => void
  }
}

const initialState: State = {
  isTextEditorOpen: false,
  answersBase64: {},
  loadingStatus: GeogebraLoadingStatus.IDLE,
  isPointsLimitReached: false,
}

export const useGeogebraModel = create(
  immer<GeogebraModel>((set) => ({
    ...initialState,
    actions: {
      setPointsLimitReached: ({ pointsLimit, currentPointsCount }) =>
        set((state) => {
          if (currentPointsCount >= pointsLimit) {
            state.isPointsLimitReached = true
            return
          }

          state.isPointsLimitReached = false
        }),
      setIsTextEditorOpen: (isOpen) =>
        set((state) => {
          state.isTextEditorOpen = isOpen
        }),
      setBase64Answer: ({ assignmentId, problemId, base64Answer }) =>
        set((state) => {
          if (!state.answersBase64[assignmentId]) {
            state.answersBase64[assignmentId] = {}
          }

          state.answersBase64[assignmentId][problemId] = base64Answer
        }),
      setLoadingStatus: (loadingStatus) =>
        set((state) => {
          state.loadingStatus = loadingStatus
        }),
    },
  }))
)

export const useGeogebraModelActions = () => useGeogebraModel((state) => state.actions)
