import React from 'react'
import styles from './styles.module.scss'
import { ArrowRightIcon } from '../../../../ui/icons/ArrowRight'

type ChatOptionProps = {
  text: string
  onClick: () => void
}

export const ChatOption = ({ onClick, text }: ChatOptionProps) => (
  <button className={styles.ChatOption} onClick={onClick}>
    <span className={styles.AnswerText}>{text}</span>
    <span className={styles.ArrowRight}>
      <ArrowRightIcon size={{ width: 16, height: 14 }} />
    </span>
  </button>
)
