import { AppLocale, languageSettings } from '../locales/i18n.config'
import i18next from 'i18next'

//TODO created new file for avoid circular dependencies, refactor userLocale.js later
export const getCurrentOrigin = () => {
  return window.location.origin
}

const getOriginLocale = () => {
  return getCurrentOrigin().includes('com') ? AppLocale.EN : AppLocale.SV
}

export const getUserLocale = () => {
  const i18nLanguage = languageSettings[i18next.language as keyof typeof languageSettings]
  if (i18nLanguage) {
    return i18nLanguage
  }

  const language = getOriginLocale()
  return languageSettings[language as keyof typeof languageSettings]
}
