import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const ProblemNotSupported = () => {
    const { t } = useTranslation();

    return (
        <div className="not-supported-container">
            <span className="not-supported-text">{t('notSupportedText')}</span>
        </div>
    );
};

export default ProblemNotSupported;