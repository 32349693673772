import { create } from 'zustand'
import { addEvent } from '../../../lib/zustand/event'
import { getSelectedAnswers } from '../PracticeModeExercise/ProblemAnswers/PracticeChoiceAnswer/helpers'
import { AnswerType } from '../../Problem/types.answer'

type SelectedAnswersModel = {
  selectedAnswers: number[]
}

const initialState: SelectedAnswersModel = {
  selectedAnswers: [],
}

type setSelectedAnswersProps = {
  answerId: number
  choiceType: AnswerType
}

export const useSelectedAnswers = create(() => initialState)
const selectAnswer = addEvent(useSelectedAnswers, ({ answerId, choiceType }: setSelectedAnswersProps, state) => ({
  selectedAnswers: getSelectedAnswers({ selectedAnswers: state.selectedAnswers, type: choiceType, answerId }),
}))
const resetSelectedAnswers = addEvent(useSelectedAnswers, () => ({
  selectedAnswers: [],
}))

export const selectedAnswersModel = {
  selectAnswer,
  resetSelectedAnswers,
}
