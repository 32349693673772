import textFit from 'textfit'
import { RefObject } from 'react'

type ResizeKaTeXProps = {
  contentRef: RefObject<HTMLDivElement | null>
  fontSize: number
}

export const resizeKaTeX = ({ contentRef, fontSize = 16 }: ResizeKaTeXProps) => {
  const descriptionKatex = contentRef.current?.querySelectorAll('.katex-html')
  if (!descriptionKatex) return

  descriptionKatex.forEach((katexHtml) => {
    try {
      textFit(katexHtml as HTMLElement, { maxFontSize: fontSize })
    } catch (error) {
      contentRef.current?.style.setProperty('width', 'auto')
    }
  })
}
