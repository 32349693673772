import React from 'react'
import { COLORS } from '@magmamath/ui'

import type { IconProps } from './types'
import { getIconSize } from './helpers'

const SmileyFigure = ({ stroke = COLORS.NEUTRAL_2, size = 198 }: IconProps) => {
  const { width, height } = getIconSize(size)

  return (
    <svg width={width} height={height} viewBox='0 0 205 198' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60.3624 21.1076C84.2282 -2.02312 129.059 -0.930946 161.448 21.1076C176.648 31.4489 189.107 46.4024 195.375 65.6296C199.846 79.3461 201.246 92.6633 200.058 105.204C198.438 122.284 192.014 137.924 182.001 151.171C171.806 164.658 157.891 175.664 141.536 183.186C124.448 191.044 111.382 196.286 88.825 192.709C74.0811 190.369 60.5474 185.409 47.18 179.722C28.0927 171.6 19.3777 157.147 11.806 139.612C5.09027 124.059 3.42755 108.632 5.37811 94.1383C8.92965 67.7495 17.5776 58.8582 28.0927 44.1772C47.1812 17.5268 75.3898 6.81041 96.4973 10.7916C102.93 12.0048 97.7193 12.3178 91.7867 11.0941'
        stroke={stroke}
        strokeWidth='7.86188'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M68.8972 134.467C66.8529 132.603 64.2985 130.179 66.6826 133.425C77.3921 148.009 93.8285 164.123 116.329 158.426C149.24 150.092 145.339 105.3 147.029 106.474C148.717 107.649 127.291 135.508 73.2002 131.792'
        stroke={stroke}
        strokeWidth='7.86188'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M56.1742 83.1742C55.7151 84.0713 53.8903 87.4868 55.7832 88.1439C56.4872 88.3883 57.8159 87.5024 58.1341 86.9288C59.2087 84.9919 57.6743 83.2192 57.5535 84.754'
        stroke={stroke}
        strokeWidth='7.86188'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M123.1 65.4415C121.508 71.7659 125.735 67.9017 125.546 66.2003C125.336 64.3035 124.231 66.3591 124.342 64.9455'
        stroke={stroke}
        strokeWidth='7.86188'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default SmileyFigure
