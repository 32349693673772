import React from 'react'

import { getCorrectAnswerVariantDisplay } from '../../../../../../helpers/problemHelper'
import AnswerVariant from './AnswerVariant/AnswerVariant'
import { SelectedAnswerChoice } from '../../types'
import { selectedAnswersModel, useSelectedAnswers } from '../../../../models/selectedAnswers'
import { AnswerType } from '../../../../../Problem/types.answer'
import { useCurrentExerciseMode } from '../../../../../Problem/models/exerciseMode'
import { ExerciseThemeVariantMapper } from '../../../../../Problem/problem.style.mode.helpers'

type AnswerVariantsProps = {
  answerVariants: SelectedAnswerChoice[]
  isTranslated: boolean
  isTranslationCompleted: boolean
  isChoiceDisabled: boolean
  style: string
  choiceType: AnswerType
}

const AnswerVariantsBlock: React.FC<AnswerVariantsProps> = ({
  answerVariants,
  isTranslated,
  isTranslationCompleted,
  isChoiceDisabled,
  style,
  choiceType,
}) => {
  const selectedAnswers = useSelectedAnswers((state) => state.selectedAnswers)
  const isWithOrder = choiceType === AnswerType.ORDERED_CHOICE
  const { exerciseMode } = useCurrentExerciseMode()

  return (
    <>
      {answerVariants.map((answerVariant) => {
        const answerContent =
          isTranslated && isTranslationCompleted && answerVariant.translation
            ? answerVariant.translation
            : getCorrectAnswerVariantDisplay(answerVariant.variant)
        const isVariantSelected = selectedAnswers.includes(answerVariant.id)

        return (
          <AnswerVariant
            content={answerContent}
            isDisabled={isChoiceDisabled}
            isSelected={isVariantSelected}
            order={
              isWithOrder && isVariantSelected
                ? selectedAnswers.findIndex((answer) => answer === answerVariant.id) + 1
                : undefined
            }
            modeStyle={ExerciseThemeVariantMapper[exerciseMode]}
            style={style}
            answerVariant={answerVariant}
            onClick={() => selectedAnswersModel.selectAnswer({ answerId: answerVariant.id, choiceType })}
            key={answerVariant.id}
          />
        )
      })}
    </>
  )
}

export default AnswerVariantsBlock
