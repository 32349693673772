import { createEvent, createStore } from 'effector'

export const createIdNamePairModel = () => {
  const setId = createEvent<string>()
  const setName = createEvent<string>()
  const $store = createStore({ id: '', name: '' })
    .on(setId, (state, id) => {
      return {
        ...state,
        id,
      }
    })
    .on(setName, (state, name) => {
      return {
        ...state,
        name,
      }
    })

  return {
    setId,
    setName,
    $store,
  }
}
