import React, { useContext } from 'react'
import clsx from 'clsx'

import './styles.scss'
import {
  MISTAKE,
  SUCCESS,
  SUCCESS_FIRST_ATTEMPT,
  NOT_SOLVED,
  ACTIVE_ISSUE,
} from '../../../../constants/answerStatusConstants'
import AnswerInfoContext from '../../../../context/answerInfoContext'
import { useCurrentExerciseMode, ExerciseMode } from '../../../../features/Problem/models/exerciseMode'

const ProblemSelectorButton = (props) => {
  const { isExamFinished } = useContext(AnswerInfoContext)
  const { exerciseMode } = useCurrentExerciseMode()

  const stylesMapper = {
    [ACTIVE_ISSUE]: 'active-issue',
    [MISTAKE]: 'mistake-issue',
    [SUCCESS]: 'success-issue',
    [SUCCESS_FIRST_ATTEMPT]: 'success-first-attempt-issue',
    [NOT_SOLVED]: 'not-solved-issue',
  }

  return (
    <button
      className={clsx('problem-selector-button', stylesMapper[props.status || NOT_SOLVED], {
        exam: exerciseMode === ExerciseMode.EXAM && !isExamFinished(),
      })}
      onClick={props.onSelectorButtonClick}
    >
      {props.name}
    </button>
  )
}

export default ProblemSelectorButton
