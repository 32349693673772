import {
  HANDWRITING,
  MULTIPLE_CHOICE,
  ORDERED,
  PREFILLED_BOXES,
  PREFILLED_CHOICES,
  SINGLE_CHOICE,
} from '../../constants/answerTypes'
import { ProblemBase } from '../../features/Problem/types'
import { removeKatexPartFromTextToSpeech } from '../../helpers'
import { apiConfig } from '../../config/api'
import { Assignment, AssignmentProblem, ExamStatuses } from '../../features/ExerciseItem/types'
import { ProblemAnswerStatus } from '../../features/Problem/models/answer'
import i18n from 'i18next'
import { ExamStatus } from '../../features/Problem/models/exam/exam'
import { isExamEnabled } from '../../features/Problem/models/exam/exam.helpers'

export const ANSWER_TYPE_BACKGROUND_DESCRIPTOR = {
  [HANDWRITING]: { showImage: true, withGrid: true },
  [MULTIPLE_CHOICE]: { showImage: true, withGrid: true },
  [SINGLE_CHOICE]: { showImage: true, withGrid: true },
  [ORDERED]: { showImage: true, withGrid: true },
  [PREFILLED_BOXES]: { showImage: false, withGrid: false },
  [PREFILLED_CHOICES]: { showImage: false, withGrid: false },
  default: { showImage: false, withGrid: true },
}

export function getBackgroundDescriptorFromAnswerType(
  answerType: keyof typeof ANSWER_TYPE_BACKGROUND_DESCRIPTOR | 'default'
) {
  return ANSWER_TYPE_BACKGROUND_DESCRIPTOR[answerType] || ANSWER_TYPE_BACKGROUND_DESCRIPTOR.default
}

export const getProblemDescription = (problem: ProblemBase) => {
  return (
    removeKatexPartFromTextToSpeech(problem?.richDescription || problem?.laTeXDescription || problem?.description) ?? ''
  )
}

export const getCurrentImage = (exercise: AssignmentProblem | null) => {
  if (!exercise || !exercise.imageOrigin) return ''
  return `${apiConfig.apiUrl}/${exercise.imageOrigin}`
}

export const getAnswerResultStyle = (exam: ExamStatus, answerStatus: ProblemAnswerStatus) => {
  const isMistakeAnswerStatus = answerStatus === ProblemAnswerStatus.MISTAKE
  const isSuccessAnswerStatus = answerStatus === ProblemAnswerStatus.SUCCESS

  if (exam.isEnabled && !exam.isFinished) return 'exam-mode'
  if (!answerStatus) return ''
  if (isMistakeAnswerStatus) return 'mistake'
  if (isSuccessAnswerStatus) return 'success'

  return 'success-first-attempt'
}

export const getSubmitAnswerButtonInfo = (exam: ExamStatus, answerStatus: ProblemAnswerStatus) => {
  const isMistakeAnswerStatus = answerStatus === ProblemAnswerStatus.MISTAKE

  if (!answerStatus && !(exam.isEnabled && exam.isFinished)) {
    return i18n.t('replyText')
  }

  if (exam.isEnabled) {
    return i18n.t('nextText')
  }

  if (isMistakeAnswerStatus) {
    return i18n.t('mistakeText')
  }

  return i18n.t('nextText')
}

type CheckIsExamStoppedProps = {
  assignment?: Assignment
  updatedAssignment?: Assignment
}

export const checkIsExamStopped = ({ assignment, updatedAssignment }: CheckIsExamStoppedProps) => {
  if (!updatedAssignment) {
    if (!assignment) {
      return false
    }

    if (!isExamEnabled(assignment)) {
      return false
    }

    const examState = assignment.stats?.examStats?.state ?? assignment.settings?.state
    return examState === ExamStatuses.STOPPED
  }

  if (!Object.keys(updatedAssignment) || !updatedAssignment.testModeEnabled) {
    return false
  }

  const updatedExerciseState = updatedAssignment.stats?.examStats?.state ?? updatedAssignment.settings?.state
  return updatedExerciseState === ExamStatuses.STOPPED
}
