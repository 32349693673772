import { useLayoutEffect, useRef, useState } from 'react'
import { useFunctionRef } from './useFunctionRef'

type UseOverflowYDetectionProps = {
  callback?: (hasOverflow: boolean) => void
}

const useOverflowYDetection = ({ callback = () => {} }: UseOverflowYDetectionProps = {}) => {
  const [isOverflowY, setIsOverflowY] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const callbackRef = useFunctionRef(callback)

  useLayoutEffect(() => {
    const { current } = parentRef
    if (!current?.firstElementChild) return

    const hasOverflowY =
      current.clientHeight < current.firstElementChild.clientHeight ||
      current.clientWidth / (current.firstElementChild.textContent?.length ?? 1) < 4.7 // condition for chrome

    setIsOverflowY(hasOverflowY)
    callbackRef?.current?.(hasOverflowY)
  }, [callbackRef])

  return { parentRef, isOverflowY }
}

export default useOverflowYDetection
