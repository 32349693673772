import { useEffect } from 'react'
import { practicePageSessionActions } from '../sessionStorage'
import { Ability, PracticeSkill, PracticeStatistics, SkillStatisticsMap } from '../types'
import { calculateSkillProgress } from '../../practiceModeSkills/skillsTree.helpers'

type UseSaveSessionOnUnloadProps = {
  statistics: PracticeStatistics | SkillStatisticsMap | null
  currentCard: Ability | PracticeSkill | null
  nextCard: Ability | PracticeSkill | null
}

export const useSaveSessionOnUnload = ({ statistics = {}, currentCard, nextCard }: UseSaveSessionOnUnloadProps) => {
  useEffect(() => {
    const cardId = currentCard?._id || ''
    const stats = statistics?.[cardId]
    const progress = typeof stats === 'number' ? stats : calculateSkillProgress(stats)
    const solvedCardStats = { [cardId]: progress }

    const onBeforeUnload = () => {
      currentCard && practicePageSessionActions.lastOpenedAbility.set(currentCard)
      nextCard && practicePageSessionActions.nextPromptedAbility.set(nextCard)
      practicePageSessionActions.currentAbilitiesStatistics.set(solvedCardStats)
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [currentCard, nextCard, statistics])
}
