import i18next from 'i18next'
import { showErrorToast } from '../constants/toasts'
import { errorCodeMessages } from '../constants/errorCodeMessages'
import { CODE_NOT_FOUND } from '../constants/customErrorMessages'
import { writeLogg } from './index'

export const makeApiCall = async <T>(
  handler: () => Promise<T>,
  finishLoadingCallback?: () => void,
  customErrorHandlingCallback?: (error: any) => void
) => {
  try {
    return await handler()
  } catch (error: any) {
    writeLogg(error)
    finishLoadingCallback && finishLoadingCallback()

    if (customErrorHandlingCallback) {
      customErrorHandlingCallback(error)
      throw error
    }

    showErrorToast({
      message: error.response
        ? i18next.t(
            `${errorCodeMessages[error.response.data.code as keyof typeof errorCodeMessages] || CODE_NOT_FOUND}`
          )
        : error.message,
      toastId: error.response ? error.response.data.code : error.message,
    })
    throw error
  }
}
