import React from 'react'
import styles from '../styles.module.scss'
import { NumpadSymbols } from '../components/NumpadSymbols'
import { NumericKeypad, NumericKeypadProps } from '../components/NumericKeypad'
import { NumpadSymbol } from '../constants/symbols'

type RegularNumpadProps = {
  symbols: NumpadSymbol[]
  keypadProps?: NumericKeypadProps
}

export const RegularNumpad = ({ symbols, keypadProps }: RegularNumpadProps) => {
  return (
    <div className={styles.NumpadWrapper}>
      <NumpadSymbols symbols={symbols} />
      <NumericKeypad {...keypadProps} />
    </div>
  )
}
