import React from 'react'
import { DEFAULT_ICON_FILL } from '../constants'
import { IconProps } from '../types'
import { getIconSize } from '../helpers'

export const AreaIcon = ({ size = 28, fill = DEFAULT_ICON_FILL }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='27' height='27' rx='5.5' stroke={fill} />
      <line x1='0.794614' y1='11.9916' x2='11.9946' y2='0.791635' stroke={fill} strokeWidth='0.4' />
      <line x1='16.634' y1='27.8307' x2='27.834' y2='16.6307' stroke={fill} strokeWidth='0.4' />
      <line x1='0.634458' y1='17.4316' x2='17.4345' y2='0.631576' stroke={fill} strokeWidth='0.4' />
      <line x1='0.474301' y1='22.8713' x2='22.8743' y2='0.471274' stroke={fill} strokeWidth='0.4' />
      <line x1='2.17791' y1='26.4479' x2='26.4479' y2='2.17798' stroke={fill} strokeWidth='0.4' />
      <line x1='6.13739' y1='27.7678' x2='27.6041' y2='6.30109' stroke={fill} strokeWidth='0.4' />
      <line x1='11.0583' y1='27.8583' x2='27.8583' y2='11.0583' stroke={fill} strokeWidth='0.4' />
    </svg>
  )
}
