export enum DeviceOS {
  Windows = 'Windows',
  macOS = 'macOS',
  UNIX = 'UNIX',
  Linux = 'Linux',
  iOS = 'iOS',
  Android = 'Android',
  UnknownOS = 'Unknown OS',
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent

  if (userAgent.indexOf('Win') !== -1) return DeviceOS.Windows
  if (userAgent.indexOf('Mac') !== -1) return DeviceOS.macOS
  if (userAgent.indexOf('X11') !== -1) return DeviceOS.UNIX
  if (userAgent.indexOf('Linux') !== -1) return DeviceOS.Linux
  if (userAgent.match(/i(Pod|Phone|Pad)/)) return DeviceOS.iOS
  if (userAgent.indexOf('Android') !== -1) return DeviceOS.Android

  return DeviceOS.UnknownOS
}
