import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

import { getUserFullProfileInfo } from '../../helpers/users/getUserInfo'
import { Context as SidebarContext } from '../../context/sidebarContext'
import InternalHeader from '../../components/Headers/InternalHeader'
import CustomSidebar from '../../components/CustomSidebar'
import Profile from '../../components/Profile'

const ProfilePage = () => {
  const { t } = useTranslation()
  const { state } = useContext(SidebarContext)

  return (
    <div className='profile-page'>
      <CustomSidebar hiddenOnExercisePage={state.hiddenOnExercisePage} />
      <div className='profile-page-info'>
        <InternalHeader title={t('accountText')} />
        <Profile userData={getUserFullProfileInfo()} />
      </div>
    </div>
  )
}

export default ProfilePage
