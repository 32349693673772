import React from 'react'
import type { IconProps } from '../types'
import { getIconSize } from '../helpers'

export const MagmaChatIcon = ({ size = { width: 60, height: 60 } }: IconProps) => {
  const { width, height } = getIconSize(size)
  return (
    <svg width={width} height={height} viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_708_11579)'>
        <path d='M53.8686 8.76709V23.1515' stroke='#A83900' strokeWidth='1.5807' strokeLinecap='round' />
        <path d='M6.44745 8.76709V23.1515' stroke='#A83900' strokeWidth='1.5807' strokeLinecap='round' />
        <path
          d='M1.70538 25.2064C3.60223 21.2862 7.76475 21.6761 9.6089 22.3611L5.02486 33.426C3.12802 32.3195 -0.191461 29.1265 1.70538 25.2064Z'
          fill='#FF7819'
        />
        <path
          d='M58.2946 25.2064C56.3978 21.2862 52.2353 21.6761 50.3911 22.3611L54.9751 33.426C56.872 32.3195 60.1915 29.1265 58.2946 25.2064Z'
          fill='#FF275E'
        />
        <path
          d='M5.02493 44.5943V35.4262C5.49914 29.3142 8.97669 16.7534 30.1581 16.6158C51.3396 16.4783 55.344 29.2088 55.4494 35.4262V44.5943C55.4494 50.7053 50.4954 55.6592 44.3845 55.6592H16.0899C9.97887 55.6592 5.02493 50.7053 5.02493 44.5943Z'
          fill='#A83900'
        />
        <path
          d='M5.02493 42.5943V33.4262C5.49914 27.3142 8.97669 14.7534 30.1581 14.6158C51.3396 14.4783 55.344 27.2088 55.4494 33.4262V42.5943C55.4494 48.7053 50.4954 53.6592 44.3845 53.6592H16.0899C9.97887 53.6592 5.02493 48.7053 5.02493 42.5943Z'
          fill='url(#paint0_linear_708_11579)'
        />
        <mask
          id='mask0_708_11579'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='10'
          y='22'
          width='40'
          height='26'
        >
          <path
            d='M42.3565 47.8104H18.1178C14.1169 47.8104 10.8735 44.5665 10.8735 40.5656V33.2679C10.8735 26.629 16.5477 22.0449 22.7288 22.0449C25.6887 22.0449 27.8002 23.2467 29.0162 24.1375C29.7188 24.6522 30.6126 24.6679 31.318 24.1571C33.1789 22.8095 34.9347 22.0449 38.2197 22.0449C44.2263 22.0449 49.6007 27.5774 49.6007 33.2679V40.5659C49.6007 44.5668 46.3574 47.8104 42.3565 47.8104Z'
            fill='#33334D'
          />
        </mask>
        <g mask='url(#mask0_708_11579)'>
          <path
            d='M52.5657 52.9886L15.9701 16.393C15.3726 15.7955 15.7958 14.7739 16.6407 14.7739H53.2363C53.7601 14.7739 54.1847 15.1985 54.1847 15.7223V52.318C54.1847 53.1629 53.1632 53.5861 52.5657 52.9886Z'
            fill='#ffffff'
            fillOpacity='0.1'
          />
          <g filter='url(#filter0_i_708_11579)'>
            <path
              d='M42.3565 47.8104H18.1178C14.1169 47.8104 10.8735 44.5665 10.8735 40.5656V33.2679C10.8735 26.629 16.5477 22.0449 22.7288 22.0449C25.6887 22.0449 27.8002 23.2467 29.0162 24.1375C29.7188 24.6522 30.6126 24.6679 31.318 24.1571C33.1789 22.8095 34.9347 22.0449 38.2197 22.0449C44.2263 22.0449 49.6007 27.5774 49.6007 33.2679V40.5659C49.6007 44.5668 46.3574 47.8104 42.3565 47.8104Z'
              fill='#F2F2F4'
            />
          </g>
          <path
            d='M42.3565 47.5437H18.1178C14.2642 47.5437 11.1402 44.4192 11.1402 40.5656V33.2679C11.1402 26.8015 16.6683 22.3116 22.7288 22.3116C25.6123 22.3116 27.6694 23.4815 28.8586 24.3526C29.6516 24.9335 30.6723 24.9539 31.4744 24.3731C32.3912 23.7092 33.2709 23.197 34.3189 22.8487C35.3676 22.5003 36.5982 22.3116 38.2197 22.3116C41.1409 22.3116 43.9211 23.658 45.9742 25.711C48.0279 27.7647 49.3341 30.5055 49.3341 33.2679V40.5659C49.3341 44.4196 46.2101 47.5437 42.3565 47.5437Z'
            stroke='#811200'
            strokeWidth='0.533333'
          />
          <path
            opacity='0.33'
            d='M16.2477 34.6591C15.9544 31.8223 15.0092 29.2075 13.4725 27.0439C13.028 27.6934 12.0725 29.2591 11.8058 30.3257C11.5391 31.3924 11.4725 32.5479 11.4725 32.9924V40.3257C11.4725 40.9924 11.3728 42.8678 12.8058 44.6591C14.1391 46.3257 15.8058 46.9924 16.2477 46.9924V34.6591Z'
            fill='#ffffff'
          />
          <path
            opacity='0.33'
            d='M25.9723 34.9816V47.3258H35.5324V34.9103C35.3985 31.2992 33.61 27.6585 30.8058 25.0169C30.8058 25.0169 30.436 25.3258 30.1391 25.3258C29.8058 25.3258 29.4725 24.9925 28.8058 24.6592C26.4725 22.9925 24.8058 22.6592 22.4725 22.6592C21.4058 22.6592 19.866 22.9543 19.2294 23.1019C22.3615 25.2763 25.1415 29.8291 25.9723 34.9816Z'
            fill='#ffffff'
          />
          <path
            opacity='0.33'
            d='M44.9407 34.9645C44.9407 36.2768 45.0908 40.5711 45.0925 46.6924C45.7747 46.3479 47.2725 45.3923 47.8058 44.3256C48.4725 42.9923 48.8058 41.9923 48.8058 40.9923V32.3256C48.6947 30.9923 47.8725 27.7923 45.4725 25.6589C42.4725 22.9923 40.1391 22.5231 37.4725 22.591C40.9611 24.3853 44.3007 28.8553 44.9407 34.9645Z'
            fill='#ffffff'
          />
        </g>
        <circle cx='6.44758' cy='6.87043' r='2.52913' fill='#fd7e14' />
        <circle cx='53.8685' cy='6.87043' r='2.52913' fill='#FF275E' />
        <path
          d='M26.3644 40.0649C27.1112 41.7753 28.4511 42.6864 30.3162 42.7521C32.2845 42.8216 33.7959 41.8699 34.5841 40.0649'
          stroke='#fd7e14'
          strokeWidth='2.52913'
          strokeLinecap='round'
        />
        <circle cx='20.8319' cy='35.1647' r='2.52913' fill='#fd7e14' stroke='#fd7e14' strokeWidth='0.316141' />
        <path
          d='M19.3997 33.4939L18.8948 34.0446C18.587 34.3804 18.6595 34.9131 19.0458 35.1546C19.347 35.3429 19.7402 35.2863 19.9763 35.0208L20.4726 34.4624C20.6857 34.2227 20.7153 33.8712 20.5453 33.5992C20.2921 33.1941 19.7225 33.1417 19.3997 33.4939Z'
          fill='#ffffff'
        />
        <circle cx='40.1165' cy='35.1647' r='2.52913' fill='#fd7e14' stroke='#fd7e14' strokeWidth='0.316141' />
        <path
          d='M38.8423 33.4939L38.3375 34.0446C38.0297 34.3804 38.1021 34.9131 38.4884 35.1546C38.7897 35.3429 39.1829 35.2863 39.4189 35.0208L39.9152 34.4624C40.1283 34.2227 40.1579 33.8712 39.9879 33.5992C39.7347 33.1941 39.1651 33.1417 38.8423 33.4939Z'
          fill='#ffffff'
        />
      </g>
      <defs>
        <filter
          id='filter0_i_708_11579'
          x='10.8735'
          y='22.0449'
          width='38.7272'
          height='25.7656'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.66667' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.823529 0 0 0 1 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_708_11579' />
        </filter>
        <linearGradient
          id='paint0_linear_708_11579'
          x1='5.02493'
          y1='36.0572'
          x2='55.4494'
          y2='36.0572'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF245F' />
          <stop offset='1' stopColor='#FF7917' />
        </linearGradient>
        <clipPath id='clip0_708_11579'>
          <rect width='60' height='60' fill='#ffffff' />
        </clipPath>
      </defs>
    </svg>
  )
}
