import type { IconProps } from './types'
import { DEFAULT_ICON_HEIGHT, DEFAULT_ICON_WIDTH } from './constants'

export const getIconSize = (size: IconProps['size']) => {
  if (!size) {
    return {
      width: DEFAULT_ICON_WIDTH,
      height: DEFAULT_ICON_HEIGHT,
    }
  }

  return {
    width: typeof size === 'number' ? size : size.width,
    height: typeof size === 'number' ? size : size.height,
  }
}
