import { createEffect } from 'effector'
import { api } from '../../../api'

export const getHelpQueueFx = createEffect(async (classroomId?: string) => {
  if (!classroomId) return null
  const response = await api.helpQueue.get(classroomId)
  return {
    classroomId,
    position: response.requestCount,
    self: response.self,
  }
})

export const addToHelpQueueFx = createEffect(api.helpQueue.addTo)

export const removeFromHelpQueueFx = createEffect(api.helpQueue.removeFrom)
